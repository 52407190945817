import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {getTimeFrameContent, isBenchMarkWarningShowing, isShowingWarningForToolbar} from "../../lib/common";
import {Col, Row, Grid, OverlayTrigger} from "react-bootstrap";
import Button from "react-bootstrap/lib/Button";
import KnoaDropdown from "./KnoaDropdown";
import KnoaNavDropdown from "./KnoaNavDropdown";
import {getWarningMessages} from "../../lib/messageHandler";
import Tooltip from "react-bootstrap/lib/Tooltip";

const DuelTimeframeContent = props => {
    const {reportFilters, projectID, t} = props;
    return (
        <>
            {isShowingWarningForToolbar(reportFilters) &&
            <OverlayTrigger
                overlay={<Tooltip id={"benchmarkwarningtooltip"} >{getWarningMessages(t, 'benchmarkSlider', null)}</Tooltip>}
                placement="top"
                delayShow={300}
                delayHide={150}
                trigger={['hover','focus']}
            >
                <div className="report-toolbar-Timeframe-warning-content">
                    <div className="report-toolbar-Timeframe-warning-background">
                        <div className="report-toolbar-Timeframe-warning-container">
                            <div className="benchmarking-warning-left-col"></div>
                            <Row className="benchmarking-warning-left">
                                <i className="fa fa-warning benchmarking-warning-left-icon" ></i>
                            </Row>
                        </div>
                        <div className="report-toolbar-Timeframe-warning-container">
                            <Row className="benchmarking-warning-right">
                                <span className="pressText report-toolbar-Timeframe-content-span">
                                    {t('report.filter:benchmarkPeriod')}
                                </span>
                            </Row>
                            <Row className="report-toolbar-Timeframe-content-row benchmarking-warning-right">
                                <span className="pressText report-toolbar-Timeframe-content-row-span">
                                    {getTimeFrameContent(reportFilters, "benchmark", projectID, t)}
                                </span>
                            </Row>
                        </div>
                    </div>
                </div>
            </OverlayTrigger>
            }
            {!isShowingWarningForToolbar(reportFilters) &&
            <div className="report-toolbar-Timeframe-content">
                <Row>
                    <span className="pressText report-toolbar-Timeframe-content-span">
                        {t('report.filter:benchmarkPeriod')}
                    </span>
                </Row>
                <Row className="report-toolbar-Timeframe-content-row">
                    <span className="pressText report-toolbar-Timeframe-content-row-span">
                        {getTimeFrameContent(reportFilters, "benchmark", projectID, t)}
                    </span>
                </Row>
            </div>
            }
        </>    
    )
};

const TimeframeContent = props => {
    const {reportFilters, projectID, t} = props;
    return (
        <div className="report-toolbar-Timeframe-content">
            <Row>
              <span className="pressText  report-toolbar-Timeframe-content-span-withcolor">
                {t('report.filter:basePeriod')}
              </span>
            </Row>
            <Row className="report-toolbar-Timeframe-content-row">
              <span className="pressText report-toolbar-Timeframe-content-row-span-withcolor">
                {getTimeFrameContent(reportFilters, "base", projectID, t)}
              </span>
            </Row>
        </div>
    )
};

const FilterContent = props => {
    const {showFiltersSection, handleShow, activeFilters, t} = props;
    return (
        <div className="report-toolbar-Content-div">
            <Button className={showFiltersSection ? "dropdownContainer dropdownContainerHide" : "dropdownContainer dropdownContainerShow"}
                    onClick={handleShow}>
                <i className="fa fa-filter knoaButtonIcon"/>
                <span className="pressText  report-toolbar-FilterContent-div-span">
          {t('report.filter:filters')}
        </span>
                <span
                    className="pressText report-toolbar-Timeframe-content-row-span"> ({activeFilters + " " + t('report.filter:active')})</span>
            </Button>
        </div>
    )
};

const DrillContent = props => {
    const {dataRangeType, currentWorkbook, backClick, options, instanceWorkbookId, isDataReport, t} = props;

    return (
        <>
            {((dataRangeType === 'instance' && currentWorkbook['workbookName'] !== "user workflow")
                    || ((dataRangeType === 'current' || dataRangeType === 'historical')
                        && (currentWorkbook['workbookName'].endsWith("daily")
                            || currentWorkbook['workbookName'].endsWith("monthly")
                            || currentWorkbook['workbookName'].endsWith("flows")))) && ! isDataReport &&
            <div className="report-toolbar-Content-div">
                <KnoaDropdown currentWorkbook={currentWorkbook} options={options}
                              instanceWorkbookId={instanceWorkbookId} dataRangeType={dataRangeType}
                              icon="fa fa-share-square-o"/>
            </div>}
            {(dataRangeType === 'instance' || isDataReport ||
                    ((dataRangeType === 'current' || dataRangeType === 'historical')
                        && currentWorkbook['workbookName'].endsWith("flow"))) &&
            <div className="report-toolbar-Content-div">
                <Button className="toolbarBtn" onClick={backClick}>
                    <i className="fa fa-mail-reply knoaButtonIcon"/>
                    <span className='pressText'>{t('common:back')}</span>
                </Button>
            </div>}
        </>
    )
};

const MoreContent = props => {
    const {moreMenuOptions} = props;
    return (
        <div className="report-toolbar-More-div">
            <KnoaNavDropdown options={moreMenuOptions}/>
        </div>
    )
};

class ReportToolbar extends Component {
    handleShow = (e) => {
        e.preventDefault();
        this.props.handleShow();
    };

    render() {
        const {
            isInstanceReport,
            currentWorkbook,
            timeframe,
            filter,
            drill,
            more,
            handleShow,
            showFiltersSection
        } = this.props;

        const {t} = this.props;

        return (
            <Col xs={12} sm={12} lg={6} className="report-toolbar-col knoa-region" tabIndex={"-1"}>
                <Grid fluid className="report-toolbar-col">
                    <Row className="report-toolbar-col-row">
                        {timeframe && timeframe.reportFilters.dualPeriod &&
                        <DuelTimeframeContent
                            reportFilters={timeframe.reportFilters}
                            projectID={timeframe.projectID}
                            t={t}
                        />
                        }
                        {timeframe &&
                        <TimeframeContent
                            reportFilters={timeframe.reportFilters}
                            projectID={timeframe.projectID}
                            t={t}
                        />
                        }
                        {filter && ((currentWorkbook['workbookName'].endsWith("daily") || currentWorkbook['workbookName'].endsWith("monthly") || currentWorkbook['workbookName'].endsWith("workflows") || currentWorkbook['workbookName'].endsWith("instances"))) &&
                        <FilterContent
                            showFiltersSection={showFiltersSection}
                            handleShow={handleShow}
                            activeFilters={filter.numOfActiveFilters}
                            t={t}
                        />
                        }
                        {drill && drill.instanceEnabled &&
                        <DrillContent
                            currentWorkbook={currentWorkbook}
                            dataRangeType={drill.dataRangeType}
                            backClick={drill.backClick}
                            options={drill.options}
                            instanceWorkbookId={drill.instanceWorkbookId}
                            isDataReport={drill.isDataReport}
                            t={t}
                        />
                        }

                        <MoreContent
                            moreMenuOptions={more.moreMenuOptions}
                        />

                    </Row>
                </Grid>
            </Col>
        )
    }
}

export default withTranslation()(ReportToolbar);