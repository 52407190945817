/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import Loader from "react-loader-spinner";
import {withTranslation} from 'react-i18next';
import {Row, Col, Grid} from "react-bootstrap";

const ExportSpinner = props => {
    const {t} = props;
    return (
        <div className="spinner">
            <Row>{t('messages.common:exportInProgress')}</Row>
            <Row><Loader type="ThreeDots" color="#0052cc" height="100" width="100"/></Row>
        </div>
    )
};

export default withTranslation()(ExportSpinner);

