import * as React from "react";

class filterFactory {
  constructor() {
    this.tableauSelections = [
      "APPLICATION_NAME",
      "MODULE_NAME",
      "SCREEN GROUP NAME",
      "STRING_ATTR1",
      "STRING_ATTR2",
      "STRING_ATTR3",
      "STRING_ATTR4",
      "STRING_ATTR5",
      "STRING_ATTR6",
      "STRING_ATTR7",
      "STRING_ATTR8",
      "STRING_ATTR9",
      "STRING_ATTR10",
      "END USER NAME",
      "ERROR TYPE",
      "MESSAGE_NAME",
      "MESSAGE DATE",
      "MESSAGE TIME",
      "SCREEN_NAME",
      "SCREEN START DATE",
      "SCREEN START TIME",
      "APPLICATION NAME VERSION",
      "BROWSER INFO",
      "OS INFO",
      "DAY ID",
      "MONTH ID",
      "OPERATION NAME", //only used in selections
      "OPERATION START DATE",
      "OPERATION START TIME",
      "SELECT_USER_ATTRIBUTE", //Summary tab - Organizational Summary
      "GEO_LOCATION", //Geography report's map
      "BENCHMARK OR BASE",
      "(EVENT TIME)", //"HOUR(EVENT TIME)"
      "(USER ERRORS PER USER)", //"AGG(USER ERRORS PER USER)" - Geography Daily's Errors tab for map selection
      "(SYSTEM ERRORS PER USER)", //"AGG(SYSTEM ERRORS PER USER)" - Geography Daily's Errors tab for map selection
      "DOMAIN NAME",
      "COMPUTER NAME"
    ];
    this.filterDayId = {
      default: "DAY ID",
      converted: "dateid"
    };
    this.filterEndUserName = {
      default: "END USER NAME",
      converted: "username"
    };
    this.timeFilters = [
      "MESSAGE TIME",
      "SCREEN START TIME",
      "OPERATION START TIME"
    ];
    this.applicationName = {
      fieldName: "APPLICATION_NAME",
      contextName: "APPLICATION NAME"
    };
    this.moduleName = {
      fieldName: "MODULE_NAME",
      contextName: "MODULE NAME"
    };
    this.screenName = {
      fieldName: "SCREEN_NAME",
      contextName: "SCREEN NAME"
    };

    this.screenGroupName = {
      fieldName: "SCREEN_GROUP_NAME",
      contextName: "SCREEN GROUP NAME"
    };
    
    this.messageName = {
      fieldName: "MESSAGE_NAME",
      contextName: "MESSAGE NAME"
    };
    this.messageTime = {
      time: "MESSAGE TIME",
      date: "MESSAGE DATE"
    };
    this.screenStartTime = {
      time: "SCREEN START TIME",
      date: "SCREEN START DATE"
    };
    this.operationStartTime = {
      time: "OPERATION START TIME",
      date: "OPERATION START DATE"
    };  
    this.eventTimebyHour = "Hour";
    this.country = "COUNTRY";
    this.userAttributePrefix = "STRING_ATTR";
    
    this.drillDownFromHomeDisabledSelections = {
      errorType:"ERROR TYPE",
      appNameVersion: "APPLICATION NAME VERSION",
      browserVersion: "BROWSER INFO",
      osVersion:"OS INFO"
    }
    
    this.applicationFilters = ['APPLICATION NAME','MODULE NAME','SCREEN NAME','MESSAGE NAME','SCREEN GROUP NAME','ERROR TYPE','APPLICATION NAME VERSION','BROWSER INFO','OS INFO'];
    this.initParameters = [
      "DAY ID",
      "MONTH ID",
      "START DAY ID",
      "END DAY ID",
      "BENCHMARK START DAY ID",
      "BENCHMARK END DAY ID",
      "TESTING EFFORT PER MODULE (H)",
      "ANNUAL FTE COST ($)",
      "ANNUAL FTE HOURS",
      "TIME COST USER ERRORS (S)",
      "TIME COST SYSTEM ERRORS (S)",
      "TIME COST NON ERRORS (S)",
      "TIME COST UNCATEGORIZED (S)",
      "PROJECTED USERS",
      "PROJECTED DURATION (YEARS)",
      "LANGUAGE",
      "dateid",
      "username"
    ];
    this.workflowInitParameters = [] //not needed?
    this.parameters = [
      "APPLICATION NAME",
      "MODULE NAME",
      "SCREEN NAME",
      "MESSAGE NAME"
    ];
    this.regexFilters = ['APPLICATION NAME','MODULE NAME','SCREEN NAME','MESSAGE NAME'];
    this.apiFilter = {
      'APPLICATION NAME': 'application',
      'MODULE NAME': 'module',
      'SCREEN NAME': 'screen',
      'MESSAGE NAME': 'message',
      'SCREEN GROUP NAME': 'screenGroup'
    }
    this.benchmarkFilterName = "BENCHMARK OR BASE";
    this.startDayIdFilterName = "START DAY ID";
    this.endDayIdFilterName = "END DAY ID";
    this.startMonthIdFilterName = "START MONTH ID";
    this.endMonthIdFilterName = "END MONTH ID";
    this.benchmarkStartDayIdFilterName = "BENCHMARK START DAY ID";
    this.benchmarkEndDayIdFilterName = "BENCHMARK END DAY ID";
    this.benchmarkStartMonthIdFilterName = "BENCHMARK START MONTH ID";
    this.benchmarkEndMonthIdFilterName = "BENCHMARK END MONTH ID";
    this.errorTypeFilterName = "ERROR TYPE";

    this.userWorkflowsFilterIgnore = 'APPLICATION NAME';
    this.userWorkflowsWorkbookId = 11;

    this.allowedMarksToFiltersInWorkflow = [
      "DAY ID",
      "END USER NAME",
      "SCREEN START DATE",
      "SCREEN START TIME",
      "OPERATION START DATE",
      "OPERATION START TIME",
      "MESSAGE DATE",
      "MESSAGE TIME"
    ];
  }

  get getTableauSelections() {
    return this.tableauSelections;
  };

  getFilterDayId(type) {
    return this.filterDayId[type];
  };

  getFilterEndUserName(type) {
    return this.filterEndUserName[type];
  };

  getTimeFilters() {
    return this.timeFilters;
  };

  getApplicationName(type) {
    return this.applicationName[type];
  };

  getModuleName(type) {
    return this.moduleName[type];
  };

  getScreenName(type) {
    return this.screenName[type];
  };

  getScreenGroupName(type) {
    return this.screenGroupName[type];
  };

  getMessageName(type) {
    return this.messageName[type];
  };

  getMessageTime(type) {
    return this.messageTime[type];
  };
  getScreenStartTime(type) {
    return this.screenStartTime[type];
  };

  getOperationStartTime(type) {
    return this.operationStartTime[type];
  };
  
  getEventTimeByHour(){
    return this.eventTimebyHour;
  };
  
  getCountry(){
    return this.country;
  };

  getUserAttributePrefix(){
    return this.userAttributePrefix;
  };

  getDrillDownHomeDisabledSelectons(type){
    return this.drillDownFromHomeDisabledSelections[type];
  };
  
  get getApplicationFilters(){
    return this.applicationFilters;
  };
  
  get getRegexFilters(){
    return this.regexFilters;
  };
  
  getApiFilter(){
    return this.apiFilter;
  };

  getBenchmarkFilterName(){
    return this.benchmarkFilterName;
  };

  getStartDayIdFilterName(){
    return this.startDayIdFilterName;
  };

  getEndDayIdFilterName(){
    return this.endDayIdFilterName;
  };

  getStartMonthIdFilterName(){
    return this.startMonthIdFilterName;
  };

  getEndMonthIdFilterName(){
    return this.endMonthIdFilterName;
  };

  getBenchmarkStartDayIdFilterName(){
    return this.benchmarkStartDayIdFilterName;
  };

  getBenchmarkEndDayIdFilterName(){
    return this.benchmarkEndDayIdFilterName;
  };

  getBenchmarkStartMonthIdFilterName(){
    return this.benchmarkStartMonthIdFilterName;
  };

  getBenchmarkEndMonthIdFilterName(){
    return this.benchmarkEndMonthIdFilterName;
  };

  getErrorTypeFilterName(){
    return this.errorTypeFilterName;
  };

  getUserWorkflowsFilterIgnore() {
    return this.userWorkflowsFilterIgnore;
  }

  getUserWorkflowsWorkbookId() {
    return this.userWorkflowsWorkbookId;
  }

  isUserWorkflowsIgnore = (filterName, workbookId) => {
    if(this.getUserWorkflowsFilterIgnore()===filterName && this.getUserWorkflowsWorkbookId()===workbookId) {
      return true;
    }
    return false;
  }

  getAllowedMarksToFiltersInWorkflow() {
    return this.allowedMarksToFiltersInWorkflow;
  }
}

const FILTERS = new filterFactory();
export { FILTERS };