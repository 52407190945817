import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Button from "../creativeTim/CustomButton.jsx";
import {NavLink} from "react-router-dom";

class LinkWithTooltip extends Component {
  constructor(props) {
    super(props);

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick = (e) => {
    this.props.onClick(e,this.props.id);
  };

  render() {
    const {id, tooltip, tooltipIconClass, buttonStyle, bsStyle, iconStyle, href, linkName, onClick} = this.props;
    return (
      <OverlayTrigger
        overlay={<Tooltip id={id} >{tooltip}</Tooltip>}
        placement="top"
        delayShow={300}
        delayHide={150}
        trigger={['hover','focus']}
      >
        {href !== "" ? (
          <NavLink
              tabIndex ="-1" //Here to make sure the focus will be on the Button instead of on the a link.
              to={{
              pathname: `${href}`,
              state: {id: id,
                      linkName: linkName ? linkName : ""}
            }}
          >
            <Button onClick={onClick ? e => this.handleButtonClick(e) : undefined} simple sm
                    bsStyle={bsStyle ? bsStyle : ""} style={buttonStyle} aria-label={tooltip}>
              <i className={tooltipIconClass} style={iconStyle} />
            </Button>
          </NavLink>
        ) : (
          <Button onClick={onClick ? e => this.handleButtonClick(e) : undefined} simple sm
                  bsStyle={bsStyle ? bsStyle : ""} style={buttonStyle} aria-label={tooltip}>
            <i className={tooltipIconClass} style={iconStyle} />
          </Button>
        )
        }
      </OverlayTrigger>
    );
  }
}

export default LinkWithTooltip;