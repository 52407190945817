import React, {Component} from "react";
import {Row, FormGroup, Grid, Nav, NavItem, Tab, Col} from "react-bootstrap";
import InfoSelectCard from "../Card/InfoSelectCard";
import {capitalizeWords} from "../../lib/common";
import {Responsive, WidthProvider} from 'react-grid-layout';
import {layoutsProject} from "../../lib/gridLayouts";
import CustomCheckbox from "../creativeTim/CustomCheckbox";
import TimeframeSelection from "./TimeframeSelection";
import ReportApplicationfilters from "../filter/ReportApplicationfilters";
import ReportUserfilters from "../filter/ReportUserfilters";
import ReportParametersFilters from "./ReportParametersFilters";


const onLayoutChange = () => {
    setTimeout(function () {
        const event = document.createEvent("Event");
        event.initEvent("resize", false, true);
        window.dispatchEvent(event);
        //window.dispatchEvent(new Event('resize'))
    }, 200);
};

const GenerateNavItems = props => {
    const {data, handleClick, trans} = props;
    const items = data.map((item, index) => (
        <NavItem eventKey={item.type} key={index} className="tabText">
            <i className={item.icon + " tabIcon"}></i><br/> {trans('route.name:' + item.type.toLowerCase())}
        </NavItem>
    ));
    return (
        <Nav className="tabUL" bsStyle="tabs" bsclass="nav nav-icons">{items}</Nav>
    )
};

const GenerateSelectCards = props => {
    const {data, id, type, icon, handleProjectReportsUpdate, reportType, trans} = props;

    if (tabSelected) {
        ResponsiveGridLayout = WidthProvider(Responsive);
    }

    //Extract report name without Daily/Monthly for translation
    const _data = data.map(item => {
        let workbook = [];
        if (reportType === 'CURRENT') {
            workbook = item.workbookName.split(' Daily');
            item.reportName = workbook[0];
            item.reportType = 'daily';
        } else if (reportType === "HISTORICAL") {
            workbook = item.workbookName.split(' Monthly');
            item.reportName = workbook[0];
            item.reportType = 'monthly';
        }
        return item;
    });

    const items = _data.map((item, index) => (
        <div key={index + 1}>
            <InfoSelectCard
                key={index}
                id={id + ":" + item.id + ":" + reportType}
                isChecked={item.checked}
                icon={{
                    name: icon,
                    status: "neutral"
                }}
                subHeading={{
                    text: item.dualPeriod ? trans('common:dualPeriod') : trans('common:singlePeriod'),
                    color: "#595959"
                }}
                heading={{
                    text: trans('report.name:withTypeFirst', {reportName: capitalizeWords(item.reportName), type: item.reportType}),
                    color: "#000000"
                }}
                footerText={trans('kpiCard.footer:' + item.description)}
                handleProjectReportsUpdate={handleProjectReportsUpdate}
                mainRowHeight={"125px"}
            />
        </div>
    ));

    tabSelected = false;

    return (
        <ResponsiveGridLayout className="layout"
                              layouts={layoutsProject}
                              breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                              cols={{lg: 6, md: 6, sm: 6, xs: 6, xxs: 2}}
                              isDraggable={false}
                              isResizable={false}
                              onLayoutChange={onLayoutChange}
        >
            {items}
        </ResponsiveGridLayout>
    )
};

const GenerateTabItems = props => {
    const {data, handleProjectReportsUpdate, reportType, trans} = props;

    const items = data.map((item, index) => (
        <Tab.Pane eventKey={item.type} key={index}>
            <GenerateSelectCards
                data={item.data}
                id={item.type}
                type={item.type}
                icon={item.icon}
                handleProjectReportsUpdate={handleProjectReportsUpdate}
                reportType={reportType}
                trans={trans}
            />
        </Tab.Pane>
    ));

    return items;
};

let ResponsiveGridLayout = WidthProvider(Responsive);
let tabSelected = false;

class KnoaTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSelectedTab: props.data ? props.data[0].type : "",
        };
        this.tabSelect = this.tabSelect.bind(this);
    }


    componentDidUpdate() {
        const scrollPosition = sessionStorage.getItem("scrollPosition");
        if (scrollPosition) {
           // document.getElementById("nav-with-icons").scrollTo(0,parseInt(scrollPosition))
            window.scrollTo(0, parseInt(scrollPosition));
            sessionStorage.removeItem("scrollPosition");
        }
    }

    tabSelect = (key) => {
        tabSelected = true;
        this.setState({currentSelectedTab: key});
        this.props.resetSelectAllCheckStatus(this.props.reportType, key);
        window.sessionStorage.setItem("scrollPosition", window.pageYOffset);
    }

    selectionForALL = (checked) => {
        this.props.selectAllHandler(this.props.reportType, checked, this.state.currentSelectedTab);
    }

    render() {
        const {
            data,
            controlId,
            handleProjectReportsUpdate,
            isSelectAllChecked,
            reportType,
            currentReportFilters,
            filterType,
            timeData,
            updateTimeFilterValues,
            updateApplicationsAndUserFilterValues,
            absoluteStartError,
            absoluteEndError,
            handleAuthorization401,
            handleAlertClick,
            updateParametersValues,
            parameterErrors,
            trans,
            workbookId,
            setTempFilterChanged
        } = this.props;

        if (data && data.length > 0) {
            return (
                <Row className="knoaRow">
                    <FormGroup
                        controlId={controlId}
                    >
                        <Tab.Container id="nav-with-icons" defaultActiveKey={data[0].type} onSelect={this.tabSelect}>
                            <div>
                                <div className="nav-container">
                                    <Row className={"project-reports-tab-row"}>
                                        <Col className="col-md-12">
                                            <GenerateNavItems
                                                data={data}
                                                trans={trans}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className={"checkbox-select-all-parent-row"}>
                                        <Col className="col-md-12 checkbox-select-all-row-Col">
                                            <CustomCheckbox
                                                isChecked={isSelectAllChecked}
                                                number={100}
                                                label={trans('common:selectAll')}
                                                inline={"selectALL"}
                                                handleSelectionForALL={this.selectionForALL}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <Tab.Content animation={false} className="gridAdjustRight gridAdjustLeft">
                                    <GenerateTabItems
                                        data={data}
                                        handleProjectReportsUpdate={handleProjectReportsUpdate}
                                        reportType={reportType}
                                        trans={trans}
                                    />
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </FormGroup>
                </Row>
            )
        } else if (controlId === "reportsFilterTabs") {
            let showParametersTab = false;
            if (this.props.currentReportFilters && Object.keys(this.props.currentReportFilters).length !== 0 && this.props.currentReportFilters.tableauParams.length > 0)
                showParametersTab = true;

            return (
                <Row className = "reportFilterTabs">
                    <FormGroup
                        controlId={controlId} className="report-filter-tabs-form-group"
                    >
                        <Tab.Container defaultActiveKey={"Filters"}>
                            <Row className="reportFilterTabs">
                                <Col className ="col-sm-12">
                                    <Nav id="reportTabs" bsStyle="tabs filterTabs">
                                        <NavItem eventKey="Filters">{trans('report.parameters:Filters')} </NavItem>
                                        {showParametersTab && <NavItem eventKey="Parameters">{trans('report.parameters:Parameters')}</NavItem>}
                                    </Nav>
                                </Col>
                                <Col className ="col-sm-12">
                                    <Tab.Content animation id="reportTabContents">
                                        <Tab.Pane eventKey="Filters">
                                            <div className={"card card-filter-filters"} id="filterPanelDiv" >
                                                <Row className={"card-filter-row"}>
                                                    <Col className="col-md-4">
                                                        <TimeframeSelection
                                                            filterType={filterType}
                                                            timeFilter={{current: timeData, historical: timeData}}
                                                            handleTimeFilterUpdate={updateTimeFilterValues}
                                                            context={'filterPanel'}
                                                            isInstanceReport={this.props.isInstanceReport}
                                                            absoluteStartError={absoluteStartError}
                                                            absoluteEndError={absoluteEndError}
                                                        />
                                                    </Col>
                                                    <Col className="col-md-4"><ReportApplicationfilters currentReportFilters={currentReportFilters}
                                                                                                        isInstanceReport={this.props.isInstanceReport}
                                                                                                        updateApplicationsAndUserFilterValues={updateApplicationsAndUserFilterValues}
                                                                                                        handleAuthorization401={handleAuthorization401}
                                                                                                        handleAlertClick={handleAlertClick}
                                                                                                        workbookId={workbookId}
                                                                                                        setTempFilterChanged={setTempFilterChanged}/></Col>
                                                    <Col className="col-md-4"><ReportUserfilters currentReportFilters={currentReportFilters}
                                                                                                 isInstanceReport={this.props.isInstanceReport}
                                                                                                 updateApplicationsAndUserFilterValues={updateApplicationsAndUserFilterValues}
                                                                                                 handleAuthorization401={handleAuthorization401}
                                                                                                 handleAlertClick={handleAlertClick}
                                                                                                 workbookId={workbookId} /></Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                        {showParametersTab && <Tab.Pane eventKey="Parameters">
                                            <div className={"card card-filter-parameters"}>
                                                <Row className={"card-filter-row-report-panel"}>
                                                    <Col className="col-md-12 card-filter-parameters-col">
                                                    <ReportParametersFilters currentReportFilters={currentReportFilters}
                                                                             isInstanceReport={this.props.isInstanceReport}
                                                                             updateParametersValues={updateParametersValues}
                                                                             parameterErrors={parameterErrors}
                                                                             workbookId={workbookId}
                                                    />
                                                    </Col>
                                                </Row>
                                            </div>   
                                        </Tab.Pane>}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </FormGroup>
                </Row>
            )
        }
        return null;
    }
}

export default KnoaTab;