/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// These must be the first lines 
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "assets/css/demo.css";
import "assets/css/pe-icon-7-stroke.css";
import "assets/css/knoa.css";

import AuthLayout from "./components/layout/Auth.jsx";
import AdminLayout from "./components/layout/Admin.jsx";

import i18n from "./i18n";
import { I18nextProvider } from 'react-i18next';

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <HashRouter>
          <Switch>
              <Route path="/auth" render={props => <AuthLayout {...props} />}/>
              <Route path="/admin" render={props => <AdminLayout {...props} />} />
              <Redirect from="*" to="/auth/login"/>
          </Switch>
      </HashRouter>
    </I18nextProvider>,
  document.getElementById("root")
);
