import React, {Component} from "react";
import {API} from "../../lib/API";
import InfoSelectCard from "../Card/InfoSelectCard";
import {Link, Redirect} from "react-router-dom";
import Card from "../Card/Card";
import {Row, Grid, FormControl, Col} from "react-bootstrap";
import Footer from "../layout/Footer";
import {ModalDialog} from '../../components/common/ModalDialog';
import {withTranslation} from 'react-i18next';
import KnoaBreadcrumb from "../../components/common/KnoaBreadcrumb";
import {mauiRoutes} from "../../routes";
import {capitalizeWordsForOSLabel} from "../../lib/common";
import {Responsive, WidthProvider} from 'react-grid-layout';
import {layoutsKpiSettings} from "../../lib/gridLayouts";
import Spinner from "../common/Spinner";
import {CommonSelector} from "../common/CommonSelector";
import {
    getErrorMessagesFromCode,
    getErrorMsgFromStatus,
    getInfoMessages,
    getSpecificOPErrorMessages,
    getSuccessMessages
} from "../../lib/messageHandler";
import {Helmet} from "react-helmet";
const localStorage = require('../../lib/localStorage');
const FormatDataType = (dataType) => {
    return <i>{dataType}</i>
};

const FormatSubHeading = (title, subTitle) => {
    if (subTitle === "" || !subTitle) {
        return <div key={title} className="card-kpi-title">{title}</div>;
    }
    return [<div key={title} className="card-kpi-title">{title}</div>, <div key={subTitle} className="card-kpi-sub-title">{subTitle}</div>];
};

const FormatFooter = (cid, footer, selected, link, filtervalue, handleShow, trans) => {
    if (link === "" || !link) {
        return trans('kpiCard.tooltip:' + footer);
    } else {
        let newLink = '';
        let dlgLabel = '';
        if (link === '%') {
            if (selected && filtervalue[0]) {
                filtervalue = filtervalue[0];
                newLink = trans('kpiCard.tooltip:value%', {value: filtervalue});
            } else {
                newLink = trans('kpiCard.tooltip:a % threshold');
            }

            dlgLabel = trans('kpiCard.tooltip:label%');
        } else if (link === 'threshold') {
            if (selected && filtervalue[0]) {
                filtervalue = filtervalue[0];
                newLink = trans('kpiCard.tooltip:valueSeconds', {value: filtervalue});
            } else {
                newLink = trans('kpiCard.tooltip:a set threshold');
            }

            dlgLabel = trans('kpiCard.tooltip:labelThreshold');
        } else {
            if (selected) {
                filtervalue = filtervalue.length;
                newLink = trans('kpiCard.tooltip:value ' + link, {value: filtervalue});
                dlgLabel = trans('kpiCard.tooltip:' + link)
            } else {
                newLink = trans('kpiCard.tooltip:' + link);
            }
        }

        const translation = trans('kpiCard.tooltip:' + footer, {link: newLink});

        if (!selected) {
            return (
                translation.split(newLink)
                    .reduce((prev, current, i) => {
                        if (!i) {
                            return [current];
                        }
                        return prev.concat(
                            <span key={cid + '-' + link + '-disabled'} className="kpiLinkDisabled">
                          {newLink}
                      </span>
                        ).concat(current);
                    }, [])
            )
        } else {
            return (
                translation.split(newLink)
                    .reduce((prev, current, i) => {
                        if (!i) {
                            return [current];
                        }
                        return prev.concat(
                            <Link key={cid + '-' + link} onClick={(e) => {
                                handleShow(e, cid + '-' + link + '-' + dlgLabel + '-' + filtervalue)
                            }}>
                                {newLink}
                            </Link>
                        ).concat(current);
                    }, [])
            )
        }
    }
};

let ResponsiveGridLayout = WidthProvider(Responsive);
let gridInitialized = false;

class KpiContainer extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleProjectReportsUpdate = this.handleProjectReportsUpdate.bind(this);
        this.handleCurrentKpiCardFilterInputChange = this.handleCurrentKpiCardFilterInputChange.bind(this);
        this.handleCurrentKpiCardFilterDropdownChange = this.handleCurrentKpiCardFilterDropdownChange.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleKpiChangeConfirm = this.handleKpiChangeConfirm.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.formatAllKpi = this.formatAllKpi.bind(this);

        this.state = {
            allKpi: [],
            currentKpiCard: {},
            showModal: false,
            formErrors: {},
            fetchInProgress: "false",
            cardOptionFocused: false,
            unSelectedKPICardOptions:[],
            showLoggedoutModal: false,
            sessionTerminated: false
        };
    }

    formatAllKpi = (kpiCards) => {
        let tempFormatterKpiCards = [];
        kpiCards.forEach(card => {
            let lastSavedValue = [];
            let cardFtOpt = card.cardFilterOption;
            if (cardFtOpt !== null) {
                //Single value text field
                if (cardFtOpt.multiValueOption === false) {
                    lastSavedValue.push(card.cardFilterOption.options.length !== 0 ? card.cardFilterOption.options[0].value : null);
                } else {
                    //Multi value select
                    cardFtOpt.options.forEach(opt => {
                        if (opt.selected === true) lastSavedValue.push(opt);
                    });
                }
                card.cardFilterOption.lastSavedValue = lastSavedValue;
                card.cardFilterOption.lastSavedValueCount = lastSavedValue.length;
            }
            tempFormatterKpiCards.push(card);
        });
        return tempFormatterKpiCards;
    };

    handleLogoutConfirm = () =>{
        this.setState({sessionTerminated: true});
    };


    componentDidMount() {
        let _this = this;
        API.admin
            .get("/admin/card/view")
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;

                _this.setState({
                    allKpi: this.formatAllKpi(data.cards),
                });
            })
            .catch(e => {
                // only log error for now
                console.error(e);
                if(localStorage.get("accessDenied401") === "true"){
                    this.setState({showLoggedoutModal:true});
                    return;
                }
                let resultCode = -1;
                if(e.data) {
                    resultCode = e.data.resultCode;
                }
                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    }

    handleShow(e, cid) {
        e.preventDefault();
        const info = cid.split("-");
        const cKpiCard = {};
        cKpiCard.id = info[0];
        cKpiCard.filterLink = info[1];
        cKpiCard.filterLabel = info[2];
        cKpiCard.cardFilterOption = this.state.allKpi.filter(card => Number(card.id) === Number(info[0]))[0].cardFilterOption;
        cKpiCard.cardFilterOption.lastTempValue = cKpiCard.cardFilterOption.lastSavedValue;

        this.setState({
            currentKpiCard: cKpiCard,
            showModal: true,
        });
    }

    handleClose() {
        let currentCard = this.state.currentKpiCard;
        if (currentCard.cardFilterOption.lastTempValue) {
            currentCard.cardFilterOption.lastTempValue = null;
        }
        this.setState({
            currentKpiCard: currentCard,
            showModal: false,
            formErrors: {},
        });
    }

    handleCurrentKpiCardFilterInputChange(e) {
        let currentCard = this.state.currentKpiCard;
        currentCard.cardFilterOption.lastTempValue = [];
        currentCard.cardFilterOption.lastTempValue.push(e.target.value);

        this.setState({
            currentKpiCard: currentCard,
            formErrors: {}
        });
    }

    handleCurrentKpiCardFilterDropdownChange(selectedOptions) {
        let optIDs = [];
        selectedOptions.forEach(opt => {
            optIDs.push(opt.id);
        });

        let currentCard = this.state.currentKpiCard;
        let tempCurrentKpiOpts = [];
        if (optIDs.length > 0) {
            optIDs.forEach(id => {
                for (let i = 0; i < currentCard.cardFilterOption.options.length; i++) {
                    if (id === currentCard.cardFilterOption.options[i].id) {
                        tempCurrentKpiOpts.push(currentCard.cardFilterOption.options[i]);
                        break;
                    }
                }
            });
        }

        currentCard.cardFilterOption.lastTempValue = [];
        currentCard.cardFilterOption.lastTempValue = tempCurrentKpiOpts;

        this.setState({
            currentKpiCard: currentCard,
            formErrors: {}
        });
    }


    handleCurrentKpiCardFilterDropdownInputChange = (inputValue) => {
        if (inputValue === "") return;
        let tempKPIOpts = [];
        let KPIOpts = this.state.currentKpiCard.cardFilterOption.options;
        let KPILastTempOpts = this.state.currentKpiCard.cardFilterOption.lastTempValue;
        let tempOPTIDs = [];
        KPILastTempOpts.forEach(opt => {
            tempOPTIDs.push(opt.id);
        });
        
        
        KPIOpts.forEach(opt=>{
            if(opt.selected === false && opt.value.toLowerCase().indexOf(inputValue.toLowerCase())>-1){
                tempKPIOpts.push(opt);
            } else if(opt.selected === true && opt.value.toLowerCase().indexOf(inputValue.toLowerCase())>-1 && !tempOPTIDs.includes(opt.id)){
                opt.selected = true;
                tempKPIOpts.push(opt);
            }
        });
        this.setState({unSelectedKPICardOptions: tempKPIOpts});
    };
    
    
    handleKpiChangeConfirm() {
        const {t} = this.props;
        let currentCard = this.state.currentKpiCard;
        let lastTempValueArray = [];
        if (currentCard.cardFilterOption.lastTempValue) {
            if (currentCard.cardFilterOption.lastTempValue[0] !== undefined && currentCard.cardFilterOption.lastTempValue[0] !== null) {
                lastTempValueArray = currentCard.cardFilterOption.lastTempValue;
            } else {
                lastTempValueArray[0] = "";
            }
        }

        if (currentCard.cardFilterOption !== null) {
            if (!currentCard.cardFilterOption.multiValueOption) {
                if (currentCard.filterLink === "%") {
                    if (lastTempValueArray[0] > currentCard.cardFilterOption.singleValueMax) {
                        this.setState({
                            formErrors: {"cardFilterOption": t('validation:numberMore', {input: currentCard.cardFilterOption.singleValueMax})}
                        });
                        return;
                    }
                }
                if (currentCard.filterLink === "threshold" || currentCard.filterLink === "%") {
                    if (isNaN(lastTempValueArray[0])) {
                        this.setState({
                            formErrors: {"cardFilterOption": t('validation:number')}
                        });
                        return;
                    }   else if ( currentCard.cardFilterOption.singleValueMin === 0 && lastTempValueArray[0]!== null &&  lastTempValueArray[0].startsWith("-")) {
                        this.setState({
                            formErrors: {"cardFilterOption": t('validation:numberLess', {input: 0})}
                        });
                        return;
                    } else if (lastTempValueArray[0] < currentCard.cardFilterOption.singleValueMin) {
                        this.setState({
                            formErrors: {"cardFilterOption": t('validation:numberLess', {input: currentCard.cardFilterOption.singleValueMin})}
                        });
                        return;
                    } else if (lastTempValueArray[0] !== null  && lastTempValueArray[0].length > 1&& lastTempValueArray[0].charAt(0) === '0') {
                        this.setState({
                            formErrors: {"cardFilterOption": t('validation:numberZero')}
                        });
                        return;
                    } else if (lastTempValueArray[0] === "") {
                        this.setState({
                            formErrors: {"cardFilterOption": t('validation:emptyValue')}
                        });
                        return;
                    }
                }
            } else if (currentCard.cardFilterOption.multiValueOption) {
                if (lastTempValueArray.length === 0 || lastTempValueArray.length === 1 && lastTempValueArray[0] === "") {
                    this.setState({
                        formErrors: {"cardFilterOption": t('validation:cardMultiSelect')}
                    });
                    return;
                }
            }
        }

        currentCard.cardFilterOption.lastSavedValue = lastTempValueArray;

        let tempAllKpi = [];
        this.state.allKpi.forEach(card => {
            if (Number(card.id) === Number(currentCard.id)) {
                card.cardFilterOption = currentCard.cardFilterOption;
            }
            tempAllKpi.push(card);
        });

        this.setState({
            allKpi: tempAllKpi
        });

        this.handleClose();
    }

    resetOptions = (event, filterName) => {
        switch(filterName) {
            case "kpiCardDropdown":   {
                let kpiForReset = this.state.unSelectedKPICardOptions;
                if(kpiForReset) kpiForReset = [];
                this.setState({
                    unSelectedKPICardOptions: kpiForReset
                });
            };
                break;
            default:  {
                console.warn("no match for KPI card options filter .")
            }
        }
    };

    handleSubmit(event) {
        event.preventDefault();
        this.setState({fetchInProgress: "true"});
        let itemsToSubmit = {};
        const selectedCards = this.state.allKpi.filter(card => card.selected === true);
        itemsToSubmit.cards = selectedCards.map(card => {
            const id = card.id;
            let filterValues = [];
            if (card.cardFilterOption) {
                if (!card.cardFilterOption.multiValueOption) {
                    filterValues.push({"filterValueA": card.cardFilterOption.lastSavedValue[0]})
                } else {
                    if (card.cardFilterOption.dependedOptionName) {
                        card.cardFilterOption.lastSavedValue.forEach(item => {
                            filterValues.push({"filterValueA": item.value, "filterValueB": item.dependedValue})
                        });
                    } else {
                        card.cardFilterOption.lastSavedValue.forEach(item => {
                            filterValues.push({"filterValueA": item.value})
                        });
                    }
                }
            }
            return {id, filterValues};
        });

        let selectedCardsWithoutValue = selectedCards
            .filter(card => card.cardFilterOption)
            .filter(card => card.cardFilterOption.lastSavedValue.length === 0)
            .map(kpi => kpi.id);

        if (selectedCardsWithoutValue.length > 0) {
            this.setState({fetchInProgress: "false"});
            this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'genericPleaseTryAgain',  "Selection could not be saved because a parameter was not set for a selected KPI Card.",null));
            return;
        }

        API.admin
            .post(`/admin/card/update`, itemsToSubmit)
            .then(resp => {
                const {data: resData} = resp;
                let inSupport = resData ? resData.inSupport : false;
                this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t,'changeSaved',  "KPI card"), inSupport);
                console.log(`KPI is updated! \n` +
                    JSON.stringify(itemsToSubmit)
                );
                this.setState({fetchInProgress: localStorage.get("isLoading")});
            })
            .catch(e => {
                console.error(e);
                this.setState({fetchInProgress: localStorage.get("isLoading")});
                if(localStorage.get("accessDenied401") === "true"){
                    this.setState({showLoggedoutModal:true});
                    return;
                }

                let resultCode = -1;
                if(e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout";

                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    }

    handleProjectReportsUpdate(label, isChecked) {
        let kpi = this.state.allKpi;
        const info = label.split(':');
        kpi = kpi.map((item) => {
            if (item.id.toString() === info[0]) {
                item.selected = isChecked;

                if (!isChecked) {
                    if (item.cardFilterOption) {
                        item.cardFilterOption.lastSavedValue = [];
                    }
                }
            }
            return item;
        });
        this.setState({
            allKpi: kpi
        })
    }

    componentWillUnmount() {
        gridInitialized = false;
    }

    generateCards() {
        const allKpi = this.state.allKpi;
        const handleProjectReportsUpdate = this.handleProjectReportsUpdate;
        const handleShow = this.handleShow;
        const trans = this.props.t;

        const cards = allKpi.map((card, index) => (
            <div key={index + 1}>
                <InfoSelectCard
                    key={index}
                    id={card.id}
                    isChecked={card.selected}
                    icon={{
                        name: card.icon,
                        status: "neutral"
                    }}
                    heading={{
                        text: FormatDataType(trans("kpiCard.footer:" + card.dataType.toLowerCase())),
                        color: "#9A9A9A"
                    }}
                    subHeading={{
                        text: FormatSubHeading(trans("kpiCard.name:" + card.title)),
                        color: "#23ccef"
                    }}
                    footerText={FormatFooter(card.id, card.info, card.selected, card.filterLink, (card.cardFilterOption !== null) ? card.cardFilterOption.lastSavedValue : "", handleShow, trans)}
                    handleProjectReportsUpdate={handleProjectReportsUpdate}
                    mainRowHeight={"110px"}
                />
            </div>
        ));

        return cards;
    }

    onLayoutChange() {
        setTimeout(function () {
            const event = document.createEvent("Event");
            event.initEvent("resize", false, true);
            window.dispatchEvent(event);
            //window.dispatchEvent(new Event('resize'))
        }, 200);
    }

    render() {
        if(this.state.sessionTerminated)
            return <Redirect to={{pathname: '/auth/logout'}}/>;
        
        let routes = mauiRoutes.getRoutes();
        const {t} = this.props;
        const footerObj = [];
        const updatePreferencesObj = {
            name: t("common:save"),
            callback: this.handleSubmit
        };
        footerObj.push(updatePreferencesObj);

        if (this.state.allKpi.length > 0) {
            if (!gridInitialized) {
                ResponsiveGridLayout = WidthProvider(Responsive);
                gridInitialized = true;
            }
        }

       let kpiCardFilterOptions = this.state.unSelectedKPICardOptions === undefined ? [] : this.state.unSelectedKPICardOptions;


        return (
            <form onSubmit={this.handleSubmit} noValidate>
                <div className="main-content card-kpi-container-main">
                    <Helmet>
                        <title>Knoa Analytics - KPI Cards</title>
                    </Helmet>
                    <Grid fluid>
                        <KnoaBreadcrumb
                            href="#/admin/AllSettings"
                            firstLevelText={t("common:settings")}
                            secondLevelText={(this.props.navVarBrand) ? this.props.navVarBrand : this.props.getActiveRoute(routes, t)}
                        />
                        <ModalDialog
                            showModal={this.state.showLoggedoutModal}
                            title={"Log Out"}
                            contentBody={
                                <span>{getInfoMessages(this.props.t, 'logoutfor401',null,null)}</span>
                            }
                            bodyPaddingBottom='15px'
                            footerBtnBsStyle="success"
                            footerBtnClass="KnoaSuccessButton"
                            footerBtnOnClick={this.handleLogoutConfirm}
                            footerBtnText={this.props.t('common:ok')}
                        />
                        {Object.keys(this.state.currentKpiCard).length > 0 &&
                        <ModalDialog
                            showModal={this.state.showModal}
                            title={t('dialog:kpiThresholdTitle')}
                            contentBody={
                                <>
                                    {!this.state.currentKpiCard.cardFilterOption.multiValueOption &&
                                    <Row className="knoaRow">
                                        <div className="col-md-5 card-kpi-container-main-row-single">
                                            <label
                                                className="card-kpi-container-main-row-span-single" htmlFor={this.state.currentKpiCard.filterLabel}> {this.state.currentKpiCard.filterLabel}</label>
                                        </div>
                                        <div className="col-md-5">
                                            <FormControl
                                                type="text"
                                                value={this.state.currentKpiCard.cardFilterOption.lastTempValue ? this.state.currentKpiCard.cardFilterOption.lastTempValue[0] : this.state.currentKpiCard.cardFilterOption.lastSavedValue[0]}
                                                placeholder={t('dialog:kpiPlaceHolder')}
                                                onChange={this.handleCurrentKpiCardFilterInputChange}
                                                name={this.state.currentKpiCard.filterLabel}
                                                id={this.state.currentKpiCard.filterLabel}
                                                aria-required="true"
                                            />
                                        </div>
                                    </Row>
                                    }
                                    {this.state.currentKpiCard.cardFilterOption.multiValueOption &&
                                    <Row>
                                        <Row>
                                            <Col className="card-kpi-container-main-row-multiple-label">
                                                <Row><label htmlFor={this.state.currentKpiCard.filterLabel} className="col-control-sr-label">{capitalizeWordsForOSLabel(this.state.currentKpiCard.filterLabel)}</label></Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="card-kpi-container-main-row-multiple-container">
                                                <CommonSelector
                                                    inputId = {this.state.currentKpiCard.filterLabel}
                                                    style={"typetosearch"}
                                                    componentLocation={"normal"}
                                                    isModal ={true}
                                                    placeholder={this.state.cardOptionFocused ? "" :t('common:typeToSearch')}
                                                    value={this.state.currentKpiCard.cardFilterOption.lastTempValue ? this.state.currentKpiCard.cardFilterOption.lastTempValue : this.state.currentKpiCard.cardFilterOption.lastSavedValue}
                                                    options={kpiCardFilterOptions}
                                                    getOptionLabel={(option) => option.dependedValue ? option.value + " - " + option.dependedValue : option.value}
                                                    getOptionValue={(option) => option.dependedValue ? option.value + " - " + option.dependedValue : option.value}
                                                    onChange={this.handleCurrentKpiCardFilterDropdownChange}
                                                    onInputChange={this.handleCurrentKpiCardFilterDropdownInputChange}
                                                    onMenuClose={(e) => this.resetOptions(e, "kpiCardDropdown")}
                                                    onFocus={(e) => {this.setState({ cardOptionFocused : true });}}
                                                    onBlur={(e) => {this.setState({ cardOptionFocused : false });}}
                                                    isClearable
                                                    isSearchable={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Row>
                                    }
                                </>
                            }
                            footerBtnBsStyle="success"
                            footerBtnClass="KnoaSuccessButton"
                            footerBtnOnClick={this.handleKpiChangeConfirm}
                            footerBtnText={t('common:confirm')}
                            footerBtnCloseText={t('common:cancel')}
                            handleClose={this.handleClose}
                            formErrors={this.state.formErrors}
                        />
                        }
                        {this.state.fetchInProgress === "true" && <Spinner/>}
                        <div hidden={this.state.fetchInProgress === "true" } className="knoa-region" tabIndex={"-1"}>
                            <Card
                                title={t("route.name:" + this.props.title)}
                                category={t("adminSettings:kpiCardsDescription")}
                                content={
                                    <Row className="cards-container gridAdjustRight">
                                        <ResponsiveGridLayout className="layout"
                                                              layouts={layoutsKpiSettings}
                                                              breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                                                              cols={{lg: 12, md: 9, sm: 9, xs: 4, xxs: 2}}
                                                              isDraggable={false}
                                                              isResizable={false}
                                                              onLayoutChange={this.onLayoutChange}
                                        >
                                            {this.generateCards()}
                                        </ResponsiveGridLayout>
                                    </Row>
                                }
                            />
                        </div>
                    </Grid>
                    <br/>
                </div>
                <Footer fluid footerObj={footerObj}/>
            </form>
        );
    }
}

export default withTranslation()(KpiContainer);