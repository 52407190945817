/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from "react";

import { API } from "lib/API";

import {Row} from "react-bootstrap";
import LinkCard from "../../../components/Card/LinkCard";
import PropTypes from "prop-types";
import {capitalizeWords} from "../../../lib/common";
import {withTranslation} from 'react-i18next';
import {Responsive, WidthProvider} from 'react-grid-layout';
import {layoutsAllReports} from "../../../lib/gridLayouts";
import {Redirect} from "react-router-dom";
import ModalDialog from "../../../components/common/ModalDialog";
import {getErrorMessagesFromCode, getErrorMsgFromStatus, getInfoMessages} from "../../../lib/messageHandler";
import {mauiRoutes} from "../../../routes";
import {Helmet} from "react-helmet";
const localStorage = require('../../../lib/localStorage');

const onLayoutChange = () => {
  setTimeout(function(){
    const event = document.createEvent("Event");
    event.initEvent("resize",false,true);
    window.dispatchEvent(event);
    //window.dispatchEvent(new Event('resize'))
  }, 200);
};

const LinkCards = props => {
    const {loaded, groupName, workbooks, icon, trans} = props;
    if(loaded) {
        const ResponsiveGridLayout = WidthProvider(Responsive);
        const cards = workbooks.map((row, index) => {
            const footerText = row.description;
            const subHeadingText = row.dualPeriod ? 'dualPeriod' : 'singlePeriod';
            return (
              <div key={index + 1}>
                <LinkCard
                    key={index}
                    icon={{
                        name: icon,
                        status: "neutral"
                    }}
                    subHeading={{
                        text: trans('common:'+subHeadingText),
                        color:"#595959"
                    }}
                    heading={{
                        text: trans('report.name:'+capitalizeWords(row.workbookName)),
                        color: "#23ccef"
                    }}
                    path={{
                      subHeading: capitalizeWords(groupName),
                      heading: row.workbookName
                    }}
                    footerText={trans('kpiCard.footer:'+footerText)}
                />
              </div>
            );
        });
        return (
          <ResponsiveGridLayout className="layout"
                                layouts={layoutsAllReports}
                                breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                                cols={{lg: 12, md: 9, sm: 6, xs: 4, xxs: 2}}
                                isDraggable={false}
                                isResizable={false}
                                containerPadding={[15,0]}
                                onLayoutChange={onLayoutChange}
          >
          {cards}
          </ResponsiveGridLayout>
        );
    }
    return <Row></Row>;
};

const Category = props => {
    const {loaded, groupName, categories, icon, trans} = props;
    if(loaded) {
        const rows = categories.map((row, index) => {
            return (
                <div key={index}>
                    <h4 className="title gridAdjustLeft knoa-region" tabIndex={"-1"}>
                        {trans('report.category:'+row.categoryName)}
                    </h4>
                    <LinkCards loaded={loaded} groupName={groupName} workbooks={row.workbooks} icon={icon} trans={trans}/>
                </div>
            );
        });
        return rows;
    }
    return <Row></Row>;
};

class AllReports extends Component {
    constructor(prop) {
        super(prop);
        const path = this.props.location.pathname.split('/');
        this.mainContent = React.createRef();
        this.state = {
            path : this.props.location.pathname,
            categories: PropTypes.array,
            reportGroup : path[path.length-2],
            loaded: false,
            showLoggedoutModal: false,
            sessionTerminated: false
        };
    }

    handleLogoutConfirm = () =>{
        this.setState({sessionTerminated: true});
    };

    
    loadData() {
        API.report
            .get(`/report/all/${this.state.reportGroup}`)
            .then(resp => {
                const { data: resData } = resp;
                const { data } = resData;
                this.setState({
                  categories: data.categories,
                  icon: data.icon,
                  loaded: true
                });
            })
            .catch(e => {
                console.error(e);
                if(localStorage.get("accessDenied401") === "true"){
                    this.setState({showLoggedoutModal:true});
                    return;
                }
                let resultCode = -1;
                if(e.data) {
                    resultCode = e.data.resultCode;
                }
                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.mainContent && this.mainContent.current) {
            this.mainContent.current.focus();
        }
    }

  render() {
      if(this.state.sessionTerminated)
          return <Redirect to={{pathname: '/auth/logout'}}/>;

      let showLoggedOutWindow = this.state.showLoggedoutModal;

      if (!this.state.loaded && !showLoggedOutWindow) {
          return null;
      }

      let routes = mauiRoutes.getRoutes();
      const {t, navVarBrand, getActiveRoute} = this.props;

      return  (
          <div className="main-content" ref={this.mainContent} tabIndex={"-1"}>
              <Helmet>
                  <title>Knoa Analytics - {(navVarBrand) ? navVarBrand : getActiveRoute(routes, t)}</title>
              </Helmet>
              <ModalDialog
                  showModal={showLoggedOutWindow}
                  title={"Log Out"}
                  contentBody={
                      <span>{getInfoMessages(this.props.t, 'logoutfor401',null,null)}</span>
                  }
                  bodyPaddingBottom='15px'
                  footerBtnBsStyle="success"
                  footerBtnClass="KnoaSuccessButton"
                  footerBtnOnClick={this.handleLogoutConfirm}
                  footerBtnText={this.props.t('common:ok')}
              />
              <Category loaded={this.state.loaded} groupName={this.state.reportGroup} categories={this.state.categories} icon={this.state.icon} trans={this.props.t}/>
          </div>
      );
  }
}

export default withTranslation()(AllReports);