//return different type of messages basing on different scenarios

//type 1: success type
export function getSuccessMessages(translator, operation, parameter) {
    if (parameter !== null)
        return translator('messages.success:' + operation, {item: parameter});
    else
        return translator('messages.success:' + operation);
}

//type 2: warning type
export function getWarningMessages(translator, operation, parameter) {
    if (parameter !== null)
        return translator('messages.warning:' + operation, {item: parameter});
    else
        return translator('messages.warning:' + operation);
}

//type 3: info type
export function getInfoMessages(translator, operation, parameterName, parameterValue) {
    if (parameterName !== null) {
        let tempObj = {};
        tempObj[parameterName] = parameterValue;
        return translator('messages.info:' + operation, tempObj);
    } else
        return translator('messages.info:' + operation);
}

//type 4: error type: result code(some of the exception code that the UI does not use, will handle it in a generic way: contact your administrator)
export function getErrorMessagesFromCode(translator, resultCode, isNormal) { //basing on the knoaAPidResult server class
    if (isNormal) {
        switch (resultCode) {
            case 1005:
                return translator('messages.error:generic', {item: 'proxyUserNoPermission'}) + " Error code: " + resultCode;
            case 2004:
                return translator('messages.error:generic', {item: 'There are not enough licenses for this role.'}) + " Error code: " + resultCode;
            case 2019:
                return translator('messages.error:generic', {item: 'InvalidMinimumPasswordLength'}) + " Error code: " + resultCode;
            case 3001:
                return translator('messages.error:genericPleaseTryAgain', {item: 'The email address already exists!'}) + " Error code: " + resultCode;
            case 3010:
                return translator('messages.error:codeRequestFailed') + " Error code: " + resultCode;
            case 3013:
                return translator('messages.error:generic', {item: 'wrongPassword'}) + " " + translator('messages.common:error') + ": " + resultCode;
            case 3017:
                return translator('messages.error:generic', {item: 'passwordHistory'}) + " Error code: " + resultCode;
            case 7006:
                return translator('messages.error:genericPleaseTryAgain', {item: 'Selection could not be saved because a parameter was not set for a selected KPI Card.'}) + " Error code: " + resultCode;
            case 8002:
                return translator('messages.error:general', {item: resultCode});
            default:
                return translator('messages.error:generic', {item: 'Please contact your administrator.'}) + " Error code: " + resultCode;
        }
    } else {
        switch (resultCode) {
            case 2004:
                return translator('messages.error:generic', {item: 'There are not enough licenses for this role.'}) + " Error code: " + resultCode;
            case 3001:
                return translator('messages.error:genericPleaseTryAgain', {item: 'The email address already exists!'}) + " Error code: " + resultCode;
            case 3010:
                return translator('messages.error:codeRequestFailed') + " Error code: " + resultCode;
            case 3013:
                return translator('messages.error:incorrectPassword') + " " + translator('messages.common:error') + ": " + resultCode;
            case 3017:
                return translator('messages.error:passwordHistoryError') + " " + translator('messages.common:error') + ": " + resultCode;
            case 5001: 
                return translator('messages.error:projectNameAlreadyExists') + " " + translator('messages.common:error') + ": " + resultCode;
            case 7006:
                return translator('messages.error:genericPleaseTryAgain', {item: 'Selection could not be saved because a parameter was not set for a selected KPI Card.'}) + " Error code: " + resultCode;
            case 8002:
                return translator('messages.error:general', {item: resultCode});
            default:
                return translator('messages.error:generic', {item: 'Please contact your administrator.'}) + " Error code: " + resultCode;
        }
    }
}

export function getErrorMsgFromStatus(translator, e, isNormal) {
    if (isNormal) {
        if (e && e.toString().includes('Request failed with status code 500'))
            return translator('messages.error:general', {item: "500"});
        else if (e.status)
            return translator('messages.error:general', {item: e.status});
        else return translator('messages.error:generic', {item: 'Please contact your administrator.'});
    } else {
        return translator('messages.error:requestCouldProcess');
    }

}

export function getGenericServerErrorMsg(translator) {
    return translator('messages.error:generic', {item: 'Please contact your administrator.'});
}

export function getSpecificOPErrorMessages(translator, operation, parameter, resultCode) {
    if (operation === "FAILED_TFA_INVALID_ACCESS_TOKEN")
        return translator('messages.error:failedTfaInvalidAccessToken');
    else if (operation === "FAILED_TFA_INVALID_TOKEN")
        return translator('messages.error:failedTfaInvalidToken');
    else if (operation === "FAILED_TFA_TOKEN_EXPIRED")
        return translator('messages.error:failedTfaTokenExpired');
    else if (operation === "INVALID_PARAMETER")
        return translator('messages.error:invalidParameter');
    else if (operation === "FAILED_EXPORT_ENCODING")
        return translator('messages.error:invalidEncoding');
    else {
        let temp;
        if (parameter !== null)
            temp = translator('messages.error:' + operation, {item: parameter});
        else
            temp = translator('messages.error:' + operation);

        if (resultCode !== null) return temp + " Error code: " + resultCode;
        else return temp;
    }
}



