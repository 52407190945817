import React, {Component} from "react";
import {Grid} from "react-bootstrap";
import UsersContainer from "../../../components/user/UsersContainer.jsx";
import {API} from "../../../lib/API";
import ModalDialog from "../../../components/common/ModalDialog";
import KnoaBreadcrumb from "../../../components/common/KnoaBreadcrumb";
import {mauiRoutes} from "../../../routes";
import {withTranslation} from 'react-i18next';
import Spinner from "../../../components/common/Spinner";
import {Redirect} from "react-router-dom";
import {getErrorMessagesFromCode, getErrorMsgFromStatus, getInfoMessages, getSuccessMessages} from "../../../lib/messageHandler";
import {Helmet} from "react-helmet";

const localStorage = require('../../../lib/localStorage');

class AdminUserPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allUsers: [],
            showModal: false,
            currentUser: {},
            totalLicences: 0,
            fetchInProgress: "false",
            showLoggedoutModal: false,
            sessionTerminated: false,
            showUnlockConfirm: false
        };

        this.handleShow = this.handleShow.bind(this);
        this.handleDeleteUser = this.handleDeleteUser.bind(this);
        this.handleUnlockUser = this.handleUnlockUser.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleShow(e, id) {
        e.preventDefault();
        const info = id.split("-");
        const cUser = {};
        cUser.userId = info[0];
        cUser.fullName = info[1];
        this.setState({
            currentUser: cUser,
            showModal: true
        });
    }

    handleUnlockWindow = (e, user) => {
        e.preventDefault();
        const info = user.split("-");
        const cUser = {};
        cUser.userId = info[0];
        cUser.fullName = info[1];
        this.setState({
            currentUser: cUser,
            showUnlockConfirm: true
        });
    };

    handleUnlockUser = () => {

        this.setState({fetchInProgress: "true"});
        let _this = this;
        console.log(`/admin/user/lock/${_this.state.currentUser.userId}`);

        let userLockParam = {};
        userLockParam.lock = false;
        API.admin
            .post(`/admin/user/lock/${_this.state.currentUser.userId}`, userLockParam)
            .then(resp => {
                    let inSupport = (resp && resp.data) ? resp.data.inSupport : false;
                    this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t, 'generic', "The user was unlocked!"), inSupport);

                    console.log(`The User ${_this.state.currentUser.fullName} was unlocked!`);
                    let updatedAllUsers = [];
                    _this.state.allUsers.forEach(user => {
                        if (user.userId.toString() === _this.state.currentUser.userId) {
                            user.lock = false;
                        }
                        updatedAllUsers.push(user);
                    });

                    _this.setState({
                        showUnlockConfirm: false,
                        allUsers: updatedAllUsers,
                        fetchInProgress: localStorage.get("isLoading")
                    });

                }
            )
            .catch(e => {
                console.error(`The User ${_this.state.currentUser.fullName} was not unlocked successfully! ` + e);
                this.setState({fetchInProgress: localStorage.get("isLoading")});
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }
                let resultCode = -1;
                if (e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"
                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));

            })

    }

    handleDeleteUser() {
        this.setState({fetchInProgress: "true"});
        let _this = this;
        API.admin
            .delete(`/admin/user/delete/${_this.state.currentUser.userId}`)
            .then(resp => {
                let inSupport = (resp && resp.data) ? resp.data.inSupport : false;
                this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t, "generic","The user was deleted!"), inSupport);

                console.log(`The User ${_this.state.currentUser.fullName} is deleted!`);
                const updatedAllUsers = _this.state.allUsers.filter(user => user.userId.toString() !== _this.state.currentUser.userId);
                _this.setState({
                    currentUser: {},
                    showModal: false,
                    allUsers: updatedAllUsers,
                    fetchInProgress: localStorage.get("isLoading")
                });
            })
            .catch(e => {
                // only log error for now
                console.error(e);
                this.setState({fetchInProgress: localStorage.get("isLoading")});
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }
                let resultCode = -1;
                if (e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"

                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));

            })
    }


    handleClose() {
        this.setState({showModal: false,
        });
    }

    handleLogoutConfirm = () => {
        this.setState({sessionTerminated: true});
    };
    
    handleUnlockClose = () => {
        this.setState({
            showUnlockConfirm: false,
        });
    }

    componentDidMount() {
        API.admin
            .get("/admin/user/list")
            .then(resp => {
                const {data} = resp.data;
                this.setState({
                    allUsers: data.users,
                    totalLicences: data.licenses.map(x => x.total).reduce((x, y) => x + y),
                });
            })
            .catch(e => {
                console.error(e);
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }
                let resultCode = -1;
                if (e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"
                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState({allUsers: [],});
    }


    render() {
        if (this.state.sessionTerminated)
            return <Redirect to={{pathname: '/auth/logout'}}/>;
        let routes = mauiRoutes.getRoutes();
        const {t} = this.props;
        return (
            <div className="main-content">
                <Helmet>
                    <title>Knoa Analytics - User Management</title>
                </Helmet>
                <Grid fluid>
                    <KnoaBreadcrumb
                        href="#/admin/AllSettings"
                        firstLevelText={t("common:settings")}
                        secondLevelText={(this.props.navVarBrand) ? this.props.navVarBrand : this.props.getActiveRoute(routes, t)}
                    />
                    <ModalDialog
                        showModal={this.state.showLoggedoutModal}
                        title={"Log Out"}
                        contentBody={
                            <span>{getInfoMessages(this.props.t, 'logoutfor401',null,null)}</span>
                        }
                        bodyPaddingBottom='15px'
                        footerBtnBsStyle="success"
                        footerBtnClass="KnoaSuccessButton"
                        footerBtnOnClick={this.handleLogoutConfirm}
                        footerBtnText={this.props.t('common:ok')}
                    />
                    <ModalDialog
                        showModal={this.state.showModal}
                        title={t("dialog:deleteUserTitle")}
                        contentBody={<span>{t('dialog:deleteUserPrompt', {user: this.state.currentUser.fullName})}</span>}
                        bodyPaddingBottom='15px'
                        footerBtnBsStyle="danger"
                        footerBtnClass="KnoaDangerButton"
                        footerBtnOnClick={this.handleDeleteUser}
                        footerBtnText={this.props.t('common:delete')}
                        footerBtnCloseText={this.props.t('common:cancel')}
                        handleClose={this.handleClose}
                    />
                    <ModalDialog
                        showModal={this.state.showUnlockConfirm}
                        title={this.props.t('user:unlockAccount')}
                        contentBody={<span>{t('dialog:unlockUserPrompt', {user: this.state.currentUser.fullName})}</span>}
                        bodyPaddingBottom='15px'
                        footerBtnBsStyle="danger"
                        footerBtnClass="KnoaDangerButton"
                        footerBtnOnClick={this.handleUnlockUser}
                        footerBtnText={this.props.t('common:unlock')}
                        footerBtnCloseText={this.props.t('common:cancel')}
                        handleClose={this.handleUnlockClose}
                    />
                    {this.state.fetchInProgress === "true" && <Spinner/>}
                    <div hidden={this.state.fetchInProgress === "true"}>
                        <UsersContainer
                            title={t("report.name:Users")}
                            allUsers={this.state.allUsers}
                            allLicenses={this.state.totalLicences}
                            handleShow={this.handleShow}
                            handleUnlockWindow={this.handleUnlockWindow}
                            translate={t}
                        />
                    </div>
                </Grid>
            </div>
        );
    }
}

export default      withTranslation()(AdminUserPage);