import React, {Component} from "react";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import {API} from "../../../lib/API";
import KnoaBreadcrumb from "../../../components/common/KnoaBreadcrumb";
import {mauiRoutes} from "../../../routes";
import {withTranslation} from 'react-i18next';
import Spinner from "../../../components/common/Spinner";
import FormInputs from "../../../components/creativeTim/FormInputs";
import Card from "../../../components/Card/Card";
import CommonContainer from "../../../components/common/CommonContainer";
import Footer from "../../../components/layout/Footer";
import Switch from "react-bootstrap-switch";
import {Redirect} from "react-router-dom";
import ModalDialog from "../../../components/common/ModalDialog";
import {
    getErrorMessagesFromCode,
    getErrorMsgFromStatus, getGenericServerErrorMsg,
    getInfoMessages,
    getSpecificOPErrorMessages,
    getSuccessMessages
} from "../../../lib/messageHandler";
import {Helmet} from "react-helmet";

const localStorage = require('../../../lib/localStorage');
const FormErrors = ({formErrors, fieldName}) =>
    <div className='row formErrors'>
        <p className="form-validation-error" aria-label={formErrors[fieldName]}> {formErrors[fieldName]}</p>
    </div>

class AdminSecuritySetting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loginAttempt: "",
            lockDuration: "",
            loginDuration: "",
            history: "",
            lifetime: "",
            minLifetime: "",
            minLength: "",
            tfaEnabled: false,
            fetchInProgress: "false",
            inputClass_loginAttempt: 'form-control form-control-border-preferedpram',
            inputClass_lockDuration: 'form-control  form-control-border-preferedpram',
            inputClass_loginDuration: 'form-control  form-control-border-preferedpram',
            inputClass_history: 'form-control form-control-border-preferedpram',
            inputClass_lifetime: 'form-control  form-control-border-preferedpram',
            inputClass_minLifetime: 'form-control  form-control-border-preferedpram',
            inputClass_minLength: 'form-control  form-control-border-preferedpram',
            formErrors: {},
            showLoggedoutModal: false,
            sessionTerminated: false,
            showResetConfirm: false
        };

    }

    handleFailedLoginAttemptsInputChange = (e) => {
        this.setState({loginAttempt: e.target.value});
    };

    handleFailedLoginDurationInputChange = (e) => {
        this.setState({loginDuration: e.target.value});
    };

    handleLockedAccountDurationInputChange = (e) => {
        this.setState({lockDuration: e.target.value});
    };

    handleHistoryInputChange = (e) => {
        this.setState({history: e.target.value});
    };

    handleLifetimeInputChange = (e) => {
        this.setState({lifetime: e.target.value});
    };

    handleMinLifetimeInputChange = (e) => {
        this.setState({minLifetime: e.target.value});
    };

    handleMinLengthInputChange = (e) => {
      this.setState({minLength: e.target.value});
    };

    handleTFACheckboxUpdate = (elem, tfaEnabled) => {
        this.setState({tfaEnabled: tfaEnabled});
    };
    
    handleLogoutConfirm = () =>{
        this.setState({sessionTerminated: true});
    };


    componentDidMount() {
        const _this = this;

        let tempLoginState = {};
        let tempPasswordState = {};
        API.admin
            .get("/admin/setting/userLock")
            .then(resp => {
                const {data} = resp.data;
                tempLoginState.loginAttempt = data.loginAttempt;
                tempLoginState.lockDuration = data.lockDuration;
                tempLoginState.loginDuration = data.loginDuration;

                API.admin
                    .get("/admin/setting/password")
                    .then(resp => {
                        const {data} = resp.data;
                        tempPasswordState.history = data.history;
                        tempPasswordState.lifetime = data.lifetime;
                        tempPasswordState.minLifetime = data.minLifetime;
                        tempPasswordState.minLength = data.minLength;

                        API.admin
                            .get("/admin/setting/tfa")
                            .then(resp => {
                                const {data} = resp.data;

                                _this.setState({
                                    loginAttempt: tempLoginState.loginAttempt,
                                    lockDuration: tempLoginState.lockDuration,
                                    loginDuration: tempLoginState.loginDuration,
                                    history: tempPasswordState.history,
                                    lifetime: tempPasswordState.lifetime,
                                    minLifetime: tempPasswordState.minLifetime,
                                    minLength: tempPasswordState.minLength,
                                    tfaEnabled: data.enabled
                                });
                            })
                            .catch(e => {
                                console.error(e);
                                if(localStorage.get("accessDenied401") === "true"){
                                    this.props.handleAlertClick("error", "tc", getInfoMessages(this.props.t, 'logoutfor401',null,null));
                                    setTimeout(
                                        function () {
                                            window.location.href = "/#/auth/logout"
                                        }.bind(this),
                                        3000
                                    );
                                    return;
                                }
                                let resultCode = -1;
                                if(e.data) {
                                    resultCode = e.data.resultCode;
                                }
                                if (resultCode > -1)
                                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                                else
                                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
                            });

                    })
                    .catch(e => {
                        console.error(e);
                        if(localStorage.get("accessDenied401") === "true"){
                            this.setState({showLoggedoutModal:true});
                            return;
                        }
                        let resultCode = -1;
                        if(e.data) {
                            resultCode = e.data.resultCode;
                        }
                        if (resultCode > -1)
                            this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                        else
                            this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
                    });
            })
            .catch(e => {
                console.error(e);
                if(localStorage.get("accessDenied401") === "true"){
                    this.setState({showLoggedoutModal:true});
                    return;
                }
                let resultCode = -1;
                if(e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"

                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    }


    submitData(type) {
        let _this = this;
       
        _this.setState({fetchInProgress: "true"});

        API.user
            .post('/admin/setting/userLock', {
                loginAttempt: type==="save"? this.state.loginAttempt: 3,
                lockDuration: type==="save"? this.state.lockDuration : 30,
                loginDuration: type==="save"? this.state.loginDuration :5
            })
            .then(resp => {
                const {resultCode} = resp.data;

                if (resultCode === 200) {
                    API.user
                        .post('/admin/setting/password', {
                            history: type==="save" ? this.state.history : 5,
                            lifetime: type==="save" ? this.state.lifetime : 90,
                            minLifetime: type==="save" ? this.state.minLifetime : 0,
                            minLength: type==="save" ? this.state.minLength : 8
                        })
                        .then(resp => {
                            const {resultCode} = resp.data;
                            
                            if (resultCode === 200) {
                                API.user
                                    .post('/admin/setting/tfa', {
                                        enabled: type==="save"? this.state.tfaEnabled : false
                                    })
                                    .then(resp => {
                                        const {resultCode} = resp.data;

                                        if (resultCode === 200) {
                                            let inSupport = resp.data ? resp.data.inSupport : false;
                                            if(type==="reset") //static default values referring to http://knoabi.helpdocsonline.com/settings$Security%20Settings
                                                this.setState({
                                                    loginAttempt: 3,
                                                    lockDuration: 30,
                                                    loginDuration: 5,
                                                    history: 5,
                                                    lifetime: 90,
                                                    minLifetime: 0,
                                                    minLength: 8,
                                                    tfaEnabled: false
                                                });
                                            
                                            type==="save"? this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t, 'changeSaved', "securitySettings"), inSupport) :                 
                                                this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t, 'valuesResetToDefault', null), inSupport);
                                        
                                        } else {
                                            type==="save"? this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'changeNotSaved',  "securitySettings", resultCode)):
                                                this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'resetNotSuccess',  "securitySettings", resultCode));
                                        }
                                        _this.setState({fetchInProgress: localStorage.get("isLoading")});
                                    })
                                    .catch(e => {
                                        console.error(e);
                                        _this.setState({fetchInProgress: localStorage.get("isLoading")});
                                        if(localStorage.get("accessDenied401") === "true"){
                                            this.setState({showLoggedoutModal:true});
                                            return;
                                        }
                                        let resultCode = -1;
                                        if(e.data) {
                                            resultCode = e.data.resultCode;
                                        }
                                        if (resultCode > -1)
                                            this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                                        else
                                            this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
                                    });
                            }
                        })
                        .catch(e => {
                            console.error(e);
                            _this.setState({fetchInProgress: localStorage.get("isLoading")});

                            if(localStorage.get("accessDenied401") === "true"){
                                this.setState({showLoggedoutModal:true});
                                return;
                            }
                            let resultCode = -1;
                            if(e.data) {
                                resultCode = e.data.resultCode;
                            }
                            if (resultCode > -1)
                                this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                            else
                                this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
                        });

                }
            })
            .catch(e => {
                console.error(e);
                _this.setState({fetchInProgress: localStorage.get("isLoading")});

                if(localStorage.get("accessDenied401") === "true"){
                    this.setState({showLoggedoutModal:true});
                    return;
                }
                if (e.message && e.message.includes("Cannot read property 'data' of undefined")) {
                    this.props.handleAlertClick("error", "tc", getGenericServerErrorMsg(this.props.t));
                } else {
                    let resultCode = -1;
                    if(e.data) {
                        resultCode = e.data.resultCode;
                    }
                    if (e.status === 401 || resultCode === 6007)
                        window.location.href = "/#/auth/logout"

                    if (resultCode > -1)
                        this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                    else
                        this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
                }
            });

    }


    handleSubmit = (event) => {
        event.preventDefault();
        let failedLoginAttemptsObj = {};
        failedLoginAttemptsObj.name = "loginAttempt";
        failedLoginAttemptsObj.value = this.state.loginAttempt;

        let failedLoginDurationObj = {};
        failedLoginDurationObj.name = "loginDuration";
        failedLoginDurationObj.value = this.state.loginDuration;

        let lockedAccountDurationObj = {};
        lockedAccountDurationObj.name = "lockDuration";
        lockedAccountDurationObj.value = this.state.lockDuration;

        let historyObj = {};
        historyObj.name = "history";
        historyObj.value = this.state.history;

        let lifetimeObj = {};
        lifetimeObj.name = "lifetime";
        lifetimeObj.value = this.state.lifetime;

        let minLifetimeObj = {};
        minLifetimeObj.name = "minLifetime";
        minLifetimeObj.value = this.state.minLifetime;

        let minLengthObj = {};
        minLengthObj.name = "minLength";
        minLengthObj.value = this.state.minLength;

        let latestSecuritySettingParams = [];
        let errors = [];
        latestSecuritySettingParams.push(failedLoginAttemptsObj);
        latestSecuritySettingParams.push(failedLoginDurationObj);
        latestSecuritySettingParams.push(lockedAccountDurationObj);
        latestSecuritySettingParams.push(historyObj);
        latestSecuritySettingParams.push(lifetimeObj);
        latestSecuritySettingParams.push(minLifetimeObj);
        latestSecuritySettingParams.push(minLengthObj);

        latestSecuritySettingParams.forEach(paramObj => {
            let currentParamValue = paramObj.value;
            let currentParamName = paramObj.name;
            let currentErrorObj = {};
            if (currentParamValue === "") {
                currentErrorObj["field"] = currentParamName;
                currentErrorObj["message"] = this.props.t('validation:emptyValue');
            } else if (isNaN(currentParamValue)) {
                currentErrorObj["field"] = currentParamName;
                currentErrorObj["message"] = this.props.t('validation:number');
            } else if ((currentParamName === "lifetime" || currentParamName === "minLifetime" || currentParamName === "minLength") && ((currentParamValue!==null && currentParamValue.toString().startsWith("-")) || Number(currentParamValue) < 0)) {
                currentErrorObj["field"] = currentParamName;
                currentErrorObj["message"] = this.props.t('validation:numberLess', {input: "0"});
            } else if (currentParamName === "minLength" && Number(currentParamValue) < 8) {
                currentErrorObj["field"] = currentParamName;
                currentErrorObj["message"] = this.props.t('validation:numberLess', {input: "8"});
            }else if ((currentParamName !== "lifetime" && currentParamName !== "minLifetime") && Number(currentParamValue) < 1) {
                currentErrorObj["field"] = currentParamName;
                currentErrorObj["message"] = this.props.t('validation:numberLess', {input: "1"});
            } else if (currentParamValue !== null && currentParamValue.length > 1 && currentParamValue.charAt(0) === '0') {
                currentErrorObj["field"] = currentParamName;
                currentErrorObj["message"] = this.props.t('validation:numberZero');
            }

            if (!(Object.keys(currentErrorObj).length === 0 && currentErrorObj.constructor === Object)) {
                errors.push(currentErrorObj);
            }
        });

        if (errors.length === 0) {
            this.submitData('save');
            this.setState({
                formErrors: '',
                inputClass_loginAttempt: 'form-control form-control-border-preferedpram',
                inputClass_lockDuration: 'form-control  form-control-border-preferedpram',
                inputClass_loginDuration: 'form-control  form-control-border-preferedpram',
                inputClass_history: 'form-control form-control-border-preferedpram',
                inputClass_lifetime: 'form-control  form-control-border-preferedpram',
                inputClass_minLifetime: 'form-control  form-control-border-preferedpram',
                inputClass_minLength: 'form-control  form-control-border-preferedpram'
            });

        } else {
            const formattedErrors = {};
            const errorFields = [];
            const allFields = ['inputClass_loginAttempt', 'inputClass_lockDuration', 'inputClass_loginDuration', 'inputClass_history', 'inputClass_lifetime', 'inputClass_minLifetime', 'inputClass_minLength'];
            errors.forEach(error => {
                formattedErrors[error.field] = error.message;
                errorFields.push('inputClass_' + error.field);
                if (error.field === 'loginAttempt') {
                    this.setState({inputClass_loginAttempt: 'form-control form-validation-input-error'});
                } else if (error.field === 'lockDuration') {
                    this.setState({inputClass_lockDuration: 'form-control form-validation-input-error'});
                } else if (error.field === 'loginDuration') {
                    this.setState({inputClass_loginDuration: 'form-control form-validation-input-error'});
                } else if (error.field === 'history') {
                    this.setState({inputClass_history: 'form-control form-validation-input-error'});
                } else if (error.field === 'lifetime') {
                    this.setState({inputClass_lifetime: 'form-control form-validation-input-error'});
                } else if (error.field === 'minLifetime') {
                    this.setState({inputClass_minLifetime: 'form-control form-validation-input-error'});
                } else if (error.field === 'minLength') {
                    this.setState({inputClass_minLength: 'form-control form-validation-input-error'});
                }
            });
            allFields.filter((field) => {
                if (!errorFields.includes(field)) {
                    this.setState({[field]: 'form-control'});
                }
            });
            this.setState({formErrors: formattedErrors});
            this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t, 'validationErrorMessage',null,null ));
        }
    };

    handleResetClick = (e) => {
        e.preventDefault();
        this.setState({
            showResetConfirm: true
        });
    };

    handleResetClose = () => {
        this.setState({
            showResetConfirm: false,
        });
    };

    handleResetConfirm = () => {
        this.setState({
            showResetConfirm: false,
            // loginAttempt: 3,
            // lockDuration: 5,
            // loginDuration: 30,
            // history: 5,
            // lifetime: 90,
            // minLifetime: 0,
            // minLength: 8,
            // tfaEnabled: false
        });
        this.submitData('reset');
    };
    

    render() {
        if(this.state.sessionTerminated)
            return <Redirect to={{pathname: '/auth/logout'}}/>;
        
        let routes = mauiRoutes.getRoutes();
        const {t} = this.props;

        const footerObj = [];
        const updateSecuritySettingObj = {
            name: t('common:save'),
            callback: this.handleSubmit
        };
        footerObj.push(updateSecuritySettingObj);

        const resetSecuritySettingObj = {
            name: t('common:reset'),  
            callback: this.handleResetClick
        };
        footerObj.push(resetSecuritySettingObj);

        return (
            <div className="main-content">
                <Helmet>
                    <title>Knoa Analytics - Security Settings</title>
                </Helmet>
                <Grid fluid>
                    <KnoaBreadcrumb
                        href="#/admin/AllSettings"
                        firstLevelText={t("common:settings")}
                        secondLevelText={(this.props.navVarBrand) ? this.props.navVarBrand : this.props.getActiveRoute(routes, t)}
                    />
                    <ModalDialog
                        showModal={this.state.showLoggedoutModal}
                        title={"Log Out"}
                        contentBody={
                            <span>{getInfoMessages(this.props.t, 'logoutfor401',null,null)}</span>
                        }
                        bodyPaddingBottom='15px'
                        footerBtnBsStyle="success"
                        footerBtnClass="KnoaSuccessButton"
                        footerBtnOnClick={this.handleLogoutConfirm}
                        footerBtnText={this.props.t('common:ok')}
                    />
                    <ModalDialog
                        showModal={this.state.showResetConfirm}
                        title={this.props.t('dialog:resetToDefaultSettings')}
                        contentBody={<span>{this.props.t('dialog:resetToDefaultSettingsConfirm')}</span>}
                        bodyPaddingBottom='15px'
                        footerBtnBsStyle="danger"
                        footerBtnClass="KnoaDangerButton"
                        footerBtnOnClick={this.handleResetConfirm}
                        footerBtnText={this.props.t('common:yes')}
                        footerBtnCloseText={this.props.t('common:no')}
                        handleClose={this.handleResetClose}
                    />
                    <form onSubmit={this.handleSubmit} noValidate>
                        <div className="main-content main-content-page-with-footer-login-management">
                            {this.state.fetchInProgress === "true" && <Spinner/>}
                            <div hidden={this.state.fetchInProgress === "true"}>
                                <CommonContainer
                                    class="common-page-content-row"
                                    content={
                                        <Col>
                                            <div role="group" aria-labelledby="LockAccountPolicy" className="knoa-region" tabIndex={"-1"}>
                                            <Card
                                                id="LockAccountPolicy"
                                                title={t('adminSettings:lockedAccountPolicy')}
                                                category={""}
                                                content={
                                                    <div className="KnoaFilterContainer">
                                                        <FormGroup
                                                            controlId="formlockedAccountPolicy"
                                                        >
                                                            <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                     sm={1}>
                                                                    {t('adminSettings:failedLoginAttempts')}
                                                                    <span className="text-danger-1">*</span>
                                                                </Col>
                                                                <Col sm={1} className="card-row-col-right">
                                                                    <FormInputs
                                                                        ncols={["col-md-12 preference-preferred-pram"]}
                                                                        proprieties={[
                                                                            {
                                                                                type: "text",
                                                                                name: "loginAttempt",
                                                                                bsClass: this.state.inputClass_loginAttempt,
                                                                                placeholder: "",
                                                                                value: this.state.loginAttempt,
                                                                                onChange: this.handleFailedLoginAttemptsInputChange,
                                                                                required: true
                                                                            }
                                                                        ]}
                                                                    />
                                                                    <div className="col-md-12">
                                                                        <FormErrors formErrors={this.state.formErrors}
                                                                                    fieldName="loginAttempt"/>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                     sm={1}>
                                                                    {t('adminSettings:failedLoginDuration')}
                                                                    <span className="text-danger-1">*</span>
                                                                </Col>
                                                                <Col sm={1} className="card-row-col-right">
                                                                    <FormInputs
                                                                        ncols={["col-md-12 preference-preferred-pram"]}
                                                                        proprieties={[
                                                                            {
                                                                                type: "text",
                                                                                name: "loginDuration",
                                                                                bsClass: this.state.inputClass_loginDuration,
                                                                                placeholder: "",
                                                                                value: this.state.loginDuration,
                                                                                onChange: this.handleFailedLoginDurationInputChange,
                                                                                required: true
                                                                            }
                                                                        ]}
                                                                    />
                                                                    <div className="col-md-12">
                                                                        <FormErrors formErrors={this.state.formErrors}
                                                                                    fieldName="loginDuration"/>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                     sm={1}>
                                                                    {t('adminSettings:lockedAccountDuration')}
                                                                    <span className="text-danger-1">*</span>
                                                                </Col>
                                                                <Col sm={1} className="card-row-col-right">
                                                                    <FormInputs
                                                                        ncols={["col-md-12 preference-preferred-pram"]}
                                                                        proprieties={[
                                                                            {
                                                                                type: "text",
                                                                                name: "lockDuration",
                                                                                bsClass: this.state.inputClass_lockDuration,
                                                                                placeholder: "",
                                                                                value: this.state.lockDuration,
                                                                                onChange: this.handleLockedAccountDurationInputChange,
                                                                                required: true
                                                                            }
                                                                        ]}
                                                                    />
                                                                    <div className="col-md-12">
                                                                        <FormErrors formErrors={this.state.formErrors}
                                                                                    fieldName="lockDuration"/>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                        </FormGroup>
                                                    </div>
                                                }
                                            />
                                            </div>
                                            <div role="group" aria-labelledby="passwordPolicy" className="knoa-region" tabIndex={"-1"}>
                                            <Card
                                                id="passwordPolicy"
                                                title={t('adminSettings:passwordPolicy')}
                                                category={""}
                                                content={
                                                    <div className="KnoaFilterContainer">
                                                        <FormGroup
                                                            controlId="formPasswordPolicyManagement"
                                                        >
                                                            <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                     sm={1}>
                                                                    {t('adminSettings:passwordPolicyHistory')}
                                                                    <span className="text-danger-1">*</span>
                                                                </Col>
                                                                <Col sm={1} className="card-row-col-right">
                                                                    <FormInputs
                                                                        ncols={["col-md-12 preference-preferred-pram"]}
                                                                        proprieties={[
                                                                            {
                                                                                type: "text",
                                                                                name: "history",
                                                                                bsClass: this.state.inputClass_history,
                                                                                placeholder: "",
                                                                                value: this.state.history,
                                                                                onChange: this.handleHistoryInputChange,
                                                                                required: true
                                                                            }
                                                                        ]}
                                                                    />
                                                                    <div className="col-md-12">
                                                                        <FormErrors formErrors={this.state.formErrors} fieldName="history"/>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                     sm={1}>
                                                                    {t('adminSettings:passwordPolicyLifetime')}
                                                                    <span className="text-danger-1">*</span>
                                                                </Col>
                                                                <Col sm={1} className="card-row-col-right">
                                                                    <FormInputs
                                                                        ncols={["col-md-12 preference-preferred-pram"]}
                                                                        proprieties={[
                                                                            {
                                                                                type: "text",
                                                                                name: "lifetime",
                                                                                bsClass: this.state.inputClass_lifetime,
                                                                                placeholder: "",
                                                                                value: this.state.lifetime,
                                                                                onChange: this.handleLifetimeInputChange,
                                                                                required: true
                                                                            }
                                                                        ]}
                                                                    />
                                                                    <div className="col-md-12">
                                                                        <FormErrors formErrors={this.state.formErrors}
                                                                                    fieldName="lifetime"/>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                     sm={1}>
                                                                    {t('adminSettings:passwordPolicyMinLifetime')}
                                                                    <span className="text-danger-1">*</span>
                                                                </Col>
                                                                <Col sm={1} className="card-row-col-right">
                                                                    <FormInputs
                                                                        ncols={["col-md-12 preference-preferred-pram"]}
                                                                        proprieties={[
                                                                            {
                                                                                type: "text",
                                                                                name: "minLifetime",
                                                                                bsClass: this.state.inputClass_minLifetime,
                                                                                placeholder: "",
                                                                                value: this.state.minLifetime,
                                                                                onChange: this.handleMinLifetimeInputChange,
                                                                                required: true,
                                                                            }
                                                                        ]}
                                                                    />
                                                                    <div className="col-md-12">
                                                                        <FormErrors formErrors={this.state.formErrors}
                                                                                    fieldName="minLifetime"/>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                     sm={1}>
                                                                    {t('adminSettings:passwordPolicyMinLength')}
                                                                    <span className="text-danger-1">*</span>
                                                                </Col>
                                                                <Col sm={1} className="card-row-col-right">
                                                                    <FormInputs
                                                                        ncols={["col-md-12 preference-preferred-pram"]}
                                                                        proprieties={[
                                                                            {
                                                                                type: "text",
                                                                                name: "minLength",
                                                                                bsClass: this.state.inputClass_minLength,
                                                                                placeholder: "",
                                                                                value: this.state.minLength,
                                                                                onChange: this.handleMinLengthInputChange,
                                                                                required: true,
                                                                            }
                                                                        ]}
                                                                    />
                                                                    <div className="col-md-12">
                                                                        <FormErrors formErrors={this.state.formErrors}
                                                                                    fieldName="minLength"/>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                        </FormGroup>
                                                    </div>
                                                }
                                            />
                                            </div>
                                            <div role="group" aria-labelledby="authenticationPolicy" className="knoa-region" tabIndex={"-1"}>
                                            <Card
                                                id="authenticationPolicy"
                                                title={t('adminSettings:authenticationPolicy')}
                                                category={""}
                                                content={
                                                    <div className="KnoaFilterContainer">
                                                        <FormGroup
                                                            controlId="formAuthenticationPolicyManagement"
                                                        >
                                                            <Row className="card-row-flex">
                                                                <Col sm={1} className="card-row-col-label" componentClass={ControlLabel}>
                                                                    {t("adminSettings:enable2FA")}
                                                                </Col>
                                                                <Col sm={1}  className="card-row-col-right">
                                                                    <Switch onChange={this.handleTFACheckboxUpdate}
                                                                            value={this.state.tfaEnabled}
                                                                            onText={t('common:ON')}
                                                                            offText={t('common:OFF')}
                                                                            bsSize={'small'}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                    </div>
                                                }
                                            />
                                            </div>
                                        </Col>
                                    }
                                />

                            </div>

                        </div>

                    </form>

                </Grid>
                <div className="Login-Management-Footer">
                    <Footer fluid footerObj={footerObj}/>
                </div>
            </div>
        );
    }
}

export default withTranslation()(AdminSecuritySetting);