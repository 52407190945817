const CustomStyles = {
    //
    // control: provided => {
    //     //console.log("--Provided internals", provided); // let's console provided and return object as before
    //     return {
    //         ...provided,
    //         backgroundColor: "#23CCEF",
    //         borderColor: "#23CCEF",
    //
    //     };
    // },

    // clearIndicator: (provided) => {
    //     //console.log("--Provided clear indicator", provided);
    //     return {
    //         ...provided,
    //         display: "none"
    //     };
    // },

    // indicatorsContainer: (provided) => {
    //     //console.log("--Provided  indicatorsContainer", provided);
    //     return {
    //         ...provided,
    //         color: "white !important",
    //     };
    // },
    placeholder: (provided) => {
        //console.log("--Provided  placeholder", provided);
        return {
            ...provided,
            color: "#6E6E6E !important",
            fontSize: "14px"
        };
    },
    // indicatorSeparator: (provided) => {
    //     //console.log("--Provided  indicatorSeparator", provided);
    //     return {
    //         ...provided,
    //         backgroundColor: "#23CCEF",
    //     };
    // },

    dropdownIndicator: (provided) => {
        //console.log("--Provided  dropdownIndicator", provided);
        return {
            ...provided,
            color: "#6E6E6E",
        };
    },

    multiValueLabel: (provided) => {
        //console.log("--Provided multiValueLabel", provided);
        return {
            ...provided,
            color: "#6E6E6E !important",
            fontSize: "14px",
            borderRadius:"0px",
            backgroundColor: "",

        };
    } ,

    singleValue: (provided) => {
        //console.log("--Provided multiValue", provided);
        return {
            ...provided,
            color: "#6E6E6E !important",
            fontSize: "14px",
            borderRadius:"0px",
            backgroundColor: "",


        };
    },

    multiValue: (provided) => {
        //console.log("--Provided multiValue", provided);
        return {
            ...provided,
            backgroundColor: "",


        };
    },

    multiValueRemove: (provided) => {
        //console.log("--Provided multiValueRemove", provided);
        return {
            ...provided,
            color: "#6E6E6E",

        };
    }

    // option: (provided, state) => ({
    //     ...provided,
    //     borderBottom: '1px dotted pink',
    //     color: state.isSelected ? 'red' : 'blue',
    //     padding: 20,
    // }),
};

export default CustomStyles;