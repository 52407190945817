import React, {Component} from "react";
import {Col, Row, FormGroup} from "react-bootstrap";
import Radio from "../creativeTim/CustomRadio";
import Datetime from "react-datetime";
import {withTranslation} from 'react-i18next';
import {
    checkBenchmarkWarning, createSliderWithBenchmark, disableSecondHandle,
    updateBaseTooltip, updateMainHandleColor, updateBenchmarkHandleColor,
    updateBenchmarkHandleVisibility, updateHandlesIndex, updateLimit,
    updateSliderColor, updateTooltips, updateValues
} from "../../lib/noUiSliderCommon";
import {calculateBenchmarkDate, checkBenchmarkWarningAbsolute, getServerTimezoneDate, hideBenchmarkWarningAbsolute} from "../../lib/common";
import moment from "moment";
import {getWarningMessages} from "../../lib/messageHandler";

class TimeframeSelection extends Component {
    constructor(props) {
        super(props);
        


        this.handleRadio = this.handleRadio.bind(this);
        this.handleSliderRelativeChange = this.handleSliderRelativeChange.bind(this);
        this.handleSliderRelativeSlide = this.handleSliderRelativeSlide.bind(this);
        this.handleSliderRelativeStart = this.handleSliderRelativeStart.bind(this);
        this.validAbsoluteStart = this.validAbsoluteStart.bind(this);
        this.validAbsoluteEnd = this.validAbsoluteEnd.bind(this);
        this.handleStartDateInputChange = this.handleStartDateInputChange.bind(this);
        this.handleEndDateInputChange = this.handleEndDateInputChange.bind(this);
        this.getPartOfDate = this.getPartOfDate.bind(this);

        //Layout
        const isCtxProject = this.props.context === 'project';
        const isCtxFilterPanel = this.props.context === 'filterPanel';
        this.layout = {
            colContainerRelative: {
                md: isCtxProject ? 10 : 12,
                mdOffset: isCtxProject ? 1 : 0
            },
            colRadioRelative: {sm: 12, md: 12, lg: 4},
            colSliderRelative: {sm: 12, md: 12, lg: 8},
            colDateAbsolute: {sm: 12, md: 12, lg: isCtxProject ? 3 : 4}
        };
    }

    getPartOfDate(date, part) {
        if (part === "month") {
            return date.getMonth();
        } else if (part === "day") {
            return date.getDate();
        } else if (part === "year") {
            return date.getFullYear();
        } else return date;
    }

    handleRadio(event) {
        const target = event.target;
        this.props.handleTimeFilterUpdate(target.name, target.getAttribute('data-datarange'), target.id);
    };

    handleSliderRelativeStart() {
        const sliderRelative = document.getElementById('sliderRelative' + this.props.filterType);
        updateBenchmarkHandleVisibility(sliderRelative, 'hidden');
    }

    handleSliderRelativeSlide() {
        const sliderRelative = document.getElementById('sliderRelative' + this.props.filterType);
        const baseValue = sliderRelative.noUiSlider.get()[0];
        let timeFilterObj = this.props.filterType === "Current" ? this.props.timeFilter.current : this.props.timeFilter.historical;
        updateSliderColor(sliderRelative, baseValue, timeFilterObj.limit, "RELATIVE");
        updateBaseTooltip(sliderRelative);
    }

    handleSliderRelativeChange() {
        const sliderRelative = document.getElementById('sliderRelative' + this.props.filterType);
        const value = parseInt(sliderRelative.noUiSlider.get()[0]);

        updateMainHandleColor(sliderRelative, "RELATIVE");
        updateBenchmarkHandleColor(sliderRelative, value, sliderRelative.noUiSlider.options.range.max, "RELATIVE");
        checkBenchmarkWarning(sliderRelative, this.props.filterType);
        updateBenchmarkHandleVisibility(sliderRelative, 'visible');

        this.props.handleTimeFilterUpdate("relativeSlider", this.props.filterType, value);
    }

    handleStartDateInputChange = (momentS) => {
       // this.setState({tempSDate: momentV});
        if(moment(momentS, "MM/DD/YYYY", true).isValid())
            this.props.handleTimeFilterUpdate("absoluteStartDate", this.props.filterType, momentS);
    };

    handleEndDateInputChange = (momentE) => {
        if(moment(momentE, "MM/DD/YYYY", true).isValid())
            this.props.handleTimeFilterUpdate("absoluteEndDate", this.props.filterType, momentE)
    };

    shouldComponentUpdate(nextProps, nextState) {
        let tempFilterChangeIdentifier = window.sessionStorage.getItem("lastChangedFilter");
        if(tempFilterChangeIdentifier=== null || (tempFilterChangeIdentifier && (tempFilterChangeIdentifier.startsWith("TIME"))))
            return true;
        else
            return false;
    }
    
    // handleAbsoluteDateStartSelect(moment) {
    //    // if(this.state && this.state.tempSDate)
    //     this.props.handleTimeFilterUpdate("absoluteStartDate", this.props.filterType, moment);
    //     // if (this.state.focused) {
    //     //     this.setState({ focused: false });
    //     // }
    // }
    //
    //
    //
    // handleAbsoluteDateEndSelect(moment) {
    //    // if(this.state && this.state.tempEDate)
    //     this.props.handleTimeFilterUpdate("absoluteEndDate", this.props.filterType, moment)
    // }

    validAbsoluteStart(current) {
        if (this.props.filterType === "Current") {
            if (current.isSameOrAfter(this.props.timeFilter.current.absoluteCalendarStart, 'day')) {
                return true;
            }
        } else if (this.props.filterType === "Historical") {
            if (current.isSameOrAfter(this.props.timeFilter.historical.absoluteCalendarStart, 'month')) {
                return true;
            }
        }
        return false;
    }

    validAbsoluteEnd(current) {
        if (this.props.filterType === "Current") {
            if (current.isSameOrAfter(this.props.timeFilter.current.absoluteStartDate, 'day')) {
                return true
            }
        } else if (this.props.filterType === "Historical") {
            if (current.isSameOrAfter(this.props.timeFilter.historical.absoluteStartDate, 'month')) {
                return true
            }
        }
        return false;
    }

    componentDidUpdate() {
        if (Object.keys(this.props.timeFilter).length > 0) {
            const sliderRelative = document.getElementById('sliderRelative' + this.props.filterType);
            const startDateAbsolute = document.getElementById('startDateAbsolute' + this.props.filterType);
            const endDateAbsolute = document.getElementById('endDateAbsolute' + this.props.filterType);
            let timeFilterObj = this.props.filterType === "Current" ? this.props.timeFilter.current : this.props.timeFilter.historical;

            if (timeFilterObj.limit) {
                updateLimit(sliderRelative, timeFilterObj.limit);
            }

            if (timeFilterObj.relativeNew) {
                updateValues(sliderRelative, timeFilterObj.relativeNew);
            }

            updateHandlesIndex(sliderRelative);
            updateTooltips(sliderRelative, this.props.t('report.filter:basePeriod'), this.props.t('report.filter:benchmarkPeriod'));
            updateSliderColor(sliderRelative, timeFilterObj.relativeNew, timeFilterObj.limit, timeFilterObj.selectedTimePeriod);
            updateMainHandleColor(sliderRelative, timeFilterObj.selectedTimePeriod);
            updateBenchmarkHandleColor(sliderRelative, timeFilterObj.relativeNew, timeFilterObj.limit, timeFilterObj.selectedTimePeriod);
            checkBenchmarkWarning(sliderRelative, this.props.filterType);

            if (this.props.isInstanceReport) {
                sliderRelative.setAttribute('disabled', true);
                sliderRelative.classList.remove("slider-primary");
                sliderRelative.classList.add("slider-disabled");
                startDateAbsolute.setAttribute('disabled', true);
                endDateAbsolute.setAttribute('disabled', true);
            } else if (timeFilterObj.selectedTimePeriod === 'RELATIVE') {
                //Enable/disable components
                sliderRelative.removeAttribute('disabled');
                sliderRelative.classList.remove("slider-disabled");
                sliderRelative.classList.add("slider-primary");
                startDateAbsolute.setAttribute('disabled', true);
                endDateAbsolute.setAttribute('disabled', true);

                //Hide warning if its displayed
                hideBenchmarkWarningAbsolute(this.props.filterType);
            } else if (timeFilterObj.selectedTimePeriod === "ABSOLUTE") {
                //Enable/disable components
                sliderRelative.setAttribute('disabled', true);
                sliderRelative.classList.remove("slider-primary");
                sliderRelative.classList.add("slider-disabled");
                startDateAbsolute.removeAttribute('disabled');
                endDateAbsolute.removeAttribute('disabled');

                //Check if warning should be displayed
                let absoluteStartDate = timeFilterObj.absoluteStartDate;
                let absoluteEndDate = timeFilterObj.EndDate;
                let absoluteBenchmarkLabel = calculateBenchmarkDate(absoluteStartDate, absoluteEndDate, this.props.filterType);
                const benchMarkStart = absoluteBenchmarkLabel.substr(0, absoluteBenchmarkLabel.indexOf(' '));
                checkBenchmarkWarningAbsolute(benchMarkStart, timeFilterObj.absoluteCalendarStart, this.props.filterType);
            }
        }
    };

    componentDidMount() {
        //Slider ->
        const sliderRelative = document.getElementById('sliderRelative' + this.props.filterType);
        createSliderWithBenchmark(sliderRelative);
        disableSecondHandle(sliderRelative);
        sliderRelative.noUiSlider.on('change', this.handleSliderRelativeChange);
        sliderRelative.noUiSlider.on('slide', this.handleSliderRelativeSlide);
        sliderRelative.noUiSlider.on('start', this.handleSliderRelativeStart);

        //<- Slider

        //Center align dates
        const startDateInput = document.getElementById('startDateAbsolute' + this.props.filterType);
        const endDateInput = document.getElementById('endDateAbsolute' + this.props.filterType);
        startDateInput.style.textAlign = 'center';
        endDateInput.style.textAlign = 'center';

        this.componentDidUpdate();

        // if (this.input) {
        //     let origClickOutside = this.input.handleClickOutside;
        //     this.input.handleClickOutside = () => {
        //         origClickOutside();
        //         this.handleAbsoluteDateStartSelect();
        //     };
        // }
    }

    render() {
        const {filterType, timeFilter, absoluteStartError, absoluteEndError} = this.props;
        const {t} = this.props;

        let radioTimeframeOptions = "1";
        let selectedTimePeriod = "RELATIVE";
        let selectedValue = "";
        let unitRelative = "";
        let absoluteStartDate = getServerTimezoneDate();
        let absoluteEndDate = getServerTimezoneDate();
        let dateViewMode = "days";
        let dateFormat = "DD-MM-YYYY";
        let sliderRelativeString = "";
        let sliderRelativeString2 = "";
        let absoluteBenchmarkLabel = "";

        if (Object.keys(timeFilter).length > 0) {
            let timeFilterObj = filterType === "Current" ? timeFilter.current : timeFilter.historical;

            //Update date objects that may be in string format (this should be fixed so that they are not saved as a string)
            if (typeof (timeFilter.current.EndDate) === 'string') {
                timeFilter.current.EndDate = new Date(timeFilter.current.EndDate);
            }
            if (typeof (timeFilter.current.absoluteStartDate) === 'string') {
                timeFilter.current.absoluteStartDate = new Date(timeFilter.current.absoluteStartDate);
            }

            selectedTimePeriod = timeFilterObj.selectedTimePeriod;
            absoluteEndDate = timeFilterObj.EndDate;
            absoluteStartDate = timeFilterObj.absoluteStartDate;
            selectedValue = timeFilterObj.relativeNew;

            absoluteBenchmarkLabel = calculateBenchmarkDate(absoluteStartDate, absoluteEndDate, filterType);

            if (filterType === "Current") {
                unitRelative = selectedValue > 1 ? "days" : "day";
                dateViewMode = "days";
                dateFormat = "MM/DD/YYYY";
                sliderRelativeString = t('project:sliderStringRelative', {value: selectedValue, unit: unitRelative});
                sliderRelativeString2 = t('preferences:sliderString2', {value: selectedValue, unit: unitRelative});
            } else {
                unitRelative = selectedValue > 1 ? "months" : "month";
                dateViewMode = "months";
                dateFormat = "MM/YYYY";
                sliderRelativeString = t('project:sliderStringRelative', {value: selectedValue, unit: unitRelative});
                sliderRelativeString2 = t('preferences:sliderString2', {value: selectedValue, unit: unitRelative});
            }

            if (selectedTimePeriod === "RELATIVE") {
                radioTimeframeOptions = "1";
            } else if (selectedTimePeriod === "ABSOLUTE") {
                radioTimeframeOptions = "2";
            }
        }

        if (this.props.i18n.language === 'zh') {
            require('moment/locale/zh-cn');
        }

        return (
            <FormGroup role="group" aria-labelledby="timeframe" className={this.props.isInstanceReport ? "timeSelectFG  report-filter-panel-instance" : "timeSelectFG"}>
                <Row className={this.props.context === 'project' ? 'timeSelectRelativeRowProject' : 'timeSelectRelativeRowFilterPanel'}>
                    <Col md={this.layout.colContainerRelative.md} mdOffset={this.layout.colContainerRelative.mdOffset}>
                        {this.props.context === 'project' &&
                        <h4 id="timeframe" className={"knoa-region"} tabIndex={"-1"}>{t('project:selectTimeframe')}</h4>
                        }
                        {this.props.context === 'filterPanel' &&
                        <Row className="card-filter-section-title">
                            <span id="timeframe">{t('report.filter:TimeFilters')}</span>
                        </Row>
                        }
                        <Col sm={this.layout.colRadioRelative.sm} md={this.layout.colRadioRelative.md} lg={this.layout.colRadioRelative.lg}>
                            <Radio
                                number="RELATIVE"
                                option="1"
                                name="radioTimeframeOptions"
                                onChange={this.handleRadio}
                                value={radioTimeframeOptions}
                                label={this.props.context === 'project' ? t('project:useRelativeTimeframe') : t('report.filter:RelativeTimeframe')}
                                data-datarange={filterType}
                                className={this.props.context === 'filterPanel' ? 'radioButtonTimeframe' : null}
                                disabled={this.props.isInstanceReport}
                            />
                        </Col>
                        <Col sm={this.layout.colSliderRelative.sm} md={this.layout.colSliderRelative.md}
                             lg={this.layout.colSliderRelative.lg} className='timeSelectRelativeSliderCol'>
                            <div id={"sliderRelative" + this.props.filterType} className="slider-primary"
                                 data-datarange={this.props.filterType}></div>
                            <div>
                                {Object.keys(this.props.timeFilter).length > 0 &&
                                <span className={this.props.context === 'filterPanel' ? 'labelText' : null}>{sliderRelativeString}</span>
                                }
                            </div>
                            <div className='timeSelectLabel'>
                                {Object.keys(this.props.timeFilter).length > 0 &&
                                <span className={this.props.context === 'filterPanel' ? 'labelText' : null}>{sliderRelativeString2}</span>
                                }
                            </div>
                            <div id={"sliderWarning" + this.props.filterType} className='timeSelectLabel timeSelectWarning'>
                                <span className="benchmarkWarning">{getWarningMessages(t, 'benchmarkSlider',null)}</span>
                            </div>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col md={this.layout.colContainerRelative.md} mdOffset={this.layout.colContainerRelative.mdOffset}>
                        <Col sm={this.layout.colRadioRelative.sm} md={this.layout.colRadioRelative.md} lg={this.layout.colRadioRelative.lg}>
                            <Radio
                                number="ABSOLUTE"
                                option="2"
                                name="radioTimeframeOptions"
                                onChange={this.handleRadio}
                                value={radioTimeframeOptions}
                                label={this.props.context === 'project' ? t('project:useAbsoluteTimeframe') : t('report.filter:AbsoluteTimeframe')}
                                data-datarange={filterType}
                                className={this.props.context === 'filterPanel' ? 'radioButtonTimeframe' : null}
                                disabled={this.props.isInstanceReport}
                                tabIndex="0"
                            />
                        </Col>
                        <Col sm={this.layout.colDateAbsolute.sm} md={this.layout.colDateAbsolute.md} lg={this.layout.colDateAbsolute.lg}
                             className='timeSelectDateCol'>
                            <Datetime
                                className="rdtPickerOpenUpwards"
                                timeFormat={false}
                                inputProps={{
                                    placeholder: t('report.filter:StartDate'),
                                    disabled: radioTimeframeOptions !== "2" || this.props.isInstanceReport,
                                    id: "startDateAbsolute" + this.props.filterType,
                                    className: this.props.context === 'filterPanel' ? 'form-control timeSelectDateInputFilterPanel' : 'form-control',
                                    //onFocus: this.onFocus.bind(this)
                                    //onClose: this.handleAbsoluteDateStartSelect,
                                    //onChange : this.handleStartDateInputChange
                                    // onClose={this.handleAbsoluteDateStartSelect}
                                }}
                                // open={false}
                                value={absoluteStartDate}
                                //input={true}
                                closeOnSelect={true}
                                onChange ={this.handleStartDateInputChange}
                                //disableCloseOnClickOutside={false}
                                //ref={(ref) => this.input = ref}
                                //onClose={this.handleAbsoluteDateStartSelect}
                                isValidDate={this.validAbsoluteStart}
                                viewMode={dateViewMode}
                                dateFormat={dateFormat}
                                disabled={this.props.isInstanceReport}
                            />
                            <span className={this.props.context === 'filterPanel' ? 'labelText' : null}>{t('project:startDate')}</span>
                            <Row className='timeSelectError'>{absoluteStartError}</Row>
                        </Col>
                        <Col sm={this.layout.colDateAbsolute.sm} md={this.layout.colDateAbsolute.md} lg={this.layout.colDateAbsolute.lg}
                             className='timeSelectDateCol'>
                            <Datetime
                                className="rdtPickerOpenUpwards"
                                timeFormat={false}
                                inputProps={{
                                    placeholder: "End Date",
                                    disabled: radioTimeframeOptions !== "2" || this.props.isInstanceReport,
                                    id: "endDateAbsolute" + this.props.filterType,
                                    className: this.props.context === 'filterPanel' ? 'form-control timeSelectDateInputFilterPanel' : 'form-control'
                                }}
                                value={absoluteEndDate}
                                //input={true}
                                closeOnSelect={true}
                                onChange ={this.handleEndDateInputChange}
                                //onBlur={this.handleAbsoluteDateEndSelect}
                                isValidDate={this.validAbsoluteEnd}
                                viewMode={dateViewMode}
                                dateFormat={dateFormat}
                                disabled={this.props.isInstanceReport}
                            />
                            <span className={this.props.context === 'filterPanel' ? 'labelText' : null}>{t('project:endDate')}</span>
                            <Row className='timeSelectError'>{absoluteEndError}</Row>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col md={this.layout.colContainerRelative.md} mdOffset={this.layout.colContainerRelative.mdOffset}>
                        <Col sm={this.layout.colRadioRelative.sm} md={this.layout.colRadioRelative.md}
                             lg={this.layout.colRadioRelative.lg}></Col>
                        <Col sm={this.layout.colSliderRelative.sm} md={this.layout.colSliderRelative.md}
                             lg={this.layout.colSliderRelative.lg}>
                            <div className='timeSelectLabel'>
                                {Object.keys(this.props.timeFilter).length > 0 &&
                                <span
                                    className={this.props.context === 'filterPanel' ? 'labelText' : null}>{t('project:benchmarkStringAbsolute', {value: absoluteBenchmarkLabel})}</span>
                                }
                            </div>
                            <div id={"absoluteTimeWarning" + this.props.filterType} className='timeSelectLabel timeSelectWarning'>
                                <span className="benchmarkWarning">{getWarningMessages(t, 'benchmarkSlider',null)}</span>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}

export default withTranslation()(TimeframeSelection);