/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

class CustomCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_checked: props.isChecked ? true : false
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    let checked;
    if(this.props.isChecked !== undefined) {
      checked = !this.props.isChecked;
    }
    else {
      checked = !this.state.is_checked;
    }
    this.setState({is_checked: checked});
    if(this.props.handleUserAttributesUpdate) {
      this.props.handleUserAttributesUpdate(this.props.label, checked);
    }
    else if(this.props.handleProjectReportsUpdate) {
      this.props.handleProjectReportsUpdate(this.props.number, checked);
    }
    else if(this.props.handleAddToProjectUpdateCheckbox) {
      this.props.handleAddToProjectUpdateCheckbox(this.props.number, checked);
    } else if(this.props.handleCheckboxUpdate){
        this.props.handleCheckboxUpdate(this.props.number, checked);
    }else if(this.props.handleSelectionForALL){
      this.props.handleSelectionForALL(checked);
    }
    
  }

  render() {
    const { isChecked, number, label, inline, isEnabled } = this.props;
    const classes = inline !== undefined ? inline==="selectALL" ? "checkbox-select-all-row checkbox checkbox-inline" : "checkbox checkbox-inline" : "checkbox";
    let checked = isChecked !== undefined ? isChecked : this.state.is_checked;

    let enabled = isEnabled !== undefined ? isEnabled : true;
    if(!enabled) checked = false;

      return (
      <div className={classes}>
        {checked && <input
          id={number}
          type="checkbox"
          onChange={this.handleClick}
          disabled={!enabled}
          checked
        />}
        {!checked && <input
            id={number}
            type="checkbox"
            onChange={this.handleClick}
            disabled={!enabled}
        />}
        <label htmlFor={number}>{label}</label>
      </div>
    );
  }
}

export default CustomCheckbox;
