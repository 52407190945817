/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

class CustomRadio extends Component {
  render() {
    const { number, label, option, name, value, ...rest } = this.props;

    return (
      <div className="radio">
          {value === option && <input id={number} name={name} type="radio" value={option} {...rest} checked/>}
          {value !== option && <input id={number} name={name} type="radio" value={option} {...rest}/>}
          <label htmlFor={number}>{label}</label>
      </div>
    );
  }
}

export default CustomRadio;
