//Capitalize the first character of every word

import {getDayOfYear} from 'date-fns'
import moment from "moment";
import {API} from "./API";

export function capitalizeWords(str) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
}

export function capitalizeWordsForOSLabel(str) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    let tempWords = splitStr.join(' ');
    if (tempWords) return tempWords.replace("Os", "OS");
    else return "";
}

//for server time zone and report date related using Offset instead of timezone ID
export function getServerTimezoneDate() {
    let serverTimeZone = API.getServerTimeZone();
    if(serverTimeZone==null || serverTimeZone === "") {
        serverTimeZone = "UTC"
    }
    const dateStr = new Date().toLocaleString('en-US', {timeZone: serverTimeZone});
    const localDate = new Date(dateStr);
    localDate.setHours(0,0,0,0);
    return localDate;


    //
    //
    // let gmtOffsetValue = 'GMT+0000';
    // if (serverTimeZone !== undefined && serverTimeZone !== null && serverTimeZone['rawOffset'] !== null && serverTimeZone['dayLightSaving'] !== null) {
    //     let offset = serverTimeZone['rawOffset'];
    //     let dts = serverTimeZone['dayLightSaving'];
    //     gmtOffsetValue = convertSeverMilliSecsToHrsMins(offset, dts);
    // }
    //
    // let localDate = new Date();
    // let utcDate = new Date(localDate.getUTCFullYear(), localDate.getMonth(), localDate.getDate(), localDate.getUTCHours(), localDate.getUTCMinutes(), 0, 0);
    //
    // let finalDate = getDateFromOffset(utcDate, gmtOffsetValue);
    // let year = finalDate.getFullYear();
    // let month = finalDate.getMonth();
    // let day = finalDate.getDate();


    // if(API.getServerTimeZone()==="unknown")  serverTimeZone ="Europe/London";
    // let moment = require('moment-timezone');
    // let newDate = moment().tz(serverTimeZone); //server timezone
    // let year = newDate.year();
    // let month = newDate.month();
    // let day   = newDate.date();
    // let hour  = newDate.getHours();
    // let minutes = newDate.getMinutes();
    // let seconds = newDate.getSeconds();
    // let miliSeconds = newDate.getMilliseconds();
    // let finalServerDate = new Date(year, month, day, 0, 0, 0, 0);
    // return finalServerDate;
}

export function calculateStartDate(type, value, fromDate) {
    const _today = fromDate ? new Date(fromDate) : getServerTimezoneDate();
    if (type === "CURRENT") {
        return new Date(_today.setDate(_today.getDate() - value + 1));
    } else if (type === "HISTORICAL") {
        const month = _today.getMonth();
        if (value === 1) {
            //Set day of current month
            _today.setDate(1);
            return _today;
        } else {
            //Including this month
            _today.setMonth(_today.getMonth() - value + 1);
            //Ensure end up in previous month
            while (_today.getMonth() === month) {
                _today.setDate(_today.getDate() - 1);
            }
            //Set day
            _today.setDate(1);
            return _today;
        }
    }
}

export function formatHistoricalAbsoluteDateRange(historicalDateRange) {
    if (historicalDateRange === "") return "";

    let startEndDates = historicalDateRange.split("-");
    let startDate = new Date(startEndDates[0]);
    let endDate = new Date(startEndDates[1]);
    let startMonthYear = startDate.getMonth() + 1 + "/" + startDate.getFullYear();
    let endMonthYear = endDate.getMonth() + 1 + "/" + endDate.getFullYear();

    return startMonthYear + " - " + endMonthYear;
}

export function formatCurrentAbsoluteDateRange(currentDateRange) {
    if (currentDateRange === "") return "";

    let startEndDates = currentDateRange.split("-");
    let startDate = new Date(startEndDates[0]);
    let endDate = new Date(startEndDates[1]);
    let startMonthYear = startDate.getMonth() + 1 + "/" + startDate.getDate() + "/" + startDate.getFullYear();
    let endMonthYear = endDate.getMonth() + 1 + "/" + endDate.getDate() + "/" + endDate.getFullYear();

    return startMonthYear + " - " + endMonthYear;
}

//dataRangeType: "current" or "historical"
//timeFilterType: "RELATIVE" or "ABSOLUTE"
//relativeTimeFrame: relative object containing timeframe filter information
//absoluteTimeFrame: absolute object containing timeframe filter information
//benchmarkType: "start" or "end"
export function calculateBenchmarkID(dataRangeType, timeFilterType, relativeTimeFrame, absoluteTimeFrame, benchmarkType) {
    let unit = dataRangeType === "current" ? "days" : "months";
    let dataRangeTypeUppercase = dataRangeType.toUpperCase();
    let dataRangeTypeLowercase = dataRangeType.toLowerCase();
    let dateID = -1;
    let diffDates = -1;
    let benchmarkStart = null;
    let benchmarkEnd = null;
    let timeFrameObj = timeFilterType === "RELATIVE" ? relativeTimeFrame : absoluteTimeFrame;

    if (timeFilterType === "RELATIVE") {
        diffDates = timeFrameObj.value;
        let baseStart = calculateStartDate(dataRangeTypeUppercase, diffDates, null);
        if (benchmarkType === 'start') {
            benchmarkStart = calculateStartDate(dataRangeTypeUppercase, diffDates + 1, baseStart);
            dateID = getDateID(dataRangeTypeLowercase, benchmarkStart);
        } else if (benchmarkType === 'end') {
            benchmarkEnd = calculateStartDate(dataRangeTypeUppercase, 2, baseStart);
            dateID = getDateID(dataRangeTypeLowercase, benchmarkEnd);
        }
    } else if (timeFilterType === "ABSOLUTE") {
        if (benchmarkType === 'start') {
            const momentStart = moment(timeFrameObj.start);
            const momentEnd = moment(timeFrameObj.end);
            diffDates = momentEnd.diff(momentStart, unit);
            diffDates++; //include this day/month

            benchmarkStart = calculateStartDate(dataRangeTypeUppercase, diffDates + 1, timeFrameObj.start);
            dateID = getDateID(dataRangeTypeLowercase, benchmarkStart);
        } else if (benchmarkType === 'end') {
            benchmarkEnd = calculateStartDate(dataRangeTypeUppercase, 2, timeFrameObj.start);
            dateID = getDateID(dataRangeTypeLowercase, benchmarkEnd);
        }
    }

    return dateID;
}

//Generic, can be used to get any DATE ID for a given date
export function getDateID(dataRangeType, date) {
    let unitOfYear = dataRangeType === 'current' ? getDayOfYear(date) : date.getMonth();
    let unit = dataRangeType === 'current' ? 366 : 12;
    let year = date.getFullYear();

    return (unitOfYear + (unit * (year - 2000)));
}

export function getStartDayID(dataRangeType, timeFilterType, relativeTimeFrame, absoluteTimeFrame) {
    let today = getServerTimezoneDate()
    let day = 0;
    let year = 2000;

    if (dataRangeType === "current") {
        if (timeFilterType === "RELATIVE") {
            today.setDate(today.getDate() - (parseInt(relativeTimeFrame.value) - 1))
            day = getDayOfYear(today);
            year = today.getFullYear();
        } else if (timeFilterType === "ABSOLUTE") {
            let startDate = absoluteTimeFrame.start;
            if (typeof (absoluteTimeFrame.start) === 'string') {
                startDate = new Date(startDate);
            }
            day = getDayOfYear(startDate);
            year = startDate.getFullYear();
        }
    } else if (dataRangeType === "historical") {
        if (timeFilterType === "RELATIVE") {
            today.setMonth(today.getMonth() - parseInt(relativeTimeFrame.value));
            day = getDayOfYear(today);
            year = today.getFullYear();
        } else if (timeFilterType === "ABSOLUTE") {
            let startDate = absoluteTimeFrame.start;
            if (typeof (absoluteTimeFrame.start) === 'string') {
                startDate = new Date(startDate);
            }
            today.setTime(startDate);
            day = getDayOfYear(today);
            year = today.getFullYear();
        }
    }

    return (day + (366 * (year - 2000)));
}

export function getEndDayID(dataRangeType, timeFilterType, relativeTimeFrame, absoluteTimeFrame) {
    let today = timeFilterType === 'RELATIVE' ? getServerTimezoneDate() : typeof (absoluteTimeFrame.end) === 'string' ? new Date(absoluteTimeFrame.end) : absoluteTimeFrame.end;
    let day = 0;
    let year = 2000;

    day = getDayOfYear(today);
    year = today.getFullYear();

    return (day + (366 * (year - 2000)));
}

export function getStartMonthID(dataRangeType, timeFilterType, relativeTimeFrame, absoluteTimeFrame) {
    let today = getServerTimezoneDate();
    let month = 0;
    let year = 2000;

    if (dataRangeType === "current") {
        if (timeFilterType === "RELATIVE") {
            let startDate = new Date().setDate(today.getDate() - parseInt(relativeTimeFrame.value) + 1);
            today.setTime(startDate);
            month = today.getMonth();
            year = today.getFullYear();
        } else if (timeFilterType === "ABSOLUTE") {
            let startDate = absoluteTimeFrame.start;
            if (typeof (absoluteTimeFrame.start) === 'string') {
                startDate = new Date(startDate);
            }
            month = startDate.getMonth();
            year = startDate.getFullYear();
        }
    } else if (dataRangeType === "historical") {
        if (timeFilterType === "RELATIVE") {
            today.setMonth(today.getMonth() - parseInt(relativeTimeFrame.value) + 1);
            month = today.getMonth();
            year = today.getFullYear();
        } else if (timeFilterType === "ABSOLUTE") {
            let startDate = absoluteTimeFrame.start;
            if (typeof (absoluteTimeFrame.start) === 'string') {
                startDate = new Date(startDate);
            }
            month = startDate.getMonth();
            year = startDate.getFullYear();
        }
    }

    return (month + (12 * (year - 2000)));
}

export function getEndMonthID(dataRangeType, timeFilterType, relativeTimeFrame, absoluteTimeFrame) {
    let today = timeFilterType === 'RELATIVE' ? getServerTimezoneDate() : typeof (absoluteTimeFrame.end) === 'string' ? new Date(absoluteTimeFrame.end) : absoluteTimeFrame.end;
    let month = 0;
    let year = 2000;

    month = today.getMonth();
    year = today.getFullYear();

    return (month + (12 * (year - 2000)));
}

export function formatDateToString(date) {
    if (typeof (date) === "string") date = new Date(date);
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();

    return month + "/" + day + "/" + year;
}

export function formatDateToStringMMYYYY(date) {
    if (typeof (date) === "string") date = new Date(date);
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    return month + "/" + year;
}

export function getTimeFrameContent(lastestFilter, reportPeriod, projectID, trans) {
    if (lastestFilter.relativeTimeFrame !== undefined && lastestFilter.relativeTimeFrame.selected) {
        let lookupString = reportPeriod === 'base' ? 'report.filter:sliderStringRelative' : 'report.filter:benchmarkLabel';
        let unit = '';
        if (lastestFilter.relativeTimeFrame.type === "CURRENT" || lastestFilter.relativeTimeFrame.type === "INSTANCE") {
            unit = lastestFilter.relativeTimeFrame.value > 1 ? 'days' : 'day';
        } else if (lastestFilter.relativeTimeFrame.type === "HISTORICAL") {
            unit = lastestFilter.relativeTimeFrame.value > 1 ? 'months' : 'month';
        }

        return trans(lookupString, {
            value: lastestFilter.relativeTimeFrame.value,
            unit: unit
        });
    } else if (lastestFilter.absoluteTimeFrame !== undefined && lastestFilter.absoluteTimeFrame.selected) {
        let returnString = '';
        if (reportPeriod === 'base') {
            if (lastestFilter.absoluteTimeFrame.type === "CURRENT" || lastestFilter.absoluteTimeFrame.type === "INSTANCE") {
                returnString = formatDateToString(lastestFilter.absoluteTimeFrame.start) + " - " + formatDateToString(lastestFilter.absoluteTimeFrame.end)
            } else if (lastestFilter.absoluteTimeFrame.type === "HISTORICAL") {
                returnString = formatDateToStringMMYYYY(lastestFilter.absoluteTimeFrame.start) + " - " + formatDateToStringMMYYYY(lastestFilter.absoluteTimeFrame.end)
            }
        } else if (reportPeriod === 'benchmark') {
            const timeframe = lastestFilter.absoluteTimeFrame.type === "HISTORICAL" ? 'Historical' : 'Current';
            returnString = calculateBenchmarkDate(new Date(lastestFilter.absoluteTimeFrame.start), new Date(lastestFilter.absoluteTimeFrame.end), timeframe);
            ;
        }

        return returnString;
    }
    return "";
};

export function GetNumberOfActiveFilters(lastestFilter) {
    if ((lastestFilter === null || lastestFilter === undefined || lastestFilter.filters === undefined || lastestFilter.filters === null) && lastestFilter.tableauParams && lastestFilter.tableauParams.length === 0) return 0;
    else {
        let numberOfActive = 0;
        Object.values(lastestFilter.filters).forEach(v => {
            if (v.enabled === true && v.options !== null) {
                let filterValuesArr = v.options.filter(opt => opt.selected === true);
                if (filterValuesArr.length > 0) numberOfActive++
            }
        });
        return numberOfActive + 1 + lastestFilter.tableauParams.length; //including the timefilter // including the paramters?? TBD
    }
}

//timeframe: "Current" or "Historical"
//Returns a string <start date> - <end date>
export function calculateBenchmarkDate(startDate, endDate, timeframe) {
    let unit = '';
    let dateFormat = '';
    if (timeframe === 'Current') {
        unit = "days";
        dateFormat = "MM/DD/YYYY"
    } else {
        unit = "months";
        dateFormat = "MM/YYYY";
    }

    const momentStart = moment(startDate);
    const momentEnd = moment(endDate);

    let diff = momentEnd.diff(momentStart, unit);
    diff++; //include this day/month

    const benchmarkStart = momentStart.clone().subtract(diff, unit);
    const benchmarkEnd = momentStart.clone().subtract(1, unit);

    return benchmarkStart.format(dateFormat) + ' - ' + benchmarkEnd.format(dateFormat);
}

//benchmarkStartDate: string
//limitDate: date
//timeframe: "Current" or "Historical"
export function checkBenchmarkWarningAbsolute(benchmarkStartDate, limitDate, timeframe) {
    let warningElement = document.getElementById('absoluteTimeWarning' + timeframe);
    if (warningElement) {
        let dateFormat = '';
        if (timeframe === 'Current') {
            dateFormat = "MM/DD/YYYY"
        } else {
            dateFormat = "MM/YYYY";
        }

        const momentBenchmarkStart = moment(benchmarkStartDate, dateFormat);
        const momentLimit = moment(limitDate, dateFormat);

        if (momentBenchmarkStart.isSameOrAfter(momentLimit, 'day') || !momentBenchmarkStart.isValid()) {
            warningElement.style.visibility = 'hidden';
        } else {
            warningElement.style.visibility = 'visible';
        }
    }
}

//timeframe: "Current" or "Historical"
export function hideBenchmarkWarningAbsolute(timeframe) {
    let warningElement = document.getElementById('absoluteTimeWarning' + timeframe);
    if (warningElement) {
        warningElement.style.visibility = 'hidden';
    }
}

//showing benchMark warning in toolBar
export function isShowingWarningForToolbar(timeframeObj){
    if(timeframeObj!==null && timeframeObj){
        let filterType = timeframeObj.reportType === "current" ? "Current" :"Historical";
       if(timeframeObj.relativeTimeFrame && timeframeObj.relativeTimeFrame.selected) {
          if( timeframeObj.relativeTimeFrame.value > (timeframeObj.relativeTimeFrame.limit / 2)) return true;
          else return false;
       } else if(timeframeObj.absoluteTimeFrame && timeframeObj.absoluteTimeFrame.selected){
           
           let absoluteStartDate = timeframeObj.absoluteTimeFrame.start;
           let absoluteEndDate = timeframeObj.absoluteTimeFrame.end;
           let absoluteBenchmarkLabel = calculateBenchmarkDate(absoluteStartDate, absoluteEndDate, timeframeObj.absoluteTimeFrame.type ==="CURRENT" ? "Current" : "Historical");
           const benchMarkStart = absoluteBenchmarkLabel.substr(0, absoluteBenchmarkLabel.indexOf(' '));
               let dateFormat = '';
               if (filterType === 'Current') {
                   dateFormat = "MM/DD/YYYY"
               } else {
                   dateFormat = "MM/YYYY";
               }

               const momentBenchmarkStart = moment(benchMarkStart, dateFormat);
               const momentLimit = moment(filterType==='Current' ? timeframeObj.currentAbsoluteCalendarStart : timeframeObj.historicalAbsoluteCalendarStart, dateFormat);

               if (momentBenchmarkStart.isSameOrAfter(momentLimit, 'day') || !momentBenchmarkStart.isValid()) {
                   return false;
               } else {
                   return true;
               }
           
       } else {
           return false;
       }
    } else {
        return false;
    }
}

export function getLastestTabTimeFilterType(lastestReportFilters) {
    if (lastestReportFilters.relativeTimeFrame !== null && lastestReportFilters.relativeTimeFrame.selected) {
        return "RELATIVE";
    } else if (lastestReportFilters.absoluteTimeFrame !== null && lastestReportFilters.absoluteTimeFrame.selected) {
        return "ABSOLUTE";
    } else
        return undefined;
}

// export function isBenchMarkWarningShowing(){
//     let warningElementCurrent = document.getElementById('absoluteTimeWarningCurrent');
//     let currentIdentifier = false;
//     if(warningElementCurrent && warningElementCurrent.style.visibility === 'hidden')
//         currentIdentifier = true;
//    
//     let warningElementHistorical = document.getElementById('absoluteTimeWarningHistorical');
//     let historicalIdentifier = false;
//     if(warningElementHistorical && warningElementHistorical.style.visibility === 'hidden')
//         historicalIdentifier = true;
//    
//     if(currentIdentifier || historicalIdentifier) return true;
//     else return false;
// }

//>>> REPORT SESSION STORAGE MANAGEMENT >>>
export function getSSReportObject(reportType, reportObjName, projectId) {
    const ssReport = reportType === 'interactive' ? JSON.parse(window.sessionStorage.getItem('reportInteractive')) : JSON.parse(window.sessionStorage.getItem('reportProject' + projectId));
    if (ssReport) {
        return ssMauiFiltersStringToDates(ssReport[reportObjName]);
    } else {
        return null;
    }
}

export function setSSReportObject(reportType, reportObjName, reportObjValue, projectId) {
    const ssKey = reportType === 'interactive' ? 'reportInteractive' : 'reportProject' + projectId;
    const ssReport = JSON.parse(window.sessionStorage.getItem(ssKey));
    if (ssReport) {
        if (reportObjName === 'currentReset' || reportObjName === 'historicalReset') {
            let obj = ssReport[reportObjName];
            if (obj) {
                for (const property in obj) {
                    if (property === 'filters') {
                        let subObj = obj[property];
                        let subReportObjValue = reportObjValue[property];
                        if (subObj) {
                            for (const subProp in subObj) {
                                if (subProp === 'APPLICATION NAME' || subProp === 'MODULE NAME' || subProp.startsWith('STRING_ATTR')) {
                                    subObj[subProp] = subReportObjValue[subProp]
                                }
                            }
                        }

                    } else {
                        obj[property] = reportObjValue[property]
                    }
                }
            }
        } else {
            ssReport[reportObjName] = reportObjValue;
        }

        window.sessionStorage.setItem(ssKey, JSON.stringify(ssReport));
    }
}

export function removeSSReportObject(reportType, reportObjName, projectId) {
    const ssKey = reportType === 'interactive' ? 'reportInteractive' : 'reportProject' + projectId;
    const ssReport = JSON.parse(window.sessionStorage.getItem(ssKey));
    if (ssReport) {
        delete ssReport[reportObjName];
        window.sessionStorage.setItem(ssKey, JSON.stringify(ssReport));
    }
}

export function ssMauiFiltersStringToDates(obj) {
    if (obj) {
        for (const property in obj) {
            if (property === 'absoluteTimeFrame') {
                obj[property].start = new Date(obj[property].start);
                obj[property].end = new Date(obj[property].end);
            } else if (property === 'currentAbsoluteCalendarStart' || property === 'historicalAbsoluteCalendarStart') {
                obj[property] = new Date(obj[property]);
            }
        }
    }

    return obj;
}

//<<< REPORT SESSION STORAGE MANAGEMENT <<<

//>>> GLOBAL OPTIONS SESSION STORAGE MANAGEMENT >>>
export function getSSGlobalOptionsObject(reportObjName) {
    const ssGlobalOptions = JSON.parse(window.sessionStorage.getItem('globalOptions'));
    if (ssGlobalOptions) {
        return ssGlobalOptions[reportObjName];
    } else {
        return null;
    }
}

export function setSSGlobalOptionsObject(reportObjName, reportObjValue) {
    const ssGlobalOptions = JSON.parse(window.sessionStorage.getItem('globalOptions'));
    if (ssGlobalOptions) {
        ssGlobalOptions[reportObjName] = reportObjValue;
        window.sessionStorage.setItem('globalOptions', JSON.stringify(ssGlobalOptions));
    }
}

//<<< GLOBAL OPTIONS SESSION STORAGE MANAGEMENT <<<


//Convert a time string to 24h format and return the hour
//Input: "10:05:23 PM" or "10:05:23"
//Output: 22 or 10
export function extractHour(time_str) {
    if (time_str) {
        //attempt to convert to 24 hour format if hour pm is detected
        var time = time_str.match(/(\d+):(\d+):(\d+) (\w)/);
        if(time) {
            var hours = Number(time[1]);
            var meridian = time[4].toLowerCase();

            if (meridian == 'p' && hours < 12) {
                hours += 12;
            }

            return hours;
        } else {
            time = time_str.match(/(\d+):(\d+):(\d+)/);
            if(time) {
                return Number(time[1]);
            } else {
                return "";
            }
        }
    } else {
        return "";
    }
}

export function getNameforStyles(orignalFieldName) {
    if (orignalFieldName === undefined) return "undefined";
    let orignalName = orignalFieldName.toString();
    let covertedName = "";

    if (orignalName.includes("ANNUAL FTE COST"))
        covertedName = "annualFTECost";
    else if (orignalName.includes("ANNUAL FTE HOURS"))
        covertedName = "annualFTEHours";
    else if (orignalName.includes("TIME COST NON ERRORS"))
        covertedName = "LTNonError";
    else if (orignalName.includes("TIME COST SYSTEM ERRORS"))
        covertedName = "LTSysError";
    else if (orignalName.includes("TIME COST USER ERRORS"))
        covertedName = "LTUserError";
    else if (orignalName.includes("TIME COST UNCATEGORIZED"))
        covertedName = "LTUncatMessage";
    else if (orignalName.includes("PROJECTED DURATION"))
        covertedName = "projectDuration";
    else if (orignalName.includes("PROJECTED USERS"))
        covertedName = "projectUser";
    else if (orignalName.includes("TESTING EFFORT PER MODULE"))
        covertedName = "testEffortPerModule";

    return covertedName;
}


export function getTimeZoneList() {
    return [
        {
            "value": "Etc/GMT-14",
            "label": "(UTC+14:00) Etc/GMT-14 (GMT+14:00)",
            "offset": "+14:00",
            "defaults": true
        },
        {
            "value": "Pacific/Kiritimati",
            "label": "(UTC+14:00) Pacific/Kiritimati (Line Is. Time)",
            "offset": "+14:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT-13",
            "label": "(UTC+13:00) Etc/GMT-13 (GMT+13:00)",
            "offset": "+13:00",
            "defaults": true
        },
        {
            "value": "Pacific/Apia",
            "label": "(UTC+13:00) Pacific/Apia (Apia Time)",
            "offset": "+13:00",
            "defaults": false
        },
        {
            "value": "Pacific/Enderbury",
            "label": "(UTC+13:00) Pacific/Enderbury (Phoenix Is. Time)",
            "offset": "+13:00",
            "defaults": false
        },
        {
            "value": "Pacific/Fakaofo",
            "label": "(UTC+13:00) Pacific/Fakaofo (Tokelau Time)",
            "offset": "+13:00",
            "defaults": false
        },
        {
            "value": "Pacific/Kanton",
            "label": "(UTC+13:00) Pacific/Kanton (Kanton Time)",
            "offset": "+13:00",
            "defaults": false
        },
        {
            "value": "Pacific/Tongatapu",
            "label": "(UTC+13:00) Pacific/Tongatapu (Tonga Time)",
            "offset": "+13:00",
            "defaults": false
        },
        {
            "value": "NZ-CHAT",
            "label": "(UTC+12:45) NZ-CHAT (Chatham Time)",
            "offset": "+12:45",
            "defaults": false
        },
        {
            "value": "Pacific/Chatham",
            "label": "(UTC+12:45) Pacific/Chatham (Chatham Time)",
            "offset": "+12:45",
            "defaults": false
        },
        {
            "value": "Antarctica/McMurdo",
            "label": "(UTC+12:00) Antarctica/McMurdo (New Zealand Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "Antarctica/South_Pole",
            "label": "(UTC+12:00) Antarctica/South_Pole (New Zealand Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "Asia/Anadyr",
            "label": "(UTC+12:00) Asia/Anadyr (Anadyr Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "Asia/Kamchatka",
            "label": "(UTC+12:00) Asia/Kamchatka (Petropavlovsk-Kamchatski Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT-12",
            "label": "(UTC+12:00) Etc/GMT-12 (GMT+12:00)",
            "offset": "+12:00",
            "defaults": true
        },
        {
            "value": "Kwajalein",
            "label": "(UTC+12:00) Kwajalein (Marshall Islands Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "NZ",
            "label": "(UTC+12:00) NZ (New Zealand Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "Pacific/Auckland",
            "label": "(UTC+12:00) Pacific/Auckland (New Zealand Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "Pacific/Fiji",
            "label": "(UTC+12:00) Pacific/Fiji (Fiji Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "Pacific/Funafuti",
            "label": "(UTC+12:00) Pacific/Funafuti (Tuvalu Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "Pacific/Kwajalein",
            "label": "(UTC+12:00) Pacific/Kwajalein (Marshall Islands Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "Pacific/Majuro",
            "label": "(UTC+12:00) Pacific/Majuro (Marshall Islands Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "Pacific/Nauru",
            "label": "(UTC+12:00) Pacific/Nauru (Nauru Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "Pacific/Tarawa",
            "label": "(UTC+12:00) Pacific/Tarawa (Gilbert Is. Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "Pacific/Wake",
            "label": "(UTC+12:00) Pacific/Wake (Wake Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "Pacific/Wallis",
            "label": "(UTC+12:00) Pacific/Wallis (Wallis \u0026 Futuna Time)",
            "offset": "+12:00",
            "defaults": false
        },
        {
            "value": "Antarctica/Casey",
            "label": "(UTC+11:00) Antarctica/Casey (Australian Western Time)",
            "offset": "+11:00",
            "defaults": false
        },
        {
            "value": "Asia/Magadan",
            "label": "(UTC+11:00) Asia/Magadan (Magadan Time)",
            "offset": "+11:00",
            "defaults": false
        },
        {
            "value": "Asia/Sakhalin",
            "label": "(UTC+11:00) Asia/Sakhalin (Sakhalin Time)",
            "offset": "+11:00",
            "defaults": false
        },
        {
            "value": "Asia/Srednekolymsk",
            "label": "(UTC+11:00) Asia/Srednekolymsk (Srednekolymsk Time)",
            "offset": "+11:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT-11",
            "label": "(UTC+11:00) Etc/GMT-11 (GMT+11:00)",
            "offset": "+11:00",
            "defaults": true
        },
        {
            "value": "Pacific/Bougainville",
            "label": "(UTC+11:00) Pacific/Bougainville (Bougainville Time)",
            "offset": "+11:00",
            "defaults": false
        },
        {
            "value": "Pacific/Efate",
            "label": "(UTC+11:00) Pacific/Efate (Vanuatu Time)",
            "offset": "+11:00",
            "defaults": false
        },
        {
            "value": "Pacific/Guadalcanal",
            "label": "(UTC+11:00) Pacific/Guadalcanal (Solomon Is. Time)",
            "offset": "+11:00",
            "defaults": false
        },
        {
            "value": "Pacific/Kosrae",
            "label": "(UTC+11:00) Pacific/Kosrae (Kosrae Time)",
            "offset": "+11:00",
            "defaults": false
        },
        {
            "value": "Pacific/Norfolk",
            "label": "(UTC+11:00) Pacific/Norfolk (Norfolk Island Time)",
            "offset": "+11:00",
            "defaults": false
        },
        {
            "value": "Pacific/Noumea",
            "label": "(UTC+11:00) Pacific/Noumea (New Caledonia Time)",
            "offset": "+11:00",
            "defaults": false
        },
        {
            "value": "Pacific/Pohnpei",
            "label": "(UTC+11:00) Pacific/Pohnpei (Ponape Time)",
            "offset": "+11:00",
            "defaults": false
        },
        {
            "value": "Pacific/Ponape",
            "label": "(UTC+11:00) Pacific/Ponape (Ponape Time)",
            "offset": "+11:00",
            "defaults": false
        },
        {
            "value": "Australia/LHI",
            "label": "(UTC+10:30) Australia/LHI (Lord Howe Time)",
            "offset": "+10:30",
            "defaults": false
        },
        {
            "value": "Australia/Lord_Howe",
            "label": "(UTC+10:30) Australia/Lord_Howe (Lord Howe Time)",
            "offset": "+10:30",
            "defaults": false
        },
        {
            "value": "Antarctica/DumontDUrville",
            "label": "(UTC+10:00) Antarctica/DumontDUrville (Dumont-d\u0027Urville Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Antarctica/Macquarie",
            "label": "(UTC+10:00) Antarctica/Macquarie (Eastern Australia Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Asia/Ust-Nera",
            "label": "(UTC+10:00) Asia/Ust-Nera (Vladivostok Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Asia/Vladivostok",
            "label": "(UTC+10:00) Asia/Vladivostok (Vladivostok Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Australia/ACT",
            "label": "(UTC+10:00) Australia/ACT (Eastern Australia Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Australia/Brisbane",
            "label": "(UTC+10:00) Australia/Brisbane (Eastern Australia Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Australia/Canberra",
            "label": "(UTC+10:00) Australia/Canberra (Eastern Australia Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Australia/Currie",
            "label": "(UTC+10:00) Australia/Currie (Eastern Australia Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Australia/Hobart",
            "label": "(UTC+10:00) Australia/Hobart (Eastern Australia Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Australia/Lindeman",
            "label": "(UTC+10:00) Australia/Lindeman (Eastern Australia Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Australia/Melbourne",
            "label": "(UTC+10:00) Australia/Melbourne (Eastern Australia Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Australia/NSW",
            "label": "(UTC+10:00) Australia/NSW (Eastern Australia Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Australia/Queensland",
            "label": "(UTC+10:00) Australia/Queensland (Eastern Australia Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Australia/Sydney",
            "label": "(UTC+10:00) Australia/Sydney (Eastern Australia Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Australia/Tasmania",
            "label": "(UTC+10:00) Australia/Tasmania (Eastern Australia Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Australia/Victoria",
            "label": "(UTC+10:00) Australia/Victoria (Eastern Australia Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT-10",
            "label": "(UTC+10:00) Etc/GMT-10 (GMT+10:00)",
            "offset": "+10:00",
            "defaults": true
        },
        {
            "value": "Pacific/Chuuk",
            "label": "(UTC+10:00) Pacific/Chuuk (Chuuk Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Pacific/Guam",
            "label": "(UTC+10:00) Pacific/Guam (Chamorro Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Pacific/Port_Moresby",
            "label": "(UTC+10:00) Pacific/Port_Moresby (Papua New Guinea Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Pacific/Saipan",
            "label": "(UTC+10:00) Pacific/Saipan (Chamorro Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Pacific/Truk",
            "label": "(UTC+10:00) Pacific/Truk (Chuuk Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Pacific/Yap",
            "label": "(UTC+10:00) Pacific/Yap (Chuuk Time)",
            "offset": "+10:00",
            "defaults": false
        },
        {
            "value": "Australia/Adelaide",
            "label": "(UTC+09:30) Australia/Adelaide (Central Australia Time)",
            "offset": "+09:30",
            "defaults": false
        },
        {
            "value": "Australia/Broken_Hill",
            "label": "(UTC+09:30) Australia/Broken_Hill (Central Australia Time)",
            "offset": "+09:30",
            "defaults": false
        },
        {
            "value": "Australia/Darwin",
            "label": "(UTC+09:30) Australia/Darwin (Central Australia Time)",
            "offset": "+09:30",
            "defaults": false
        },
        {
            "value": "Australia/North",
            "label": "(UTC+09:30) Australia/North (Central Australia Time)",
            "offset": "+09:30",
            "defaults": false
        },
        {
            "value": "Australia/South",
            "label": "(UTC+09:30) Australia/South (Central Australia Time)",
            "offset": "+09:30",
            "defaults": false
        },
        {
            "value": "Australia/Yancowinna",
            "label": "(UTC+09:30) Australia/Yancowinna (Central Australia Time)",
            "offset": "+09:30",
            "defaults": false
        },
        {
            "value": "Asia/Chita",
            "label": "(UTC+09:00) Asia/Chita (Yakutsk Time)",
            "offset": "+09:00",
            "defaults": false
        },
        {
            "value": "Asia/Dili",
            "label": "(UTC+09:00) Asia/Dili (Timor-Leste Time)",
            "offset": "+09:00",
            "defaults": false
        },
        {
            "value": "Asia/Jayapura",
            "label": "(UTC+09:00) Asia/Jayapura (East Indonesia Time)",
            "offset": "+09:00",
            "defaults": false
        },
        {
            "value": "Asia/Khandyga",
            "label": "(UTC+09:00) Asia/Khandyga (Yakutsk Time)",
            "offset": "+09:00",
            "defaults": false
        },
        {
            "value": "Asia/Pyongyang",
            "label": "(UTC+09:00) Asia/Pyongyang (Korean Time)",
            "offset": "+09:00",
            "defaults": false
        },
        {
            "value": "Asia/Seoul",
            "label": "(UTC+09:00) Asia/Seoul (Korean Time)",
            "offset": "+09:00",
            "defaults": false
        },
        {
            "value": "Asia/Tokyo",
            "label": "(UTC+09:00) Asia/Tokyo (Japan Time)",
            "offset": "+09:00",
            "defaults": false
        },
        {
            "value": "Asia/Yakutsk",
            "label": "(UTC+09:00) Asia/Yakutsk (Yakutsk Time)",
            "offset": "+09:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT-9",
            "label": "(UTC+09:00) Etc/GMT-9 (GMT+09:00)",
            "offset": "+09:00",
            "defaults": true
        },
        {
            "value": "Japan",
            "label": "(UTC+09:00) Japan (Japan Time)",
            "offset": "+09:00",
            "defaults": false
        },
        {
            "value": "Pacific/Palau",
            "label": "(UTC+09:00) Pacific/Palau (Palau Time)",
            "offset": "+09:00",
            "defaults": false
        },
        {
            "value": "ROK",
            "label": "(UTC+09:00) ROK (Korean Time)",
            "offset": "+09:00",
            "defaults": false
        },
        {
            "value": "Australia/Eucla",
            "label": "(UTC+08:45) Australia/Eucla (Australian Central Western Time)",
            "offset": "+08:45",
            "defaults": false
        },
        {
            "value": "Asia/Brunei",
            "label": "(UTC+08:00) Asia/Brunei (Brunei Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Choibalsan",
            "label": "(UTC+08:00) Asia/Choibalsan (Ulaanbaatar Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Chongqing",
            "label": "(UTC+08:00) Asia/Chongqing (China Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Chungking",
            "label": "(UTC+08:00) Asia/Chungking (China Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Harbin",
            "label": "(UTC+08:00) Asia/Harbin (China Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Hong_Kong",
            "label": "(UTC+08:00) Asia/Hong_Kong (Hong Kong Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Irkutsk",
            "label": "(UTC+08:00) Asia/Irkutsk (Irkutsk Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Kuala_Lumpur",
            "label": "(UTC+08:00) Asia/Kuala_Lumpur (Malaysia Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Kuching",
            "label": "(UTC+08:00) Asia/Kuching (Malaysia Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Macao",
            "label": "(UTC+08:00) Asia/Macao (China Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Macau",
            "label": "(UTC+08:00) Asia/Macau (China Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Makassar",
            "label": "(UTC+08:00) Asia/Makassar (Central Indonesia Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Manila",
            "label": "(UTC+08:00) Asia/Manila (Philippine Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Shanghai",
            "label": "(UTC+08:00) Asia/Shanghai (China Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Singapore",
            "label": "(UTC+08:00) Asia/Singapore (Singapore Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Taipei",
            "label": "(UTC+08:00) Asia/Taipei (Taipei Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Ujung_Pandang",
            "label": "(UTC+08:00) Asia/Ujung_Pandang (Central Indonesia Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Ulaanbaatar",
            "label": "(UTC+08:00) Asia/Ulaanbaatar (Ulaanbaatar Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Asia/Ulan_Bator",
            "label": "(UTC+08:00) Asia/Ulan_Bator (Ulaanbaatar Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Australia/Perth",
            "label": "(UTC+08:00) Australia/Perth (Western Australia Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Australia/West",
            "label": "(UTC+08:00) Australia/West (Western Australia Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT-8",
            "label": "(UTC+08:00) Etc/GMT-8 (GMT+08:00)",
            "offset": "+08:00",
            "defaults": true
        },
        {
            "value": "Hongkong",
            "label": "(UTC+08:00) Hongkong (Hong Kong Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "PRC",
            "label": "(UTC+08:00) PRC (China Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Singapore",
            "label": "(UTC+08:00) Singapore (Singapore Time)",
            "offset": "+08:00",
            "defaults": false
        },
        {
            "value": "Antarctica/Davis",
            "label": "(UTC+07:00) Antarctica/Davis (Davis Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Asia/Bangkok",
            "label": "(UTC+07:00) Asia/Bangkok (Indochina Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Asia/Barnaul",
            "label": "(UTC+07:00) Asia/Barnaul (Barnaul Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Asia/Ho_Chi_Minh",
            "label": "(UTC+07:00) Asia/Ho_Chi_Minh (Indochina Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Asia/Hovd",
            "label": "(UTC+07:00) Asia/Hovd (Hovd Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Asia/Jakarta",
            "label": "(UTC+07:00) Asia/Jakarta (West Indonesia Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Asia/Krasnoyarsk",
            "label": "(UTC+07:00) Asia/Krasnoyarsk (Krasnoyarsk Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Asia/Novokuznetsk",
            "label": "(UTC+07:00) Asia/Novokuznetsk (Krasnoyarsk Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Asia/Novosibirsk",
            "label": "(UTC+07:00) Asia/Novosibirsk (Novosibirsk Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Asia/Phnom_Penh",
            "label": "(UTC+07:00) Asia/Phnom_Penh (Indochina Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Asia/Pontianak",
            "label": "(UTC+07:00) Asia/Pontianak (West Indonesia Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Asia/Saigon",
            "label": "(UTC+07:00) Asia/Saigon (Indochina Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Asia/Tomsk",
            "label": "(UTC+07:00) Asia/Tomsk (Tomsk Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Asia/Vientiane",
            "label": "(UTC+07:00) Asia/Vientiane (Indochina Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT-7",
            "label": "(UTC+07:00) Etc/GMT-7 (GMT+07:00)",
            "offset": "+07:00",
            "defaults": true
        },
        {
            "value": "Indian/Christmas",
            "label": "(UTC+07:00) Indian/Christmas (Christmas Island Time)",
            "offset": "+07:00",
            "defaults": false
        },
        {
            "value": "Asia/Rangoon",
            "label": "(UTC+06:30) Asia/Rangoon (Myanmar Time)",
            "offset": "+06:30",
            "defaults": false
        },
        {
            "value": "Asia/Yangon",
            "label": "(UTC+06:30) Asia/Yangon (Myanmar Time)",
            "offset": "+06:30",
            "defaults": false
        },
        {
            "value": "Indian/Cocos",
            "label": "(UTC+06:30) Indian/Cocos (Cocos Islands Time)",
            "offset": "+06:30",
            "defaults": false
        },
        {
            "value": "Antarctica/Vostok",
            "label": "(UTC+06:00) Antarctica/Vostok (Vostok Time)",
            "offset": "+06:00",
            "defaults": false
        },
        {
            "value": "Asia/Almaty",
            "label": "(UTC+06:00) Asia/Almaty (Alma-Ata Time)",
            "offset": "+06:00",
            "defaults": false
        },
        {
            "value": "Asia/Bishkek",
            "label": "(UTC+06:00) Asia/Bishkek (Kirgizstan Time)",
            "offset": "+06:00",
            "defaults": false
        },
        {
            "value": "Asia/Dacca",
            "label": "(UTC+06:00) Asia/Dacca (Bangladesh Time)",
            "offset": "+06:00",
            "defaults": false
        },
        {
            "value": "Asia/Dhaka",
            "label": "(UTC+06:00) Asia/Dhaka (Bangladesh Time)",
            "offset": "+06:00",
            "defaults": false
        },
        {
            "value": "Asia/Kashgar",
            "label": "(UTC+06:00) Asia/Kashgar (Xinjiang Time)",
            "offset": "+06:00",
            "defaults": false
        },
        {
            "value": "Asia/Omsk",
            "label": "(UTC+06:00) Asia/Omsk (Omsk Time)",
            "offset": "+06:00",
            "defaults": false
        },
        {
            "value": "Asia/Qostanay",
            "label": "(UTC+06:00) Asia/Qostanay (Kostanay Time)",
            "offset": "+06:00",
            "defaults": false
        },
        {
            "value": "Asia/Thimbu",
            "label": "(UTC+06:00) Asia/Thimbu (Bhutan Time)",
            "offset": "+06:00",
            "defaults": false
        },
        {
            "value": "Asia/Thimphu",
            "label": "(UTC+06:00) Asia/Thimphu (Bhutan Time)",
            "offset": "+06:00",
            "defaults": false
        },
        {
            "value": "Asia/Urumqi",
            "label": "(UTC+06:00) Asia/Urumqi (Xinjiang Time)",
            "offset": "+06:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT-6",
            "label": "(UTC+06:00) Etc/GMT-6 (GMT+06:00)",
            "offset": "+06:00",
            "defaults": true
        },
        {
            "value": "Indian/Chagos",
            "label": "(UTC+06:00) Indian/Chagos (Indian Ocean Territory Time)",
            "offset": "+06:00",
            "defaults": false
        },
        {
            "value": "Asia/Kathmandu",
            "label": "(UTC+05:45) Asia/Kathmandu (Nepal Time)",
            "offset": "+05:45",
            "defaults": false
        },
        {
            "value": "Asia/Katmandu",
            "label": "(UTC+05:45) Asia/Katmandu (Nepal Time)",
            "offset": "+05:45",
            "defaults": false
        },
        {
            "value": "Asia/Calcutta",
            "label": "(UTC+05:30) Asia/Calcutta (India Time)",
            "offset": "+05:30",
            "defaults": false
        },
        {
            "value": "Asia/Colombo",
            "label": "(UTC+05:30) Asia/Colombo (India Time)",
            "offset": "+05:30",
            "defaults": false
        },
        {
            "value": "Asia/Kolkata",
            "label": "(UTC+05:30) Asia/Kolkata (India Time)",
            "offset": "+05:30",
            "defaults": false
        },
        {
            "value": "Antarctica/Mawson",
            "label": "(UTC+05:00) Antarctica/Mawson (Mawson Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Asia/Aqtau",
            "label": "(UTC+05:00) Asia/Aqtau (Aqtau Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Asia/Aqtobe",
            "label": "(UTC+05:00) Asia/Aqtobe (Aqtobe Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Asia/Ashgabat",
            "label": "(UTC+05:00) Asia/Ashgabat (Turkmenistan Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Asia/Ashkhabad",
            "label": "(UTC+05:00) Asia/Ashkhabad (Turkmenistan Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Asia/Atyrau",
            "label": "(UTC+05:00) Asia/Atyrau (Atyrau Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Asia/Dushanbe",
            "label": "(UTC+05:00) Asia/Dushanbe (Tajikistan Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Asia/Karachi",
            "label": "(UTC+05:00) Asia/Karachi (Pakistan Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Asia/Oral",
            "label": "(UTC+05:00) Asia/Oral (Oral Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Asia/Qyzylorda",
            "label": "(UTC+05:00) Asia/Qyzylorda (Qyzylorda Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Asia/Samarkand",
            "label": "(UTC+05:00) Asia/Samarkand (Uzbekistan Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Asia/Tashkent",
            "label": "(UTC+05:00) Asia/Tashkent (Uzbekistan Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Asia/Yekaterinburg",
            "label": "(UTC+05:00) Asia/Yekaterinburg (Yekaterinburg Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT-5",
            "label": "(UTC+05:00) Etc/GMT-5 (GMT+05:00)",
            "offset": "+05:00",
            "defaults": true
        },
        {
            "value": "Indian/Kerguelen",
            "label": "(UTC+05:00) Indian/Kerguelen (French Southern \u0026 Antarctic Lands Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Indian/Maldives",
            "label": "(UTC+05:00) Indian/Maldives (Maldives Time)",
            "offset": "+05:00",
            "defaults": false
        },
        {
            "value": "Asia/Kabul",
            "label": "(UTC+04:30) Asia/Kabul (Afghanistan Time)",
            "offset": "+04:30",
            "defaults": false
        },
        {
            "value": "Asia/Baku",
            "label": "(UTC+04:00) Asia/Baku (Azerbaijan Time)",
            "offset": "+04:00",
            "defaults": false
        },
        {
            "value": "Asia/Dubai",
            "label": "(UTC+04:00) Asia/Dubai (Gulf Time)",
            "offset": "+04:00",
            "defaults": false
        },
        {
            "value": "Asia/Muscat",
            "label": "(UTC+04:00) Asia/Muscat (Gulf Time)",
            "offset": "+04:00",
            "defaults": false
        },
        {
            "value": "Asia/Tbilisi",
            "label": "(UTC+04:00) Asia/Tbilisi (Georgia Time)",
            "offset": "+04:00",
            "defaults": false
        },
        {
            "value": "Asia/Yerevan",
            "label": "(UTC+04:00) Asia/Yerevan (Armenia Time)",
            "offset": "+04:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT-4",
            "label": "(UTC+04:00) Etc/GMT-4 (GMT+04:00)",
            "offset": "+04:00",
            "defaults": true
        },
        {
            "value": "Europe/Astrakhan",
            "label": "(UTC+04:00) Europe/Astrakhan (Astrakhan Time)",
            "offset": "+04:00",
            "defaults": false
        },
        {
            "value": "Europe/Samara",
            "label": "(UTC+04:00) Europe/Samara (Samara Time)",
            "offset": "+04:00",
            "defaults": false
        },
        {
            "value": "Europe/Saratov",
            "label": "(UTC+04:00) Europe/Saratov (Saratov Time)",
            "offset": "+04:00",
            "defaults": false
        },
        {
            "value": "Europe/Ulyanovsk",
            "label": "(UTC+04:00) Europe/Ulyanovsk (Ulyanovsk Time)",
            "offset": "+04:00",
            "defaults": false
        },
        {
            "value": "Indian/Mahe",
            "label": "(UTC+04:00) Indian/Mahe (Seychelles Time)",
            "offset": "+04:00",
            "defaults": false
        },
        {
            "value": "Indian/Mauritius",
            "label": "(UTC+04:00) Indian/Mauritius (Mauritius Time)",
            "offset": "+04:00",
            "defaults": false
        },
        {
            "value": "Indian/Reunion",
            "label": "(UTC+04:00) Indian/Reunion (Reunion Time)",
            "offset": "+04:00",
            "defaults": false
        },
        {
            "value": "Asia/Tehran",
            "label": "(UTC+03:30) Asia/Tehran (Iran Time)",
            "offset": "+03:30",
            "defaults": false
        },
        {
            "value": "Iran",
            "label": "(UTC+03:30) Iran (Iran Time)",
            "offset": "+03:30",
            "defaults": false
        },
        {
            "value": "Africa/Addis_Ababa",
            "label": "(UTC+03:00) Africa/Addis_Ababa (Eastern Africa Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Africa/Asmara",
            "label": "(UTC+03:00) Africa/Asmara (Eastern Africa Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Africa/Asmera",
            "label": "(UTC+03:00) Africa/Asmera (Eastern Africa Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Africa/Dar_es_Salaam",
            "label": "(UTC+03:00) Africa/Dar_es_Salaam (Eastern Africa Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Africa/Djibouti",
            "label": "(UTC+03:00) Africa/Djibouti (Eastern Africa Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Africa/Kampala",
            "label": "(UTC+03:00) Africa/Kampala (Eastern Africa Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Africa/Mogadishu",
            "label": "(UTC+03:00) Africa/Mogadishu (Eastern Africa Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Africa/Nairobi",
            "label": "(UTC+03:00) Africa/Nairobi (Eastern Africa Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Antarctica/Syowa",
            "label": "(UTC+03:00) Antarctica/Syowa (Syowa Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Aden",
            "label": "(UTC+03:00) Asia/Aden (Arabian Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Baghdad",
            "label": "(UTC+03:00) Asia/Baghdad (Arabian Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Bahrain",
            "label": "(UTC+03:00) Asia/Bahrain (Arabian Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Istanbul",
            "label": "(UTC+03:00) Asia/Istanbul (Turkey Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Kuwait",
            "label": "(UTC+03:00) Asia/Kuwait (Arabian Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Qatar",
            "label": "(UTC+03:00) Asia/Qatar (Arabian Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Riyadh",
            "label": "(UTC+03:00) Asia/Riyadh (Arabian Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT-3",
            "label": "(UTC+03:00) Etc/GMT-3 (GMT+03:00)",
            "offset": "+03:00",
            "defaults": true
        },
        {
            "value": "Europe/Istanbul",
            "label": "(UTC+03:00) Europe/Istanbul (Turkey Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Kirov",
            "label": "(UTC+03:00) Europe/Kirov (Kirov Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Minsk",
            "label": "(UTC+03:00) Europe/Minsk (Moscow Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Moscow",
            "label": "(UTC+03:00) Europe/Moscow (Moscow Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Simferopol",
            "label": "(UTC+03:00) Europe/Simferopol (Moscow Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Volgograd",
            "label": "(UTC+03:00) Europe/Volgograd (Volgograd Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Indian/Antananarivo",
            "label": "(UTC+03:00) Indian/Antananarivo (Eastern Africa Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Indian/Comoro",
            "label": "(UTC+03:00) Indian/Comoro (Eastern Africa Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Indian/Mayotte",
            "label": "(UTC+03:00) Indian/Mayotte (Eastern Africa Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Turkey",
            "label": "(UTC+03:00) Turkey (Turkey Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "W-SU",
            "label": "(UTC+03:00) W-SU (Moscow Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Africa/Blantyre",
            "label": "(UTC+02:00) Africa/Blantyre (Central Africa Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Bujumbura",
            "label": "(UTC+02:00) Africa/Bujumbura (Central Africa Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Cairo",
            "label": "(UTC+02:00) Africa/Cairo (Eastern European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Gaborone",
            "label": "(UTC+02:00) Africa/Gaborone (Central Africa Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Harare",
            "label": "(UTC+02:00) Africa/Harare (Central Africa Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Johannesburg",
            "label": "(UTC+02:00) Africa/Johannesburg (South Africa Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Juba",
            "label": "(UTC+02:00) Africa/Juba (Central Africa Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Khartoum",
            "label": "(UTC+02:00) Africa/Khartoum (Central Africa Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Kigali",
            "label": "(UTC+02:00) Africa/Kigali (Central Africa Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Lubumbashi",
            "label": "(UTC+02:00) Africa/Lubumbashi (Central Africa Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Lusaka",
            "label": "(UTC+02:00) Africa/Lusaka (Central Africa Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Maputo",
            "label": "(UTC+02:00) Africa/Maputo (Central Africa Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Maseru",
            "label": "(UTC+02:00) Africa/Maseru (South Africa Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Mbabane",
            "label": "(UTC+02:00) Africa/Mbabane (South Africa Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Tripoli",
            "label": "(UTC+02:00) Africa/Tripoli (Eastern European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Asia/Amman",
            "label": "(UTC+02:00) Asia/Amman (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Beirut",
            "label": "(UTC+02:00) Asia/Beirut (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Damascus",
            "label": "(UTC+02:00) Asia/Damascus (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Famagusta",
            "label": "(UTC+02:00) Asia/Famagusta (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Gaza",
            "label": "(UTC+02:00) Asia/Gaza (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Hebron",
            "label": "(UTC+02:00) Asia/Hebron (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Jerusalem",
            "label": "(UTC+02:00) Asia/Jerusalem (Israel Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Nicosia",
            "label": "(UTC+02:00) Asia/Nicosia (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Asia/Tel_Aviv",
            "label": "(UTC+02:00) Asia/Tel_Aviv (Israel Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "EET",
            "label": "(UTC+02:00) EET (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Egypt",
            "label": "(UTC+02:00) Egypt (Eastern European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT-2",
            "label": "(UTC+02:00) Etc/GMT-2 (GMT+02:00)",
            "offset": "+02:00",
            "defaults": true
        },
        {
            "value": "Europe/Athens",
            "label": "(UTC+02:00) Europe/Athens (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Bucharest",
            "label": "(UTC+02:00) Europe/Bucharest (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Chisinau",
            "label": "(UTC+02:00) Europe/Chisinau (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Helsinki",
            "label": "(UTC+02:00) Europe/Helsinki (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Kaliningrad",
            "label": "(UTC+02:00) Europe/Kaliningrad (Eastern European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Kiev",
            "label": "(UTC+02:00) Europe/Kiev (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Kyiv",
            "label": "(UTC+02:00) Europe/Kyiv (Kyiv Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Mariehamn",
            "label": "(UTC+02:00) Europe/Mariehamn (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Nicosia",
            "label": "(UTC+02:00) Europe/Nicosia (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Riga",
            "label": "(UTC+02:00) Europe/Riga (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Sofia",
            "label": "(UTC+02:00) Europe/Sofia (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Tallinn",
            "label": "(UTC+02:00) Europe/Tallinn (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Tiraspol",
            "label": "(UTC+02:00) Europe/Tiraspol (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Uzhgorod",
            "label": "(UTC+02:00) Europe/Uzhgorod (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Vilnius",
            "label": "(UTC+02:00) Europe/Vilnius (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Europe/Zaporozhye",
            "label": "(UTC+02:00) Europe/Zaporozhye (Eastern European Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Israel",
            "label": "(UTC+02:00) Israel (Israel Time)",
            "offset": "+03:00",
            "defaults": false
        },
        {
            "value": "Libya",
            "label": "(UTC+02:00) Libya (Eastern European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Algiers",
            "label": "(UTC+01:00) Africa/Algiers (Central European Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Bangui",
            "label": "(UTC+01:00) Africa/Bangui (West Africa Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Brazzaville",
            "label": "(UTC+01:00) Africa/Brazzaville (West Africa Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Ceuta",
            "label": "(UTC+01:00) Africa/Ceuta (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Douala",
            "label": "(UTC+01:00) Africa/Douala (West Africa Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Kinshasa",
            "label": "(UTC+01:00) Africa/Kinshasa (West Africa Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Lagos",
            "label": "(UTC+01:00) Africa/Lagos (West Africa Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Libreville",
            "label": "(UTC+01:00) Africa/Libreville (West Africa Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Luanda",
            "label": "(UTC+01:00) Africa/Luanda (West Africa Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Malabo",
            "label": "(UTC+01:00) Africa/Malabo (West Africa Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Ndjamena",
            "label": "(UTC+01:00) Africa/Ndjamena (West Africa Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Niamey",
            "label": "(UTC+01:00) Africa/Niamey (West Africa Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Porto-Novo",
            "label": "(UTC+01:00) Africa/Porto-Novo (West Africa Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Tunis",
            "label": "(UTC+01:00) Africa/Tunis (Central European Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Windhoek",
            "label": "(UTC+01:00) Africa/Windhoek (Central African Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Arctic/Longyearbyen",
            "label": "(UTC+01:00) Arctic/Longyearbyen (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Atlantic/Jan_Mayen",
            "label": "(UTC+01:00) Atlantic/Jan_Mayen (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "CET",
            "label": "(UTC+01:00) CET (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT-1",
            "label": "(UTC+01:00) Etc/GMT-1 (GMT+01:00)",
            "offset": "+01:00",
            "defaults": true
        },
        {
            "value": "Europe/Amsterdam",
            "label": "(UTC+01:00) Europe/Amsterdam (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Andorra",
            "label": "(UTC+01:00) Europe/Andorra (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Belgrade",
            "label": "(UTC+01:00) Europe/Belgrade (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Berlin",
            "label": "(UTC+01:00) Europe/Berlin (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Bratislava",
            "label": "(UTC+01:00) Europe/Bratislava (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Brussels",
            "label": "(UTC+01:00) Europe/Brussels (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Budapest",
            "label": "(UTC+01:00) Europe/Budapest (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Busingen",
            "label": "(UTC+01:00) Europe/Busingen (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Copenhagen",
            "label": "(UTC+01:00) Europe/Copenhagen (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Gibraltar",
            "label": "(UTC+01:00) Europe/Gibraltar (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Ljubljana",
            "label": "(UTC+01:00) Europe/Ljubljana (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Luxembourg",
            "label": "(UTC+01:00) Europe/Luxembourg (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Madrid",
            "label": "(UTC+01:00) Europe/Madrid (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Malta",
            "label": "(UTC+01:00) Europe/Malta (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Monaco",
            "label": "(UTC+01:00) Europe/Monaco (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Oslo",
            "label": "(UTC+01:00) Europe/Oslo (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Paris",
            "label": "(UTC+01:00) Europe/Paris (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Podgorica",
            "label": "(UTC+01:00) Europe/Podgorica (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Prague",
            "label": "(UTC+01:00) Europe/Prague (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Rome",
            "label": "(UTC+01:00) Europe/Rome (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/San_Marino",
            "label": "(UTC+01:00) Europe/San_Marino (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Sarajevo",
            "label": "(UTC+01:00) Europe/Sarajevo (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Skopje",
            "label": "(UTC+01:00) Europe/Skopje (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Stockholm",
            "label": "(UTC+01:00) Europe/Stockholm (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Tirane",
            "label": "(UTC+01:00) Europe/Tirane (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Vaduz",
            "label": "(UTC+01:00) Europe/Vaduz (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Vatican",
            "label": "(UTC+01:00) Europe/Vatican (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Vienna",
            "label": "(UTC+01:00) Europe/Vienna (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Warsaw",
            "label": "(UTC+01:00) Europe/Warsaw (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Zagreb",
            "label": "(UTC+01:00) Europe/Zagreb (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Europe/Zurich",
            "label": "(UTC+01:00) Europe/Zurich (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "MET",
            "label": "(UTC+01:00) MET (Middle Europe Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Poland",
            "label": "(UTC+01:00) Poland (Central European Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Africa/Abidjan",
            "label": "(UTC+00:00) Africa/Abidjan (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Africa/Accra",
            "label": "(UTC+00:00) Africa/Accra (Ghana Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Africa/Bamako",
            "label": "(UTC+00:00) Africa/Bamako (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Africa/Banjul",
            "label": "(UTC+00:00) Africa/Banjul (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Africa/Bissau",
            "label": "(UTC+00:00) Africa/Bissau (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Africa/Casablanca",
            "label": "(UTC+00:00) Africa/Casablanca (Western European Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Conakry",
            "label": "(UTC+00:00) Africa/Conakry (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Africa/Dakar",
            "label": "(UTC+00:00) Africa/Dakar (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Africa/El_Aaiun",
            "label": "(UTC+00:00) Africa/El_Aaiun (Western European Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Africa/Freetown",
            "label": "(UTC+00:00) Africa/Freetown (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Africa/Lome",
            "label": "(UTC+00:00) Africa/Lome (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Africa/Monrovia",
            "label": "(UTC+00:00) Africa/Monrovia (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Africa/Nouakchott",
            "label": "(UTC+00:00) Africa/Nouakchott (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Africa/Ouagadougou",
            "label": "(UTC+00:00) Africa/Ouagadougou (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Africa/Sao_Tome",
            "label": "(UTC+00:00) Africa/Sao_Tome (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Africa/Timbuktu",
            "label": "(UTC+00:00) Africa/Timbuktu (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "America/Danmarkshavn",
            "label": "(UTC+00:00) America/Danmarkshavn (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Antarctica/Troll",
            "label": "(UTC+00:00) Antarctica/Troll (Troll Time)",
            "offset": "+02:00",
            "defaults": false
        },
        {
            "value": "Atlantic/Canary",
            "label": "(UTC+00:00) Atlantic/Canary (Western European Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Atlantic/Faeroe",
            "label": "(UTC+00:00) Atlantic/Faeroe (Western European Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Atlantic/Faroe",
            "label": "(UTC+00:00) Atlantic/Faroe (Western European Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Atlantic/Madeira",
            "label": "(UTC+00:00) Atlantic/Madeira (Western European Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Atlantic/Reykjavik",
            "label": "(UTC+00:00) Atlantic/Reykjavik (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Atlantic/St_Helena",
            "label": "(UTC+00:00) Atlantic/St_Helena (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Eire",
            "label": "(UTC+00:00) Eire (Irish Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT",
            "label": "(UTC+00:00) Etc/GMT (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": true
        },
        {
            "value": "Etc/GMT+0",
            "label": "(UTC+00:00) Etc/GMT+0 (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": true
        },
        {
            "value": "Etc/GMT-0",
            "label": "(UTC+00:00) Etc/GMT-0 (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": true
        },
        {
            "value": "Etc/GMT0",
            "label": "(UTC+00:00) Etc/GMT0 (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": true
        },
        {
            "value": "Etc/Greenwich",
            "label": "(UTC+00:00) Etc/Greenwich (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Etc/UCT",
            "label": "(UTC+00:00) Etc/UCT (Coordinated Universal Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Etc/Universal",
            "label": "(UTC+00:00) Etc/Universal (Coordinated Universal Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Etc/UTC",
            "label": "(UTC+00:00) Etc/UTC (Coordinated Universal Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Etc/Zulu",
            "label": "(UTC+00:00) Etc/Zulu (Coordinated Universal Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Europe/Belfast",
            "label": "(UTC+00:00) Europe/Belfast (British Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Europe/Dublin",
            "label": "(UTC+00:00) Europe/Dublin (Irish Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Europe/Guernsey",
            "label": "(UTC+00:00) Europe/Guernsey (British Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Europe/Isle_of_Man",
            "label": "(UTC+00:00) Europe/Isle_of_Man (British Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Europe/Jersey",
            "label": "(UTC+00:00) Europe/Jersey (British Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Europe/Lisbon",
            "label": "(UTC+00:00) Europe/Lisbon (Western European Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Europe/London",
            "label": "(UTC+00:00) Europe/London (British Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "GB",
            "label": "(UTC+00:00) GB (British Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "GB-Eire",
            "label": "(UTC+00:00) GB-Eire (British Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "GMT",
            "label": "(UTC+00:00) GMT (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "GMT0",
            "label": "(UTC+00:00) GMT0 (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Greenwich",
            "label": "(UTC+00:00) Greenwich (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Iceland",
            "label": "(UTC+00:00) Iceland (Greenwich Mean Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Portugal",
            "label": "(UTC+00:00) Portugal (Western European Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "UCT",
            "label": "(UTC+00:00) UCT (Coordinated Universal Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Universal",
            "label": "(UTC+00:00) Universal (Coordinated Universal Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "UTC",
            "label": "(UTC+00:00) UTC (Coordinated Universal Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "WET",
            "label": "(UTC+00:00) WET (Western European Time)",
            "offset": "+01:00",
            "defaults": false
        },
        {
            "value": "Zulu",
            "label": "(UTC+00:00) Zulu (Coordinated Universal Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "America/Scoresbysund",
            "label": "(UTC-01:00) America/Scoresbysund (East Greenland Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Atlantic/Azores",
            "label": "(UTC-01:00) Atlantic/Azores (Azores Time)",
            "offset": "Z",
            "defaults": false
        },
        {
            "value": "Atlantic/Cape_Verde",
            "label": "(UTC-01:00) Atlantic/Cape_Verde (Cape Verde Time)",
            "offset": "-01:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT+1",
            "label": "(UTC-01:00) Etc/GMT+1 (GMT-01:00)",
            "offset": "-01:00",
            "defaults": true
        },
        {
            "value": "America/Noronha",
            "label": "(UTC-02:00) America/Noronha (Fernando de Noronha Time)",
            "offset": "-02:00",
            "defaults": false
        },
        {
            "value": "Atlantic/South_Georgia",
            "label": "(UTC-02:00) Atlantic/South_Georgia (South Georgia Time)",
            "offset": "-02:00",
            "defaults": false
        },
        {
            "value": "Brazil/DeNoronha",
            "label": "(UTC-02:00) Brazil/DeNoronha (Fernando de Noronha Time)",
            "offset": "-02:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT+2",
            "label": "(UTC-02:00) Etc/GMT+2 (GMT-02:00)",
            "offset": "-02:00",
            "defaults": true
        },
        {
            "value": "America/Araguaina",
            "label": "(UTC-03:00) America/Araguaina (Brasilia Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Argentina/Buenos_Aires",
            "label": "(UTC-03:00) America/Argentina/Buenos_Aires (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Argentina/Catamarca",
            "label": "(UTC-03:00) America/Argentina/Catamarca (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Argentina/ComodRivadavia",
            "label": "(UTC-03:00) America/Argentina/ComodRivadavia (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Argentina/Cordoba",
            "label": "(UTC-03:00) America/Argentina/Cordoba (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Argentina/Jujuy",
            "label": "(UTC-03:00) America/Argentina/Jujuy (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Argentina/La_Rioja",
            "label": "(UTC-03:00) America/Argentina/La_Rioja (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Argentina/Mendoza",
            "label": "(UTC-03:00) America/Argentina/Mendoza (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Argentina/Rio_Gallegos",
            "label": "(UTC-03:00) America/Argentina/Rio_Gallegos (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Argentina/Salta",
            "label": "(UTC-03:00) America/Argentina/Salta (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Argentina/San_Juan",
            "label": "(UTC-03:00) America/Argentina/San_Juan (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Argentina/San_Luis",
            "label": "(UTC-03:00) America/Argentina/San_Luis (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Argentina/Tucuman",
            "label": "(UTC-03:00) America/Argentina/Tucuman (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Argentina/Ushuaia",
            "label": "(UTC-03:00) America/Argentina/Ushuaia (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Bahia",
            "label": "(UTC-03:00) America/Bahia (Brasilia Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Belem",
            "label": "(UTC-03:00) America/Belem (Brasilia Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Buenos_Aires",
            "label": "(UTC-03:00) America/Buenos_Aires (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Catamarca",
            "label": "(UTC-03:00) America/Catamarca (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Cayenne",
            "label": "(UTC-03:00) America/Cayenne (French Guiana Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Cordoba",
            "label": "(UTC-03:00) America/Cordoba (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Fortaleza",
            "label": "(UTC-03:00) America/Fortaleza (Brasilia Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Godthab",
            "label": "(UTC-03:00) America/Godthab (West Greenland Time)",
            "offset": "-02:00",
            "defaults": false
        },
        {
            "value": "America/Jujuy",
            "label": "(UTC-03:00) America/Jujuy (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Maceio",
            "label": "(UTC-03:00) America/Maceio (Brasilia Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Mendoza",
            "label": "(UTC-03:00) America/Mendoza (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Miquelon",
            "label": "(UTC-03:00) America/Miquelon (St. Pierre \u0026 Miquelon Time)",
            "offset": "-02:00",
            "defaults": false
        },
        {
            "value": "America/Montevideo",
            "label": "(UTC-03:00) America/Montevideo (Uruguay Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Nuuk",
            "label": "(UTC-03:00) America/Nuuk (West Greenland Time)",
            "offset": "-02:00",
            "defaults": false
        },
        {
            "value": "America/Paramaribo",
            "label": "(UTC-03:00) America/Paramaribo (Suriname Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Punta_Arenas",
            "label": "(UTC-03:00) America/Punta_Arenas (Punta Arenas Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Recife",
            "label": "(UTC-03:00) America/Recife (Brasilia Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Rosario",
            "label": "(UTC-03:00) America/Rosario (Argentina Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Santarem",
            "label": "(UTC-03:00) America/Santarem (Brasilia Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Sao_Paulo",
            "label": "(UTC-03:00) America/Sao_Paulo (Brasilia Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "Antarctica/Palmer",
            "label": "(UTC-03:00) Antarctica/Palmer (Chile Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "Antarctica/Rothera",
            "label": "(UTC-03:00) Antarctica/Rothera (Rothera Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "Atlantic/Stanley",
            "label": "(UTC-03:00) Atlantic/Stanley (Falkland Islands Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "Brazil/East",
            "label": "(UTC-03:00) Brazil/East (Brasilia Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT+3",
            "label": "(UTC-03:00) Etc/GMT+3 (GMT-03:00)",
            "offset": "-03:00",
            "defaults": true
        },
        {
            "value": "America/St_Johns",
            "label": "(UTC-03:30) America/St_Johns (Newfoundland Time)",
            "offset": "-02:30",
            "defaults": false
        },
        {
            "value": "Canada/Newfoundland",
            "label": "(UTC-03:30) Canada/Newfoundland (Newfoundland Time)",
            "offset": "-02:30",
            "defaults": false
        },
        {
            "value": "America/Anguilla",
            "label": "(UTC-04:00) America/Anguilla (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Antigua",
            "label": "(UTC-04:00) America/Antigua (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Aruba",
            "label": "(UTC-04:00) America/Aruba (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Asuncion",
            "label": "(UTC-04:00) America/Asuncion (Paraguay Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Barbados",
            "label": "(UTC-04:00) America/Barbados (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Blanc-Sablon",
            "label": "(UTC-04:00) America/Blanc-Sablon (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Boa_Vista",
            "label": "(UTC-04:00) America/Boa_Vista (Amazon Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Campo_Grande",
            "label": "(UTC-04:00) America/Campo_Grande (Amazon Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Caracas",
            "label": "(UTC-04:00) America/Caracas (Venezuela Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Cuiaba",
            "label": "(UTC-04:00) America/Cuiaba (Amazon Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Curacao",
            "label": "(UTC-04:00) America/Curacao (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Dominica",
            "label": "(UTC-04:00) America/Dominica (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Glace_Bay",
            "label": "(UTC-04:00) America/Glace_Bay (Atlantic Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Goose_Bay",
            "label": "(UTC-04:00) America/Goose_Bay (Atlantic Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Grenada",
            "label": "(UTC-04:00) America/Grenada (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Guadeloupe",
            "label": "(UTC-04:00) America/Guadeloupe (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Guyana",
            "label": "(UTC-04:00) America/Guyana (Guyana Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Halifax",
            "label": "(UTC-04:00) America/Halifax (Atlantic Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Kralendijk",
            "label": "(UTC-04:00) America/Kralendijk (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/La_Paz",
            "label": "(UTC-04:00) America/La_Paz (Bolivia Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Lower_Princes",
            "label": "(UTC-04:00) America/Lower_Princes (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Manaus",
            "label": "(UTC-04:00) America/Manaus (Amazon Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Marigot",
            "label": "(UTC-04:00) America/Marigot (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Martinique",
            "label": "(UTC-04:00) America/Martinique (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Moncton",
            "label": "(UTC-04:00) America/Moncton (Atlantic Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Montserrat",
            "label": "(UTC-04:00) America/Montserrat (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Port_of_Spain",
            "label": "(UTC-04:00) America/Port_of_Spain (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Porto_Velho",
            "label": "(UTC-04:00) America/Porto_Velho (Amazon Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Puerto_Rico",
            "label": "(UTC-04:00) America/Puerto_Rico (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Santiago",
            "label": "(UTC-04:00) America/Santiago (Chile Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Santo_Domingo",
            "label": "(UTC-04:00) America/Santo_Domingo (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/St_Barthelemy",
            "label": "(UTC-04:00) America/St_Barthelemy (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/St_Kitts",
            "label": "(UTC-04:00) America/St_Kitts (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/St_Lucia",
            "label": "(UTC-04:00) America/St_Lucia (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/St_Thomas",
            "label": "(UTC-04:00) America/St_Thomas (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/St_Vincent",
            "label": "(UTC-04:00) America/St_Vincent (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Thule",
            "label": "(UTC-04:00) America/Thule (Atlantic Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Tortola",
            "label": "(UTC-04:00) America/Tortola (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Virgin",
            "label": "(UTC-04:00) America/Virgin (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "Atlantic/Bermuda",
            "label": "(UTC-04:00) Atlantic/Bermuda (Atlantic Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "Brazil/West",
            "label": "(UTC-04:00) Brazil/West (Amazon Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "Canada/Atlantic",
            "label": "(UTC-04:00) Canada/Atlantic (Atlantic Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "Chile/Continental",
            "label": "(UTC-04:00) Chile/Continental (Chile Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT+4",
            "label": "(UTC-04:00) Etc/GMT+4 (GMT-04:00)",
            "offset": "-04:00",
            "defaults": true
        },
        {
            "value": "SystemV/AST4",
            "label": "(UTC-04:00) SystemV/AST4 (Atlantic Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "SystemV/AST4ADT",
            "label": "(UTC-04:00) SystemV/AST4ADT (Atlantic Time)",
            "offset": "-03:00",
            "defaults": false
        },
        {
            "value": "America/Atikokan",
            "label": "(UTC-05:00) America/Atikokan (Eastern Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Bogota",
            "label": "(UTC-05:00) America/Bogota (Colombia Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Cancun",
            "label": "(UTC-05:00) America/Cancun (Eastern Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Cayman",
            "label": "(UTC-05:00) America/Cayman (Eastern Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Coral_Harbour",
            "label": "(UTC-05:00) America/Coral_Harbour (Eastern Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Detroit",
            "label": "(UTC-05:00) America/Detroit (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Eirunepe",
            "label": "(UTC-05:00) America/Eirunepe (Acre Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Fort_Wayne",
            "label": "(UTC-05:00) America/Fort_Wayne (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Grand_Turk",
            "label": "(UTC-05:00) America/Grand_Turk (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Guayaquil",
            "label": "(UTC-05:00) America/Guayaquil (Ecuador Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Havana",
            "label": "(UTC-05:00) America/Havana (Cuba Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Indiana/Indianapolis",
            "label": "(UTC-05:00) America/Indiana/Indianapolis (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Indiana/Marengo",
            "label": "(UTC-05:00) America/Indiana/Marengo (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Indiana/Petersburg",
            "label": "(UTC-05:00) America/Indiana/Petersburg (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Indiana/Vevay",
            "label": "(UTC-05:00) America/Indiana/Vevay (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Indiana/Vincennes",
            "label": "(UTC-05:00) America/Indiana/Vincennes (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Indiana/Winamac",
            "label": "(UTC-05:00) America/Indiana/Winamac (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Indianapolis",
            "label": "(UTC-05:00) America/Indianapolis (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Iqaluit",
            "label": "(UTC-05:00) America/Iqaluit (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Jamaica",
            "label": "(UTC-05:00) America/Jamaica (Eastern Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Kentucky/Louisville",
            "label": "(UTC-05:00) America/Kentucky/Louisville (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Kentucky/Monticello",
            "label": "(UTC-05:00) America/Kentucky/Monticello (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Lima",
            "label": "(UTC-05:00) America/Lima (Peru Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Louisville",
            "label": "(UTC-05:00) America/Louisville (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Montreal",
            "label": "(UTC-05:00) America/Montreal (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Nassau",
            "label": "(UTC-05:00) America/Nassau (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/New_York",
            "label": "(UTC-05:00) America/New_York (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Nipigon",
            "label": "(UTC-05:00) America/Nipigon (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Panama",
            "label": "(UTC-05:00) America/Panama (Eastern Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Pangnirtung",
            "label": "(UTC-05:00) America/Pangnirtung (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Port-au-Prince",
            "label": "(UTC-05:00) America/Port-au-Prince (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Porto_Acre",
            "label": "(UTC-05:00) America/Porto_Acre (Acre Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Rio_Branco",
            "label": "(UTC-05:00) America/Rio_Branco (Acre Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Thunder_Bay",
            "label": "(UTC-05:00) America/Thunder_Bay (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Toronto",
            "label": "(UTC-05:00) America/Toronto (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "Brazil/Acre",
            "label": "(UTC-05:00) Brazil/Acre (Acre Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "Canada/Eastern",
            "label": "(UTC-05:00) Canada/Eastern (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "Cuba",
            "label": "(UTC-05:00) Cuba (Cuba Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "EST5EDT",
            "label": "(UTC-05:00) EST5EDT (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT+5",
            "label": "(UTC-05:00) Etc/GMT+5 (GMT-05:00)",
            "offset": "-05:00",
            "defaults": true
        },
        {
            "value": "Jamaica",
            "label": "(UTC-05:00) Jamaica (Eastern Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "SystemV/EST5",
            "label": "(UTC-05:00) SystemV/EST5 (Eastern Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "SystemV/EST5EDT",
            "label": "(UTC-05:00) SystemV/EST5EDT (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "US/East-Indiana",
            "label": "(UTC-05:00) US/East-Indiana (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "US/Eastern",
            "label": "(UTC-05:00) US/Eastern (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "US/Michigan",
            "label": "(UTC-05:00) US/Michigan (Eastern Time)",
            "offset": "-04:00",
            "defaults": false
        },
        {
            "value": "America/Bahia_Banderas",
            "label": "(UTC-06:00) America/Bahia_Banderas (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Belize",
            "label": "(UTC-06:00) America/Belize (Central Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Chicago",
            "label": "(UTC-06:00) America/Chicago (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Costa_Rica",
            "label": "(UTC-06:00) America/Costa_Rica (Central Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/El_Salvador",
            "label": "(UTC-06:00) America/El_Salvador (Central Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Guatemala",
            "label": "(UTC-06:00) America/Guatemala (Central Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Indiana/Knox",
            "label": "(UTC-06:00) America/Indiana/Knox (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Indiana/Tell_City",
            "label": "(UTC-06:00) America/Indiana/Tell_City (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Knox_IN",
            "label": "(UTC-06:00) America/Knox_IN (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Managua",
            "label": "(UTC-06:00) America/Managua (Central Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Matamoros",
            "label": "(UTC-06:00) America/Matamoros (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Menominee",
            "label": "(UTC-06:00) America/Menominee (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Merida",
            "label": "(UTC-06:00) America/Merida (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Mexico_City",
            "label": "(UTC-06:00) America/Mexico_City (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Monterrey",
            "label": "(UTC-06:00) America/Monterrey (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/North_Dakota/Beulah",
            "label": "(UTC-06:00) America/North_Dakota/Beulah (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/North_Dakota/Center",
            "label": "(UTC-06:00) America/North_Dakota/Center (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/North_Dakota/New_Salem",
            "label": "(UTC-06:00) America/North_Dakota/New_Salem (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Rainy_River",
            "label": "(UTC-06:00) America/Rainy_River (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Rankin_Inlet",
            "label": "(UTC-06:00) America/Rankin_Inlet (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Regina",
            "label": "(UTC-06:00) America/Regina (Central Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Resolute",
            "label": "(UTC-06:00) America/Resolute (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Swift_Current",
            "label": "(UTC-06:00) America/Swift_Current (Central Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Tegucigalpa",
            "label": "(UTC-06:00) America/Tegucigalpa (Central Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Winnipeg",
            "label": "(UTC-06:00) America/Winnipeg (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "Canada/Central",
            "label": "(UTC-06:00) Canada/Central (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "Canada/Saskatchewan",
            "label": "(UTC-06:00) Canada/Saskatchewan (Central Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "Chile/EasterIsland",
            "label": "(UTC-06:00) Chile/EasterIsland (Easter Island Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "CST6CDT",
            "label": "(UTC-06:00) CST6CDT (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT+6",
            "label": "(UTC-06:00) Etc/GMT+6 (GMT-06:00)",
            "offset": "-06:00",
            "defaults": true
        },
        {
            "value": "Mexico/General",
            "label": "(UTC-06:00) Mexico/General (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "Pacific/Easter",
            "label": "(UTC-06:00) Pacific/Easter (Easter Island Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "Pacific/Galapagos",
            "label": "(UTC-06:00) Pacific/Galapagos (Galapagos Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "SystemV/CST6",
            "label": "(UTC-06:00) SystemV/CST6 (Central Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "SystemV/CST6CDT",
            "label": "(UTC-06:00) SystemV/CST6CDT (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "US/Central",
            "label": "(UTC-06:00) US/Central (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "US/Indiana-Starke",
            "label": "(UTC-06:00) US/Indiana-Starke (Central Time)",
            "offset": "-05:00",
            "defaults": false
        },
        {
            "value": "America/Boise",
            "label": "(UTC-07:00) America/Boise (Mountain Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Cambridge_Bay",
            "label": "(UTC-07:00) America/Cambridge_Bay (Mountain Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Chihuahua",
            "label": "(UTC-07:00) America/Chihuahua (Mexican Pacific Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Creston",
            "label": "(UTC-07:00) America/Creston (Mountain Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "America/Dawson",
            "label": "(UTC-07:00) America/Dawson (Mountain Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "America/Dawson_Creek",
            "label": "(UTC-07:00) America/Dawson_Creek (Mountain Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "America/Denver",
            "label": "(UTC-07:00) America/Denver (Mountain Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Edmonton",
            "label": "(UTC-07:00) America/Edmonton (Mountain Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Fort_Nelson",
            "label": "(UTC-07:00) America/Fort_Nelson (Mountain Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "America/Hermosillo",
            "label": "(UTC-07:00) America/Hermosillo (Mexican Pacific Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "America/Inuvik",
            "label": "(UTC-07:00) America/Inuvik (Mountain Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Mazatlan",
            "label": "(UTC-07:00) America/Mazatlan (Mexican Pacific Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Ojinaga",
            "label": "(UTC-07:00) America/Ojinaga (Mountain Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Phoenix",
            "label": "(UTC-07:00) America/Phoenix (Mountain Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "America/Shiprock",
            "label": "(UTC-07:00) America/Shiprock (Mountain Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Whitehorse",
            "label": "(UTC-07:00) America/Whitehorse (Mountain Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "America/Yellowknife",
            "label": "(UTC-07:00) America/Yellowknife (Mountain Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "Canada/Mountain",
            "label": "(UTC-07:00) Canada/Mountain (Mountain Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "Canada/Yukon",
            "label": "(UTC-07:00) Canada/Yukon (Mountain Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT+7",
            "label": "(UTC-07:00) Etc/GMT+7 (GMT-07:00)",
            "offset": "-07:00",
            "defaults": true
        },
        {
            "value": "Mexico/BajaSur",
            "label": "(UTC-07:00) Mexico/BajaSur (Mexican Pacific Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "MST7MDT",
            "label": "(UTC-07:00) MST7MDT (Mountain Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "Navajo",
            "label": "(UTC-07:00) Navajo (Mountain Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "SystemV/MST7",
            "label": "(UTC-07:00) SystemV/MST7 (Mountain Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "SystemV/MST7MDT",
            "label": "(UTC-07:00) SystemV/MST7MDT (Mountain Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "US/Arizona",
            "label": "(UTC-07:00) US/Arizona (Mountain Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "US/Mountain",
            "label": "(UTC-07:00) US/Mountain (Mountain Time)",
            "offset": "-06:00",
            "defaults": false
        },
        {
            "value": "America/Ensenada",
            "label": "(UTC-08:00) America/Ensenada (Pacific Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "America/Los_Angeles",
            "label": "(UTC-08:00) America/Los_Angeles (Pacific Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "America/Santa_Isabel",
            "label": "(UTC-08:00) America/Santa_Isabel (Northwest Mexico Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "America/Tijuana",
            "label": "(UTC-08:00) America/Tijuana (Pacific Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "America/Vancouver",
            "label": "(UTC-08:00) America/Vancouver (Pacific Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "Canada/Pacific",
            "label": "(UTC-08:00) Canada/Pacific (Pacific Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT+8",
            "label": "(UTC-08:00) Etc/GMT+8 (GMT-08:00)",
            "offset": "-08:00",
            "defaults": true
        },
        {
            "value": "Mexico/BajaNorte",
            "label": "(UTC-08:00) Mexico/BajaNorte (Pacific Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "Pacific/Pitcairn",
            "label": "(UTC-08:00) Pacific/Pitcairn (Pitcairn Time)",
            "offset": "-08:00",
            "defaults": false
        },
        {
            "value": "PST8PDT",
            "label": "(UTC-08:00) PST8PDT (Pacific Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "SystemV/PST8",
            "label": "(UTC-08:00) SystemV/PST8 (Pacific Time)",
            "offset": "-08:00",
            "defaults": false
        },
        {
            "value": "SystemV/PST8PDT",
            "label": "(UTC-08:00) SystemV/PST8PDT (Pacific Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "US/Pacific",
            "label": "(UTC-08:00) US/Pacific (Pacific Time)",
            "offset": "-07:00",
            "defaults": false
        },
        {
            "value": "America/Anchorage",
            "label": "(UTC-09:00) America/Anchorage (Alaska Time)",
            "offset": "-08:00",
            "defaults": false
        },
        {
            "value": "America/Juneau",
            "label": "(UTC-09:00) America/Juneau (Alaska Time)",
            "offset": "-08:00",
            "defaults": false
        },
        {
            "value": "America/Metlakatla",
            "label": "(UTC-09:00) America/Metlakatla (Alaska Time)",
            "offset": "-08:00",
            "defaults": false
        },
        {
            "value": "America/Nome",
            "label": "(UTC-09:00) America/Nome (Alaska Time)",
            "offset": "-08:00",
            "defaults": false
        },
        {
            "value": "America/Sitka",
            "label": "(UTC-09:00) America/Sitka (Alaska Time)",
            "offset": "-08:00",
            "defaults": false
        },
        {
            "value": "America/Yakutat",
            "label": "(UTC-09:00) America/Yakutat (Alaska Time)",
            "offset": "-08:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT+9",
            "label": "(UTC-09:00) Etc/GMT+9 (GMT-09:00)",
            "offset": "-09:00",
            "defaults": true
        },
        {
            "value": "Pacific/Gambier",
            "label": "(UTC-09:00) Pacific/Gambier (Gambier Time)",
            "offset": "-09:00",
            "defaults": false
        },
        {
            "value": "SystemV/YST9",
            "label": "(UTC-09:00) SystemV/YST9 (Alaska Time)",
            "offset": "-09:00",
            "defaults": false
        },
        {
            "value": "SystemV/YST9YDT",
            "label": "(UTC-09:00) SystemV/YST9YDT (Alaska Time)",
            "offset": "-08:00",
            "defaults": false
        },
        {
            "value": "US/Alaska",
            "label": "(UTC-09:00) US/Alaska (Alaska Time)",
            "offset": "-08:00",
            "defaults": false
        },
        {
            "value": "Pacific/Marquesas",
            "label": "(UTC-09:30) Pacific/Marquesas (Marquesas Time)",
            "offset": "-09:30",
            "defaults": false
        },
        {
            "value": "America/Adak",
            "label": "(UTC-10:00) America/Adak (Hawaii-Aleutian Time)",
            "offset": "-09:00",
            "defaults": false
        },
        {
            "value": "America/Atka",
            "label": "(UTC-10:00) America/Atka (Hawaii-Aleutian Time)",
            "offset": "-09:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT+10",
            "label": "(UTC-10:00) Etc/GMT+10 (GMT-10:00)",
            "offset": "-10:00",
            "defaults": true
        },
        {
            "value": "Pacific/Honolulu",
            "label": "(UTC-10:00) Pacific/Honolulu (Hawaii-Aleutian Time)",
            "offset": "-10:00",
            "defaults": false
        },
        {
            "value": "Pacific/Johnston",
            "label": "(UTC-10:00) Pacific/Johnston (Hawaii-Aleutian Time)",
            "offset": "-10:00",
            "defaults": false
        },
        {
            "value": "Pacific/Rarotonga",
            "label": "(UTC-10:00) Pacific/Rarotonga (Cook Islands Time)",
            "offset": "-10:00",
            "defaults": false
        },
        {
            "value": "Pacific/Tahiti",
            "label": "(UTC-10:00) Pacific/Tahiti (Tahiti Time)",
            "offset": "-10:00",
            "defaults": false
        },
        {
            "value": "SystemV/HST10",
            "label": "(UTC-10:00) SystemV/HST10 (Hawaii-Aleutian Time)",
            "offset": "-10:00",
            "defaults": false
        },
        {
            "value": "US/Aleutian",
            "label": "(UTC-10:00) US/Aleutian (Hawaii-Aleutian Time)",
            "offset": "-09:00",
            "defaults": false
        },
        {
            "value": "US/Hawaii",
            "label": "(UTC-10:00) US/Hawaii (Hawaii-Aleutian Time)",
            "offset": "-10:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT+11",
            "label": "(UTC-11:00) Etc/GMT+11 (GMT-11:00)",
            "offset": "-11:00",
            "defaults": true
        },
        {
            "value": "Pacific/Midway",
            "label": "(UTC-11:00) Pacific/Midway (Samoa Time)",
            "offset": "-11:00",
            "defaults": false
        },
        {
            "value": "Pacific/Niue",
            "label": "(UTC-11:00) Pacific/Niue (Niue Time)",
            "offset": "-11:00",
            "defaults": false
        },
        {
            "value": "Pacific/Pago_Pago",
            "label": "(UTC-11:00) Pacific/Pago_Pago (Samoa Time)",
            "offset": "-11:00",
            "defaults": false
        },
        {
            "value": "Pacific/Samoa",
            "label": "(UTC-11:00) Pacific/Samoa (Samoa Time)",
            "offset": "-11:00",
            "defaults": false
        },
        {
            "value": "US/Samoa",
            "label": "(UTC-11:00) US/Samoa (Samoa Time)",
            "offset": "-11:00",
            "defaults": false
        },
        {
            "value": "Etc/GMT+12",
            "label": "(UTC-12:00) Etc/GMT+12 (GMT-12:00)",
            "offset": "-12:00",
            "defaults": true
        }
    ]
}

export function getPossibleTimezone() {
   let offset = convertMinsToHrsMins(new Date().getTimezoneOffset());
   return getTimeZoneList().find(tz => tz.offset === offset && tz.defaults===true );
}

export function convertMinsToHrsMins(minutes) {
    // let sign = minutes > 0 ? 'GMT-' : 'GMT+';
    let sign = minutes > 0 ? '-' : '+';
    let absMinutes = Math.abs(minutes);
    let h = Math.floor(absMinutes / 60);
    let m = absMinutes % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return sign + h +":"+ m;
}

export function convertSeverMilliSecsToHrsMins(milliSeconds, dst) {
    let sign = milliSeconds < 0 ? 'GMT-' : 'GMT+';

    let absMilliSeconds = Math.abs(milliSeconds);
    if (dst === true) absMilliSeconds = absMilliSeconds - 3600000;
    let absMinutes = absMilliSeconds / 60000;
    let h = Math.floor(absMinutes / 60);
    let m = absMinutes % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return sign + h + m;
}

export function getLocalDateFromUTCTimestamp(milliSeconds) {

    const _date = new Date(milliSeconds);
    let userTimeZone = API.getUserTimeZone();
    if (userTimeZone==null || userTimeZone === "") {
        return _date.toLocaleDateString();
    }
    // because we do not have the locale and timezone ID information yet, will default to this format for now
    return _date.toLocaleString('en-US', { timeZone: userTimeZone });
}


export function getPartOfDate(date, part) {
    if (part === "month") {
        return date.getMonth();
    } else if (part === "day") {
        return date.getDate();
    } else if (part === "year") {
        return date.getFullYear();
    } else return date;
}

export function getDateFromOffset(date, gmtOffset) {
    let addOrMinues = gmtOffset.substr(3, 1);
    let hours = gmtOffset.substr(4, 2).startsWith('0') ? gmtOffset.substr(5, 1) : gmtOffset.substr(4, 2);
    let minutes = gmtOffset.substr(6, 2).startsWith('0') ? gmtOffset.substr(7, 1) : gmtOffset.substr(6, 2);

    if (hours === '0' && minutes === '0')
        return date;
    if (addOrMinues === '+') {
        date.setTime(date.getTime() + parseInt(hours) * 60 * 60 * 1000);
        date.setTime(date.getTime() + parseInt(minutes) * 60 * 1000);
    } else {
        date.setTime(date.getTime() - parseInt(hours) * 60 * 60 * 1000);
        date.setTime(date.getTime() - parseInt(minutes) * 60 * 1000);
    }
    return date;
}


// export function getUserTimezoneDate(){
//     let userTimeZone = API.getUserTimeZone();
//     if(userTimeZone==="")
//         return new Date();
//     else {
//         let offsetValue = userTimeZone.substr(3);
//         let offsetFinalValue = offsetValue.substr(0,3)+':'+offsetValue.substr(3);
//         return moment.utc(new Date().getTime()).utcOffset(offsetFinalValue).toDate();
//     }
// }

export function getReportHelpLink(reportTitle) {
    let baseUrl = "http://knoabi.helpdocsonline.com/";
    let reportUrl = "";
    if (reportTitle === undefined || reportTitle === null) {
        reportUrl = "home";
    } else {
        let nameArr = reportTitle.toLowerCase().split(' ');
        if (reportTitle.includes("Daily") || reportTitle.includes("daily") || reportTitle.includes("Monthly") || reportTitle.includes("monthly")) nameArr.pop();
        reportUrl = nameArr.join("_");
    }
    return baseUrl + reportUrl;

}

export function scrollbarForFilterPanel(oldHeight, newHeight, filterName) {
    const element = document.getElementById("filterPanelDiv");

    if (element !== null && oldHeight!==0 && oldHeight < newHeight) {
        if (filterName && (filterName === "APPLICATION NAME" || filterName === 'MODULE NAME')) {
            element.scrollBy(0, 12);
            console.log(filterName);
        } else
            element.scrollBy(0, newHeight - oldHeight);
        
    }
}
