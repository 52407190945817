import React, {Component} from "react";
import {Col, Row, ControlLabel, FormControl, FormGroup} from "react-bootstrap";
import CommonSelector from "../common/CommonSelector";

class ProjectInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teamFocused: false,
            unSelectedTeamOpts:[]
        };
        this.handleProjectNameChange = this.handleProjectNameChange.bind(this);
        this.handleProjectTeamChange = this.handleProjectTeamChange.bind(this);
    }

    handleProjectNameChange(e) {
        if (e.target.type === "text") {
            this.props.handleProjectNameUpdate(e.target.value);
        }
    }

    handleProjectTeamChange(e) {
        const projectTeam = e.map((user) => {
            return user.value;
        });
        this.props.handleProjectTeamUpdate(projectTeam);
    }


    handleProjectTeamInputChange = (inputValue) => {
        if (inputValue === "") return;
        let tempTeamOpts = [];
        let TeamOpts = this.props.userOptions;
        TeamOpts.forEach(opt=>{
            if(opt.checked === false && opt.label.toLowerCase().indexOf(inputValue.toLowerCase())>-1){
                tempTeamOpts.push(opt);
            }
        });
        this.setState({unSelectedTeamOpts: tempTeamOpts});
    };

    resetOptions = (event, filterName) => {
        switch(filterName) {
            case "team":   {
                let teamForReset = this.state.unSelectedTeamOpts;
                if(teamForReset) teamForReset = [];
                this.setState({
                    unSelectedTeamOpts: teamForReset
                });
            };
                break;
            default:  {
                console.warn("no match for user attribute filter .")
            }
        }
    };
    
    render() {
        const {projectName, projectTeam, userOptions, projectNameError, trans} = this.props;
        let selectedTeam = [];

        if (projectTeam && userOptions.length > 0) {
            selectedTeam = userOptions.filter(user => projectTeam.indexOf(user.value) > -1);
        }

        let userFilterOptions = this.state.unSelectedTeamOpts === undefined ? [] : this.state.unSelectedTeamOpts;
        
        return (
            <>
                <Row>
                    <Col md={10} mdOffset={1}>
                        <FormGroup role="group" aria-labelledby="projectInformation_head">
                            <h4 id="projectInformation_head" className={"knoa-region"} tabIndex={"-1"}>{trans('project:projectInformation')}</h4>
                            <Row className="card-row-flex">
                                <Col className="card-row-col-label" componentClass={ControlLabel} sm={1}>
                                    <label htmlFor= "projectName" className="col-control-sr-label">{trans('project:projectName')} <span className="text-danger-1">*</span></label>
                                </Col>
                                <Col sm={1} className="card-row-col-right">
                                    <FormControl
                                        name="projectName"
                                        id="projectName"
                                        type="text"
                                        value={projectName}
                                        name="project_name"
                                        placeholder={trans('project:exampleMyProject')}
                                        onChange={this.handleProjectNameChange}
                                        aria-required="true"
                                    />
                                </Col>
                            </Row>
                            {projectNameError &&
                            <Row className="card-row-flex formError">
                                <Col className="card-row-col-label noTextTranform" sm={1}></Col>
                                <Col sm={1} className="card-row-col-right">
                                    {projectNameError}
                                </Col>
                            </Row>
                            }
                            <Row className="card-row-flex">
                                <Col className="card-row-col-label" componentClass={ControlLabel} sm={1}>
                                    <label htmlFor= "projectTeamSelect" className="col-control-sr-label">{trans('project:shareWithUsers')}</label>
                                </Col>
                                <Col sm={1} className="card-row-col-right">
                                    <CommonSelector
                                        inputId = "projectTeamSelect"
                                        style={"typetosearch"}
                                        componentLocation={"normal"}
                                        name="projectTeamSelect"
                                        placeholder={this.state.teamFocused ? "" : trans('common:typeToSearch')}
                                        value={selectedTeam}
                                        options={userFilterOptions}
                                        onChange={this.handleProjectTeamChange}
                                        onInputChange={this.handleProjectTeamInputChange}
                                        onMenuClose={(e) => this.resetOptions(e, "team")}
                                        onFocus={(e) => {
                                            this.setState({teamFocused: true});
                                        }}
                                        onBlur={(e) => {
                                            this.setState({teamFocused: false});
                                        }}
                                        isClearable
                                        isSearchable={true}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={10} mdOffset={1}>
                        <FormGroup>
                            
                            
                        </FormGroup>
                    </Col>
                </Row>
            </>
        )
    }
}

export default ProjectInformation;