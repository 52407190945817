import React, {Component} from "react";
import KnoaCardMainRow from "./KnoaCardMainRow";
import {Col} from "react-bootstrap";
import StatCardFooter from "./StatCardFooter";
import {Link, NavLink} from "react-router-dom";

class StatCard extends Component {
    
    
    render() {
        const {
            icon,
            subHeading,
            heading,
            tooltip,
            stats,
            checkbox,
            mainRowHeight
        } = this.props;

        return (
            <>
                {heading.linkPath && heading.cardId ? (
                    <NavLink
                        to={{
                            pathname: heading.linkPath,
                            state: {
                                id: heading.cardId
                            }
                        }}
                        className="nav-link"
                        aria-label = {tooltip.text}
                    >
                        <div className="card">
                            <div className="content">
                                {checkbox}
                                <KnoaCardMainRow icon={icon} subHeading={subHeading} heading={heading} mainRowHeight={mainRowHeight}/>
                                <StatCardFooter tooltip={tooltip} stats={stats} iconStatus={icon.status}/>
                            </div>
                        </div>
                    </NavLink>
                ) : (
                    <div className="card">
                        <div className="content">
                            {checkbox}
                            <KnoaCardMainRow icon={icon} subHeading={subHeading} heading={heading} mainRowHeight={mainRowHeight}/>
                            <StatCardFooter tooltip={tooltip} stats={stats} iconStatus={icon.status}/>
                        </div>
                    </div>
                )
                }
            </>
        )
    }
}

export default StatCard;