import React, {Component} from "react";
import KnoaCardMainRow from "./KnoaCardMainRow";
import {Col} from "react-bootstrap";
import InfoCardFooter from "./InfoCardFooter";

class InfoCard extends Component {
    render() {
        const {
            icon,
            subHeading,
            heading,
            footerText,
            checkbox,
            mainRowHeight
        } = this.props;

        return (
            <div className="card">
                <div className="content">
                    <div>
                        {checkbox}
                        <KnoaCardMainRow icon={icon} subHeading={subHeading} heading={heading} mainRowHeight={mainRowHeight}/>
                        <InfoCardFooter footerText={footerText}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default InfoCard;