import React, {Component} from "react";
import LinkWithTooltip from "../common/LinkWithTooltip.jsx";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import {Row} from "react-bootstrap";


class UsersContainer extends Component {
    generateData = (users) => {
        const dataToReturn = users.map(user => {
            let userArray = [];
            userArray.push(user.userId);
            userArray.push(user.firstName + " " + user.lastName);
            userArray.push(user.email);
            userArray.push(user.role);
            userArray.push(user.userName);
            userArray.push(user.lock);
            userArray.push(user.needSupport);
            return userArray
        });
        return dataToReturn;
    };

    render() {
        const dataTable = this.generateData(this.props.allUsers);
        const defaultPageSize = 10;
        const minRows = (dataTable.length > 0 && dataTable.length <= 10) ? dataTable.length : dataTable.length === 0 ? 3 : defaultPageSize;

        return (
            <div className="card">
                <div className="content">
                    <div className="panel-body">
                        <Row className="users-table-header">
                            <div className="NavBar-btn-container">
                                <Link to={{
                                    pathname: "/admin/Settings/createUser",
                                    state: {
                                        createUser: true
                                    }
                                }}
                                      className="btn btn-default NavBar-btn btn-md knoa-region" tabIndex={"-1"}>{this.props.translate('common:newUser')}</Link>
                            </div>
                            <h4 className="card-title">{this.props.title} ({this.props.translate('user:numOfnum', {
                                num1: this.props.allUsers.length.toString(),
                                num2: this.props.allLicenses.toString()
                            })})</h4>
                        </Row>
                        <div className="knoa-region" tabIndex={"-1"}>
                            <ReactTable
                                getTheadThProps={(state, rowInfo, column, instance) => {
                                    return {
                                        tabIndex: column.sortable === false ? '-1' : '0',
                                        onKeyPress: (event) => {
                                            if (
                                                column.sortable !== false &&
                                                (event.key === 'Enter' || event.key === ' ')
                                            )
                                                instance.sortColumn(column);
                                        },
                                    }}}
                                columns={[
                                    {
                                        Header: this.props.translate('user:name'),
                                        accessor: "name",
                                        maxWidth: '20%',
                                    },
                                    {Header: this.props.translate('user:email'), accessor: "email", maxWidth: '20%'},
                                    {Header: this.props.translate('user:analyticsRole'), accessor: "role", maxWidth: '20%'},
                                    {Header: this.props.translate('user:accountStatus'), accessor: "lock", sortable: false, maxWidth: '10%'},
                                    {Header: this.props.translate('user:proxyUserMode'), accessor: "needSupport", sortable: false, maxWidth: '20%'},
                                    {Header: this.props.translate('user:actions'), accessor: "actions", sortable: false, maxWidth: '10%'}
                                ]}
                                data={
                                    dataTable.map((prop, key) => {
                                        return ({
                                            id: prop[0],
                                            name: prop[1],
                                            email: prop[2],
                                            role: prop[3],
                                            lock: (
                                                <div className="unlock-left">
                                                    <>{!prop[5] && <span>{this.props.translate('user:active')}</span>}
                                                        {prop[5] && <span>{this.props.translate('user:locked')}</span>}
                                                    </>
                                                    <>
                                                        {prop[5] &&
                                                        <LinkWithTooltip tooltip={this.props.translate('user:unlockAccount')}
                                                                         onClick={this.props.handleUnlockWindow} href="Settings"
                                                                         id={prop[0] + '-' + prop[4]} tooltipIconClass="fa fa-lock"
                                                                         bsStyle="danger" iconStyle={{fontSize: 16 + 'px'}}/>}
                                                    </>

                                                </div>
                                            ),
                                            needSupport: (                                                   
                                                <>{prop[6] && <span>{this.props.translate('user:enabled')}</span>}
                                                    {!prop[6] && <span>{this.props.translate('user:disabled')}</span>}
                                                </>
                                            ),
                                            actions: (
                                                <div className="actions-right">

                                                    <>
                                                        <LinkWithTooltip tooltip={this.props.translate('common:edit')} href="editUser"
                                                                         id={prop[0]} tooltipIconClass="fa fa-edit" bsStyle="edit"
                                                                         iconStyle={{fontSize: 16 + 'px'}} linkName="editUser"/>
                                                        {(prop[3] === 'Admin') &&
                                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                                                        {(prop[3] !== 'Admin') &&
                                                        <LinkWithTooltip tooltip={this.props.translate('common:delete')}
                                                                         onClick={this.props.handleShow} href="Settings"
                                                                         id={prop[0] + '-' + prop[4]} tooltipIconClass="fa fa-times"
                                                                         bsStyle="edit" iconStyle={{fontSize: 16 + 'px'}}/>}
                                                    </>

                                                </div>
                                            )
                                        })
                                    })
                                }
                                defaultSorted={[
                                    {
                                        id: "name",
                                        desc: false
                                    }
                                ]}
                                filterable={false}
                                defaultPageSize={defaultPageSize}
                                showPagination
                                showPaginationTop={false}
                                showPaginationBottom
                                showPageSizeOptions={false}
                                className="-highlight"
                                minRows={minRows}
                                getPaginationProps={(a) => {
                                    return {
                                        style: {
                                            fontSize: '12px',
                                            width: '360px',
                                        }
                                    }
                                }}
                                previousText={this.props.translate('common:previous')}
                                nextText={this.props.translate('common:next')}
                                noDataText={this.props.translate('project:noPrjectsFound')}
                                pageText={this.props.translate('common:page')}
                                ofText={this.props.translate('common:of')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UsersContainer;