import React, {Component} from "react";
import {Col, ControlLabel, FormGroup, Row} from "react-bootstrap";
import UAFilterContainer from "../common/UAFilterContainer";
import CommonSelector from "../common/CommonSelector";
import FormInputs from "../creativeTim/FormInputs";

const FormErrors = ({formErrors, fieldName}) =>
    <div className='row formErrors' aria-label={formErrors[fieldName]}>
        <p className="form-validation-error"> {formErrors[fieldName]}</p>
    </div>

class ProjectFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appFocused: false,
            moduleFocused: false,
            bpFocused: false
        };
    }

    handleApplicationsOnChange = (selectedValue) => {
        this.props.handleApplicationsOnChange(selectedValue);
    };

    handleApplicationsInputChange = (inputValue) => {
        this.props.handleApplicationsInputChange(inputValue);
    };


    handleModulesOnChange = (selectedValue) => {
        this.props.handleModulesOnChange(selectedValue);
    };

    handleModulesInputChange = (inputValue) => {
        this.props.handleModulesInputChange(inputValue);
    };

    /*handleScreenGroupsOnChange = (selectedValue) => {
        this.props.handleScreenGroupsOnChange(selectedValue);
    };

    handleScreenGroupsInputChange = (inputValue) => {
        this.props.handleScreenGroupsInputChange(inputValue);
    };*/

    handleAnnualFTECostInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.handleAnnualFTECostInputChange(e.target.value);
        }
    };

    handleAnnualFTEHoursInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.handleAnnualFTEHoursInputChange(e.target.value);
        }
    };

    handleLTNonErrorInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.handleLTNonErrorInputChange(e.target.value);
        }
    };

    handleLTSysErrorInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.handleLTSysErrorInputChange(e.target.value);
        }
    };

    handleLTUserErrorInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.handleLTUserErrorInputChange(e.target.value);
        }
    };

    handleLTUncatMessageInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.handleLTUncatMessageInputChange(e.target.value);
        }
    };

    handleProjectDurationInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.handleProjectDurationInputChange(e.target.value);
        }
    };

    handleProjectUserInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.handleProjectUserInputChange(e.target.value);
        }
    };

    handleTestEffortPerModuleInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.handleTestEffortPerModuleInputChange(e.target.value);
        }
    };

    resetOptions = (event, filterName) => {
        if (filterName === "application") {
            let appForReset = this.state.unSelectedApps;
            if (appForReset) appForReset = [];
            this.setState({
                unSelectedApps: appForReset
            });
        } else
            this.props.resetOptions(event, filterName);
    };

    render() {
        //screenGroupSelected, screenGroupOptions
        const {applicationSelected, moduleSelected, applicationOptions, moduleOptions, userAttributeFilters, handleUserAttributesUpdate,
            annualFTECost, annualFTEHours, LTNonError, LTSysError, LTUserError, LTUncatMessage ,projectDuration, projectUser, testEffortPerModule,formErrors,
            inputClass_annualFTECost, inputClass_annualFTEHours, inputClass_LTNonError, inputClass_LTSysError, inputClass_LTUserError, inputClass_LTUncatMessage, inputClass_projectDuration, inputClass_projectUser,
            inputClass_testEffortPerModule,trans} = this.props;

        let appValue = applicationSelected ? applicationSelected.filter(x => x.selected === true) : [];
        let appOptions = applicationOptions ? applicationOptions : [];
        let modValue = moduleSelected ? moduleSelected.filter(x => x.selected === true) : [];
        let modOptions = moduleOptions ? moduleOptions : [];
        //let sgValue = screenGroupSelected ? screenGroupSelected.filter(x => x.selected === true) : [];
        //let sgOptions = screenGroupOptions ? screenGroupOptions : [];

        return (
            <>
                <Row>
                    <Col md={10} mdOffset={1}>
                        <FormGroup role="group" aria-labelledby="projectFilters_head">
                            <h4 id="projectFilters_head" className={"knoa-region"} tabIndex={"-1"}>{trans('project:projectFilters')}</h4>
                            <div role="group" aria-labelledby="applicationFilters_head">
                            <h5 id="applicationFilters_head">{trans('preferences:applicationFilters')}</h5>
                            <Row className="card-row-flex">
                                <Col className="card-row-col-label" componentClass={ControlLabel} sm={1}>
                                    <label htmlFor= "Applications" className="col-control-sr-label">{trans('preferences:applications')}</label>
                                </Col>
                                <Col sm={1} className="card-row-col-right">
                                    <CommonSelector
                                        inputId = "Applications"
                                        isCreatable={true}
                                        style={"typetosearch"}
                                        componentLocation={"normal"}
                                        name="applicationsSelect"
                                        placeholder={this.state.appFocused ? "" : trans('common:typeToSearch')}
                                        value={appValue}
                                        options={appOptions}
                                        getOptionLabel={(option) => option.value}
                                        getOptionValue={(option) => option.value}
                                        onChange={this.handleApplicationsOnChange}
                                        onInputChange={this.handleApplicationsInputChange}
                                        onMenuClose={(e) => this.resetOptions(e, "application")}
                                        onFocus={(e) => {this.setState({appFocused: true});}}
                                        onBlur={(e) => {this.setState({appFocused: false});}}
                                        isClearable
                                        isSearchable={true}
                                    />
                                </Col>
                            </Row>
                            <Row className="card-row-flex">
                                <Col className="card-row-col-label" componentClass={ControlLabel} sm={1}>
                                    <label htmlFor= "modules" className="col-control-sr-label">{trans('preferences:modules')}</label>
                                </Col>
                                <Col sm={1} className="card-row-col-right">
                                    <CommonSelector
                                        inputId = "modules"
                                        isCreatable={true}
                                        style={"typetosearch"}
                                        componentLocation={"normal"}
                                        placeholder={this.state.moduleFocused ? "" : trans('common:typeToSearch')}
                                        name="modulesSelect"
                                        value={modValue}
                                        options={modOptions}
                                        getOptionLabel={(option) => option.value}
                                        getOptionValue={(option) => option.value}
                                        onChange={this.handleModulesOnChange}
                                        onInputChange={this.handleModulesInputChange}
                                        onMenuClose={(e) => this.resetOptions(e, "module")}
                                        onFocus={(e) => {this.setState({moduleFocused: true});}}
                                        onBlur={(e) => {this.setState({moduleFocused: false});}}
                                        isClearable
                                        isSearchable={true}
                                    />
                                </Col>
                            </Row>
                            {/*<Row className="card-row-flex-business-process">
                                <Col className="card-row-col-label" componentClass={ControlLabel} sm={1}>
                                    {trans('preferences:businessProcess')}
                                </Col>
                                <Col sm={1} className="card-row-col-right">
                                    <CommonSelector
                                        style={"typetosearch"}
                                        componentLocation={"normal"}
                                        placeholder={this.state.bpFocused ? "" : trans('common:typeToSearch')}
                                        name="screenGroupsSelect"
                                        value={sgValue}
                                        options={sgOptions}
                                        getOptionLabel={(option) => option.value}
                                        getOptionValue={(option) => option.value}
                                        onChange={this.handleScreenGroupsOnChange}
                                        onInputChange={this.handleScreenGroupsInputChange}
                                        onMenuClose={(e) => this.resetOptions(e, "screenGroups")}
                                        onFocus={(e) => {this.setState({bpFocused: true});}}
                                        onBlur={(e) => {this.setState({bpFocused: false});}}
                                        isClearable
                                        isSearchable={true}
                                    />
                                </Col>
                            </Row>*/}
                            </div>
                            <div role="group" aria-labelledby="userAttributes_head">
                            <h5 id="userAttributes_head">{trans('preferences:userAttributes')}</h5>
                            <UAFilterContainer
                                userAttributesOptions={userAttributeFilters}
                                handleUserAttributesUpdate={handleUserAttributesUpdate}
                                translate={trans}
                            />
                            </div>
                            <div role="group" aria-labelledby="projectParameters_head">
                            <h4 id="projectParameters_head" className={"knoa-region"} tabIndex={"-1"}>{trans('project:projectParameters')}</h4>
                            <Row className="project-info-row-preferred-prams card-row-flex">
                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                     sm={1}>
                                    <label htmlFor= "annualFTECost" className="col-control-sr-label">{trans('preferences:annualFTECost')} <span className="text-danger-1">*</span></label>
                                </Col>
                                <Col sm={1} className="card-row-col-right">
                                    <FormInputs
                                        ncols={["col-md-12"]}
                                        proprieties={[
                                            {
                                                type: "text",
                                                name: "annualFTECost",
                                                bsClass: inputClass_annualFTECost,
                                                placeholder: "",
                                                value: annualFTECost.value,
                                                onChange: this.handleAnnualFTECostInputChange,
                                                id: "annualFTECost",
                                                required: true,
                                            }
                                        ]}
                                    />
                                    <div className="col-md-12">
                                        <FormErrors formErrors={formErrors} fieldName="annualFTECost"/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="project-info-row-preferred-prams card-row-flex">
                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                     sm={1}>
                                    <label htmlFor= "annualFTEHours" className="col-control-sr-label">{trans('preferences:annualFTEHours')} <span className="text-danger-1">*</span></label>
                                </Col>
                                <Col sm={1} className="card-row-col-right">
                                    <FormInputs
                                        ncols={["col-md-12"]}
                                        proprieties={[
                                            {
                                                type: "text",
                                                name: "annualFTEHours",
                                                bsClass: inputClass_annualFTEHours,
                                                placeholder: "",
                                                value: annualFTEHours.value,
                                                onChange: this.handleAnnualFTEHoursInputChange,
                                                id: "annualFTEHours",
                                                required: true,
                                            }
                                        ]}
                                    />
                                    <div className="col-md-12">
                                        <FormErrors formErrors={formErrors} fieldName="annualFTEHours"/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="project-info-row-preferred-prams card-row-flex">
                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                     sm={1}>
                                    <label htmlFor= "LTNonError" className="col-control-sr-label">{trans('preferences:LTNonError')} <span className="text-danger-1">*</span></label>
                                </Col>
                                <Col sm={1} className="card-row-col-right">
                                    <FormInputs
                                        ncols={["col-md-12"]}
                                        proprieties={[
                                            {
                                                type: "text",
                                                name: "LTNonError",
                                                bsClass: inputClass_LTNonError,
                                                placeholder: "",
                                                value: LTNonError.value,
                                                onChange: this.handleLTNonErrorInputChange,
                                                id: "LTNonError",
                                                required: true,
                                            }
                                        ]}
                                    />
                                    <div className="col-md-12">
                                        <FormErrors formErrors={formErrors} fieldName="LTNonError"/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="project-info-row-preferred-prams card-row-flex">
                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                     sm={1}>
                                    <label htmlFor= "LTSysError" className="col-control-sr-label">{trans('preferences:LTSysError')} <span className="text-danger-1">*</span></label>
                                </Col>
                                <Col sm={1} className="card-row-col-right">
                                    <FormInputs
                                        ncols={["col-md-12"]}
                                        proprieties={[
                                            {
                                                type: "text",
                                                name: "LTSysError",
                                                bsClass: inputClass_LTSysError,
                                                placeholder: "",
                                                value: LTSysError.value,
                                                onChange: this.handleLTSysErrorInputChange,
                                                id: "LTSysError",
                                                required: true,
                                            }
                                        ]}
                                    />
                                    <div className="col-md-12">
                                        <FormErrors formErrors={formErrors} fieldName="LTSysError"/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="project-info-row-preferred-prams card-row-flex">
                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                     sm={1}>
                                    <label htmlFor= "LTUserError" className="col-control-sr-label">{trans('preferences:LTUserError')} <span className="text-danger-1">*</span></label>
                                </Col>
                                <Col sm={1} className="card-row-col-right">
                                    <FormInputs
                                        ncols={["col-md-12"]}
                                        proprieties={[
                                            {
                                                type: "text",
                                                name: "LTUserError",
                                                bsClass: inputClass_LTUserError,
                                                placeholder: "",
                                                value: LTUserError.value,
                                                onChange: this.handleLTUserErrorInputChange,
                                                id: "LTUserError",
                                                required: true,
                                            }
                                        ]}
                                    />
                                    <div className="col-md-12">
                                        <FormErrors formErrors={formErrors} fieldName="LTUserError"/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="project-info-row-preferred-prams card-row-flex">
                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                     sm={1}>
                                    <label htmlFor= "LTUncatMessage" className="col-control-sr-label">{trans('preferences:LTUncatMessage')} <span className="text-danger-1">*</span></label>
                                </Col>
                                <Col sm={1} className="card-row-col-right">
                                    <FormInputs
                                        ncols={["col-md-12"]}
                                        proprieties={[
                                            {
                                                type: "text",
                                                name: "LTUncatMessage",
                                                bsClass: inputClass_LTUncatMessage,
                                                placeholder: "",
                                                value: LTUncatMessage.value,
                                                onChange: this.handleLTUncatMessageInputChange,
                                                id: "LTUncatMessage",
                                                required: true,
                                            }
                                        ]}
                                    />
                                    <div className="col-md-12">
                                        <FormErrors formErrors={formErrors} fieldName="LTUncatMessage"/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="project-info-row-preferred-prams card-row-flex">
                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                     sm={1}>
                                    <label htmlFor= "projectDuration" className="col-control-sr-label">{trans('preferences:projectDuration')} <span className="text-danger-1">*</span></label>
                                </Col>
                                <Col sm={1} className="card-row-col-right">
                                    <FormInputs
                                        ncols={["col-md-12"]}
                                        proprieties={[
                                            {
                                                type: "text",
                                                name: "projectDuration",
                                                bsClass: inputClass_projectDuration,
                                                placeholder: "",
                                                value: projectDuration.value,
                                                onChange: this.handleProjectDurationInputChange,
                                                id: "projectDuration",
                                                required: true,
                                            }
                                        ]}
                                    />
                                    <div className="col-md-12">
                                        <FormErrors formErrors={formErrors} fieldName="projectDuration"/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="project-info-row-preferred-prams card-row-flex">
                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                     sm={1}>
                                    <label htmlFor= "projectUser" className="col-control-sr-label">{trans('preferences:projectUser')} <span className="text-danger-1">*</span></label>
                                </Col>
                                <Col sm={1} className="card-row-col-right">
                                    <FormInputs
                                        ncols={["col-md-12"]}
                                        proprieties={[
                                            {
                                                type: "text",
                                                name: "projectUser",
                                                bsClass: inputClass_projectUser,
                                                placeholder: "",
                                                value: projectUser.value,
                                                onChange: this.handleProjectUserInputChange,
                                                id: "projectUser",
                                                required: true,
                                            }
                                        ]}
                                    />
                                    <div className="col-md-12">
                                        <FormErrors formErrors={formErrors} fieldName="projectUser"/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="project-info-row-preferred-prams card-row-flex">
                                <Col className="card-row-col-label" componentClass={ControlLabel}
                                     sm={1}>
                                    <label htmlFor= "testEffortPerModule" className="col-control-sr-label">{trans('preferences:testEffortPerModule')} <span className="text-danger-1">*</span></label>
                                </Col>
                                <Col sm={1} className="card-row-col-right">
                                    <FormInputs
                                        ncols={["col-md-12"]}
                                        proprieties={[
                                            {
                                                type: "text",
                                                name: "testEffortPerModule",
                                                bsClass: inputClass_testEffortPerModule,
                                                placeholder: "",
                                                value: testEffortPerModule.value,
                                                onChange: this.handleTestEffortPerModuleInputChange,
                                                id: "testEffortPerModule",
                                                required: true,
                                            }
                                        ]}
                                    />
                                    <div className="col-md-12">
                                        <FormErrors formErrors={formErrors} fieldName="testEffortPerModule"/>
                                    </div>
                                </Col>
                            </Row>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    }
}

export default ProjectFilters;