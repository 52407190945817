/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from "react";
import {Col, Row, Grid} from "react-bootstrap";
import ReportFiltersContainer from "../common/ReportFiltersContainer";
import {withTranslation} from "react-i18next";
import ReportToolbar from "../common/ReportToolbar";
import {getReportHelpLink} from "../../lib/common";

class Card extends Component {
    constructor(prop) {
        super(prop);
        this.handleShow = this.handleShow.bind(this);

        this.state = {
            showFiltersSection: true
        };
    }

    handleShow = (e) => {
        e.preventDefault();
        this.setState({showFiltersSection: !this.state.showFiltersSection});
    };

    render() {
        const {t} = this.props;

        const titleColumn = {
            xs: 12,
            sm: this.props.reportToolbar ? 12 : 12,
            lg: this.props.reportToolbar ? 6 : 12
        };
        //let reportTitle = this.props.title;

        return (
            <div
                className={
                    "card" +
                    (this.props.hidden ? " card-hidden" : "") +
                    (this.props.calendar ? " card-calendar" : "") +
                    (this.props.plain ? " card-plain" : "") +
                    (this.props.wizard ? " card-wizard" : "") +
                    (this.props.custom ? " " + this.props.custom : "")
                }
                id={(this.props.id === undefined) ? (Date.now() + Math.random()) : this.props.id }
                style={this.props.style ? this.props.style : {}}
            >
                {this.props.title !== undefined || this.props.category !== undefined || (this.props.reportToolbar && this.props.reportToolbar.filter.enabled !== undefined) ? (
                    <div  className={"header" + (this.props.textCenter ? " text-center" : "")}>
                        <Grid fluid>
                            <Row>
                                <Col xs={titleColumn.xs} sm={titleColumn.sm} lg={titleColumn.lg}>
                                    <h4 className="card-title  card-title-captialize" >{this.props.title}</h4>
                                    <span className="category">{this.props.category}</span>
                                    {( this.props.helpLinkReport !== undefined && this.props.helpLinkReport!=="User Workflow"  ) && <a aria-current="page" className="report-help-link"  href= {getReportHelpLink(this.props.helpLinkReport)}  target="_blank">&nbsp; {this.props.helpLinkText} </a>}
                                </Col>
                                {this.props.reportToolbar &&
                                    <ReportToolbar
                                      isInstanceReport={this.props.reportToolbar.isInstanceReport}
                                      currentWorkbook={this.props.reportToolbar.currentWorkbook}
                                      timeframe={this.props.reportToolbar.timeframe}
                                      filter={this.props.reportToolbar.filter}
                                      showFiltersSection={this.state.showFiltersSection}
                                      handleShow={this.handleShow}
                                      drill={this.props.reportToolbar.drill}
                                      more={this.props.reportToolbar.more}
                                    />
                                }
                            </Row>
                        </Grid>
                    </div>
                ) : (
                    ""
                )}
                {this.props.filterPanel && this.props.filterPanel.enabled &&
                    <div hidden={this.state.showFiltersSection}>
                        <ReportFiltersContainer currentReportFilters={this.props.filterPanel.currentReportFilters}
                                                latestAppliedMauiFilters={this.props.filterPanel.latestAppliedMauiFilters}
                                                filterType={this.props.filterPanel.currentReportFilters.reportType==='current' ? "Current" : "Historical"}
                                                reportType={this.props.filterPanel.reportType}
                                                isInstanceReport={this.props.reportToolbar.isInstanceReport}
                                                sessionPersist={this.props.filterPanel.sessionPersist}
                                                preferencePersist={this.props.filterPanel.preferencePersist}
                                                projectPersist={this.props.filterPanel.projectPersist}
                                                projectEditable={this.props.filterPanel.projectEditable}
                                                updateTimeFilterValues={this.props.filterPanel.updateTimeFilterValues}
                                                updateApplicationsAndUserFilterValues={this.props.filterPanel.updateApplicationsAndUserFilterValues}
                                                absoluteStartError={this.props.filterPanel.absoluteStartError}
                                                absoluteEndError={this.props.filterPanel.absoluteEndError}
                                                handleAuthorization401 = {this.props.filterPanel.handleAuthorization401}
                                                handleResetFilters={this.props.filterPanel.handleResetFilters}
                                                handleApplyNewFilters={this.props.filterPanel.handleApplyNewFilters}
                                                handleSessionPersistChange={this.props.filterPanel.handleSessionPersistChange}
                                                handlePreferencePersistChange={this.props.filterPanel.handlePreferencePersistChange}
                                                handleProjectPersistChange ={this.props.filterPanel.handleProjectPersistChange}
                                                handleAlertClick={this.props.filterPanel.handleAlertClick}
                                                updateParametersValues={this.props.filterPanel.updateParametersValues}
                                                parameterErrors={this.props.filterPanel.parameterErrors}
                                                workbookId={this.props.filterPanel.workbookId}
                                                setTempFilterChanged={this.props.filterPanel.setTempFilterChanged}
                        />
                    </div>
                }
                <div
                    className={
                        "content" +
                        (this.props.ctAllIcons ? " all-icons" : "") +
                        (this.props.ctFullWidth ? " content-full-width" : "") +
                        (this.props.ctTextCenter ? " text-center" : "") +
                        (this.props.tableFullWidth ? " table-full-width" : "") +
                        (this.props.wizard ? " wizard-content" : "") +
                        (this.props.contentClass ? " "+this.props.contentClass : "")
                    }
                >
                    {this.props.content}
                </div>
                {this.props.stats !== undefined || this.props.legend !== undefined ? (
                    <div
                        className={
                            "footer" + (this.props.ftTextCenter ? " text-center" : "")
                        }
                    >
                        {this.props.legend !== undefined ? (
                            <div className="legend">{this.props.legend}</div>
                        ) : null}
                        {this.props.stats !== undefined ? <hr/> : null}
                        {this.props.stats !== undefined ? (
                            <div className="stats">{this.props.stats}</div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        );
    }
}

export default withTranslation()(Card);