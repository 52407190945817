import React, {Component} from "react";
import SingleSelectFilter from "./SingleSelectFilter";
import {Col, Row} from "react-bootstrap";


class UAFilterContainer extends Component {
    constructor(props) {
        super(props);
        this.handleUserAttributesUpdate = this.handleUserAttributesUpdate.bind(this);
    }

    handleUserAttributesUpdate(selectedOptions, id) {
        this.props.handleUserAttributesUpdate(selectedOptions, id);
    }
    
    
    render() {
        const {userAttributesOptions, translate} = this.props;
        if (userAttributesOptions && userAttributesOptions.length > 0) {
            let eachUASelections = [];
            if (this.props.identifier === undefined) {
                userAttributesOptions.forEach(
                    ua => {
                        eachUASelections.push(
                            <SingleSelectFilter key={ua.id} userAttribute={ua} handleUserAttributesUpdate={this.handleUserAttributesUpdate}  workbookId={this.props.workbookId}
                                                translate={translate}/>
                        );
                    }
                );
            } else if(this.props.identifier === "reportFilter") {
                let arrayLength = userAttributesOptions.length;
                for (let i = 0; i < arrayLength-1; i += 2) {
                    eachUASelections.push(
                        <Row key={i} className="card-filter-section-row-users">
                            <Col className="col-md-6">
                                <SingleSelectFilter key={userAttributesOptions[i][0]} userAttribute={userAttributesOptions[i]} isInstanceReport={this.props.isInstanceReport} workbookId={this.props.workbookId}
                                                    handleUserAttributesUpdate={this.handleUserAttributesUpdate} identifier ={this.props.identifier} translate={translate}/>
                            </Col>
                            <Col className="col-md-6">
                                <SingleSelectFilter key={userAttributesOptions[i+1][0]} userAttribute={userAttributesOptions[i+1]} isInstanceReport={this.props.isInstanceReport}  workbookId={this.props.workbookId}
                                                    handleUserAttributesUpdate={this.handleUserAttributesUpdate} identifier ={this.props.identifier}  translate={translate}/>
                            </Col>
                        </Row>
                    );
                }
                if(arrayLength % 2 === 1){
                    eachUASelections.push(
                    <Row key={arrayLength-1} className="card-filter-section-row-users">
                        <Col className="col-md-6">
                            <SingleSelectFilter key={userAttributesOptions[arrayLength-1][0]} userAttribute={userAttributesOptions[arrayLength-1]} isInstanceReport={this.props.isInstanceReport}
                                                handleUserAttributesUpdate={this.handleUserAttributesUpdate} identifier ={this.props.identifier} translate={translate}/>
                        </Col>
                        <Col className="col-md-6">
                        </Col>
                    </Row>
                    );
                }
            }
            
            return <div>{eachUASelections}</div>;

        } else {
            return <div></div>;
        }
    }
}

export default UAFilterContainer;