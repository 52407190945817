import React, {Component} from "react";
import TimeframeSelection from "../../../components/common/TimeframeSelection";
import ProjectReports from "../../../components/Project/ProjectReports";
import {withTranslation} from 'react-i18next';

class ProjectStep2 extends Component{
  render() {
    const {timeFilter, handleTimeFilterUpdate, projects, handleProjectReportsUpdate, selectAllHandler,isSelectAllChecked,resetSelectAllCheckStatus,absoluteStartError, absoluteEndError, t} = this.props;
    return(
      <div className="wizard-step project-wizard-step-main-content">
        <h5 className="text-center wizard-subtitle">{t('project:currentTitle')}</h5>
        <TimeframeSelection
          filterType="Current"
          timeFilter={timeFilter}
          handleTimeFilterUpdate={handleTimeFilterUpdate}
          context={'project'}
          absoluteStartError={absoluteStartError}
          absoluteEndError={absoluteEndError}
        />
        <ProjectReports
          reportType="CURRENT"
          projects={projects}
          handleProjectReportsUpdate={handleProjectReportsUpdate}
          selectAllHandler={selectAllHandler}
          isSelectAllChecked ={isSelectAllChecked}
          resetSelectAllCheckStatus={resetSelectAllCheckStatus}
          trans={t}
        />
      </div>
    )
  }
}

export default withTranslation()(ProjectStep2);