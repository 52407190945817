import React, {Component} from "react";
import {Col, Row, FormGroup} from "react-bootstrap";
import {withTranslation} from 'react-i18next';
import {API} from "../../lib/API";
import UAFilterContainer from "../common/UAFilterContainer";
import CommonSelector from "../common/CommonSelector";
import {FILTERS} from "../../lib/filters";
import {getErrorMessagesFromCode, getErrorMsgFromStatus} from "../../lib/messageHandler";
import {scrollbarForFilterPanel} from "../../lib/common";

const localStorage = require('../../lib/localStorage');

class ReportUserfilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OriginalUsers: {},
            users: {},
            userFocused: false,
            oldHeight:0,
        }

    }

    componentDidUpdate(prevProps) {
        //for common report
        if (window.sessionStorage.getItem('originalCommonPanelFilterForUser') !== null) {
            let originalUsersForReset = this.state.OriginalUsers;
            originalUsersForReset.enabled = prevProps.currentReportFilters.filters[FILTERS.getFilterEndUserName("default")].enabled;
            originalUsersForReset.options = [];

            let usersForReset = this.state.users;
            // usersForReset.enabled = originalReportFilter.filters[FILTERS.getFilterEndUserName("default")].enabled;
            usersForReset.options = [];


            this.setState({
                users: usersForReset,
                OriginalUsers: originalUsersForReset
            });
            window.sessionStorage.removeItem("originalCommonPanelFilterForUser");
        }

        //for project report
        if (window.sessionStorage.getItem('originalProjectPanelFilterForUser') !== null) {
            let originalUsersForReset = this.state.OriginalUsers;
            originalUsersForReset.enabled = prevProps.currentReportFilters.filters[FILTERS.getFilterEndUserName("default")].enabled;
            originalUsersForReset.options = [];

            let usersForReset = this.state.users;
            // usersForReset.enabled = originalReportFilter.filters[FILTERS.getFilterEndUserName("default")].enabled;
            usersForReset.options = [];


            this.setState({
                users: usersForReset,
                OriginalUsers: originalUsersForReset
            });
            window.sessionStorage.removeItem("originalProjectPanelFilterForUser");
        }
    }

    getAttributeOriginalName = (newName, uaList) => {
        let nameArr = newName.split(".");
        let originaUAObj = uaList.filter(ua => ua[0].replace(FILTERS.getUserAttributePrefix(), "") === nameArr[0]);
        return originaUAObj[0][0];
    }

    handleUserAttributesUpdate = (selectedOptions, oringalUAname) => {
        console.log("parent handle was called for attribute name: " + oringalUAname);
        let selectedListLength = selectedOptions.length;
        //let oringlaFilterOBj = this.props.currentReportFilters; //to be deleted
        let originalOptions = this.props.currentReportFilters.filters[oringalUAname].options === null ? [] : this.props.currentReportFilters.filters[oringalUAname].options.filter(x=>x.selected===true);
       
        if (selectedListLength > originalOptions.length) {
            let newlySelected = {
                id: selectedOptions[selectedListLength - 1].id,
                label: selectedOptions[selectedListLength - 1].value,
                selected: true,
                value: selectedOptions[selectedListLength - 1].value
            };
            if(originalOptions.filter(x=>x.id === selectedOptions[selectedListLength - 1].id ).length>0){
                originalOptions.map(op => op.id === selectedOptions[selectedListLength - 1].id ? newlySelected : op )
            } else
                originalOptions.push(newlySelected);
        } else {
            originalOptions = selectedOptions;
        }
        let lastFilter = this.props.currentReportFilters.filters[oringalUAname];
        lastFilter.filterType = "MULTI_SEARCH";
        lastFilter.options = originalOptions;
        lastFilter.enabled = this.props.currentReportFilters.filters[oringalUAname].enabled;

       // this.setState({OriginalUsers: lastFilter});
        window.sessionStorage.removeItem("showSpinner");
       // scrollbarForFilterPanel();
        this.props.updateApplicationsAndUserFilterValues(oringalUAname, lastFilter);
        // let newHeight = document.getElementById("fusers").offsetHeight;
        // scrollbarForFilterPanel(this.state.oldHeight,newHeight, "USERS");
        // this.setState({oldHeight: newHeight});
    }


    handleEndUserInputChange = (inputValue) => {
        if (inputValue === "") return;
        let fOldHeight = document.getElementById("fusers").offsetHeight;
        let _workbookId = 0;
        if(this.props.workbookId) {
            _workbookId = this.props.workbookId
        }
        console.log("the old height is: "+fOldHeight);
        API.report
        // .get(`/report/list/enduser/${inputValue}?top=100`)
            .post(`/report/list/enduser/${_workbookId}?top=100`, {
                word: inputValue
            })
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;
                let lastestOptions = data.results;
                let currentFilter = this.state.users;
                currentFilter.options = lastestOptions;
                currentFilter.enabled = this.props.currentReportFilters.filters[FILTERS.getFilterEndUserName("default")].enabled;
                this.setState({users: currentFilter,
                    oldHeight: fOldHeight});
            })
            .catch(e => {
                console.error(e);
                if (localStorage.get("accessDenied401") === "true") {
                    this.props.handleAuthorization401();
                    return;
                }
                let resultCode = -1;
                if(e.data) {
                    resultCode = e.data.resultCode;
                }
                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    };

    resetUserFiltersOptions = () => {
        let usersForReset = this.state.users;
        usersForReset.options = [];
        this.setState({
            users: usersForReset,
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        let tempFilterChangeIdentifier = window.sessionStorage.getItem("lastChangedFilter");
         if(tempFilterChangeIdentifier=== null || (tempFilterChangeIdentifier && (tempFilterChangeIdentifier.startsWith("STRING") ||tempFilterChangeIdentifier.startsWith("END"))))
        return true;
         else
             return false;
    }

    handleEndUserUpdate = (selectedValue) => {
        let selectedListLength = selectedValue.length;
        let originalOptions = this.props.currentReportFilters.filters[FILTERS.getFilterEndUserName("default")].options === null ? [] : this.props.currentReportFilters.filters[FILTERS.getFilterEndUserName("default")].options;
        if (selectedListLength > originalOptions.length) {
            let newlySelected = {
                id: selectedValue[selectedListLength - 1].id,
                value: selectedValue[selectedListLength - 1].value,
                selected: true
            };
            originalOptions.push(newlySelected);
        } else {
            originalOptions = selectedValue;
        }
        let lastFilter = this.state.OriginalUsers;
        lastFilter.filterType = "MULTI_SEARCH";
        lastFilter.options = originalOptions;
        lastFilter.enabled = this.props.currentReportFilters.filters[FILTERS.getFilterEndUserName("default")].enabled;
        lastFilter.name = FILTERS.getFilterEndUserName("default")

        
        window.sessionStorage.removeItem("showSpinner");
        //scrollbarForFilterPanel();
        this.props.updateApplicationsAndUserFilterValues(FILTERS.getFilterEndUserName("default"), lastFilter);
        let newHeight = document.getElementById("fusers").offsetHeight;
        scrollbarForFilterPanel(this.state.oldHeight,newHeight, "users");
        this.setState({OriginalUsers: lastFilter,
            oldHeight: newHeight});

    };

    render() {
        const {t} = this.props;

        let showUserFilter = true;
        let selectedUsers = [];
        let userFilterOptions = [];

        let userAttributesList = [];


        if (this.props.currentReportFilters.filters !== undefined) {
            showUserFilter = this.props.currentReportFilters.filters[FILTERS.getFilterEndUserName("default")].enabled;
            if (showUserFilter) {
                userFilterOptions = this.state.users.options === undefined ? [] : this.state.users.options;
                selectedUsers = this.props.currentReportFilters.filters[FILTERS.getFilterEndUserName("default")].options === null ? [] : this.props.currentReportFilters.filters[FILTERS.getFilterEndUserName("default")].options.filter(x => x.selected === true);
            }

            userAttributesList = Object.entries(this.props.currentReportFilters.filters).filter(x => x[0].startsWith(FILTERS.getUserAttributePrefix()) && x[1].enabled === true);

            userAttributesList.map(att => {
                    att[0] = att[0].replace(FILTERS.getUserAttributePrefix(), "") + ". " + att[1].name;
                }
            );

            userAttributesList.sort((x, y) => {
                let a = parseInt(x[0].split(".")[0]),
                    b = parseInt(y[0].split(".")[0]);
                return a === b ? 0 : a > b ? 1 : -1;
            });
        }

        return (
            <FormGroup
                role="group" aria-labelledby="UserFilters"
                className={this.props.isInstanceReport ? "report-filter-panel-formGroup  report-filter-panel-instance" : "report-filter-panel-formGroup"}>
                <Row className="card-filter-section-title">
                    <span id="UserFilters">{t('report.filter:UserFilters')}</span>
                </Row>

                <Row className="card-filter-section-row-users" hidden={!showUserFilter}>
                    <Col className="col-md-12">
                        <Row className="report-filter-panel-row-user">
                            <Row className="card-filter-section-row">
                                <span><label htmlFor="users" className="col-control-sr-label">{t('report.filter:users')}</label></span>
                            </Row>
                            <Row className="card-filter-section-row">
                                <CommonSelector
                                    id="fusers"
                                    inputId ="users"
                                    style={"typetosearch"}
                                    componentLocation={"filterpanel"}
                                    placeholder={this.state.userFocused ? "" : t('common:typeToSearch')}
                                    value={selectedUsers}
                                    options={userFilterOptions}
                                    getOptionLabel={(option) => option.value}
                                    getOptionValue={(option) => option.value}
                                    onChange={this.handleEndUserUpdate}
                                    onInputChange={this.handleEndUserInputChange}
                                    maxMenuHeight={"300px"}
                                    onMenuClose={this.resetUserFiltersOptions}
                                    onFocus={(e) => {
                                        this.setState({userFocused: true});
                                    }}
                                    onBlur={(e) => {
                                        this.setState({userFocused: false});
                                    }}
                                    isClearable
                                    isInstanceReport={this.props.isInstanceReport}/>
                            </Row>
                        </Row>
                    </Col>
                </Row>

                <UAFilterContainer
                    userAttributesOptions={userAttributesList}
                    handleUserAttributesUpdate={this.handleUserAttributesUpdate}
                    isInstanceReport={this.props.isInstanceReport}
                    identifier={"reportFilter"}
                    workbookId={this.props.workbookId}
                    translate={t}/>


            </FormGroup>
        )
    }
}

export default withTranslation()(ReportUserfilters);