import noUiSlider from "nouislider";
import wNumb from "wnumb";

//Slider with benchmark
export function createSliderWithBenchmark(sliderElement) {
  noUiSlider.create(sliderElement, {
    start: [1, 2],
    behaviour: 'drag-unconstrained',
    connect: true,
    keyboardSupport: true,
    tooltips: [true,true],
    step: 1,
    range: {
      'min': 1,
      'max': 10
    },
    format: wNumb({
      decimals: 0
    })
  });
  sliderElement.style.zIndex = '0';
}

export function updateHandlesIndex(sliderElement) {
  const origins = sliderElement.getElementsByClassName('noUi-origin');
  const baseIndex = origins[0].style.zIndex;
  const benchmarkIndex = origins[1].style.zIndex;
  if(baseIndex < benchmarkIndex) {
    origins[0].style.zIndex = benchmarkIndex;
    origins[1].style.zIndex = baseIndex;
  }
}

export function disableSecondHandle(sliderElement) {
  const origins = sliderElement.getElementsByClassName('noUi-origin');
  origins[1].setAttribute('disabled', true);
}

export function updateValues(sliderElement, value) {
  sliderElement.noUiSlider.set([value, value * 2]);
}

export function updateSliderColor(sliderElement, value, limitValue, selectedTimePeriod) {
  const connects = sliderElement.getElementsByClassName('noUi-connect')[0];
  const transform = connects.style.transform; //translate(14.7541%, 0px) scale(0.149031, 1)
  const translatePercent = transform.substring(transform.indexOf("translate(")+10,transform.indexOf("%")); //14.7541
  const scale = transform.substring(transform.indexOf("scale(")+6,transform.lastIndexOf(",")); //0.149031
  let updatedTransform = transform.replace('translate('+translatePercent, 'translate(-1.5'); //translate(0%, 0px) scale(0.149031, 1)
  updatedTransform = updatedTransform.replace('scale('+scale, 'scale('+value / limitValue);
  connects.style.transform = updatedTransform;
  if(selectedTimePeriod==='ABSOLUTE') {
    connects.style.background = '#595959';
  }
  else {
    connects.style.background = '#4064f5';
  }
}

export function updateMainHandleColor(sliderElement, selectedTimePeriod) {
  let sliderHandle = sliderElement.getElementsByClassName('noUi-handle noUi-handle-lower')[0];

  if(selectedTimePeriod === 'ABSOLUTE') {
    sliderHandle.style.background = '#e5e5e5';
  }
  else {
    sliderHandle.style.background = '#4064f5';
  }
}

export function updateBenchmarkHandleColor(sliderElement, value, limitValue, selectedTimePeriod) {
  let benchMarkHandle = sliderElement.getElementsByClassName('noUi-handle noUi-handle-upper')[0];
  if(selectedTimePeriod === 'ABSOLUTE') {
    benchMarkHandle.style.background = '#e5e5e5';
    benchMarkHandle.setAttribute('warning', '0');
  }
  else if(value <= (limitValue / 2)) {
    benchMarkHandle.style.background = '#90EE90';
    benchMarkHandle.setAttribute('warning', '0');
  }
  else {
    benchMarkHandle.style.background = '#E60202';
    benchMarkHandle.setAttribute('warning', '1');
  }
}

//timeframe: "Current" or "Historical"
export function checkBenchmarkWarning(sliderElement, timeframe) {
  let benchMarkHandle = sliderElement.getElementsByClassName('noUi-handle noUi-handle-upper')[0];
  let warningElement = document.getElementById('sliderWarning'+timeframe);
  if(warningElement) {
    if (benchMarkHandle.getAttribute('warning') === '1') {
      warningElement.style.visibility = 'visible';
    } else {
      warningElement.style.visibility = 'hidden';
    }
  }
}

export function updateBaseTooltip(sliderElement) {
  let tooltipBase = sliderElement.getElementsByClassName('noUi-tooltip')[0];
  tooltipBase.style.width = '60px';
}

export function updateTooltips(sliderElement, baseText, benchmarkText) {
  let tooltipBase = sliderElement.getElementsByClassName('noUi-tooltip')[0];
  tooltipBase.style.width = 'auto';
  //tooltipBase.style.left = '-72%';
  tooltipBase.innerText = baseText;
  let tooltipBenchmark = sliderElement.getElementsByClassName('noUi-tooltip')[1];
  tooltipBenchmark.style.width = 'auto';
  //tooltipBenchmark.style.left = '-144%';
  tooltipBenchmark.innerText = benchmarkText;
}

export function updateBenchmarkHandleVisibility(sliderElement, state) {
  let benchMarkHandle = sliderElement.getElementsByClassName('noUi-handle noUi-handle-upper')[0];
  benchMarkHandle.style.visibility = state;
}

//General Slider
export function updateLimit(sliderElement, limitValue) {
  sliderElement.noUiSlider.updateOptions({
    range: {
      'min': 1,
      'max': limitValue===1? 2: limitValue
    }
  });
}