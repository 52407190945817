import React from "react";
import PropTypes, {array} from 'prop-types';
import shallowequal from 'shallowequal';
import UrlUtil from 'url';
import {isMobileOnly, isTablet} from "react-device-detect";
import Spinner from "../common/Spinner";
import {extractHour} from "../../lib/common";
import {API} from "../../lib/API";
import {FILTERS} from "../../lib/filters";
import {PARAMETERS} from "../../lib/parameters";

const TableauApi = window.tableau;

const worksheetOrder = {
    "Application Details": {
        "modules": 1,
        "screens": 2
    },
    "Summary": {
        "user_attributes": 1,
        "business_processes": 1,
        "apps": 2,
        "activity_trend": 2,
        "errors_trend": 2,
        "performance_trend": 2,
        "daily_trend": 3
    },
    "Activity": {
        "activity_map": 1,
        "user_attributes_1": 1,
        "user_attributes_2": 1,
        "app_activity": 2,
        "mod_activity": 2,
        "appactivity_difference": 2,
        "modactivity_difference": 2,
        "activity_trend": 3,
        "adoption_trend": 3
    },
    "Performance": {
        "performance_map": 1,
        "user_attributes_1": 1,
        "user_attributes_2": 1,
        "app_performance": 2,
        "mod_performance": 2,
        "appperf_difference": 2,
        "modperf_difference" : 2,
        "performance_trend": 3,
        "response_trend": 3
    },
    "Errors": {
        "usererrors_map": 1,
        "systemerrors_map": 1,
        "app_errors": 2,
        "error_trend": 3
    },
    "Task Details": {
        "tasks": 1,
        "users": 2,
        "trend": 3
    },
    "User Errors": {
        "user_attributes_3": 1,
        "user_attributes_4": 1,
        "app_errors": 2,
        "mod_errors": 2,
        "apperrors_difference": 2,
        "moderrors_difference": 2,
        "user_error_trend": 3,
    },
    "System Errors": {
        "user_attributes_3": 1,
        "user_attributes_4": 1,
        "appsyserrors": 2,
        "modsyserrors": 2,
        "appsyserrors_difference": 2,
        "modsyserrors_difference": 2,
        "sys_error_trend": 3,
    }
};

const tabsToIgnore = [
    "Filters"
];

const propTypes = {
    filters: PropTypes.object,
    url: PropTypes.string,
    parameters: PropTypes.object,
    options: PropTypes.object,
    token: PropTypes.string,
    refreshSeconds: PropTypes.number,
    onLoad: PropTypes.func
};

const defaultProps = {
    loading: false,
    parameters: {},
    filters: {},
    options: {},
    refreshSeconds: 0,
    onLoad: function (date) {
    }
};

class TableauWorkbook extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: props.filters ? props.filters : {},
            parameters: props.parameters,
            didInvalidateToken: false,
            frameWidth: 1000,
            fetchInProgress: "true",
            wheelAction: false,
        };
    }

    invalidateToken() {
        this.setState({didInvalidateToken: true});
    }

    static tokenizeUrl(_url, token) {
        const parsed = UrlUtil.parse(_url, true);
        const {protocol, host, pathname} = parsed;

        return `${protocol}//${host}/trusted/${token}${pathname}`;
    }

    getUrl(_props) {
        const {token} = _props;
        const parsed = UrlUtil.parse(_props.url, true);
        const query = '?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes&:showAppBanner=false&:showVizHome=no&:display_count=no';
        if (!this.state.didInvalidateToken && token) {
            this.invalidateToken();
            //temp
            // const _url = "https://tableau.knoa.com/views/FinancialImpactofTraining_15699372578720/Summary";
            // return TableauWorkbook.tokenizeUrl(_url, token) + query;
            return TableauWorkbook.tokenizeUrl(_props.url, token) + query;
        }
        return parsed.protocol + '//' + parsed.host + parsed.pathname + query;
    }

    getFilterValue(appliedValues) {
        return appliedValues.map((value) => {
            return value.value
        });
    }

    getMarks(allMarks, tabName, attributeMap, wsName) {
        let _newMarks = {};
        let wf_sessionCounts = [];
        if (allMarks) {
            const ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
            let allTabs = ssLastReport ? ssLastReport.tabs : [];
            let markId = 0;
            let isInMap = wsName.indexOf('_map') !== -1;
            allMarks.map(singleMark => {
                const pairs2 = singleMark.getPairs();
                let tempSingleMark = {};
                pairs2.map(pair => {
                    let tempFieldName = pair.fieldName;
                    if(pair.fieldName.includes("(")) {
                        let regExp = /\(([^)]+)\)/;
                        let matches = regExp.exec(pair.fieldName);
                        tempFieldName = "(" + matches[1] + ")";
                    }
                    if (FILTERS.getTableauSelections.includes(tempFieldName) && pair.value !== null) {
                        if(!tempSingleMark[markId]){
                            tempSingleMark[markId] = {};
                        }
                        if (pair.fieldName.endsWith("TIME") || pair.fieldName.endsWith("DATE")) {
                            tempSingleMark[markId][pair.fieldName] = pair.formattedValue;
                        } else if (pair.fieldName === 'GEO_LOCATION') {
                            //find string attribute that has Country
                            if (attributeMap && Object.keys(attributeMap) && Object.keys(attributeMap).length !== 0) {
                                Object.entries(attributeMap).forEach(attribute => {
                                    if (attribute[1].toUpperCase() === FILTERS.getCountry()) {
                                        tempSingleMark[markId][attribute[0]] = pair.value;
                                    }
                                })
                            }
                        } else if (pair.fieldName.indexOf('(USER ERRORS PER USER)') !== -1) {
                            if (isInMap) {
                                let _fieldName = 'ERROR TYPE';
                                let _value = 'User Error';
                                if (!tempSingleMark[markId][_fieldName]) {
                                    tempSingleMark[markId][_fieldName] = _value;
                                } else {
                                    delete tempSingleMark[markId][_fieldName];
                                }
                            }
                        } else if (pair.fieldName.indexOf('(SYSTEM ERRORS PER USER)') !== -1) {
                            if (isInMap) {
                                let _fieldName = 'ERROR TYPE';
                                let _value = 'System Error';
                                if (!tempSingleMark[markId][_fieldName]) {
                                    tempSingleMark[markId][_fieldName] = _value;
                                } else {
                                    delete tempSingleMark[markId][_fieldName];
                                }
                            }
                        } else {
                            tempSingleMark[markId][pair.fieldName] = pair.value;
                        }
                    } else if (tabName === 'User Workflows' && pair.fieldName.indexOf('(APPLICATION SESSIONS)') !== -1) {
                        wf_sessionCounts.push(pair.value);
                    }
                });
                let exists = false;
                for (const item in _newMarks) {
                    if (JSON.stringify(_newMarks[item]) === JSON.stringify(tempSingleMark[markId])) {
                        exists = true;
                        break;
                    }
                }
                if (!exists && tempSingleMark[markId] && Object.keys(tempSingleMark[markId]) && Object.keys(tempSingleMark[markId]).length !== 0) {
                    _newMarks[markId] = tempSingleMark[markId];
                    markId++;
                }
            });

            if (wf_sessionCounts && wf_sessionCounts.length > 0) {
                if (allTabs && allTabs.length > 0) {
                    allTabs.map(tab => {
                        if (tab.name === tabName) {
                            if (wf_sessionCounts.length === 1 && wf_sessionCounts[0] > 0) {
                                tab.isValid = true;
                            } else {
                                tab.isValid = false;
                            }
                        }
                    });
                    //update sessionStorage
                    ssLastReport.tabs = allTabs;

                    window.sessionStorage.setItem('lastReport', JSON.stringify(ssLastReport));
                }
            }
        }
        return _newMarks;
    }

    setMarksSession(marks, tabName, wsName, dataRangeType) {
        let ssLastReport = {};
        if (dataRangeType === "instance")
            ssLastReport = JSON.parse(window.sessionStorage.getItem('lastInstanceReport'));
        else if (dataRangeType === "current" && (window.sessionStorage.getItem("clickFromInstance") === "true" || window.sessionStorage.getItem("latestReportDataType") === "currentuser workflow")) {
            ssLastReport = JSON.parse(window.sessionStorage.getItem('workflowReport'));
        } else
            ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));

        if (!ssLastReport) {
            ssLastReport = {};
        }

        let allTabs = ssLastReport ? ssLastReport.tabs : [];
        if (allTabs && allTabs.length > 0) {
            allTabs.map(tab => {
                if (tab.name === tabName) {
                    let worksheets = tab.worksheets;
                    if (worksheets && worksheets.length > 0) {
                        worksheets.map((ws, i) => {
                            if (ws.name === wsName) {
                                //delete worksheet
                                worksheets.splice(i, 1);
                            }
                        });

                        //Add new worksheet
                        if (Object.keys(marks) && Object.keys(marks).length !== 0) {
                            let worksheet = {};
                            worksheet.name = wsName;
                            worksheet.marks = marks;
                            tab.worksheets.push(worksheet);
                        }

                        //Re-order worksheets if needed
                        if (tab.worksheets && tab.worksheets.length > 1) {
                            tab.worksheets.sort((a, b) => worksheetOrder[tab.name][a.name] > worksheetOrder[tab.name][b.name] ? 1 : -1)
                        }

                        //delete worksheets if empty
                        if (tab.worksheets && tab.worksheets.length === 0) {
                            delete tab.worksheets;
                        }
                    } else {
                        if (Object.keys(marks) && Object.keys(marks).length !== 0) {
                            tab.worksheets = [];
                            tab.worksheets.push({
                                name: wsName,
                                marks: marks
                            });
                        }
                    }
                }
            });
            //update sessionStorage
            ssLastReport.tabs = allTabs;
            if (dataRangeType === "instance")
                window.sessionStorage.setItem('lastInstanceReport', JSON.stringify(ssLastReport));
            else if (dataRangeType === "current" && (window.sessionStorage.getItem("clickFromInstance") === "true" || window.sessionStorage.getItem("latestReportDataType") === "currentuser workflow")) {
                window.sessionStorage.setItem('workflowReport', JSON.stringify(ssLastReport));
            } else
                window.sessionStorage.setItem('lastReport', JSON.stringify(ssLastReport));

        }
    }

    changeParameterValue(workbook, name, value) {
        workbook.changeParameterValueAsync(name, value);
    }

    //marks
    reapplySelections(tabName, worksheets, dataRangeType) {
        let ssLastReport = {};
        if (dataRangeType === "instance") {
            ssLastReport = JSON.parse(window.sessionStorage.getItem('lastInstanceReport'));
        } else if (dataRangeType === "current" && (window.sessionStorage.getItem("clickFromInstance") === "true" || window.sessionStorage.getItem("latestReportDataType") === "currentuser workflow")) {
            ssLastReport = JSON.parse(window.sessionStorage.getItem('workflowReport'));
        } else {
            ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
        }

        let allTabs = ssLastReport ? ssLastReport.tabs : [];
        if (allTabs && allTabs.length > 0) {
            for (let a = 0; a < allTabs.length; a++) {
                if (allTabs[a].name === tabName && allTabs[a].lastUsed) {
                    //clear selections
                    for (let b = 0; b < worksheets.length; b++) {
                        worksheets[b].clearSelectedMarksAsync();
                    }

                    let sliderFilter = allTabs[a].sliderFilter;

                    //apply selections
                    let _ws = allTabs[a].worksheets;
                    if (_ws) {
                        for (let c = 0; c < _ws.length; c++) {
                            for (let d = 0; d < worksheets.length; d++) {
                                if (_ws[c].name === worksheets[d].getName()) {
                                    //apply page filter for the tab
                                    if (sliderFilter && worksheets[d].getName() === sliderFilter["sliderWorksheet"]) {
                                        let sliderFilterName = sliderFilter["name"];
                                        let minDecimalValueStrParam = "";
                                        let maxDecimalValueStrParam = "";
                                        if (sliderFilterName.indexOf("(AVG RESPONSE TIME OPERATION)") !== -1 || sliderFilterName.indexOf("(AVG RESPONSE TIME USER)") !== -1) {
                                            minDecimalValueStrParam = sliderFilter["min"]["value"] + ".0";
                                            maxDecimalValueStrParam = sliderFilter["max"]["value"] + ".0";
                                        }
                                        worksheets[d].applyRangeFilterAsync(sliderFilterName, {
                                            min: minDecimalValueStrParam,
                                            max: maxDecimalValueStrParam
                                        }).then(function (res) {
                                                console.log(res);
                                                //store all marks in an array and select all at once 
                                                //currently because of filter race condition, even if put the mark selection restoration at here, still can not promise the filter range restored 
                                            }, function (err) {
                                                //here exception can happen even if the field name and value are correct, still get inValidFieldNameorValue exceptiion sometimes.
                                                console.log(err);
                                            });
                                    }

                                    //store all marks in an array and select all at once
                                    let marksObj = {};
                                    let marks = _ws[c].marks;
                                    if (marks) {
                                        //loop through each mark
                                        for (let prop in marks) {
                                            //loop through each key in mark
                                            for (let fieldName in marks[prop]) {
                                                if (fieldName !== 'zeroInstance') {
                                                    const fieldValue = marks[prop][fieldName];


                                                    if (_ws[c].name.indexOf('_map') !== -1) {
                                                        //worksheet: activity_map
                                                        fieldName = 'GEO_LOCATION';
                                                    } else if (fieldName.indexOf('STRING_ATTR') !== -1) {
                                                        //worksheets: 'user_attributes' and 'user_stats'
                                                        fieldName = 'SELECT_USER_ATTRIBUTE';
                                                    }

                                                    if (marksObj[fieldName]) {
                                                        //check if value already exists
                                                        if (!marksObj[fieldName].includes(fieldValue)) {
                                                            marksObj[fieldName].push(fieldValue);
                                                        }
                                                    } else {
                                                        marksObj[fieldName] = [fieldValue];
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (Object.keys(marksObj) && Object.keys(marksObj).length !== 0) {
                                        const msgTime = FILTERS.getMessageTime("time");
                                        const msgDate = FILTERS.getMessageTime("date");
                                        const scStartTime = FILTERS.getScreenStartTime("time");
                                        const scStartDate = FILTERS.getScreenStartTime("date");
                                        const opStartTime = FILTERS.getOperationStartTime("time");
                                        const opStartDate = FILTERS.getOperationStartTime("date");

                                        if (marksObj[msgTime] && marksObj[msgDate]) {
                                            marksObj[msgTime][0] = marksObj[msgDate][0] + " " + marksObj[msgTime][0];
                                        } else if (marksObj[scStartTime] && marksObj[scStartDate]) {
                                            marksObj[scStartTime][0] = marksObj[scStartDate][0] + " " + marksObj[scStartTime][0];
                                        } else if (marksObj[opStartTime] && marksObj[opStartDate]) {
                                            marksObj[opStartTime][0] = marksObj[opStartDate][0] + " " + marksObj[opStartTime][0];
                                        }
                                        worksheets[d].selectMarksAsync(marksObj, "ADD");
                                    }
                                    break;
                                }
                            }
                        }
                    }
                    break;
                }
            }
        }
    }

    updateDrillToReportButton(active) {
        const drillToReportBtn = document.getElementById('knoaDropdown');
        if (drillToReportBtn) {
            if (active) {
                if (drillToReportBtn.classList.contains('dropdownContainerHide')) {
                    drillToReportBtn.classList.remove('dropdownContainerHide');
                }
                if (!drillToReportBtn.classList.contains('dropdownContainerShow')) {
                    drillToReportBtn.classList.add('dropdownContainerShow');
                }
                //Caret handling
                const dropdownElements = document.getElementsByClassName("dropdownContainer");
                for (const dropdown in dropdownElements) {
                    const children = dropdownElements[dropdown].children;
                    if (children) {
                        for (const child of children) {
                            if (child.classList.contains('caret')) {
                                child.classList.add("caretSelected");
                            }
                        }
                    }
                }
            } else {
                if (drillToReportBtn.classList.contains('dropdownContainerShow')) {
                    drillToReportBtn.classList.remove('dropdownContainerShow');
                }
                if (!drillToReportBtn.classList.contains('dropdownContainerHide')) {
                    drillToReportBtn.classList.add('dropdownContainerHide');
                }
                //Caret handling
                const dropdownElements = document.getElementsByClassName("dropdownContainer");
                for (const dropdown in dropdownElements) {
                    const children = dropdownElements[dropdown].children;
                    if (children) {
                        for (const child of children) {
                            if (child.classList.contains('caretSelected')) {
                                child.classList.remove("caretSelected");
                            }
                        }
                    }
                }
            }
        }
    }

    updateDrillButton(tabName, dataRangeType) {
        if (this.props.allInstanceWorkbookIds.length && this.props.allInstanceWorkbookIds.length > 0) {
            let marksExist = false;
            let ssLastReport = {};

            if (dataRangeType === "instance") {
                ssLastReport = JSON.parse(window.sessionStorage.getItem('lastInstanceReport'));
            } else {
                ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
            }

            const allTabs = ssLastReport ? ssLastReport.tabs : [];
            if (allTabs && allTabs.length > 0) {
                allTabs.map(tab => {
                    if (tab.name === tabName) {
                        let worksheets = tab.worksheets;
                        if (worksheets) {
                            if(ssLastReport['name']==="errors daily" && worksheets.length===1 && worksheets[0].name === "error_type"){
                                marksExist = false;
                            } else if (dataRangeType === "instance") {
                                if (ssLastReport['tabs'] && ssLastReport['tabs'][0]['worksheets'].length === 1 && ssLastReport['tabs'][0]['worksheets'][0].name === "data_summary") {
                                    marksExist = false;
                                } else if ((ssLastReport['tabs'] && ssLastReport['tabs'][0]['worksheets'].length === 1 && ssLastReport['tabs'][0]['worksheets'][0].name !== "data_summary" && ssLastReport['tabs'][0]['worksheets'][0]['marks']['1'] === undefined)
                                    || (ssLastReport['tabs'] && ssLastReport['tabs'][0]['worksheets'].length > 1 && ssLastReport['tabs'][0]['worksheets'][0].name === "data_summary" && ssLastReport['tabs'][0]['worksheets'][1]['marks']['1'] === undefined)
                                ) {
                                    marksExist = true;
                                }
                            } else {
                                worksheets.map(worksheet => {
                                    if (Object.keys(worksheet.marks) && Object.keys(worksheet.marks).length !== 0) {
                                        if (tabName === "User Workflows") {
                                            if (tab.isValid) {
                                                marksExist = true;
                                            } else {
                                                marksExist = false;
                                            }
                                        } else {
                                            marksExist = true;
                                        }
                                    }
                                });
                            }
                        }
                    }
                });
            }

            this.props.allInstanceWorkbookIds.map(instance => {
                const btnDrill = document.getElementById('btnDrill' + instance);
                if (btnDrill) {
                    const parentLi = btnDrill.parentElement;
                    if (!marksExist) {
                        if (!parentLi.classList.contains('disabled')) {
                            parentLi.classList.add('disabled');
                        }
                    } else {
                        if (parentLi.classList.contains('disabled')) {
                            parentLi.classList.remove('disabled');
                        }
                    }
                }
            });

            if (!marksExist) {
                this.updateDrillToReportButton(false);
            } else {
                this.updateDrillToReportButton(true);
            }
        }
    }

    escapeFilterValues(filterObj) {
        let tempObj = {};
        for (let filter in filterObj) {
            if (filterObj[filter] instanceof Array && filterObj[filter].length > 0) {
                const tempArray = filterObj[filter].map(filterVal => {
                    let tempFilterValue = filterVal;

                    if (typeof (tempFilterValue) === 'string') {
                        if (filterVal.indexOf('\\') > -1) {
                            tempFilterValue = tempFilterValue.replace(/\\/g, "\\\\");
                        }
                        if (filterVal.indexOf(',') > -1) {
                            tempFilterValue = tempFilterValue.replace(/,/g, "\\,");
                        }
                    }

                    return tempFilterValue;
                });
                tempObj[filter] = tempArray;
            } else {
                tempObj[filter] = filterObj[filter];
            }
        }
        return tempObj;
    }

    handleDropdownParameter(workBook2, targetParameterName2, targetTabName2, targetWorksheetName2) {
        workBook2.getParametersAsync()
            .then(function (params) {
                let paramName;
                let paramValue;
                for (let i = 0; i < params.length; i++) {
                    if (targetParameterName2.includes(params[i].getName())) {
                        paramName = params[i].getName();
                        paramValue = params[i].getCurrentValue().formattedValue; //"Application", "Modules"
                        break;
                    }
                }

                if(paramName && paramValue) {
                    const ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
                    let allTabs = ssLastReport ? ssLastReport.tabs : [];
                    if (allTabs && allTabs.length > 0) {
                        let tempDropdownParam = {};
                        allTabs.map(tab => {
                            if (tab.name === targetTabName2) {
                                let worksheets = tab.worksheets;
                                if (worksheets) {
                                    worksheets.map(worksheet => {
                                        if (worksheet.name.indexOf(targetWorksheetName2) !== -1) {
                                            if (worksheet.marks) {
                                                tempDropdownParam.name = paramName;
                                                tempDropdownParam.value = paramValue;
                                                worksheet.dropdownParam = tempDropdownParam;
                                            }
                                        }
                                    })
                                }
                            }
                        });

                        //Save in session storage
                        ssLastReport.tabs = allTabs;
                        window.sessionStorage.setItem('lastReport', JSON.stringify(ssLastReport));
                    }
                }
            })
    }

    handleUserAttribute(workBook, targetParameterName, targetTabName, targetWorksheetName) {
        workBook.getParametersAsync()
            .then(function (params) {
                let paramValue;
                for (let i = 0; i < params.length; i++) {
                    if (params[i].getName() === targetParameterName) {
                        paramValue = params[i].getCurrentValue().value;
                        break;
                    }
                }

                if (paramValue) {
                    const newKeyName = FILTERS.getUserAttributePrefix() + paramValue;
                    const ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
                    let allTabs = ssLastReport ? ssLastReport.tabs : [];
                    if (allTabs && allTabs.length > 0) {
                        //save value of each mark, then delete the mark from session storage
                        let tempMarks = {};
                        allTabs.map(tab => {
                            if (tab.name === targetTabName) {
                                let worksheets = tab.worksheets;
                                if (worksheets) {
                                    worksheets.map(worksheet => {
                                        tempMarks = worksheet.marks;
                                        if (Object.keys(tempMarks) && Object.keys(tempMarks).length !== 0) {
                                            if (worksheet.name.indexOf(targetWorksheetName) !== -1) {
                                                if (tempMarks) {
                                                    for (let prop in tempMarks) {
                                                        for (let fieldName in tempMarks[prop]) {
                                                            if (fieldName === 'SELECT_USER_ATTRIBUTE') {
                                                                //store saved values under new key
                                                                tempMarks[prop][newKeyName] = tempMarks[prop][fieldName];
                                                                //delete old key
                                                                delete tempMarks[prop][fieldName];
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    })
                                }
                            }
                        });

                        allTabs.map(tab => {
                            if (tab.name === targetTabName) {
                                let worksheets = tab.worksheets;
                                if (worksheets) {
                                    worksheets.map(worksheet => {
                                        let marks = worksheet.marks;
                                        if (Object.keys(marks) && Object.keys(marks).length === 0) {
                                            if (worksheet.name.indexOf(targetWorksheetName) !== -1) {
                                                worksheet.marks = tempMarks;
                                            }
                                        }
                                    });
                                }
                            }
                        });

                        //Save in session storage
                        ssLastReport.tabs = allTabs;
                        window.sessionStorage.setItem('lastReport', JSON.stringify(ssLastReport));
                    }
                }
            })
    }

    reEvaluateSelections(workBook, worksheets, tabName, dataRangeType, _this) {
        if (worksheets && worksheets.length > 0) {
            worksheets.map(worksheet => {
                let wsName = worksheet.getName();
                worksheet.getSelectedMarksAsync()
                    .then(function (allMarks) {
                        //get marks from worksheet
                        let _newMarks = _this.getMarks(allMarks, tabName, _this.props.attributeMap, wsName);

                        //save marks to session storage
                        _this.setMarksSession(_newMarks, tabName, wsName, dataRangeType);

                        //Update instance drill item
                        _this.updateDrillButton(tabName, dataRangeType);
                    })
                    .then(function () {
                        if (wsName.indexOf('user_attributes') !== -1 || wsName.indexOf('user_stats') !== -1) {
                            let worksheetName = '';
                            if (wsName.indexOf('user_attributes') !== -1) {
                                worksheetName = 'user_attributes';
                            } else {
                                worksheetName = wsName;
                            }
                            _this.handleUserAttribute(workBook, PARAMETERS.getUserAttributeParameters, tabName, worksheetName);
                        } else if(PARAMETERS.getDropdownParameterWorksheets.includes(wsName)) {
                            _this.handleDropdownParameter(workBook, PARAMETERS.getDropdownParameters, tabName, wsName);
                        }
                    })
            });
        } else {
            //Update instance drill item
            _this.updateDrillButton(tabName, dataRangeType);
        }
    }

    getParameterValue(parameterName) {
        let parameterValue;
        const ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
        if (ssLastReport) {
            let _parameters = ssLastReport.parameters;
            if (_parameters) {
                for (let c = 0; c < _parameters.length; c++) {
                    if (_parameters[c].name === parameterName) {
                        parameterValue = _parameters[c].value;
                        break;
                    }
                }
            }
        }
        return parameterValue
    }

    getParameterValueInTab(reportType,tabName, parameterName) {
        let parameterValue;
        let ssLastReport;
        if(reportType === 'instance')
            ssLastReport = JSON.parse(window.sessionStorage.getItem('lastInstanceReport'));
        else
            ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
        if (ssLastReport) {
            let allTabs = ssLastReport.tabs;
            for (let i = 0; i < allTabs.length; i++) {
                if (allTabs[i].name === tabName) {
                    if(allTabs[i]['tabParameters'] && allTabs[i]['tabParameters'][parameterName]) {
                        parameterValue = allTabs[i]['tabParameters'][parameterName];
                    }
                    break;
                }
            }
        }
        return parameterValue
    }

    setParameterValueInTab(reportType,tabName, keyName, value) {
        let ssLastReport ={};
        if(reportType==='instance')
             ssLastReport = JSON.parse(window.sessionStorage.getItem('lastInstanceReport'));
        else
            ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));

        if (ssLastReport) {
            let allTabs = ssLastReport ? ssLastReport.tabs : [];
            for (let i = 0; i < allTabs.length; i++) {
                if (allTabs[i].name === tabName) {
                    let tabParameters = allTabs[i]['tabParameters'] ? allTabs[i]['tabParameters'] : {};
                    tabParameters[keyName] = value;
                    allTabs[i]['tabParameters'] = tabParameters;
                    break;
                }
            }
            //Save in session storage
            ssLastReport.tabs = allTabs;
            if(reportType==='instance')
                window.sessionStorage.setItem('lastInstanceReport', JSON.stringify(ssLastReport));
            else
                window.sessionStorage.setItem('lastReport', JSON.stringify(ssLastReport));
        }
    }

    setParameterFlag(tabName, keyName, value) {
        const ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
        if (ssLastReport) {
            let allTabs = ssLastReport ? ssLastReport.tabs : [];
            for (let i = 0; i < allTabs.length; i++) {
                if (allTabs[i].name === tabName) {
                    allTabs[i][keyName] = value;
                    break;
                }
            }
            //Save in session storage
            ssLastReport.tabs = allTabs;
            window.sessionStorage.setItem('lastReport', JSON.stringify(ssLastReport));
        }
    }

    isWorksheetNameFound(worksheets, string, isRegex) {
        for (let i = 0; i < worksheets.length; i++) {
            if(isRegex) {
                if (worksheets[i].getName().match(string) !== -1) {
                    return true;
                }
            } else {
                if (worksheets[i].getName().indexOf(string) !== -1) {
                    return true;
                }
            }
        }
        return false;
    }

    getUserAttNum() {
        let attNum;
        const ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
        if (ssLastReport && ssLastReport.tabs) {
            for (let a = 0; a < ssLastReport.tabs.length; a++) {
                if (ssLastReport.tabs[a].lastUsed) {
                    let _ws = ssLastReport.tabs[a].worksheets;
                    if (_ws) {
                        for (let c = 0; c < _ws.length; c++) {
                            if (_ws[c].name.indexOf('user_attributes') !== -1 || _ws[c].name.indexOf('user_stats') !== -1) {
                                let marks = _ws[c].marks;
                                if (marks) {
                                    for (let prop in marks) {
                                        for (let fieldName in marks[prop]) {
                                            if(fieldName.indexOf('STRING_ATTR') !== -1) {
                                                let parts = fieldName.split(FILTERS.getUserAttributePrefix());
                                                return parts[1];
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return attNum
    }

    getDropdownParameters() {
        let dropdownParams = [];
        const ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
        if (ssLastReport && ssLastReport.tabs) {
            for (let a = 0; a < ssLastReport.tabs.length; a++) {
                if (ssLastReport.tabs[a].lastUsed) {
                    let _ws = ssLastReport.tabs[a].worksheets;
                    if (_ws) {
                        for (let c = 0; c < _ws.length; c++) {
                            if (PARAMETERS.getDropdownParameterWorksheets.includes(_ws[c].name)) {
                                if(_ws[c].dropdownParam) {
                                    dropdownParams.push(_ws[c].dropdownParam);
                                }
                            }
                        }
                    }
                }
            }
        }
        return dropdownParams
    }

    initTableau(_props) {
        const {contextFilter} = _props.filters;
        const {instanceEnabled, workflowReport, isWorkflowsIgnore} = _props;
        if (_props.dataRangeType === "instance"
            || (_props.dataRangeType === "current"
                    && (window.sessionStorage.getItem("clickFromInstance") === "true"
                    || window.sessionStorage.getItem("latestReportDataType") === "currentuser workflow"))) {
            let ssLastReport = {};
            if (_props.dataRangeType === "instance")
                ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));

            else
                ssLastReport = JSON.parse(window.sessionStorage.getItem('lastInstanceReport'));

            let allTabs = (ssLastReport && ssLastReport.tabs) ? ssLastReport.tabs : [];
            if (allTabs && allTabs.length > 0) {
                //update contextFilter
                let markedFilters = {}
                allTabs.map(tab => {
                    let _worksheets = tab.worksheets;
                    if (_worksheets) {
                        if (tab.lastUsed) {
                            _worksheets.map(worksheet => {
                                let _marks = worksheet.marks;
                                if (_marks) {
                                    let errorTypes = [];
                                    let endUser = [];
                                    for (let group in _marks) {
                                        for (let field in _marks[group]) {
                                            let fieldName = field;
                                            let value = _marks[group][field];
                                            if (contextFilter[fieldName]) {
                                                //filters that should be replaced by selection
                                                if(fieldName === FILTERS.getErrorTypeFilterName()) {
                                                    errorTypes.push(value);
                                                } else if(fieldName === FILTERS.getFilterEndUserName("default") && workflowReport && contextFilter[fieldName].length > 1) {
                                                    endUser.push(value);
                                                } else if (!contextFilter[fieldName].includes(value)) {
                                                    contextFilter[fieldName].push(value);
                                                    if (markedFilters[fieldName])
                                                        markedFilters[fieldName].push(value)
                                                    else  markedFilters[fieldName] = [value];
                                                }
                                            } else {
                                                if(!workflowReport || FILTERS.getAllowedMarksToFiltersInWorkflow().includes(fieldName)) {
                                                    contextFilter[fieldName] = [value];
                                                    markedFilters[fieldName] = [value];
                                                }
                                            }
                                        }
                                    }
                                    //filters that should be replaced by selection
                                    if(errorTypes && errorTypes.length > 0) {
                                        contextFilter[FILTERS.getErrorTypeFilterName()] = errorTypes;
                                    }
                                    if(endUser && endUser.length > 0) {
                                        contextFilter[FILTERS.getFilterEndUserName("default")] = endUser;
                                    }
                                }
                            });
                        }
                    }
                });
                window.sessionStorage.setItem('markedFilters', JSON.stringify(markedFilters));
            }
            
            //for users daily and applications daily report drill down error-type
            if(ssLastReport && (ssLastReport['name'] ==="users daily" || ssLastReport['name'] ==="applications daily") && _props.dataRangeType === "instance" && _props.url && _props.url.indexOf("MessageInstances")>-1){
                contextFilter['ERROR TYPE'] =  ['User Error', 'System Error'];
            }

            //for benchmark reports -> Aggregate Data report, Start Day ID and End Day ID should be updated depending on the selection (Benchmark, Base, or both)
            if(ssLastReport && ssLastReport.dualPeriod) {
                let benchmarkFound = false;
                let baseFound = false;
                if(contextFilter[FILTERS.getBenchmarkFilterName()]) {
                    contextFilter[FILTERS.getBenchmarkFilterName()].map(type => {
                        if (type === 'Benchmark') {
                            benchmarkFound = true;
                        } else if (type === 'Base') {
                            baseFound = true;
                        }
                    });
                }

                const baseStartFilterName = _props.dataRangeTypeForLookup === 'historical' ?  FILTERS.getStartMonthIdFilterName() : FILTERS.getStartDayIdFilterName();
                const baseEndFilterName = _props.dataRangeTypeForLookup === 'historical' ?  FILTERS.getEndMonthIdFilterName() : FILTERS.getEndDayIdFilterName();
                const BenchmarkStartFilterName = _props.dataRangeTypeForLookup === 'historical' ?  FILTERS.getBenchmarkStartMonthIdFilterName() : FILTERS.getBenchmarkStartDayIdFilterName();
                const BenchmarkEndFilterName = _props.dataRangeTypeForLookup === 'historical' ?  FILTERS.getBenchmarkEndMonthIdFilterName() : FILTERS.getBenchmarkEndDayIdFilterName();

                //base only
                let startId = contextFilter[baseStartFilterName];
                let endId = contextFilter[baseEndFilterName];

                if(!baseFound && benchmarkFound) { //benchmark only
                    startId = contextFilter[BenchmarkStartFilterName];
                    endId = contextFilter[BenchmarkEndFilterName];
                } else if(baseFound && benchmarkFound) { //base + benchmark
                    startId = contextFilter[BenchmarkStartFilterName];
                } else if(!baseFound && !benchmarkFound) { //base + benchmark on empty
                    startId = contextFilter[BenchmarkStartFilterName];
                }

                //update contextFilter
                contextFilter[baseStartFilterName] = startId;
                contextFilter[baseEndFilterName] = endId;
            }
        }

        //Escape filter values
        let tableauFilter = this.escapeFilterValues(contextFilter);
        let _this = this;

        //Support drilling in workflow report
        //dateid and username are tableau parameters and are not getting picked up by a selection (via tableau api)
        if (workflowReport) {
            const defaultDayId = FILTERS.getFilterDayId("default");
            const convertedDayId = FILTERS.getFilterDayId("converted");
            const defaultEndUserName = FILTERS.getFilterEndUserName("default");
            const convertedEndUserName = FILTERS.getFilterEndUserName("converted");
            if (tableauFilter[defaultDayId] && tableauFilter[defaultEndUserName]) {
                const tempDayId = tableauFilter[defaultDayId][0];
                tableauFilter[convertedDayId] = [];
                tableauFilter[convertedDayId].push(tempDayId);

                const tempEndUserName = tableauFilter[defaultEndUserName][0];
                tableauFilter[convertedEndUserName] = [];
                tableauFilter[convertedEndUserName].push(tempEndUserName);
                let exportWorkflowParam = {}
                exportWorkflowParam.dayId = tempDayId;
                exportWorkflowParam.username = tempEndUserName;
                window.sessionStorage.setItem('exportWorkflowParam', JSON.stringify(exportWorkflowParam));

            }

            //Apply Application Name filter when drilling from the User Workflows -> User Workflow
            if(isWorkflowsIgnore && !tableauFilter[FILTERS.getApplicationName("fieldName")]) {
                tableauFilter[FILTERS.getApplicationName("fieldName")] = tableauFilter[FILTERS.getApplicationName("contextName")];
            }

            if ((_props.dataRangeType === "current" && (window.sessionStorage.getItem("clickFromInstance") === "true" || window.sessionStorage.getItem("latestReportDataType") === "currentuser workflow"))) {
                let formattedHour = "";
                FILTERS.getTimeFilters().every(filter => {
                    if (tableauFilter[filter] && tableauFilter[filter].length !== 0) {
                        formattedHour = tableauFilter[filter];
                        return false;
                    }
                    return true;
                });

                let timeInHour = extractHour(formattedHour[0]);
                let _marks = [];
                let defaultMark = {};
                if(tableauFilter[FILTERS.getApplicationName("fieldName")] !== "") {
                    defaultMark[FILTERS.getApplicationName("fieldName")] = tableauFilter[FILTERS.getApplicationName("fieldName")];
                }
                if(tableauFilter[FILTERS.getFilterDayId("default")] !== "") {
                    defaultMark[FILTERS.getFilterDayId("default")] = tableauFilter[FILTERS.getFilterDayId("default")];
                }
                if(timeInHour !== "") {
                    defaultMark[FILTERS.getEventTimeByHour()] = [timeInHour];
                }
                _marks[0] = defaultMark;

                let tabs = [];
                let defaultTab = {};
                defaultTab["name"] = "User Workflow";
                defaultTab["lastUsed"] = "true";

                let worksheets = [];
                let defaultWorksheet = {};
                defaultWorksheet["name"] = "activity_summary";
                defaultWorksheet["marks"] = _marks;
                worksheets[0] = defaultWorksheet;
                defaultTab["worksheets"] = worksheets;
                tabs[0] = defaultTab;
                let tempWorkflowReport = JSON.parse(window.sessionStorage.getItem('workflowReport'));
                if (tempWorkflowReport)
                    tempWorkflowReport["tabs"] = tabs;

                window.sessionStorage.setItem('workflowReport', JSON.stringify(tempWorkflowReport));

                FILTERS.getTableauSelections.map(field => {
                    if (field === FILTERS.getApplicationName("fieldName") && !tableauFilter[FILTERS.getApplicationName("contextName")]) { //TEMP
                        tableauFilter[FILTERS.getApplicationName("contextName")] = [];
                    } else if (field === FILTERS.getModuleName("fieldName") && !tableauFilter[FILTERS.getModuleName("contextName")]) { //TEMP
                        tableauFilter[FILTERS.getModuleName("contextName")] = [];
                    } else if (field === FILTERS.getScreenName("fieldName") && !tableauFilter[FILTERS.getScreenName("contextName")]) { //TEMP
                        tableauFilter[FILTERS.getScreenName("contextName")] = [];
                    } else if (field === FILTERS.getMessageName("fieldName") && !tableauFilter[FILTERS.getMessageName("contextName")]) { //TEMP
                        tableauFilter[FILTERS.getMessageName("contextName")] = [];
                    } else if (!tableauFilter[field]) {
                        tableauFilter[field] = [];
                    }
                });
            }
        }

        const deviceType = isMobileOnly ? "phone" : isTablet ? "tablet" : "desktop";
        const vizUrl = this.getUrl(_props);
        let initFilters = [];
        let asyncFilters = [];
        let length = 500;
        for (let field in tableauFilter) {
            if(FILTERS.initParameters.includes(field)) {
                initFilters[field] = tableauFilter[field];
                if(tableauFilter[field] instanceof Array) {
                    for(let idx in tableauFilter[field])
                        length += (""+tableauFilter[field][idx]).length + 1
                } else {
                    length += (""+tableauFilter[field]).length;
                }
            }
        }
        for (let field in tableauFilter) {
            if(!FILTERS.initParameters.includes(field)) {
                let _length = (field.length + 2);
                if (tableauFilter[field] instanceof Array) {
                    for (let idx in tableauFilter[field])
                        _length += (tableauFilter[field][idx].length + 1)
                } else {
                    _length = ("" + tableauFilter[field]).length;
                }

                if ((length + _length) < 2000 ) {
                    initFilters[field] = tableauFilter[field];
                    length += _length;
                } else {
                    asyncFilters[field] = tableauFilter[field];
                }
            }
        }

        const options = {
            hideTabs: false,
            height: "100%",
            width: "100%",
            hideToolbar: true,
            device: deviceType,
            ..._props.options,
            ...initFilters,
            onFirstVizSizeKnown: () => {
                API.initLoadingReport("true");
                // _this.viz.hide();
            },
            onFirstInteractive: () => {
                // _this.viz.hide();
                _this.workbook = _this.viz.getWorkbook();
                _this.sheet = _this.workbook.getActiveSheet();
                _this.worksheets = _this.sheet.getWorksheets();
                _this.worksheet = _this.worksheets[0];
                _this.tabName = _this.sheet.getName();

                let count = 0;
                for (let field in asyncFilters) {
                    count++;
                    if(FILTERS.parameters.includes(field)) {
                         const tester = (asyncFilters[field]) +"";
                         if(tester.length!==0) {
                             _this.workbook.changeParameterValueAsync(field, asyncFilters[field]).then(function(res) {
                                 --count;
                                 if(count===0) {
                                     API.completeLoadingReport("false");
                                     _this.setState({fetchInProgress: false});
                                     // _this.viz.show();
                                 }
                             }, function(err) {
                                 console.log(err)
                                 --count;
                                 if(count===0) {
                                     API.completeLoadingReport("false");
                                     _this.setState({fetchInProgress: false});
                                     // _this.viz.show();
                                 }
                             });
                         }else {
                             --count
                             if(count===0) {
                                 API.completeLoadingReport("false");
                                 _this.setState({fetchInProgress: false});
                                 // _this.viz.show();
                             }
                         }
                    } else {
                        if (asyncFilters[field] && asyncFilters[field].length===0) {
                            --count;
                            if (count === 0) {
                                API.completeLoadingReport("false");
                                _this.setState({fetchInProgress: false});
                                // _this.viz.show();
                            }
                        } else {
                            _this.worksheet.applyFilterAsync(field, asyncFilters[field], window.tableau.FilterUpdateType.ADD).then(function (res) {
                                --count;
                                if (count === 0) {
                                    API.completeLoadingReport("false");
                                    _this.setState({fetchInProgress: false});
                                    // _this.viz.show();
                                }
                            }, function (err) {
                                console.log(err)
                                --count;
                                if (count === 0) {
                                    API.completeLoadingReport("false");
                                    _this.setState({fetchInProgress: false});
                                    // _this.viz.show();
                                }
                            });
                        }
                    }
                }
                const ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
                const ssLastInstanceReport = JSON.parse(window.sessionStorage.getItem('lastInstanceReport'));
                if (ssLastReport && !tabsToIgnore.includes(_this.tabName) && (_props.dataRangeType !== "instance" && (window.sessionStorage.getItem("clickFromInstance") !== "true" || window.sessionStorage.getItem("latestReportDataType") !== "currentuser workflow"))) {
                    if (!ssLastReport.tabs) {
                        ssLastReport.tabs = [];
                        ssLastReport.tabs.push({name: _this.tabName, lastUsed: true});
                        window.sessionStorage.setItem('lastReport', JSON.stringify(ssLastReport));
                    }
                } else if (ssLastInstanceReport && !tabsToIgnore.includes(_this.tabName) && !ssLastInstanceReport.tabs && _props.dataRangeType === "instance") {
                    ssLastInstanceReport.tabs = [];
                    ssLastInstanceReport.tabs.push({name: _this.tabName, lastUsed: true});
                    window.sessionStorage.setItem('lastInstanceReport', JSON.stringify(ssLastInstanceReport));
                }

                if (instanceEnabled) {
                    if (!tabsToIgnore.includes(_this.tabName) && _props.dataRangeType === "current" && (window.sessionStorage.getItem("clickFromInstance") === "true" || window.sessionStorage.getItem("latestReportDataType") === "currentuser workflow")) {
                        _this.reapplySelections(_this.tabName, _this.worksheets, _props.dataRangeType);
                    } else if (!tabsToIgnore.includes(_this.tabName) && (window.sessionStorage.getItem("clickFromInstance") !== "true" || window.sessionStorage.getItem("latestReportDataType") !== "currentuser workflow")) {  // only for drill down direction, not for go back
                        //Update all parameters
                        PARAMETERS.getTableauParameters.map(parameter => {
                            let parameterValue = _this.getParameterValueInTab(_props.dataRangeType, _this.tabName, parameter);
                            if (parameterValue) {
                                _this.changeParameterValue(_this.workbook, parameter, parameterValue);
                            }
                        });

                        //set flag for determining whether a SELECT METRIC option is available in the tab based on a worksheet name that has "_<num>"
                        _this.setParameterFlag(_this.tabName, 'selectMetric', _this.isWorksheetNameFound(_this.worksheets, '/_[0-9]+/', true) || _this.isWorksheetNameFound(_this.worksheets, '_idletime', false));

                        //Update parameters
                        for(let i = 0; i < PARAMETERS.getMetricSelectionParameters.length; i++) {
                            let parameterValue = _this.getParameterValue(PARAMETERS.getMetricSelectionParameters[i]);
                            if (parameterValue) {
                                _this.changeParameterValue(_this.workbook, PARAMETERS.getMetricSelectionParameters[i], parameterValue);
                            }
                        }

                        //update parameters: user attributes in summary tab
                        let userAttributeN = _this.getUserAttNum();
                        if (userAttributeN) {
                            userAttributeN = 'User Attribute ' + userAttributeN;
                            _this.changeParameterValue(_this.workbook, PARAMETERS.getUserAttributeParameters, userAttributeN);
                        }

                        //update dropdown parameters: e.g.,Select Application Context in Post-Migration Adoption/Performance
                        let dropdownParams = _this.getDropdownParameters();
                        if (dropdownParams) {
                            for(let j = 0; j < dropdownParams.length; j++) {
                                _this.changeParameterValue(_this.workbook, dropdownParams[j].name, dropdownParams[j].value);
                            }
                        }

                        //reapply selections
                        _this.reapplySelections(_this.tabName, _this.worksheets, _props.dataRangeType);

                        //update instance drill item
                        _this.updateDrillButton(_this.tabName, _props.dataRangeType);

                    } else {
                        //disable instance drill item
                        if (_props.allInstanceWorkbookIds && _props.allInstanceWorkbookIds.length > 0) {
                            _props.allInstanceWorkbookIds.map(instance => {
                                const btnDrill = document.getElementById('btnDrill' + instance);
                                if (btnDrill) {
                                    const parentLi = btnDrill.parentElement;
                                    if (parentLi) {
                                        if (!parentLi.classList.contains('disabled')) {
                                            parentLi.classList.add('disabled');
                                        }
                                    }
                                }
                            });
                            _this.updateDrillToReportButton(false);
                        }
                    }
                }

                // _this.setState({fetchInProgress: localStorage.getItem("isReportLoading")});
                window.sessionStorage.removeItem("showSpinner");
                window.sessionStorage.removeItem('updateTableau');
                window.sessionStorage.removeItem("clickFromInstance");
                window.sessionStorage.removeItem("clickFromBreadcrumb");
                _this.viz.setFrameSize("100%", 800);
                if(count===0) {
                    API.completeLoadingReport("false");
                    _this.setState({fetchInProgress: false});
                    // _this.viz.show();


                }
            }
        };

        // cleanup
        if (this.viz) {
            this.viz.dispose();
            this.viz = null;
        }

        if (window.tableau) {
            API.initLoadingReport("true");
            this.viz = new window.tableau.Viz(this.container, vizUrl, options);
        } else {
            return;
        }

        if (instanceEnabled) {
            this.viz.addEventListener("marksSelection", function (marksEvent) {
                const workBook = marksEvent.getViz().getWorkbook();
                const activeSheet = workBook.getActiveSheet();
                const tabName = activeSheet.getName();
                const wsName = marksEvent.getWorksheet().getName();

                if (!tabsToIgnore.includes(tabName)) {
                    marksEvent.getMarksAsync()
                        .then(function (marks) {
                            //get marks from event
                            let _marks = _this.getMarks(marks, tabName, _props.attributeMap, wsName);

                            //save marks to session storage
                            _this.setMarksSession(_marks, tabName, wsName, _props.dataRangeType);

                            //check if marks in other worksheets needs to be updated
                            const otherWorksheets = activeSheet.getWorksheets().filter(worksheet => worksheet.getName() !== wsName);
                            _this.reEvaluateSelections(workBook, otherWorksheets, tabName, _props.dataRangeType, _this);
                        })
                        .then(function () {
                            if (wsName.indexOf('user_attributes') !== -1 || wsName.indexOf('user_stats') !== -1) {
                                let worksheetName = '';
                                if (wsName.indexOf('user_attributes') !== -1) {
                                    worksheetName = 'user_attributes';
                                } else {
                                    worksheetName = wsName;
                                }
                                _this.handleUserAttribute(workBook, PARAMETERS.getUserAttributeParameters, tabName, worksheetName);
                            } else if(PARAMETERS.getDropdownParameterWorksheets.includes(wsName)) {
                                _this.handleDropdownParameter(workBook, PARAMETERS.getDropdownParameters, tabName, wsName);
                            }
                        })
                }
            });
        }

        this.viz.addEventListener("tabswitch", function (TabSwitchEvent) {
            const workbook = TabSwitchEvent.getViz().getWorkbook();
            const worksheets = workbook.getActiveSheet().getWorksheets();
            const newTabName = TabSwitchEvent.getNewSheetName();
            let ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
            let _worksheets;

            //for the workflow report
            if (_props.dataRangeType === "current" && newTabName === "User Workflow") {
                ssLastReport = JSON.parse(window.sessionStorage.getItem('workflowReport'));
            }
            //save new tab name
            if (!tabsToIgnore.includes(newTabName) && _props.dataRangeType !== "instance") {
                if (ssLastReport) {
                    let _tabs = ssLastReport.tabs;
                    if (_tabs && _tabs.length > 0) {
                        let exists = false;
                        _tabs.map(tab => {
                            if (tab.name === newTabName) {
                                tab.lastUsed = true;
                                exists = true;
                                _worksheets = tab.worksheets;
                            } else {
                                tab.lastUsed = false;
                            }
                        });
                        if (!exists) {
                            ssLastReport.tabs.push({name: newTabName, lastUsed: true});
                        }
                    } else {
                        ssLastReport.tabs = [];
                        ssLastReport.tabs.push({name: newTabName, lastUsed: true});
                    }
                    if (_props.dataRangeType === "current" && newTabName === "User Workflow") {
                        window.sessionStorage.setItem('workflowReport', JSON.stringify(ssLastReport))
                    } else
                        window.sessionStorage.setItem('lastReport', JSON.stringify(ssLastReport));
                }
            }

            if (instanceEnabled) {
                if (!tabsToIgnore.includes(newTabName) && _props.dataRangeType !== "instance") {

                    //set flag for determining whether a SELECT METRIC option is available in the tab based on a worksheet name that has "_<num>>"
                    _this.setParameterFlag(newTabName, 'selectMetric', _this.isWorksheetNameFound(worksheets, '/_[0-9]+/', true) || _this.isWorksheetNameFound(_this.worksheets, '_idletime', false));

                    if (_worksheets) {
                        //update parameters: user attributes in summary tab
                        let userAttributeN = _this.getUserAttNum();
                        if (userAttributeN) {
                            userAttributeN = 'User Attribute ' + userAttributeN;
                            _this.changeParameterValue(workbook, PARAMETERS.getUserAttributeParameters, userAttributeN);
                        }

                        //reapply selections
                        _this.reapplySelections(newTabName, worksheets, _props.dataRangeType);

                        //Update instance drill item
                        _this.updateDrillButton(newTabName, _props.dataRangeType);
                    } else {
                        //remove all selections
                        const _worksheets = TabSwitchEvent.getViz().getWorkbook().getActiveSheet().getWorksheets();
                        if (_worksheets && _worksheets.length > 0) {
                            _worksheets.map(worksheet => {
                                worksheet.clearSelectedMarksAsync();
                            });
                        }

                        //Disable instance drill items
                        if (_props.allInstanceWorkbookIds && _props.allInstanceWorkbookIds.length > 0) {
                            _props.allInstanceWorkbookIds.map(instance => {
                                const btnDrill = document.getElementById('btnDrill' + instance);
                                if (btnDrill) {
                                    const parentLi = btnDrill.parentElement;
                                    if (parentLi) {
                                        if (!parentLi.classList.contains('disabled')) {
                                            parentLi.classList.add('disabled');
                                        }
                                    }
                                }
                            });
                            _this.updateDrillToReportButton(false);
                        }
                    }
                } else {
                    //Disable instance drill item
                    if (_props.allInstanceWorkbookIds && _props.allInstanceWorkbookIds.length > 0) {
                        _props.allInstanceWorkbookIds.map(instance => {
                            const btnDrill = document.getElementById('btnDrill' + instance);
                            if (btnDrill) {
                                const parentLi = btnDrill.parentElement;
                                if (parentLi) {
                                    if (!parentLi.classList.contains('disabled')) {
                                        parentLi.classList.add('disabled');
                                    }
                                }
                            }
                        });
                        _this.updateDrillToReportButton(false);
                    }
                }
            }
        });

        this.viz.addEventListener("parametervaluechange", function (ParameterEvent) {
            const workBook = ParameterEvent.getViz().getWorkbook();
            const activeSheet = workBook.getActiveSheet();
            const workSheets = activeSheet.getWorksheets();
            const tabName = activeSheet.getName();
            if (ParameterEvent.getParameterName() === PARAMETERS.getUserAttributeParameters || PARAMETERS.getDropdownParameters.includes(ParameterEvent.getParameterName())) {
                ParameterEvent.getParameterAsync()
                    .then(function (parameter) {
                        _this.reEvaluateSelections(workBook, workSheets, tabName, _props.dataRangeType, _this);
                    });
            } else if (PARAMETERS.getMetricSelectionParameters.includes(ParameterEvent.getParameterName())) {
                ParameterEvent.getParameterAsync()
                    .then(function (parameter) {
                        const ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
                        if (ssLastReport) {
                            let parameters = ssLastReport.parameters ? ssLastReport.parameters : [];
                            if (parameters && parameters.length > 0) {
                                let exists = false;
                                for (let i = 0; i < parameters.length; i++) {
                                    if (PARAMETERS.getMetricSelectionParameters.includes(parameters[i].name)) {
                                        exists = true;
                                        parameters[i].value = parameter.getCurrentValue().formattedValue;
                                        ssLastReport.parameters = parameters;
                                        break;
                                    }
                                }
                                if (!exists) {
                                    parameters.push({name: parameter.getName(), value: parameter.getCurrentValue().formattedValue});
                                    ssLastReport.parameters = parameters;
                                }
                            } else {
                                parameters.push({name: parameter.getName(), value: parameter.getCurrentValue().formattedValue});
                                ssLastReport.parameters = parameters;
                            }

                            //remove all selections for tabs that have selectMetric = true in session storage only if selection ACTUALLY changed (this is also executed on initial load)
                            let paramValueFound = false;
                            let paramValueChanged = false;
                            for(let j = 0; j < PARAMETERS.getMetricSelectionParameters.length; j++) {
                                let parameterValue = _this.getParameterValue(PARAMETERS.getMetricSelectionParameters[j]);
                                if (parameterValue) {
                                    paramValueFound = true;
                                    if (parameterValue !== parameter.getCurrentValue().formattedValue) {
                                        paramValueChanged = true;
                                    }
                                    break;
                                }
                            }
                            if (!paramValueFound || (paramValueFound && paramValueChanged)) {
                                let allTabs = ssLastReport.tabs ? ssLastReport.tabs : [];
                                allTabs.map(tab => {
                                    if (tab.worksheets && tab.worksheets.length > 0 && tab.selectMetric && tab.selectMetric === true) {
                                        delete tab.worksheets;
                                    }
                                });

                                //Save in session storage
                                ssLastReport.tabs = allTabs;
                                window.sessionStorage.setItem('lastReport', JSON.stringify(ssLastReport));

                                //Update instance drill item
                                _this.updateDrillButton(tabName, _props.dataRangeType);
                            } else {
                                //Save in session storage
                                window.sessionStorage.setItem('lastReport', JSON.stringify(ssLastReport));
                            }
                        }
                    })
                    .then(function (parameter) {
                        _this.reEvaluateSelections(workBook, workSheets, tabName, _props.dataRangeType, _this);
                    })
            } else if(PARAMETERS.getTableauParameters.includes(ParameterEvent.getParameterName())) {
                ParameterEvent.getParameterAsync()
                    .then(function (parameter) {
                        _this.setParameterValueInTab(_props.dataRangeType,tabName,ParameterEvent.getParameterName(), parameter.getCurrentValue().value);
                    })
            }
        })

        this.viz.addEventListener("filterchange", function (FilterEvent) {

            const workBook = FilterEvent.getViz().getWorkbook();
            const activeSheet = workBook.getActiveSheet();
            const workSheets = activeSheet.getWorksheets();
            const tabName = activeSheet.getName();
            const fieldName = FilterEvent.getFieldName();
            const sliderWorksheet = FilterEvent.getWorksheet();

            if (fieldName) {
                FilterEvent.getFilterAsync()
                    .then(function (filterObj) {
                        let ssLastReport;
                        if (_props.dataRangeType === "current" && tabName !== "User Workflow") {
                            ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
                        } else if (_props.dataRangeType === "instance") {
                            ssLastReport = JSON.parse(window.sessionStorage.getItem('lastInstanceReport'));
                        }

                        let reportTabs = ssLastReport.tabs;
                        let targetTab = reportTabs.filter(x => x.name === tabName)[0];

                        if (targetTab) {
                            let sliderFilter = targetTab.sliderFilter;
                            if (sliderFilter) {
                                sliderFilter.currentTab = tabName;
                                sliderFilter.sliderWorksheet = sliderWorksheet.getName();
                                sliderFilter.name = fieldName;
                                sliderFilter.min = filterObj.getMin();
                                sliderFilter.max = filterObj.getMax();
                            } else {
                                sliderFilter = {};
                                sliderFilter.currentTab = tabName;
                                sliderFilter.sliderWorksheet = sliderWorksheet.getName();
                                sliderFilter.name = fieldName;
                                sliderFilter.min = filterObj.getMin();
                                sliderFilter.max = filterObj.getMax();
                            }

                            ssLastReport.tabs.forEach(tab => {
                                if (tab.name === targetTab.name) tab.sliderFilter = sliderFilter
                            });
                        }

                        if (_props.dataRangeType === "current" && tabName !== "User Workflow") {
                            window.sessionStorage.setItem('lastReport', JSON.stringify(ssLastReport));
                        } else if (_props.dataRangeType === "instance") {
                            window.sessionStorage.setItem('lastInstanceReport', JSON.stringify(ssLastReport));
                        }
                    })
                    .then(function (filterObj) {
                        _this.reEvaluateSelections(workBook, workSheets, tabName, _props.dataRangeType, _this);
                    });
            }




            //  let activeSt = workSheets[0].getFiltersAsync();

            // FilterEvent.getFilterAsync()
            //     .then(function (filterObj) {
            //         const filterType = filterObj.getFilterType();
            //         let filterValue;
            //
            //         filterValue = "[" + _this.getCategoricalValues(filterObj.getAppliedValues()).toString() + "]";
            //
            //         console.log(filterObj.getFieldName() + " Filter [" + filterType + "] is updated! Applied values " + filterValue);
            //     });
        });

    };

    componentDidMount() {
        this.setState({
            frameWidth: this.container.offsetWidth,
            fetchInProgress: "true"
        });
        this.initTableau(this.props);
        // setTimeout(
        //     () => this.setState({fetchInProgress: "false"}),
        //     30000
        // );
    }

    componentWillUnmount() {
        // stop refreshing Tableau
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer);
            this.refreshTimer = null;
        }
        window.sessionStorage.removeItem("showSpinner");
    }

    componentWillReceiveProps(nextProps) {
        const isReportChanged = nextProps.url.substring(0, nextProps.url.lastIndexOf('/')) !== this.props.url.substring(0, this.props.url.lastIndexOf('/'));
        const isFiltersChanged = !shallowequal(this.props.timeFilter, nextProps.timeFilter, this.compareArrays);
        const isParametersChanged = !shallowequal(this.props.parameters, nextProps.parameters);
        const isLoading = this.state.loading;

        if (window.sessionStorage.getItem("showSpinner") === "true") {
            this.setState({fetchInProgress: "true"});
        }

        // Only report is changed - re-initialize
        if (isReportChanged) {
            this.initTableau(nextProps);
        }

        if (window.sessionStorage.getItem('updateTableau') === "true") {
            this.initTableau(nextProps);
        }

        // Only filters are changed, apply via the API
        if (!isReportChanged && isFiltersChanged && !isLoading) {
            this.applyFilters(nextProps.timeFilter);
        }

        // Only parameters are changed, apply via the API
        if (!isReportChanged && isParametersChanged && !isLoading) {
            this.applyParameters(nextProps.parameters);
        }

        // token change, validate it.
        if (nextProps.token !== this.props.token) {
            this.setState({didInvalidateToken: false});
        }

        // refresh rate change
        if (nextProps.refreshSeconds !== this.props.refreshSeconds) {
            // clear current refresh interval timer if it is set
            if (typeof (this.refreshTimer) !== "undefined") {
                clearInterval(this.refreshTimer);
            }

            // if the new refresh rate is > 0, set up the timer
            if (nextProps.refreshSeconds > 0) {
                this.refreshTimer = setInterval(this.refresh.bind(this), nextProps.refreshSeconds * 1000);
            }
        }

        // setTimeout(
        //     () => this.setState({fetchInProgress: "false"}),
        //     30000
        // );
    }

    compareArrays(a, b) {
        if (Array.isArray(a) && Array.isArray(b)) {
            return a.sort().toString() === b.sort().toString();
        }

        return undefined;
    }

    applyFilters(filters) {
        const REPLACE = TableauApi.FilterUpdateType.REPLACE;
        const promises = [];

        this.setState({loading: true});
        for (const key in filters) {
            if (
                !this.state.filters.hasOwnProperty(key) ||
                !this.compareArrays(this.state.filters[key], filters[key])
            ) {
                promises.push(this.worksheet.applyFilterAsync(key, filters[key], REPLACE));
            }
        }

        this.onComplete(promises, () => this.setState({loading: false, filters}));
    }

    onComplete(promises, cb) {
        Promise.all(promises).then(() => cb(), () => cb())
    }

    refresh() {
        if (typeof (this.viz) !== "undefined") {
            this.viz.refreshDataAsync();
        }
    }

    render() {
        return (
            <div>
                {this.state.fetchInProgress === "true" && <Spinner/>}
                <div ref={c => this.container = c} className="report-tableau-main-content knoa-region" tabIndex={"-1"}/>
            </div>
        );
    }
}

TableauWorkbook.propTypes = propTypes;
TableauWorkbook.defaultProps = defaultProps;

export default TableauWorkbook;