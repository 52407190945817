import React, { Component } from "react";
import {Col} from "react-bootstrap";

class KnoaCardStat extends Component {
  render() {
    const {
      stats,
      iconStatus
    } = this.props;

    return(
      <Col sm={8} className="card-footer-col-right">
        <div
          className={"stats text-right " + (stats.dataDiff === "0" ? "text-gray-dark" : iconStatus==="success" ? "text-success" : "text-danger-home")}>
          <i className="material-icons">
            {(stats.dataDiff === "0"  ? "" : stats.dataDiff > 0 ? "trending_up" : "trending_down")}
          </i>&nbsp; {stats.text}
        </div>
      </Col>
    )
  }
}

export default KnoaCardStat;