import React, {Component} from "react";
import NotificationSystem from "react-notification-system";
import NotificationStyles from "../../../components/common/NotificationStyles";
import {Link, Redirect} from "react-router-dom";
import {Grid,Row,Col,FormGroup,ControlLabel,FormControl} from "react-bootstrap";
import Card from "../../../components/Card/Card";
import Button from "../../../components/creativeTim/CustomButton";
import PropTypes from "prop-types";
import {API} from "lib/API";
import Spinner from "../../../components/common/Spinner";
import {withTranslation} from 'react-i18next';
import Footer from "../../../components/layout/Footer";
import CommonContainer from "../../../components/common/CommonContainer";
import ModalDialog from "../../../components/common/ModalDialog";
import {getErrorMessagesFromCode, getErrorMsgFromStatus, getInfoMessages, getSuccessMessages} from "../../../lib/messageHandler";
import {Helmet} from "react-helmet";
const localStorage = require('../../../lib/localStorage');
class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.verifyLength = this.verifyLength.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.isValidated = this.isValidated.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
    this.parseURL = this.parseURL.bind(this);
    this.handleSubmitNewRequest = this.handleSubmitNewRequest.bind(this);
    this.validateToken = this.validateToken.bind(this);

    this.passwordInputOld = null;
    this.passwordInputNew = null;
    this.passwordInputConfirm = null;

    this.setPasswordInputOldRef = element => {
      this.passwordInputOld = element;
    };
    this.setPasswordInputNewRef = element => {
      this.passwordInputNew = element;
    };
    this.setPasswordInputConfirmRef = element => {
      this.passwordInputConfirm = element;
    };
    this.focusPasswordInputOld = () => {
      if (this.passwordInputOld) this.passwordInputOld.focus();
    };
    this.focusPasswordInputNew = () => {
      if (this.passwordInputNew) this.passwordInputNew.focus();
    };
    this.focusPasswordInputConfirm = () => {
      if (this.passwordInputConfirm) this.passwordInputConfirm.focus();
    };

    this.mainContent = React.createRef();

    this.state = {
      isPasswordExpired: (this.props.location.state && this.props.location.state.isPasswordExpired) ? true : false,
      email: (this.props.location.state && this.props.location.state.email) ? this.props.location.state.email : null,
      passwordMin: (this.props.location.state && this.props.location.state.passwordMin) ? this.props.location.state.passwordMin : -1,
      passwordMax: (this.props.location.state && this.props.location.state.passwordMax) ? this.props.location.state.passwordMax : -1,
      isFromSidebar: this.props.location.pathname==='/admin/password' ? true : false,
      passwordState: '',
      passwordOld: '',
      passwordNew: '',
      passwordConfirm: '',
      token: PropTypes.string,
      passwordErrorOld: null,
      passwordErrorNew: null,
      passwordErrorConfirm: null,
      transition: false,
      transitionTitle: PropTypes.string,
      transitionMessage: PropTypes.string,
      transitionMessageDiv: PropTypes.string,
      transitionIcon: PropTypes.string,
      transitionIconColor: PropTypes.string,
      submitNewRequest: false,
      loginRedirect: false,
      resetPasswordDisabled: true,
      _notificationSystem: null,
      fetchInProgress: "false",
      showLoggedoutModal: false,
      sessionTerminated: false
    }
  }

  resetValues() {
    this.setState({
      passwordState: '',
      passwordOld: '',
      passwordNew: '',
      passwordConfirm: '',
      resetPasswordDisabled: true
    });
    this.focusPasswordInputOld();
  }

  validateToken() {
    //valid, invalid_format, expired, invalid
    let tokenState = this.parseURL('valid');

    if (tokenState === 'valid') {
      let tokenValue = this.parseURL('token');
      this.setState({
        token: tokenValue,
        transitionTitle: 'Password Reset Successful',
        transitionMessage: 'Success! Your password has been changed.',
        transitionMessageDiv: 'transitionMessageDivSuccess',
        transitionIcon: 'pe-7s-check',
        transitionIconColor: 'forgotPassIconSuccess',
        transition: false
      });
    } else if (tokenState === '') {
      this.setState({loginRedirect: true});
    } else {
      let message = 'An unexpected error occurred! We\'ll need to re-send your authentication email. Please submit a new request to reset your password.';
      if (tokenState === 'expired') {
        message = 'Sorry, your token expired! We\'ll need to re-send your authentication email. Please submit a new request to reset your password.';
      }

      this.setState({
        transitionTitle: 'Password Reset Problem',
        transitionMessageDiv: 'transitionMessageDivProblem',
        transitionIcon: 'pe-7s-attention',
        transitionIconColor: 'forgotPassIconProblem',
        transitionMessage: message,
        transition: true
      });
    }
  }

  handleSubmitNewRequest() {
    this.setState({submitNewRequest: true});
  }

  parseURL(key) {
    let value = '';
    const search = this.props.location.search; //"?valid=valid&token=eyJpZ..."
    const items = search.split('&'); //["?valid=valid","token=eyJpZ..."]
    let indexStart;
    for(let i=0; i<items.length; i++){
      if(items[i].indexOf(key)!==-1){
        indexStart = items[i].indexOf(key + '=')+key.length+1;
        value = items[i].substring(indexStart);
        break;
      }
    }
    return value;
  }

  verifyLength = (value, length) => {
    return value.length >= length;
  };

  isValidated = () => {
    if (this.state.passwordState==='valid') {
      return true;
    } else {
      this.setState({passwordState: "invalid"});
      return false;
    }
  };

  submitRequest() {
    this.setState({fetchInProgress: "true"});

    let stringAPI;
    let objAPI = {};

    if(this.state.isPasswordExpired) {
      stringAPI = '/session/expiredPassword';
      objAPI.email = this.state.email;
      objAPI.oldPassword = this.state.passwordOld;
      objAPI.password = this.state.passwordNew;
    } else if(this.state.isFromSidebar) {
      stringAPI = '/user/v2/changePassword';
      objAPI.oldPassword = this.state.passwordOld;
      objAPI.password = this.state.passwordNew;
    } else {
      stringAPI = '/session/reset';
      objAPI.token = this.state.token;
      objAPI.password = this.state.passwordNew;
    }

    //Trim password if character size is greater than max
    if(objAPI.password.length > this.state.passwordMax) {
      objAPI.password = objAPI.password.substring(0,this.state.passwordMax);
    }
    if(objAPI.oldPassword && objAPI.oldPassword.length > this.state.passwordMax) {
      objAPI.oldPassword = objAPI.oldPassword.substring(0,this.state.passwordMax);
    }

    API.user
        .post(stringAPI, objAPI)
        .then(resp => {
          if(resp) {
            const {data: resData} = resp;

            let inSupport = resData ? resData.inSupport : false;

            if(this.state.isFromSidebar) {
              if(localStorage.get("accessDenied401") === "true"){
                this.setState({showLoggedoutModal:true});
                return;
              }
              this.props.clearAlerts();
              this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t,'generic', 'passwordChange'), inSupport);
              this.resetValues();
            }
            else {
              this.props.clearAlerts();
              this.setState({
                transition: true
              });
            }
          }

          this.setState({fetchInProgress: localStorage.get("isLoading")});
        })
        .catch(e => {
          console.error(e);
          this.setState({fetchInProgress: localStorage.get("isLoading")});
          if(localStorage.get("accessDenied401") === "true" && this.state.isFromSidebar){
            this.setState({showLoggedoutModal:true});
            return;
          }

          let resultCode = -1;

          if(e.data) {
            resultCode = e.data.resultCode;
          }

          if(resultCode > -1)
            if(this.state.isFromSidebar) {
              this.props.clearAlerts();
              this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
            } else {
              this.props.clearAlerts();
              this.props.handleAlertClick( "error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, false), 0);
            }
          else
          if(this.state.isFromSidebar) {
            this.props.clearAlerts();
            this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
          } else {
            this.props.clearAlerts();
            this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, false));
          }

        });
  };

  handleLogoutConfirm = () =>{
    this.setState({sessionTerminated: true});
  };


  submitForm = e => {
    e.preventDefault();
    if (!this.isValidated()) return;
    this.submitRequest();
  };

  change = (event, stateName, type) => {
    let resetPasswordDisabled = true;
    if(type==='New' || type==='Confirm') {
      let state = 'invalid';
      let reason = null;
      let clearOther = false;
      let otherType = type === 'New' ? 'Confirm' : 'New';
      let otherValue = this.state[stateName + otherType];
      let minValue = this.state.passwordMin;
      let maxValue = this.state.passwordMax;

      //length verification
      if (this.verifyLength(event.target.value, minValue)) {
        //uppercase/lowercase/number/specialCharacter verification
        let otherIssues = [];
        let specialChars = RegExp('[!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]');
        let targetValue = event.target.value;
        if(targetValue.length > maxValue){
          targetValue = targetValue.substring(0,maxValue);
        }

        if (!targetValue.match(/[A-Z]/g)) {
          otherIssues.push(this.props.t('validation:uppercaseLetter', {input: "1"}));
        }
        if (!targetValue.match(/[a-z]/g)) {
          otherIssues.push(this.props.t('validation:lowercaseLetter', {input: "1"}));
        }
        if (!targetValue.match(/[0-9]/g)) {
          otherIssues.push(this.props.t('validation:numberInput', {input: "1"}));
        }
        if (!specialChars.test(targetValue)) {
          otherIssues.push(this.props.t('validation:specialCharacter', {input: "1"}));
        }

        if (otherIssues.length > 0) {
          reason = this.props.t('validation:passwordIssues') + " " + otherIssues.join(', ');
        } else {
          //mismatch verification
          if (typeof (otherValue) === 'string' && this.verifyLength(otherValue, minValue)) {
            if (event.target.value !== otherValue) {
              reason = this.props.t('validation:passwordMismatch');
              clearOther = true;
            } else {
              state = 'valid';
              if((typeof (this.state.passwordOld) === 'string' && this.verifyLength(this.state.passwordOld, 1)) || ((!this.state.isPasswordExpired && !this.state.isFromSidebar))){
                resetPasswordDisabled = false;
              }
            }
          }
        }
      } else if (this.verifyLength(event.target.value, 1)) {
        reason = this.props.t('validation:passwordLength', {input: minValue});
        if (typeof (otherValue) === 'string' && this.verifyLength(otherValue, minValue) && event.target.value !== otherValue) {
          clearOther = true;
        }
      }

      this.setState({
        [stateName + "State"]: state,
        ['passwordError' + type]: !reason ? null : (
            <small className="text-danger">
              {reason}
            </small>
        ),
        [stateName + type]: event.target.value,
        ['passwordError' + otherType]: (clearOther || typeof (otherValue) !== 'string') ? null : this.state['passwordError' + otherType],
        resetPasswordDisabled: resetPasswordDisabled
      });
    } else if(type==='Old') {
      if (this.verifyLength(event.target.value, 1) && this.state[stateName + "State"]==='valid') {
        resetPasswordDisabled = false;
      }

      this.setState({
        [stateName + type]: event.target.value,
        resetPasswordDisabled: resetPasswordDisabled
      });
    }
  };

  componentDidMount() {
    if(!this.state.isPasswordExpired) {
      //CASE 1: User changes password from inside app
      if(this.state.isFromSidebar) {
        let minPassLength = -1;
        let maxPassLength = -1;
        API.user
          .get('/user/passwordLengthSetting')
          .then(resp => {
            const {data} = resp.data;
            minPassLength = data.min;
            maxPassLength = data.max;

            this.setState({
              passwordMin: minPassLength,
              passwordMax: maxPassLength
            });
          })
          .catch(e => {
            console.error(e);

            let resultCode = -1;
            if (e.data) {
              resultCode = e.data.resultCode;
            }

            if (resultCode > -1)
              this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
            else
              this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
          })
      } else {
        //CASE 2: User resets password via EMAIL
        let passMin = this.parseURL("min");
        let passMax = this.parseURL("max");

        this.setState({
          _notificationSystem: this.refs.notificationSystem,
          passwordMin: passMin,
          passwordMax: passMax
        });

        this.validateToken();
        this.focusPasswordInputNew();
      }
    } else {
      //CASE 3: User's password is expired
      this.setState({
        _notificationSystem: this.refs.notificationSystem,
        transitionTitle: 'Password Reset Successful',
        transitionMessage: 'Success! Your password has been changed.',
        transitionMessageDiv: 'transitionMessageDivSuccess',
        transitionIcon: 'pe-7s-check',
        transitionIconColor: 'forgotPassIconSuccess'
      });
      this.props.clearAlerts();
      this.props.handleAlertClick("error", "tc", "Logon failure: The password has expired or no longer meets the password requirement. Please choose a new password.", 0);
      this.focusPasswordInputOld();
    }

    if(this.mainContent && this.mainContent.current) {
      this.mainContent.current.focus();
    }
  }

  render() {
    if(this.state.sessionTerminated)
      return <Redirect to={{pathname: '/auth/logout'}}/>;
    else if(this.state.submitNewRequest){
      return <Redirect to={{pathname: '/auth/forgotPassword'}}/>
    } else if(this.state.loginRedirect){
      return <Redirect to={{pathname: '/auth/login'}}/>
    }

    const {transitionTitle, transitionIcon, transitionIconColor, transitionMessageDiv, transitionMessage, transition, passwordErrorOld, passwordErrorNew, passwordErrorConfirm, passwordState, isPasswordExpired, resetPasswordDisabled, isFromSidebar, passwordMin, passwordMax} = this.state;

    const footerObj = [];
    if(isFromSidebar) {
      const updatePasswordObj = {
        name: this.props.t('common:save'),
        callback: this.submitForm,
        disabled:resetPasswordDisabled
      };
      footerObj.push(updatePasswordObj);
    }

    return (
        <Grid bsClass={isFromSidebar ? null : 'container'}>
          <Helmet>
            {isFromSidebar &&<title>Knoa Analytics - Password</title>}
            {!isFromSidebar &&<title>Knoa Analytics - Reset Password</title>}
          </Helmet>
          {!isFromSidebar &&
          <Row>
            <NotificationSystem ref="notificationSystem" style={NotificationStyles}/>
          </Row>
          }
          {isFromSidebar &&
          <ModalDialog
              showModal={this.state.showLoggedoutModal}
              title={"Log Out"}
              contentBody={
                <span>{getInfoMessages(this.props.t, 'logoutfor401',null,null)}</span>
              }
              bodyPaddingBottom='15px'
              footerBtnBsStyle="success"
              footerBtnClass="KnoaSuccessButton"
              footerBtnOnClick={this.handleLogoutConfirm}
              footerBtnText={this.props.t('common:ok')}
          />
          }
          <Col md={isFromSidebar ? null : 4} sm={isFromSidebar ? null : 6} mdOffset={isFromSidebar ? null : 4} smOffset={isFromSidebar ? null : 3}>
            {!transition ? (
                <form onSubmit={this.submitForm}>
                  <div className={isFromSidebar ? "main-content main-content-page-with-footer" : null} ref={isFromSidebar ? this.mainContent : null} tabIndex={"-1"}>
                    {this.state.fetchInProgress  === "true" && <Spinner/>}
                    <div hidden={this.state.fetchInProgress  === "true"}>
                      <CommonContainer
                          class={isFromSidebar ? "common-page-content-row" : null}
                          content={
                            <Col className="knoa-region" tabIndex={"-1"}>
                              <Card
                                  textCenter={isFromSidebar ? false : true}
                                  title={isFromSidebar ? this.props.t('common:changePassword') : "Reset Password"}
                                  content={
                                    <div className={isFromSidebar ? null : 'resetPasswordInfo'}>
                              <span
                                  className='forgotPassInfo'>{this.props.t('messages.item:passwordComplexityTitle')}</span>
                                      <ul className='resetPasswordList'>
                                        <li>{this.props.t('messages.item:passwordComplexityLength', {min: passwordMin, max: passwordMax})}</li>
                                        <li>{this.props.t('validation:containAtLeast', {key: "uppercaseLetter", input: "1"})}</li>
                                        <li>{this.props.t('validation:containAtLeast', {key: "lowercaseLetter", input: "1"})}</li>
                                        <li>{this.props.t('validation:containAtLeast', {key: "numberInput", input: "1"})}</li>
                                        <li>{this.props.t('validation:containAtLeast', {key: "specialCharacter", input: "1"})}</li>
                                        <li>{this.props.t('validation:passwordHistoryInfo')}</li>
                                      </ul>
                                      {(isPasswordExpired || isFromSidebar) &&
                                      <FormGroup>
                                        <ControlLabel htmlFor="currentPassword">{this.props.t('common:currentPassword')} <span className="text-danger-1">*</span></ControlLabel>
                                        <Row className={isFromSidebar ? null : 'cardRowPad'}>
                                          <Col xs={isFromSidebar ? 8 : null} sm={isFromSidebar ? 6 : null}>
                                            <FormControl
                                                value={this.state.passwordOld}
                                                placeholder={this.props.t('common:currentPassword')}
                                                type="password"
                                                autoComplete="off"
                                                onChange={e => this.change(e, "password", "Old")}
                                                inputRef={this.setPasswordInputOldRef}
                                                className={isFromSidebar ? 'passField' : null}
                                                name="currentPassword"
                                                id="currentPassword"
                                                aria-required="true"
                                            />
                                            {passwordErrorOld}
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                      }
                                      <FormGroup>
                                        <ControlLabel htmlFor="newPassword">{this.props.t('common:newPassword')} <span className="text-danger-1">*</span></ControlLabel>
                                        <Row className={isFromSidebar ? null : 'cardRowPad'}>
                                          <Col xs={isFromSidebar ? 8 : null} sm={isFromSidebar ? 6 : null}>
                                            <FormControl
                                                value={this.state.passwordNew}
                                                placeholder={this.props.t('common:newPassword')}
                                                type="password"
                                                autoComplete="off"
                                                onChange={e => this.change(e, "password", "New")}
                                                inputRef={this.setPasswordInputNewRef}
                                                className={isFromSidebar ? 'passField' : null}
                                                name="newPassword"
                                                id="newPassword"
                                                aria-required="true"
                                            />
                                            {passwordErrorNew}
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                      <FormGroup>
                                        <ControlLabel htmlFor="confirmNewPassword">{this.props.t('common:confirmNewPassword')} <span className="text-danger-1">*</span></ControlLabel>
                                        <Row className={isFromSidebar ? null : 'cardRowPad'}>
                                          <Col xs={isFromSidebar ? 8 : null} sm={isFromSidebar ? 6 : null}>
                                            <FormControl
                                                value={this.state.passwordConfirm}
                                                placeholder={this.props.t('common:confirmPassword')}
                                                type="password"
                                                autoComplete="off"
                                                onChange={e => this.change(e, "password", "Confirm")}
                                                inputRef={this.setPasswordInputConfirmRef}
                                                className={isFromSidebar ? 'passField' : null}
                                                name="confirmNewPassword"
                                                id="confirmNewPassword"
                                                aria-required="true"
                                            />
                                            {passwordErrorConfirm}
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                    </div>
                                  }
                                  legend={!isFromSidebar &&
                                  <>
                                    <Button
                                        disabled={resetPasswordDisabled}
                                        bsStyle="info"
                                        fill
                                        wd
                                        type="submit">
                                      Reset Password
                                    </Button>
                                    <br></br>
                                    <Link className='loginLink' to={{pathname: "/auth/login"}}>Cancel</Link>
                                  </>
                                  }
                                  ftTextCenter
                                  custom='resetPasswordCard'
                                  contentClass={isFromSidebar ? 'cardContent' : null}
                              />
                            </Col>
                          }
                      />
                    </div>
                    {isFromSidebar &&
                    <Footer fluid footerObj={footerObj}/>
                    }
                  </div>
                </form>
            ) : (
                <Card
                    textCenter
                    title={transitionTitle}
                    content={
                      <div>
                        <div className='forgotPassIconDiv'><i className={transitionIcon + ' ' + transitionIconColor} /></div>
                        <div className={transitionMessageDiv}>
                          <span>{transitionMessage}</span>
                        </div>
                      </div>
                    }
                    legend={
                      <>
                        {passwordState!=='valid' &&
                        <>
                          <Button
                              bsStyle="info"
                              fill
                              wd
                              type="submit"
                              onClick={this.handleSubmitNewRequest}>
                            Submit new request
                          </Button>
                        </>
                        }
                        <br></br>
                        <Link className='loginLink' to={{pathname: "/auth/login"}}>Return to login</Link>
                      </>
                    }
                    ftTextCenter
                />
            )
            }
          </Col>
        </Grid>
    );
  }
}

export default withTranslation()(ResetPassword);