module.exports = {
    HEART_BEAT: 'ping',
    TIMEOUT: 'timeout',
    LANGUAGE_CODE: 'language_code',
    TEMP_USER_ID: 'maui_temp_user_id',
    SESSION_LANGUAGE: 'session_lang',
    LOGIN_PROGRESS: 'login_progress',
    USER_TIMEZONE:"user_timezone",
    SERVER_TIMEZONE:"server_timezone"
};
