import React, { Component } from "react";
import KnoaTab from "../common/KnoaTab";
import {Col, FormGroup} from "react-bootstrap";

class ProjectReports extends Component {
  render() {
    const {projects, handleProjectReportsUpdate, selectAllHandler,isSelectAllChecked,resetSelectAllCheckStatus,
      reportType, trans} = this.props;
    return (
      <FormGroup>
        <Col mdOffset={1}>
          <h4 className={"knoa-region"} tabIndex={"-1"}>{trans('project:selectReports')}</h4>
          <KnoaTab
            data={projects}
            controlId="formReports"
            handleProjectReportsUpdate={handleProjectReportsUpdate}
            selectAllHandler={selectAllHandler}
            isSelectAllChecked ={isSelectAllChecked}
            resetSelectAllCheckStatus={resetSelectAllCheckStatus}
            reportType={reportType}
            trans={trans}
          />
        </Col>
      </FormGroup>
    );
  }
}

export default ProjectReports;