/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from "react";
import {Grid, Col, Row,DropdownButton, MenuItem} from "react-bootstrap";
import Card from "../../../components/Card/Card.jsx";
import TableauWorkbook from "../../../components/tableau/TableauWorkbook";
import PropTypes from "prop-types";
import {API} from "../../../lib/API"
import StatCard from "../../../components/Card/StatCard";
import {withTranslation} from "react-i18next";
import {Responsive, WidthProvider} from 'react-grid-layout';
import {layoutsHome} from "../../../lib/gridLayouts";
import {NavLink, Redirect} from "react-router-dom";
import ModalDialog from "../../../components/common/ModalDialog";
import {getServerTimezoneDate} from "../../../lib/common";
import {getErrorMessagesFromCode, getErrorMsgFromStatus, getInfoMessages} from "../../../lib/messageHandler";
import {Helmet} from "react-helmet";
const localStorage = require('../../../lib/localStorage');
const key = require('../../../constant/key');

const GetMenuItems = ({workbooks, activeMenuItem, menuSelect, menuClick, trans}) => {
    let menuItems = [];
    if(workbooks && workbooks.length>0) {
        //console.log("the workbooks length: "+workbooks.length);
        workbooks.forEach(wb => {
            menuItems.push(
                <MenuItem key={wb.id} eventKey={wb.id} onSelect={menuSelect} onClick={menuClick}
                          active={wb.id === activeMenuItem.id}>{trans('watchlist:'+wb.workbookName)}</MenuItem>
            );
        });
    }
    return menuItems;
};

const SingleMauiReport = props => {
    const {loaded, tableauUrl, token, workbook , filters, dataRangeType, trans} = props;
    if(loaded && workbook) {
        const _url = tableauUrl+"/t/"+workbook.siteName+"/views/"+workbook.viewUrl;
        return (
            <Row>
                <Col md={12} >
                    <Card
                        title={trans('watchlist:'+workbook.workbookName)}
                        category={trans('watchlist:'+workbook.description)}
                        helpLinkReport ={workbook.workbookName}
                        helpLinkText ={trans('report.name:help')}
                        content={
                            <Row>
                                <Col>
                                    <TableauWorkbook
                                        url={_url}
                                        token={token}
                                        filters={filters}
                                        dataRangeType={dataRangeType}
                                    />
                                </Col>
                            </Row>
                        }
                    />
                </Col>
            </Row>
        );
    }
    return <div></div>;
};

let ResponsiveGridLayout = WidthProvider(Responsive);
let gridInitialized = false;

class MauiHome extends Component {
    constructor(prop) {
        super(prop);

        this.menuSelect = this.menuSelect.bind(this);
        this.menuClick = this.menuClick.bind(this);
        this.checkError = this.checkError.bind(this);
        getServerTimezoneDate();

        this.mainContent = React.createRef();

        this.state = {
            cards: [],
            tableauUrl: PropTypes.string,
            tableauToken: PropTypes.string,
            workbooks: [],
            session: PropTypes.boolean,
            filters: [],
            dataRangeType: "current",
            loaded: false,
            currentWorkbook: {},
            activeMenuItem: 0,
            dropdownIsOpen: false,
            cardUpdatedTime:0,
            showLoggedoutModal: false,
            sessionTerminated: false,
            setMainFocus: false
        };
    }

    checkError() {
        let redirectError =window.sessionStorage.getItem('redirectError')
        window.sessionStorage.removeItem('redirectError');
        if (redirectError&& redirectError === 'true') {
            this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, null, false));
        }
    }

    loadData() {
        API.report
            .get("/report/home")
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;
                this.setState({
                    session: true,
                    cards: data.cards,
                    tableauUrl: data.tableau.url,
                    tableauToken: data.tableau.token,
                    workbooks: data.workbooks,
                    currentWorkbook: data.workbooks[0],
                    menuItemWorkbookList: data.workbooks,
                    filter: data.reportFilter,
                    cardUpdatedTime:data.cardUpdatedTime ? this.getUpdatedTimeGap(data.cardUpdatedTime) :0,
                    loaded: true,
                    sessionTerminated: false,
                    setMainFocus: true
                });
            })
            .catch(e => {
                console.error(e);
                if(localStorage.get("accessDenied401") === "true"){
                    this.setState({showLoggedoutModal:true});
                    return;
                }
                let resultCode = -1;
                if(e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"
                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else 
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    }

    menuSelect= (key) =>{
        let temp = this.state.workbooks.filter(x=>x.id===key)[0];
        this.setState({currentWorkbook: temp});
    };

    menuClick = () => this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen });

    handleLogoutConfirm = () =>{
        this.setState({sessionTerminated: true});
    };
    
    componentDidMount() {
        this.checkError();
        this.loadData();
        localStorage.set(key.LOGIN_PROGRESS, "false");
    }

    componentWillUnmount() {
        gridInitialized = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.setMainFocus) {
            if (this.mainContent && this.mainContent.current) {
                this.mainContent.current.focus();
                this.setState({setMainFocus: false})
            }
        }
    }

    generateCards() {
      const _loaded = this.state.loaded;
      const _cards = this.state.cards;
      const trans = this.props.t;

      if(_loaded) {
        const cards = _cards.map((row, index) => {
          const fractionDigits = row.fractionDigits;
          const dataDiff = row.data - row.baseData;          
          const changeStr = dataDiff===0  ? "0" :  row.baseData===0 ? "100" : ((dataDiff / row.baseData) * 100).toFixed(1);          
          const diffStr = dataDiff===0 ? "0" : dataDiff.toFixed(fractionDigits);
          const statusMessage = changeStr + "% (" + diffStr + ")";
          const success = (row.impact ? (dataDiff >= 0) : (dataDiff <= 0));
          let link = '';
          if(row.hasParam) {
            if(row.paramUnit==='percentage'){
              link = trans('kpiCard.tooltip:value%',{value: row.cardParam});
            }
            if(row.paramUnit==='second'){
              link = trans('kpiCard.tooltip:valueSeconds',{value: row.cardParam});
            }
            if(row.paramUnit==='values'){
              link = row.cardParam;
            }
          }
          let tooltip = "";
          if(row.paramUnit==='values') {
            tooltip = trans('kpiCard.tooltip:Home tooltip - '+row.tooltip,{link: link});
          } else {
            tooltip = trans('kpiCard.tooltip:'+row.tooltip,{link: link});
          }

          let headingText =  row.data===0 ? "0" : row.data.toFixed(fractionDigits);
          if(row.dataUnit==='percentage'){
            headingText = headingText + "%";
          }
          let linkToReport = "";
          if(row.reportLink) {
              linkToReport = "/admin/"+row.reportLink.groupName.replace(/\s/g, '').toLowerCase()
                            + "/"+row.reportLink.reportName.replace(/\s/g, '').toLowerCase()
          }

          return (
            <div key={index + 1}>
              <StatCard
                key={index}
                icon={{
                  name: row.icon,
                  status: success ? "success" : "danger"
                }}
                subHeading={{
                  text: trans('kpiCard.name:'+row.title),
                  color: "#595959"
                }}
                heading={{
                  text: headingText,
                  color: "#0052cc",
                  linkPath: linkToReport,
                  cardId: row.id ? row.id : -1
                }}
                tooltip={{
                  text: tooltip,
                  key: row.title + ":" + index,
                  iconClass: "fa fa-info-circle",
                  buttonStyle: {paddingLeft: 2 + 'px'},
                  iconStyle: {fontSize: 16 + 'px', paddingRight: 10+'px'}
                }}
                stats={{
                  dataDiff: diffStr,
                  text: statusMessage
                }}
                mainRowHeight={"90px"}
              />
            </div>
          );
        });
        return cards;
      }
    }

    onLayoutChange() {
      setTimeout(function(){
        const event = document.createEvent("Event");
        event.initEvent("resize",false,true);
        window.dispatchEvent(event);
        //window.dispatchEvent(new Event('resize'))
      }, 200);
    }
    //get updated time gap for the label with localization
    getUpdatedTimeGap(updatedTimestamp){
        const trans = this.props.t;

        if(updatedTimestamp<=0)
            return trans('common:refreshingData')
        let currentTime = new Date();
        let Difference_In_Time = currentTime.getTime() - updatedTimestamp;
        let Difference_In_Minutes = Math.floor(Difference_In_Time / (1000 * 60));
        let Difference_In_Hours = Math.floor(Difference_In_Time / (1000 * 3600));
        let Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
        
        console.log("minutes:"+ Difference_In_Minutes);
        console.log("hours:"+ Difference_In_Hours);
        console.log("days:"+ Difference_In_Days);
        if(Difference_In_Minutes<60) return trans('common:updatedMinutesAgo', {value: Difference_In_Minutes});
        else if(Difference_In_Minutes>60 && Difference_In_Hours<24) return trans('common:updatedHoursAgo', {value: Difference_In_Hours});
        else if(Difference_In_Minutes>=1440 && Difference_In_Hours>=24 && Difference_In_Days>=1) return trans('common:updatedDaysAgo', {value: Difference_In_Days});
        else return trans('common:refreshingData')
    }

  render() {
        if(this.state.sessionTerminated)
            return <Redirect to={{pathname: '/auth/logout'}}/>;
      
        let showLoggedOutWindow = this.state.showLoggedoutModal;
      
        if (!this.state.loaded && !showLoggedOutWindow) {
          return null;
        }

        const t = this.props.t;
        
        let timeFrameValue = "";

        if(this.state.loaded){
          if(!gridInitialized){
            ResponsiveGridLayout = WidthProvider(Responsive);
            gridInitialized = true;
          }
          timeFrameValue = this.state.filter.currentTimeFilter;
        }

        const labelUnit = timeFrameValue > 1 ? 'days' : 'day';
        
        return (
            <div className="main-content" ref={this.mainContent} tabIndex={"-1"}>
                <Helmet>
                    <title>Knoa Analytics - Home</title>
                </Helmet>
                <ModalDialog
                    showModal={showLoggedOutWindow}
                    title={"Log Out"}
                    contentBody={
                        <span>{getInfoMessages(this.props.t, 'logoutfor401',null,null)}</span>
                    }
                    bodyPaddingBottom='15px'
                    footerBtnBsStyle="success"
                    footerBtnClass="KnoaSuccessButton"
                    footerBtnOnClick={this.handleLogoutConfirm}
                    footerBtnText={this.props.t('common:ok')}
                />
                <Row>
                    <Col className=" col-lg-6 col-sm-6">
                        <h5 className="homepage-title-mykpis knoa-region" tabIndex={"-1"}>{t('common:myKPIs')}</h5>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 homepage-title-timeframe">
                      <div className="homepage-title-timeframe-div">
                        <Row>
                            <div className="homepage-title-timeframe-container">
                            <span className="pressText">
                              {t('report.filter:lastVsPrior', {value: timeFrameValue, unit: labelUnit})}
                            </span>
                            </div>
                          <div className="homepage-title-updatedtime-container">
                           <span className="pressText">
                               {this.state.cardUpdatedTime}
                            </span>
                          </div >
 
                        </Row>
                      </div>
                    </Col>
                </Row>
              <ResponsiveGridLayout className="layout"
                                    layouts={layoutsHome}
                                    breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                                    cols={{lg: 12, md: 9, sm: 6, xs: 4, xxs: 2}}
                                    isDraggable={false}
                                    isResizable={false}
                                    allowOverlap={false}
                                    containerPadding={[15,0]}
                                    onLayoutChange={this.onLayoutChange}
              >
                {this.generateCards()}
              </ResponsiveGridLayout>
                <Grid fluid className="gridAdjustTop">
                    <Row>
                        <div className=" col-lg-12 col-sm-12">
                            <h5 className="knoa-region" tabIndex={"-1"}>{t('watchlist:myWatchlists')}</h5>
                        </div>
                    </Row>
                    {Object.keys(this.state.workbooks).length > 0 &&
                      <Row className="dropdownRow">
                          <Col lg={3} sm={6}>
                              <DropdownButton
                                  title={t('watchlist:selectReport')}
                                  key="1"
                                  id={`dropdown-basic-0`}
                                  className="dropdownButton"
                                  onToggle={this.menuClick}
                                  open={this.state.dropdownIsOpen}
                              >
                                  <GetMenuItems
                                      workbooks={this.state.workbooks}
                                      activeMenuItem={this.state.currentWorkbook}
                                      menuSelect={this.menuSelect}
                                      menuClick={this.menuClick}
                                      trans={t}
                                  />
                              </DropdownButton>
                          </Col>
                      </Row>
                    }
                    {Object.keys(this.state.workbooks).length > 0 ?
                        (
                          <SingleMauiReport
                            loaded={this.state.loaded}
                            tableauUrl={this.state.tableauUrl}
                            token={this.state.tableauToken}
                            workbook={this.state.currentWorkbook}
                            filters={this.state.filter}
                            dataRangeType={this.state.dataRangeType}
                            handleShow={this.handleShow}
                            trans={t}
                          />
                        ) : (this.state.loaded &&
                          <div className="emptyContainer">
                            <i className="fa fa-info-circle emptyContentInfoInco"></i>
                            <span className="emptyContentMessage">{t('watchlist:noWatchlists')}</span>
                            <NavLink to={{pathname: "myPreference"}}>
                              {t('route.name:My Preferences')}
                            </NavLink>
                          </div>
                        )
                    }
                </Grid>
            </div>
        );
    }
}

export default withTranslation()(MauiHome);
