import React, {Component} from "react";
import {Col} from "react-bootstrap";
import InfoCardFooter from "./InfoCardFooter";
import KnoaCardLinkRow from "./KnoaCardLinkRow";
import {NavLink} from "react-router-dom";


class LinkCard extends Component {
    render() {
        const {
            icon,
            subHeading,
            heading,
            path,
            footerText
        } = this.props;

        return (
            <>
                {path.heading && path.subHeading ? (<NavLink
                        to={{
                            pathname: `/admin/${path.subHeading.replace(/ /g, '')}/${path.heading.replace(/ /g, '')}`
                        }}
                        className="nav-link"
                        activeClassName="active"
                    >
                        <div className="card">
                            <div className="content">
                                <KnoaCardLinkRow icon={icon} subHeading={subHeading} heading={heading} path={path}/>
                                <InfoCardFooter footerText={footerText}/>
                            </div>
                        </div>
                    </NavLink>
                ) : (
                    <div className="card">
                        <div className="content">
                            <KnoaCardLinkRow icon={icon} subHeading={subHeading} heading={heading} path={path}/>
                            <InfoCardFooter footerText={footerText}/>
                        </div>
                    </div>
                )
                }
            </>
        )
    }
}

export default LinkCard;