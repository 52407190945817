import React, { Component } from "react";
import Checkbox from "../creativeTim/CustomCheckbox";
import {withTranslation} from 'react-i18next';

export class ListSelect extends Component {
  render() {
    const { data, handleAddToProjectUpdateCheckbox, t} = this.props;
    if(data && data.length > 0) {
      let tasks = [];
      for (let i = 0; i < data.length; i++) {
        tasks.push(
          <tr key={i} className="listSelect-tr">
            <td  className="listSelect-td-cb">
              <Checkbox
                number={data[i].id}
                isChecked={data[i].checked}
                handleAddToProjectUpdateCheckbox={handleAddToProjectUpdateCheckbox}
              />
            </td>
            <td  className="listSelect-td-projectnames">{data[i].name}</td>
          </tr>
        );
      }
      return <tbody className={data.length>5 ? "listSelect-body-long" : "listSelect-body-short"}>{tasks}</tbody>;
    }
    else {
      return (
        <tbody className="listSelect-span-info">
          <tr>
            <td><i className="fa fa-exclamation-circle"/>{t('dialog:addToProjectInfo')}</td>
          </tr>
        </tbody>
      );
    }
  }
}

export default withTranslation()(ListSelect);