/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";
import FooterContent from "./FooterContent";
import Button from "../creativeTim/CustomButton.jsx";


const style = {
  styleFooterLarge: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: "61px",
    borderTop: "1px solid rgba(0, 0, 0, 0.1)" 
  },
  styleFooterSmall: {
    position: "static",
    bottom: 0,
    width: "100%",
    height: "61px",
    borderTop: "1px solid rgba(0, 0, 0, 0.1)"
  }

};

const GenerateButtons = props => {
  const {buttons} = props;
  return (buttons.map((button,index) => (
    <div  key={index} className="footer-style-div">
      <Button disabled={button.disabled} key={index}  bsStyle={button.bsStyle ? button.bsStyle : "info"} fill type="submit" onClick={button.callback}>
        {button.name}
      </Button>
    </div>
  )))
};

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
        };
    }

    updateDimensions() {
        this.setState({ width: window.innerWidth });
    }

    componentDidMount() {
      this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }
    
  render() {
    const {transparent, fluid, loginpage, footerObj} = this.props;
    return (
      <footer
        className={
          "footer" +
          (transparent !== undefined ? " footer-transparent" : "")
            +" "+ (loginpage ? {} : this.state.width > 992  ? "footer-large" : "footer-small")
            +" "+ (loginpage ? {} : "knoa-region")
        }
        tabIndex={"-1"}
      >
        <div
          className={
            "container" + (fluid !== undefined ? "-fluid" : "")
          }
        >
          {loginpage ? (
            <FooterContent
              useNav={true}
              useCopyrightClass={true}
              buildVersionNewLine={false}
            />
          ) : (
            <GenerateButtons buttons={footerObj}/>
          )}
        </div>
      </footer>
    );
  }
}
export default Footer;
