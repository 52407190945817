import React, {Component} from "react";
import {Col, Row, FormGroup, ControlLabel} from "react-bootstrap";
import FormInputs from "../creativeTim/FormInputs";
import {withTranslation} from "react-i18next";

const FormErrors = ({formErrors, fieldName}) =>
    <div className='row formErrors'>
        <p className="form-validation-error-filter-parameters" aria-label={formErrors[fieldName]}> {formErrors[fieldName]}</p>
    </div>;

class ReportParametersFilters extends Component {
    constructor(props) {
        super(props);
    }

    handleAnnualFTECostInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.updateParametersValues("Annual FTE Cost", e.target.value);
        }
    };

    handleAnnualFTEHoursInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.updateParametersValues("Annual FTE hours", e.target.value);
        }
    };

    handleLTNonErrorInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.updateParametersValues("Time cost non errors", e.target.value);
        }
    };

    handleLTSysErrorInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.updateParametersValues("Time cost system errors", e.target.value);
        }
    };

    handleLTUserErrorInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.updateParametersValues("Time cost user errors", e.target.value);
        }
    };

    handleLTUncatMessageInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.updateParametersValues("Time cost uncategorized", e.target.value);
        }
    };

    handleProjectDurationInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.updateParametersValues("Projected Duration", e.target.value);
        }
    };

    handleProjectUserInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.updateParametersValues("Projected Users", e.target.value);
        }
    };


    handleTestEffortPerModuleInputChange = (e) => {
        if (e.target.type === "text") {
            this.props.updateParametersValues("Testing effort per module", e.target.value);
        }
    };

    render() {
        const t = this.props.t;
        let tableauParameters = this.props.currentReportFilters.tableauParams;
        let annualFTECost = tableauParameters.filter(param => param.name.includes("Annual FTE Cost".toUpperCase()))[0];
        let showAnnualFTECost = annualFTECost ? true : false;

        let annualFTEHours = tableauParameters.filter(param => param.name.includes("Annual FTE hours".toUpperCase()))[0];
        let showAnnualFTEHours = annualFTEHours ? true : false;

        let LTNonError = tableauParameters.filter(param => param.name.includes("Time cost non errors".toUpperCase()))[0];
        let showLTNonError = LTNonError ? true : false;

        let LTSysError = tableauParameters.filter(param => param.name.includes("Time cost system errors".toUpperCase()))[0];
        let showLTSysError = LTSysError ? true : false;

        let LTUserError = tableauParameters.filter(param => param.name.includes("Time cost user errors".toUpperCase()))[0];
        let showLTUserError = LTUserError ? true : false;

        let LTUncatMessage = tableauParameters.filter(param => param.name.includes("Time cost uncategorized".toUpperCase()))[0];
        let showLTUncatMessage = LTUncatMessage ? true : false;

        let projectDuration = tableauParameters.filter(param => param.name.includes("Projected Duration".toUpperCase()))[0];
        let showProjectDuration = projectDuration ? true : false;

        let projectUser = tableauParameters.filter(param => param.name.includes("Projected Users".toUpperCase()))[0];
        let showProjectUser = projectUser ? true : false;

        let testEffortPerModule = tableauParameters.filter(param => param.name.includes("Testing effort per module".toUpperCase()))[0];
        let showTestEffortPerModule = testEffortPerModule ? true : false;

        let parameterErrorFields = Object.keys(this.props.parameterErrors);
        let inputClass_annualFTECost = parameterErrorFields.includes("annualFTECost") ? "form-control form-validation-input-error" : "form-control form-control-filter-panel-pram";
        let inputClass_annualFTEHours = parameterErrorFields.includes("annualFTEHours") ? "form-control form-validation-input-error" : 'form-control  form-control-filter-panel-pram';
        let inputClass_LTNonError = parameterErrorFields.includes("LTNonError") ? "form-control form-validation-input-error" : 'form-control  form-control-filter-panel-pram';
        let inputClass_LTSysError = parameterErrorFields.includes("LTSysError") ? "form-control form-validation-input-error" : 'form-control  form-control-filter-panel-pram';
        let inputClass_LTUserError = parameterErrorFields.includes("LTUserError") ? "form-control form-validation-input-error" : 'form-control  form-control-filter-panel-pram';
        let inputClass_LTUncatMessage = parameterErrorFields.includes("LTUncatMessage") ? "form-control form-validation-input-error" : 'form-control  form-control-filter-panel-pram';
        let inputClass_projectDuration = parameterErrorFields.includes("projectDuration") ? "form-control form-validation-input-error" : 'form-control form-control-filter-panel-pram';
        let inputClass_projectUser = parameterErrorFields.includes("projectUser") ? "form-control form-validation-input-error" : 'form-control form-control-filter-panel-pram';
        let inputClass_testEffortPerModule = parameterErrorFields.includes("testEffortPerModule") ? "form-control form-validation-input-error" : 'form-control form-control-filter-panel-pram';

        let disabledForInstance = this.props.isInstanceReport;
        return (
            <FormGroup className="report-parameter-formgroup">
                <Row
                    className={showAnnualFTECost ? "card-row-flex-report-prams" : "card-row-flex-tabs-preferred-prams-hidden"}>
                    <Col className="card-row-col-label-report-parameters" componentClass={ControlLabel}>
                        <label htmlFor= "annualFTECost" className="col-control-sr-label">{t('preferences:annualFTECost')} <span className="text-danger-1">*</span></label>
                    </Col>
                    <Col className="card-row-col-right-parameter">
                        <FormInputs
                            ncols={["tab-parameter-input"]}
                            proprieties={[
                                {
                                    type: "text",
                                    name: "annualFTECost",
                                    bsClass: inputClass_annualFTECost,
                                    placeholder: "",
                                    value: annualFTECost ? annualFTECost.value : "",
                                    onChange: this.handleAnnualFTECostInputChange,
                                    readOnly: disabledForInstance,
                                    id: "annualFTECost",
                                    required: true,
                                }
                            ]}
                        />
                        <div className="col-md-12">
                            <FormErrors formErrors={this.props.parameterErrors} fieldName="annualFTECost"/>
                        </div>
                    </Col>
                </Row>
                <Row
                    className={showAnnualFTEHours ? "card-row-flex-report-prams" : "card-row-flex-tabs-preferred-prams-hidden"}>
                    <Col className="card-row-col-label-report-parameters" componentClass={ControlLabel}>
                        <label htmlFor= "annualFTEHours" className="col-control-sr-label">{t('preferences:annualFTEHours')} <span className="text-danger-1">*</span></label>
                    </Col>
                    <Col className="card-row-col-right-parameter">
                        <FormInputs
                            ncols={["tab-parameter-input"]}
                            proprieties={[
                                {
                                    type: "text",
                                    name: "annualFTEHours",
                                    bsClass: inputClass_annualFTEHours,
                                    placeholder: "",
                                    value: annualFTEHours ? annualFTEHours.value : "",
                                    onChange: this.handleAnnualFTEHoursInputChange,
                                    readOnly: disabledForInstance,
                                    id: "annualFTEHours",
                                    required: true,
                                }
                            ]}
                        />
                        <div className="col-md-12">
                            <FormErrors formErrors={this.props.parameterErrors} fieldName="annualFTEHours"/>
                        </div>
                    </Col>
                </Row>
                <Row
                    className={showLTNonError ? "card-row-flex-report-prams" : "card-row-flex-tabs-preferred-prams-hidden"}>
                    <Col className="card-row-col-label-report-parameters" componentClass={ControlLabel}>
                        <label htmlFor= "LTNonError" className="col-control-sr-label">{t('preferences:LTNonError')} <span className="text-danger-1">*</span></label>
                    </Col>
                    <Col className="card-row-col-right-parameter">
                        <FormInputs
                            ncols={["tab-parameter-input"]}
                            proprieties={[
                                {
                                    type: "text",
                                    name: "LTNonError",
                                    bsClass: inputClass_LTNonError,
                                    placeholder: "",
                                    value: LTNonError ? LTNonError.value : "",
                                    onChange: this.handleLTNonErrorInputChange,
                                    readOnly: disabledForInstance,
                                    id: "LTNonError",
                                    required: true,
                                }
                            ]}
                        />
                        <div className="col-md-12">
                            <FormErrors formErrors={this.props.parameterErrors} fieldName="LTNonError"/>
                        </div>
                    </Col>
                </Row>
                <Row
                    className={showLTSysError ? "card-row-flex-report-prams" : "card-row-flex-tabs-preferred-prams-hidden"}>
                    <Col className="card-row-col-label-report-parameters" componentClass={ControlLabel}>
                        <label htmlFor= "LTSysError" className="col-control-sr-label">{t('preferences:LTSysError')} <span className="text-danger-1">*</span></label>
                    </Col>
                    <Col className="card-row-col-right-parameter">
                        <FormInputs
                            ncols={["tab-parameter-input"]}
                            proprieties={[
                                {
                                    type: "text",
                                    name: "LTSysError",
                                    bsClass: inputClass_LTSysError,
                                    placeholder: "",
                                    value: LTSysError ? LTSysError.value : "",
                                    onChange: this.handleLTSysErrorInputChange,
                                    readOnly: disabledForInstance,
                                    id: "LTSysError",
                                    required: true,
                                }
                            ]}
                        />
                        <div className="col-md-12">
                            <FormErrors formErrors={this.props.parameterErrors} fieldName="LTSysError"/>
                        </div>
                    </Col>
                </Row>
                <Row
                    className={showLTUserError ? "card-row-flex-report-prams" : "card-row-flex-tabs-preferred-prams-hidden"}>
                    <Col className="card-row-col-label-report-parameters" componentClass={ControlLabel}>
                        <label htmlFor= "LTUserError" className="col-control-sr-label"> {t('preferences:LTUserError')} <span className="text-danger-1">*</span></label>
                    </Col>
                    <Col className="card-row-col-right-parameter">
                        <FormInputs
                            ncols={["tab-parameter-input"]}
                            proprieties={[
                                {
                                    type: "text",
                                    name: "LTUserError",
                                    bsClass: inputClass_LTUserError,
                                    placeholder: "",
                                    value: LTUserError ? LTUserError.value : "",
                                    onChange: this.handleLTUserErrorInputChange,
                                    readOnly: disabledForInstance,
                                    id: "LTUserError",
                                    required: true,
                                }
                            ]}
                        />
                        <div className="col-md-12">
                            <FormErrors formErrors={this.props.parameterErrors} fieldName="LTUserError"/>
                        </div>
                    </Col>
                </Row>
                <Row
                    className={showLTUncatMessage ? "card-row-flex-report-prams" : "card-row-flex-tabs-preferred-prams-hidden"}>
                    <Col className="card-row-col-label-report-parameters" componentClass={ControlLabel}>
                        <label htmlFor= "LTUncatMessage" className="col-control-sr-label"> {t('preferences:LTUncatMessage')} <span className="text-danger-1">*</span></label>
                    </Col>
                    <Col className="card-row-col-right-parameter">
                        <FormInputs
                            ncols={["tab-parameter-input"]}
                            proprieties={[
                                {
                                    type: "text",
                                    name: "LTUncatMessage",
                                    bsClass: inputClass_LTUncatMessage,
                                    placeholder: "",
                                    value: LTUncatMessage ? LTUncatMessage.value : "",
                                    onChange: this.handleLTUncatMessageInputChange,
                                    readOnly: disabledForInstance,
                                    id: "LTUncatMessage",
                                    required: true,
                                }
                            ]}
                        />
                        <div className="col-md-12">
                            <FormErrors formErrors={this.props.parameterErrors} fieldName="LTUncatMessage"/>
                        </div>
                    </Col>
                </Row>
                <Row
                    className={showProjectDuration ? "card-row-flex-report-prams" : "card-row-flex-tabs-preferred-prams-hidden"}>
                    <Col className="card-row-col-label-report-parameters" componentClass={ControlLabel}>
                        <label htmlFor= "projectDuration" className="col-control-sr-label"> {t('preferences:projectDuration')} <span className="text-danger-1">*</span></label>
                    </Col>
                    <Col className="card-row-col-right-parameter">
                        <FormInputs
                            ncols={["tab-parameter-input"]}
                            proprieties={[
                                {
                                    type: "text",
                                    name: "projectDuration",
                                    bsClass: inputClass_projectDuration,
                                    placeholder: "",
                                    value: projectDuration ? projectDuration.value : "",
                                    onChange: this.handleProjectDurationInputChange,
                                    readOnly: disabledForInstance,
                                    id: "projectDuration",
                                    required: true,
                                }
                            ]}
                        />
                        <div className="col-md-12">
                            <FormErrors formErrors={this.props.parameterErrors} fieldName="projectDuration"/>
                        </div>
                    </Col>
                </Row>
                <Row
                    className={showProjectUser ? "card-row-flex-report-prams" : "card-row-flex-tabs-preferred-prams-hidden"}>
                    <Col className="card-row-col-label-report-parameters" componentClass={ControlLabel}>
                        <label htmlFor= "projectUser" className="col-control-sr-label">{t('preferences:projectUser')} <span className="text-danger-1">*</span></label>
                    </Col>
                    <Col className="card-row-col-right-parameter">
                        <FormInputs
                            ncols={["tab-parameter-input"]}
                            proprieties={[
                                {
                                    type: "text",
                                    name: "projectUser",
                                    bsClass: inputClass_projectUser,
                                    placeholder: "",
                                    value: projectUser ? projectUser.value : "",
                                    onChange: this.handleProjectUserInputChange,
                                    readOnly: disabledForInstance,
                                    id: "projectUser",
                                    required: true,
                                }
                            ]}
                        />
                        <div className="col-md-12">
                            <FormErrors formErrors={this.props.parameterErrors} fieldName="projectUser"/>
                        </div>
                    </Col>
                </Row>
                <Row
                    className={showTestEffortPerModule ? "card-row-flex-report-prams" : "card-row-flex-tabs-preferred-prams-hidden"}>
                    <Col className="card-row-col-label-report-parameters" componentClass={ControlLabel}>
                        <label htmlFor= "testEffortPerModule" className="col-control-sr-label">{t('preferences:testEffortPerModule')} <span className="text-danger-1">*</span></label>
                    </Col>
                    <Col className="card-row-col-right-parameter">
                        <FormInputs
                            ncols={["tab-parameter-input"]}
                            proprieties={[
                                {
                                    type: "text",
                                    name: "testEffortPerModule",
                                    bsClass: inputClass_testEffortPerModule,
                                    placeholder: "",
                                    value: testEffortPerModule ? testEffortPerModule.value : "",
                                    onChange: this.handleTestEffortPerModuleInputChange,
                                    readOnly: disabledForInstance,
                                    id: "testEffortPerModule",
                                    required: true,
                                }
                            ]}
                        />
                        <div className="col-md-12">
                            <FormErrors formErrors={this.props.parameterErrors} fieldName="testEffortPerModule"/>
                        </div>
                    </Col>
                </Row>
            </FormGroup>)
    }
}

export default withTranslation()(ReportParametersFilters);