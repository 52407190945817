import React, {Component} from "react";
import NotificationSystem from "react-notification-system";
import NotificationStyles from "../../../components/common/NotificationStyles";
import {Link, Redirect} from "react-router-dom";
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl} from "react-bootstrap";
import Card from "../../../components/Card/Card";
import Button from "../../../components/creativeTim/CustomButton";
import {API} from "lib/API";
import Spinner from "../../../components/common/Spinner";
import {withTranslation} from 'react-i18next';
import {
    getErrorMessagesFromCode, getErrorMsgFromStatus,
    getGenericServerErrorMsg,
    getSpecificOPErrorMessages,
    getSuccessMessages
} from "../../../lib/messageHandler";
const localStorage = require('../../../lib/localStorage');
class twoFactorAuthentication extends Component {

    constructor(props) {
        super(props);
        this.oneTimeCodeInput = null;
        this.submitRequest = this.submitRequest.bind(this);
        this.setOneTimeCodeInputRef = element => {
            this.oneTimeCodeInput = element;
        };
        this.state = {
            oneTimeToken: null,
            accessToken: (this.props.location.state && this.props.location.state.accessToken) ? this.props.location.state.accessToken : "",
            email: (this.props.location.state && this.props.location.state.email) ? this.props.location.state.email : null, //to be used
            password: (this.props.location.state && this.props.location.state.password) ? this.props.location.state.password : null, //to be used
            homeRedirect: false,
            _notificationSystem: null,
            fetchInProgress: "false"
        }
    }


    oneTimeCodeChange = (e) => {
        this.setState({
            oneTimeToken: e.target.value
        });
    };

    updateTokens = ({accessToken, expiresIn}) => {
        API.updateTimeout({expiresIn});
        this.setState({accessToken: accessToken});
    };


    submitRequest() {
        this.setState({fetchInProgress: "true"});
        API.clearBeat();
        let objAPI = {};
        objAPI.accessToken = this.state.accessToken;
        objAPI.oneTimeToken = this.state.oneTimeToken;

        console.log("the very first time accessToken " + objAPI.accessToken);
        console.log("one time token " + objAPI.oneTimeToken);
        API.user
            .post('/session/tfa', objAPI)
            .then(resp => {
                if (resp) {
                    const {data: resData} = resp;
                    const {data} = resData;
                    this.setState({
                        accessToken: data.access});
                    this.updateTokens({
                        accessToken: data.access,
                        expiresIn: data.expires
                    });
                    
                    this.setState({homeRedirect: true})
                }else {
                    this.props.clearAlerts();
                    this.props.handleAlertClick("error", "tc",getGenericServerErrorMsg( this.props.t), 0);
                }

                this.setState({fetchInProgress: localStorage.get("isLoading")});
            })
            .catch(e => {
                console.error(e);
                this.setState({homeRedirect: false,
                    fetchInProgress: localStorage.get("isLoading")});
                if(localStorage.get("accessDenied401")=== "true"){
                    window.location.href = "/#/auth/logout"
                    return;
                }
                
                if (e.data) {
                    this.props.clearAlerts();
                    let result = e.data.result;
                    if(result !== undefined || result !== null)
                        this.props.handleAlertClick( "error", "tc", getSpecificOPErrorMessages(this.props.t, result,null, null), 0);
                    else 
                        this.props.handleAlertClick( "error", "tc",getErrorMsgFromStatus(this.props.t, e, true), 0);
                    
                }
            });
    };

    submitForm = e => {
        e.preventDefault();
        this.submitRequest();
    };

    focusOneTimeCodeInput() {
        if (this.oneTimeCodeInput) this.oneTimeCodeInput.focus();
    }

    componentDidMount() {
        this.setState({
            _notificationSystem: this.refs.notificationSystem,
        });
        this.focusOneTimeCodeInput();
    }

    handleClick = () => {
        API.clearBeat();
        API.user
            .post('/session/login', {
                email: this.state.email,
                password: this.state.password
            })
            .then(resp => {
                if (resp) {
                    const {data: resData} = resp;
                    const {data} = resData;

                    this.setState({
                        accessToken: data.access});
                    this.updateTokens({
                        accessToken: data.access,
                        refreshToken: data.refresh,
                        expiresIn: data.expires
                    });

                    this.props.clearAlerts();
                    this.props.handleAlertClick("info", "tc",getSuccessMessages(this.props.t, 'codeSentConfirmation', null), 0);
                } else {
                    this.props.clearAlerts();
                    this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t, 'codeSentFailed', null, null) , 0);
                }
            })
            .catch(e => {
                console.error(e);
                if(localStorage.get("accessDenied401")=== "true"){
                    window.location.href = "/#/auth/logout"
                    return;
                }
                this.props.clearAlerts();
         
                if(e.data.resultCode>-1)
                    this.props.handleAlertClick( "error", "tc", getErrorMessagesFromCode(this.props.t,e.data.resultCode, true), 0);
                else
                    this.props.handleAlertClick( "error", "tc", getSpecificOPErrorMessages(this.props.t,'codeRequestFailed',null, null), 0);
            });
    };

    render() {
        if (this.state.homeRedirect) {
            return <Redirect to={{pathname: '/admin/home'}}/>
        }
        

        return (
            <Grid>
                <Row>
                    <NotificationSystem ref="notificationSystem" style={NotificationStyles}/>
                </Row>
                <Row>
                    <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                        <form onSubmit={this.submitForm}>
                                {this.state.fetchInProgress === "true" && <Spinner/>}
                                <div hidden={this.state.fetchInProgress === "true"}>
                    
                                                <Card
                                                    textCenter={true}
                                                    title={this.props.t('adminSettings:tfaConfirmCardTitle')}
                                                    content={
                                                        <div>
                                                            <span className='forgotPassInfo'>Check your email and enter the code below. If you did not receive a code or your code is expired, click the "Request New Code" link to request a new one.</span>
                                                            <FormGroup>
                                                                <ControlLabel htmlFor="Code">{"Code"}</ControlLabel>
                                                                        <FormControl
                                                                            name="Code"
                                                                            id="Code"
                                                                            value={this.state.oneTimeCode}
                                                                            placeholder={"Code"}
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            inputRef={this.setOneTimeCodeInputRef}
                                                                            onChange={this.oneTimeCodeChange}
                                                                        />
                                                     
                                                            </FormGroup>

                                                        </div>
                                                    }
                                                    legend={
                                                        <>
                                                            <Button
                                                                bsStyle="info"
                                                                fill
                                                                wd
                                                                type="submit">
                                                                Verify
                                                            </Button>
                                                            <br></br>
                                                            <Link className='loginLink' onClick={this.handleClick}>Request New Code</Link>
                                                        </>
                                                    }
                                                    ftTextCenter
                                                />
                                </div>
                        </form>
                    </Col>
                </Row>
            </Grid>);

    }


}

export default withTranslation()(twoFactorAuthentication);