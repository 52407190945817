import React, { Component } from "react";
import {Row, Grid} from "react-bootstrap";
import KnoaCardToolip from "./KnoaCardTooltip";
import KnoaCardStat from "./KnoaCardStat";

class StatCardFooter extends Component {
  render() {
    const {
      tooltip,
      stats,
      iconStatus
    } = this.props;

    return(
      <div className="footer card-footer">
        <hr />
        <Grid fluid>
          <Row>
            {<KnoaCardToolip tooltip={tooltip}/>}
            {<KnoaCardStat stats={stats} iconStatus={iconStatus} />}
          </Row>
        </Grid>
      </div>
    )
  }
}

export default StatCardFooter;