import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";
import {Grid,Row,Col,FormGroup,ControlLabel,FormControl} from "react-bootstrap";
import Card from "../../../components/Card/Card";
import Button from "../../../components/creativeTim/CustomButton";
import PropTypes from "prop-types";
import {API} from "lib/API";
import {Helmet} from "react-helmet";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.emailInput = null;

    this.isValidated = this.isValidated.bind(this);
    this.submitClick = this.submitClick.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitRequest = this.submitRequest.bind(this);

    this.setEmailInputRef = element => {
      this.emailInput = element;
    };

    this.focusEmailInput = () => {
      // Focus the text input using the raw DOM API
      if (this.emailInput) {
        this.emailInput.focus();
      }
    };

    this.state = {
      email: PropTypes.string,
      emailError: null,
      submitted: false
    }
  }

  verifyEmail = value => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  };

  change = (event, stateName, type) => {
    let state;
    let error;

    if (this.verifyEmail(event.target.value)) {
      state = 'valid';
      error = null;
    } else {
      state = 'invalid';
      error = (<small className="text-danger">
          Email format should be <i>john@doe.com</i>.
        </small>);
      this.focusEmailInput();
    }

    this.setState({
      [stateName + "State"]: state,
      emailError: error,
      [stateName]: event.target.value
    });
  };

  submitClick = () => {
    if (this.state.emailState !== "valid") {
      this.setState({
        emailState: "invalid",
        emailError: (
          <small className="text-danger">
            Email format should be <i>user@knoa.com</i>.
          </small>
        )
      });
      this.focusEmailInput();
    }
  };

  isValidated = () => {
    if (this.state.emailState==="valid") {
      return true;
    } else {
      this.setState({emailState: "invalid"});
      return false;
    }
  };

  submitRequest() {
    API.user
      .post('/session/forgotPassword', {
        email: this.state.email
      })
      .then(resp => {
        if(resp) {
          const {data: resData} = resp;
          const {data} = resData;

          this.setState({
            emailError: null,
            submitted: true
          });
        }
      })
      .catch(e => {
        console.error(e);
      });
  }

  submitForm = e => {
    e.preventDefault();
    this.submitClick();
    if (!this.isValidated()) return;
    this.submitRequest();
  };

  render() {
    return (
      <Grid>
        <Helmet>
          <title>Knoa Analytics - Forgot Password</title>
        </Helmet>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            {!this.state.submitted ? (
              <form onSubmit={this.submitForm}>
                <Card
                  textCenter
                  title="Forgot Password"
                  content={
                    <div>
                      <span className='forgotPassInfo'>To begin changing your password, please enter your username.</span>
                      <FormGroup>
                        <ControlLabel htmlFor="Username">Username</ControlLabel>
                        <FormControl
                            name="Username"
                            id="Username"
                          placeholder="email"
                          type="email"
                          onChange={e => this.change(e, "email", "email")}
                          onFocus={() => this.setState({focusedEmail: true})}
                          onBlur={() => this.setState({focusedEmail: false})}
                          inputRef={this.setEmailInputRef}
                            aria-required="true"
                        />
                        {this.state.emailError}
                      </FormGroup>
                    </div>
                  }
                  legend={
                    <>
                      <Button
                        bsStyle="info"
                        fill
                        wd
                        type="submit">
                        Submit
                      </Button>
                      <br></br>
                      <Link className='loginLink' to={{pathname: "/auth/login"}}>Cancel</Link>
                    </>
                  }
                  ftTextCenter
                />
              </form>
              ) : (
              <Card
                textCenter
                title="Forgot Password"
                content={
                  <div className='forgotPassContentDiv'>
                    <div className='forgotPassIconDiv'><i className='pe-7s-mail'/></div>
                      <span>
                        If your email address is registered, you will receive a password recovery link at your email address within the next few minutes. It contains instructions for changing your password.
                      </span>
                  </div>
                }
                legend={
                  <>
                    <br></br>
                    <Link className='loginLink' to={{pathname: "/auth/login"}}>Return to login</Link>
                  </>
                }
                ftTextCenter
                custom='forgotPassSubmit'
              />
            )
            }
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default ForgotPassword;