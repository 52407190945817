const CustomStyles = {

    control: provided => {
        //console.log("--Provided internals", provided); // let's console provided and return object as before
        return {
            ...provided,
            backgroundColor: "white",
            minHeight: 'fit-content',
            height: 'fit-content'
        };
    },

    container: (provided) =>{
    return {
        ...provided,
        backgroundColor: "white",
        height: 'fit-content',
    };
},

    clearIndicator: (provided) => {
        //console.log("--Provided clear indicator", provided);
        return {
            ...provided,
     };
    },

    indicatorsContainer: (provided) => {
        //console.log("--Provided  indicatorsContainer", provided);
        return {
            ...provided,
            // color: "white !important",
            height: '20px',
            
        };
    },
    placeholder: (provided) => {
        //console.log("--Provided  placeholder", provided);
        return {
            ...provided,
            color: "black",
            fontSize: "12px"
        };
    },
    indicatorSeparator: (provided) => {
        //console.log("--Provided  indicatorSeparator", provided);
        return {
            ...provided,
            marginTop:'4px',
            height: '12px'
        };
    },


    multiValueLabel: (provided) => {
        //console.log("--Provided multiValueLabel", provided);
        return {
            ...provided,
           // color: "white !important",
            fontSize: "12px",
            borderRadius:"0px",
            backgroundColor: "",

        };
    } ,

    singleValue: (provided) => {
        //console.log("--Provided multiValue", provided);
        return {
            ...provided,
           // color: "white !important",
            fontSize: "12px",
            borderRadius:"0px",
            backgroundColor: "",


        };
    },

    multiValue: (provided) => {
        //console.log("--Provided multiValue", provided);
        return {
            ...provided,
            backgroundColor: "",


        };
    },

    multiValueRemove: (provided) => {
        //console.log("--Provided multiValueRemove", provided);
        return {
            ...provided,
            color: "black",

        };
    },

 
    input: (provided, state) => ({
        ...provided,
        height: '12px'
    }),
    option: (provided, state) => ({
        ...provided,
        fontfamily: 'font-family: Arial, Helvetica,sans-serif !important',
        fontSize: '12px'
    }),
};

export default CustomStyles;