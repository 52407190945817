import React, {Component} from "react";
import StepZilla from "react-stepzilla";
import {Grid, Row, Col} from "react-bootstrap";
import Card from "../../../components/Card/Card";
import KnoaBreadcrumb from "../../../components/common/KnoaBreadcrumb";
import ProjectStep1 from "./ProjectStep1";
import ProjectStep2 from "./ProjectStep2";
import ProjectStep3 from "./ProjectStep3";
import {API} from "../../../lib/API";
import Footer from "../../../components/layout/Footer";
import {Redirect} from "react-router-dom";
import moment from "moment";
import CommonContainer from "../../../components/common/CommonContainer";
import {withTranslation} from 'react-i18next';
import {calculateStartDate, getServerTimezoneDate} from "../../../lib/common";
import Spinner from "../../../components/common/Spinner";
import ModalDialog from "../../../components/common/ModalDialog";
import {
    getErrorMessagesFromCode,
    getErrorMsgFromStatus,
    getInfoMessages,
    getSpecificOPErrorMessages,
    getSuccessMessages
} from "../../../lib/messageHandler";
import {Helmet} from "react-helmet";

const localStorage = require('../../../lib/localStorage');

class ProjectWizard extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.generateSelectOptions = this.generateSelectOptions.bind(this);
        this.handleProjectNameUpdate = this.handleProjectNameUpdate.bind(this);
        this.handleProjectTeamUpdate = this.handleProjectTeamUpdate.bind(this);
        this.handleUserAttributesUpdate = this.handleUserAttributesUpdate.bind(this);
        this.handleTimeFilterUpdate = this.handleTimeFilterUpdate.bind(this);
        this.handleProjectReportsUpdate = this.handleProjectReportsUpdate.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.getPartOfDate = this.getPartOfDate.bind(this);
        this.updateVisible = this.updateVisible.bind(this);
        this.validateTimeframe = this.validateTimeframe.bind(this);
        this.resetSelectAllCheckStatus = this.resetSelectAllCheckStatus.bind(this);

        this.state = {
            projectName: "",
            projectTeam: [],
            application: "",
            userOptions: [],
            applicationOptions: [],
            applicationSelected: [],
            moduleOptions: [],
            moduleSelected: [],
            //screenGroupOptions: [],
            //screenGroupSelected: [],
            userAttributeFilters: [],
            timeFilter: {},
            workbooks: {},
            createdProjectId: -1,
            createdProjectName: "",
            formValid: false,
            preselectedReportID: this.props.location.state ? this.props.location.state.preSelectReportID : -1,
            updatedProjectId: this.props.location.state ? this.props.location.state.id : -1,
            projectNameError: null,
            absoluteStartErrorCurrent: null,
            absoluteEndErrorCurrent: null,
            absoluteStartErrorHistorical: null,
            absoluteEndErrorHistorical: null,
            redirect: false,
            fetchInProgress: "false",
            isSelectAllChecked: false,
            annualFTECost: {},
            annualFTEHours: {},
            LTNonError: {},
            LTSysError: {},
            LTUserError: {},
            LTUncatMessage: {},
            projectDuration: {},
            projectUser: {},
            testEffortPerModule: {},
            formErrors: {},
            inputClass_annualFTECost: 'form-control form-control-border-preferedpram',
            inputClass_annualFTEHours: 'form-control  form-control-border-preferedpram',
            inputClass_LTNonError: 'form-control  form-control-border-preferedpram',
            inputClass_LTSysError: 'form-control  form-control-border-preferedpram',
            inputClass_LTUserError: 'form-control  form-control-border-preferedpram',
            inputClass_LTUncatMessage: 'form-control  form-control-border-preferedpram',
            inputClass_projectDuration: 'form-control form-control-border-preferedpram',
            inputClass_projectUser: 'form-control form-control-border-preferedpram',
            inputClass_testEffortPerModule: 'form-control form-control-border-preferedpram',
            showLoggedoutModal: false,
            sessionTerminated: false
        }
    };

    formatDate(date) {
        if (date)
            return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
        else
            return ""
    }

    getPartOfDate(date, part) {
        if (part === "month") {
            return date.getMonth();
        } else if (part === "day") {
            return date.getDate();
        } else if (part === "year") {
            return date.getFullYear();
        } else return date;
    }

    updateVisible(elementId, value) {
        const element = document.getElementById(elementId);
        if (element) {
            element.style.visibility = value;
        }
    }

    handleLogoutConfirm = () => {
        this.setState({sessionTerminated: true});
    };


    handleSubmit() {
        let itemsToSubmit = {};
        let _this = this;
        let newProject = this.props.location.state.newProject;
        let projectURL = "";

        if (newProject) {
            projectURL = '/project/create';
        } else {
            projectURL = `/project/edit/${this.state.updatedProjectId}`
        }

        //applicationValueFilter
        itemsToSubmit.applicationValueFilter = this.state.applicationSelected.filter(x => x.selected === true).map(y => y.value);

        let timeFilterValue = "";
        //1 - ABSOLUTE - "mm/dd/yyyy-mm/dd/yyyy"
        //2 - BENCHMARK - "mm/dd/yyyy:<digit>" NO LONGER USED (MAUI-466)
        //3 - RELATIVE - "<digit>"

        //currentTimeFilter
        itemsToSubmit.currentTimeFilter = {};
        itemsToSubmit.currentTimeFilter.dataRange = "CURRENT";
        itemsToSubmit.currentTimeFilter.timeFilterId = this.state.timeFilter.current.selectedTimePeriodId;
        if (itemsToSubmit.currentTimeFilter.timeFilterId === 1) {
            timeFilterValue = this.formatDate(this.state.timeFilter.current.absoluteStartDate) + "-" + this.formatDate(this.state.timeFilter.current.EndDate);
        } else if (itemsToSubmit.currentTimeFilter.timeFilterId === 3) {
            timeFilterValue = this.state.timeFilter.current.relativeNew;
        }
        itemsToSubmit.currentTimeFilter.timeFilterValue = timeFilterValue.toString();

        //historicalTimeFilter
        itemsToSubmit.historicalTimeFilter = {};
        itemsToSubmit.historicalTimeFilter.dataRange = "HISTORICAL";
        itemsToSubmit.historicalTimeFilter.timeFilterId = this.state.timeFilter.historical.selectedTimePeriodId;
        timeFilterValue = "";
        if (itemsToSubmit.historicalTimeFilter.timeFilterId === 1) {
            timeFilterValue = this.formatDate(this.state.timeFilter.historical.absoluteStartDate) + "-" + this.formatDate(this.state.timeFilter.historical.EndDate);
        } else if (itemsToSubmit.historicalTimeFilter.timeFilterId === 3) {
            timeFilterValue = this.state.timeFilter.historical.relativeNew;
        }
        itemsToSubmit.historicalTimeFilter.timeFilterValue = timeFilterValue.toString();

        //moduleValueFilters
        itemsToSubmit.moduleValueFilters = this.state.moduleSelected.filter(x => x.selected === true).map(y => y.value);

        //name
        itemsToSubmit.name = this.state.projectName;

        //reports
        itemsToSubmit.reports = this.state.workbooks.currentSelectedProjects.concat(this.state.workbooks.historicalSelectedProjects);

        //team
        itemsToSubmit.team = this.state.projectTeam;

        //userAttributes
        let uaParam = [];
        let originaUAFilterValue = this.state.userAttributeFilters;
        originaUAFilterValue.forEach(att => {
            att.options.filter(v => v.selected === true).map(v => v.id).forEach(vv => uaParam.push(vv))
        });
        itemsToSubmit.userAttributes = uaParam;

        //itemsToSubmit.screenGroupFilter = this.state.screenGroupSelected.filter(x => x.selected === true).map(y => y.id);

        let tempTableauParameters = [];
        let latestannualFTECost = {};
        latestannualFTECost.name = this.state.annualFTECost.name;
        latestannualFTECost.value = this.state.annualFTECost.value;
        tempTableauParameters.push(latestannualFTECost);

        let latestannualFTEHours = {};
        latestannualFTEHours.name = this.state.annualFTEHours.name;
        latestannualFTEHours.value = this.state.annualFTEHours.value;
        tempTableauParameters.push(latestannualFTEHours);

        let latestLTNonError = {};
        latestLTNonError.name = this.state.LTNonError.name;
        latestLTNonError.value = this.state.LTNonError.value;
        tempTableauParameters.push(latestLTNonError);

        let latestLTSysError = {};
        latestLTSysError.name = this.state.LTSysError.name;
        latestLTSysError.value = this.state.LTSysError.value;
        tempTableauParameters.push(latestLTSysError);

        let latestLTUserError = {};
        latestLTUserError.name = this.state.LTUserError.name;
        latestLTUserError.value = this.state.LTUserError.value;
        tempTableauParameters.push(latestLTUserError);

        let latestLTUncatMessage = {};
        latestLTUncatMessage.name = this.state.LTUncatMessage.name;
        latestLTUncatMessage.value = this.state.LTUncatMessage.value;
        tempTableauParameters.push(latestLTUncatMessage);

        let latestprojectDuration = {};
        latestprojectDuration.name = this.state.projectDuration.name;
        latestprojectDuration.value = this.state.projectDuration.value;
        tempTableauParameters.push(latestprojectDuration);

        let latestprojectUser = {};
        latestprojectUser.name = this.state.projectUser.name;
        latestprojectUser.value = this.state.projectUser.value;
        tempTableauParameters.push(latestprojectUser);

        let latesttestEffortPerModule = {};
        latesttestEffortPerModule.name = this.state.testEffortPerModule.name;
        latesttestEffortPerModule.value = this.state.testEffortPerModule.value;
        tempTableauParameters.push(latesttestEffortPerModule);

        itemsToSubmit.tableauParameters = tempTableauParameters;

        this.setState({fetchInProgress: "true"});

        API.project
            .post(projectURL, itemsToSubmit)
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;

                let inSupport = resData ? resData.inSupport : false;

                if (newProject) {
                    console.log(`Project with id ${data.projectId} is created! \n` +
                        JSON.stringify(itemsToSubmit)
                    );
                    _this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t, 'changeSaved', 'Project'), inSupport);
                    _this.setState({
                        createdProjectId: data.projectId,
                        createdProjectName: itemsToSubmit.name,
                        formValid: true,
                        projectNameError: null,
                        absoluteStartErrorCurrent: null,
                        absoluteEndErrorCurrent: null,
                        absoluteStartErrorHistorical: null,
                        absoluteEndErrorHistorical: null,
                        fetchInProgress: localStorage.get("isLoading")
                    });
                } else {
                    //Reset filters for this project reports
                    window.sessionStorage.removeItem('reportProject' + _this.state.updatedProjectId);

                    console.log(`Project with id ${_this.state.updatedProjectId} is updated! \n` +
                        JSON.stringify(itemsToSubmit)
                    );
                    _this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t, 'changeSaved', 'Project'), inSupport);
                    _this.setState({
                        formValid: true,
                        projectNameError: null,
                        absoluteStartErrorCurrent: null,
                        absoluteEndErrorCurrent: null,
                        absoluteStartErrorHistorical: null,
                        absoluteEndErrorHistorical: null,
                        fetchInProgress: localStorage.get("isLoading")
                    });
                }
            })
            .catch(e => {
                console.error(e);
                this.setState({fetchInProgress: localStorage.get("isLoading")});
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }

                let resultCode = -1;
                let resultData = "";

                if (e.data) {
                    const {data: resData} = e;
                    const {data} = resData;
                    resultCode = data.resultCode;
                    resultData = data.data;
                }

                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"

                if (resultData === "PROJECT_EXISTS_WITH_SAME_NAME") {
                    this.setState({
                        projectNameError: (
                            <small className="text-danger">
                                A project with this name already exists
                            </small>
                        ),
                        formValid: false
                    });
                    this.updateVisible("errorTab1", "visible");
                    this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t, 'projectNameIssue', 'Project', null));
                } else if (resultCode > -1) {
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                } else {
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
                }

            });
    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.setState({redirect: true});
            return;
        }

        //Create and hide error symbol in wizard tabs
        const tabList = document.getElementsByClassName("progtrckr")[0];
        const tab1 = tabList.children[0];
        const textTab1 = tab1.children[1];
        const tab2 = tabList.children[1];
        const textTab2 = tab2.children[1];
        const tab3 = tabList.children[2];
        const textTab3 = tab3.children[1];

        tab1.tabIndex = 0;
        tab2.tabIndex = 0;
        tab3.tabIndex = 0;

        tab1.addEventListener("keydown",function(e) {
            if (e.keyCode === 13) {
                e.srcElement.click();
            }
        });
        tab2.addEventListener("keydown",function(e) {
            if (e.keyCode === 13) {
                e.srcElement.click();
            }
        });
        tab3.addEventListener("keydown",function(e) {
            if (e.keyCode === 13) {
                e.srcElement.click();
            }
        });

        const spanElement1 = document.createElement("SPAN");
        spanElement1.className = "text-danger";
        spanElement1.innerText = " *";
        spanElement1.id = "errorTab1";
        const spanElement2 = document.createElement("SPAN");
        spanElement2.className = "text-danger";
        spanElement2.innerText = " *";
        spanElement2.id = "errorTab2";
        const spanElement3 = document.createElement("SPAN");
        spanElement3.className = "text-danger";
        spanElement3.innerText = " *";
        spanElement3.id = "errorTab3";
        textTab1.appendChild(spanElement1);
        textTab2.appendChild(spanElement2);
        textTab3.appendChild(spanElement3);
        this.updateVisible(spanElement1.id, "hidden");
        this.updateVisible(spanElement2.id, "hidden");
        this.updateVisible(spanElement3.id, "hidden");

        let _this = this;
        let projectURL = "";
        let newProject = this.props.location.state.newProject;

        //New Project
        if (newProject) {
            projectURL = "/project/v2/view/create";

            if (this.state.preselectedReportID >= 0) {
                projectURL = projectURL + "/" + this.state.preselectedReportID;
                console.log("Pre-selected report ID: " + this.state.preselectedReportID);
            }
        }
        //Edit Project
        else {
            projectURL = `/project/v2/view/edit/${this.state.updatedProjectId}`
        }

        API.project
            .get(projectURL)
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;

                let projectName = "";
                if (!newProject) {
                    //Project Name
                    projectName = data.name;
                }

                //Project Team dropdown
                const userOptions = _this.generateSelectOptions(data.users, "userName", "userId");
                let team = [];
                if (!newProject) {
                    team = data.team.map(user => {
                        return user.userId.toString();
                    });
                }

                //Time filters
                const timeFilter = {};
                timeFilter.current = {};
                timeFilter.historical = {};
                //selected timeframe option - current
                timeFilter.current.selectedTimePeriod = data.currentAnalysis.timeFilters.timeFilterOptions.filter(item => item.selected)[0].timePeriod;
                timeFilter.current.selectedTimePeriodId = data.currentAnalysis.timeFilters.timeFilterOptions.filter(item => item.selected)[0].id;
                timeFilter.current.timePeriodInfo = data.currentAnalysis.timeFilters.timeFilterOptions.map(item => {
                    return ({
                        "id": item.id,
                        "name": item.timePeriod
                    })
                });
                //selected timeframe option - current
                timeFilter.historical.selectedTimePeriod = data.historicalAnalysis.timeFilters.timeFilterOptions.filter(item => item.selected)[0].timePeriod;
                timeFilter.historical.selectedTimePeriodId = data.historicalAnalysis.timeFilters.timeFilterOptions.filter(item => item.selected)[0].id;
                timeFilter.historical.timePeriodInfo = data.historicalAnalysis.timeFilters.timeFilterOptions.map(item => {
                    return ({
                        "id": item.id,
                        "name": item.timePeriod
                    })
                });
                //limit
                timeFilter.current.limit = data.currentAnalysis.timeFilters.timeRangeLimit;
                timeFilter.historical.limit = data.historicalAnalysis.timeFilters.timeRangeLimit;

                //relative from preferences and updated
                const currentRelativePref = data.currentAnalysis.timeFilters.defaultTimeRangeValue;
                const historicalRelativePref = data.historicalAnalysis.timeFilters.defaultTimeRangeValue;
                if (!newProject) {
                    if (timeFilter.current.selectedTimePeriod === "RELATIVE") {
                        timeFilter.current.relativeNew = data.currentTimeFilter.timeFilterValue;
                    } else {
                        timeFilter.current.relativeNew = currentRelativePref;
                    }

                    if (timeFilter.historical.selectedTimePeriod === "RELATIVE") {
                        timeFilter.historical.relativeNew = data.historicalTimeFilter.timeFilterValue;
                    } else {
                        timeFilter.historical.relativeNew = historicalRelativePref;
                    }
                } else {
                    timeFilter.current.relativeNew = currentRelativePref;
                    timeFilter.historical.relativeNew = historicalRelativePref;
                }

                //date calculation - current start/end (including today)
                if (newProject || timeFilter.current.selectedTimePeriod === "RELATIVE") {
                    timeFilter.current.absoluteStartDate = calculateStartDate("CURRENT", currentRelativePref, null);
                    timeFilter.current.EndDate = getServerTimezoneDate();
                } else if (timeFilter.current.selectedTimePeriod === "ABSOLUTE") {
                    let absoluteDates = data.currentTimeFilter.timeFilterValue.split('-');
                    timeFilter.current.absoluteStartDate = new Date(absoluteDates[0]);
                    timeFilter.current.EndDate = new Date(absoluteDates[1]);
                }
                timeFilter.current.absoluteCalendarStart = calculateStartDate("CURRENT", timeFilter.current.limit, null);

                //date calculation - historical start/end
                if (newProject || timeFilter.historical.selectedTimePeriod === "RELATIVE") {
                    timeFilter.historical.absoluteStartDate = calculateStartDate("HISTORICAL", historicalRelativePref, null);
                    timeFilter.historical.EndDate = getServerTimezoneDate();
                } else if (timeFilter.historical.selectedTimePeriod === "ABSOLUTE") {
                    let absoluteDates = data.historicalTimeFilter.timeFilterValue.split('-');
                    timeFilter.historical.absoluteStartDate = new Date(absoluteDates[0]);
                    timeFilter.historical.EndDate = new Date(absoluteDates[1]);
                }
                timeFilter.historical.absoluteCalendarStart = calculateStartDate("HISTORICAL", timeFilter.historical.limit, null);

                //Report information
                const workbooks = {};
                workbooks.currentReportArray = data.currentAnalysis.reportCategories.map(key => {
                    return {
                        type: key.categoryName,
                        data: key.workbooks,
                        icon: key.icon
                    }
                });
                workbooks.historicalReportArray = data.historicalAnalysis.reportCategories.map(key => {
                    return {
                        type: key.categoryName,
                        data: key.workbooks,
                        icon: key.icon
                    }
                });

                workbooks.currentSelectedProjects = [];
                workbooks.currentReportArray.map(report => {
                    report.data.map(type => {
                        if (type.id === this.state.preselectedReportID || type.selected === true) {
                            type.checked = true;
                            workbooks.currentSelectedProjects.push(type.id);
                        } else {
                            type.checked = false;
                        }
                    });
                });
                workbooks.historicalSelectedProjects = [];
                workbooks.historicalReportArray.map(report => {
                    report.data.map(type => {
                        if (type.id === this.state.preselectedReportID || type.selected === true) {
                            type.checked = true;
                            workbooks.historicalSelectedProjects.push(type.id);
                        } else {
                            type.checked = false;
                        }
                    });
                });

                //add label to filter options
                Object.entries(data.contextFilterOptions).forEach(filter => {
                    if (filter[0] === 'applicationFilters' || filter[0] === 'moduleFilters') { //|| filter[0]==='screenGroupFilters'
                        if (filter[1] && filter[1].length > 0) {
                            filter[1].map(option => {
                                option.label = option.value;
                            });
                        }
                    }
                });

                _this.setState({
                    projectName: projectName,
                    projectTeam: team,
                    userOptions: userOptions,
                    applicationOptions: data.contextFilterOptions.applicationFilters,
                    applicationSelected: data.contextFilterOptions.applicationFilters,
                    ModuleOptions: data.contextFilterOptions.moduleFilters,
                    moduleSelected: data.contextFilterOptions.moduleFilters,
                    //screenGroupOptions: data.contextFilterOptions.screenGroupFilters,
                    //screenGroupSelected: data.contextFilterOptions.screenGroupFilters,
                    userAttributeFilters: data.contextFilterOptions.userAttributes,
                    timeFilter: timeFilter,
                    workbooks: workbooks,
                    annualFTECost: data.tableauParams.filter(param => param.name === "Annual FTE Cost (currency)")[0],
                    annualFTEHours: data.tableauParams.filter(param => param.name === "Annual FTE hours")[0],
                    LTNonError: data.tableauParams.filter(param => param.name === "Lost time due to non errors (seconds)")[0],
                    LTSysError: data.tableauParams.filter(param => param.name === "Lost time due to system errors (seconds)")[0],
                    LTUserError: data.tableauParams.filter(param => param.name === "Lost time due to user errors (seconds)")[0],
                    LTUncatMessage: data.tableauParams.filter(param => param.name === "Lost Time Due to Uncategorized Messages (seconds)")[0],
                    projectDuration: data.tableauParams.filter(param => param.name === "Projected Duration (years)")[0],
                    projectUser: data.tableauParams.filter(param => param.name === "Projected Users")[0],
                    testEffortPerModule: data.tableauParams.filter(param => param.name === "Testing effort per module (hours)")[0],
                });
            })
            .catch(e => {
                // only log error for now
                console.error(e);
                _this.setState({redirect: true});
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }
                let resultCode = -1;
                if (e.data) {
                    resultCode = e.data.resultCode;
                }
                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    }

    generateSelectOptions(data, _label, _value) {
        return data.map(key => {
            return {
                id: key.userId,
                label: key[_label],
                value: key[_value]===undefined? "": key[_value].toString(),
                checked: key.selected ? key.selected : false,
                // options: key.options ? key.options : undefined  //remove for react-select latest version wont accept unuseful undefined field here
            }
        });
    }

    handleProjectNameUpdate(input) {
        this.setState({
            projectName: input
        });
    }

    handleProjectTeamUpdate(input) {
        this.setState({
            projectTeam: input
        });
    }

    handleApplicationsOnChange = (event) => {
        let selectedOptionsLength = event.length;
        let selectedApplications = this.state.applicationSelected;
        if (selectedOptionsLength > selectedApplications.length) {
            let newOptions = {
                id: event[selectedOptionsLength - 1].id,
                value: event[selectedOptionsLength - 1].value,
                label: event[selectedOptionsLength - 1].label,
                selected: true
            };
            selectedApplications.push(newOptions);
        } else {
            selectedApplications = event;
        }

        this.setState({applicationSelected: selectedApplications});
    };

    handleApplicationsInputChange = (event) => {
        if (event === "") return;
        // passing 0 as workbook id to indicates that this request is not report specific
        API.report
            .post('/report/list/application/0?top=100', {
                word: event
            })
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;

                let latestOptions = data.results;

                //add label
                latestOptions.map(item => {
                    item.label = item.value;
                });

                this.setState({applicationOptions: latestOptions});
            })
            .catch(e => {
                console.error(e);
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }
                let resultCode = -1;
                if (e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"

                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    };

    handleModulesOnChange = (event) => {
        let selectedOptionsLength = event.length;
        let selectedModules = this.state.moduleSelected;
        if (selectedOptionsLength > selectedModules.length) {
            let newOptions = {
                id: event[selectedOptionsLength - 1].id,
                value: event[selectedOptionsLength - 1].value,
                label: event[selectedOptionsLength - 1].label,
                selected: true
            };
            selectedModules.push(newOptions);
        } else {
            selectedModules = event;
        }

        this.setState({moduleSelected: selectedModules});
    };

    handleModulesInputChange = (event) => {
        if (event === "") return;
        // passing 0 as workbook id to indicates that this request is not report specific
        API.report
            .post('/report/list/module/0?top=100', {
                word: event
            })
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;

                let latestOptions = data.results;

                //add label
                latestOptions.map(item => {
                    item.label = item.value;
                });

                this.setState({moduleOptions: latestOptions});
            })
            .catch(e => {
                console.error(e);
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }
                let resultCode = -1;
                if (e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"

                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    };

    /*handleScreenGroupsOnChange = (event) => {
        let selectedOptionsLength = event.length;
        let selectedScreenGroups = this.state.screenGroupSelected;
        if (selectedOptionsLength > selectedScreenGroups.length) {
            let newOptions = {
                id: event[selectedOptionsLength - 1].id,
                value: event[selectedOptionsLength - 1].value,
                label: event[selectedOptionsLength - 1].label,
                selected: true
            };
            selectedScreenGroups.push(newOptions);
        } else {
            selectedScreenGroups = event;
        }

        this.setState({screenGroupSelected: selectedScreenGroups});
    };

    handleScreenGroupsInputChange = (event) => {
        if (event==="") return;
        API.report
          .post('/report/list/screenGroup?top=100', {
              word: event
          })
          .then(resp => {
              const {data: resData} = resp;
              const {data} = resData;

              let latestOptions = data.results;

              //add label
              latestOptions.map(item => {
                  item.label = item.value;
              });

              this.setState({screenGroupOptions: latestOptions});
          })
          .catch(e => {
              console.error(e);
              let resultCode = -1;
              if (e.data) {
                  resultCode = e.data.resultCode;
              }
              if (e.status === 401 || resultCode === 6007)
                  window.location.href = "/#/auth/logout"

              if(resultCode > -1)
                  this.props.handleAlertClick("error", "tc", this.props.t('messages.error:generic', {item: "Failed to communicate with the server."}) + " Error code: " + resultCode);
              else
                  this.props.handleAlertClick("error", "tc", this.props.t('messages.error:generic', {item: "Failed to communicate with the server."}));
          });
    };*/

    handleUserAttributesUpdate(selectedOptions, id) {
        console.log("parent handle was called for attribute ID: " + id);
        // let optIDs = [];
        // selectedOptions.forEach(opt => {
        //     optIDs.push(opt.id);
        // });
        // let tempUSAtts = [];
        // this.state.userAttributeFilters.forEach(ua => {
        //     if (ua.id === id)
        //         ua.options.forEach(opt => {
        //                 if (optIDs.length === 0) {
        //                     opt.selected = false;     //for delete all or only one selection
        //                 } else if (optIDs.length === 1) {
        //                     if (opt.id === optIDs[0] && opt.selected === false) opt.selected = true; //for choose the first option ever
        //                     else if (opt.id !== optIDs[0] && opt.selected === true) opt.selected = false;  // for delete one from two selected options
        //                 } else {
        //                     if ((optIDs.includes(opt.id))) {
        //                         if (!opt.selected) opt.selected = true; // for adding new options after first
        //                     } else {
        //                         opt.selected = false;//for delete one from more than two selection
        //                     }
        //                 }
        //             }
        //         );
        //
        //     tempUSAtts.push(ua);
        // });
        // this.setState({userAttributeFilters: tempUSAtts});
        let selectedListLength = selectedOptions.length;
        let currentUAObj = this.state.userAttributeFilters.filter(ua => ua.id === id);
        let currentUAOptions = currentUAObj[0].options;
        let originalOptions = (currentUAOptions === null || currentUAOptions === undefined || currentUAObj.length === 0) ? [] : currentUAOptions.filter(x => x.selected === true);

        if (selectedListLength > originalOptions.length) {
            let newlySelected = {
                id: selectedOptions[selectedListLength - 1].id,
                value: selectedOptions[selectedListLength - 1].value,
                selected: true
            };
            if (originalOptions.filter(x => x.id === selectedOptions[selectedListLength - 1].id).length > 0) {
                originalOptions.map(op => op.id === selectedOptions[selectedListLength - 1].id ? newlySelected : op)
            } else
                originalOptions.push(newlySelected);
        } else {
            originalOptions = selectedOptions;
        }

        let tempAllUAs = this.state.userAttributeFilters;
        tempAllUAs.forEach(ua => {
            if (ua.id === id) ua.options = originalOptions
        });
        this.setState({userAttributeFilters: tempAllUAs});
    }

    handleTimeFilterUpdate(type, dataRange, value) {
        const timeFilter = this.state.timeFilter;

        if (type === "radioTimeframeOptions") {
            if (dataRange === "Current") {
                timeFilter.current.selectedTimePeriod = value;
                timeFilter.current.selectedTimePeriodId = timeFilter.current.timePeriodInfo.filter(item => item.name === value)[0].id;
            } else if (dataRange === "Historical") {
                timeFilter.historical.selectedTimePeriod = value;
                timeFilter.historical.selectedTimePeriodId = timeFilter.historical.timePeriodInfo.filter(item => item.name === value)[0].id;
            }
        } else if (type === "relativeSlider") {
            if (dataRange === "Current") {
                timeFilter.current.relativeNew = value;
            } else if (dataRange === "Historical") {
                timeFilter.historical.relativeNew = value;
            }
        } else if (type === "absoluteStartDate") {
            if (typeof (value) === "string") {
                if (dataRange === "Current") {
                    timeFilter.current.absoluteStartDate = value;
                } else if (dataRange === "Historical") {
                    timeFilter.historical.absoluteStartDate = value;
                }
            } else {
                const startDate = value.toDate();
                if (dataRange === "Current") {
                    timeFilter.current.absoluteStartDate = startDate;
                    if (timeFilter.current.EndDate < timeFilter.current.absoluteStartDate) {
                        timeFilter.current.EndDate = value.toDate();
                    }
                } else if (dataRange === "Historical") {
                    startDate.setDate(1);
                    timeFilter.historical.absoluteStartDate = startDate;
                    if (timeFilter.historical.EndDate < timeFilter.historical.absoluteStartDate) {
                        timeFilter.historical.EndDate = value.toDate();
                    }
                }
            }
        } else if (type === "absoluteEndDate") {
            if (typeof (value) === "string") {
                if (dataRange === "Current") {
                    timeFilter.current.EndDate = value;
                } else if (dataRange === "Historical") {
                    timeFilter.historical.EndDate = value;
                }
            } else {
                if (dataRange === "Current") {
                    timeFilter.current.EndDate = value.toDate();
                } else if (dataRange === "Historical") {
                    timeFilter.historical.EndDate = value.toDate();
                }
            }
        }

        this.setState({timeFilter: timeFilter});
    }

    handleProjectReportsUpdate(label, isChecked) {
        const info = label.split(':');
        let workbooks = this.state.workbooks;
        let pr = [];
        let selectedProjects = [];

        if (info[2] === "CURRENT") {
            pr = workbooks.currentReportArray;
            selectedProjects = workbooks.currentSelectedProjects;
        } else if (info[2] === "HISTORICAL") {
            pr = workbooks.historicalReportArray;
            selectedProjects = workbooks.historicalSelectedProjects;
        }

        pr = pr.map((project) => {
            let data = project.data;
            data = data.map((item) => {
                if (item.id.toString() === info[1]) {
                    item.checked = isChecked;
                    if (isChecked && selectedProjects.indexOf(item.id) === -1) {
                        selectedProjects.push(item.id);
                    } else if (!isChecked && selectedProjects.indexOf(item.id) !== -1) {
                        let index = selectedProjects.indexOf(item.id);
                        if (index > -1) {
                            selectedProjects.splice(index, 1);
                        }
                    }
                }
                return item;
            });
            return project;
        });

        let currentTypeReports = pr.filter(x => x.type === info[0]);
        let currentTypeReportsData = currentTypeReports[0].data;
        let numberOfUnselected = currentTypeReportsData.filter(y => y.checked === false).length;

        this.setState({
            workbooks: workbooks,
            isSelectAllChecked: numberOfUnselected <= 0
        })
    }

    handleAnnualFTECostInputChange = (e) => {
        let tempAnnualFTECost = this.state.annualFTECost;
        tempAnnualFTECost.value = e;
        this.setState({annualFTECost: tempAnnualFTECost});
    };

    handleAnnualFTEHoursInputChange = (e) => {
        let tempAnnualFTEHours = this.state.annualFTEHours;
        tempAnnualFTEHours.value = e;
        this.setState({annualFTEHours: tempAnnualFTEHours});
    };

    handleLTNonErrorInputChange = (e) => {
        let tempLTNonError = this.state.LTNonError;
        tempLTNonError.value = e;
        this.setState({LTNonError: tempLTNonError});
    };

    handleLTSysErrorInputChange = (e) => {
        let tempLTSysError = this.state.LTSysError;
        tempLTSysError.value = e;
        this.setState({LTSysError: tempLTSysError});
    };

    handleLTUserErrorInputChange = (e) => {
        let tempLTUserError = this.state.LTUserError;
        tempLTUserError.value = e;
        this.setState({LTUserError: tempLTUserError});
    };

    handleLTUncatMessageInputChange = (e) => {
        let tempLTUncatMessage = this.state.LTUncatMessage;
        tempLTUncatMessage.value = e;
        this.setState({LTUncatMessage: tempLTUncatMessage});
    };

    handleProjectDurationInputChange = (e) => {
        let tempProjectDuration = this.state.projectDuration;
        tempProjectDuration.value = e;
        this.setState({projectDuration: tempProjectDuration});
    };

    handleProjectUserInputChange = (e) => {
        let tempProjectUser = this.state.projectUser;
        tempProjectUser.value = e;
        this.setState({projectUser: tempProjectUser});
    };


    handleTestEffortPerModuleInputChange = (e) => {
        let tempTestEffortPerModule = this.state.testEffortPerModule;
        tempTestEffortPerModule.value = e;
        this.setState({testEffortPerModule: tempTestEffortPerModule});
    };


    selectAllHandler = (reportType, checked, tabKey) => {
        console.log(reportType + "   " + tabKey);
        this.setState({isSelectAllChecked: checked});
        let workbooks = this.state.workbooks;

        let pr = [];
        let selectedProjects = [];

        if (reportType === "CURRENT") {
            pr = workbooks.currentReportArray;
            selectedProjects = workbooks.currentSelectedProjects;
        } else if (reportType === "HISTORICAL") {
            pr = workbooks.historicalReportArray;
            selectedProjects = workbooks.historicalSelectedProjects;
        }

        pr.map((project) => {
            if (project.type === tabKey) {
                let data = project.data;
                data.map((item) => {
                    item.checked = checked;
                    if (selectedProjects.indexOf(item.id) === -1) {
                        selectedProjects.push(item.id);
                    } else if (!checked && selectedProjects.indexOf(item.id) !== -1) {
                        let index = selectedProjects.indexOf(item.id);
                        if (index > -1) {
                            selectedProjects.splice(index, 1);
                        }
                    }
                    return item;
                });
                return project;
            }
        });

        this.setState({
            workbooks: workbooks
        })
    };

    resetSelectAllCheckStatus = (reportType, tabKey) => {
        let workbooks = this.state.workbooks;
        let pr = [];

        if (reportType === "CURRENT") {
            pr = workbooks.currentReportArray;
        } else if (reportType === "HISTORICAL") {
            pr = workbooks.historicalReportArray;
        }

        let currentTabPr = pr.filter(p => p.type === tabKey);
        let allReportsUnderThisTab = currentTabPr[0].data;
        let selectedNOofReports = allReportsUnderThisTab.filter(x => x.checked === true);
        if (allReportsUnderThisTab.length > 0 && allReportsUnderThisTab.length === selectedNOofReports.length)
            this.setState({isSelectAllChecked: true});
        else
            this.setState({isSelectAllChecked: false});
    };

    handleStepChange = (step) => {
        let workbooks = this.state.workbooks;
        let pr = [];

        if (step === 1) {
            pr = workbooks.currentReportArray;
        } else if (step === 2) {
            pr = workbooks.historicalReportArray;
        }

        let numberOfUnselected = 0;
        if (pr.length > 0) {
            let defaultTypeReports = pr[0].data;
            if (defaultTypeReports)
                numberOfUnselected = defaultTypeReports.filter(y => y.checked === false).length;
        }
        this.setState({
            isSelectAllChecked: numberOfUnselected <= 0
        })

    };

    resetOptions = (event, filterName) => {
        switch (filterName) {
            case "application": {
                this.setState({applicationOptions: []});
                break;
            }
            case "module": {
                this.setState({moduleOptions: []});
                break;
            }
            /*case "screenGroups":   {
                this.setState({screenGroupOptions: []});
                break;
            }*/
            default: {
                console.warn("no match filter callback method .")
            }
        }
    };

    validateTimeframe(type) {
        let timeFilter = {};
        let ASErrorFound = false;
        let AEErrorFound = false;
        let dateFormat = "";

        if (type === "Current") {
            timeFilter = this.state.timeFilter.current;
            dateFormat = "MM/DD/YYYY";
        } else if (type === "Historical") {
            timeFilter = this.state.timeFilter.historical;
            dateFormat = "MM/YYYY";
        }

        if (timeFilter.selectedTimePeriod === "ABSOLUTE") {
            if (typeof (timeFilter.absoluteStartDate) === "string" || typeof (timeFilter.EndDate) === 'string') {
                if (typeof (timeFilter.absoluteStartDate) === "string") {
                    if (type === "Current") {
                        this.setState({
                            absoluteStartErrorCurrent: (<small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                        });
                        this.updateVisible("errorTab2", "visible");
                    } else if (type === "Historical") {
                        this.setState({
                            absoluteStartErrorHistorical: (<small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                        });
                        this.updateVisible("errorTab3", "visible");
                    }
                    ASErrorFound = true;
                }
                if (typeof (timeFilter.EndDate) === 'string') {
                    if (type === "Current") {
                        this.setState({
                            absoluteEndErrorCurrent: (<small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                        });
                        this.updateVisible("errorTab2", "visible");
                    } else if (type === "Historical") {
                        this.setState({
                            absoluteEndErrorHistorical: (<small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                        });
                        this.updateVisible("errorTab3", "visible");
                    }
                    AEErrorFound = true;
                }
            } else {
                const startDateAbsolute = document.getElementById('startDateAbsolute' + type);
                const endDateAbsolute = document.getElementById('endDateAbsolute' + type);

                if (startDateAbsolute && endDateAbsolute) {
                    let startDate = new Date(this.getPartOfDate(timeFilter.absoluteCalendarStart, "year"),
                        this.getPartOfDate(timeFilter.absoluteCalendarStart, "month"),
                        this.getPartOfDate(timeFilter.absoluteCalendarStart, "day"), 0, 0, 0
                    );
                    let endDate = new Date(this.getPartOfDate(timeFilter.absoluteStartDate, "year"),
                        this.getPartOfDate(timeFilter.absoluteStartDate, "month"),
                        this.getPartOfDate(timeFilter.absoluteStartDate, "day"), 0, 0, 0
                    );

                    let absoluteStartDateValue = {};
                    let absoluteEndDateValue = {};

                    if (type === "Current") {
                        absoluteStartDateValue = new Date(startDateAbsolute.value);
                        absoluteEndDateValue = new Date(endDateAbsolute.value);
                    } else if (type === "Historical") {
                        absoluteStartDateValue = new Date(startDateAbsolute.value.slice(0, 3) + "01" + startDateAbsolute.value.slice(2));
                        absoluteEndDateValue = new Date(endDateAbsolute.value.slice(0, 3) + "01" + endDateAbsolute.value.slice(2));
                    }

                    if (!moment(startDateAbsolute.value, dateFormat, true).isValid()) {
                        if (type === "Current") {
                            this.setState({
                                absoluteStartErrorCurrent: (<small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                            });
                            this.updateVisible("errorTab2", "visible");
                        } else if (type === "Historical") {
                            this.setState({
                                absoluteStartErrorHistorical: (
                                    <small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                            });
                            this.updateVisible("errorTab3", "visible");
                        }
                        ASErrorFound = true;
                    } else if (absoluteStartDateValue.valueOf() > 0) {
                        if (startDate.valueOf() > 0 && absoluteStartDateValue.valueOf() < startDate.valueOf()) {
                            if (type === "Current") {
                                this.setState({
                                    absoluteStartErrorCurrent: (
                                        <small className="text-danger">{this.props.t('validation:dateOutOfRange')}</small>)
                                });
                                this.updateVisible("errorTab2", "visible");
                            } else if (type === "Historical") {
                                this.setState({
                                    absoluteStartErrorHistorical: (
                                        <small className="text-danger">{this.props.t('validation:dateOutOfRange')}</small>)
                                });
                                this.updateVisible("errorTab3", "visible");
                            }
                            ASErrorFound = true;
                        }
                    } else {
                        if (type === "Current") {
                            this.setState({
                                absoluteStartErrorCurrent: (<small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                            });
                            this.updateVisible("errorTab2", "visible");
                        } else if (type === "Historical") {
                            this.setState({
                                absoluteStartErrorHistorical: (
                                    <small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                            });
                            this.updateVisible("errorTab3", "visible");
                        }
                        ASErrorFound = true;
                    }

                    if (!moment(endDateAbsolute.value, dateFormat, true).isValid()) {
                        if (type === "Current") {
                            this.setState({
                                absoluteEndErrorCurrent: (<small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                            });
                            this.updateVisible("errorTab2", "visible");
                        } else if (type === "Historical") {
                            this.setState({
                                absoluteEndErrorHistorical: (
                                    <small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                            });
                            this.updateVisible("errorTab3", "visible");
                        }
                        AEErrorFound = true;
                    } else if (absoluteEndDateValue.valueOf() > 0) {
                        if (endDate.valueOf() > 0 && absoluteEndDateValue.valueOf() < endDate.valueOf()) {
                            if (type === "Current") {
                                this.setState({
                                    absoluteEndErrorCurrent: (
                                        <small className="text-danger">{this.props.t('validation:dateEndBeforeStart')}</small>)
                                });
                                this.updateVisible("errorTab2", "visible");
                            } else if (type === "Historical") {
                                this.setState({
                                    absoluteEndErrorHistorical: (
                                        <small className="text-danger">{this.props.t('validation:dateEndBeforeStart')}</small>)
                                });
                                this.updateVisible("errorTab3", "visible");
                            }
                            AEErrorFound = true;
                        }
                    } else {
                        if (type === "Current") {
                            this.setState({
                                absoluteEndErrorCurrent: (<small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                            });
                            this.updateVisible("errorTab2", "visible");
                        } else if (type === "Historical") {
                            this.setState({
                                absoluteEndErrorHistorical: (
                                    <small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                            });
                            this.updateVisible("errorTab3", "visible");
                        }
                        AEErrorFound = true;
                    }
                }
            }
        }

        if (ASErrorFound || AEErrorFound) {
            if (!ASErrorFound) {
                if (type === "Current") {
                    this.setState({absoluteStartErrorCurrent: null});
                } else if (type === "Historical") {
                    this.setState({absoluteStartErrorHistorical: null});
                }
            }
            if (!AEErrorFound) {
                if (type === "Current") {
                    this.setState({absoluteEndErrorCurrent: null});
                } else if (type === "Historical") {
                    this.setState({absoluteEndErrorHistorical: null});
                }
            }
            return false;
        } else {
            if (type === "Current") {
                this.setState({
                    absoluteStartErrorCurrent: null,
                    absoluteEndErrorCurrent: null
                });
                this.updateVisible("errorTab2", "hidden");
            } else if (type === "Historical") {
                this.setState({
                    absoluteStartErrorHistorical: null,
                    absoluteEndErrorHistorical: null
                });
                this.updateVisible("errorTab3", "hidden");
            }
            return true;
        }
    }

    getNameforStyles = (orignalFieldName) => {
        if (orignalFieldName === undefined) return "undefined";
        let orignalName = orignalFieldName.toString();
        let covertedName = "";

        if (orignalName.includes("Annual FTE Cost"))
            covertedName = "annualFTECost";
        else if (orignalName.includes("Annual FTE hours"))
            covertedName = "annualFTEHours";
        else if (orignalName.includes("Lost time due to non errors"))
            covertedName = "LTNonError";
        else if (orignalName.includes("Lost time due to system errors"))
            covertedName = "LTSysError";
        else if (orignalName.includes("Lost time due to user errors"))
            covertedName = "LTUserError";
        else if (orignalName.includes("Lost Time Due to Uncategorized Messages"))
            covertedName = "LTUncatMessage";
        else if (orignalName.includes("Projected Duration"))
            covertedName = "projectDuration";
        else if (orignalName.includes("Projected Users"))
            covertedName = "projectUser";
        else if (orignalName.includes("Testing effort per module"))
            covertedName = "testEffortPerModule";

        return covertedName;
    };

    handleClickSave() {
        let isValidProjectName = true;
        let isValidCurrentTimeframe = true;
        let isValidHistoricalTimeframe = true;

        //Project name validation
        if (this.state.projectName === "") {
            this.setState({
                projectNameError: (
                    <small className="text-danger">
                        {this.props.t('validation:fieldRequired', {field: 'projectName'})}
                    </small>
                )
            });
            isValidProjectName = false;
            this.updateVisible("errorTab1", "visible");
        } else {
            this.updateVisible("errorTab1", "hidden");
            this.setState({projectNameError: null});
        }

        //Timeframe validation
        isValidCurrentTimeframe = this.validateTimeframe("Current");
        isValidHistoricalTimeframe = this.validateTimeframe("Historical");

        //preferred parameters validation
        const AnnualFTECost = this.state.annualFTECost;
        const AnnualFTEHours = this.state.annualFTEHours;
        const LTNonError = this.state.LTNonError;
        const LTSysError = this.state.LTSysError;
        const LTUserError = this.state.LTUserError;
        const LTUncatMessage = this.state.LTUncatMessage;
        const ProjectDuration = this.state.projectDuration;
        const ProjectUse = this.state.projectUser;
        const TestEffortPerModule = this.state.testEffortPerModule;

        let latestPreferredParams = [];
        let errors = [];
        latestPreferredParams.push(AnnualFTECost);
        latestPreferredParams.push(AnnualFTEHours);
        latestPreferredParams.push(LTNonError);
        latestPreferredParams.push(LTSysError);
        latestPreferredParams.push(LTUserError);
        latestPreferredParams.push(LTUncatMessage);
        latestPreferredParams.push(ProjectDuration);
        latestPreferredParams.push(ProjectUse);
        latestPreferredParams.push(TestEffortPerModule);

        latestPreferredParams.forEach(paramObj => {
            let currentParamValue = paramObj.value;
            let currentErrorObj = {};
            if (isNaN(currentParamValue)) {
                currentErrorObj["field"] = this.getNameforStyles(paramObj.name);
                currentErrorObj["message"] = this.props.t('validation:number');
            } else if ((currentParamValue!==null && currentParamValue.startsWith("-")) || Number(currentParamValue) < 0) {
                currentErrorObj["field"] = this.getNameforStyles(paramObj.name);
                currentErrorObj["message"] = this.props.t('validation:numberLess', {input: "0"});
            } else if (currentParamValue !== null && currentParamValue.length > 1 && currentParamValue.charAt(0) === '0') {
                currentErrorObj["field"] = this.getNameforStyles(paramObj.name);
                currentErrorObj["message"] = this.props.t('validation:numberZero');
            } else if (currentParamValue === "") {
                currentErrorObj["field"] = this.getNameforStyles(paramObj.name);
                currentErrorObj["message"] = this.props.t('validation:emptyValue');
            } else if (Number(currentParamValue) > 0) {
                let fieldName = this.getNameforStyles(paramObj.name);
                if (fieldName === 'annualFTECost' && Number(currentParamValue) > 1000000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "1000000"});
                } else if (fieldName === 'annualFTEHours' && Number(currentParamValue) > 10000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "10000"});
                } else if (fieldName === 'LTNonError' && Number(currentParamValue) > 100000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100000"});
                } else if (fieldName === 'LTSysError' && Number(currentParamValue) > 100000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100000"});
                } else if (fieldName === 'LTUserError' && Number(currentParamValue) > 100000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100000"});
                } else if (fieldName === 'LTUncatMessage' && Number(currentParamValue) > 100000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100000"});
                } else if (fieldName === 'projectDuration' && Number(currentParamValue) > 100) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100"});
                } else if (fieldName === 'projectUser' && Number(currentParamValue) > 1000000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "1000000"});
                } else if (fieldName === 'testEffortPerModule' && Number(currentParamValue) > 1000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "1000"});
                }
            }

            if (!(Object.keys(currentErrorObj).length === 0 && currentErrorObj.constructor === Object)) {
                errors.push(currentErrorObj);
            }

            if (errors.length === 0)
                this.updateVisible("errorTab1", "hidden");
            else
                this.updateVisible("errorTab1", "visible");
        });


        if (isValidProjectName && isValidCurrentTimeframe && isValidHistoricalTimeframe && errors.length === 0) {
            this.handleSubmit();
            this.setState({
                formErrors: '',
                inputClass_annualFTECost: 'form-control form-control-border-preferedpram',
                inputClass_annualFTEHours: 'form-control  form-control-border-preferedpram',
                inputClass_LTNonError: 'form-control  form-control-border-preferedpram',
                inputClass_LTSysError: 'form-control  form-control-border-preferedpram',
                inputClass_LTUserError: 'form-control  form-control-border-preferedpram',
                inputClass_LTUncatMessage: 'form-control  form-control-border-preferedpram',
                inputClass_projectDuration: 'form-control form-control-border-preferedpram',
                inputClass_projectUser: 'form-control form-control-border-preferedpram',
                inputClass_testEffortPerModule: 'form-control form-control-border-preferedpram',
            });
        } else {
            const formattedErrors = {};
            const errorFields = [];
            const allFields = ['inputClass_annualFTECost', 'inputClass_annualFTEHours', 'inputClass_LTNonError', 'inputClass_LTSysError', 'inputClass_LTUserError', 'inputClass_LTUncatMessage', 'inputClass_projectDuration', 'inputClass_projectUser', 'inputClass_testEffortPerModule'];
            errors.forEach(error => {
                formattedErrors[error.field] = error.message;
                errorFields.push('inputClass_' + error.field);
                if (error.field === 'annualFTECost') {
                    this.setState({inputClass_annualFTECost: 'form-control form-validation-input-error'});
                } else if (error.field === 'annualFTEHours') {
                    this.setState({inputClass_annualFTEHours: 'form-control form-validation-input-error'});
                } else if (error.field === 'LTNonError') {
                    this.setState({inputClass_LTNonError: 'form-control form-validation-input-error'});
                } else if (error.field === 'LTSysError') {
                    this.setState({inputClass_LTSysError: 'form-control form-validation-input-error'});
                } else if (error.field === 'LTUserError') {
                    this.setState({inputClass_LTUserError: 'form-control form-validation-input-error'});
                } else if (error.field === 'LTUncatMessage') {
                    this.setState({inputClass_LTUncatMessage: 'form-control form-validation-input-error'});
                } else if (error.field === 'projectDuration') {
                    this.setState({inputClass_projectDuration: 'form-control form-validation-input-error'});
                } else if (error.field === 'projectUser') {
                    this.setState({inputClass_projectUser: 'form-control form-validation-input-error'});
                } else if (error.field === 'testEffortPerModule') {
                    this.setState({inputClass_testEffortPerModule: 'form-control form-validation-input-error'});
                }

            });
            allFields.filter((field) => {
                if (!errorFields.includes(field)) {
                    this.setState({[field]: 'form-control'});
                }
            });
            this.setState({formErrors: formattedErrors});
            this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t, 'validationErrorMessage',null,null ));
        }
    }

    render() {
        if (this.state.sessionTerminated)
            return <Redirect to={{pathname: '/auth/logout'}}/>;

        else if (this.state.redirect) {
            return <Redirect to='/admin/projects'/>;
        }

        const {t} = this.props;

        let newProject = this.props.location.state && this.props.location.state.newProject;
        if (this.state.formValid === true) {
            //scroll to the top
            if (this.props.mainPanel) {
                this.props.mainPanel.current.scrollTop = 0;
            }
            if (newProject) {
                return <Redirect to={{
                    pathname: '/admin/viewProject',
                    state: {
                        id: this.state.createdProjectId,
                        projectName: this.state.createdProjectName,
                        editable: true
                    }
                }}/>
            } else {
                return <Redirect to={{
                    pathname: '/admin/viewProject',
                    state: {
                        id: this.state.updatedProjectId,
                        projectName: this.state.projectName,
                        editable: true
                    }
                }}/>
            }
        }

        const steps = [
            {
                name: t('project:projectInformation'),
                component:
                    <ProjectStep1
                        projectName={this.state.projectName}
                        projectTeam={this.state.projectTeam}
                        userOptions={this.state.userOptions}
                        handleProjectNameUpdate={this.handleProjectNameUpdate}
                        handleProjectTeamUpdate={this.handleProjectTeamUpdate}
                        applicationSelected={this.state.applicationSelected}
                        moduleSelected={this.state.moduleSelected}
                        //screenGroupSelected={this.state.screenGroupSelected}
                        applicationOptions={this.state.applicationOptions}
                        moduleOptions={this.state.moduleOptions}
                        //screenGroupOptions={this.state.screenGroupOptions}
                        userAttributeFilters={this.state.userAttributeFilters}
                        handleApplicationsOnChange={this.handleApplicationsOnChange}
                        handleApplicationsInputChange={this.handleApplicationsInputChange}
                        handleModulesOnChange={this.handleModulesOnChange}
                        handleModulesInputChange={this.handleModulesInputChange}
                        //handleScreenGroupsOnChange={this.handleScreenGroupsOnChange}
                        //handleScreenGroupsInputChange={this.handleScreenGroupsInputChange}
                        handleUserAttributesUpdate={this.handleUserAttributesUpdate}
                        resetOptions={this.resetOptions}
                        projectNameError={this.state.projectNameError}
                        annualFTECost={this.state.annualFTECost}
                        annualFTEHours={this.state.annualFTEHours}
                        LTNonError={this.state.LTNonError}
                        LTSysError={this.state.LTSysError}
                        LTUserError={this.state.LTUserError}
                        LTUncatMessage={this.state.LTUncatMessage}
                        projectDuration={this.state.projectDuration}
                        projectUser={this.state.projectUser}
                        testEffortPerModule={this.state.testEffortPerModule}
                        formErrors={this.state.formErrors}
                        inputClass_annualFTECost={this.state.inputClass_annualFTECost}
                        inputClass_annualFTEHours={this.state.inputClass_annualFTEHours}
                        inputClass_LTNonError={this.state.inputClass_LTNonError}
                        inputClass_LTSysError={this.state.inputClass_LTSysError}
                        inputClass_LTUserError={this.state.inputClass_LTUserError}
                        inputClass_LTUncatMessage={this.state.inputClass_LTUncatMessage}
                        inputClass_projectDuration={this.state.inputClass_projectDuration}
                        inputClass_projectUser={this.state.inputClass_projectUser}
                        inputClass_testEffortPerModule={this.state.inputClass_testEffortPerModule}
                        handleAnnualFTECostInputChange={this.handleAnnualFTECostInputChange}
                        handleAnnualFTEHoursInputChange={this.handleAnnualFTEHoursInputChange}
                        handleLTNonErrorInputChange={this.handleLTNonErrorInputChange}
                        handleLTSysErrorInputChange={this.handleLTSysErrorInputChange}
                        handleLTUserErrorInputChange={this.handleLTUserErrorInputChange}
                        handleLTUncatMessageInputChange={this.handleLTUncatMessageInputChange}
                        handleProjectDurationInputChange={this.handleProjectDurationInputChange}
                        handleProjectUserInputChange={this.handleProjectUserInputChange}
                        handleTestEffortPerModuleInputChange={this.handleTestEffortPerModuleInputChange}
                    />
            },
            {
                name: t('project:currentAnalysis'),
                component:
                    <ProjectStep2
                        timeFilter={this.state.timeFilter}
                        handleTimeFilterUpdate={this.handleTimeFilterUpdate}
                        projects={this.state.workbooks.currentReportArray}
                        selectedProjects={this.state.workbooks.currentSelectedProjects}
                        handleProjectReportsUpdate={this.handleProjectReportsUpdate}
                        selectAllHandler={this.selectAllHandler}
                        isSelectAllChecked={this.state.isSelectAllChecked}
                        resetSelectAllCheckStatus={this.resetSelectAllCheckStatus}
                        absoluteStartError={this.state.absoluteStartErrorCurrent}
                        absoluteEndError={this.state.absoluteEndErrorCurrent}
                    />
            },
            {
                name: t('project:historicalAnalysis'),
                component:
                    <ProjectStep3
                        timeFilter={this.state.timeFilter}
                        handleTimeFilterUpdate={this.handleTimeFilterUpdate}
                        projects={this.state.workbooks.historicalReportArray}
                        selectedProjects={this.state.workbooks.historicalSelectedProjects}
                        handleProjectReportsUpdate={this.handleProjectReportsUpdate}
                        selectAllHandler={this.selectAllHandler}
                        isSelectAllChecked={this.state.isSelectAllChecked}
                        resetSelectAllCheckStatus={this.resetSelectAllCheckStatus}
                        absoluteStartError={this.state.absoluteStartErrorHistorical}
                        absoluteEndError={this.state.absoluteEndErrorHistorical}
                    />
            }
        ];

        const footerObj = [];
        const saveObj = {
            name: t('common:save'),
            callback: this.handleClickSave
        };
        footerObj.push(saveObj);

        return (
            <>
                <div className="main-content main-content-page-with-footer project-wizard-main-content">
                    <Helmet>
                        {newProject && <title>Knoa Analytics - New Project </title>}
                        {!newProject && <title>Knoa Analytics - Edit Project </title>}
                    </Helmet>
                    <Row className="user-admin-breadcrumb">
                        <KnoaBreadcrumb
                            href="#/admin/projects"
                            firstLevelText={t('route.name:Projects')}
                            secondLevelText={newProject ? t('route.name:New Project') : t('route.name:Edit Project')}
                        />
                    </Row>
                    <ModalDialog
                        showModal={this.state.showLoggedoutModal}
                        title={"Log Out"}
                        contentBody={
                            <span>{getInfoMessages(this.props.t, 'logoutfor401', null, null)}</span>
                        }
                        bodyPaddingBottom='15px'
                        footerBtnBsStyle="success"
                        footerBtnClass="KnoaSuccessButton"
                        footerBtnOnClick={this.handleLogoutConfirm}
                        footerBtnText={this.props.t('common:ok')}
                    />
                    {this.state.fetchInProgress === "true" && <Spinner/>}
                    <div hidden={this.state.fetchInProgress === "true"}>
                        <CommonContainer
                            class="common-page-content-row user-profile-rule"
                            content={
                                <Col className="knoa-region" tabIndex={"-1"}>
                                    <Card
                                        wizard
                                        id="projectWizardCard"
                                        textCenter
                                        title={t('project:projectSetup')}
                                        content={
                                            <StepZilla
                                                steps={steps}
                                                showNavigation={false}
                                                showSteps={true}
                                                stepsNavigation={true}
                                                preventEnterSubmission={true}
                                                onStepChange={(step) => this.handleStepChange(step)}
                                            />
                                        }
                                    />
                                </Col>
                            }
                        />
                    </div>
                </div>
                <Footer fluid footerObj={footerObj}/>
            </>
        );
    }
}

export default withTranslation()(ProjectWizard);
