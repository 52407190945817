import React, {Component} from "react";
import {Row, FormGroup} from "react-bootstrap";
import {withTranslation} from 'react-i18next';
import {API} from "../../lib/API";
import {capitalizeWords, scrollbarForFilterPanel} from "../../lib/common";
import CommonSelector from "../common/CommonSelector";
import {FILTERS} from "../../lib/filters";
import {getErrorMessagesFromCode, getErrorMsgFromStatus} from "../../lib/messageHandler";
const localStorage = require('../../lib/localStorage');

const GenerateFilters = props => {
    const {enabledFilters, t, handleOnChange, handleOnInputChange, resetOptions, currentState, setFocus, allFilters,isInstanceReport,workbookId} = props;
    return (enabledFilters.map(filter => (
      <Row className="report-filter-panel-row">
          <Row className="card-filter-section-row-application">
              <label htmlFor={filter} className="col-control-sr-label">{capitalizeWords(t('report.filter:'+filter))}</label>
          </Row>
          <Row className="card-filter-section-row-application">
              <CommonSelector
                id={"f"+filter}
                inputId ={filter}
                isCreatable={FILTERS.getRegexFilters.indexOf(filter)>-1&&!FILTERS.isUserWorkflowsIgnore(filter,workbookId)}
                style={"typetosearch"}
                componentLocation={"filterpanel"}
                placeholder={currentState[filter + ' Focused'] ? "" : t('common:typeToSearch')}
                value={allFilters[filter].options ? allFilters[filter].options.filter(x => x.selected === true) : []}
                options={currentState[filter + ' OPTIONS'] ? currentState[filter + ' OPTIONS'] : []}
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => option.value}
                onChange={(e) => handleOnChange(e, filter)}
                onInputChange={(e) => handleOnInputChange(e, filter)}
                maxMenuHeight={"300px"}
                onMenuClose={(e) => resetOptions(e, filter)}
                onFocus={(e) => setFocus(e, filter, true)}
                onBlur={(e) => setFocus(e, filter, false)}
                isClearable
                isSearchable={true}
                isInstanceReport={isInstanceReport}/>
          </Row>
      </Row>
    )));
};

class ReportApplicationfilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            enabledFilters: [],
            loaded: false,
            oldHeight:0
        }
    }

    handleOnChange = (event, filterName) => {
        let latestFilter = this.props.currentReportFilters.filters[filterName];
        console.log("here in app filter")
        console.log(this.props.currentReportFilters)

        if(FILTERS.getApiFilter().hasOwnProperty(filterName)) {
            let selectedOptionsLength = event.length;
            if(FILTERS.getRegexFilters.indexOf(filterName)>-1 && selectedOptionsLength > 0 && !FILTERS.isUserWorkflowsIgnore(filterName,this.props.workbookId)) {
                let stringAPI;
                let word;
                let _workbookId = 0;
                if(this.props.workbookId) {
                    _workbookId = this.props.workbookId
                }
                if(selectedOptionsLength===1) {
                    stringAPI = `/report/regex/convert/${_workbookId}`;
                    word = event[0].value;
                } else {
                    stringAPI = `/report/regex/convert/list/${_workbookId}`;
                    event.map(item => {
                        if(!word) word = [];
                        word.push(item.value)
                    });
                }
                API.report
                  .post(stringAPI, {
                      word: word
                  })
                  .then(resp => {
                      const {data: resData} = resp;
                      const {data} = resData;

                      let regexString = typeof(data.regex)==='string' ? data.regex : data.regex.join('|');

                      let filterOptions = latestFilter.options !== null ? latestFilter.options : [];
                      if (selectedOptionsLength > filterOptions.length) {
                          let newOptions = {
                              id: event[selectedOptionsLength - 1].id,
                              value: event[selectedOptionsLength - 1].value,
                              label: event[selectedOptionsLength - 1].label,
                              selected: true
                          };
                          filterOptions.push(newOptions);
                      } else {
                          filterOptions = event;
                      }
                      latestFilter.options = filterOptions;
                      latestFilter.regex = [regexString];
                      window.sessionStorage.removeItem("showSpinner");
                      
                      this.props.updateApplicationsAndUserFilterValues(filterName, latestFilter);
                      let newHeight = document.getElementById("f"+filterName).offsetHeight;
                      scrollbarForFilterPanel(this.state.oldHeight,newHeight, filterName);
                      this.setState({
                          oldHeight: newHeight});
                     // console.log("the new height is:"+newHeight);
                  })
                  .catch(e => {
                      console.error(e);
                      if(localStorage.get("accessDenied401") === "true"){
                          this.props.handleAuthorization401();
                          return;
                      }
                      let resultCode = -1;
                      if(e.data) {
                          resultCode = e.data.resultCode;
                      }
                      if (resultCode > -1)
                          this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                      else
                          this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
                  });
            } else {
                let filterOptions = latestFilter.options !== null ? latestFilter.options : [];
                if (selectedOptionsLength > filterOptions.length) {
                    let newOptions = {
                        id: event[selectedOptionsLength - 1].id,
                        value: event[selectedOptionsLength - 1].value,
                        label: event[selectedOptionsLength - 1].label,
                        selected: true
                    };
                    filterOptions.push(newOptions);
                } else {
                    filterOptions = event;
                }

                if(FILTERS.getRegexFilters.indexOf(filterName)>-1 && !FILTERS.isUserWorkflowsIgnore(filterName,this.props.workbookId)) {
                  latestFilter.regex = [];
                }

                latestFilter.options = filterOptions;
                window.sessionStorage.removeItem("showSpinner");
                //scrollbarForFilterPanel();
                this.props.updateApplicationsAndUserFilterValues(filterName, latestFilter);
                let newHeight = document.getElementById("f"+filterName).offsetHeight;
                scrollbarForFilterPanel(this.state.oldHeight,newHeight, filterName);

                this.props.setTempFilterChanged(true);

                this.setState({oldHeight: newHeight});
                //console.log("the new height is:"+newHeight);
            }
        } else {
            let optIDs = [];
            event.forEach(opt => {
               optIDs.push(opt.id);
            });
            let tempOpts = [];
            latestFilter.options.forEach(opt => {
                if (optIDs.length === 0) {
                    opt.selected = false;     //for delete all or only one selection
                } else if (optIDs.length === 1) {
                    if (opt.id === optIDs[0] && opt.selected === false) opt.selected = true; //for choose the first option ever
                    else if (opt.id !== optIDs[0] && opt.selected === true) opt.selected = false;  // for delete one from two selected options
                } else {
                    if ((optIDs.includes(opt.id))) {
                        if (!opt.selected) opt.selected = true; // for adding new options after first
                    } else {
                        opt.selected = false;//for delete one from more than two selection
                    }
                }
                tempOpts.push(opt);
            });
            window.sessionStorage.removeItem("showSpinner");
            //scrollbarForFilterPanel();
            this.props.updateApplicationsAndUserFilterValues(filterName, latestFilter);
            let newHeight = document.getElementById("f"+filterName).offsetHeight;
            scrollbarForFilterPanel(this.state.oldHeight,newHeight, filterName);
            this.setState({oldHeight: newHeight});
            //console.log("the new height is:"+newHeight);
        }
    };

    handleOnInputChange = (event, filterName) => {
        if(event==="") return;
        let fOldHeight = document.getElementById("f"+filterName).offsetHeight;
        //console.log("the old height is: "+fOldHeight);
        let latestFilter = this.props.currentReportFilters.filters[filterName];
        let _workbookId = 0;
        if(this.props.workbookId) {
            _workbookId = this.props.workbookId
        }
        if(FILTERS.getApiFilter().hasOwnProperty(filterName)) {
            API.report
              .post('/report/list/'+FILTERS.getApiFilter()[filterName]+'/'+_workbookId+'?top=100', {
                word: event
            })
              .then(resp => {
                  const {data: resData} = resp;
                  const {data} = resData;

                  let latestOptions = data.results;

                  //add label
                  latestOptions.map(item => {
                      item.label = item.value;
                  });

                  this.setState({[filterName + ' OPTIONS']: latestOptions,
                      oldHeight: fOldHeight});
              })
              .catch(e => {
                  console.error(e);
                  if(localStorage.get("accessDenied401") === "true"){
                      this.props.handleAuthorization401();
                      return;
                  }
                  let resultCode = -1;
                  if(e.data) {
                      resultCode = e.data.resultCode;
                  }
                  if (resultCode > -1)
                      this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                  else
                      this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
              });
        } else {
            let tempOpts = [];
            let latestFilterOptions = latestFilter.options;
            latestFilterOptions.forEach(opt=>{
                if(opt.selected === false && opt.value.toLowerCase().indexOf(event.toLowerCase())>-1){
                    tempOpts.push(opt);
                }
            });
            this.setState({[filterName + ' OPTIONS']: tempOpts,
            oldHeight: fOldHeight});
        }
    };

    resetOptions = (event, filterName) => {
        let filterOptions = this.state[filterName + ' OPTIONS'];
        if(filterOptions) {
            let allFilters = this.props.currentReportFilters.filters;
            if(allFilters !== undefined && allFilters[filterName]) {
                if(FILTERS.getApiFilter().hasOwnProperty(filterName)){
                    this.setState({
                        [filterName + ' OPTIONS']: []
                    })
                } else {
                    this.setState({
                        [filterName + ' OPTIONS']: allFilters[filterName].options
                    })
                }
            }
        }
    };

    setFocus = (event, filterName, flag) => {
      this.setState({[filterName + ' Focused']: flag});
    };

    shouldComponentUpdate(nextProps, nextState) {
        let tempFilterChangeIdentifier = window.sessionStorage.getItem("lastChangedFilter");
        if(tempFilterChangeIdentifier=== null || (tempFilterChangeIdentifier && !(tempFilterChangeIdentifier.startsWith("TIME") || tempFilterChangeIdentifier.startsWith("STRING") ||tempFilterChangeIdentifier.startsWith("END"))))
            return true;
        else
            return false;
    }

    componentDidMount() {
        let allFilters = this.props.currentReportFilters.filters;
        let enabledFilters = [];
        if(allFilters !== undefined) {
            //set all options and selected options for filters that are enabled
            FILTERS.getApplicationFilters.map(filter => {
                if(allFilters[filter] && allFilters[filter].enabled) {
                    let allOptions = allFilters[filter].options;
                    if(allOptions && allOptions.length > 0) {
                        allOptions.map(item => {
                            item.label = item.value;
                        });
                    }
                    enabledFilters.push(filter);
                    this.setState({
                        [filter + ' OPTIONS']: allOptions
                    })
                }
            });
        }
        this.setState({
            enabledFilters: enabledFilters,
            loaded: true
        });
    }

    render() {
        if(!this.state || !this.state.loaded) {
            return null;
        }

        return (
            <FormGroup  role="group" aria-labelledby="applicationFilters" className= {this.props.isInstanceReport ? "report-filter-panel-formGroup  report-filter-panel-instance": "report-filter-panel-formGroup"}>
                <Row className="card-filter-section-title">
                    <span id="applicationFilters">{this.props.t('report.filter:applicationFilters')}</span>
                </Row>
                <GenerateFilters
                    enabledFilters={this.state.enabledFilters}
                    t={this.props.t}
                    handleOnChange={this.handleOnChange}
                    handleOnInputChange={this.handleOnInputChange}
                    resetOptions={this.resetOptions}
                    currentState={this.state}
                    setFocus={this.setFocus}
                    allFilters={this.props.currentReportFilters.filters}
                    isInstanceReport={this.props.isInstanceReport}
                    workbookId={this.props.workbookId}
                />
            </FormGroup>
        )
    }
}

export default withTranslation()(ReportApplicationfilters);