import React, {Component} from "react";
import {Col, ControlLabel, FormGroup, Row} from "react-bootstrap";
import {capitalizeWords, scrollbarForFilterPanel} from "../../lib/common";
import CommonSelector from "./CommonSelector";
import {API} from "../../lib/API";
import {FILTERS} from "../../lib/filters";
import {getErrorMessagesFromCode, getErrorMsgFromStatus} from "../../lib/messageHandler";

class SingleSelectFilter extends Component {
    constructor(props) {
        super(props);
        this.handleUserAttributesUpdate = this.handleUserAttributesUpdate.bind(this);
        this.handleUserAttributesInputChange = this.handleUserAttributesInputChange.bind(this);
        this.state = {
            userAttribute: props.userAttribute,
            handleUserAttributesUpdate: props.handleUserAttributesUpdate,
            uaFocused: false,
            unSelectedOptions:[],
            oldHeight:0,
        };
    }

    handleUserAttributesUpdate =(e) => {
        console.log("starting to update the options: ");
        let uaFieldID ;
        if(this.props.identifier === "reportFilter") {
            this.props.handleUserAttributesUpdate(e, this.state.userAttribute[1].originalName);
            let tempUAID = this.state.userAttribute[0];
            uaFieldID = tempUAID.substring(0, tempUAID.indexOf('.'));
        }
        else {
            this.props.handleUserAttributesUpdate(e, this.state.userAttribute.id);
            uaFieldID = this.state.userAttribute.id;
        }

        let newHeight = document.getElementById(uaFieldID).offsetHeight;
        scrollbarForFilterPanel(this.state.oldHeight,newHeight, uaFieldID);
        this.setState({
            oldHeight: newHeight});
    };

    handleUserAttributesInputChange =(inputValue) => {
        if (inputValue === "") return;
        let uaID ;

        if (this.props.identifier === undefined)
            uaID = this.state.userAttribute.id;
        else if(this.props.identifier === "reportFilter")  {
            let tempUAID = this.state.userAttribute[0];
            uaID = tempUAID.substring(0, tempUAID.indexOf('.'));
        }
        let fOldHeight = document.getElementById(uaID).offsetHeight;
        let _workbookId = 0;
        if(this.props.workbookId) {
            _workbookId = this.props.workbookId
        }
        API.report
            .post('/report/list/ua/'+uaID+'/'+_workbookId+'?top=100', {
                word: inputValue
            })
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;
                let latestOptions = data.results;
                this.setState({unSelectedOptions: latestOptions,
                    oldHeight: fOldHeight});
            })
            .catch(e => {
                console.error(e);
                if (localStorage.get("accessDenied401") === "true") {
                    this.props.handleAuthorization401();
                    return;
                }
                let resultCode = -1;
                if(e.data) {
                    resultCode = e.data.resultCode;
                }
                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });

    };

    resetOptions = (event, filterName) => {
        switch(filterName) {
            case "ua":   {
                let uaForReset = this.state.unSelectedOptions;
                if(uaForReset) uaForReset = [];
                this.setState({
                    unSelectedOptions: uaForReset
                });
            };
                break;
            default:  {
                console.warn("no match for user attribute filter .")
            }
        }
    };
    
    render() {
        const {userAttribute,isInstanceReport, key,translate} = this.props;

        // userFilterOptions = this.state.users.options === undefined ? [] : this.state.users.options;
        // selectedUsers = this.props.currentReportFilters.filters[FILTERS.getFilterEndUserName("default")].options === null ? [] : this.props.currentReportFilters.filters[FILTERS.getFilterEndUserName("default")].options.filter(x => x.selected === true);
        let uaFieldID;
        let selectedOptions = [];
        let unSelectedOptions = this.state.unSelectedOptions === undefined ? [] : this.state.unSelectedOptions;
        if (this.props.identifier === undefined) {
            if (userAttribute && userAttribute.options.length > 0) userAttribute.options.forEach(x => {
                if (x.selected === true) selectedOptions.push(x)
            });
            uaFieldID = this.state.userAttribute.id;
        } else if(this.props.identifier === "reportFilter") {
            if (userAttribute && userAttribute[1].options.length > 0) userAttribute[1].options.forEach(x => {
                if (x.selected === true) selectedOptions.push(x)
            });
            let tempUAID = this.state.userAttribute[0];
            uaFieldID = tempUAID.substring(0, tempUAID.indexOf('.'));
        }
        return (
            <>
                {this.props.identifier === undefined &&
                <Row className="card-row-flex">
                    <Col className="card-row-col-label" componentClass={ControlLabel} sm={1}>
                        <label htmlFor={userAttribute.name} className="col-control-sr-label">{userAttribute.name}</label>
                    </Col>
                    <Col sm={1} className="card-row-col-right">
                        <CommonSelector
                            id={uaFieldID}
                            inputId = {userAttribute.name}
                            style={"typetosearch"}
                            componentLocation={"normal"}
                            placeholder={translate ? (this.state.uaFocused ? "" : translate('common:typeToSearch')) : "Type to Search..."}
                            value={selectedOptions}
                            options={unSelectedOptions}
                            getOptionLabel={(option) => option.value}
                            getOptionValue={(option) => option.value}
                            onChange={this.handleUserAttributesUpdate}
                            onInputChange={this.handleUserAttributesInputChange}
                            onFocus={(e) => {this.setState({ uaFocused : true });}}
                            onBlur={(e) => {this.setState({ uaFocused : false });}}
                            onMenuClose={(e) => this.resetOptions(e, "ua")}
                            isSearchable={true}
                            isClearable/>
                    </Col>
                </Row>
                }
                {this.props.identifier === "reportFilter" &&
                <Row className="single-select-filter-row">
                    <Row className="card-filter-section-row">
                        <label htmlFor={userAttribute[0]} className="col-control-sr-label">{capitalizeWords(userAttribute[0].toLowerCase())}</label>
                    </Row>
                    <Row className="card-filter-section-row">
                        <CommonSelector
                            id={uaFieldID}
                            inputId = {userAttribute[0]}
                            style={"typetosearch"}
                            componentLocation={"filterpanel"}
                            placeholder={this.state.uaFocused ? "" : translate('common:typeToSearch')}
                            value={selectedOptions}
                            options={unSelectedOptions}
                            getOptionLabel={(option) => option.value}
                            getOptionValue={(option) => option.value}
                            onChange={this.handleUserAttributesUpdate}
                            onInputChange={this.handleUserAttributesInputChange}
                            onFocus={(e) => {this.setState({ uaFocused : true });}}
                            onBlur={(e) => {this.setState({ uaFocused : false });}}
                            onMenuClose={(e) => this.resetOptions(e, "ua")}
                            maxMenuHeight={"300px"}
                            isSearchable={true}
                            isClearable
                            isInstanceReport={isInstanceReport}/>
                    </Row>
                </Row>
                }

            </>

        );
    }
}

export default SingleSelectFilter;
