import React, { Component } from "react";
import {Row, Col} from "react-bootstrap";
import {NavLink} from "react-router-dom";

class KnoaCardLinkRow extends Component {
  render() {
    const {
      icon,
      subHeading,
      heading,
      path
    } = this.props;

    const subHeadingStyle = {
      color: subHeading.color,
        flex:1,
    };

    const headingStyle = {
      color: heading.color,
      fontSize: "22px",
        flex:1,
    };
    
    return(
      <Row className="middleRow">
        <Col lg={3} sm={3} className="colLeft">
          <div className="bigIcon">
            <i className={icon.name + (icon.status==="success" ? " text-success" : icon.status==="danger" ? " text-danger" : icon.status==="neutral" ? " selectCardIcon" : "") } />
          </div>
        </Col>
        <Col lg={9} sm={9} className="card-col-right colRight">
          <span style={subHeadingStyle}>{subHeading.text}</span>
            <h5 style={headingStyle}>{heading.text}</h5>
        </Col>
      </Row>
    )
  }
}

export default KnoaCardLinkRow;