import * as React from "react";

class parameterFactory {
  constructor() {
    this.tableauParameters = [
      "SORT BY",
      "DATA PAGE NUMBER",
      "MODULES PAGE NUMBER",
      "SCREENS PAGE NUMBER",
      "MESSAGES PAGE NUMBER",
      "OPERATIONS PAGE NUMBER",
      "USERS PAGE NUMBER",
      "TASKS PAGE NUMBER",
      "COMPUTERS PAGE NUMBER"
    ];

    this.metricSelectionParameters = [
      "SELECT METRIC",
      "SELECT TREND",
      "SELECT ERROR METRIC",
      "DIFFERENCE OR ACTUAL"
    ];

    this.userAttributeParameters = "SELECT USER ATTRIBUTE";

    //dropdown selections other than "SELECT USER ATTRIBUTE"
    this.dropdownParameters = [
        "SELECT VIEW" //Select Application Context in Post-Migration Adoption/Performance
    ];

    //worksheets that contain dropdown selection parameters other than "SELECT USER ATTRIBUTE"
    this.dropdownParameterWorksheets = [
      "appactivity_difference", //Post-Migration Adoption - Activity - Difference - Applications
      "modactivity_difference", //Post-Migration Adoption - Activity - Difference - Modules
      "app_activity", //Post-Migration Adoption - Activity - Actual - Applications
      "mod_activity", //Post-Migration Adoption - Activity - Actual - Modules
      "apperrors_difference", //Post-Migration Adoption - User Errors - Difference - Applications
      "moderrors_difference", //Post-Migration Adoption - User Errors - Difference - Modules
      "app_errors", //Post-Migration Adoption - User Errors - Actual - Applications
      "mod_errors", //Post-Migration Adoption - User Errors - Actual - Modules
      "appperf_difference", //Post-Migration Performance - Performance - Difference - Applications
      "modperf_difference", //Post-Migration Performance - Performance - Difference - Modules
      "app_performance", //Post-Migration Performance - Performance - Actual - Applications
      "mod_performance", //Post-Migration Performance - Performance - Actual - Modules
      "appsyserrors_difference", //Post-Migration Performance - System Errors - Difference - Applications
      "modsyserrors_difference", //Post-Migration Performance - System Errors - Difference - Modules
      "appsyserrors", //Post-Migration Performance - System Errors - Actual - Applications
      "modsyserrors" //Post-Migration Performance - System Errors - Actual - Modules
    ];
  }

  get getTableauParameters() {
    return this.tableauParameters;
  }

  get getMetricSelectionParameters() {
    return this.metricSelectionParameters;
  }

  get getUserAttributeParameters() {
    return this.userAttributeParameters;
  }

  get getDropdownParameters() {
    return this.dropdownParameters;
  }

  get getDropdownParameterWorksheets() {
    return this.dropdownParameterWorksheets;
  }
}

const PARAMETERS = new parameterFactory();
export { PARAMETERS };