import React, { Component } from "react";
import LinkCard from "../../../components/Card/LinkCard";
import { mauiRoutes } from "../../../routes.js";
import {withTranslation} from "react-i18next";
import {Responsive, WidthProvider} from 'react-grid-layout';
import {layoutsSettings} from "../../../lib/gridLayouts";
import {Helmet} from "react-helmet";

const onLayoutChange = () => {
  setTimeout(function(){
    const event = document.createEvent("Event");
    event.initEvent("resize",false,true);
    window.dispatchEvent(event);
    //window.dispatchEvent(new Event('resize'))
  }, 200);
};

const AdminSettingsCards = props => {
    const {cardIcon, userIcon, trans} = props;
    const ResponsiveGridLayout = WidthProvider(Responsive);

    return (
        <ResponsiveGridLayout className="layout"
                              layouts={layoutsSettings}
                              breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                              cols={{lg: 12, md: 9, sm: 6, xs: 4, xxs: 2}}
                              isDraggable={false}
                              isResizable={false}
                              containerPadding={[15,0]}
                              onLayoutChange={onLayoutChange}
        >
          <div key={"1"}>
            <LinkCard
                key={"1"}
                subHeading={{
                    text: trans("route.name:Settings"),
                    color: "#595959"
                }}
                heading={{
                    text: trans("route.name:User Management"),
                    color: "#23ccef"
                }}
                path={{
                  subHeading: "Settings",
                  heading: "User Management"
                }}
                footerText={trans("adminSettings:userManagementFooter")}
                icon={{
                    name: userIcon,
                    status: "neutral"
                }}
            />
          </div>
          <div key="2">
            <LinkCard
                key={"2"}
                subHeading={{
                    text: trans("route.name:Settings"),
                    color: "#595959"
                }}
                heading={{
                    text: trans("route.name:KPI Cards"),
                    color: "#23ccef"
                }}
                path={{
                  subHeading: "Settings",
                  heading: "KPI Cards"
                }}
                footerText={trans("adminSettings:kpiCardsFooter")}
                icon={{
                    name: cardIcon,
                    status: "neutral"
                }}
            />
          </div>
            <div key="3">
                <LinkCard
                    key={"3"}
                    subHeading={{
                        text: trans("route.name:Settings"),
                        color: "#595959"
                    }}
                    heading={{
                        text: trans("route.name:securitySettings"),
                        color: "#23ccef"
                    }}
                    path={{
                        subHeading: "Settings",
                        heading: "Security Settings"
                    }}
                    footerText={trans("adminSettings:securitySettingsFooter")}
                    icon={{
                        name: "pe-7s-key",
                        status: "neutral"
                    }}
                />
            </div>
        </ResponsiveGridLayout>
    );
};

class AdminSettings extends Component {
    constructor(prop) {
        super(prop);
        let routes = mauiRoutes.getRoutes();
        const userIdx = routes.findIndex(x => x.path ==="/Settings/UserManagement");
        const cardIdx = routes.findIndex(x => x.path ==="/Settings/KPICards");

        this.mainContent = React.createRef();
        
        this.state = {
            settings: [],
            userIcon: routes[userIdx].icon,
            cardIcon: routes[cardIdx].icon,
        };
    }

componentDidMount() {
        if(this.mainContent && this.mainContent.current) {
            this.mainContent.current.focus();
        }
    }

    render() {
        return (
            <div className="main-content knoa-region" ref={this.mainContent} tabIndex={"-1"}>
                <Helmet>
                    <title>Knoa Analytics - Settings</title>
                </Helmet>
              <AdminSettingsCards data={this.state.settings} cardIcon={this.state.cardIcon} userIcon={this.state.userIcon} trans={this.props.t}/>
            </div>
        );
    }
}

export default withTranslation()(AdminSettings);