import * as React from "react";
import {addAlert, clearAlert} from "../NotificationSystem";
import {convertMinsToHrsMins} from "../common";
import jwt_decode from "jwt-decode";

const Cookies = require('js-cookie');
const omit = require('lodash/omit');
const moment = require('moment');
// import Router from "next/router";
const localStorage = require('../localStorage');

const DEFAULT_LANG = 'en';


const key = require('../../constant/key');
const axios = require('axios');
const merge = require('lodash/merge');
const _ = require('lodash');



class ApiFactory {
    constructor() {
        this.DEFAULT_HEADERS = {
            'Content-Type': 'application/json',
        };
        this.axios = null;
        this.headers = {};
        this.apiBaseURL = process.env.REACT_APP_MAUI_DEFAULT;
        this.timerId = -1;
        this.sid = -1;
        this.timeout = 30;
        this.initInstance();
    }

    initInstance() {
        this.setHeaders(this.DEFAULT_HEADERS);
        this.createInstance();

    }   
    
    get config() {
        return {
            baseURL: this.apiBaseURL,
            headers: this.headers,
            withCredentials: true,
            timeout: 300000,
        };
    }

    setHeaders(header = {}) {
        this.headers = Object.assign({}, this.DEFAULT_HEADERS, header);
    }
    
    addHeaders(header = {}) {
        this.headers = Object.assign(this.headers, header);
    }
    
    omitHeaders(keys = []) {
        for (const key of keys) {
            this.headers = omit(this.headers, key);
        }
    }

    createInstance() {
        this.axios = axios.create(this.config);        
        this.axios.interceptors.request.use(
            async config => {
                // return config;
                //document.body.classList.add('loading-indicator');
                localStorage.set("isLoading", "true");
                this.initLoadingReport("true");
                return merge(config, {
                    headers: merge(config.headers, await this.getCustomHeaders()),
                });
            },
            error => {
                //document.body.classList.remove('loading-indicator');
                localStorage.set("isLoading", "false");
                this.completeLoadingReport("false");
                if (401 === error.response.status) {
                    localStorage.set("accessDenied401", "true");
                } else {
                    return Promise.reject(error);
                }
            }
        );

        this.axios.interceptors.response.use(
            response => {
                //document.body.classList.remove('loading-indicator');
                localStorage.set("isLoading", "false");
                this.refreshTimeout();
                this.completeLoadingReport("false");
                const mauiResultCode = _.get(response, 'data.resultCode');
                if (!!mauiResultCode) {
                    if (mauiResultCode === 200) {
                        return response;
                    } else {
                        this.createMauiServerError(response);
                        return Promise.reject(response);
                    }
                } else {
                    return response;
                }
            },
            error => {
               // document.body.classList.remove('loading-indicator');
                localStorage.set("isLoading", "false");
                this.completeLoadingReport("false");
                console.error(error);
                if(error.message && error.message==='canceled') {
                    return Promise.reject(error);
                }
                if (error.response.status && 401 === error.response.status) {
                    localStorage.set("accessDenied401", "true");
                } else {
                    return Promise.reject(error);
                }
            }
        );
    }
    
    initLoadingReport = (tableauLoading)=>{
        //console.log("the report is loading");
        localStorage.set("isReportLoading", tableauLoading);
    };
    
    completeLoadingReport = (tableauLoading) =>{
        //console.log("the report loaded complete");
        localStorage.set("isReportLoading", tableauLoading);
    };
    
    createMauiServerError(response) {
        const resultCode = _.get(response, 'data.resultCode');
        const message = _.get(response, 'data.message');
        const responseURL = _.get(response, 'request.responseURL');
        console.error(
            `[MAUI API] resultCode ${resultCode}: ${message} at ${responseURL}`
        );
    }

    refreshTimeout() {
        const timeout = Cookies.get(key.TIMEOUT);
        if(timeout) {
            this.updateTimeout( { expiresIn: timeout} )
        }
    }
    
    updateTimeout({ expiresIn }) {
        if(this.timerId!==-1) {
            clearTimeout(this.timerId);
        }

        this.saveHeartBeat( { expiresIn })
        this.timeout = expiresIn;
        let _this = this;
        this.timerId = setInterval(function(){
            const beep = Cookies.get(key.HEART_BEAT);
            if (!beep) {
                window.location.reload();
            }

         }, 10000);                
        this.createInstance();
    }

    saveHeartBeat({ expiresIn }) {

        Cookies.set(key.HEART_BEAT, "beep", {
            expires: moment()
                .add(expiresIn*60, 'seconds')
                .toDate(),
        });
    }
    
    clearBeat() {
        if(this.timerId!==-1) {
            clearTimeout(this.timerId);
        }
        Cookies.remove(key.HEART_BEAT);
        this.createInstance();
    }
    
    setSessionLanguage( { sessionLanguage } ) {
        Cookies.set(key.SESSION_LANGUAGE, sessionLanguage, {
            expires: moment()
                .add(1, 'day')
                .toDate(),
        });
    }
    
    getSessionLanguage() {
        const language = Cookies.get(key.SESSION_LANGUAGE);
        if (!!language && language!=='undefined') {
            return language;
        }
        return 'en'
    }

    setUserTimeZone( timezone ) {
        localStorage.set(key.USER_TIMEZONE, timezone);
    }

    getUserTimeZone() {
        const value = localStorage.get(key.USER_TIMEZONE);
        return (!!value && value!=='undefined') ?  value : "";
    }

    setServerTimeZone( timezone ) {
        const zoneToSave = timezone==null||timezone===""||timezone==="unknown" ? "UTC" : timezone
        localStorage.set(key.SERVER_TIMEZONE, zoneToSave);
    }

    getServerTimeZone() {
        const value = localStorage.get(key.SERVER_TIMEZONE);
        return (!!value && value!=='undefined') ?  value : "";
    }

    isBeep() {
        const beep = Cookies.get(key.HEART_BEAT);
        if (!!beep && beep!=='undefined') {
            return true;
        }
        return false
    }

    getTimeout() {
        return this.timeout ? this.timeout : 30;
    }

    get user() {
        return this.getApiInstance(process.env.REACT_APP_MAUI_USER_API);
    }

    get report() {
        return this.getApiInstance(process.env.REACT_APP_MAUI_REPORT_API);
    }

    get tableau() {
        return this.getApiInstance(process.env.REACT_APP_MAUI_TABLEAU_API);
    }

    get project() {
        return this.getApiInstance(process.env.REACT_APP_MAUI_PROJECT_API);
    }

    get admin() {
        return this.getApiInstance(process.env.REACT_APP_MAUI_ADMIN_API);
    }

    get notification() {
        return this.getApiInstance(process.env.REACT_APP_MAUI_NOTIFICATION_API);
    }
    get publicAnnouncement() {
        return axios.create({
            baseURL: process.env.REACT_APP_MAUI_PUBLIC_ANNOUNCEMENT_API,
            headers: {  'Content-Type': 'application/json' },
            timeout: 300000,
        });
    }
    
    getApiInstance(baseURL) {
        if (this.apiBaseURL !== baseURL) {
            this.apiBaseURL = baseURL;
            this.createInstance();
        }
        return this.axios;
    }

    //fixme: temporal implementation this should be its own file
    async getCustomHeaders() {
        let languageCode = DEFAULT_LANG;

        try {
            languageCode = localStorage.get(key.LANGUAGE_CODE);
            if (!languageCode) {
                const browserLanguage = navigator.language || navigator.userLanguage || '';
                languageCode = /(\w{2})-?(\w{2})?/.exec(browserLanguage)[1];
                localStorage.set(key.LANGUAGE_CODE, languageCode);
            }
        } catch (e) {
            console.error(e);
        }
        const version = '0.0.2';
        return {
            'x-knoa-maui-access': +new Date(),
            'x-knoa-maui-language': languageCode,
            'x-knoa-maui-version': version,
        };

    }
}



const API = new ApiFactory();
export { API };
