import React, { Component } from "react";
import {Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";

class KnoaCardMainRow extends Component {
  render() {
    const {
      icon,
      subHeading,
      heading,
      mainRowHeight
    } = this.props;
    
    return(
      <Row className="card-main-row">
        <Col sm={4} className="colLeft">
          <div className="bigIcon" >
            <i className={icon.name + (icon.status==="success" ? " text-success" : icon.status==="danger" ? " text-danger-home" : icon.status==="neutral" ? " selectCardIcon" : "") } />
          </div>
        </Col>
        <Col sm={8} className="card-col-right">
          <span >{subHeading.text}</span>
          <h5 className="card-main-row-heading">
            {heading.text}
          </h5>
        </Col>
      </Row>
    )
  }
}

export default KnoaCardMainRow;