/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import {API} from "lib/API"


class ProxySessionChange extends Component {
    constructor(props) {
        super(props)
        let _email ="";
        if (this.props.location.state && this.props.location.state.email) {
            _email = this.props.location.state.email;
        } else {
            _email = null;
        }
        this.state = {
            isLoaded: false,
            customers: null,
            email:_email
        };
    }
    componentDidMount() {
        API.user
            .get("/session/proxyChange")
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;
                this.setState({
                    isLoaded: true,
                    customers: data.customers
                })
            }).catch(e => {
            console.error(e);
            if (localStorage.get("accessDenied401") === "true") {
                window.location.href = "/#/auth/logout";
                return;
            }
        });
    }
    render() {
        return (
            <div>
                {this.state.isLoaded && (
                    <Redirect
                        to={{
                            pathname: '/auth/login',
                            state: {
                                customers: this.state.customers,
                                email: this.state.email
                            }}
                    }/>)}
            </div>
        );
    }
}

export default withTranslation()(ProxySessionChange);