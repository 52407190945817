//Home page
const layoutLgHome = [
  {i: '1', x: 0, y: 0, w: 3, h: 1.15},
  {i: '2', x: 3, y: 0, w: 3, h: 1.15},
  {i: '3', x: 6, y: 0, w: 3, h: 1.15},
  {i: '4', x: 9, y: 0, w: 3, h: 1.15},
  {i: '5', x: 0, y: 2, w: 3, h: 1.15},
  {i: '6', x: 3, y: 2, w: 3, h: 1.15},
  {i: '7', x: 6, y: 2, w: 3, h: 1.15},
  {i: '8', x: 9, y: 2, w: 3, h: 1.15},
  {i: '9', x: 0, y: 4, w: 3, h: 1.15},
  {i: '10', x: 3, y: 4, w: 3, h: 1.15},
  {i: '11', x: 6, y: 4, w: 3, h: 1.15},
  {i: '12', x: 9, y: 4, w: 3, h: 1.15}
];

const layoutMdHome = [
  {i: '1', x: 0, y: 0, w: 3, h: 1.09},
  {i: '2', x: 3, y: 0, w: 3, h: 1.09},
  {i: '3', x: 6, y: 0, w: 3, h: 1.09},
  {i: '4', x: 0, y: 2, w: 3, h: 1.09},
  {i: '5', x: 3, y: 2, w: 3, h: 1.09},
  {i: '6', x: 6, y: 2, w: 3, h: 1.09},
  {i: '7', x: 0, y: 4, w: 3, h: 1.09},
  {i: '8', x: 3, y: 4, w: 3, h: 1.09},
  {i: '9', x: 6, y: 4, w: 3, h: 1.09},
  {i: '10', x: 0, y: 6, w: 3, h: 1.09},
  {i: '11', x: 3, y: 6, w: 3, h: 1.09},
  {i: '12', x: 6, y: 6, w: 3, h: 1.09}
];

const layoutSmHome = [
  {i: '1', x: 0, y: 0, w: 3, h: 1.09},
  {i: '2', x: 3, y: 0, w: 3, h: 1.09},
  {i: '3', x: 0, y: 2, w: 3, h: 1.09},
  {i: '4', x: 3, y: 2, w: 3, h: 1.09},
  {i: '5', x: 0, y: 4, w: 3, h: 1.09},
  {i: '6', x: 3, y: 4, w: 3, h: 1.09},
  {i: '7', x: 0, y: 6, w: 3, h: 1.09},
  {i: '8', x: 3, y: 6, w: 3, h: 1.09},
  {i: '9', x: 0, y: 8, w: 3, h: 1.09},
  {i: '10', x: 3, y: 8, w: 3, h: 1.09},
  {i: '11', x: 0, y: 10, w: 3, h: 1.09},
  {i: '12', x: 3, y: 10, w: 3, h: 1.09}
];

const layoutXsHome = [
  {i: '1', x: 0, y: 0, w: 2, h: 1.35},
  {i: '2', x: 2, y: 0, w: 2, h: 1.35},
  {i: '3', x: 0, y: 2, w: 2, h: 1.35},
  {i: '4', x: 2, y: 2, w: 2, h: 1.35},
  {i: '5', x: 0, y: 4, w: 2, h: 1.35},
  {i: '6', x: 2, y: 4, w: 2, h: 1.35},
  {i: '7', x: 0, y: 6, w: 2, h: 1.35},
  {i: '8', x: 2, y: 6, w: 2, h: 1.35},
  {i: '9', x: 0, y: 8, w: 2, h: 1.35},
  {i: '10', x: 2, y: 8, w: 2, h: 1.35},
  {i: '11', x: 0, y: 10, w: 2, h: 1.35},
  {i: '12', x: 2, y: 10, w: 2, h: 1.35}
];

const layoutXxsHome = [
  {i: '1', x: 0, y: 0, w: 2, h: 1.09},
  {i: '2', x: 0, y: 2, w: 2, h: 1.09},
  {i: '3', x: 0, y: 4, w: 2, h: 1.09},
  {i: '4', x: 0, y: 6, w: 2, h: 1.09},
  {i: '5', x: 0, y: 8, w: 2, h: 1.09},
  {i: '6', x: 0, y: 10, w: 2, h: 1.09},
  {i: '7', x: 0, y: 12, w: 2, h: 1.09},
  {i: '8', x: 0, y: 14, w: 2, h: 1.09},
  {i: '9', x: 0, y: 16, w: 2, h: 1.09},
  {i: '10', x: 0, y: 18, w: 2, h: 1.09},
  {i: '11', x: 0, y: 20, w: 2, h: 1.09},
  {i: '12', x: 0, y: 22, w: 2, h: 1.09}
];

export const layoutsHome = {
  lg: layoutLgHome,
  md: layoutMdHome,
  sm: layoutSmHome,
  xs: layoutXsHome,
  xxs: layoutXxsHome
};

//New/Edit Project
const layoutLgProject = [
  {i: '1', x: 0, y: 0, w: 3, h: 1.75},
  {i: '2', x: 3, y: 0, w: 3, h: 1.75},
  {i: '3', x: 0, y: 2, w: 3, h: 1.75},
  {i: '4', x: 3, y: 2, w: 3, h: 1.75},
  {i: '5', x: 0, y: 4, w: 3, h: 1.75},
  {i: '6', x: 3, y: 4, w: 3, h: 1.75},
  {i: '7', x: 0, y: 6, w: 3, h: 1.75},
  {i: '8', x: 3, y: 6, w: 3, h: 1.75},
  {i: '9', x: 0, y: 8, w: 3, h: 1.75},
  {i: '10', x: 3, y: 8, w: 3, h: 1.75},
  {i: '11', x: 0, y: 10, w: 3, h: 1.75},
  {i: '12', x: 3, y: 10, w: 3, h: 1.75}
];

const layoutMdProject = [
  {i: '1', x: 0, y: 0, w: 3, h: 1.65},
  {i: '2', x: 3, y: 0, w: 3, h: 1.65},
  {i: '3', x: 0, y: 2, w: 3, h: 1.65},
  {i: '4', x: 3, y: 2, w: 3, h: 1.65},
  {i: '5', x: 0, y: 4, w: 3, h: 1.65},
  {i: '6', x: 3, y: 4, w: 3, h: 1.65},
  {i: '7', x: 0, y: 6, w: 3, h: 1.65},
  {i: '8', x: 3, y: 6, w: 3, h: 1.65},
  {i: '9', x: 0, y: 8, w: 3, h: 1.65},
  {i: '10', x: 3, y: 8, w: 3, h: 1.65},
  {i: '11', x: 0, y: 10, w: 3, h: 1.65},
  {i: '12', x: 3, y: 10, w: 3, h: 1.65}
];

const layoutSmProject = [
  {i: '1', x: 0, y: 0, w: 3, h: 1.65},
  {i: '2', x: 3, y: 0, w: 3, h: 1.65},
  {i: '3', x: 0, y: 2, w: 3, h: 1.65},
  {i: '4', x: 3, y: 2, w: 3, h: 1.65},
  {i: '5', x: 0, y: 4, w: 3, h: 1.65},
  {i: '6', x: 3, y: 4, w: 3, h: 1.65},
  {i: '7', x: 0, y: 6, w: 3, h: 1.65},
  {i: '8', x: 3, y: 6, w: 3, h: 1.65},
  {i: '9', x: 0, y: 8, w: 3, h: 1.65},
  {i: '10', x: 3, y: 8, w: 3, h: 1.65},
  {i: '11', x: 0, y: 10, w: 3, h: 1.65},
  {i: '12', x: 3, y: 10, w: 3, h: 1.65}
];

const layoutXsProject = [
  {i: '1', x: 0, y: 0, w: 3, h: 1.90},
  {i: '2', x: 3, y: 0, w: 3, h: 1.90},
  {i: '3', x: 0, y: 2, w: 3, h: 1.90},
  {i: '4', x: 3, y: 2, w: 3, h: 1.90},
  {i: '5', x: 0, y: 4, w: 3, h: 1.90},
  {i: '6', x: 3, y: 4, w: 3, h: 1.90},
  {i: '7', x: 0, y: 6, w: 3, h: 1.90},
  {i: '8', x: 3, y: 6, w: 3, h: 1.90},
  {i: '9', x: 0, y: 8, w: 3, h: 1.90},
  {i: '10', x: 3, y: 8, w: 3, h: 1.90},
  {i: '11', x: 0, y: 10, w: 3, h: 1.90},
  {i: '12', x: 3, y: 10, w: 3, h: 1.90}
];

const layoutXxsProject = [
  {i: '1', x: 0, y: 0, w: 2, h: 1.85},
  {i: '2', x: 0, y: 2, w: 2, h: 1.85},
  {i: '3', x: 0, y: 4, w: 2, h: 1.85},
  {i: '4', x: 0, y: 6, w: 2, h: 1.85},
  {i: '5', x: 0, y: 8, w: 2, h: 1.85},
  {i: '6', x: 0, y: 10, w: 2, h: 1.85},
  {i: '7', x: 0, y: 12, w: 2, h: 1.85},
  {i: '8', x: 0, y: 14, w: 2, h: 1.85},
  {i: '9', x: 0, y: 16, w: 2, h: 1.85},
  {i: '10', x: 0, y: 18, w: 2, h: 1.85},
  {i: '11', x: 0, y: 20, w: 2, h: 1.85},
  {i: '12', x: 0, y: 22, w: 2, h: 1.85}
];

export const layoutsProject = {
  lg: layoutLgProject,
  md: layoutMdProject,
  sm: layoutSmProject,
  xs: layoutXsProject,
  xxs: layoutXxsProject
};

//KPI Cards Settings
const layoutLgKpiSettings = [
  {i: '1', x: 0, y: 0, w: 3, h: 2},
  {i: '2', x: 3, y: 0, w: 3, h: 2},
  {i: '3', x: 6, y: 0, w: 3, h: 2},
  {i: '4', x: 9, y: 0, w: 3, h: 2},
  {i: '5', x: 0, y: 2, w: 3, h: 2},
  {i: '6', x: 3, y: 2, w: 3, h: 2},
  {i: '7', x: 6, y: 2, w: 3, h: 2},
  {i: '8', x: 9, y: 2, w: 3, h: 2},
  {i: '9', x: 0, y: 4, w: 3, h: 2},
  {i: '10', x: 3, y: 4, w: 3, h: 2},
  {i: '11', x: 6, y: 4, w: 3, h: 2},
  {i: '12', x: 9, y: 4, w: 3, h: 2}
];

const layoutMdKpiSettings = [
  {i: '1', x: 0, y: 0, w: 3, h: 2},
  {i: '2', x: 3, y: 0, w: 3, h: 2},
  {i: '3', x: 6, y: 0, w: 3, h: 2},
  {i: '4', x: 0, y: 2, w: 3, h: 2},
  {i: '5', x: 3, y: 2, w: 3, h: 2},
  {i: '6', x: 6, y: 2, w: 3, h: 2},
  {i: '7', x: 0, y: 4, w: 3, h: 2},
  {i: '8', x: 3, y: 4, w: 3, h: 2},
  {i: '9', x: 6, y: 4, w: 3, h: 2},
  {i: '10', x: 0, y: 6, w: 3, h: 2},
  {i: '11', x: 3, y: 6, w: 3, h: 2},
  {i: '12', x: 6, y: 6, w: 3, h: 2}
];

const layoutSmKpiSettings = [
  {i: '1', x: 0, y: 0, w: 3, h: 2},
  {i: '2', x: 3, y: 0, w: 3, h: 2},
  {i: '3', x: 6, y: 0, w: 3, h: 2},
  {i: '4', x: 0, y: 2, w: 3, h: 2},
  {i: '5', x: 3, y: 2, w: 3, h: 2},
  {i: '6', x: 6, y: 2, w: 3, h: 2},
  {i: '7', x: 0, y: 4, w: 3, h: 2},
  {i: '8', x: 3, y: 4, w: 3, h: 2},
  {i: '9', x: 6, y: 4, w: 3, h: 2},
  {i: '10', x: 0, y: 6, w: 3, h: 2},
  {i: '11', x: 3, y: 6, w: 3, h: 2},
  {i: '12', x: 6, y: 6, w: 3, h: 2}
];

const layoutXsKpiSettings = [
  {i: '1', x: 0, y: 0, w: 2, h: 2.25},
  {i: '2', x: 2, y: 0, w: 2, h: 2.25},
  {i: '3', x: 0, y: 2, w: 2, h: 2.25},
  {i: '4', x: 2, y: 2, w: 2, h: 2.25},
  {i: '5', x: 0, y: 4, w: 2, h: 2.25},
  {i: '6', x: 2, y: 4, w: 2, h: 2.25},
  {i: '7', x: 0, y: 6, w: 2, h: 2.25},
  {i: '8', x: 2, y: 6, w: 2, h: 2.25},
  {i: '9', x: 0, y: 8, w: 2, h: 2.25},
  {i: '10', x: 2, y: 8, w: 2, h: 2.25},
  {i: '11', x: 0, y: 10, w: 2, h: 2.25},
  {i: '12', x: 2, y: 10, w: 2, h: 2.25}
];

const layoutXxsKpiSettings = [
  {i: '1', x: 0, y: 0, w: 2, h: 1.75},
  {i: '2', x: 0, y: 2, w: 2, h: 1.75},
  {i: '3', x: 0, y: 4, w: 2, h: 1.75},
  {i: '4', x: 0, y: 6, w: 2, h: 1.75},
  {i: '5', x: 0, y: 8, w: 2, h: 1.75},
  {i: '6', x: 0, y: 10, w: 2, h: 1.75},
  {i: '7', x: 0, y: 12, w: 2, h: 1.75},
  {i: '8', x: 0, y: 14, w: 2, h: 1.75},
  {i: '9', x: 0, y: 16, w: 2, h: 1.75},
  {i: '10', x: 0, y: 18, w: 2, h: 1.75},
  {i: '11', x: 0, y: 20, w: 2, h: 1.75},
  {i: '12', x: 0, y: 22, w: 2, h: 1.75}
];

export const layoutsKpiSettings = {
  lg: layoutLgKpiSettings,
  md: layoutMdKpiSettings,
  sm: layoutSmKpiSettings,
  xs: layoutXsKpiSettings,
  xxs: layoutXxsKpiSettings
};

//Settings
const layoutLgSettings = [
  {i: '1', x: 0, y: 0, w: 3, h: 1.5},
  {i: '2', x: 3, y: 0, w: 3, h: 1.5},
  {i: '3', x: 6, y: 0, w: 3, h: 1.5}
];

const layoutMdSettings = [
  {i: '1', x: 0, y: 0, w: 3, h: 1.5},
  {i: '2', x: 3, y: 0, w: 3, h: 1.5},
  {i: '3', x: 6, y: 0, w: 3, h: 1.5}
];

const layoutSmSettings = [
  {i: '1', x: 0, y: 0, w: 3, h: 1.5},
  {i: '2', x: 3, y: 0, w: 3, h: 1.5},
  {i: '3', x: 0, y: 2, w: 3, h: 1.5}
];

const layoutXsSettings = [
  {i: '1', x: 0, y: 0, w: 2, h: 1.5},
  {i: '2', x: 2, y: 0, w: 2, h: 1.5},
  {i: '3', x: 0, y: 2, w: 2, h: 1.5}
  
];

const layoutXxsSettings = [
  {i: '1', x: 0, y: 0, w: 2, h: 1.25},
  {i: '2', x: 0, y: 2, w: 2, h: 1.25},
  {i: '3', x: 0, y: 4, w: 2, h: 1.25}
];

export const layoutsSettings = {
  lg: layoutLgSettings,
  md: layoutMdSettings,
  sm: layoutSmSettings,
  xs: layoutXsSettings,
  xxs: layoutXxsSettings
};

//All Reports
const layoutLgAllReports = [
  {i: '1', x: 0, y: 0, w: 3, h: 1.5},
  {i: '2', x: 3, y: 0, w: 3, h: 1.5},
  {i: '3', x: 6, y: 0, w: 3, h: 1.5},
  {i: '4', x: 9, y: 0, w: 3, h: 1.5},
  {i: '5', x: 0, y: 2, w: 3, h: 1.5},
  {i: '6', x: 3, y: 2, w: 3, h: 1.5},
  {i: '7', x: 6, y: 2, w: 3, h: 1.5},
  {i: '8', x: 9, y: 2, w: 3, h: 1.5},
  {i: '9', x: 0, y: 4, w: 3, h: 1.5},
  {i: '10', x: 3, y: 4, w: 3, h: 1.5},
  {i: '11', x: 6, y: 4, w: 3, h: 1.5},
  {i: '12', x: 9, y: 4, w: 3, h: 1.5}
];

const layoutMdAllReports = [
  {i: '1', x: 0, y: 0, w: 3, h: 1.25},
  {i: '2', x: 3, y: 0, w: 3, h: 1.25},
  {i: '3', x: 6, y: 0, w: 3, h: 1.25},
  {i: '4', x: 0, y: 2, w: 3, h: 1.25},
  {i: '5', x: 3, y: 2, w: 3, h: 1.25},
  {i: '6', x: 6, y: 2, w: 3, h: 1.25},
  {i: '7', x: 0, y: 4, w: 3, h: 1.25},
  {i: '8', x: 3, y: 4, w: 3, h: 1.25},
  {i: '9', x: 6, y: 4, w: 3, h: 1.25},
  {i: '10', x: 0, y: 6, w: 3, h: 1.25},
  {i: '11', x: 3, y: 6, w: 3, h: 1.25},
  {i: '12', x: 6, y: 6, w: 3, h: 1.25}
];

const layoutSmAllReports = [
  {i: '1', x: 0, y: 0, w: 2, h: 1.5},
  {i: '2', x: 2, y: 0, w: 2, h: 1.5},
  {i: '3', x: 4, y: 0, w: 2, h: 1.5},
  {i: '4', x: 0, y: 2, w: 2, h: 1.5},
  {i: '5', x: 2, y: 2, w: 2, h: 1.5},
  {i: '6', x: 4, y: 2, w: 2, h: 1.5},
  {i: '7', x: 0, y: 4, w: 2, h: 1.5},
  {i: '8', x: 2, y: 4, w: 2, h: 1.5},
  {i: '9', x: 4, y: 4, w: 2, h: 1.5},
  {i: '10', x: 0, y: 6, w: 2, h: 1.5},
  {i: '11', x: 2, y: 6, w: 2, h: 1.5},
  {i: '12', x: 4, y: 6, w: 2, h: 1.5}
];

const layoutXsAllReports = [
  {i: '1', x: 0, y: 0, w: 2, h: 2},
  {i: '2', x: 2, y: 0, w: 2, h: 2},
  {i: '3', x: 0, y: 2, w: 2, h: 2},
  {i: '4', x: 2, y: 2, w: 2, h: 2},
  {i: '5', x: 0, y: 4, w: 2, h: 2},
  {i: '6', x: 2, y: 4, w: 2, h: 2},
  {i: '7', x: 0, y: 6, w: 2, h: 2},
  {i: '8', x: 2, y: 6, w: 2, h: 2},
  {i: '9', x: 0, y: 8, w: 2, h: 2},
  {i: '10', x: 2, y: 8, w: 2, h: 2},
  {i: '11', x: 0, y: 10, w: 2, h: 2},
  {i: '12', x: 2, y: 10, w: 2, h: 2}
];

const layoutXxsAllReports = [
  {i: '1', x: 0, y: 0, w: 2, h: 1.5},
  {i: '2', x: 0, y: 2, w: 2, h: 1.5},
  {i: '3', x: 0, y: 4, w: 2, h: 1.5},
  {i: '4', x: 0, y: 6, w: 2, h: 1.5},
  {i: '5', x: 0, y: 8, w: 2, h: 1.5},
  {i: '6', x: 0, y: 10, w: 2, h: 1.5},
  {i: '7', x: 0, y: 12, w: 2, h: 1.5},
  {i: '8', x: 0, y: 14, w: 2, h: 1.5},
  {i: '9', x: 0, y: 16, w: 2, h: 1.5},
  {i: '10', x: 0, y: 18, w: 2, h: 1.5},
  {i: '11', x: 0, y: 20, w: 2, h: 1.5},
  {i: '12', x: 0, y: 22, w: 2, h: 1.5}
];

export const layoutsAllReports = {
  lg: layoutLgAllReports,
  md: layoutMdAllReports,
  sm: layoutSmAllReports,
  xs: layoutXsAllReports,
  xxs: layoutXxsAllReports
};