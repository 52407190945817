import React, { Component } from "react";



class FooterContent extends Component {
  render() {
    const buildTimestamp = "2024-11-15 15:28:52";
    const buildTProductVersion = "8.1.49.202411-SNAPSHOT";
    return (
      <>

        <p className={this.props.useCopyrightClass ? "copyright" : ""} style={this.props.copyright}>
            <a
                className="font-weight-bold ml-1"
                href="http://knoabi.helpdocsonline.com/release_notes"
                target="_blank"
                style={this.props.copyrightA ? this.props.copyrightA : {}} >
                Version: ({buildTProductVersion} built at {buildTimestamp})
            </a>
            <br/>
          &copy; {new Date().getFullYear()}{" "}
            <a
                className="font-weight-bold ml-1"
                href="https://www.knoa.com"
                target="_blank"
                style={this.props.copyrightA ? this.props.copyrightA : {}}>
                Knoa Software
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
                className="font-weight-bold ml-1"
                href={process.env.REACT_APP_MAUI_DATA_PRIVACY_LINK}
                target="_blank"
                style={this.props.copyrightA ? this.props.copyrightA : {}}>
                Data Privacy
            </a>
        </p>
      </>
    )
  }
}

export default FooterContent;