import React, { Component } from "react";
import {Row, Col} from "react-bootstrap";
import CustomCheckbox from "../creativeTim/CustomCheckbox";
import {withTranslation} from "react-i18next";

class KnoaCardCheckbox extends Component {
  render() {
    const {
      id,
      isChecked,
      handleProjectReportsUpdate,
      t
    } = this.props;

    return(
      <>
        <Row className="topRow">
          <Col className="checkbox-card">
            <CustomCheckbox
              isChecked={isChecked}
              number={id}
              label={t('common:select')}
              inline={false}
              handleProjectReportsUpdate={handleProjectReportsUpdate}
            />
          </Col>
        </Row>
        <br />
      </>
    )
  }
}

export default withTranslation()(KnoaCardCheckbox);