import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//import Backend from 'i18next-xhr-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';

//https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
import en from './locales/en'; //English US (English US)
import zh from './locales/zh'; //简体中文 (Simplified Chinese)
import de from './locales/de'; //Deutsch (German)
import es from './locales/es'; //Español (Spanish)
import fr from './locales/fr'; //Français (French)
import ja from './locales/ja'; //日本語 (Japanese)
import ko from './locales/ko'; //한국어 (Korean)
import pt from './locales/pt'; //Português (Portuguese)
import ru from './locales/ru'; //Русский (Russian)

i18n
  //.use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    keySeparator: false,
    nsSeparator : ":",
    interpolation: {
      escapeValue: false
    },
    resources: {
      en,
      zh,
      de,
      es,
      fr,
      ja,
      ko,
      pt,
      ru
    },
    ns: [
      "language",
      "common",
      "kpiCard.name",
      "kpiCard.tooltip",
      "kpiCard.footer",
      "watchlist",
      "route.name",
      "report.name",
      "instanceReport.name",
      "report.category",
      "user",
      "preferences",
      "project",
      "adminSettings",
      "dialog",
      "messages.item",
      "messages.common",
      "messages.error",
      "messages.warning",
      "messages.success",
      "messages.info",
      "validation"
    ]
  });

export default i18n;