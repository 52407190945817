/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from "react";
import NotificationSystem from "react-notification-system";
import LoginNotificationStyles from "../../../components/common/LoginNotificationStyles";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {Redirect} from "react-router-dom";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import {withTranslation} from 'react-i18next';
import Button from "components/creativeTim/CustomButton.jsx";
import {API} from "lib/API"
import {getErrorMessagesFromCode, getErrorMsgFromStatus} from "../../../lib/messageHandler";
import {Helmet} from "react-helmet";
const localStorage = require('../../../lib/localStorage');
const key = require('../../../constant/key');

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.emailInput = null;
        this.passwordInput = null;
        this.checkParams = this.checkParams.bind(this);

        this.setEmailInputRef = element => {
            this.emailInput = element;
        };
        this.setPasswordInputRef = element => {
            this.passwordInput = element;
        };

        this.focusEmailInput = () => {
            // Focus the text input using the raw DOM API
            if (this.emailInput) this.emailInput.focus();
        };
        this.focusPasswordInput = () => {
            // Focus the text input using the raw DOM API
            if (this.passwordInput) this.passwordInput.focus();
        };

        this.submitLogin = this.submitLogin.bind(this);
        let _customers;
        if (this.props.location.state && this.props.location.state.customers) {
            _customers = this.props.location.state.customers;
        } else {
            _customers = null;
        }

        let _email;
        if (this.props.location.state && this.props.location.state.email) {
            _email = this.props.location.state.email;
        } else {
            _email = null;
        }
        
        this.state = {
            cardHidden: true,
            email: _email,
            password: PropTypes.string,
            emailState: PropTypes.string,
            passwordState: "valid",
            session: false,
            tableauToken: PropTypes.string,
            cards: PropTypes.array,
            passwordErrorLogin: null,
            emailErrorLogin: null,
            wrongPassword: null,
            wrongUser: null,
            buildDate: "11.15.2024",
            currentAnnouncements:[],
            customers: _customers,
            tfa: false,
            _notificationSystem: null,
            passwordExpired: false,
            passwordMin: -1,
            passwordMax: 64,
            updatedAccessToken: ""
        };
    }

    verifyEmail = value => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRex.test(value);
    };

    verifyLength = (value, length) => {
        return value.length >= length;
    };

    checkParams() {
        //valid, invalid_format, expired, invalid
        let state = this.parseURL('ssofail');
        if (state === 'true') {
            this.setState({
                wrongPassword: (
                    <small className="text-danger">
                        {this.props.t('messages.error:lockedAccountMessage')}
                    </small>
                ),
                wrongUser: null
            });
            window.history.replaceState({},"Knoa Analytics",'/#/auth/login');
        }
    }

    parseURL(key) {
        let value = '';
        const search = this.props.location.search; //"?valid=valid&token=eyJpZ..."
        const items = search.split('&'); //["?valid=valid","token=eyJpZ..."]
        let indexStart;
        for(let i=0; i<items.length; i++){
            if(items[i].indexOf(key)!==-1){
                indexStart = items[i].indexOf(key + '=')+key.length+1;
                value = items[i].substring(indexStart);
                break;
            }
        }
        return value;
    }

    change = (event, stateName, type) => {
        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({[stateName + "State"]: "valid"});
                    this.setState({emailErrorLogin: null});
                } else {
                    this.setState({[stateName + "State"]: "invalid"});
                    this.setState({
                        wrongUser: null,
                        wrongPassword: null
                    });
                    this.focusEmailInput();
                }
                break;

            default:
                break;
        }
        this.setState({[stateName]: event.target.value});
    };

    loginClick = () => {
        if (this.state.emailState !== "valid") {
            this.setState({emailState: "invalid"});
            this.setState({
                emailErrorLogin: (
                    <small className="text-danger">
                        Email is required and format should be <i>user@knoa.com</i>.
                    </small>
                )
            });
            this.focusEmailInput();
        }
        if (this.state.passwordState !== "valid") {
            this.setState({passwordState: "invalid"});
            this.setState({
                passwordErrorLogin: (
                    <small className="text-danger">
                        You must enter a password of at least 6 characters.
                    </small>
                )
            });
            this.focusPasswordInput();
        }
    };

    isValidated = () => {
        if (
            this.state.passwordState === "valid" &&
            this.state.emailState === "valid"
        ) {
            return true;
        } else {
            if (this.state.passwordState !== "valid") {
                this.setState({passwordState: "invalid"});
            }
            if (this.state.emailState !== "valid") {
                this.setState({emailState: "invalid"});
            }
            return false;
        }
    };

    loadNotifications = () =>{
        API.publicAnnouncement
            .get("/notification/public/announcement")
            .then(resp => {
                const {data} = resp.data;

                let currentAnnouncements = data.announcements.map(an => an.announcement);
                if (currentAnnouncements && currentAnnouncements.length > 0) {
                    currentAnnouncements.forEach(ans =>
                        this.props.handleAlertClick("error", "tc", ans, 300)
                    )
                }
            })
            .catch(e => {
                // no  need to display error
                console.error(e);
            });
    };

    componentDidMount() {
        window.sessionStorage.removeItem('redirectError');
        localStorage.set(key.LOGIN_PROGRESS, "true");
        this.setState({ _notificationSystem: this.refs.notificationSystem });
        this.loadNotifications();
        this.timerID = setInterval(this.loadNotifications, 300000);
        this.checkParams();

        if(localStorage.get("accessDenied401") === "true"){
            API.clearBeat();
            localStorage.remove("accessDenied401");
        }
        setTimeout(
            function () {
                this.setState({cardHidden: false});
            }.bind(this),
            700
        );
        this.focusEmailInput();
        if(API.isBeep()) {
            this.setState({ session: true });
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
        //localStorage.remove("accessDenied401");
    }

    updateTokens = ({accessToken, expiresIn}) => {
        API.updateTimeout({expiresIn});
        this.setState({session: true,
            updatedAccessToken: accessToken});
    };

    login() {
        //Trim password if character size is greater than max
        let password = this.state.password;
        let passwordMax = this.state.passwordMax;
        if(password.length > passwordMax) {
            password = password.substring(0,passwordMax);
        }

        API.user
            .post('/session/login', {
                email: this.state.email,
                password: password
            })
            .then(resp => {
                if(resp) {

                    let contentType = resp.headers['content-type'];
                    if(contentType.includes("html")) {
                        window.open(resp.request.responseURL, "_self")
                        return;
                    }

                    const {data: resData} = resp;
                    const {data} = resData;

                    this.setState({
                        wrongPassword: null,
                        wrongUser: null,
                        customers: data.customers,
                        tfa: data.tfa,
                    });

                    this.updateTokens({
                        accessToken: data.access,
                        expiresIn: data.expires
                    });
                }
            })
            .catch(e => {
                let resultCode = -1;
                if(e.data) {
                    resultCode = e.data.resultCode;
                    if(e.data.result === "INVALID_USERNAME_OR_PASSWORD" || e.data.result === "FAILED_LOGIN_USER_LOCKED") {
                        this.setState({
                            wrongPassword: (
                                <small className="text-danger">
                                    {this.props.t('messages.error:lockedAccountMessage')}
                                </small>
                            ),
                            wrongUser: null
                        });
                        this.focusPasswordInput();
                        console.error(e);
                    }
                    else if (e.data.result === "FAILED_PASSWORD_EXPIRED" || e.data.result=== "FAILED_INVALID_PASSWORD_RULE_VIOLATION") {
                        this.setState({
                            passwordExpired: true,
                            passwordMin: e.data.data.data.min,
                            passwordMax: e.data.data.data.max
                        });
                    } else if (e.data.result === "RESTRICTED_IPADDRESS") {
                        this.setState({
                            wrongPassword: (
                                <small className="text-danger">
                                    {this.props.t('messages.error:restrictedIp', {ip: e.data.data.data})}
                                </small>
                            ),
                            wrongUser: null
                        });
                        this.focusPasswordInput();
                        console.error(e);
                    } else if (resultCode > -1)
                        this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                } else {
                    console.error(e);
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
                }
            });
    };

    submitLogin = e => {
        e.preventDefault();
        this.loginClick();
        if (!this.isValidated()) return;
        this.login();
    };

    render() {
        if(this.state.passwordExpired) {
            return <Redirect to={{
                pathname: '/auth/resetPassword',
                state: {
                    isPasswordExpired: true,
                    email: this.state.email,
                    passwordMin: this.state.passwordMin,
                    passwordMax: this.state.passwordMax
                }
            }}/>
        }
        else if (this.state.session === true) {
            if(this.state.customers) {
                let proxyPros ={};
                proxyPros.email = this.state.email;
                proxyPros.customers = this.state.customers;
                window.sessionStorage.setItem("proxyProps", JSON.stringify(proxyPros));
                return <Redirect to={{
                    pathname: '/admin/customerSupport',
                    state: {
                        email: this.state.email,
                        customers: this.state.customers
                    }
                }}/>
            } else if(this.state.tfa){
                return <Redirect to={{
                    pathname: '/auth/twoFactorAuthentication',
                    state: {
                        email: this.state.email,
                        tfa: this.state.tfa,
                        accessToken: this.state.updatedAccessToken,
                        password: this.state.password,

                    }
                }}/>
            } else {
                return <Redirect to={{pathname: '/admin/home'}}/>
            }
        }
        return (
            <Grid>
                <Helmet>
                    <title>Knoa Analytics - Login</title>
                </Helmet>
                <Row>
                    <NotificationSystem ref="notificationSystem" style={LoginNotificationStyles} />
                </Row>
                <Row>
                    <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                        <form onSubmit={this.submitLogin}>
                            <Card
                                hidden={this.state.cardHidden}
                                textCenter
                                title="Knoa Analytics Login"
                                content={
                                    <div>
                                        <FormGroup>
                                            <ControlLabel htmlFor="Username">Username</ControlLabel>
                                            <FormControl
                                                name="Username"
                                                id="Username"
                                                placeholder="email"
                                                type="email"
                                                onChange={e => this.change(e, "email", "email")}
                                                onFocus={() => this.setState({focusedEmail: true})}
                                                onBlur={() => this.setState({focusedEmail: false})}
                                                inputRef={this.setEmailInputRef}
                                                aria-required="true"
                                            />
                                            {this.state.emailErrorLogin}
                                            {this.state.wrongUser}
                                            
                                        </FormGroup>
                                        <FormGroup>
                                            <ControlLabel htmlFor="Password">Password</ControlLabel>
                                            <FormControl
                                                name="Password"
                                                id="Password"
                                                placeholder="password"
                                                type="password"
                                                autoComplete="off"
                                                onChange={e => this.change(e, "password", "password")}
                                                onFocus={() =>
                                                    this.setState({focusedPassword: true})
                                                }
                                                onBlur={() =>
                                                    this.setState({focusedPassword: false})
                                                }
                                                inputRef={this.setPasswordInputRef}
                                                aria-required="true"
                                            />
                                            {this.state.passwordErrorLogin}
                                            {this.state.wrongPassword}
                                        </FormGroup>
                                    </div>
                                }
                                legend={
                                    <>
                                        <Button
                                            bsStyle="info"
                                            fill
                                            wd
                                            type="submit">
                                            Login
                                        </Button>
                                        <br></br>
                                        <Link className='loginLink' to={{pathname: "/auth/forgotPassword"}}>Forgot Password</Link>
                                    </>
                                }
                                ftTextCenter
                            />
                        </form>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default withTranslation()(LoginPage);