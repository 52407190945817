import React, {Component} from "react";
import {Grid, Row, Col} from "react-bootstrap";
import ProjectContainer from "../../../components/Project/ProjectContainer";
import {API} from "../../../lib/API";
import ModalDialog from "../../../components/common/ModalDialog";
import Select from "react-select";
import CustomSelectStyles from "../../../components/common/CustomSelectStyles";
import {validateAll, validations} from "indicative/validator";
import {withTranslation} from 'react-i18next';
import Card from "../../../components/Card/Card";
import Spinner from "../../../components/common/Spinner";
import {Redirect} from "react-router-dom";
import {
    getErrorMessagesFromCode,
    getErrorMsgFromStatus,
    getInfoMessages,
    getSpecificOPErrorMessages,
    getSuccessMessages
} from "../../../lib/messageHandler";
import {Helmet} from "react-helmet";
const localStorage = require('../../../lib/localStorage');


class Projects extends Component {
    constructor(props) {
        super(props);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDeleteProject = this.handleDeleteProject.bind(this);
        this.handleShareShow = this.handleShareShow.bind(this);
        this.handleShareProjectUpdate = this.handleShareProjectUpdate.bind(this);
        this.handleShareProjectConfirm = this.handleShareProjectConfirm.bind(this);
        this.getProjectList = this.getProjectList.bind(this);

        this.mainContent = React.createRef();

        this.state = {
            myProjects: [],
            allProjects: [],
            showModal: false,
            currentProject: {},
            showShare: false,
            users: [],
            selectedUsers: [],
            formErrors: "",
            fetchInProgress: "false",
            loaded: false,
            showLoggedoutModal: false,
            sessionTerminated: false,
            setMainFocus: false
        };
    }

    handleClose() {
        this.setState({
            showModal: false,
            showShare: false,
            formErrors: ""
        });
    }

    handleLogoutConfirm = () =>{
        this.setState({sessionTerminated: true});
    };


    handleShow(e, id) {
        e.preventDefault();
        const info = id.split("_-$-_");
        const cProject = {};
        cProject.id = info[0];
        cProject.name = info[1];
        cProject.shared = info[3];
        this.setState({
            currentProject: cProject,
            showModal: true
        });
    }

    handleShareShow(e, id) {
        e.preventDefault();
        const info = id.split("_-$-_");
        const cProject = {};
        cProject.id = info[0];
        cProject.name = info[1];
        cProject.shared = info[3];
        API.project
            .get(`/project/view/share/${info[0]}`)
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;
                this.setState({
                    users: data.users,
                    selectedUsers: data.users.filter(user => {
                        return user.selected === true
                    }),
                    currentProject: cProject,
                    showShare: true,
                });
            })
            .catch(e => {
                console.error(e);
                if(localStorage.get("accessDenied401") === "true"){
                    this.setState({showLoggedoutModal:true});
                    return;
                }
                let resultCode = -1;
                if(e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"

                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    }

    handleDeleteProject() {
        this.setState({fetchInProgress: "true"});

        let _this = this;
        API.project
            .delete(`/project/${_this.state.currentProject.id}`)
            .then(resp => {
                console.log(`The project ${_this.state.currentProject.name} is deleted!`);

                let inSupport = (resp && resp.data) ? resp.data.inSupport : false;
                this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t,'generic', 'The project was deleted!'), inSupport);

                const updatedMyProjects = _this.state.myProjects.filter(project => project.id.toString() !== _this.state.currentProject.id);
                const updatedAllProjects = _this.state.allProjects.filter(project => project.id.toString() !== _this.state.currentProject.id);

                _this.setState({
                    currentProject: {},
                    showModal: false,
                    myProjects: updatedMyProjects,
                    allProjects: updatedAllProjects,
                    fetchInProgress: localStorage.get("isLoading")
                });
            })
            .catch(e => {
                console.error(e);
                this.setState({fetchInProgress: localStorage.get("isLoading")});
                if(localStorage.get("accessDenied401") === "true"){
                    this.setState({showLoggedoutModal:true});
                    return;
                }
                let resultCode = -1;
                if(e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"

                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));

            })
    }

    handleShareProjectUpdate = (selectedUsers) => {
        this.setState({selectedUsers: selectedUsers})
    };

    handleShareProjectConfirm = () => {
        let usersToShare = {};
        let _this = this;
        const {t} = this.props;

        let selectedUserIDs = [];
        _this.state.selectedUsers.forEach(opt => {
            selectedUserIDs.push(opt.id);
        });

        usersToShare.team = selectedUserIDs;

        const data = {
            team: selectedUserIDs.toString()
        };
        const rules = {
            team: [validations.required()]
        };
        const messages = {
            required: t('validation:cardMultiSelect')
        };

        validateAll(data, rules, messages)
            .then(() => {
                this.setState({fetchInProgress: "true"});
                API.project
                    .post(`/project/share/${_this.state.currentProject.id}`, usersToShare)
                    .then(resp => {
                        const {data: resData} = resp;
                        this.getProjectList();
                        console.log(`The projected was shared successfully! \n` +
                            JSON.stringify(this.state.selectedUsers)
                        );
                        _this.handleClose();
                        let inSupport = resData ? resData.inSupport : false;
                        this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t,'generic',  "The project was shared."), inSupport);
                        this.setState({fetchInProgress: localStorage.get("isLoading")});

                    })
                    .catch(e => {
                        console.error(e);
                        this.setState({fetchInProgress: localStorage.get("isLoading")});
                        if(localStorage.get("accessDenied401") === "true"){
                            this.setState({showLoggedoutModal:true});
                            return;
                        }
                        if(e.data && e.data.inSupport && e.data.resultCode)
                            this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, e.data.resultCode, true));
                        else
                            this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'genericPleaseTryAgain',  "The project was not shared!",null));
                    });
            }).catch(errors => {
            console.log(errors);
            const formattedErrors = {};
            let errorClass = "";
            errors.forEach(error => {
                formattedErrors[error.field] = error.message;
                errorClass = "form-validation-input-error"
            });
            _this.setState({
                formErrors: formattedErrors
            });
        });
    };

    getProjectList() {
        let _this = this;
        API.project
            .get("/project/list")
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;
                _this.setState({
                    myProjects: data.userProjects,
                    allProjects: data.sharedProjects,
                    loaded: true,
                    setMainFocus: true
                });
            })
            .catch(e => {
                console.error(e);
                if(localStorage.get("accessDenied401") === "true"){
                    this.setState({showLoggedoutModal:true});
                    return;
                }
                let resultCode = -1;
                if(e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"

                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    }

    componentDidMount() {
        this.getProjectList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.setMainFocus) {
            if (this.mainContent && this.mainContent.current) {
                this.mainContent.current.focus();
                this.setState({setMainFocus: false})
            }
        }
    }

    render() {
        if(this.state.sessionTerminated)
            return <Redirect to={{pathname: '/auth/logout'}}/>;

        let showLoggedOutWindow = this.state.showLoggedoutModal;

        if (!this.state.loaded && !showLoggedOutWindow) {
            return null;
        }

        const {t} = this.props;

        return (
            <div className="main-content" ref={this.mainContent} tabIndex={"-1"}>
                <Helmet>
                    <title>Knoa Analytics - Projects</title>
                </Helmet>
                <Grid fluid>
                    <ModalDialog
                        showModal={showLoggedOutWindow}
                        title={"Log Out"}
                        contentBody={
                            <span>{getInfoMessages(this.props.t, 'logoutfor401',null,null)}</span>
                        }
                        bodyPaddingBottom='15px'
                        footerBtnBsStyle="success"
                        footerBtnClass="KnoaSuccessButton"
                        footerBtnOnClick={this.handleLogoutConfirm}
                        footerBtnText={this.props.t('common:ok')}
                    />
                    <ModalDialog
                        showModal={this.state.showModal}
                        handleClose={this.handleClose}
                        title={t('dialog:deleteProjectTitle')}
                        footerBtnBsStyle="danger"
                        footerBtnClass="KnoaDangerButton"
                        footerBtnOnClick={this.handleDeleteProject}
                        footerBtnText={this.props.t('common:delete')}
                        footerBtnCloseText={this.props.t('common:cancel')}
                        contentBody={
                            <Card
                              content={
                                  <>
                                      <span>{t('dialog:deleteProjectPrompt', {project: this.state.currentProject.name})}</span>
                                      {this.state.currentProject.shared === "true" &&
                                      <>
                                          <br/><br/>
                                          <div className="projects-dialog-delete-box">
                                              <i className="fa fa-exclamation-triangle projects-dialog-delete-box-icon"/>
                                              <span>{t('dialog:deleteProjectWarn')}</span>
                                          </div>
                                      </>
                                      }
                                  </>
                              }
                            />
                        }
                    />
                    <ModalDialog
                        showModal={this.state.showShare}
                        handleClose={this.handleClose}
                        title={t('dialog:shareProject')}
                        footerBtnBsStyle="success"
                        footerBtnClass="KnoaSuccessButton"
                        footerBtnOnClick={this.handleShareProjectConfirm}
                        footerBtnText={t('common:share')}
                        footerBtnCloseText={t('common:cancel')}
                        formErrors={this.state.formErrors}
                        contentBody={
                            <Card
                                title={t('user:analyticsUsers')}
                                category={t('dialog:shareWithUsersPrompt')}
                                content={
                                    <Col className="projects-dialog-share-content">
                                        <Select
                                          styles={CustomSelectStyles}
                                          placeholder={t('common:selectPlaceholder')}
                                          value={this.state.selectedUsers}
                                          options={this.state.users}
                                          getOptionLabel={(option) => option.value}
                                          getOptionValue={(option) => option.id}
                                          onChange={this.handleShareProjectUpdate}
                                          isMulti
                                          isSearchable={false}/>
                                    </Col>
                                }
                            />
                        }
                    />
                    {this.state.fetchInProgress==="true" && <Spinner/>}
                    <div hidden={this.state.fetchInProgress==="true"}>
                    <ProjectContainer
                        myProjects={this.state.myProjects}
                        allProjects={this.state.allProjects}
                        handleShow={this.handleShow}
                        handleShareShow={this.handleShareShow}
                    />
                    </div>
                </Grid>
            </div>
        );
    }
}

export default withTranslation()(Projects);