const CustomStyles = {

    control: provided => {
        //console.log("--Provided internals", provided); // let's console provided and return object as before
        return {
            ...provided,
            backgroundColor: "white",
            //height: "32px",
            minHeight:"30px",
            borderRadius: "0px",
            
        };
    },

    container: (provided) =>{
        return {
            ...provided,
            backgroundColor: "white",
            minHeight: '30px',
        };
    },

    // group: (provided) =>{
    //     return {
    //         ...provided,
    //         backgroundColor: "green",
    //         minHeight: '30px',
    //     };
    // },

    valueContainer: (provided, state) => ({
        ...provided,
        minHeight: "30px",
        // padding: "0 6px"
    }),

    indicatorsContainer: (provided) => {
        //console.log("--Provided  indicatorsContainer", provided);
        return {
            ...provided,
            minHeight: "30px",
        };
    },

    
    placeholder: (provided) => {
        //console.log("--Provided  placeholder", provided);
        return {
            ...provided,
            color: "black",
            fontSize: "12px"
        };
    },
    indicatorSeparator: (provided) => {
        //console.log("--Provided  indicatorSeparator", provided);
        return {
            ...provided,
            backgroundColor: "white",
        };
    },

    dropdownIndicator: (provided) => {
        //console.log("--Provided  dropdownIndicator", provided);
        return {
            ...provided,
            color: "white",
            display: "none",
            paddingTop: 0,
            paddingBottom: 0,
        };
    },

    clearIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    
    multiValueLabel: (provided) => {
        //console.log("--Provided multiValueLabel", provided);
        return {
            ...provided,
            color: "black !important",
            fontSize: "12px",
            borderRadius:"0px",
            backgroundColor: "",
            padding: "0px",
            marginTop: "0px",
            marginBottom:"0px"

        };
    } ,

    singleValue: (provided) => {
        //console.log("--Provided multiValue", provided);
        return {
            ...provided,
            color: "black !important",
            fontSize: "12px",
            borderRadius:"0px",
            backgroundColor: "",
            padding: "0px",
            marginTop: "0px",
            marginBottom:"0px"


        };
    },

    multiValue: (provided) => {
        //console.log("--Provided multiValue", provided);
        return {
            ...provided,
            backgroundColor: "",
            padding: "0px",
            marginTop: "0px",
            marginBottom:"0px"

        };
    },

    multiValueRemove: (provided) => {
        //console.log("--Provided multiValueRemove", provided);
        return {
            ...provided,
            color: "black",

        };
    },

    input: (provided, state) => ({
        ...provided,
        height: '12px', 
        padding: "0px",
        marginTop: "0px",
        marginBottom:"0px"
    }),

    option: (provided, state) => ({
        ...provided,
        fontfamily: 'font-family: Arial, Helvetica,sans-serif !important',
        fontSize: '12px'
    }),
};

export default CustomStyles;
