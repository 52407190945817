import React, {Component} from "react";
import { DropdownButton, MenuItem } from 'react-bootstrap';
import {withTranslation} from "react-i18next";

class KnoaDropdown extends Component {
  constructor(prop) {
    super(prop);

    this.onToggle = this.onToggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.state = {
      isOpen: false
    };
  }

  onToggle = (_isOpen, _event, _source) => {
    if(_isOpen) {
      //Caret handling
      const dropdownElements = document.getElementsByClassName("dropdownContainer");
      for(const dropdown in dropdownElements) {
        const children = dropdownElements[dropdown].children;
        if(children) {
          for(const child of children) {
            if(child.classList.contains('caret')) {
              child.classList.add("caretSelected");
            }
          }
        }
      }
    } else {
      //Caret handling
      if(!this.isDrillEnabled()) {
        const dropdownElements = document.getElementsByClassName("dropdownContainer");
        for (const dropdown in dropdownElements) {
          const children = dropdownElements[dropdown].children;
          if (children) {
            for (const child of children) {
              if (child.classList.contains('caretSelected')) {
                child.classList.remove("caretSelected");
              }
            }
          }
        }
      }
    }

    //Backup to update dropdown class
    this.updateKnoaDropdownClass();

    this.setState({ isOpen: _isOpen })
  }

  onMouseEnter = (e) => {
    //Caret handling
    const dropdownElements = document.getElementsByClassName("dropdownContainer");
    for(const dropdown in dropdownElements) {
      const children = dropdownElements[dropdown].children;
      if(children) {
        for(const child of children) {
          if(child.classList.contains('caret')) {
            child.classList.add("caretSelected");
          }
        }
      }
    }

    //Backup to update dropdown class
    this.updateKnoaDropdownClass();

    this.setState({ isOpen: true })
  };

  onMouseLeave = (e) => {
    //Caret handling
    if(!this.isDrillEnabled()) {
      const dropdownElements = document.getElementsByClassName("dropdownContainer");
      for (const dropdown in dropdownElements) {
        const children = dropdownElements[dropdown].children;
        if (children) {
          for (const child of children) {
            if (child.classList.contains('caretSelected')) {
              child.classList.remove("caretSelected");
            }
          }
        }
      }
    }

    this.setState({ isOpen: false })
  };

  isDrillEnabled() {
    if(!this.props.instanceWorkbookId) {
      return false;
    }

    const btnDrill = document.getElementById('btnDrill'+this.props.instanceWorkbookId);
    if (btnDrill) {
      const parentLi = btnDrill.parentElement;
      if (parentLi && !parentLi.classList.contains('disabled')) {
        return true;
      }
    }
    return false;
  };

  updateKnoaDropdownClass() {
    const dropdownEl = document.getElementById('knoaDropdown');
    if(dropdownEl) {
      if(dropdownEl.classList.contains('dropdownContainerHide')){
        dropdownEl.classList.remove('dropdownContainerHide');
      }
      if(!dropdownEl.classList.contains('dropdownContainerShow')){
        dropdownEl.classList.add('dropdownContainerShow');
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    //Update dropdown after drilling
    if(prevProps.currentWorkbook.id!==this.props.currentWorkbook.id){
      const btnDrill = document.getElementById('btnDrill'+this.props.instanceWorkbookId);
      if (btnDrill) {
        const parentLi = btnDrill.parentElement;
        if (parentLi && !parentLi.classList.contains('disabled')) {
          parentLi.classList.add('disabled');
        }
      }

      //Caret handling
      const dropdownElements = document.getElementsByClassName("dropdownContainer");
      for (const dropdown in dropdownElements) {
        const children = dropdownElements[dropdown].children;
        if (children) {
          for (const child of children) {
            if (child.classList.contains('caretSelected')) {
              child.classList.remove("caretSelected");
            }
          }
        }
      }
      const drillToReportBtn = document.getElementById('knoaDropdown');
      if (drillToReportBtn) {
        if (drillToReportBtn.classList.contains('dropdownContainerShow')) {
          drillToReportBtn.classList.remove('dropdownContainerShow');
        }
        if (!drillToReportBtn.classList.contains('dropdownContainerHide')) {
          drillToReportBtn.classList.add('dropdownContainerHide');
        }
      }
      this.setState({ isOpen: false })
    }
  }

  render() {
    const {currentWorkbook, icon, instanceWorkbookId, dataRangeType} = this.props;
    let {options} = this.props;
    const {t} = this.props;
    let classValue = 'dropdownContainer';
    if(this.state.isOpen || this.isDrillEnabled()) {
      classValue = classValue + ' dropdownContainerShow';
    }
    else {
      classValue = classValue + ' dropdownContainerHide';
    }

    if(dataRangeType==='instance') {
      options = options.filter(item => item.id==="btnDrill"+instanceWorkbookId);
    }

    return (
      <DropdownButton
        pullRight
        title={
          <>
            {icon && <i className={icon + " knoaButtonIcon"}/>}
            <span className="pressText">{t('report.filter:drillToReport')}</span>
          </>
        }
        key="1"
        id="knoaDropdown"
        className={classValue}
        open={this.state.isOpen}
        onToggle={this.onToggle}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {options.map((option, index) => (
            <MenuItem
                key={index}
                eventKey={index}
                className="pressText"
                disabled={option.disabled}
                id={option.id}
                onClick={option.onClick}
            >
              {currentWorkbook['workbookName'].endsWith("instances") ? t('instanceReport.name:' + option.workflowReportName): t('instanceReport.name:' + option.instanceReportName) }
            </MenuItem>
        ))}
      </DropdownButton>
    );
  }
}

export default withTranslation()(KnoaDropdown);