import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import {withTranslation} from "react-i18next";
import {MenuItem} from "react-bootstrap";

class KnoaNavDropdown extends Component {
  constructor(prop) {
    super(prop);

    this.onToggle = this.onToggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.state = {
      isOpen: false
    };
  }

  onToggle = (_isOpen, _event, _source) => {
    if(_isOpen) {
      //Caret handling
      const dropdownElements = document.getElementsByClassName("dropdownNavContainer");
      for(const dropdown in dropdownElements) {
        const children = dropdownElements[dropdown].children;
        if(children) {
          for(const child of children) {
            if(child.classList.contains('caret')) {
              child.classList.add("caretSelected");
            }
          }
        }
      }

      this.setState({ isOpen: true })
    } else {
      //Caret handling
      const dropdownElements = document.getElementsByClassName("dropdownNavContainer");
      for(const dropdown in dropdownElements) {
        const children = dropdownElements[dropdown].children;
        if(children) {
          for(const child of children) {
            if(child.classList.contains('caretSelected')) {
              child.classList.remove("caretSelected");
            }
          }
        }
      }

      this.setState({ isOpen: false })
    }
  }

  onMouseEnter = (e) => {
    //Caret handling
    const dropdownElements = document.getElementsByClassName("dropdownNavContainer");
    for(const dropdown in dropdownElements) {
      const children = dropdownElements[dropdown].children;
      if(children) {
        for(const child of children) {
          if(child.classList.contains('caret')) {
            child.classList.add("caretSelected");
          }
        }
      }
    }

    this.setState({ isOpen: true })
  };

  onMouseLeave = (e) => {
    //Caret handling
    const dropdownElements = document.getElementsByClassName("dropdownNavContainer");
    for(const dropdown in dropdownElements) {
      const children = dropdownElements[dropdown].children;
      if(children) {
        for(const child of children) {
          if(child.classList.contains('caretSelected')) {
            child.classList.remove("caretSelected");
          }
        }
      }
    }

    this.setState({ isOpen: false })
  };

  render() {
    const {options} = this.props;
    const {t} = this.props;

    return (
      <DropdownButton
        pullRight
        title={
            <span className="pressText">{t('common:more')}</span>
        }
        key="1"
        id="knoaDropdown-more"
        className={this.state.isOpen ? "dropdownNavContainer dropdownNavContainerShow" : "dropdownNavContainer dropdownNavContainerHide"}
        open={this.state.isOpen}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onToggle={this.onToggle}
      >
        {options.map((option, index) =>
            option.divider ? ( <MenuItem divider /> ) : (
              <li role="presentation" key={index}>
                <NavLink
                    to={{
                      pathname: option.href,
                      state: {
                        id: option.id,
                        newProject: option.newProject ? option.newProject : null,
                        preSelectReportID: option.preSelectReportID ? option.preSelectReportID : null
                      }
                    }}
                    onClick={option.onClick}
                    role="menuitem"
                    tabIndex={"-1"}
                >
                  <span className="pressText navItemText">{option.name}</span>
                </NavLink>
              </li>
            )
        )}
      </DropdownButton>
    );
  }
}

export default withTranslation()(KnoaNavDropdown);