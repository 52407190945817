/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {validateAll} from 'indicative/validator';
import Card from "components/Card/Card.jsx";
import FormInputs from "components/creativeTim/FormInputs.jsx";
import {API} from "lib/API";
import Footer from "../../../components/layout/Footer";
import {withTranslation} from 'react-i18next';
import KnoaBreadcrumb from "../../../components/common/KnoaBreadcrumb";
import {mauiRoutes} from "../../../routes";
import {capitalizeWords} from "../../../lib/common";
import CommonContainer from "../../../components/common/CommonContainer";
import Spinner from "../../../components/common/Spinner";
import {Redirect} from "react-router-dom";
import ModalDialog from "../../../components/common/ModalDialog";
import {
    getErrorMessagesFromCode,
    getErrorMsgFromStatus,
    getInfoMessages,
    getSpecificOPErrorMessages,
    getSuccessMessages
} from "../../../lib/messageHandler";
import {Helmet} from "react-helmet";

const localStorage = require('../../../lib/localStorage');
const FormErrors = ({formErrors, fieldName}) =>
    <div className='row formErrors'>
        <p className={fieldName === "lastName" ? "form-validation-error-lastName" : "form-validation-error"} aria-label={formErrors[fieldName]}> {formErrors[fieldName]}</p>
    </div>

class UserAccount extends Component {
    constructor(prop) {
        super(prop);

        this.mainContent = React.createRef();

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            orignalEmail: '',
            showEmailChange: false,
            knoaRoles: [],
            role: '',
            proxyUserMode: true,
            licenses: [],   // no use will delete
            userId: this.props.location.state ? this.props.location.state.id : -1,
            formErrors: {},
            formValid: false,
            inputClass_firstName: 'form-control',
            inputClass_lastName: 'form-control',
            inputClass_email: 'form-control',
            fetchInProgress: "false",
            redirect: false,
            loaded: false,
            disableSaveButton: false,
            showLoggedoutModal: false,
            sessionTerminated: false,
            setMainFocus: false
        };
    }

    submitData() {
        if (this.state.formValid) {
            this.setState({fetchInProgress: "true"});

            let userSubmitURL = '';
            let createUser = this.props.location.state ? this.props.location.state.createUser : false;
            if (createUser) userSubmitURL = '/admin/user/create';
            else userSubmitURL = `/admin/user/edit/${this.state.userId}`;

            API.admin
                .post(userSubmitURL, {
                    "firstName": this.state.firstName,
                    "lastName": this.state.lastName,
                    "email": this.state.email,
                    // "iniPassword": this.state.password,
                    "role": this.state.role,
                    "needSupport": this.state.proxyUserMode
                })
                .then(resp => {
                    const {message, resultCode} = resp.data;
                    let inSupport = resp.data ? resp.data.inSupport : false;
                    if (createUser) {
                        if (resultCode === 200) {
                            this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t, "generic","a new user was created!"), inSupport);
                        } else {
                            this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'genericPleaseTryAgain',  "A new user was not created!", resultCode));
                        }
                        this.setState({
                            fetchInProgress: localStorage.get("isLoading"),
                            redirect: true
                        });
                    } else {
                        if (resultCode === 200) {
                            this.setState({orginalEmail: this.state.email});
                            this.props.handleAlertClick("success", "tc",getSuccessMessages(this.props.t,"changeSaved",'User account' ), inSupport);
                        } else if (resultCode === 2004)
                            this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'generic', "There are not enough licenses for this role.", resultCode));
                        else {
                            this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'changeNotSaved',  'User account', resultCode));
                        }
                        this.setState({fetchInProgress: localStorage.get("isLoading")});
                    }
                })
                .catch(e => {
                    console.error(e);
                    this.setState({fetchInProgress: localStorage.get("isLoading")});
                    if(localStorage.get("accessDenied401") === "true"){
                        this.setState({showLoggedoutModal:true});
                        return;
                    }
                    let resultCode = -1;
                    if (e.data) {
                        resultCode = e.data.resultCode;
                    }
                    if (e.status === 401 || resultCode === 6007)
                        window.location.href = "/#/auth/logout"
                    if (resultCode > -1)
                        this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                    else
                        this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
                });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        localStorage.set("proceedToSave", "true");
        let createUser = this.props.location.state ? this.props.location.state.createUser : false;

        const data = this.state;
        let rules = {};
        let messages = {};
        if (createUser) {
            rules = {
                firstName: 'required|string',
                lastName: 'required|string',
                email: 'required|email'
            };
            messages = {
                'firstName.required': this.props.t('validation:fieldRequired', {field: 'firstName'}),
                'lastName.required': this.props.t('validation:fieldRequired', {field: 'lastName'}),
                'email.required': this.props.t('validation:fieldRequired', {field: 'email'}),
                'email.email': this.props.t('validation:fieldValidationFail', {field: 'email'})
            };
        } else {
            rules = {
                firstName: 'required|string',
                lastName: 'required|string',
                email: 'required|email'
            };
            messages = {
                'firstName.required': this.props.t('validation:fieldRequired', {field: 'firstName'}),
                'lastName.required': this.props.t('validation:fieldRequired', {field: 'lastName'}),
                'email.required': this.props.t('validation:fieldRequired', {field: 'email'}),
                'email.email': this.props.t('validation:fieldValidationFail', {field: 'email'})
            };
        }

        validateAll(data, rules, messages,)
            .then(() => {
                this.setState({formErrors: ''});
                this.setState({
                    inputClass_firstName: 'form-control',
                    inputClass_lastName: 'form-control',
                    inputClass_email: 'form-control'
                });
                this.setState({formValid: true});
                if (!createUser) this.handleEmailChange();
               // if(this.state.proceedToSave)
                if(localStorage.get('proceedToSave') === "true")
                this.submitData();
            })
            .catch(errors => {
                const formattedErrors = {};
                const errorFields = [];
                const allFields = ['inputClass_firstName', 'inputClass_lastName', 'inputClass_email'];
                errors.forEach(error => {
                    formattedErrors[error.field] = error.message;
                    errorFields.push('inputClass_' + error.field);
                    if (error.field === 'firstName') {
                        this.setState({inputClass_firstName: 'form-control form-validation-input-error'});
                    } else if (error.field === 'lastName') {
                        this.setState({inputClass_lastName: 'form-control form-validation-input-error'});
                    } else if (error.field === 'email') {
                        this.setState({inputClass_email: 'form-control form-validation-input-error'});
                    }

                });
                allFields.filter((field) => {
                    if (!errorFields.includes(field)) {
                        this.setState({[field]: 'form-control'});
                    }
                });
                this.setState({formErrors: formattedErrors,
                    formValid: false});
                this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t, 'validationErrorMessage',null,null ));

            });
    };

    updateRole = (e) => {
        this.setState({role: e.target.value});
    };

    updateProxyUserMode = (e) => {
        let enabled = e.target.value === 'Enabled';
        this.setState({proxyUserMode: enabled});
    };

    updateProxyUserModeForEditUser = (e) => {
        let enabled = e.target.value === 'true';
        this.setState({proxyUserMode: enabled});
    };

    handleChange = (e) => {
        e.preventDefault();
        this.setState({[e.target.name]: e.target.value});
    };
    

    handleEmailChange = () => {
        if (this.state.email !== this.state.orginalEmail) {
            localStorage.set("proceedToSave", "false");
            this.setState({
                showEmailChange: true,
                inputClass_email: 'form-control',
                disableSaveButton: true
            }); 
        } else
            this.setState({
                inputClass_email: 'form-control',
                disableSaveButton: false
            });
    };

    handleEmailChangeConfirm = () => {
        let tempFormErrors = this.state.formErrors;
        if (tempFormErrors !== '') tempFormErrors['email'] = '';
        localStorage.set("proceedToSave", "true");
        this.setState({
            showEmailChange: false,
            formErrors: tempFormErrors,
            inputClass_email: 'form-control',
            disableSaveButton: false,
        })
        this.submitData();
    };

    handleClose = () => {
        let tempFormErrors = this.state.formErrors;
        if (tempFormErrors !== '') tempFormErrors['email'] = '';
        localStorage.set("proceedToSave", "false");
        this.setState({
            showEmailChange: false,
            email: this.state.orginalEmail,
            formErrors: tempFormErrors,
            disableSaveButton: false
        });
    };

    handleLogoutConfirm = () =>{
        this.setState({sessionTerminated: true});
    };


    componentDidMount() {
        let userURL = "";
        let createUser = this.props.location.state ? this.props.location.state.createUser : false;
        let editUser = this.props.location.state ? this.props.location.state.linkName : "";

        if (createUser) userURL = '/admin/user/view/create';
        else if (editUser === "editUser") userURL = `/admin/user/view/edit/${this.state.userId}`;
        else userURL = '/user/info';


        API.project
            .get(userURL)
            .then(resp => {
                const {data} = resp.data;

                let firstName = "";
                let lastName = "";
                let email = "";
                let role = "";
                let knoaRoles = [];
                let proxyUserMode = true;

                if (createUser) {
                    firstName = "";
                    lastName = "";
                    email = "";
                    role = data.knoaRoles[0];
                    knoaRoles = data.knoaRoles;
                    proxyUserMode = true;
                } else if (editUser === "editUser") {
                    firstName = data.userInfo.firstName;
                    lastName = data.userInfo.lastName;
                    email = data.userInfo.email;
                    role = data.userInfo.role;
                    knoaRoles = data.knoaRoles;
                    proxyUserMode = data.userInfo.needSupport;
                } else {
                    firstName = data.firstName;
                    lastName = data.lastName;
                    role = data.role;
                    email = data.email;
                }

                this.setState({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    orginalEmail: email,
                    role: role,
                    knoaRoles: knoaRoles,
                    proxyUserMode: proxyUserMode,
                    loaded: true,
                    setMainFocus: true
                });
            })
            .catch(e => {
                console.error(e);
                if(localStorage.get("accessDenied401") === "true"){
                    this.setState({showLoggedoutModal:true});
                    return;
                }
                let resultCode = -1;
                if(e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"

                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.setMainFocus) {
            if (this.mainContent && this.mainContent.current) {
                this.mainContent.current.focus();
                this.setState({setMainFocus: false})
            }
        }
    }

    render() {
        if(this.state.sessionTerminated)
            return <Redirect to={{pathname: '/auth/logout'}}/>;

        let showLoggedOutWindow = this.state.showLoggedoutModal;

        if (!this.state.loaded && !showLoggedOutWindow) {
            return null;
        }

        const {t} = this.props;
        let routes = mauiRoutes.getRoutes();
        const footerObj = [];

        let createUser = this.props.location.state ? this.props.location.state.createUser : false;
        let editUser = this.props.location.state ? this.props.location.state.linkName : "";

        if (createUser && this.state.redirect) {
            return <Redirect to='/admin/Settings/UserManagement'/>;
        }

        let disableButton = this.state.disableSaveButton;
        if (createUser || editUser === "editUser") {
            const updatePreferencesObj = {
                name: this.props.t('common:save'),
                callback: this.handleSubmit,
                disabled: disableButton
            };
            footerObj.push(updatePreferencesObj);
        }

        return (
            <div ref={this.mainContent} tabIndex={"-1"}>
                <ModalDialog
                    showModal={showLoggedOutWindow}
                    title={t("common:logOut")}
                    contentBody={
                        <span>{getInfoMessages(this.props.t, 'logoutfor401',null,null)}</span>
                    }
                    bodyPaddingBottom='15px'
                    footerBtnBsStyle="success"
                    footerBtnClass="KnoaSuccessButton"
                    footerBtnOnClick={this.handleLogoutConfirm}
                    footerBtnText={this.props.t('common:ok')}
                />
                {(editUser === "editUser") &&
                <ModalDialog
                    showModal={this.state.showEmailChange}
                    title={t("adminSettings:emailChange")}
                    contentBody={
                        <span>{getInfoMessages(this.props.t, 'changeEmail', 'email', this.state.orginalEmail)}</span>
                    }                    
                    bodyPaddingBottom='15px'
                    footerBtnBsStyle="danger"
                    footerBtnClass="KnoaDangerButton"
                    footerBtnOnClick={this.handleEmailChangeConfirm}
                    footerBtnText={this.props.t('common:yes')}
                    footerBtnCloseText={this.props.t('common:no')}
                    handleClose={this.handleClose}
                />}
                {(createUser || editUser === "editUser") && <form onSubmit={this.handleSubmit} noValidate>
                    <div className="main-content main-content-page-with-footer">
                        <Helmet>
                            {createUser && <title>Knoa Analytics - New User</title>}
                            {!createUser && (editUser === "editUser") && <title>Knoa Analytics - Edit User</title>}
                        </Helmet>
                        <Row className="user-admin-breadcrumb">
                            <KnoaBreadcrumb
                                href="#/admin/AllSettings"
                                href2="#/admin/Settings/UserManagement"
                                firstLevelText={t("common:settings")}
                                secondLevelText={t("route.name:User Management")}
                                thirdLevelText={(this.props.navVarBrand) ? this.props.navVarBrand : this.props.getActiveRoute(routes, t)}
                            />
                        </Row>
                        {this.state.fetchInProgress === "true" && <Spinner/>}
                        <div hidden={this.state.fetchInProgress === "true"}>
                            <CommonContainer
                                class="common-page-content-row user-profile-rule"
                                content={
                                    <Col className="knoa-region" tabIndex={"-1"}>
                                        <Card
                                            title={t('user:userAccount')}
                                            content={
                                                <>
                                                    <div className="col-md-12">
                                                        <FormInputs
                                                            ncols={["col-md-6", "col-md-6"]}
                                                            proprieties={[
                                                                {
                                                                    label: t('user:firstName'),
                                                                    type: "text",
                                                                    name: "firstName",
                                                                    bsClass: this.state.inputClass_firstName,
                                                                    placeholder: capitalizeWords(t('user:firstName')),
                                                                    value: this.state.firstName,
                                                                    disabled: false,
                                                                    onChange: this.handleChange,
                                                                    id: "firstName",
                                                                    required: true
                                                                },
                                                                {
                                                                    label: t('user:lastName'),
                                                                    type: "text",
                                                                    name: "lastName",
                                                                    bsClass: this.state.inputClass_lastName,
                                                                    placeholder: capitalizeWords(t('user:lastName')),
                                                                    value: this.state.lastName,
                                                                    disabled: false,
                                                                    onChange: this.handleChange,
                                                                    id: "lastName",
                                                                    required: true
                                                                }
                                                            ]}
                                                        />
                                                        <div className="col-md-6">
                                                            <FormErrors formErrors={this.state.formErrors} fieldName="firstName"/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <FormErrors formErrors={this.state.formErrors} fieldName="lastName"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div class="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="control-label" htmlFor="email">{t('user:email')} <span className="text-danger-1">*</span></label>
                                                                    <input name="email" id="email" placeholder={capitalizeWords(t('user:email'))}
                                                                           type="email" onChange={this.handleChange}
                                                                           className={this.state.inputClass_email}
                                                                           value={this.state.email}
                                                                           required
                                                                    />
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="control-label" htmlFor="role">{t('user:analyticsRole')}</label>
                                                                    {createUser &&
                                                                    <select onChange={this.updateRole} className="form-control" name="role" id="role">
                                                                        {this.state.knoaRoles.map(role => <option
                                                                            value={role}>{role}</option>)}
                                                                    </select>}
                                                                    {(editUser === "editUser") && <select
                                                                        name="role" 
                                                                        id="role"
                                                                        disabled={this.state.role === "Admin"}
                                                                        onChange={this.updateRole}
                                                                        className="form-control"
                                                                        value={this.state.role}>
                                                                        {this.state.role === "Admin" && <option value={this.state.role}
                                                                                                                key={0}>{this.state.role}</option>}
                                                                        {this.state.role !== "Admin" && this.state.knoaRoles.map((role, index) =>
                                                                            <option value={role}
                                                                                    key={index}>{role}</option>)}
                                                                    </select>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <FormErrors formErrors={this.state.formErrors} fieldName="email"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label
                                                                        className="control-label" htmlFor="mode">{t('user:proxyUserMode')}</label>
                                                                    {createUser && <select 
                                                                        onChange={this.updateProxyUserMode}
                                                                        name="mode" 
                                                                        id="mode"                    
                                                                        className="form-control">
                                                                        <option value={'Enabled'}>Enabled</option>
                                                                        <option value={'Disabled'}>Disabled</option>
                                                                    </select>}
                                                                    {(editUser === "editUser") && <select
                                                                        onChange={this.updateProxyUserModeForEditUser}
                                                                        name="mode"
                                                                        id="mode"
                                                                        className="form-control"
                                                                        value={this.state.proxyUserMode}>
                                                                        <option value={true}>Enabled</option>
                                                                        <option value={false}>Disabled</option>
                                                                    </select>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="clearfix"/>
                                                </>
                                            }
                                        />
                                    </Col>
                                }
                            />
                        </div>
                    </div>
                    <Footer fluid footerObj={footerObj}/>
                </form>}
                {!(createUser || editUser === "editUser") &&
                <div className="main-content">
                    <Helmet>
                        <title>Knoa Analytics - My Profile</title>
                    </Helmet>
                    {this.state.fetchInProgress === "true" && <Spinner/>}
                    <div hidden={this.state.fetchInProgress === "true"}>
                        <CommonContainer
                            class="common-page-content-row user-profile-rule"
                            content={
                                <Col className="knoa-region" tabIndex={"-1"}>
                                    <Card
                                        title={t("user:userAccount")}
                                        content={
                                            <>
                                                <FormInputs
                                                    ncols={["col-md-6", "col-md-6"]}
                                                    proprieties={[
                                                        {
                                                            label: t("user:firstName"),
                                                            type: "text",
                                                            bsClass: "form-control",
                                                            placeholder: "firstname",
                                                            defaultValue: this.state.firstName,
                                                            disabled: true,
                                                            id: "firstname",
                                                            name: "firstname"
                                                        },
                                                        {
                                                            label: t("user:lastName"),
                                                            type: "text",
                                                            bsClass: "form-control",
                                                            placeholder: "lastname",
                                                            defaultValue: this.state.lastName,
                                                            disabled: true,
                                                            id: "lastname",
                                                            name: "lastname"
                                                        }
                                                    ]}
                                                />
                                                <FormInputs
                                                    ncols={["col-md-6", "col-md-6"]}
                                                    proprieties={[
                                                        {
                                                            label: t("user:username") + " (" + t("user:emailAddress") + ")",
                                                            type: "email",
                                                            bsClass: "form-control",
                                                            placeholder: "username",
                                                            defaultValue: this.state.email,
                                                            disabled: true,
                                                            id: "username",
                                                            name: "username"
                                                        },
                                                        {
                                                            label: t("user:analyticsRole"),
                                                            type: "text",
                                                            bsClass: "form-control",
                                                            placeholder: "knoarole",
                                                            defaultValue: this.state.role,
                                                            disabled: true,
                                                            id: "role",
                                                            name: "role"
                                                        }
                                                    ]}
                                                />
                                                <div className="clearfix"/>
                                            </>
                                        }
                                    />
                                </Col>
                            }
                        />
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default withTranslation()(UserAccount);
