/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, {Component} from "react";
import {Collapse} from "react-bootstrap";
import {NavLink} from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
//import PerfectScrollbar from "perfect-scrollbar";
//import "perfect-scrollbar/css/perfect-scrollbar.css";

import AdminNavbarLinks from "components/layout/navbars/AdminNavbarLinks.jsx";

// image for avatar in Sidebar
import avatar from "assets/img/default-avatar.png";
import {API} from "../../lib/API";
import PropTypes from "prop-types";
import {mauiRoutes} from "../../routes.js";
import {withTranslation} from 'react-i18next';
import {capitalizeWords, getPossibleTimezone} from "../../lib/common";

const localStorage = require('../../lib/localStorage');
let routes = mauiRoutes.getRoutes();
var ps;

const GenerateSidebarLinks = props => {
    const {csRoutes, currentRoute, handleClick} = props;
    return (csRoutes.map((routeItem, key) => (
        <li className={currentRoute.id === routeItem.id ? "active" : ""} key={key}>
            <a id={routeItem.id} onClick={handleClick}>
                <span className="col-sm-1">&nbsp;</span>
                <span className="sidebar-normal">{capitalizeWords(routeItem.sbLinkName)}</span>
            </a>
        </li>
    )))
};

const BuildSideBarMenus = props => {
    const {width, loaded, createLinks, createAdminSettingLinks, createHelpLinks, trans} = props;
    if (loaded) {
        return (
            <ul className="nav">
                {/*
              If we are on responsive, we want both links from navbar and sidebar
              to appear in sidebar, so we render here HeaderLinks
            */}
                {width <= 992 ? <AdminNavbarLinks t={trans}/> : null}
                {/*
              here we render the links in the sidebar if the link is simple,
              we make a simple link, if not, we have to create a collapsible group,
              with the speciffic parent button and with it's children which are the links
            */}
                {createLinks(routes, false, false)}
                {createAdminSettingLinks(routes)}
                {createHelpLinks(routes)}
            </ul>
        )
    }
    return <ul className="nav"></ul>
};

class Sidebar extends Component {
    constructor(props) {
        super(props);

        if (this.props.email === undefined) {
            this.state = {
                ...this.getCollapseStates(routes),
                openAvatar: false,
                isWindows: navigator.platform.indexOf("Win") > -1,
                width: window.innerWidth,
                userLoaded: false,
                userName: "",
                reportLoaded: false,
                reportList: PropTypes.array,
                updatePageTitle: props.updatePageTitle,
                isAdmin: false
            };
            routes = mauiRoutes.getRoutes();
        }
    }

    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = routes => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.toLowerCase().indexOf(routes[i].path.toLowerCase()) !== -1) {
                return true;
            }
        }
        return false;
    }

    createAdminSettingLinks = routes => {
        return this.createLinks(routes, true, false);
    };

    createHelpLinks = routes => {
        return this.createLinks(routes, false, true);
    };


    //this function will decide the target is the subreport for each category
    isSubReportForTheCategory(categoryViews, targetUrl) {
        let reportVeiwPages = categoryViews.length;
        let i;
        for (i = 0; i < reportVeiwPages; i++) {
            if (targetUrl.includes(categoryViews[i].path.toLowerCase())) return true;
        }
        return false;
    }


    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = (routes, admin, help) => {
        return routes.map((prop, key) => {

            if (prop.redirect) {
                return null;
            }
            if (prop.invisible) {
                return null;
            }
            if (admin && !prop.isAdmin)
                return null;
            if (!admin && prop.isAdmin)
                return null;
            if (help && !prop.isHelp)
                return null;
            if (!help && prop.isHelp)
                return null;


            if (prop.collapse) {
                let openMenu = this.isSubReportForTheCategory(prop.views, this.props.location.pathname.toLowerCase());
                var st = {};
                st[prop["state"]] = !(openMenu ? openMenu : this.state[prop.state]);
                return (
                    <li
                        className={this.getCollapseInitialState(prop.views) ? "active" : ""}
                        key={key}
                    >
                        <NavLink
                            to={{
                                pathname: prop.layout + prop.path + prop.page,
                            }}
                            // to={prop.layout + prop.path}
                            aria-expanded={openMenu}
                            className="nav-link"
                            activeClassName="active"
                            onClick={e => {
                                // e.preventDefault();
                                this.setState(st);
                                this.setState({openAvatar: false});
                            }}
                        >
                            <i className={prop.icon}/>
                            <p>
                                {this.props.t('route.name:' + prop.name)}
                                <b
                                    className={
                                        (openMenu ? openMenu : this.state[prop.state] && this.getCollapseInitialState(prop.views)) ? "caret rotate-180" : "caret" // for up or down triangle
                                    }
                                />
                            </p>
                            {/*</a>*/}
                        </NavLink>
                        <Collapse in={openMenu ? openMenu : (this.state[prop.state] && this.getCollapseInitialState(prop.views))}>
                            <ul className="nav">{this.createLinks(prop.views, false, false)}</ul>
                        </Collapse>
                    </li>
                );
            } //if it is collapse, if not, return below.
            return (
                <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
                    <NavLink
                        to={{
                            pathname: prop.layout + prop.path,
                        }}
                        // to={prop.layout + prop.path}
                        className="nav-link"
                        activeClassName="active"
                        onClick={e => {
                            // e.preventDefault();
                            this.setState({openAvatar: false});
                        }}
                    >
                        {prop.icon ? (
                            <>
                                <i className={prop.icon}/>
                                <p>{this.props.t('route.name:' + prop.name)}</p>
                            </>
                        ) : (
                            <>
                                <span className="col-sm-1">&nbsp;</span>
                                <span className="sidebar-normal">{this.props.t('report.name:' + capitalizeWords(prop.name))}</span>
                            </>
                        )}
                    </NavLink>
                </li>
            );
        });
    };
    // verifies if routeName is the one active (in browser input)
    activeRoute = routeName => {
       // console.log("routeName: "+routeName);
       // console.log("page: "+this.props.location.pathname);
        let currentPage = this.props.location.pathname.toLowerCase();
        if (routeName.toLowerCase() === "/admin/projects" && (currentPage === "/admin/newproject" || currentPage === "/admin/editproject" || currentPage === "/admin/viewproject")) {
            return "active";
        } else {
            return currentPage.indexOf(routeName.toLowerCase()) > -1 ? "active" : "";
        }
    };


    // if the windows width changes CSS has to make some changes
    // this functions tell react what width is the window
    updateDimensions() {
        this.setState({width: window.innerWidth});
    }

    componentDidUpdate() {
        // if (navigator.platform.indexOf("Win") > -1) {
        //     setTimeout(() => {
        //         // ps.update();
        //     }, 350);
        // }
    }

    updateSessionLanguage = ({language}) => {
        API.setSessionLanguage({sessionLanguage: language});
        this.props.i18n.changeLanguage(language);
    };

    loadUserInfo() {
        let _this = this;
        API.user
            .get("/user/info")
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;
                this.setState({
                    userName: data.fullName,
                    isAdmin: data.role.toUpperCase() === "ADMIN",
                    supportAccount: data.supportAccount,
                    loginAs: data.loginAs,
                    userLoaded: true
                });


                let userTimeZone = data.userTimezone;
                if(userTimeZone == null || userTimeZone=== "") {
                    userTimeZone = data.serverTimezone.zoneId==null|| data.serverTimezone.zoneId==="" ? getPossibleTimezone().value : data.serverTimezone.zoneId;
                }
                API.setUserTimeZone( userTimeZone );
                API.setServerTimeZone(data.serverTimezone.zoneId);

                // console.log("serverTimezone: "+ (data.serverTimezone !==null ? JSON.stringify(data.serverTimezone): null));
                this.updateSessionLanguage({language: data.language});
                window.sessionStorage.setItem("currentUser", data.fullName);
            }).catch(e => {
            console.error(e);
            if (localStorage.get("accessDenied401") === "true") {
                window.location.href = "/#/auth/logout";
            }
        });
    }

    componentDidMount() {

        this.loadUserInfo();

        this.updateDimensions();
        // add event listener for windows resize
        window.addEventListener("resize", this.updateDimensions.bind(this));
        // if you are using a Windows Machine, the scrollbars will have a Mac look
        if (navigator.platform.indexOf("Win") > -1) {
            ps = this.refs.sidebarWrapper;
        }
    }

    // componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    // if (navigator.platform.indexOf("Win") > -1) {
    //     ps.destroy();
    // }
    // }

    handleClick = (e) => {
        if (this.props.handleSidebarClick) {
            this.props.handleSidebarClick(e.currentTarget.id);
        }
    }

    render() {
        const {email, csRoutes, currentRoute, t} = this.props;

        let logoClass = 'logo';
        let sidebarWrapperClass = 'sidebar-wrapper knoa-region';
        let infoClass = 'info';
        let userLinkClass = 'user-name-wrap';
        let userCaretClass = 'caret';
        let userName = "";
        let supportAccount = "";
        if (email) {
            userName = "";
            supportAccount = "";
        } else {
            userName = this.state.userName;
            supportAccount = this.state.supportAccount;
        }
        if (email) {
            return (
                <div className="sidebar">
                    <div className="sidebar-background"/>
                    <div className="logo csLogo">
                        <a target="_blank" className="simple-text logo-normal sidebarTitle">KNOA SUPPORT</a>
                    </div>
                    <div className="sidebar-wrapper csSidebar-wrapper knoa-region" tabIndex={"-1"}>
                        <div className="user csMainUser">
                            <div className="photo user-photo-maui">
                                <img src={avatar} alt="Avatar"/>
                            </div>
                            <div className="info">
                                <span>{email}</span>
                                <ul className="nav">
                                    <li className="csLogoutLI">
                                        <NavLink
                                            to={
                                                {
                                                    pathname: "/auth/logout",
                                                    state: {session: false}
                                                }
                                            }
                                            className="nav-link"
                                            activeClassName="active"
                                        >
                                            <>
                                                <span className="col-sm-1 csLogoutSPAN">&nbsp;</span>
                                                <span className="sidebar-normal">Log out</span>
                                            </>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <ul className="nav">
                            <GenerateSidebarLinks
                                csRoutes={csRoutes}
                                currentRoute={currentRoute}
                                handleClick={this.handleClick}
                            />
                        </ul>
                    </div>
                </div>
            );

        } else {
            if (document.body.classList.contains("sidebar-mini")) {
                sidebarWrapperClass = sidebarWrapperClass + ' sidebar-wrapper-mini';
            }

            if (this.state.userLoaded && this.state.loginAs) {
                logoClass = logoClass + ' csLogo';
                sidebarWrapperClass = sidebarWrapperClass + ' csSidebar-wrapper';
                infoClass = infoClass + ' csInfo';
                userLinkClass = 'user-name-wrap csUserA';
                userCaretClass = userCaretClass + ' csUserCaret';
                routes = mauiRoutes.getRoutes();
            }

            return (

                <div
                    className="sidebar"
                    data-color={this.props.color}
                    data-image={this.props.image}
                >
                    {this.props.hasImage ? (
                        <div
                            className="sidebar-background"
                            style={{backgroundImage: "url(" + this.props.image + ")"}}
                        />
                    ) : (
                        ""
                    )}
                    <div className={logoClass}>
                        <a
                            target="_blank"
                            className="simple-text logo-normal sidebarTitle"
                        >
                            {t('common:appName')}
                        </a>
                    </div>
                    <div className={sidebarWrapperClass}
                         ref="sidebarWrapper"
                         tabIndex={"-1"}>
                        <div className="user user-display-wrap">
                            <div className="photo user-photo-maui">
                                <img src={avatar} alt="Avatar"
                                     className={this.state.openAvatar || window.location.href.indexOf("/admin/myProfile") !== -1 || window.location.href.indexOf("/admin/myPreference") !== -1 ? "profile-highlight" : ""}/>
                            </div>
                            <div className={infoClass}>
                                <a
                                    href="#"
                                    onClick={e => {
                                        e.preventDefault();
                                        if ((window.location.href.indexOf("/admin/myProfile") !== -1 || window.location.href.indexOf("/admin/myPreference") !== -1) && this.state.openAvatar === false)
                                            this.setState({openAvatar: true});
                                        else if ((window.location.href.indexOf("/admin/myProfile") !== -1 || window.location.href.indexOf("/admin/myPreference") !== -1) && this.state.openAvatar === true)
                                            this.setState({openAvatar: false});
                                        else
                                            this.setState({openAvatar: !this.state.openAvatar});
                                    }}
                                    className={userLinkClass}
                                    aria-expanded={this.state.openAvatar}
                                >
                                    {this.state.loginAs ?
                                        (
                                            <>
                                                <div className={"user-name-wrap"}>
                                                <span>
                                                    <b className={"support-user-sidebar-proxyTitle"}>
                                                {t('common:loggedInAs', {user1: supportAccount, user2: userName})}
                                                    </b>
                                                     </span>
                                                </div>
                                                <b
                                                    className={
                                                        this.state.openAvatar || window.location.href.indexOf("/admin/myProfile") !== -1 || window.location.href.indexOf("/admin/myPreference") !== -1 ? userCaretClass + " rotate-180" : userCaretClass
                                                    }
                                                />
                                                <br></br>
                                            </>
                                        ) : (
                                            <>
                                                <div className={"user-name-wrap"}>
                                            <span>
                                                 <b className={"support-user-sidebar-proxyTitle"}>{this.state.userLoaded ? userName : ""}</b>
                                                </span>
                                                </div>
                                                <b
                                                    className={
                                                        this.state.openAvatar || window.location.href.indexOf("/admin/myProfile") !== -1 || window.location.href.indexOf("/admin/myPreference") !== -1 ? "caret rotate-180" : "caret"
                                                    }
                                                />
                                            </>
                                        )
                                    }
                                </a>

                                <Collapse
                                    in={this.state.openAvatar || window.location.href.indexOf("/admin/myProfile") !== -1 || window.location.href.indexOf("/admin/myPreference") !== -1}>
                                    <ul className="nav">
                                        <li className={window.location.href.indexOf("/admin/myProfile") !== -1 ? "active" : ""}>
                                            <NavLink
                                                to={{pathname: "/admin/myProfile"}}
                                                className="nav-link nav-link-user"
                                                activeClassName="active"
                                                onClick={e => {
                                                    this.createLinks(routes, false, false)
                                                }}
                                            >
                                                <>
                                                    {/*<span className="col-sm-1">&nbsp;</span>*/}
                                                    <span className="sidebar-normal">{t('route.name:My Profile')}</span>
                                                </>
                                            </NavLink>
                                        </li>
                                        <li className={window.location.href.indexOf("/admin/password") !== -1 ? "active" : ""}>
                                            <NavLink
                                                to={{pathname: "/admin/password"}}
                                                className="nav-link nav-link-user"
                                                activeClassName="active"
                                                onClick={e => {
                                                    this.createLinks(routes, false, false)
                                                }}
                                            >
                                                <>
                                                    {/*<span className="col-sm-1">&nbsp;</span>*/}
                                                    <span className="sidebar-normal">{t('user:password')}</span>
                                                </>
                                            </NavLink>
                                        </li>
                                        <li className={window.location.href.indexOf("/admin/myPreference") !== -1 ? "active" : ""}>
                                            <NavLink
                                                to={{pathname: "/admin/myPreference"}}
                                                className="nav-link nav-link-user"
                                                activeClassName="active"
                                                onClick={e => {
                                                    this.createLinks(routes, false, false)
                                                }}
                                            >
                                                <>
                                                    {/*<span className="col-sm-1">&nbsp;</span>*/}
                                                    <span className="sidebar-normal">{t('route.name:My Preferences')}</span>
                                                </>
                                            </NavLink>
                                        </li>
                                        {this.state.loginAs &&
                                            <li>
                                                <NavLink
                                                    // to={prop.layout + prop.path}
                                                    to={
                                                        {
                                                            pathname: "/auth/proxySessionChange",
                                                            state: {session: false,
                                                            email: supportAccount}
                                                        }
                                                    }
                                                    className="nav-link nav-link-user"
                                                    activeClassName="active"
                                                >
                                                    <>
                                                        {/*<span className="col-sm-1">&nbsp;</span>*/}
                                                        <span className="sidebar-normal">{t('route.name:ProxySessionChange')}</span>
                                                    </>
                                                </NavLink>
                                            </li>
                                        }
                                        <li>
                                            <NavLink
                                                // to={prop.layout + prop.path}
                                                to={
                                                    {
                                                        pathname: "/auth/logout",
                                                        state: {session: false}
                                                    }
                                                }
                                                className="nav-link nav-link-user"
                                                activeClassName="active"
                                            >
                                                <>
                                                    {/*<span className="col-sm-1">&nbsp;</span>*/}
                                                    <span className="sidebar-normal">{t('route.name:logOut')}</span>
                                                </>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Collapse>
                            </div>
                        </div>
                        <BuildSideBarMenus
                            width={this.state.width}
                            loaded={this.state.userLoaded}
                            createLinks={this.createLinks}
                            createAdminSettingLinks={this.createAdminSettingLinks}
                            createHelpLinks={this.createHelpLinks}
                            trans={t}
                        />
                    </div>

                </div>
            );
        }


    }
}

export default withTranslation()(Sidebar);
