import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {Col, Row, DropdownButton, MenuItem, Tab, NavItem} from "react-bootstrap";
import {API} from "../../../lib/API";
import Card from "../../../components/Card/Card";
import TableauWorkbook from "../../../components/tableau/TableauWorkbook";
import ListSelect from '../../../components/common/ListSelect';
import KnoaBreadcrumb from "../../../components/common/KnoaBreadcrumb";
import ModalDialog from "../../../components/common/ModalDialog";
import Table from "react-bootstrap/lib/Table";
import {validateAll, validations} from "indicative/validator";
import {mauiRoutes} from "../../../routes";
import {withTranslation} from 'react-i18next';
import {
    calculateBenchmarkID,
    calculateStartDate,
    capitalizeWords,
    formatDateToString,
    getEndDayID,
    getEndMonthID,
    GetNumberOfActiveFilters,
    getStartDayID,
    getStartMonthID,
    getSSReportObject,
    setSSReportObject,
    removeSSReportObject,
    setSSGlobalOptionsObject,
    getSSGlobalOptionsObject,
    getNameforStyles,
    getPartOfDate, getServerTimezoneDate
} from "../../../lib/common";
import _ from "lodash";
import Spinner from "../../../components/common/Spinner";
import ExportSpinner from "../../../components/common/ExportSpinner";
import Select from "react-select";
import CustomSelectStyles from "../../../components/common/CustomSelectStyles";
import {NavLink} from "react-router-dom";
import {FILTERS} from "../../../lib/filters";
import moment from "moment";
import {
    getErrorMessagesFromCode,
    getErrorMsgFromStatus,
    getInfoMessages,
    getSpecificOPErrorMessages,
    getSuccessMessages, getWarningMessages
} from "../../../lib/messageHandler";
import {Helmet} from "react-helmet";
import Tabs from "react-bootstrap/lib/Tabs";
import Nav from "react-bootstrap/lib/Nav";

const localStorage = require('../../../lib/localStorage');

const SupportedEncoding = [
    {id: 'encoding-1001', value: 'Unicode (UTF-8)'},
    {id: 'encoding-1002', value: 'Unicode (UTF-16 Big Endian)'},
    {id: 'encoding-1003', value: 'ISO-8859-1 (General US & Western European, ISO-LATIN-1)'},
    {id: 'encoding-1004', value: 'Japanese (Windows)'},
    {id: 'encoding-1005', value: 'Japanese (Shift-JIS)'},
    {id: 'encoding-1006', value: 'Chinese National Standard (GB18030)'},
    {id: 'encoding-1007', value: 'Chinese Simplified (GB2312)'},
    {id: 'encoding-1008', value: 'Chinese Traditional (Big5)'},
    {id: 'encoding-1009', value: 'Big 5 Traditional Chinese (HKSCS)'},
    {id: 'encoding-1010', value: 'Korean (EUC-KR)'}
];

const GenerateMenuItems = ({workbooks, activeMenuItem, menuSelect, t}) => {
    let _workbooks = workbooks;
    
        //Extract report name without Daily/Monthly for translation
        _workbooks = workbooks.map(item => {
            if (item.workbookName.indexOf('daily') !== -1 || item.workbookName.indexOf('monthly') !== -1) {
                let workbook = item.workbookName.split(' ');
                if (workbook.length > 1) {
                    item.reportType = workbook[workbook.length - 1];
                    workbook.pop();
                    item.reportName = capitalizeWords(workbook.join(' '));
                } else {
                    item.reportName = capitalizeWords(item.workbookName);
                }
            } else {
                item.reportName = capitalizeWords(item.workbookName);
            }

            return item;
        });
    

    return (_workbooks.map((workbook, index) => (
        <MenuItem
            key={workbook.id}
            eventKey={workbook.id}
            onSelect={menuSelect}
            type ="project"
            active={workbook.id === activeMenuItem}
        >
            {
                (workbook.reportName && workbook.reportType) ? t('report.name:withTypeFirst', {
                    reportName: workbook.reportName,
                    type: workbook.reportType
                }) : t('report.name:' + workbook.reportName)
            
            }
        </MenuItem>
            
    )))
};

const SingleMauiReport = props => {
    const {
        tableauWorkbookRef, loaded, tableauUrl, token, reportType, lastReportTab, workbook, filters, dataRangeType, dataRangeTypeForLookup,
        drillOptions, instanceWorkbookId, isDataReport, allInstanceWorkbookIds, backClick, instanceEnabled, currentReportFilters, latestAppliedMauiFilters,absoluteStartError,absoluteEndError,
        updateTimeFilterValues, updateApplicationsAndUserFilterValues, handleAuthorization401, updateParametersValues, handleResetFilters, handleApplyNewFilters,
        moreMenuOptions, handleSessionPersistChange, handlePreferencePersistChange, handleProjectPersistChange, workflowReport,
        sessionPersist, preferencePersist, projectPersist, projectId, projectEditable, attributeMap, parameterErrors,filterApplied, filterReset, setTempFilterChanged, tempFilterChanged, trans
    } = props;

    if (loaded && workbook) {
        let _url = tableauUrl + "/t/" + workbook.siteName + "/views/" + workbook.viewUrl;

        //update URL to enter last tab
        if (lastReportTab && lastReportTab !== "") {
            const tempUrl = _url.substring(0, _url.lastIndexOf('/') + 1);
            _url = tempUrl + lastReportTab.replace(/\s+/g, '');
        }

        let wbType = "";
        let wbName = "";
        let wbKey = "";
        if (workflowReport) {
            wbName = capitalizeWords(workbook.workbookName);
        } else {
            let tempWorkbook = workbook.workbookName.split(' ');
            if (tempWorkbook.length > 1) {
                wbType = tempWorkbook[tempWorkbook.length - 1];
                if (wbType === 'daily' || wbType === 'monthly') {
                    wbKey = "withTypeFirst";
                } else {
                    wbKey = "withTypeLast"
                }
                tempWorkbook.pop();
                wbName = capitalizeWords(tempWorkbook.join(' '));
            } else {
                wbName = capitalizeWords(workbook.workbookName);
            }
        }

        window.sessionStorage.setItem('latestReportDataType', dataRangeType + workbook.workbookName);

        //update application name filter values in case user visits the User Workflows report
        let tempCurrentReportFilters = currentReportFilters.filters;
        let tempLatestReportFilters = latestAppliedMauiFilters.filters;
        let tempFiltersContextFilter = filters.contextFilter;
        let tempWorkbookId = getSSReportObject(reportType, "workbookId", projectId);
        let isWorkflowsIgnore = false;
        for(const filter in currentReportFilters.filters) {
            if(currentReportFilters.filters.hasOwnProperty(filter)) {
                if(FILTERS.isUserWorkflowsIgnore(filter,workbook.id) || (tempWorkbookId && FILTERS.isUserWorkflowsIgnore(filter,tempWorkbookId))) {
                    isWorkflowsIgnore = true;
                    if((!filterApplied || filterReset)) {
                        if(!tempFilterChanged) {
                            tempCurrentReportFilters[filter].options = [];
                            if (tempCurrentReportFilters[filter].regex) {
                                delete tempCurrentReportFilters[filter].regex;
                            }
                        }

                        tempLatestReportFilters[filter].options = []
                        if(tempLatestReportFilters[filter].regex) {
                            delete tempLatestReportFilters[filter].regex;
                        }
                        tempFiltersContextFilter[filter] = [];
                    }
                }
            }
        }

        currentReportFilters.filters = tempCurrentReportFilters;
        latestAppliedMauiFilters.filters = tempLatestReportFilters;
        filters.contextFilter = tempFiltersContextFilter;

        return (
            <div>
                <Col md={12} className="report-column-container">
                    <Card
                        title={wbType !== "" ? trans('report.name:' + wbKey, {
                            reportName: wbName,
                            type: wbType
                        }) : trans('report.name:' + wbName)}
                        category={trans('kpiCard.footer:' + workbook.description)}
                        helpLinkReport={wbType !== "" ? (wbName + ' ' + wbType) : wbName}
                        helpLinkText={trans('report.name:help')}
                        reportToolbar={{
                            isInstanceReport: dataRangeType.includes("instance"),
                            currentWorkbook: workbook,
                            timeframe: {
                                reportFilters: latestAppliedMauiFilters,
                                projectID: projectId
                            },
                            filter: {
                                numOfActiveFilters: GetNumberOfActiveFilters(latestAppliedMauiFilters),
                            },
                            drill: {
                                instanceEnabled: instanceEnabled,
                                dataRangeType: dataRangeType,
                                backClick: backClick,
                                options: drillOptions,
                                instanceWorkbookId: instanceWorkbookId,
                                isDataReport: isDataReport,
                            },
                            more: {
                                moreMenuOptions: moreMenuOptions
                            }
                        }}
                        filterPanel={{
                            enabled: !(((dataRangeType.includes("current") || dataRangeType.includes("instance")) && workbook['workbookName'].endsWith("workflow"))),
                            reportType: reportType,
                            currentReportFilters: currentReportFilters,
                            latestAppliedMauiFilters: latestAppliedMauiFilters,
                            sessionPersist: sessionPersist,
                            preferencePersist: preferencePersist,
                            projectPersist: projectPersist,
                            projectEditable: projectEditable,
                            updateTimeFilterValues: updateTimeFilterValues,
                            updateApplicationsAndUserFilterValues: updateApplicationsAndUserFilterValues,
                            absoluteStartError: absoluteStartError,
                            absoluteEndError: absoluteEndError,
                            handleAuthorization401: handleAuthorization401,
                            updateParametersValues: updateParametersValues,
                            handleResetFilters: handleResetFilters,
                            handleApplyNewFilters: handleApplyNewFilters,
                            handleSessionPersistChange: handleSessionPersistChange,
                            handlePreferencePersistChange: handlePreferencePersistChange,
                            handleProjectPersistChange: handleProjectPersistChange,
                            parameterErrors: parameterErrors,
                            workbookId: workbook.id,
                            setTempFilterChanged: setTempFilterChanged
                        }}
                        content={
                            <Row>
                                <Col>
                                    <TableauWorkbook
                                        ref={tableauWorkbookRef}
                                        url={_url}
                                        token={token}
                                        filters={filters}
                                        dataRangeType={dataRangeType}
                                        dataRangeTypeForLookup={dataRangeTypeForLookup}
                                        instanceEnabled={instanceEnabled}
                                        workflowReport={workflowReport}
                                        allInstanceWorkbookIds={allInstanceWorkbookIds}
                                        attributeMap={attributeMap}
                                        isWorkflowsIgnore={isWorkflowsIgnore}
                                    />
                                </Col>
                            </Row>
                        }
                    />
                </Col>
            </div>
        );
    }
    return <div></div>;
};

let abortController; // for cancel export
class Report extends Component {
    constructor(props) {
        super(props);

        this.menuSelect = this.menuSelect.bind(this);
        this.postLoadDataCheck = this.postLoadDataCheck.bind(this);
        this.loadData = this.loadData.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.prepareExportDialog = this.prepareExportDialog.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleAddToProjectUpdateCheckbox = this.handleAddToProjectUpdateCheckbox.bind(this);
        this.handleAddProject = this.handleAddProject.bind(this);
        this.exportData = this.exportData.bind(this);
        this.handleShareProject = this.handleShareProject.bind(this);
        this.handleShareProjectUpdate = this.handleShareProjectUpdate.bind(this);
        this.drillClick = this.drillClick.bind(this);
        this.backClick = this.backClick.bind(this);
        this.updateTimeFilterValues = this.updateTimeFilterValues.bind(this);
        this.updateApplicationsAndUserFilterValues = this.updateApplicationsAndUserFilterValues.bind(this);
        this.resetFiltersForObject = this.resetFiltersForObject.bind(this);
        this.handleResetFilters = this.handleResetFilters.bind(this);
        this.handleApplyNewFilters = this.handleApplyNewFilters.bind(this);
        this.handleSessionPersistChange = this.handleSessionPersistChange.bind(this);
        this.handlePreferencePersistChange = this.handlePreferencePersistChange.bind(this);
        this.handleProjectPersistChange = this.handleProjectPersistChange.bind(this);
        this.getLastestTabTimeFilterType = this.getLastestTabTimeFilterType.bind(this);
        this.getTableauFilterFromPanelFilter = this.getTableauFilterFromPanelFilter.bind(this);
        this.sortWorkbooks = this.sortWorkbooks.bind(this);
        this.getFilterValues = this.getFilterValues.bind(this);
        this.handleOnEncodingChange = this.handleOnEncodingChange.bind(this);
        this.setTempFilterChanged = this.setTempFilterChanged.bind(this);
        this.callExportReportData = this.callExportReportData.bind(this);
        this.callExportToPdf = this.callExportToPdf.bind(this);

        this.mainContent = React.createRef();
        this.tableauWorkbookRef = React.createRef();

        this.state = {
            redirect: false,
            reportType: this.props.reportType, //"interactive" or "project"
            showModal: false,
            formValid: false,
            formErrors: "",
            moreMenuOptions: [],
            listOfProjects: [],
            currentSelectedProjects: [],
            historicalSelectedProjects: [],
            users: [],
            selectedUsers: [],
            fetchInProgress: false,
            dataRangeType: this.props.reportType === 'interactive' ? 'current' : '',
            dataRangeTypePrev: "",
            tableau: {},
            currentReports: [],
            historicalReports: [],
            instanceReports: [],
            workflowReport: false,
            reportCount: -1,
            workbookId: -1,
            sessionPersist: false,
            projectPersist: false,
            preferencePersist: false,
            projectId: -1,
            projectEditable: false,
            projectName: '',
            groupName: "",
            allPath: "",
            kpiCardId: -1,
            reportChanged: false,
            attributeMap: {},
            _notificationSystem: null,
            loaded: false,
            parameterErrors: {},
            showLoggedoutModal: false,
            sessionTerminated: false,
            absoluteStartError: null,
            absoluteEndError: null,
            nUpdate:0,
            showExportModal: false,
            selectedEncoding: [],
            exportInProgress: false,
            exportLimit: 50000,
            dataReport: {},
            filterApplied: false,
            filterReset: false,
            tempFilterChanged: false,
            setMainFocus: false
            // currentData: true,
            // tabReportID :-1
        }
    }

    setTempFilterChanged(value) {
        this.setState({
            tempFilterChanged: value
        })
    };

    //options: filter options, filterName: "APPLICATION NAME", type: "id" or "string"
    getFilterValues(options, filterName, type) {
        if (type === 'id') {
            let returnVal = options.filter(item => {
                if (item.selected === true && item.id !== null && item.id !== undefined) {
                    return true;
                } else {
                    return false;
                }
            });
            returnVal = returnVal.map(filter => filter.id);
            return returnVal;
        } else if (type === 'string') {
            let returnVal = options.filter(item => {
                if (item.selected === true) {
                    return true;
                } else {
                    return false;
                }
            });
            returnVal = returnVal.map(filter => filter.value);
            return returnVal;
        } else {
            return [];
        }
    }

    callExportReportData(e) {
        e.preventDefault();
        this.tableauWorkbookRef.current.viz.showExportDataDialog();
    }

    callExportToPdf(e) {
        e.preventDefault();
        this.tableauWorkbookRef.current.viz.showExportPDFDialog();
    }

    prepareExportDialog(e) {
        e.preventDefault();
        if(this.state.exportInProgress) {
            this.props.handleAlertClick("warning", "tc", getWarningMessages(this.props.t,'exportInProgress',  null, true));
            return;
        }
        this.setState({
            showExportModal: true
        });
    }

    handleShow(e) {
        e.preventDefault();

        const {reportType, workbookId, projectId} = this.state;
        let getStringAPI = reportType === 'interactive' ? `/report/list/project/${workbookId}` : `/project/view/share/${projectId}`;
        API.report.get(getStringAPI)
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;

                if (reportType === 'interactive') {
                    this.setState({
                        listOfProjects: data.projects,
                        showModal: true
                    });
                } else if (reportType === 'project') {
                    this.setState({
                        users: data.users,
                        selectedUsers: data.users.filter(user => {
                            return user.selected === true;
                        }),
                        showModal: true
                    });
                }
            })
            .catch(e => {
                //only log error for now
                console.error(e);
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }
                let resultCode = -1;
                if (e.data) {
                    resultCode = e.data.resultCode;
                }
                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    }

    removeReportFromProject = (e) => {
        e.preventDefault();

        const {workbookId, projectId} = this.state;
        let projectIdList = [];
        projectIdList.push(projectId);
        let parameterObj = {};
        parameterObj.projectIds = projectIdList;
        parameterObj.reportId = workbookId;


        API.project
            .post(`/project/remove/report`, parameterObj)
            .then(resp => {
                const {data: resData} = resp;
                const {resultCode} = resData;

                if (resultCode === 200) {
                    let inSupport = resp.data ? resp.data.inSupport : false;
                    this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t,"reportRemoved",null ), inSupport);
                    this.setState({redirect: true});
                } else {
                    this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'reportNotRemoved', null,  resultCode));
                }

            })
            .catch(e => {
                console.error(e);
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }
                let resultCode = -1;
                if (e.data) {
                    resultCode = e.data.resultCode;
                }
                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            })


    };

    handleCloseExport = () => {
        this.setState({
            showExportModal: false
        })
    }

    handleClose() {
        this.setState({
            showModal: false,
            formValid: true,
            formErrors: '',
            currentSelectedProjects: [],
            historicalSelectedProjects: []
        });
    }

    handleLogoutConfirm = () => {
        this.setState({sessionTerminated: true});
    };

    handleAuthorization401 = () => {
        this.setState({showLoggedoutModal: true});
    };
    
    // handleCurrentDataClick = (currentDataID) => {
    //     this.setState({currentData: !this.state.currentData});
    // }
    // handleHistoricalDataClick = (historicalDataID) => this.setState({currentData: !this.state.currentData});
    //

    handleSelect =  (key) => {
        this.menuSelect(key, "interactive");
    }
    
    menuSelect(key, type) {
        window.sessionStorage.setItem("showSpinner", "true");
        window.sessionStorage.removeItem('lastChangedFilter');
        
        //Closing the dropdown menu
        if(type==="project") {
            const reportDropdown = document.getElementById("dropdown-basic-0");
            const reportDropdownContainer = reportDropdown.parentElement;
            if (reportDropdown && reportDropdownContainer && reportDropdownContainer.classList.contains("open")) {
                reportDropdown.click();
            }
        } 

        //determine dataRangeType based on key
        let _dataRangeType = "";
        let tempCurrentReportIds = this.state.currentReports.length > 0 ? this.state.currentReports.map(x => x.id) : [];
        if (tempCurrentReportIds.includes(key)) {
            _dataRangeType = "current";
        } else {
            _dataRangeType = "historical";
        }

        if (key !== this.state.workbookId) {
            setSSReportObject(this.state.reportType, 'workbookId', key, this.state.projectId);
            setSSReportObject(this.state.reportType, 'dataRangeType', _dataRangeType, this.state.projectId);

            const tempResetFilter = getSSReportObject(this.state.reportType, _dataRangeType + 'Reset', this.state.projectId);
            const ssSessionPersist = getSSReportObject(this.state.reportType, 'sessionPersist', this.state.projectId);

            if (!ssSessionPersist || (ssSessionPersist && ssSessionPersist === false)) {
                setSSReportObject(this.state.reportType, _dataRangeType + 'MauiFilter', tempResetFilter, this.state.projectId);
                setSSReportObject(this.state.reportType, _dataRangeType + 'MauiFilterTemp', tempResetFilter, this.state.projectId);
            }

            if (this.state.kpiCardId > -1) {
                //Reset all home page drill into interactive report related information
                removeSSReportObject('interactive', 'kpiCardId', null);
                removeSSReportObject('interactive', 'currentMauiFilterDrill', null);
                removeSSReportObject('interactive', 'currentMauiFilterTempDrill', null);
                removeSSReportObject('interactive', 'sessionParameters', null);
            }

            this.setState({
                reportChanged: true,
                workbookId: key,
                dataRangeType: _dataRangeType,
                kpiCardId: -1,
                filterApplied: false,
                loaded: false,
                setMainFocus: false
            });
        } else if (this.state.dataRangeType === 'instance') {
            setSSReportObject(this.state.reportType, 'workbookId', key, this.state.projectId);
            setSSReportObject(this.state.reportType, 'dataRangeType', _dataRangeType, this.state.projectId);

            this.setState({
                workbookId: key,
                dataRangeType: _dataRangeType
            });
        }
    }

    drillClick = (currentWorkBook, instanceId) => {
        const btnDrill = document.getElementById('btnDrill' + instanceId);
        if (btnDrill) {
            const parentLi = btnDrill.parentElement;
            if (parentLi) {
                if (!parentLi.classList.contains('disabled')) {
                    //set instance workbook id in session storage
                    setSSReportObject(this.state.reportType, 'instanceWorkbookId', instanceId, this.state.projectId);

                    if (currentWorkBook['workflowReport'] && currentWorkBook['workflowReport'] !== null && currentWorkBook['workbookName'] !== 'user workflows') {
                        setSSReportObject(this.state.reportType, 'dataRangeType', "current", this.state.projectId);
                        this.setState({
                            dataRangeType: "current"
                        });
                    } else {
                        setSSReportObject(this.state.reportType, 'dataRangeType', "instance", this.state.projectId);
                        this.setState({
                            dataRangeType: "instance",
                            dataRangeTypePrev: this.state.dataRangeType
                        });

                    }
                }
            }
        }
        window.sessionStorage.setItem("showSpinner", "true");
        window.sessionStorage.setItem("clickFromInstance", "true");
    };

    removeLastReportSelections = (reportObject) => {
        if(reportObject) {
            if (reportObject.dataRangeType === 'current')
                window.sessionStorage.removeItem('workflowReport');
            else if (reportObject.dataRangeType === 'instance')
                window.sessionStorage.removeItem('lastInstanceReport');
        }
    };

    backClick = (currentWorkBook) => {
        let ssLastReport = {};
        let selectionsToBeRemovedReport = {};
        let ssLastReportTemp = JSON.parse(window.sessionStorage.getItem('lastReport'));
        if (currentWorkBook.workbookName === "user workflow" && ssLastReportTemp.name !== "user workflows") {
            ssLastReport = JSON.parse(window.sessionStorage.getItem('lastInstanceReport'));
            selectionsToBeRemovedReport = JSON.parse(window.sessionStorage.getItem('workflowReport'));
        } else {
            ssLastReport = ssLastReportTemp;
            selectionsToBeRemovedReport = JSON.parse(window.sessionStorage.getItem('lastInstanceReport'));
        }
        this.removeLastReportSelections(selectionsToBeRemovedReport);
        if (ssLastReport) {
            setSSReportObject(this.state.reportType, 'dataRangeType', ssLastReport.dataRangeType, this.state.projectId);
            this.setState({
                dataRangeType: ssLastReport.dataRangeType
            });
        }
        window.sessionStorage.setItem("showSpinner", "true");
        window.sessionStorage.removeItem('lastChangedFilter');
    };

    backClickFromLink = (currentWorkBook, dataRangeType) => {
        let ssLastReport = {};
        let ssLastReportTemp = JSON.parse(window.sessionStorage.getItem('lastReport'));
        if (currentWorkBook.workbookName === "user workflow" && ssLastReportTemp.name !== "user workflows") {
            ssLastReport = JSON.parse(window.sessionStorage.getItem('lastInstanceReport'));
        } else {
            ssLastReport = ssLastReportTemp;
        }
        if (ssLastReport) {
            setSSReportObject(this.state.reportType, 'dataRangeType', dataRangeType, this.state.projectId);
            this.setState({
                dataRangeType: dataRangeType
            });
        }
        window.sessionStorage.setItem("clickFromBreadcrumb", "true");
        window.sessionStorage.setItem("showSpinner", "true");
        window.sessionStorage.removeItem('lastChangedFilter');
    };

    handleAddToProjectUpdateCheckbox(id, isChecked) {
        let _this = this;
        const dataRangeType = this.state.dataRangeType;
        let projects = this.state.listOfProjects;
        let selectedProjects = dataRangeType === 'historical' ? this.state.historicalSelectedProjects : this.state.currentSelectedProjects;

        projects.map(project => {
            if (project.id === id) {
                if (isChecked && selectedProjects.indexOf(project.id) === -1) {
                    selectedProjects.push(project.id);
                } else if (!isChecked && selectedProjects.indexOf(project.id) !== -1) {
                    let index = selectedProjects.indexOf(project.id);
                    if (index > -1) {
                        selectedProjects.splice(index, 1);
                    }
                }
            }
        });

        if (dataRangeType === 'historical') {
            _this.setState({
                historicalSelectedProjects: selectedProjects
            });
        } else {
            _this.setState({
                currentSelectedProjects: selectedProjects
            });
        }
    }

    handleOnEncodingChange(selected) {
        this.setState({selectedEncoding: selected})
    };

    exportData() {
        let _this = this;
        let _exportFilter = {};
        this.handleCloseExport();
        this.setState({
            exportInProgress: true
        });

        const _lastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
        const projectId = (_lastReport.reportType==="project") ?  _lastReport.projectId : -1;
        const _savedFilter =  (_lastReport.reportType==="project")
            ? JSON.parse(window.sessionStorage.getItem('reportProject' + projectId))
            : JSON.parse(window.sessionStorage.getItem('reportInteractive'));
        const _reportFilter = (_savedFilter.dataRangeType === 'historical')  ? _savedFilter.historicalMauiFilter : _savedFilter.currentMauiFilter
        _exportFilter.workbookId = _savedFilter.workbookId;
        _exportFilter.absoluteTimeFrame = _reportFilter.absoluteTimeFrame;
        _exportFilter.relativeTimeFrame = _reportFilter.relativeTimeFrame;
        _exportFilter.filters = [];
        _exportFilter.encoding = this.state.selectedEncoding.id;
        for (const property in _reportFilter.filters) {
           _exportFilter.filters.push(_reportFilter.filters[property])
        }

        const exportInfo = JSON.parse(window.sessionStorage.getItem('exportInfo'));
        const reportName = exportInfo.workbook;
        const reportId = exportInfo.id;

        const exportWorkflowParam = JSON.parse(window.sessionStorage.getItem('exportWorkflowParam'));
        if(exportWorkflowParam) {
            _exportFilter.workflowDayId = exportWorkflowParam.dayId;
            _exportFilter.workflowUserName = exportWorkflowParam.username;
        }
        const markedFilters = JSON.parse(window.sessionStorage.getItem('markedFilters'));
        _exportFilter.markedFilters = [];
        for (const property in markedFilters) {
            const _markedFilter = {}
            _markedFilter.name = property;
            _markedFilter.values = markedFilters[property]
            _exportFilter.markedFilters.push(_markedFilter)
        }


        abortController = new AbortController();
        API.report
            .post('/report/export/'+reportId, _exportFilter,
                {responseType: 'blob',  signal: abortController.signal})
            .then(resp => {

                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;
                const fileName = reportName? reportName.replace(/\s/g, '_') + "_data.csv" : "KnoaAnalyticsData.csv"
                link.setAttribute('download',fileName);
                document.body.appendChild(link);
                link.click();
                // _this.handleCloseExport();

                _this.setState({
                    exportInProgress: false
                });
                abortController = null;
            })
            .catch(e => {
                console.error(e);
                _this.setState({
                    exportInProgress: false
                });
                abortController = null;
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }

                if(e.data && e.data.inSupport && e.data.resultCode)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, e.data.resultCode, true));
                else {
                    if(e.response.status===400)
                        this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'FAILED_EXPORT_ENCODING',  null,null));
                    else
                        this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, 500, true));
                }

            });
    }

    handleAddProject() {
        let itemsToSubmit = {};
        let _this = this;
        const dataRangeType = this.state.dataRangeType;
        let projects = this.state.listOfProjects;

        //projectIds
        itemsToSubmit.projectIds = dataRangeType === 'historical' ? this.state.historicalSelectedProjects : this.state.currentSelectedProjects;
        //reportId
        if (dataRangeType === "historical") {
            itemsToSubmit.reportId = this.state.historicalReports[0].id;
        } else {
            itemsToSubmit.reportId = this.state.currentReports[0].id;
        }

        const rules = {
            projectIds: 'required'
        };

        const data = {
            projectIds: itemsToSubmit.projectIds.toString()
        };

        const messages = {
            required: this.props.t('validation:cardMultiSelect')
        };

        validateAll(data, rules, messages)
            .then(() => {
                API.project
                    .post('/project/add/report', itemsToSubmit)
                    .then(resp => {
                        const {data: resData} = resp;

                        let inSupport = resData ? resData.inSupport : false;

                        const updatedProjects = projects.filter(project => itemsToSubmit.projectIds.indexOf(project.id) <= -1);
                        //
                        // console.log(`The report was added successfully! \n` +
                        //     JSON.stringify(itemsToSubmit)
                        // );

                        _this.setState({
                            listOfProjects: updatedProjects,
                            formValid: true,
                            formErrors: ''
                        });

                        _this.handleClose();
                        this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t,'generic',  "The report was added."), inSupport);

                    })
                    .catch(e => {
                        console.error(e);
                        if (localStorage.get("accessDenied401") === "true") {
                            this.setState({showLoggedoutModal: true});
                            return;
                        }
                        if(e.data && e.data.inSupport && e.data.resultCode)
                            this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, e.data.resultCode, true));
                        else
                            this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'genericPleaseTryAgain',  "The report was not added.",null));
                    });
            })
            .catch(errors => {
                console.log(errors);
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }
                this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'genericPleaseTryAgain',  "The report was not added.",null));
                const formattedErrors = {};
                let errorClass = "";
                errors.forEach(error => {
                    formattedErrors[error.field] = error.message;
                    if (error.field === "projectReports") {
                        errorClass = "form-validation-input-error";
                    } else {
                        errorClass = "form-control form-validation-input-error"
                    }
                });
                _this.setState({
                    formErrors: formattedErrors,
                    formValid: false
                });
            });
    }

    handleShareProject() {
        let itemsToSubmit = {};
        let _this = this;

        let selectedUserIDs = [];
        this.state.selectedUsers.forEach(opt => {
            selectedUserIDs.push(opt.id);
        });

        itemsToSubmit.team = selectedUserIDs;

        const data = {
            team: selectedUserIDs.toString()
        };
        const rules = {
            team: [validations.required()]
        };
        const messages = {
            required: this.props.t('validation:cardMultiSelect')
        };

        validateAll(data, rules, messages)
            .then(() => {
                API.project
                    .post(`/project/share/${_this.state.projectId}`, itemsToSubmit)
                    .then(resp => {
                        const {data: resData} = resp;

                        let inSupport = resData ? resData.inSupport : false;

                        // console.log(`The projected was shared successfully! \n` +
                        //     JSON.stringify(_this.state.selectedUsers)
                        // );
                        _this.handleClose();
                        this.props.handleAlertClick("success", "tc",getSuccessMessages(this.props.t,'generic',  "The project was shared."), inSupport);
                    })
                    .catch(e => {
                        console.error(e);
                        if (localStorage.get("accessDenied401") === "true") {
                            this.setState({showLoggedoutModal: true});
                            return;
                        }
                        if(e.data && e.data.inSupport && e.data.resultCode)
                            this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, e.data.resultCode, true));
                        else
                            this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'genericPleaseTryAgain', "The project was not shared!",null));
                    });
            }).catch(errors => {
            console.log(errors);
            if (localStorage.get("accessDenied401") === "true") {
                this.setState({showLoggedoutModal: true});
                return;
            }
            const formattedErrors = {};
            let errorClass = "";
            errors.forEach(error => {
                formattedErrors[error.field] = error.message;
                errorClass = "form-validation-input-error"
            });
            _this.setState({
                formErrors: formattedErrors,
            });
        });
    }

    handleShareProjectUpdate(selectedUsers) {
        this.setState({selectedUsers: selectedUsers})
    };

    sortWorkbooks(data) {
        return data.sort((a, b) => (a.workbookName > b.workbookName) ? 1 : -1);
    }

    //name: radioTimeframeOptions|relativeSlider|absoluteStartDate|absoluteEndDate
    //dataRange: Current|Historical
    //value: RELATIVE|ABSOLUTE
    updateTimeFilterValues(name, dataRange, value) {
        let reportType = this.state.kpiCardId > -1 ? 'interactive' : this.state.reportType;
        let reportObjName = this.state.kpiCardId > -1 ? 'currentMauiFilterTempDrill' : dataRange.toLowerCase() + 'MauiFilterTemp';
        let projectId = this.state.kpiCardId > -1 ? null : this.state.projectId;
        let latestTempFilters = getSSReportObject(reportType, reportObjName, projectId);

        if (name === "radioTimeframeOptions") {
            if (value === "RELATIVE") {
                let selectedReltiveTimeFrame = latestTempFilters.relativeTimeFrame === null ? {} : latestTempFilters.relativeTimeFrame;
                let lastestAbsoluteTimeFrame = latestTempFilters.absoluteTimeFrame;
                selectedReltiveTimeFrame.selected = true;
                if (selectedReltiveTimeFrame === "current") {
                    selectedReltiveTimeFrame.type = "CURRENT";
                } else if (dataRange === "Historical") {
                    selectedReltiveTimeFrame.type = "HISTORICAL";
                }
                latestTempFilters.relativeTimeFrame = selectedReltiveTimeFrame;
                if (lastestAbsoluteTimeFrame !== null) {
                    lastestAbsoluteTimeFrame.selected = false;
                }
                latestTempFilters.absoluteTimeFrame = lastestAbsoluteTimeFrame;
            } else if (value === "ABSOLUTE") {
                let selectedAbsTimeFrame = latestTempFilters.absoluteTimeFrame === null ? {} : latestTempFilters.absoluteTimeFrame;
                let lastestRelativeTimeFrame = latestTempFilters.relativeTimeFrame;
                selectedAbsTimeFrame.selected = true;
                if (dataRange === "Current") {
                    selectedAbsTimeFrame.type = "CURRENT";
                } else if (dataRange === "Historical") {
                    selectedAbsTimeFrame.type = "HISTORICAL";
                }
                latestTempFilters.absoluteTimeFrame = selectedAbsTimeFrame;
                if (lastestRelativeTimeFrame !== null) {
                    lastestRelativeTimeFrame.selected = false;
                }
                latestTempFilters.relativeTimeFrame = lastestRelativeTimeFrame;
            }
        } else if (name === "relativeSlider") {
            let selectedReltiveTimeFrame = latestTempFilters.relativeTimeFrame === null ? {} : latestTempFilters.relativeTimeFrame;
            selectedReltiveTimeFrame.selected = true;
            selectedReltiveTimeFrame.value = value;
            latestTempFilters.relativeTimeFrame = selectedReltiveTimeFrame;
        } else if (name === "absoluteStartDate") {
            let selectedAbsTimeFrame = latestTempFilters.absoluteTimeFrame === null ? {} : latestTempFilters.absoluteTimeFrame;
            selectedAbsTimeFrame.selected = true;
            if (typeof (value) === "string") {
                selectedAbsTimeFrame.start = value;
            } else {
                const startDate = value.toDate();
                selectedAbsTimeFrame.start = startDate;
                if (selectedAbsTimeFrame.end < selectedAbsTimeFrame.start) {
                    selectedAbsTimeFrame.end = value.toDate();
                }
            }
            latestTempFilters.absoluteTimeFrame = selectedAbsTimeFrame;
        } else if (name === "absoluteEndDate") {
            let selectedAbsTimeFrame = latestTempFilters.absoluteTimeFrame === null ? {} : latestTempFilters.absoluteTimeFrame;
            selectedAbsTimeFrame.selected = true;
            if (typeof (value) === "string") {
                selectedAbsTimeFrame.end = value;
            } else {
                selectedAbsTimeFrame.end = value.toDate();
            }
            latestTempFilters.absoluteTimeFrame = selectedAbsTimeFrame;
        }

        if (typeof (latestTempFilters.absoluteTimeFrame.start) === "string") latestTempFilters.absoluteTimeFrame.start = new Date(latestTempFilters.absoluteTimeFrame.start);
        if (typeof (latestTempFilters.absoluteTimeFrame.end) === "string") latestTempFilters.absoluteTimeFrame.end = new Date(latestTempFilters.absoluteTimeFrame.end);

        window.sessionStorage.setItem("lastChangedFilter", "TIME");
        setSSReportObject(reportType, reportObjName, latestTempFilters, projectId);
        this.setState({ nUpdate: this.state.nUpdate + 1 });
        //this.forceUpdate();
    };

    updateApplicationsAndUserFilterValues(type, value) {
        let reportType = this.state.kpiCardId > -1 ? 'interactive' : this.state.reportType;
        let reportObjName = this.state.kpiCardId > -1 ? 'currentMauiFilterTempDrill' : this.state.dataRangeType + 'MauiFilterTemp';
        let projectId = this.state.kpiCardId > -1 ? null : this.state.projectId;
        let latestTempFilters = getSSReportObject(reportType, reportObjName, projectId);
        latestTempFilters.filters[type] = value;
        
        // let reportObjNameOriginal = this.state.kpiCardId > -1 ? 'currentMauiFilterDrill' : this.state.dataRangeType + 'MauiFilter';
        // let latestFilters = getSSReportObject(reportType, reportObjNameOriginal, projectId);
        //
        // if(_.isEqual(latestFilters, latestTempFilters)) return;

        window.sessionStorage.setItem("lastChangedFilter", type);
        setSSReportObject(reportType, reportObjName, latestTempFilters, projectId);
         this.setState({ nUpdate: this.state.nUpdate + 1 });
          //this.forceUpdate();
    };

    updateParametersValues = (field, inputvalue) => {
        //basing on field name to update the value
        let reportType = this.state.kpiCardId > -1 ? 'interactive' : this.state.reportType;
        let reportObjName = this.state.kpiCardId > -1 ? 'currentMauiFilterTempDrill' : this.state.dataRangeType + 'MauiFilterTemp';
        let projectId = this.state.kpiCardId > -1 ? null : this.state.projectId;
        let latestTempFilters = getSSReportObject(reportType, reportObjName, projectId);
        let oldTableauParams = latestTempFilters.tableauParams;

        let updatedTableauParams = [];
        oldTableauParams.forEach(p => {
            if (p.name.includes(field.toUpperCase())) {
                p.value = inputvalue;
            }
            updatedTableauParams.push(p);
        });

        latestTempFilters.tableauParams = updatedTableauParams;
        setSSReportObject(reportType, reportObjName, latestTempFilters, projectId);
        this.setState({ nUpdate: this.state.nUpdate + 1 });
        //this.forceUpdate();
    };

    resetFiltersForObject(reportType, dataRangeType) {
        let latestFilters = getSSReportObject(reportType, dataRangeType + 'MauiFilter', this.state.projectId);
        if (latestFilters) {
            let resetFilters = getSSReportObject(reportType, dataRangeType + 'Reset', this.state.projectId);

            latestFilters.relativeTimeFrame = resetFilters.relativeTimeFrame;
            if (reportType === 'interactive') {
                latestFilters.relativeTimeFrame.selected = true;
            }
            latestFilters.absoluteTimeFrame = resetFilters.absoluteTimeFrame;
            if (latestFilters.absoluteTimeFrame !== null) {
                if (typeof (latestFilters.absoluteTimeFrame.start) === "string") latestFilters.absoluteTimeFrame.start = new Date(latestFilters.absoluteTimeFrame.start);
                if (typeof (latestFilters.absoluteTimeFrame.end) === "string") latestFilters.absoluteTimeFrame.end = new Date(latestFilters.absoluteTimeFrame.end);
            }

            Object.entries(resetFilters.filters).forEach(prop => {
                if (latestFilters.filters[prop[0]]) {
                    latestFilters.filters[prop[0]].options = prop[1].options;
                    latestFilters.filters[prop[0]].regex = prop[1].regex;
                }
            });

            latestFilters.tableauParams = resetFilters.tableauParams;

            setSSReportObject(reportType, dataRangeType + 'MauiFilter', latestFilters, this.state.projectId);
            setSSReportObject(reportType, dataRangeType + 'MauiFilterTemp', latestFilters, this.state.projectId);
            setSSReportObject(reportType, dataRangeType + 'Reset', resetFilters, this.state.projectId);
        }
    }

    handleResetFilters() {
        this.resetFiltersForObject(this.state.reportType, 'current');
        this.resetFiltersForObject(this.state.reportType, 'historical');
        this.setState({
            parameterErrors: {}
        });
        //Update sessionPersist (non global) indicating that the user have not applied anything new while sessionPersist was on
        setSSReportObject(this.state.reportType, 'sessionPersist', false, this.state.projectId);

        window.sessionStorage.setItem('updateTableau', "true");
        window.sessionStorage.setItem("showSpinner", "true");

        if (this.state.kpiCardId > -1) {
            //Reset all home page drill into interactive report related information
            removeSSReportObject('interactive', 'kpiCardId', null);
            removeSSReportObject('interactive', 'currentMauiFilterDrill', null);
            removeSSReportObject('interactive', 'currentMauiFilterTempDrill', null);
            removeSSReportObject('interactive', 'sessionParameters', null);
            this.setState({
                kpiCardId: -1,
                parameterErrors: {}
            });
        } else {
            this.setState({
                nUpdate: this.state.nUpdate + 1,
                filterApplied: false,
                filterReset: true,
                tempFilterChanged: false
            });
            //this.forceUpdate();
        }
        window.sessionStorage.removeItem('lastChangedFilter');
    };

    validateTimeFrameForAbs = (tempFilters) => {
        //let isValid = true;

        let ASErrorFound = false;
        let AEErrorFound = false;
        let dateFormat = "";
        let type = "";

        let tempAbsTimeFrame = tempFilters.absoluteTimeFrame;
        let absCalendarStart;
        if (tempAbsTimeFrame && tempAbsTimeFrame['selected'] === true) {
            if (tempAbsTimeFrame['type'] === 'CURRENT') {
                dateFormat = "MM/DD/YYYY";
                type = "Current";
                absCalendarStart = tempFilters.currentAbsoluteCalendarStart;
            } else {
                dateFormat = "MM/YYYY";
                type = "Historical";
                absCalendarStart = tempFilters.historicalAbsoluteCalendarStart;
            }

            const startDateAbsolute = document.getElementById('startDateAbsolute' + type);
            const endDateAbsolute = document.getElementById('endDateAbsolute' + type);

            if (startDateAbsolute && endDateAbsolute) {
                let startDate = new Date(getPartOfDate(absCalendarStart, "year"),
                    getPartOfDate(absCalendarStart, "month"),
                    getPartOfDate(absCalendarStart, "day"), 0, 0, 0
                );
                let endDate = new Date(getPartOfDate(tempAbsTimeFrame['start'], "year"),
                    getPartOfDate(tempAbsTimeFrame['start'], "month"),
                    getPartOfDate(tempAbsTimeFrame['start'], "day"), 0, 0, 0
                );


                let absoluteStartDateValue = {};
                let absoluteEndDateValue = {};

                if (type === "Current") {
                    absoluteStartDateValue = new Date(startDateAbsolute.value);
                    absoluteEndDateValue = new Date(endDateAbsolute.value);
                } else if (type === "Historical") {
                    absoluteStartDateValue = new Date(startDateAbsolute.value.slice(0, 3) + "01" + startDateAbsolute.value.slice(2));
                    absoluteEndDateValue = new Date(endDateAbsolute.value.slice(0, 3) + "01" + endDateAbsolute.value.slice(2));
                }


                if (!moment(startDateAbsolute.value, dateFormat, true).isValid()) {
                    this.setState({
                        absoluteStartError: (<small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                    });
                    ASErrorFound = true;
                } else if (absoluteStartDateValue.valueOf() > 0) {
                    if (startDate.valueOf() > 0 && absoluteStartDateValue.valueOf() < startDate.valueOf()) {
                        this.setState({
                            absoluteStartError: (
                                <small className="text-danger">{this.props.t('validation:dateOutOfRange')}</small>)
                        });
                        ASErrorFound = true;
                    }
                } else {
                    this.setState({
                        absoluteStartError: (<small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                    });
                    ASErrorFound = true;
                }


                if (!moment(endDateAbsolute.value, dateFormat, true).isValid()) {
                    this.setState({
                        absoluteEndError: (<small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                    });
                    AEErrorFound = true;
                } else if (absoluteEndDateValue.valueOf() > 0) {
                    if (endDate.valueOf() > 0 && absoluteEndDateValue.valueOf() < endDate.valueOf()) {

                        this.setState({
                            absoluteEndError: (
                                <small className="text-danger">{this.props.t('validation:dateEndBeforeStart')}</small>)
                        });
                        AEErrorFound = true;
                    }
                } else {
                    this.setState({
                        absoluteEndError: (<small className="text-danger">{this.props.t('validation:invalidDate')}</small>)
                    });
                    AEErrorFound = true;
                }

            }
        }

        if (ASErrorFound || AEErrorFound) {
            if (!ASErrorFound) {
                this.setState({absoluteStartError: null});
            }
            if (!AEErrorFound) {
                this.setState({absoluteEndError: null});
            }
            return false;
        } else {
            this.setState({
                absoluteStartError: null,
                absoluteEndError: null
            });
            return true;
        }

    };

    handleApplyNewFilters() {
        let reportType = this.state.kpiCardId > -1 ? 'interactive' : this.state.reportType;
        let reportObjName1 = this.state.kpiCardId > -1 ? 'currentMauiFilterDrill' : this.state.dataRangeType + 'MauiFilter';
        let reportObjName2 = this.state.kpiCardId > -1 ? 'currentMauiFilterTempDrill' : this.state.dataRangeType + 'MauiFilterTemp';
        let projectId = this.state.kpiCardId > -1 ? null : this.state.projectId;

        let latestFilters = getSSReportObject(reportType, reportObjName1, projectId);
        let latestFiltersTemp = getSSReportObject(reportType, reportObjName2, projectId);

        //update application name filter values in case user visits the User Workflows report
        let tempCurrentReportFilters = latestFilters.filters;
        let tempLatestReportFilters = latestFiltersTemp.filters;
        for(const filter in latestFilters.filters) {
            if(latestFilters.filters.hasOwnProperty(filter)) {
                if(FILTERS.isUserWorkflowsIgnore(filter,this.state.workbookId)) {
                    if(!this.state.filterApplied || this.state.filterReset) {
                        if(!this.state.tempFilterChanged) {
                            tempLatestReportFilters[filter].options = [];
                            if (tempLatestReportFilters[filter].regex) {
                                delete tempLatestReportFilters[filter].regex;
                            }
                        }
                        tempCurrentReportFilters[filter].options = [];
                        if(tempCurrentReportFilters[filter].regex) {
                           delete tempCurrentReportFilters[filter].regex;
                        }
                    }
                }
            }
        }
        latestFilters.filters = tempCurrentReportFilters;
        latestFiltersTemp.filters = tempLatestReportFilters;

        // absolute timeframe validation
        let isAbsTimeFrameValid = this.validateTimeFrameForAbs(latestFiltersTemp);

        //parameters validations
        let tableauParamsForValidations = latestFiltersTemp.tableauParams;
        let errors = [];
        tableauParamsForValidations.forEach(paramObj => {
            let currentParamValue = paramObj.value;
            let currentErrorObj = {};
            if (isNaN(currentParamValue)) {
                currentErrorObj["field"] = getNameforStyles(paramObj.name);
                currentErrorObj["message"] = this.props.t('validation:number');
            } else if ((currentParamValue!==null && currentParamValue.startsWith("-")) || Number(currentParamValue) < 0) {
                currentErrorObj["field"] = getNameforStyles(paramObj.name);
                currentErrorObj["message"] = this.props.t('validation:numberLess', {input: "0"});
            } else if (currentParamValue !== null && currentParamValue.length > 1 && currentParamValue.charAt(0) === '0') {
                currentErrorObj["field"] = getNameforStyles(paramObj.name);
                currentErrorObj["message"] = this.props.t('validation:numberZero');
            } else if (currentParamValue === "") {
                currentErrorObj["field"] = getNameforStyles(paramObj.name);
                currentErrorObj["message"] = this.props.t('validation:emptyValue');
            } else if(Number(currentParamValue) > 0 ){
                let fieldName = getNameforStyles(paramObj.name);
                if(fieldName==='annualFTECost' && Number(currentParamValue)> 1000000 ){
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "1000000"});
                } else if(fieldName==='annualFTEHours' && Number(currentParamValue)> 10000 ){
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "10000"});
                } else if(fieldName==='LTNonError' && Number(currentParamValue)> 100000 ){
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100000"});
                } else if(fieldName==='LTSysError' && Number(currentParamValue)> 100000 ){
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100000"});
                } else if(fieldName==='LTUserError' && Number(currentParamValue)> 100000 ){
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100000"});
                } else if(fieldName==='LTUncatMessage' && Number(currentParamValue)> 100000 ){
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100000"});
                } else if(fieldName==='projectDuration' && Number(currentParamValue)> 100 ){
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100"});
                } else if(fieldName==='projectUser' && Number(currentParamValue)> 1000000 ){
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "1000000"});
                } else if(fieldName==='testEffortPerModule' && Number(currentParamValue)> 1000 ){
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "1000"});
                }
            }

            if (!(Object.keys(currentErrorObj).length === 0 && currentErrorObj.constructor === Object)) {
                errors.push(currentErrorObj);
            }
        });

        if (errors.length > 0 || !isAbsTimeFrameValid) {
            if (!isAbsTimeFrameValid) {
                this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'reportAbsTimeFrameIssues', null,null));
            }
            if (errors.length > 0) {
                let formattedErrors = {};
                errors.forEach(error => {
                    formattedErrors[error.field] = error.message;
                });
                this.setState({
                    parameterErrors: formattedErrors
                });
                this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'reportParameterIssues',null, null));
            }

            return false;
        }

        this.setState({
            parameterErrors: {}
        });

        if (_.isEqual(latestFilters, latestFiltersTemp) && ((this.state.reportType === 'interactive' && this.state.preferencePersist === false) || (this.state.reportType === 'project' && this.state.projectPersist === false))) {
            // no change
            return;
        }
        window.sessionStorage.setItem("showSpinner", "true");

        if (latestFiltersTemp.absoluteTimeFrame !== null) {
            if (typeof (latestFiltersTemp.absoluteTimeFrame.start) === "string") latestFiltersTemp.absoluteTimeFrame.start = new Date(latestFiltersTemp.absoluteTimeFrame.start);
            if (typeof (latestFiltersTemp.absoluteTimeFrame.end) === "string") latestFiltersTemp.absoluteTimeFrame.end = new Date(latestFiltersTemp.absoluteTimeFrame.end);
        }

        if ((this.state.preferencePersist && this.state.preferencePersist === true) || (this.state.projectPersist && this.state.projectPersist === true)) {
            this.setState({fetchInProgress: true});

            //Indicating that the latest applied values match what is the preferences. No new applied values were set while sessionPersist as on.
            if (!this.state.sessionPersist) {
                setSSReportObject(this.state.reportType, 'sessionPersist', false, this.state.projectId);
            } else {
                setSSReportObject(this.state.reportType, 'sessionPersist', true, this.state.projectId);
            }

            let itemsToSubmit = {};
            // convert the latestFilters to format parameters
            const appContextName = FILTERS.getApplicationName("contextName");
            if (!latestFiltersTemp.filters[appContextName] || latestFiltersTemp.filters[appContextName].enabled === false) {
                itemsToSubmit.applicationValueFilter = [];
            } else {
                itemsToSubmit.applicationValueFilter = this.getFilterValues(latestFiltersTemp.filters[appContextName].options, appContextName, "string");
            }

            const moduleContextName = FILTERS.getModuleName("contextName");
            if (!latestFiltersTemp.filters[moduleContextName] || latestFiltersTemp.filters[moduleContextName].enabled === false) {
                itemsToSubmit.moduleValueFilters = [];
            } else {
                itemsToSubmit.moduleValueFilters = this.getFilterValues(latestFiltersTemp.filters[moduleContextName].options, moduleContextName, "string");
            }

            const scGroupContextName = FILTERS.getScreenGroupName("contextName");
            if (!latestFiltersTemp.filters[scGroupContextName] || latestFiltersTemp.filters[scGroupContextName].enabled === false) {
                //applicationFilter && //applicationValueFilter
                itemsToSubmit.screenGroupFilter = [];
            } else {
                itemsToSubmit.screenGroupFilter = this.getFilterValues(latestFiltersTemp.filters[scGroupContextName].options, scGroupContextName, "id");
            }

            let tempTimeFilterObj = {};
            tempTimeFilterObj.useRelative = latestFiltersTemp.relativeTimeFrame.selected;
            if (tempTimeFilterObj.useRelative) {
                tempTimeFilterObj.relativeTime = latestFiltersTemp.relativeTimeFrame.value;
            } else if (this.state.reportType === 'project') {
                tempTimeFilterObj.absoluteStart = formatDateToString(latestFiltersTemp.absoluteTimeFrame.start);
                tempTimeFilterObj.absoluteEnd = formatDateToString(latestFiltersTemp.absoluteTimeFrame.end);
            }
            itemsToSubmit[latestFiltersTemp.reportType + 'TimeFilter'] = tempTimeFilterObj;

            let userAttributesList = Object.entries(latestFiltersTemp.filters).filter(x => x[0].startsWith(FILTERS.getUserAttributePrefix()) && x[1].enabled === true);
            let uaParam = [];
            userAttributesList.forEach(att => {
                att[1].options.filter(v => v.selected === true).map(v => v.id).forEach(vv => uaParam.push(vv))
            });
            itemsToSubmit.userAttributes = uaParam;

            let newTableauParameters = [];
            let newTableauParamObj = latestFiltersTemp.tableauParams;
            newTableauParamObj.forEach(p => {
                let tempParam = {};
                let tempName = p.name;
                tempName = tempName.replace("($)", "(CURRENCY)").replace("TIME COST NON ERRORS (S)", "Lost time due to non errors (seconds)").replace("TIME COST SYSTEM ERRORS (S)", "Lost time due to system errors (seconds)").replace("TIME COST USER ERRORS (S)", "Lost time due to user errors (seconds)").replace("TIME COST UNCATEGORIZED (S)", "Lost Time Due to Uncategorized Messages (seconds)").replace("(H)", "(hours)");
                tempParam.name = tempName;
                tempParam.value = p.value;
                newTableauParameters.push(tempParam);
            });
            itemsToSubmit.tableauParameters = newTableauParameters;

            let getStringAPI = this.state.reportType === 'interactive' ? '/user/filter' : `/project/filter/${this.state.projectId}`;

            API.project
                .post(getStringAPI, itemsToSubmit)
                .then(resp => {
                    const {data: resData} = resp;

                    let inSupport = resData ? resData.inSupport : false;

                    // console.log(`The preferences values were saved successfully! \n` + JSON.stringify(itemsToSubmit));
                    let messageItem = this.state.reportType === 'interactive' ? 'The filter values were saved to my preferences.' : 'The filter values were saved to the project.';
                    this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t,'generic',  messageItem), inSupport);
                    this.setState({fetchInProgress: false});
                })
                .catch(e => {
                    console.error(e);
                    this.setState({fetchInProgress: false});
                    if (localStorage.get("accessDenied401") === "true") {
                        this.setState({showLoggedoutModal: true});
                        return;
                    }
                    this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,'genericPleaseTryAgain', "The filter values were not saved.",null));
                });
        } else {
            setSSReportObject(this.state.reportType, 'sessionPersist', this.state.sessionPersist, this.state.projectId);
        }

        //Update Session Storage
        if ((this.state.sessionPersist && this.state.sessionPersist === true) || (this.state.preferencePersist && this.state.preferencePersist === true) || (this.state.projectPersist && this.state.projectPersist === true)) {
            if (this.state.dataRangeType === 'current') {
                setSSReportObject(this.state.reportType, 'currentMauiFilter', latestFiltersTemp, this.state.projectId);
                setSSReportObject(this.state.reportType, 'currentMauiFilterTemp', latestFiltersTemp, this.state.projectId);

                //partial update historical filters too
                const ssHistoricalMauiFilter = getSSReportObject(this.state.reportType, 'historicalMauiFilter', this.state.projectId);
                if (ssHistoricalMauiFilter) {
                    ssHistoricalMauiFilter.filters = latestFiltersTemp.filters;
                    ssHistoricalMauiFilter.tableauParams = latestFiltersTemp.tableauParams;
                    setSSReportObject(this.state.reportType, 'historicalMauiFilter', ssHistoricalMauiFilter, this.state.projectId);
                    setSSReportObject(this.state.reportType, 'historicalMauiFilterTemp', ssHistoricalMauiFilter, this.state.projectId);
                }

                //reset filters should be updated
                if ((this.state.preferencePersist && this.state.preferencePersist === true) || (this.state.projectPersist && this.state.projectPersist === true)) {
                    setSSReportObject(this.state.reportType, 'currentReset', latestFiltersTemp, this.state.projectId);
                    if (ssHistoricalMauiFilter) {
                        setSSReportObject(this.state.reportType, 'historicalReset', ssHistoricalMauiFilter, this.state.projectId);
                    }
                }
            } else if (this.state.dataRangeType === 'historical') {
                setSSReportObject(this.state.reportType, 'historicalMauiFilter', latestFiltersTemp, this.state.projectId);
                setSSReportObject(this.state.reportType, 'historicalMauiFilterTemp', latestFiltersTemp, this.state.projectId);

                //partial update current filters too
                const ssCurrentMauiFilter = getSSReportObject(this.state.reportType, 'currentMauiFilter', this.state.projectId);
                if (ssCurrentMauiFilter) {
                    ssCurrentMauiFilter.filters = latestFiltersTemp.filters;
                    ssCurrentMauiFilter.tableauParams = latestFiltersTemp.tableauParams;
                    setSSReportObject(this.state.reportType, 'currentMauiFilter', ssCurrentMauiFilter, this.state.projectId);
                    setSSReportObject(this.state.reportType, 'currentMauiFilterTemp', ssCurrentMauiFilter, this.state.projectId);
                }

                //reset filters should be updated
                if ((this.state.preferencePersist && this.state.preferencePersist === true) || (this.state.projectPersist && this.state.projectPersist === true)) {
                    setSSReportObject(this.state.reportType, 'historicalReset', latestFiltersTemp, this.state.projectId);
                    if (ssCurrentMauiFilter) {
                        setSSReportObject(this.state.reportType, 'currentReset', ssCurrentMauiFilter, this.state.projectId);
                    }
                }
            }

            // common parameters session update
            // let latestSessionTableauParameters = getSSReportObject(this.state.reportType, "sessionParameters", projectId);
            // if (latestSessionTableauParameters === null || latestSessionTableauParameters === undefined) {
            //     setSSReportObject(this.state.reportType, 'sessionParameters', latestFiltersTemp.tableauParams, this.state.projectId);
            // } else {
            //     let updatedTableauParams = latestFiltersTemp.tableauParams;
            //     let extraSessionParameters = [];
            //     latestSessionTableauParameters.forEach(sp => {
            //         let commonSPs = updatedTableauParams.filter(up => up.name === sp.name);
            //         if (commonSPs.length === 0) extraSessionParameters.push(sp);
            //     });
            //
            //     let newCombinedSessionParams = updatedTableauParams.concat(extraSessionParameters);
            //     setSSReportObject(this.state.reportType, 'sessionParameters', newCombinedSessionParams, this.state.projectId);
            // }
            setSSReportObject(this.state.reportType, 'sessionParameters', latestFiltersTemp.tableauParams, this.state.projectId);
        } else {
            setSSReportObject(this.state.reportType, this.state.dataRangeType + 'MauiFilter', latestFiltersTemp, this.state.projectId);
            setSSReportObject(this.state.reportType, this.state.dataRangeType + 'MauiFilterTemp', latestFiltersTemp, this.state.projectId);
            //setSSReportObject(this.state.reportType, 'sessionParameters', latestFiltersTemp.tableauParams, this.state.projectId);
        }

        window.sessionStorage.setItem('updateTableau', "true");

        if (this.state.kpiCardId > -1) {
            //Reset all home page drill into interactive report related information
            removeSSReportObject('interactive', 'kpiCardId', null);
            removeSSReportObject('interactive', 'currentMauiFilterDrill', null);
            removeSSReportObject('interactive', 'currentMauiFilterTempDrill', null);
            removeSSReportObject('interactive', 'sessionParameters', null);
            
            this.setState({kpiCardId: -1});
        } else {
            this.setState({
                nUpdate: this.state.nUpdate + 1,
                filterApplied: true,
                filterReset: false,
                tempFilterChanged: false
            });
            //this.forceUpdate();
        }
        window.sessionStorage.removeItem('lastChangedFilter');
    };

    handleSessionPersistChange(elem, sessionPersist) {
        setSSGlobalOptionsObject('sessionPersist', sessionPersist);
        this.setState({sessionPersist: sessionPersist});
    };

    handlePreferencePersistChange(elem, preferencePersist) {
        setSSGlobalOptionsObject('preferencePersist', preferencePersist);
        this.setState({preferencePersist: preferencePersist});
    };

    handleProjectPersistChange(elem, projectPersist) {
        setSSGlobalOptionsObject('projectPersist', projectPersist);
        this.setState({projectPersist: projectPersist});
    };

    getLastestTabTimeFilterType(lastestReportFilters) {
        if (lastestReportFilters.relativeTimeFrame !== null && lastestReportFilters.relativeTimeFrame.selected === true) {
            return "RELATIVE";
        } else if (lastestReportFilters.absoluteTimeFrame !== null && lastestReportFilters.absoluteTimeFrame.selected === true) {
            return "ABSOLUTE";
        } else
            return undefined;
    };

    getTableauFilterFromPanelFilter(lastestReportFilters, action) {
        let lastestTableauTimeFilterType = this.getLastestTabTimeFilterType(lastestReportFilters);
        let tempTableauFilters = {};
        tempTableauFilters.contextFilter = {};
        tempTableauFilters.timeFilterType = lastestTableauTimeFilterType;

        let currentDataType = lastestReportFilters.reportType === "" ? "current" : lastestReportFilters.reportType;
        if (currentDataType === "current") {
            if (lastestTableauTimeFilterType === "RELATIVE") {
                tempTableauFilters.currentTimeFilter = lastestReportFilters.relativeTimeFrame.value;
            } else if (lastestTableauTimeFilterType === "ABSOLUTE") {
                tempTableauFilters.currentTimeFilter = formatDateToString(lastestReportFilters.absoluteTimeFrame.start) + "-" + formatDateToString(lastestReportFilters.absoluteTimeFrame.end);
            }

            if (tempTableauFilters.contextFilter !== undefined) {
                tempTableauFilters.contextFilter["START DAY ID"] = getStartDayID(lastestReportFilters.reportType, lastestTableauTimeFilterType, lastestReportFilters.relativeTimeFrame, lastestReportFilters.absoluteTimeFrame);
                tempTableauFilters.contextFilter["END DAY ID"] = getEndDayID(lastestReportFilters.reportType, lastestTableauTimeFilterType, lastestReportFilters.relativeTimeFrame, lastestReportFilters.absoluteTimeFrame);
                tempTableauFilters.contextFilter["BENCHMARK START DAY ID"] = calculateBenchmarkID(lastestReportFilters.reportType, lastestTableauTimeFilterType, lastestReportFilters.relativeTimeFrame, lastestReportFilters.absoluteTimeFrame, "start");
                tempTableauFilters.contextFilter["BENCHMARK END DAY ID"] = calculateBenchmarkID(lastestReportFilters.reportType, lastestTableauTimeFilterType, lastestReportFilters.relativeTimeFrame, lastestReportFilters.absoluteTimeFrame, "end");
            }
        } else if (currentDataType === "historical") {
            if (lastestTableauTimeFilterType === "RELATIVE") {
                tempTableauFilters.historicalTimeFilter = lastestReportFilters.relativeTimeFrame.value;
            } else if (lastestTableauTimeFilterType === "ABSOLUTE") {
                tempTableauFilters.historicalTimeFilter = formatDateToString(lastestReportFilters.absoluteTimeFrame.start) + "-" + formatDateToString(lastestReportFilters.absoluteTimeFrame.end);
            }

            if (tempTableauFilters.contextFilter !== undefined) {
                tempTableauFilters.contextFilter["START MONTH ID"] = getStartMonthID(lastestReportFilters.reportType, lastestTableauTimeFilterType, lastestReportFilters.relativeTimeFrame, lastestReportFilters.absoluteTimeFrame);
                tempTableauFilters.contextFilter["END MONTH ID"] = getEndMonthID(lastestReportFilters.reportType, lastestTableauTimeFilterType, lastestReportFilters.relativeTimeFrame, lastestReportFilters.absoluteTimeFrame);
                tempTableauFilters.contextFilter["BENCHMARK START MONTH ID"] = calculateBenchmarkID(lastestReportFilters.reportType, lastestTableauTimeFilterType, lastestReportFilters.relativeTimeFrame, lastestReportFilters.absoluteTimeFrame, "start");
                tempTableauFilters.contextFilter["BENCHMARK END MONTH ID"] = calculateBenchmarkID(lastestReportFilters.reportType, lastestTableauTimeFilterType, lastestReportFilters.relativeTimeFrame, lastestReportFilters.absoluteTimeFrame, "end");
            }
        }

        lastestReportFilters.dataRangeType = currentDataType;
        Object.entries(lastestReportFilters.filters).forEach(prop => {
            if (prop[1].regex !== null && prop[1].regex !== undefined && prop[1].enabled === true) {
                tempTableauFilters.contextFilter[prop[0]] = typeof (prop[1].regex) === 'string' ? prop[1].regex : prop[1].regex.join('|');
            } else if (prop[1].options !== null && prop[1].options !== undefined && prop[1].enabled === true) {
                let tempSelectedOptIDs = prop[1].options.filter(opt => opt.selected === true).map(x => x.value);
                tempTableauFilters.contextFilter[prop[0]] = tempSelectedOptIDs;
            } else if (action === "reset" || (prop[1].options == null && prop[1].enabled === true) || prop[1].enabled === false) {
                tempTableauFilters.contextFilter[prop[0]] = [];
            }
        });

        //language
        tempTableauFilters.contextFilter.LANGUAGE = this.props.i18n.language ? [this.props.i18n.language] : ["en"];

        //preferred parameters
        if (lastestReportFilters.tableauParams && lastestReportFilters.tableauParams !== null && lastestReportFilters.tableauParams.length > 0)
            Object.entries(lastestReportFilters.tableauParams).forEach(param => {
                let paramValue = [];
                paramValue.push(param[1].value);
                tempTableauFilters.contextFilter[param[1].name] = paramValue;
            });

        return tempTableauFilters;
    };

    postLoadDataCheck() {
        //Update Application Filter If Not Regex and outside of User Workflows
        let foundAppFilter = false;
        const _this = this;
        const reportType = _this.props.reportType;
        let projectId = null;
        let ssLastReport = JSON.parse(sessionStorage.getItem('lastReport'));
        if (reportType === 'project') {
            if (ssLastReport && ssLastReport.reportType === 'project') {
                projectId = ssLastReport.projectId;
            } else {
                if (!this.props.location.state) {
                    this.setState({redirect: true});
                    return;
                }
                projectId = _this.props.location.state.id;
            }
        }
        let tempDataRangeType = _this.state.dataRangeType;
        let tempCurrentReport = getSSReportObject(reportType, tempDataRangeType+'MauiFilterTemp', projectId);
        let tempLatestReport = getSSReportObject(reportType, tempDataRangeType+'MauiFilter', projectId);
        if(tempCurrentReport) {
            let tempCurrentReportFilters = tempCurrentReport.filters;
            let tempLatestReportFilters = tempLatestReport.filters;
            let stateWorkbookId = _this.state.workbookId;
            if(stateWorkbookId) {
                for (const filter in tempCurrentReportFilters) {
                    if (tempCurrentReportFilters.hasOwnProperty(filter)) {
                        if (!FILTERS.isUserWorkflowsIgnore(filter,stateWorkbookId)) {
                            if (filter === FILTERS.getUserWorkflowsFilterIgnore()) {
                                let latestFilter = tempCurrentReportFilters[filter];
                                if (!latestFilter.regex) {
                                    if (FILTERS.getApiFilter().hasOwnProperty(filter)) {
                                        let selectedOptionsLength = latestFilter.options.length;
                                        if (FILTERS.getRegexFilters.indexOf(filter) > -1 && selectedOptionsLength > 0) {
                                            let stringAPI;
                                            let word;
                                            if (selectedOptionsLength === 1) {
                                                stringAPI = `/report/regex/convert/${stateWorkbookId}`;
                                                word = latestFilter.options[0].value;
                                            } else {
                                                stringAPI = `/report/regex/convert/list/${stateWorkbookId}`;
                                                latestFilter.options.map(item => {
                                                    if (!word) word = [];
                                                    word.push(item.value)
                                                });
                                            }
                                            foundAppFilter = true;
                                            API.report
                                                .post(stringAPI, {
                                                    word: word
                                                })
                                                .then(resp => {
                                                    const {data: resData} = resp;
                                                    const {data} = resData;

                                                    let regexString = typeof (data.regex) === 'string' ? data.regex : data.regex.join('|');

                                                    latestFilter.regex = [regexString];
                                                    tempLatestReportFilters[filter].regex = [regexString];

                                                    setSSReportObject(reportType, tempDataRangeType+'MauiFilterTemp', tempCurrentReport, projectId);
                                                    setSSReportObject(reportType, tempDataRangeType+'MauiFilter', tempLatestReport, projectId);

                                                    _this.setState({
                                                        loaded: true,
                                                        setMainFocus: true
                                                    });
                                                })
                                                .catch(e => {
                                                    console.error(e);
                                                    if (localStorage.get("accessDenied401") === "true") {
                                                        this.props.handleAuthorization401();
                                                        return;
                                                    }
                                                    let resultCode = -1;
                                                    if (e.data) {
                                                        resultCode = e.data.resultCode;
                                                    }
                                                    if (resultCode > -1)
                                                        this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                                                    else
                                                        this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
                                                });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if(!foundAppFilter) {
            _this.setState({
                loaded: true,
                setMainFocus: true
            });
        }
    }

    loadData() {
        const _this = this;
        const reportType = _this.props.reportType;

        //State
        let tempState = {};
        //SessionStorage
        let report = {};
        let globalOptions = {};

        let getStringAPI = '';
        let firstWorkBookId = -1;
        let projectId = null;
        let projectEditable = null;
        let projectName = null;
        let ssLastReport = JSON.parse(sessionStorage.getItem('lastReport'));
        let kpiCardId = getSSReportObject(reportType, 'kpiCardId', null);

        if (reportType === 'project') {
            if (ssLastReport && ssLastReport.reportType === 'project') {
                projectId = ssLastReport.projectId;
                projectEditable = ssLastReport.editable;
                projectName = ssLastReport.projectName;
            } else {
                if (!this.props.location.state) {
                    this.setState({redirect: true});
                    return;
                }
                projectId = this.props.location.state.id;
                projectEditable = this.props.location.state.editable;
                projectName = this.props.location.state.projectName;
            }
            tempState.projectId = projectId;
            tempState.projectEditable = projectEditable;
            tempState.projectName = projectName;
        }

        if (reportType === 'interactive') {
            const path = this.props.location.pathname.split('/');
            tempState.reportGroup = path[path.length - 2];
            const reportName = path[path.length - 1];
            kpiCardId = (this.props.location.state && this.props.location.state.id && !this.state.reportChanged) ? this.props.location.state.id : kpiCardId ? kpiCardId : -1;
            if (kpiCardId > -1) {
                report.kpiCardId = kpiCardId;
                tempState.kpiCardId = kpiCardId;
            }
            getStringAPI = kpiCardId >= 0 ? `/report/v2/${tempState.reportGroup}/${reportName}/${kpiCardId}` : `/report/v2/${tempState.reportGroup}/${reportName}`;
        } else if (reportType === 'project') {
            if (projectId === null || projectEditable === null || projectName === null) {
                this.setState({redirect: true});
                return;
            }

            if (projectName && this.props.updatePageTitle) {
                this.props.updatePageTitle(projectName);
            }

            getStringAPI = `/project/v2/view/${projectId}`;
        }

        let ssSessionPersistGlobal = getSSGlobalOptionsObject('sessionPersist');
        let ssPreferencePersistGlobal = getSSGlobalOptionsObject('preferencePersist');
        let ssProjectPersistGlobal = getSSGlobalOptionsObject('projectPersist');
        let ssDataRangeType = getSSReportObject(reportType, 'dataRangeType', projectId);
        let ssCurrentMauiFilter = getSSReportObject(reportType, 'currentMauiFilter', projectId);
        let ssCurrentReset = getSSReportObject(reportType, 'currentReset', projectId);
        let ssHistoricalMauiFilter = getSSReportObject(reportType, 'historicalMauiFilter', projectId);
        let ssHistoricalReset = getSSReportObject(reportType, 'historicalReset', projectId);
        let ssWorkbookId = getSSReportObject(reportType, 'workbookId', projectId);
        let ssSessionPersistReport = getSSReportObject(reportType, 'sessionPersist', projectId);
        let ssInstanceWorkbookId = getSSReportObject(reportType, 'instanceWorkbookId', projectId);
        let ssSessionParameters = getSSReportObject(reportType, "sessionParameters", projectId);

        API.report
            .get(getStringAPI)
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;

                if (reportType === 'project') {
                    if (projectEditable) {
                        const navMenuOptions = [
                            {
                                icon: data.menuIcons.edit,
                                id: projectId
                            },
                            {
                                icon: data.menuIcons.shared,
                                id: projectId,
                                onClick: _this.handleShow
                            }];
                        _this.props.updateNavMenuOptions(navMenuOptions);
                    } else {
                        _this.props.updateNavMenuOptions([]);
                    }
                }

                //From API
                tempState.name = data.name;
                tempState.tableau = data.tableau;
                tempState.currentReports = data.currentReports;
                tempState.historicalReports = data.historicalReports;
                tempState.instanceReports = data.instanceReports;
                tempState.sharedProjects = data.sharedProjects;
                tempState.dataReport = {};
                tempState.exportLimit = data.exportLimit;


                const _menuGroupName = data.menuGroupName ? data.menuGroupName : "";
                if (!tempState.currentReports || (tempState.currentReports && tempState.currentReports.length === 1 && tempState.currentReports[0] === null)) {
                    tempState.currentReports = [];
                }
                if (!tempState.historicalReports || (tempState.historicalReports && tempState.historicalReports.length === 1 && tempState.historicalReports[0] === null)) {
                    tempState.historicalReports = [];
                }

                let allInstanceWorkbookIds = [];
                if (!tempState.instanceReports || (tempState.instanceReports && tempState.instanceReports.length === 1 && tempState.instanceReports[0] === null)) {
                    tempState.instanceReports = [];
                } else {
                    report.instanceWorkbookId = ssInstanceWorkbookId ? ssInstanceWorkbookId : tempState.instanceReports[0].id;

                    tempState.instanceReports.map(instance => {
                        allInstanceWorkbookIds.push(instance.id);
                    });
                    report.allInstanceWorkbookIds = allInstanceWorkbookIds;
                }
                report.dataReportId = tempState.dataReport ? tempState.dataReport.id : -1;

                tempState.reportCount = tempState.currentReports.length + tempState.historicalReports.length;
                tempState.workflowReport = reportType === 'interactive' ? data.workflowReport : false;
                report.cardDrillDown = reportType === 'interactive' ? data.cardDrillDown : false;



                if (reportType === 'interactive') {
                    tempState.workbookId = ssWorkbookId;
                    if (!tempState.workbookId) {
                        tempState.workbookId = tempState.currentReports[0].id;
                    }
                    report.workbookId = tempState.workbookId;

                    let tempMoreMenuOptions = [];
                    tempMoreMenuOptions = [
                        {
                            name: _this.props.t('dialog:addToProject'),
                            icon: "pe-7s-light report-add-to-project-icon",
                            href: "",
                            id: "addToProject",
                            onClick: _this.handleShow
                        },
                        {
                            name: _this.props.t('common:newProject'),
                            icon: "pe-7s-light  report-new-project-icon",
                            href: "/admin/newProject",
                            id: tempState.workbookId,
                            newProject: true,
                            preSelectReportID: tempState.workbookId
                        },
                        {
                          divider: true
                        },
                        {
                            name: _this.props.t('dialog:exportSourceData'),
                            icon: "pe-7s-light report-data-export-icon",
                            href: "",
                            id: "exportData",
                            onClick: _this.prepareExportDialog
                        },
                        {
                            name: _this.props.t('dialog:exportReportData'),
                            icon: "pe-7s-light report-data-export-icon",
                            href: "",
                            id: "exportReportData",
                            onClick: _this.callExportReportData
                        },
                        {
                            name: _this.props.t('dialog:exportToPdf'),
                            icon: "pe-7s-light report-data-export-icon",
                            href: "",
                            id: "exportToPdf",
                            onClick: _this.callExportToPdf
                        }
                    ];

                    //projects and options for state
                    tempState.moreMenuOptions = tempMoreMenuOptions;

                    if (tempState.currentReports.length > 0
                        && tempState.currentReports[0] !== null) {
                        const currentWorkbook = tempState.currentReports.filter(report => report.id === tempState.workbookId)[0];
                        if(currentWorkbook && currentWorkbook.dataReport) {
                            tempState.dataReport = currentWorkbook.dataReport;
                            if(!report.allInstanceWorkbookIds)
                                report.allInstanceWorkbookIds =[];
                            report.allInstanceWorkbookIds.push(currentWorkbook.dataReport.id)
                        }
                    }
                    if (tempState.historicalReports.length > 0
                        && tempState.historicalReports[0] !== null) {
                        const currentWorkbook = tempState.historicalReports.filter(report => report.id === tempState.workbookId)[0];
                        if(currentWorkbook && currentWorkbook.dataReport) {
                            tempState.dataReport = currentWorkbook.dataReport;
                            if(!report.allInstanceWorkbookIds)
                                report.allInstanceWorkbookIds =[];
                            report.allInstanceWorkbookIds.push(currentWorkbook.dataReport.id)
                        }

                    }

                } else if (reportType === 'project') {

                    //active workbook after sorting by name
                    if (tempState.currentReports.length > 0 || tempState.historicalReports.length > 0) {
                        firstWorkBookId = _this.sortWorkbooks(tempState.currentReports.concat(tempState.historicalReports))[0].id;
                        tempState.workbookId = (ssWorkbookId !== null && ssWorkbookId !== undefined) ? ssWorkbookId : firstWorkBookId;
                        report.workbookId = tempState.workbookId;
                    }

                    //dataRangeType
                    let tempCurrentReportIds = tempState.currentReports.length > 0 ? tempState.currentReports.map(x => x.id) : [];
                    if (tempCurrentReportIds.includes(tempState.workbookId)) {
                        tempState.dataRangeType = 'current';
                    } else {
                        tempState.dataRangeType = 'historical';
                    }

                    if (tempState.dataRangeType === 'current' && tempState.currentReports.length > 0 && tempState.currentReports[0] !== null) {
                        const currentWorkbook = tempState.currentReports.filter(report => report.id === tempState.workbookId)[0];

                        //instance report
                        const _instanceReport = currentWorkbook.instanceReport;
                        if (_instanceReport) {
                            tempState.instanceReports = _instanceReport;
                            report.instanceWorkbookId = ssInstanceWorkbookId ? ssInstanceWorkbookId : tempState.instanceReports[0].id;

                            tempState.instanceReports.map(instance => {
                                allInstanceWorkbookIds.push(instance.id);
                            });
                            report.allInstanceWorkbookIds = allInstanceWorkbookIds;
                        }

                        //workflowReport
                        tempState.workflowReport = currentWorkbook.workflowReport;
                        tempState.dataReport = currentWorkbook.dataReport;
                        if(!report.allInstanceWorkbookIds)
                            report.allInstanceWorkbookIds =[];
                        if(currentWorkbook.dataReport)
                            report.allInstanceWorkbookIds.push(currentWorkbook.dataReport.id)

                    }
                    if (tempState.dataRangeType === 'historical' && tempState.historicalReports.length > 0
                        && tempState.historicalReports[0] !== null) {
                        const currentWorkbook = tempState.historicalReports.filter(report => report.id === tempState.workbookId)[0];
                        tempState.dataReport = currentWorkbook.dataReport;
                        if(!report.allInstanceWorkbookIds)
                            report.allInstanceWorkbookIds =[];
                        report.allInstanceWorkbookIds.push(currentWorkbook.dataReport.id)

                    }

                    let currentUser = window.sessionStorage.getItem("currentUser");
                    let projectOwner = tempState.sharedProjects ? tempState.sharedProjects.projectOwner : "";

                    // API.project
                    //     .get("/project/list")
                    //     .then(resp => {
                    //         const {data: resData} = resp;
                    //         const {data} = resData;
                    //         ownedProject= data.userProjects;
                    //     })
                    //     .catch(e => {
                    //         console.error(e);
                    //     });
                    // console.log("projects: "+ ownedProject.length);
                    // ownedProject.forEach(p=>{console.log("individual project: "+ p.id)});
                    // for project more option
                    let tempMoreMenuOptions = [];
                    if (currentUser) {
                        if (projectOwner.toLowerCase() === currentUser.toLowerCase()) {
                            tempMoreMenuOptions = [
                                {
                                    name: _this.props.t('common:removeReport'),
                                    icon: "pe-7s-back-2",
                                    href: "",
                                    id: tempState.workbookId,
                                    onClick: _this.removeReportFromProject
                                },
                                {
                                    divider: true
                                },
                                {
                                    name: _this.props.t('dialog:exportSourceData'),
                                    icon: "pe-7s-light report-data-export-icon",
                                    href: "",
                                    id: "exportData",
                                    onClick: _this.prepareExportDialog
                                },
                                {
                                    name: _this.props.t('dialog:exportReportData'),
                                    icon: "pe-7s-light report-data-export-icon",
                                    href: "",
                                    id: "exportReportData",
                                    onClick: _this.callExportReportData
                                },
                                {
                                    name: _this.props.t('dialog:exportToPdf'),
                                    icon: "pe-7s-light report-data-export-icon",
                                    href: "",
                                    id: "exportToPdf",
                                    onClick: _this.callExportToPdf
                                }
                            ];
                        } else {
                            tempMoreMenuOptions = [
                                {
                                    name: _this.props.t('dialog:exportSourceData'),
                                    icon: "pe-7s-light report-data-export-icon",
                                    href: "",
                                    id: "exportData",
                                    onClick: _this.prepareExportDialog
                                },
                                {
                                    name: _this.props.t('dialog:exportReportData'),
                                    icon: "pe-7s-light report-data-export-icon",
                                    href: "",
                                    id: "exportSourceData",
                                    onClick: _this.callExportReportData
                                },
                                {
                                    name: _this.props.t('dialog:exportToPdf'),
                                    icon: "pe-7s-light report-data-export-icon",
                                    href: "",
                                    id: "exportToPdf",
                                    onClick: _this.callExportToPdf
                                }
                            ];
                        }
                    }
                    tempState.moreMenuOptions = tempMoreMenuOptions;

                }




                //Project - end early if project has no reports
                if (reportType === 'project' && !tempState.workbookId) {
                    //session storage
                    const ssKey = 'reportProject' + projectId;
                    //remove existing report from session storage (if exists)
                    window.sessionStorage.removeItem(ssKey);
                    //save report in session storage
                    window.sessionStorage.setItem(ssKey, JSON.stringify(report));

                    //Save to state
                    _this.setState({
                        dataRangeType: tempState.dataRangeType,
                        tableau: tempState.tableau,
                        currentReports: tempState.currentReports,
                        historicalReports: tempState.historicalReports,
                        instanceReports: tempState.instanceReports,
                        workflowReport: tempState.workflowReport,
                        reportCount: tempState.reportCount,
                        workbookId: tempState.workbookId,
                        sessionPersist: tempState.sessionPersist,
                        projectPersist: reportType === 'project' ? tempState.projectPersist : false,
                        preferencePersist: reportType === 'interactive' ? tempState.preferencePersist : false,
                        projectId: reportType === 'project' ? tempState.projectId : -1,
                        projectEditable: reportType === 'project' ? tempState.projectEditable : false,
                        projectName: reportType === 'project' ? tempState.projectName : '',
                        groupName: reportType === 'interactive' ? tempState.reportGroup : '',
                        allPath: reportType === 'interactive' ? "#/admin/" + _menuGroupName + "/All" + _menuGroupName : '',
                        kpiCardId: reportType === 'interactive' ? tempState.kpiCardId : -1,
                        isDualPeriod: false,
                        moreMenuOptions: null,
                        _notificationSystem: _this.refs.notificationSystem,
                        loaded: true,
                        setMainFocus: true
                    });
                } else if (tempState.workbookId >= 0) {
                    kpiCardId = (this.props.location.state && this.props.location.state.id && !this.state.reportChanged)
                        ? this.props.location.state.id : kpiCardId ? kpiCardId : -1;

                    let getStringAPI = reportType === 'interactive'
                        ? kpiCardId >= 0 ? `/report/filter/${tempState.workbookId}/${kpiCardId}` : `/report/filter/${tempState.workbookId}`
                        : `/project/filter/${projectId}/${tempState.workbookId}`;
                    API.report.get(getStringAPI)
                        .then(resp => {
                            const {data: resData} = resp;
                            const {data} = resData;

                            let currentData = data;

                            //add label to filter options and remove the unselected options for user attributes only in session storage
                            Object.entries(currentData.filters).forEach(filter => {
                                if (filter[1].options && filter[1].options.length > 0) {
                                    if(filter[1].originalName && filter[1].originalName.startsWith("STRING_ATTR")){
                                        let selectedOptions = filter[1].options.filter(opt => opt.selected === true);
                                        filter[1].options  = selectedOptions;
                                    }
                                    filter[1].options.map(option => {
                                        option.label = option.value;
                                    });
                                }
                            });

                            tempState.dataRangeType = (ssDataRangeType !== null && ssDataRangeType !== undefined) ? ssDataRangeType : currentData.reportType;
                            report.dataRangeType = tempState.dataRangeType;

                            //set absoluteTimeFrame if null or unselected
                            if (currentData.relativeTimeFrame !== null && (currentData.absoluteTimeFrame === null || (!currentData.absoluteTimeFrame.selected && currentData.relativeTimeFrame.selected))) {
                                let tempAbsolute = {};
                                tempAbsolute.selected = false;
                                tempAbsolute.type = currentData.relativeTimeFrame.type;
                                tempAbsolute.start = calculateStartDate(currentData.relativeTimeFrame.type.toUpperCase(), currentData.relativeTimeFrame.value, null);
                                tempAbsolute.end = getServerTimezoneDate();
                                currentData.absoluteTimeFrame = tempAbsolute;
                            }

                            if (currentData.reportType === "current") {
                                currentData.currentAbsoluteCalendarStart = calculateStartDate("CURRENT", currentData.relativeTimeFrame.limit, null);
                            } else {
                                currentData.historicalAbsoluteCalendarStart = calculateStartDate("HISTORICAL", currentData.relativeTimeFrame.limit, null);
                            }

                            //state
                            tempState.sessionPersist = (ssSessionPersistGlobal !== null && ssSessionPersistGlobal !== undefined) ? ssSessionPersistGlobal : currentData.sessionPersist;
                            tempState.preferencePersist = (ssPreferencePersistGlobal !== null && ssPreferencePersistGlobal !== undefined) ? ssPreferencePersistGlobal : currentData.preferencePersist;
                            tempState.projectPersist = (ssProjectPersistGlobal !== null && ssProjectPersistGlobal !== undefined) ? ssProjectPersistGlobal : currentData.projectPersist;

                            //session storage
                            globalOptions.sessionPersist = tempState.sessionPersist;
                            globalOptions.preferencePersist = tempState.preferencePersist;
                            globalOptions.projectPersist = tempState.projectPersist;
                            report.sessionPersist = (ssSessionPersistReport !== null && ssSessionPersistReport !== undefined) ? ssSessionPersistReport : false;

                            //Ensure certain filters are reset if drilling in from the Home page's KPI cards (interactive reports)
                            if (reportType === 'interactive' && report.cardDrillDown && report.cardDrillDown === true) {
                                Object.entries(currentData.filters).forEach(prop => {
                                    // if (prop[0].startsWith(FILTERS.getDrillDownHomeDisabledSelectons("errorType")) || prop[0].startsWith(FILTERS.getDrillDownHomeDisabledSelectons("appNameVersion")) || prop[0].startsWith(FILTERS.getDrillDownHomeDisabledSelectons("browserVersion")) || prop[0].startsWith(FILTERS.getDrillDownHomeDisabledSelectons("osVersion"))) {
                                    //     if (currentData.filters[prop[0]].options !== null && currentData.filters[prop[0]].options.length !== 0) {
                                    //         currentData.filters[prop[0]].options.forEach(opt => {
                                    //             opt.selected = false;
                                    //         });
                                    //     }
                                    // } else
                                    if (prop[0].startsWith(FILTERS.getMessageTime("contextName")) || prop[0].startsWith(FILTERS.getScreenName("contextName")) || prop[0].startsWith(FILTERS.getFilterEndUserName("default"))) {
                                        currentData.filters[prop[0]].options = [];
                                    }
                                });

                                let currentMauiFilterDrill = currentData;
                                //temporary for session storage
                                Object.entries(currentData.filters).forEach(prop => {
                                    if (prop[1].enabled === false) {
                                        delete currentMauiFilterDrill.filters[prop[0]];
                                    }
                                });

                                report.currentMauiFilterDrill = currentData;
                                report.currentMauiFilterDrill.filters = currentMauiFilterDrill.filters;
                                report.currentMauiFilterTempDrill = report.currentMauiFilterDrill;
                            }

                            let currentDataCopyOne = JSON.parse(JSON.stringify(currentData));
                            if (ssSessionPersistReport === false && ssHistoricalMauiFilter === undefined && currentData.reportType === 'historical') ssHistoricalMauiFilter = currentDataCopyOne;
                            let thisTypeMauiFilters = currentData.reportType === 'historical' ? ssHistoricalMauiFilter : ssCurrentMauiFilter;
                            let otherTypeMauiFilters = currentData.reportType === 'historical' ? ssCurrentMauiFilter : ssHistoricalMauiFilter;
                            let latestMauiFilters = thisTypeMauiFilters ? thisTypeMauiFilters : otherTypeMauiFilters ? otherTypeMauiFilters : currentData;

                            //latestMauiFilters.tableauParams=currentData.tableauParams;
                            let originalCurrentTableauParams = [];
                            currentData.tableauParams.forEach(p => {
                                originalCurrentTableauParams.push(Object.assign({}, p))
                            });

                            let currentDataCopy = JSON.parse(JSON.stringify(currentData));


                            let sessionStoredTableauParams = [];
                            let updatedTableauTotalParams = [];
                            //let totalNewSessionParameters =[];
                            if (report.sessionPersist === false) {
                                updatedTableauTotalParams = currentData.tableauParams;
                            } else {
                                sessionStoredTableauParams = (ssSessionParameters === undefined || ssSessionParameters === null) ? [] : ssSessionParameters;
                                currentData.tableauParams.forEach(p => {
                                    let tempCommonParam = sessionStoredTableauParams.filter(x => (x.name === p.name));
                                    if (tempCommonParam.length > 0) {
                                        p.value = tempCommonParam[0].value;
                                    }
                                    updatedTableauTotalParams.push(p);
                                });
                            }

                            // let extraSessionParameters = [];
                            // sessionStoredTableauParams.forEach(sp =>{
                            //     let commonSPs  = updatedTableauTotalParams.filter(up => up.name===sp.name);
                            //     if(commonSPs.length===0) extraSessionParameters.push(sp);
                            // });
                            //
                            // totalNewSessionParameters = updatedTableauTotalParams.concat(extraSessionParameters);


                            latestMauiFilters.tableauParams = updatedTableauTotalParams;
                            currentData.tableauParams = originalCurrentTableauParams;
                            report.sessionParameters = sessionStoredTableauParams;

                            Object.entries(currentData.filters).forEach(prop => {
                                if (prop[1].enabled === true) {
                                    if (latestMauiFilters.filters[prop[0]] && latestMauiFilters.filters[prop[0]].enabled === false) {
                                        if (report.sessionPersist === false) {
                                            latestMauiFilters.filters[prop[0]] = currentData.filters[prop[0]];
                                        } else {
                                            latestMauiFilters.filters[prop[0]].enabled = true;
                                        }
                                    } else if (!latestMauiFilters.filters[prop[0]]) {
                                        latestMauiFilters.filters[prop[0]] = currentData.filters[prop[0]];
                                    }
                                } else if (latestMauiFilters.filters[prop[0]]) {
                                    if (report.sessionPersist === true) {
                                        latestMauiFilters.filters[prop[0]].enabled = false;
                                    } else {
                                        delete latestMauiFilters.filters[prop[0]];
                                    }
                                }
                            });

                            //attribute mapping
                            let attMap = {};
                            Object.entries(currentData.filters).forEach(prop => {
                                if (prop[1].name && prop[1].name.toUpperCase() === FILTERS.getCountry()) {
                                    attMap[prop[0]] = prop[1].name;
                                }
                            });
                            tempState.attributeMap = attMap;

                            //maui filter for SessionStorage
                            if (currentData.reportType === 'historical') {
                                //historical
                                report.historicalReset = currentDataCopy;
                                report.historicalMauiFilter = (ssHistoricalMauiFilter !== null && ssHistoricalMauiFilter !== undefined) ? ssHistoricalMauiFilter : currentData;
                                report.historicalMauiFilter.filters = latestMauiFilters.filters;
                                report.historicalMauiFilter.tableauParams = latestMauiFilters.tableauParams;

                                //Update dualPeriod
                                report.historicalReset.dualPeriod = currentData.dualPeriod;
                                report.historicalMauiFilter.dualPeriod = currentData.dualPeriod;
                                report.historicalMauiFilterTemp = report.historicalMauiFilter;

                                if (ssCurrentMauiFilter !== null && ssCurrentMauiFilter !== undefined) {
                                    //current (if exists)
                                    ssCurrentReset.tableauParams = currentData.tableauParams;
                                    report.currentReset = ssCurrentReset;
                                    report.currentMauiFilter = ssCurrentMauiFilter;
                                    report.currentMauiFilter.filters = latestMauiFilters.filters;
                                    report.currentMauiFilterTemp = report.currentMauiFilter;
                                }
                                // report.historicalReset.tableauParams = originalCurrentTableauParams;
                            } else {
                                //current
                                report.currentReset = currentData;
                                report.currentMauiFilter = (ssCurrentMauiFilter !== null && ssCurrentMauiFilter !== undefined) ? ssCurrentMauiFilter : currentData;
                                report.currentMauiFilter.filters = latestMauiFilters.filters;
                                report.currentMauiFilter.tableauParams = latestMauiFilters.tableauParams;

                                //Update dualPeriod
                                report.currentReset.dualPeriod = currentData.dualPeriod;
                                report.currentMauiFilter.dualPeriod = currentData.dualPeriod;
                                report.currentMauiFilterTemp = report.currentMauiFilter;

                                if (ssHistoricalMauiFilter !== null && ssHistoricalMauiFilter !== undefined) {
                                    //current (if exists)
                                    ssHistoricalReset.tableauParams = currentData.tableauParams;
                                    report.historicalReset = ssHistoricalReset;
                                    report.historicalMauiFilter = ssHistoricalMauiFilter;
                                    report.historicalMauiFilter.filters = latestMauiFilters.filters;
                                    report.historicalMauiFilterTemp = report.historicalMauiFilter;
                                }
                            }
                            //report.tableauParams= latestMauiFilters.tableauParams;

                            //session storage
                            const ssKey = reportType === 'project' ? 'reportProject' + projectId : 'reportInteractive';
                            //remove existing report from session storage (if exists)
                            window.sessionStorage.removeItem(ssKey);
                            //save report in session storage
                            // const byteSize = str => new Blob([str]).size;
                            // if (reportType === 'interactive' && report.cardDrillDown && report.cardDrillDown === true) {
                            //     console.log(ssKey+" object(from KPI card link) string size in bytes: "+  byteSize(JSON.stringify(report)));
                            //     //console.log(ssKey+" object(from KPI card link) string size in length: "+  JSON.stringify(report).length);
                            // } else {
                            //     console.log(ssKey+" object(from left menu) string size in bytes: "+  byteSize(JSON.stringify(report)));
                            //    // console.log(ssKey+" object(from left menu) string size in length: "+  JSON.stringify(report).length);
                            // }
                            window.sessionStorage.setItem(ssKey, JSON.stringify(report));
                            //remove existing global options from session storage (if exists)
                            window.sessionStorage.removeItem('globalOptions');
                            //save global options in session storage
                            window.sessionStorage.setItem('globalOptions', JSON.stringify(globalOptions));

                            //Save to state
                            _this.setState({
                                dataRangeType: tempState.dataRangeType,
                                tableau: tempState.tableau,
                                currentReports: tempState.currentReports,
                                //tabReportID: tempState.currentReports[0].id,
                                historicalReports: tempState.historicalReports,
                                instanceReports: tempState.instanceReports,
                                workflowReport: tempState.workflowReport,
                                reportCount: tempState.reportCount,
                                workbookId: tempState.workbookId,
                                sessionPersist: tempState.sessionPersist,
                                projectPersist: reportType === 'project' ? tempState.projectPersist : false,
                                preferencePersist: reportType === 'interactive' ? tempState.preferencePersist : false,
                                projectId: reportType === 'project' ? tempState.projectId : -1,
                                projectEditable: reportType === 'project' ? tempState.projectEditable : false,
                                projectName: reportType === 'project' ? tempState.projectName : '',
                                groupName: reportType === 'interactive' ? tempState.reportGroup : '',
                                allPath: reportType === 'interactive' ? "#/admin/" + _menuGroupName + "/All" + _menuGroupName : '',
                                kpiCardId: reportType === 'interactive' ? tempState.kpiCardId : -1,
                                moreMenuOptions: tempState.moreMenuOptions,
                                attributeMap: tempState.attributeMap,
                                dataReport: tempState.dataReport,
                                isDualPeriod: currentData.dualPeriod,
                                _notificationSystem: _this.refs.notificationSystem,
                                exportLimit: tempState.exportLimit
                                //loaded: true
                            })

                            _this.postLoadDataCheck();
                        }).catch(e => {
                        console.error(e);
                        if (localStorage.get("accessDenied401") === "true") {
                            this.setState({showLoggedoutModal: true});
                            return;
                        }
                        let resultCode = -1;
                        if (e.data) {
                            resultCode = e.data.resultCode;
                        }
                        if (resultCode > -1)
                            this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                        else
                            this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
                    })
                }
            }).catch(e => {
            console.error(e);
            if (localStorage.get("accessDenied401") === "true") {
                this.setState({showLoggedoutModal: true});
                return;
            }
            if (e.data && e.data.result && (e.data.result === 'PROJECT_DOES_NOT_EXIST')) {
                this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t, 'generic',  "The project does not exist!",null));
            } else {
                let resultCode = -1;
                if (e.data) {
                    resultCode = e.data.resultCode;
                }
                if (resultCode > -1)
                this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
            else
                this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            }
            this.setState({redirect: true});
        });
    }

    componentDidMount() {
        this.loadData();
        window.sessionStorage.removeItem('lastChangedFilter');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.workbookId !== prevState.workbookId && prevState.workbookId !== -1 && !this.state.loaded) {
            this.loadData();
        }
        if(this.state.setMainFocus) {
            if (this.mainContent && this.mainContent.current) {
                this.mainContent.current.focus();
                this.setState({setMainFocus: false})
            }
        }
    }

    componentWillUnmount() {
        if(abortController) {
            abortController.abort();
            this.props.handleAlertClick("warning", "tc", getWarningMessages(this.props.t,'exportCancelled',  null, true));

        }
        if (this.state.loaded) {
            if (this.props.history.location.pathname.toLowerCase() !== this.props.location.pathname.toLowerCase()) {
                if (!this.state.sessionPersist) {
                    //Remove interactive report from session storage
                    window.sessionStorage.removeItem('reportInteractive');

                    //Remove all project report from session storage
                    Object.keys(sessionStorage).forEach(key => {
                        if (key.startsWith("reportProject")) {
                            window.sessionStorage.removeItem(key);
                        }
                    })
                } else {
                    const ssSessionPersist = getSSReportObject(this.state.reportType, 'sessionPersist', this.state.projectId);
                    if (ssSessionPersist !== undefined && ssSessionPersist === false) {
                        const key = this.state.reportType === 'interactive' ? 'reportInteractive' : 'reportProject' + this.state.projectId;
                        window.sessionStorage.removeItem(key);
                    }
                }

                removeSSReportObject(this.state.reportType, 'dataRangeType', this.state.projectId);
                removeSSReportObject(this.state.reportType, 'workbookId', this.state.projectId);

                if (this.state.kpiCardId > -1) {
                    //Reset all home page drill into interactive report related information
                    removeSSReportObject('interactive', 'kpiCardId', null);
                    removeSSReportObject('interactive', 'currentMauiFilterDrill', null);
                    removeSSReportObject('interactive', 'currentMauiFilterTempDrill', null);
                    // removeSSReportObject('interactive', 'sessionParameters', null);
                }

                window.sessionStorage.removeItem('lastReport');
                window.sessionStorage.removeItem('lastInstanceReport');
                window.sessionStorage.removeItem('workflowReport');
                window.sessionStorage.removeItem('latestReportDataType');
                //double rendering issue for interactive report
                if (this.state.reportType === 'project') {
                    this.props.updatePageTitle("");
                    this.props.updateNavMenuOptions([]);
                }
            }
        } else if (this.state.redirect) {
            if (this.state.reportType === 'project') {
                this.props.updatePageTitle("");
                this.props.updateNavMenuOptions([]);
            }
        } else if (this.props.history.location.pathname.toLowerCase() !== this.props.location.pathname.toLowerCase()) {
            //cleanup in case report did not load and the user navigates to another report
            window.sessionStorage.removeItem('reportInteractive');
        }
        window.sessionStorage.removeItem('lastChangedFilter');
    }

    render() {
        try {
            if (this.state.sessionTerminated)
                return <Redirect to={{pathname: '/auth/logout'}}/>;

            let showLoggedOutWindow = this.state.showLoggedoutModal;

            const {redirect, loaded} = this.state;

            if (redirect) {
                return <Redirect to='/admin/projects'/>;
            }

            if (!loaded && !showLoggedOutWindow) {
                return null;
            }

            let routes = mauiRoutes.getRoutes();
            const {t, navVarBrand, getActiveRoute} = this.props;
            const {
                dataRangeType, dataRangeTypePrev, reportType, currentReports, historicalReports, instanceReports, allPath,
                groupName, workflowReport, listOfProjects, showModal, formErrors, reportCount, tableau,
                sessionPersist, preferencePersist, projectPersist, moreMenuOptions, users, selectedUsers,
                projectName, projectEditable, projectId, workbookId, kpiCardId, attributeMap, exportLimit,
                showExportModal, dataReport, isDualPeriod
            } = this.state;
            let dataRangeTypeForLookup = '';
            let latestMauiFilters = {};
            let latestMauiFiltersTemp = {};
            let latestTableauFilters = {};
            let projects = [];
            let combinedWorkbooks = [];
            let currentWorkbook = [];
            let currentInstanceWorkbook = [];
            let instanceEnabled = false;
            let _lastReportTab = "";
            let lastReport = {};
            let lastInstanceReport = {};
            let isWorkflowReport = workflowReport;
            let instanceWorkbookId = getSSReportObject(reportType, 'instanceWorkbookId', projectId);
            let allInstanceWorkbookIds = getSSReportObject(reportType, 'allInstanceWorkbookIds', projectId);
            let drillOptions = [];
            let currentDataID = -1;
            let historicalDataID = -1;
            let activeKeyID = -1;
            let isDataReport = false;

            if (reportCount > 0) {
                dataRangeTypeForLookup = (dataRangeType === 'instance' && dataRangeTypePrev !== '') ? dataRangeTypePrev : (dataRangeType === 'instance' || dataRangeType === '') ? 'current' : dataRangeType;

                if (reportType === 'interactive' && dataRangeTypeForLookup === 'current' && kpiCardId > -1) {
                    latestMauiFilters = getSSReportObject('interactive', 'currentMauiFilterDrill', null);
                    latestMauiFiltersTemp = getSSReportObject('interactive', 'currentMauiFilterTempDrill', null);
                } else {
                    latestMauiFilters = getSSReportObject(reportType, dataRangeTypeForLookup + 'MauiFilter', projectId);
                    latestMauiFiltersTemp = getSSReportObject(reportType, dataRangeTypeForLookup + 'MauiFilterTemp', projectId);
                }

                //interactive report list
                if (reportType !== 'project' && reportCount !== 1) {
                    if (currentReports && currentReports.length > 0) currentDataID = currentReports[0].id;
                    if (historicalReports && historicalReports.length > 0) historicalDataID = historicalReports[0].id;
                    activeKeyID = dataRangeTypeForLookup === 'historical' ? historicalDataID : currentDataID;
                }

                //Filters for Tableau
                latestTableauFilters = this.getTableauFilterFromPanelFilter(latestMauiFilters, 'update');

                let clickFromInstance = window.sessionStorage.getItem("clickFromInstance");
                let latestReportType = window.sessionStorage.getItem("latestReportDataType"); //currentuser workflow
                let clickFromBreadcrumb = window.sessionStorage.getItem("clickFromBreadcrumb");

                projects = listOfProjects;
                combinedWorkbooks = currentReports.concat(historicalReports);
                //sort wookrbooks by name
                combinedWorkbooks = this.sortWorkbooks(combinedWorkbooks, "workbookName");
                currentInstanceWorkbook = instanceReports.filter(report => report.id === instanceWorkbookId)[0];
                if (dataRangeType === 'instance' && !currentInstanceWorkbook) {
                    let _currentReport = currentReports.filter(report => report.id === workbookId)[0];
                    if (_currentReport && _currentReport.dataReport) {
                        currentInstanceWorkbook = currentReports.filter(report => report.id === workbookId)[0].dataReport;
                        activeKeyID = currentDataID;
                    }
                    if (!currentInstanceWorkbook) {
                        _currentReport = historicalReports.filter(report => report.id === workbookId)[0];
                        if (_currentReport && _currentReport.dataReport) {
                            currentInstanceWorkbook = historicalReports.filter(report => report.id === workbookId)[0].dataReport;
                            activeKeyID = historicalDataID;
                        }
                    }
                    if (currentInstanceWorkbook) {
                        instanceEnabled = false;
                        isDataReport = true;

                    }
                }
                currentWorkbook = dataRangeType === 'instance'
                    ? currentInstanceWorkbook
                    : dataRangeType === 'historical'
                        ? historicalReports.filter(report => report.id === workbookId)[0]
                        : ((clickFromInstance === "true"
                            || (latestReportType === "currentuser workflow" && clickFromBreadcrumb !== "true"))
                        && currentInstanceWorkbook
                        && currentInstanceWorkbook['workflowReport'] !== null ? currentInstanceWorkbook['workflowReport'] : currentReports.filter(report => report.id === workbookId)[0]);

                if ((clickFromInstance === "true" || latestReportType === "currentuser workflow") && dataRangeType === "current") {
                    isWorkflowReport = true;
                }

                if (dataReport && !isWorkflowReport) {
                    instanceEnabled = true;
                    let option = {};
                    option.onClick = () => this.drillClick(currentWorkbook, dataReport.id);
                    option.instanceReportName = t('common:aggregateData')
                    option.id = 'btnDrill' + dataReport.id;
                    option.disabled = true;
                    drillOptions.push(option);
                }

                if ((instanceReports && instanceReports.length > 0 && currentInstanceWorkbook !== null)
                    && (dataRangeType === "current" || dataRangeType === "" || dataRangeType === "instance")) {
                    instanceEnabled = true;

                    //generate drill options for instance reports
                    instanceReports.map(instance => {
                        let option = {};
                        option.onClick = () => this.drillClick(currentWorkbook, instance.id);
                        option.instanceReportName = instance.workbookName;
                        option.id = 'btnDrill' + instance.id;
                        option.disabled = true;

                        if (!isWorkflowReport) {
                            option.workflowReportName = instance.workflowReport.workbookName;
                        }

                        drillOptions.push(option);
                    });
                }

                let ssLastReport = JSON.parse(window.sessionStorage.getItem('lastReport'));
                let ssLastInstanceReport = JSON.parse(window.sessionStorage.getItem('lastInstanceReport'));
                let exportInfo = {}

                exportInfo.workbook = currentWorkbook.workbookName;
                exportInfo.id = currentWorkbook.id;
                window.sessionStorage.setItem('exportInfo', JSON.stringify(exportInfo));
                if (dataRangeType === "historical" || dataRangeType === "current" || dataRangeType === "") {
                    lastReport.name = currentWorkbook.workbookName;
                    lastReport.dataRangeType = dataRangeType !== "" ? dataRangeType : "current";
                    lastReport.reportType = reportType;
                    lastReport.dualPeriod = isDualPeriod;
                    if (reportType === 'project') {
                        lastReport.projectId = projectId;
                        lastReport.editable = projectEditable;
                        lastReport.projectName = projectName;
                    }

                    if (ssLastReport && ssLastReport.name === lastReport.name) {
                        let _tabs = ssLastReport.tabs;
                        if (_tabs) {
                            for (let i = 0; i < _tabs.length; i++) {
                                if (_tabs[i].lastUsed === true) {
                                    _lastReportTab = _tabs[i].name;
                                }
                            }
                        }
                    } else {
                        if (currentWorkbook.workbookName !== "user workflow")
                            window.sessionStorage.setItem('lastReport', JSON.stringify(lastReport));
                        else
                            window.sessionStorage.setItem('workflowReport', JSON.stringify(lastReport));
                    }
                } else if (dataRangeType === "instance") {
                    lastInstanceReport.name = currentWorkbook.workbookName;
                    lastInstanceReport.dataRangeType = "instance";
                    lastInstanceReport.reportType = reportType;
                    if (reportType === 'project') {
                        lastInstanceReport.projectId = projectId;
                        lastInstanceReport.editable = projectEditable;
                        lastInstanceReport.projectName = projectName;
                    }

                    if (ssLastInstanceReport && ssLastInstanceReport.name === lastInstanceReport.name) {
                        let _tabs = ssLastInstanceReport.tabs;
                        if (_tabs) {
                            for (let i = 0; i < _tabs.length; i++) {
                                if (_tabs[i].lastUsed === true) {
                                    _lastReportTab = _tabs[i].name;
                                }
                            }
                        }
                    } else {
                        window.sessionStorage.setItem('lastInstanceReport', JSON.stringify(lastInstanceReport));
                    }
                }
            } else {
                lastReport.reportType = reportType;
                lastReport.projectId = projectId;
                lastReport.editable = projectEditable;
                lastReport.projectName = projectName;
                window.sessionStorage.setItem('lastReport', JSON.stringify(lastReport));
            }

            return (
                <div className="main-content" ref={this.mainContent} tabIndex={"-1"}>
                    <Helmet>
                        <title>Knoa Analytics - {(navVarBrand) ? capitalizeWords(navVarBrand) : capitalizeWords(getActiveRoute(routes, t))}</title>
                    </Helmet>
                    <ModalDialog
                        showModal={showLoggedOutWindow}
                        title={"Log Out"}
                        contentBody={
                            <span>{getInfoMessages(this.props.t, 'logoutfor401', null, null)}</span>
                        }
                        bodyPaddingBottom='15px'
                        footerBtnBsStyle="success"
                        footerBtnClass="KnoaSuccessButton"
                        footerBtnOnClick={this.handleLogoutConfirm}
                        footerBtnText={this.props.t('common:ok')}
                    />
                    {dataRangeType === 'instance' ? (
                        <KnoaBreadcrumb
                            href={reportType === 'interactive' ? allPath : '#/admin/projects'}
                            firstLevelText={reportType === 'interactive' ? t('route.name:' + groupName.toLowerCase()) : t('route.name:Projects')}
                            secondLevelText={reportType === 'interactive' ? (navVarBrand) ? navVarBrand : getActiveRoute(routes, t) : t('common:viewProject')}
                            href2={'#' + this.props.location.pathname}
                            thirdLevelText={isWorkflowReport ? t('report.name:User Workflow') : t('instanceReport.name:' + currentInstanceWorkbook.workbookName)}
                            onClick2={() => this.backClick(currentWorkbook)}
                        />
                    ) : (
                        dataRangeType === 'current' && currentWorkbook['workbookName'] === "user workflow" ? (
                            <KnoaBreadcrumb
                                href={reportType === 'interactive' ? allPath : '#/admin/projects'}
                                firstLevelText={reportType === 'interactive' ? t('route.name:' + groupName.toLowerCase()) : t('route.name:Projects')}
                                secondLevelText={reportType === 'interactive' ? (navVarBrand) ? navVarBrand : getActiveRoute(routes, t) : t('common:viewProject')}
                                href2={'#' + this.props.location.pathname}
                                thirdLevelText={t('instanceReport.name:' + currentInstanceWorkbook.workbookName)}
                                onClick2={() => this.backClickFromLink(currentWorkbook, "current")}
                                href3={'#' + this.props.location.pathname}
                                forthLevelText={t('report.name:User Workflow')}
                                onClick3={() => this.backClickFromLink(currentWorkbook, "instance")}

                            />
                        ) : (<KnoaBreadcrumb
                            href={reportType === 'interactive' ? allPath : '#/admin/projects'}
                            firstLevelText={reportType === 'interactive' ? t('route.name:' + groupName.toLowerCase()) : t('route.name:Projects')}
                            secondLevelText={reportType === 'interactive' ? (navVarBrand) ? navVarBrand : getActiveRoute(routes, t) : t('common:viewProject')}
                        />)
                    )
                    }
                    {
                        <ModalDialog
                            showModal={showExportModal}
                            handleClose={this.handleCloseExport}
                            title={t('dialog:exportSourceData')}
                            footerBtnBsStyle="success"
                            footerBtnClass="KnoaSuccessButton"
                            footerBtnOnClick={this.exportData}
                            footerBtnText={t('common:export')}
                            footerBtnCloseText={t('common:cancel')}
                            formErrors={formErrors}
                            contentBody={
                                <Card
                                    title={t('common:options')}
                                    category={t('dialog:exportDataEncoding')}
                                    content={
                                        <div>
                                            <Col className="projects-dialog-share-content">
                                                <Select
                                                    styles={CustomSelectStyles}
                                                    placeholder={t('common:selectPlaceholder')}
                                                    options={SupportedEncoding}
                                                    getOptionLabel={(option) => option.value}
                                                    getOptionValue={(option) => option.id}
                                                    onChange={this.handleOnEncodingChange}
                                                    isSearchable={false}/>
                                            </Col>
                                            <br/>
                                            <span className="footer text-center">{t('messages.info:exportLimitMessage', {limit: exportLimit})}</span>
                                        </div>

                                    }
                                />
                            }
                        />
                    }
                    {(projects.length >= 0 || users.length >= 0) &&
                        <ModalDialog
                            showModal={showModal}
                            handleClose={this.handleClose}
                            title={reportType === 'interactive' ? t('dialog:addToProject') : t('dialog:shareProject')}
                            footerBtnBsStyle="success"
                            footerBtnClass="KnoaSuccessButton"
                            footerBtnOnClick={reportType === 'interactive' ? this.handleAddProject : this.handleShareProject}
                            footerBtnText={reportType === 'interactive' ? t('common:add') : t('common:share')}
                            footerBtnCloseText={t('common:cancel')}
                            formErrors={formErrors}
                            contentBody={
                                <Card
                                    title={reportType === 'interactive' ? t('route.name:Projects') : t('user:analyticsUsers')}
                                    category={reportType === 'interactive' ? t('dialog:addToProjectPrompt') : t('dialog:shareWithUsersPrompt')}
                                    content={reportType === 'interactive' ? (
                                        <Table className="table" style={{overflow: "auto"}}>
                                            <ListSelect
                                                data={projects}
                                                handleAddToProjectUpdateCheckbox={this.handleAddToProjectUpdateCheckbox}
                                            />
                                        </Table>
                                    ) : (
                                        <Col className="projects-dialog-share-content">
                                            <Select
                                                styles={CustomSelectStyles}
                                                placeholder={t('common:selectPlaceholder')}
                                                value={selectedUsers}
                                                options={this.state.users}
                                                getOptionLabel={(option) => option.value}
                                                getOptionValue={(option) => option.id}
                                                onChange={this.handleShareProjectUpdate}
                                                isMulti
                                                isSearchable={false}/>

                                        </Col>
                                    )
                                    }
                                />
                            }
                        />
                    }
                    {reportCount > 0 ? (
                        <>
                            <Row className="dropdownRow knoa-region"
                                 tabIndex={"-1"}
                                 style={{visibility: reportCount === 1 ? 'hidden' : 'visible', display: reportCount === 1 ? 'none' : 'block'}}>
                                <Col lg={4} sm={8}>
                                    {reportType !== 'project' &&
                                        <Nav bsStyle="pills" activeKey={activeKeyID} onSelect={this.handleSelect} className="report-list-tabs">
                                            <NavItem eventKey={currentDataID} title={t('report.name:currentDataTooltip')}>
                                                {t('report.name:currentData')}
                                            </NavItem>
                                            <NavItem eventKey={historicalDataID} title={t('report.name:historicalDataTooltip')}>
                                                {t('report.name:historicalData')}
                                            </NavItem>
                                        </Nav>
                                    }
                                    {reportType === 'project' &&
                                        <DropdownButton
                                            title={t('project:projectReports', {num: reportCount})}
                                            key="1"
                                            id={`dropdown-basic-0`}
                                            className="dropdownButton"
                                        >
                                            <GenerateMenuItems
                                                workbooks={combinedWorkbooks}
                                                activeMenuItem={dataRangeType === 'instance' ? -1 : workbookId}
                                                menuSelect={this.menuSelect}
                                                t={t}
                                            />
                                        </DropdownButton>
                                    }
                                </Col>
                            </Row>
                            {this.state.exportInProgress &&
                                <ExportSpinner/>
                            }
                            {this.state.fetchInProgress &&
                                <Spinner/>
                            }
                            <div hidden={this.state.fetchInProgress}>
                                <SingleMauiReport
                                    tableauWorkbookRef={this.tableauWorkbookRef}
                                    loaded={loaded}
                                    tableauUrl={tableau.url}
                                    token={tableau.token}
                                    reportType={reportType}
                                    lastReportTab={_lastReportTab}
                                    workbook={currentWorkbook}
                                    filters={latestTableauFilters}
                                    dataRangeType={dataRangeType === "" ? "current" : dataRangeType}
                                    dataRangeTypeForLookup={dataRangeTypeForLookup}
                                    handleShow={this.handleShow}
                                    drillOptions={drillOptions}
                                    instanceWorkbookId={instanceWorkbookId}
                                    isDataReport={isDataReport}
                                    allInstanceWorkbookIds={allInstanceWorkbookIds}
                                    backClick={() => this.backClick(currentWorkbook)}
                                    instanceEnabled={instanceEnabled}
                                    currentReportFilters={latestMauiFiltersTemp}
                                    absoluteStartError={this.state.absoluteStartError}
                                    absoluteEndError={this.state.absoluteEndError}
                                    latestAppliedMauiFilters={latestMauiFilters}
                                    updateTimeFilterValues={this.updateTimeFilterValues}
                                    updateApplicationsAndUserFilterValues={this.updateApplicationsAndUserFilterValues}
                                    handleAuthorization401={this.handleAuthorization401}
                                    updateParametersValues={this.updateParametersValues}
                                    handleResetFilters={this.handleResetFilters}
                                    handleApplyNewFilters={this.handleApplyNewFilters}
                                    moreMenuOptions={moreMenuOptions}
                                    handleSessionPersistChange={this.handleSessionPersistChange}
                                    handlePreferencePersistChange={this.handlePreferencePersistChange}
                                    handleProjectPersistChange={this.handleProjectPersistChange}
                                    workflowReport={isWorkflowReport}
                                    sessionPersist={sessionPersist}
                                    preferencePersist={preferencePersist}
                                    projectPersist={projectPersist}
                                    projectId={projectId}
                                    projectEditable={projectEditable}
                                    attributeMap={attributeMap}
                                    parameterErrors={this.state.parameterErrors}
                                    update={this.state.nUpdate}
                                    filterApplied={this.state.filterApplied}
                                    filterReset={this.state.filterReset}
                                    setTempFilterChanged={this.setTempFilterChanged}
                                    tempFilterChanged={this.state.tempFilterChanged}
                                    trans={t}
                                />
                            </div>
                        </>
                    ) : (
                        <div className="emptyContainer">
                            <i className="fa fa-info-circle emptyContentInfoInco"></i>
                            <span
                                className="emptyContentMessage">{projectEditable ? t('project:viewProjectNoReport') : t('project:viewProjectNoReportViewOnly')}</span>
                            {projectEditable &&
                                <NavLink
                                    to={{
                                        pathname: "editProject",
                                        state: {id: projectId}
                                    }}
                                >
                                    {t('route.name:Edit Project')}
                                </NavLink>
                            }
                        </div>
                    )}
                </div>
            );
        } catch(e) {
            console.error(e);
            window.sessionStorage.setItem("redirectError", "true");
            return <Redirect to='/admin/home'/>;
        }
    }
}

export default withTranslation()(Report);