import React, { Component } from "react";
import {
  Breadcrumb
} from 'react-bootstrap';


class KnoaBreadcrumb extends Component {
  render() {
    const {href, firstLevelText, href2, secondLevelText, href3, thirdLevelText, forthLevelText} = this.props;
    if(href3){
      return (
          <div className="knoa-region" tabIndex={"-1"}>
            <Breadcrumb className="knoa-breadCrumb">
              <Breadcrumb.Item href={href}>
                {firstLevelText}
              </Breadcrumb.Item>
              <Breadcrumb.Item href={href2} onClick={this.props.onClick2 ? this.props.onClick2 : null}>
                {secondLevelText}
              </Breadcrumb.Item>
              <Breadcrumb.Item href={href3} onClick={this.props.onClick3 ? this.props.onClick3 : null}>
                {thirdLevelText}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {forthLevelText}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
      )} else if(href2)
      return (
        <div className="knoa-region" tabIndex={"-1"}>
          <Breadcrumb className="knoa-breadCrumb">
            <Breadcrumb.Item href={href}>
              {firstLevelText}
            </Breadcrumb.Item>
            <Breadcrumb.Item href={href2} onClick={this.props.onClick2 ? this.props.onClick2 : null}>
              {secondLevelText}
            </Breadcrumb.Item>
              <Breadcrumb.Item active>
                  {thirdLevelText}
              </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      )
    else
      return (
        <div className="knoa-region" tabIndex={"-1"}>
          <Breadcrumb className="knoa-breadCrumb">
            <Breadcrumb.Item href={href}>
              {firstLevelText}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {secondLevelText}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      )
  }
}

export default KnoaBreadcrumb;