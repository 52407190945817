/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import MainNavDropdown from "../../common/MainNavDropdown";
import {withTranslation} from 'react-i18next';

class HeaderLinks extends Component {
  render() {
    return (
      <div>
        {this.props.navbarMenuOptions && this.props.navbarMenuOptions!==null &&
          <MainNavDropdown options={this.props.navbarMenuOptions} menuIcon ={this.props.menuIcon} t={this.props.t}/>
        }
      </div>
    );
  }
}
export default withTranslation()(HeaderLinks);
