/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import {API} from "lib/API"

class LogOut extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        API.clearBeat();
        API.user.get("/session/logout");
        sessionStorage.clear();
    }


    render() {
        return <Redirect to={{pathname: '/auth/login'}}/>;
    }
}

export default withTranslation()(LogOut);