import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import ReportApplicationfilters from "../filter/ReportApplicationfilters";
import ReportUserfilters from "../filter/ReportUserfilters";
import Button from "../creativeTim/CustomButton.jsx";
import Switch from 'react-bootstrap-switch';
import TimeframeSelection from "./TimeframeSelection";
import {withTranslation} from "react-i18next";
import KnoaTab from "../common/KnoaTab";
import _ from "lodash";

class ReportFiltersContainer extends Component {
    constructor(props) {
        super(props);
    }

    handleSessionSelectionChange = (elem, checkedSessionSelections) => {
        this.props.handleSessionPersistChange(elem, checkedSessionSelections);
    };

    handleMyPreferenceSaveChange = (elem, checkedPrefenceSelections) => {
        this.props.handlePreferencePersistChange(elem, checkedPrefenceSelections);
    };

    handleProjectPersistChange = (elem, checkedPrefenceSelections) => {
        this.props.handleProjectPersistChange(elem, checkedPrefenceSelections);
    };

    compareUpdatedFilterObj = (currentFilterObj, lastAppliedFilterObj) => {
        // Object.entries(currentFilterObj).forEach()
        let compObj = [];
        let changed = false;
        Object.keys(currentFilterObj).forEach(key => {
            // if (key.startsWith('STRING_ATTR')) {
            //     changed = _.isEqual(currentFilterObj[key]['options'], lastAppliedFilterObj[key]['options'])
            // } else  {
                 if((currentFilterObj[key]['options'] === null || currentFilterObj[key]['options'].length === 0) && (lastAppliedFilterObj[key]['options'] === null || lastAppliedFilterObj[key]['options'].length === 0)) {
                     changed = true;
                 } else {
                     changed = _.isEqual(currentFilterObj[key]['options'], lastAppliedFilterObj[key]['options']);
                 }
            // } 
            compObj.push(changed);
        });

        return compObj.every(Boolean);
    }

    render() {
        const t = this.props.t;
        let timeData = {};
        if (this.props.currentReportFilters && Object.keys(this.props.currentReportFilters).length !== 0) {
            timeData = {
                EndDate: this.props.currentReportFilters.absoluteTimeFrame.end,
                absoluteCalendarStart: this.props.filterType === 'Current' ? this.props.currentReportFilters.currentAbsoluteCalendarStart : this.props.currentReportFilters.historicalAbsoluteCalendarStart,
                absoluteStartDate: this.props.currentReportFilters.absoluteTimeFrame.start,
                limit: this.props.currentReportFilters.relativeTimeFrame.limit,
                relativeNew: this.props.currentReportFilters.relativeTimeFrame.value,
                selectedTimePeriod: this.props.currentReportFilters.relativeTimeFrame.selected ? 'RELATIVE' : 'ABSOLUTE'
            };
        }
        let changed = false;
        if (this.props.currentReportFilters && this.props.latestAppliedMauiFilters) {
            let currentAbsoluteTimeFrame = this.props.currentReportFilters.absoluteTimeFrame;
            let latestAppliedAbsoluteTimeFrame = this.props.latestAppliedMauiFilters.absoluteTimeFrame;

            let currentAbsoluteCalendarStart = this.props.currentReportFilters.currentAbsoluteCalendarStart;
            let latestAppliedAbsoluteCalendarStart = this.props.latestAppliedMauiFilters.currentAbsoluteCalendarStart;

            let currentFilters = this.props.currentReportFilters.filters;
            let latestAppliedFilters = this.props.latestAppliedMauiFilters.filters;

            let currentRelativeTimeFrame = this.props.currentReportFilters.relativeTimeFrame;
            let latestRelativeTimeFrame = this.props.latestAppliedMauiFilters.relativeTimeFrame;

            let currentTableauParams = this.props.currentReportFilters.tableauParams;
            let latestTableauParams = this.props.latestAppliedMauiFilters.tableauParams;

            if (_.isEqual(currentAbsoluteTimeFrame, latestAppliedAbsoluteTimeFrame) && _.isEqual(currentAbsoluteCalendarStart, latestAppliedAbsoluteCalendarStart)
                && this.compareUpdatedFilterObj(currentFilters, latestAppliedFilters) && _.isEqual(currentRelativeTimeFrame, latestRelativeTimeFrame) && _.isEqual(currentTableauParams, latestTableauParams)) {
                changed = false;
            } else {
                changed = true;
            }
        }

        return (
            <>
                <KnoaTab
                    controlId="reportsFilterTabs"
                    currentReportFilters={this.props.currentReportFilters}
                    filterType={this.props.filterType}
                    isInstanceReport={this.props.isInstanceReport}
                    timeData={timeData}
                    updateTimeFilterValues={this.props.updateTimeFilterValues}
                    updateApplicationsAndUserFilterValues={this.props.updateApplicationsAndUserFilterValues}
                    absoluteStartError={this.props.absoluteStartError}
                    absoluteEndError={this.props.absoluteEndError}
                    handleAuthorization401={this.props.handleAuthorization401}
                    handleAlertClick={this.props.handleAlertClick}
                    updateParametersValues={this.props.updateParametersValues}
                    parameterErrors={this.props.parameterErrors}
                    trans={t}
                    workbookId={this.props.workbookId}
                    setTempFilterChanged={this.props.setTempFilterChanged}
                />

                {!this.props.isInstanceReport && <div className={"card-filter"}>
                    <Row className={"card-filter-row"}>
                        <Col className="col-md-6 report-filter-col">
                            <Switch onChange={this.handleSessionSelectionChange}
                                    value={this.props.sessionPersist === true}
                                    onText={t('common:ON')}
                                    offText={t('common:OFF')}
                                    bsSize={'small'}
                            /><span className="pressText KnoaToggle report-filter-col-span">{t('preferences:sessionPersistTitle')}</span>

                            {this.props.reportType === "interactive" &&
                            <>
                                <Switch onChange={this.handleMyPreferenceSaveChange}
                                        value={this.props.preferencePersist === true}
                                        onText={t('common:ON')}
                                        offText={t('common:OFF')}
                                        bsSize={'small'}
                                /><span className="pressText KnoaToggle">{t('preferences:preferencePersist')}</span>
                            </>
                            }
                            {this.props.reportType === "project" && this.props.projectEditable === true &&
                            <>
                                <Switch onChange={this.handleProjectPersistChange}
                                        value={this.props.projectPersist === true}
                                        onText={t('common:ON')}
                                        offText={t('common:OFF')}
                                        bsSize={'small'}
                                /><span className="pressText KnoaToggle">{t('preferences:projectPersist')}</span>
                            </>
                            }
                        </Col>
                        <Col className="col-md-6 card-filter-footer-col-right">
                            <Button
                                id="btnReset"
                                className="pressText KnoaButton"
                                onClick={this.props.handleResetFilters}>{t('common:reset')}</Button>
                            <Button
                                id="btnApply"
                                className={changed ? "pressText KnoaButton changed-filter-identifier" : "pressText KnoaButton"}
                                onClick={this.props.handleApplyNewFilters}>{t('common:apply')}</Button>
                        </Col>
                    </Row>
                </div>}
            </>)
    }
}

export default withTranslation()(ReportFiltersContainer);