/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from "react";
import {Navbar} from "react-bootstrap";

// links that appear in navbar - they are separated from this component (navbar) so that we can redner them on responsive in sidebar as well

import AdminNavbarLinks from "./AdminNavbarLinks.jsx";
import { NavLink } from "react-router-dom";
// we import here the routes for dashboard pages (links that appear in sidebar) to set navbar's name

import { mauiRoutes } from "../../../routes.js";

import { withTranslation } from 'react-i18next';

 

const MenuIcons = props => {
    const {isAdmin, adminIcon, helpIcon, translate} = props;
    return (
      <div className="NavBar-btn-container knoa-region" tabIndex={"-1"}>
          {(isAdmin) ? <NavLink
            to={{pathname: "/admin/AllSettings"}}
            className="nav-link-topbar-setting"
            activeClassName="active"
            title={translate('common:settings')}
          ><i className={adminIcon} title={translate('common:settings')}> </i>
          </NavLink> : null}
          <a aria-current="page" className="nav-link-topbar-help"  href="http://knoabi.helpdocsonline.com/home" title={translate('common:help')} target="_blank"><i className={helpIcon}></i></a>
      </div>
    )
};

class AdminNavbar extends Component {
    constructor(props) {
        super(props);

        this.getNavbarMenuOptions = this.getNavbarMenuOptions.bind(this);

        this.state = {
            navVarBrand : props.navVarBrand
        }
    }
    
    // function for responsive that hides/shows the sidebar
    mobileSidebarToggle = e => {
        document.documentElement.classList.toggle("nav-open");
    };

    getNavbarMenuOptions(t, additionalOptions) {
        let pageName = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
        let menuOptions = null;
        if(pageName==='home'){
            menuOptions = [
                {
                    name: this.props.t('common:customize'),
                    icon: "pe-7s-tools",
                    href: "myPreference"
                }
            ]
        }
        else if(pageName==='viewProject') {
            if (additionalOptions && additionalOptions.length > 0) {
                menuOptions = [
                    {
                        name: this.props.t('route.name:Edit Project'),
                        href: "editProject"
                    },
                    {
                        name: this.props.t('dialog:shareProject'),
                        href: ""
                    }
                ];
                if (additionalOptions) {
                    additionalOptions.map((item, index) => {
                        menuOptions[index] = {...menuOptions[index], ...item};
                    });
                }
            }
        }
        return menuOptions;
    }

    render() {
        let routes = mauiRoutes.getRoutes();
        const { t } = this.props;
        const menuOptions = this.getNavbarMenuOptions(t, this.props.navMenuOptions);

        return (
            <Navbar fluid className="navbar-fixed">
                <Navbar.Header>
                    <Navbar.Brand>
                        {/* Here we create navbar brand, based on route name */}
                        {(this.props.navVarBrand=== undefined || this.props.navVarBrand === "") ? this.props.getActiveRoute(routes,t) : this.props.navVarBrand } 
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={this.mobileSidebarToggle}/>
                </Navbar.Header>
                {/* Here we import the links that appear in navbar */}
                <MenuIcons isAdmin={this.props.isAdmin} adminIcon={this.props.adminIcon} helpIcon={this.props.helpIcon} translate={t}/>
                {window.innerWidth > 992 ? (
                    <Navbar.Collapse>
                        <AdminNavbarLinks navbarMenuOptions={menuOptions}/>
                    </Navbar.Collapse>
                ) : null}
            </Navbar>
        );
    }
}

export default withTranslation()(AdminNavbar);