import React, { Component } from "react";
import {Modal, Row} from "react-bootstrap";
import Button from "react-bootstrap/lib/Button";

const FormErrors = ({formErrors, field}) => (
  formErrors && (
    <div className='formErrors'>
      {Object.keys(formErrors).map((fieldName, i) => {
        if(formErrors[fieldName].length > 0 ){
          return (
            <p className="form-validation-error" key={i} aria-label={formErrors[fieldName]}> {formErrors[fieldName]}</p>
          )
        } else {
          return '';
        }
      })}
    </div>
  )
);

export class ModalDialog extends Component {
  constructor(props){
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    if(this.props.handleClose){
      this.props.handleClose();
    }
  }

  render() {
    const { showModal, title, contentBody, footerBtnBsStyle, footerBtnClass, footerBtnOnClick, footerBtnText,footerBtnCloseText, formErrors, bodyPaddingBottom } = this.props;
    const btnDisabled = (contentBody.props.content && contentBody.props.content.props.children.props && contentBody.props.content.props.children.props.data) ? contentBody.props.content.props.children.props.data.length<=0 : false;
    const btnClass = btnDisabled ? "KnoaSuccessButtonDisabled" : footerBtnClass;
    return (
      <Modal show={showModal} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="dialog-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{paddingBottom: bodyPaddingBottom ? bodyPaddingBottom : 0}}>
          {formErrors &&
            <Row className="dialog-row-error">
              <FormErrors formErrors={formErrors} field=""/>
            </Row>
          }
          {contentBody}
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={btnDisabled} bsStyle={footerBtnBsStyle} className={btnClass} onClick={footerBtnOnClick}>{footerBtnText}</Button>
            {this.props.handleClose && <Button onClick={this.handleClose}>{footerBtnCloseText}</Button>} 
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ModalDialog;