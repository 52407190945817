import React, {Component} from "react";
import KpiContainer from "../../../components/kpi/KpiContainer";

class AdminKpiCardPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allKpi: [],
            title: "KPI Cards"
        };
    }

    render() {
        return (
            <KpiContainer title={this.state.title} allKpi={this.state.allKpi} handleAlertClick={this.props.handleAlertClick} getActiveRoute={this.props.getActiveRoute} />
        );
    }
}

export default AdminKpiCardPage;