import React, {Component} from "react";
import {Nav, NavDropdown} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {withTranslation} from "react-i18next";

const GenerateMenuItems = ({options}) => {
    const menuItems = options.map((item, index) => {
        const ekey = (index + 1) / 10 + 1;
        return (
            <li role="presentation" key={ekey}>
                <NavLink
                    to={{
                        pathname: item.href,
                        state: {
                            id: item.id ? item.id : "",
                            newProject: item.newProject ? item.newProject : null,
                            preSelectReportID: item.preSelectReportID ? item.preSelectReportID : null
                        }
                    }}
                    onClick={item.onClick ? item.onClick : null}
                    role="menuitem"
                    tabIndex={"-1"}
                >
                    <div>
                        <i className={item.icon ? item.icon : ""}/> {item.name}
                    </div>
                </NavLink>
            </li>
        )
    });
    return menuItems
};

class MainNavDropdown extends Component {
    render() {
        const {options} = this.props;

        return (
            <>
                {this.props.menuIcon === undefined &&
                <Nav pullRight>
                    <NavDropdown
                        eventKey={1}
                        title={
                            <i className="pe-7s-menu" title={this.props.t("common:options")}/>
                        }
                        id="basic-nav-dropdown-3"
                        bsClass="dropdown-with-icons dropdown"
                        aria-label={this.props.t("common:options")}
                    >
                        <GenerateMenuItems options={options}/>
                    </NavDropdown>
                </Nav>}
                {this.props.menuIcon !== undefined &&
                <Nav pullRight>
                    <NavDropdown
                        eventKey={1}
                        title={
                            <i className={this.props.menuIcon + " filter-moreoption-menu"}/>
                        }
                        id="basic-nav-dropdown-4"
                        bsClass="dropdown-with-icons dropdown"
                    >
                        <GenerateMenuItems options={options}/>
                    </NavDropdown>
                </Nav>}
            </>
        );
    }
}

export default withTranslation()(MainNavDropdown);