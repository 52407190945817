import React, {Component} from "react";
import ProjectInformation from "../../../components/Project/ProjectInformation";
import ProjectFilters from "../../../components/Project/ProjectFilters";
import {withTranslation} from 'react-i18next';

class ProjectStep1 extends Component {
    render() {
      //screenGroupSelected, screenGroupOptions, handleScreenGroupsOnChange, handleScreenGroupsInputChange,
        const {
            projectName, projectTeam, userOptions, handleProjectNameUpdate, handleProjectTeamUpdate,
            applicationSelected, moduleSelected, applicationOptions, moduleOptions, userAttributeFilters, handleApplicationsOnChange,
            handleApplicationsInputChange, handleModulesOnChange, handleModulesInputChange, handleUserAttributesUpdate, resetOptions, projectNameError,
            annualFTECost, annualFTEHours, LTNonError, LTSysError, LTUserError, LTUncatMessage, projectDuration, projectUser, testEffortPerModule,formErrors,
            inputClass_annualFTECost, inputClass_annualFTEHours, inputClass_LTNonError, inputClass_LTSysError, inputClass_LTUserError, inputClass_LTUncatMessage, inputClass_projectDuration, inputClass_projectUser, inputClass_testEffortPerModule,
            handleAnnualFTECostInputChange, handleAnnualFTEHoursInputChange, handleLTNonErrorInputChange, handleLTSysErrorInputChange, handleLTUserErrorInputChange, handleLTUncatMessageInputChange ,handleProjectDurationInputChange, handleProjectUserInputChange, handleTestEffortPerModuleInputChange,t} = this.props;

        return (
            <div className="wizard-step">
                <h5 className="text-center wizard-subtitle">{t('project:infoTitle')}</h5>
                <ProjectInformation
                    projectName={projectName}
                    projectTeam={projectTeam}
                    userOptions={userOptions}
                    handleProjectNameUpdate={handleProjectNameUpdate}
                    handleProjectTeamUpdate={handleProjectTeamUpdate}
                    projectNameError={projectNameError}
                    trans={t}
                />
                <ProjectFilters
                    applicationSelected={applicationSelected}
                    moduleSelected={moduleSelected}
                    //screenGroupSelected={screenGroupSelected}
                    applicationOptions={applicationOptions}
                    moduleOptions={moduleOptions}
                    //screenGroupOptions={screenGroupOptions}
                    userAttributeFilters={userAttributeFilters}
                    handleApplicationsOnChange={handleApplicationsOnChange}
                    handleApplicationsInputChange={handleApplicationsInputChange}
                    handleModulesOnChange={handleModulesOnChange}
                    handleModulesInputChange={handleModulesInputChange}
                    //handleScreenGroupsOnChange={handleScreenGroupsOnChange}
                    //handleScreenGroupsInputChange={handleScreenGroupsInputChange}
                    handleUserAttributesUpdate={handleUserAttributesUpdate}
                    resetOptions={resetOptions}
                    projectNameError={projectNameError}
                    annualFTECost={annualFTECost}
                    annualFTEHours={annualFTEHours}
                    LTNonError={LTNonError}
                    LTSysError={LTSysError}
                    LTUserError={LTUserError}
                    LTUncatMessage={LTUncatMessage}
                    projectDuration={projectDuration}
                    projectUser={projectUser}
                    testEffortPerModule={testEffortPerModule}
                    formErrors={formErrors}
                    inputClass_annualFTECost={inputClass_annualFTECost}
                    inputClass_annualFTEHours={inputClass_annualFTEHours}
                    inputClass_LTNonError={inputClass_LTNonError}
                    inputClass_LTSysError={inputClass_LTSysError}
                    inputClass_LTUserError={inputClass_LTUserError}
                    inputClass_LTUncatMessage={inputClass_LTUncatMessage}
                    inputClass_projectDuration={inputClass_projectDuration}
                    inputClass_projectUser={inputClass_projectUser}
                    inputClass_testEffortPerModule={inputClass_testEffortPerModule}
                    handleAnnualFTECostInputChange={handleAnnualFTECostInputChange}
                    handleAnnualFTEHoursInputChange={handleAnnualFTEHoursInputChange}
                    handleLTNonErrorInputChange={handleLTNonErrorInputChange}
                    handleLTSysErrorInputChange={handleLTSysErrorInputChange}
                    handleLTUserErrorInputChange={handleLTUserErrorInputChange}
                    handleLTUncatMessageInputChange={handleLTUncatMessageInputChange}
                    handleProjectDurationInputChange={handleProjectDurationInputChange}
                    handleProjectUserInputChange={handleProjectUserInputChange}
                    handleTestEffortPerModuleInputChange={handleTestEffortPerModuleInputChange}
                    trans={t}
                />
            </div>
        )
    }
}

export default withTranslation()(ProjectStep1);