import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";
import {Grid,Row,Col,FormGroup,ControlLabel,FormControl} from "react-bootstrap";
import Card from "../../../components/Card/Card";
import Button from "../../../components/creativeTim/CustomButton";
import PropTypes from "prop-types";
import {API} from "lib/API";
import Spinner from "../../../components/common/Spinner";
import {withTranslation} from 'react-i18next';
import Footer from "../../../components/layout/Footer";
import CommonContainer from "../../../components/common/CommonContainer";
import ModalDialog from "../../../components/common/ModalDialog";
import {getErrorMessagesFromCode, getErrorMsgFromStatus, getSpecificOPErrorMessages} from "../../../lib/messageHandler";
const localStorage = require('../../../lib/localStorage');
class SamlLanding extends Component {
  constructor(props) {
    super(props);
    this.parseURL = this.parseURL.bind(this);
    this.state = {
      userId : "",
      number: 0,
      loaded: false,
    }
  }

  parseURL(key) {
    let value = '';
    const search = this.props.location.search; //"?valid=valid&token=eyJpZ..."
    const items = search.split('&'); //["?valid=valid","token=eyJpZ..."]
    let indexStart;
    for(let i=0; i<items.length; i++){
      if(items[i].indexOf(key)!==-1){
        indexStart = items[i].indexOf(key + '=')+key.length+1;
        value = items[i].substring(indexStart);
        break;
      }
    }
    return value;
  }

  updateTokens = ({accessToken, refreshToken, expiresIn}) => {
    API.updateAccessToken({accessToken, refreshToken, expiresIn});
    this.setState({session: true,
      updatedAccessToken: accessToken});
  };

  login(_userId) {
    API.user
        .post('/session/loginSaml', {
          userId : _userId,
        })
        .then(resp => {
          if(resp) {


            const {data: resData} = resp;
            const {data} = resData;

            this.setState({
              wrongPassword: null,
              wrongUser: null,
              customers: data.customers,
              tfa: data.tfa,
              loaded: true
            });

            this.updateTokens({
              accessToken: data.access,
              expiresIn: data.expires
            });


          }
        })
        .catch(e => {
          let resultCode = -1;
          if(e.data) {
            resultCode = e.data.resultCode;
          }

          if (resultCode > -1){
            this.props.clearAlerts();
            this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
          } else{
            this.props.clearAlerts();
            this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t,  "requestCouldProcess", null, null));
        }
        });
  };

  setUserId(userId) {
    this.setState({
      userId : userId
    });
  }

  check() {
    let _userId  = this.parseURL('id');
    this.setUserId(_userId);
    this.login(_userId)
  }

  componentDidMount() {
    let t = new Date().getTime();
    this.setState({
          number : t
        });
    this.check();
  }

  render() {
    if(this.state.loaded)
      return <Redirect to={{pathname: '/admin/home'}}/>;
    return ( <div/> );
  }
}

export default withTranslation()(SamlLanding);