/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "./Footer.jsx";
// dinamically create pages routes
import {mauiRoutes} from "../../routes";
import bgImage from "assets/img/full-screen-image-2.jpg";

import NotificationSystem from "react-notification-system";
import NotificationStyles from "../common/NotificationStyles";

let routes = mauiRoutes.getRoutes();

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null
    }
  }

  componentDidMount() {
    this.setState({_notificationSystem: this.refs.notificationSystem});
  }

  handleNotificationClick = (level, position, message, autoDismissTime) => {
    let iconClass = "";
    switch(level){
      case "success":
        iconClass = "pe-7s-check";
        break;
      case "warning":
        iconClass = "pe-7s-attention";
        break;
      case "error":
        iconClass = "pe-7s-attention";
        break;
      case "info":
        iconClass = "pe-7s-info";
        break;
      default:
        break;
    }

    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className={iconClass}/>,
      message: <div aria-label={message} role="alert" >{message}</div>,
      level: level,
      position: position,
      autoDismiss: autoDismissTime>=0 ? autoDismissTime : 15
    });
  };
  
  clearNotification = () => {
    this.state._notificationSystem.clearNotifications();
  };
  
    componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  getPageClass() {
    var pageClass = "";
    switch (this.props.location.pathname.toLowerCase()) {
      case "/auth/login":
        pageClass = "full-page login-page";
        break;
      case "/auth/forgotpassword":
        pageClass = "full-page login-page";
        break;
      case "/auth/resetpassword":
        pageClass = "full-page login-page";
        break;
      case "/auth/twofactorauthentication":
        pageClass = "full-page login-page";
        break;
      case "/auth/changepassword":
        pageClass = "full-page login-page";
        break;
      case "/auth/register-page":
        pageClass = "full-page register-page";
        break;
      case "/auth/lock-screen-page":
        pageClass = "full-page lock-page";
        break;
      default:
        pageClass = "full-page";
        break;
    }
    return pageClass;
  }

  getContentClass() {
    let contentClass = "";
    switch (this.props.location.pathname.toLowerCase()) {
      case "/auth/login":
        contentClass = "content";
        break;
      default:
        contentClass = "content";
        break;
    }
    return contentClass;
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => (
              <prop.component {...props}
                              handleAlertClick={this.handleNotificationClick}
                              clearAlerts={this.clearNotification}/>
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <div>
        {/*<AuthNavbar />*/}
        <NotificationSystem ref="notificationSystem" style={NotificationStyles}/>
        <div className="wrapper wrapper-full-page knoaAuth">
          <div
            className={this.getPageClass()}
            data-color="blue"
            data-image={bgImage}
          >
            <div className={this.getContentClass()}>
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
            {this.props.location.pathname!=='/auth/customerSupport' &&
            <Footer transparent loginpage/>
            }
            <div
              className="full-page-background"
              style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Pages;