/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from "react";
import {Grid, Row, Col} from "react-bootstrap";

class CommonContainer extends Component {
    render() {
        return (
            <Grid fluid>
                    <Row className={this.props.class ? this.props.class : {}}>
                        {this.props.content}
                    </Row>
            </Grid>
        );
    }
}

export default CommonContainer;
