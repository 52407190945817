/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
// react component that creates notifications (like some alerts with messages)
import NotificationSystem from "react-notification-system";

import Sidebar from "components/layout/Sidebar.jsx";
import AdminNavbar from "components/layout/navbars/AdminNavbar.jsx";

import {capitalizeWords} from "../../lib/common";

import image from "assets/img/sidebar.jpg";

// dinamically create dashboard routes
import {mauiRoutes} from "../../routes.js";
import {API} from "../../lib/API";
import NotificationStyles from "../common/NotificationStyles";
import {withTranslation} from "react-i18next";
import UserProxy from "../customerSupport/UserProxy";
import IdleTimer from 'react-idle-timer';
import ModalDialog from "../common/ModalDialog";
import {getInfoMessages} from "../../lib/messageHandler";

let ps;
let routes;
const localStorage = require('../../lib/localStorage');
const BuildSideBar = props => {
    let {loaded,updateRoutes} = props;
    if(loaded && window.sessionStorage.getItem("proxyLogin")==="true")
        updateRoutes();
    if (loaded) {
        return <Sidebar
            {...props}
            image={props.image}
            color={props.color}
            hasImage={props.hasImage}
            mini={props.mini}
            updatePageTitle={props.updatePageTitle}
            email={props.email}
            csRoutes={props.csRoutes}
            currentRoute={props.currentRoute}
            handleSidebarClick={props.handleSidebarClick}
            t={props.t}
        />
    }
    return <div></div>;
};

const BuildSwitches = props => {
    const {loaded, getRoutes, updatePageTitle} = props;
    if (loaded) {
        return <Switch>{getRoutes(routes, updatePageTitle)}</Switch>
    }
    return <div></div>;
};

const csRoutes = [
    {
        id: 0,
        component: UserProxy,
        sbLinkName: "Proxy User"
    }
];

class Dashboard extends Component {
    constructor(props) {
        super(props);

        let IsCustomerSupportPage = this.props.location.pathname === '/admin/customerSupport';

        if (IsCustomerSupportPage) {
            this.handleSidebarClick = this.handleSidebarClick.bind(this);
            this.state = {
                _notificationSystem: null,
                currentRoute: csRoutes[0],
                accessDenied: false,
                loaded: false,
                showTimeoutWindow:false,
                isTimedOut: false
            };
        } else {
            routes = mauiRoutes.getRoutes();
            
            this.updateNavMenuOptions = this.updateNavMenuOptions.bind(this);
            this.updatePageTitle = this.updatePageTitle.bind(this);
            this.getActiveRoute = this.getActiveRoute.bind(this);

            this.mainPanel = React.createRef();

            this.state = {
                _notificationSystem: null,
                image: image,
                color: "blue",
                hasImage: true,
                mini: false,
                fixedClasses: "dropdown show-dropdown open",
                navMenuOptions: null,
                navBarBrand: "",
                loaded: false,
                isAdmin: false,
                isSupportAcctLoggedin: false,
                showTimeoutWindow:false,
                isTimedOut: false
            };
        }

        this.idleTimer = null;
        this.logoutTimer = null;
    }

    updateRoutes = () =>{
        this.loadRoutes();
        window.sessionStorage.removeItem("proxyLogin");
    };

    loadRoutes() {
        API.user
            .get("/user/menu")
            .then(resp => {
                const {data, resultCode} = resp.data;
                if (resultCode === 200) {
                    mauiRoutes.loadRoutes(data.reportMenu, data.admin, data.homeIcon, data.projectIcon, data.adminCardIcon, data.adminUserIcon);
                    this.setState({
                        isAdmin: data.admin,
                        adminIcon: data.adminIcon,
                        helpIcon: data.helpIcon
                    });
                    routes = mauiRoutes.getRoutes();
                    this.setState({loaded: true});
                } else if (resultCode === 6007) {
                    window.location.href = "/#/auth/logout";
                }
            })
            .catch(e => {
                console.log(e);
                if(localStorage.get("accessDenied401")=== "true"){
                    window.location.href = "/#/auth/logout";
                    return;
                }
                if (e.data) {
                    const {data, resultCode} = e.data;
                    if (resultCode === 6007) {
                        window.location.href = "/#/auth/logout";
                    }
                } else {
                    window.location.href = "/#/auth/logout";
                }
            });
    }

    _onIdle=(e)=> {
        this.setState({showTimeoutWindow: true});
        this.logoutTimer = setTimeout(() => {
            this.handleLogout();
        }, 1000 * 60);
    }

    handleExtendSession = () => {
        //refresh token or call api to extend the session
        this.setState({showTimeoutWindow: false});
        if (this.logoutTimer) {
            clearTimeout(this.logoutTimer);
            this.logoutTimer = null;
        }
        this.idleTimer.reset();
        API.refreshAccessToken();
    };

    handleLogout = () =>{
        this.setState({showTimeoutWindow: false});
        window.location.href = "/#/auth/logout";
    };

    componentDidMount() {
        this.setState({_notificationSystem: this.refs.notificationSystem});
        this.loadRoutes();
        let IsCustomerSupportPage = this.props.location.pathname === '/admin/customerSupport';
        if (IsCustomerSupportPage) {
            let locationState = this.props.location.state;
            this.setState({
                accessDenied:  !locationState && window.sessionStorage.getItem("proxyProps")===undefined,
                loaded: true
            });
            
        } else {
            if (navigator.platform.indexOf("Win") > -1) {
                ps = this.mainPanel.current;
            }
        }

        //keyboard shortcuts
        window.sessionStorage.removeItem("ssFocusIndx");
        document.addEventListener('keydown',function (event) {
            if(event.key === 'F6') {
                console.log('pressed: ' + event.key)
                let indx = JSON.parse(window.sessionStorage.getItem('ssFocusIndx'));
                const knoaRegionElements = document.getElementsByClassName("knoa-region");
                const elementLength = knoaRegionElements.length;

                //allow for browser to execute its own functionality
                if(indx === -1) {
                    window.sessionStorage.removeItem("ssFocusIndx");
                    return;
                }

                //reset if index is ever greater than element count - 1
                //Example: Project Setup - Project Information step -> Current Analysis step
                if(indx > elementLength - 1) {
                    indx = null;
                }

                if(indx===null) {
                    indx = !event.shiftKey ? 0 : (elementLength - 1);
                }

                if(!event.shiftKey) { //F6
                    if (indx <= elementLength - 1) {
                        event.preventDefault();
                        knoaRegionElements[indx].focus();
                        indx = (indx < elementLength - 1) ? (indx + 1) : -1;
                        window.sessionStorage.setItem("ssFocusIndx", JSON.stringify(indx));
                    }
                } else { //Shift+F6
                    if(indx >= 0) {
                        event.preventDefault();
                        knoaRegionElements[indx].focus();
                        indx = indx > 0 ? (indx - 1) : -1;
                        window.sessionStorage.setItem("ssFocusIndx", JSON.stringify(indx));
                    }
                }
            }
        })
    }

    componentWillUnmount() {
        //reset ssFocusIndx (e.g., refresh)
        window.sessionStorage.removeItem("ssFocusIndx");
    }

    componentDidUpdate(e) {
        if (window.innerWidth < 993 &&
            e.history.action === "PUSH" &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
        }

        //reset ssFocusIndx on any update
        window.sessionStorage.removeItem("ssFocusIndx");
    }

    componentWillMount() {
        if (document.documentElement.className.indexOf("nav-open") !== -1) {
            document.documentElement.classList.toggle("nav-open");
        }
    }

    updateNavMenuOptions = (options) => {
        if (options) {
            this.setState({
                navMenuOptions: options
            });
        }
    };

    clearNotification = () => {
        this.state._notificationSystem.clearNotifications();
    };

    //for customer support page 
    handleSidebarClick(id) {
        this.setState({currentRoute: csRoutes[id]});
    }

    // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
    handleNotificationClick = (level, position, message, inSupport) => {
        let iconClass = "";
        switch(level){
            case "success":
                iconClass = "pe-7s-check";
                break;
            case "warning":
                iconClass = "pe-7s-attention";
                break;
            case "error":
                iconClass = "pe-7s-attention";
                break;
            case "info":
                iconClass = "pe-7s-info";
                break;
            default:
                break;
        }

        this.state._notificationSystem.addNotification({
            title:  <span data-notify="icon" className={iconClass} />,
            message: <div aria-label={message} role="alert" >{message}</div>,
            level: level,
            position: position,
            autoDismiss: 15
        });
        // if(inSupport) {
        //     let msg = getInfoMessages(this.props.t, 'proxyUser',null,null);
        //     this.state._notificationSystem.addNotification({
        //         title: <span data-notify="icon" className="pe-7s-info"/>,
        //         message: (msg),
        //         level: "info",
        //         position: position,
        //         autoDismiss: 15
        //     });
        // }
    };
    
    handleImageClick = image => {
        this.setState({image: image});
    };
    handleColorClick = color => {
        this.setState({color: color});
    };
    handleHasImage = hasImage => {
        this.setState({hasImage: hasImage});
    };
    handleMiniClick = () => {
        this.setState({mini: !this.state.mini});
        document.body.classList.toggle("sidebar-mini");
    };
    handleFixedClick = () => {
        if (this.state.fixedClasses === "dropdown") {
            this.setState({fixedClasses: "dropdown show-dropdown open"});
        } else {
            this.setState({fixedClasses: "dropdown"});
        }
    };
    getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            } else if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        key={key}
                        render={routeProps => (
                            <prop.component
                                {...routeProps}
                                handleAlertClick={this.handleNotificationClick}
                                clearAlerts={this.clearNotification}
                                updateNavMenuOptions={this.updateNavMenuOptions}
                                updatePageTitle={this.updatePageTitle}
                                key={prop.needRefresh ? Date.now() : prop.name}
                                navVarBrand={this.state.navBarBrand}
                                getActiveRoute={this.getActiveRoute}
                                isAdmin={this.state.isAdmin}  // for control the admin edit
                                reportType={prop.reportType ? prop.reportType : null}
                            />
                        )}
                    />
                );
            } else {
                return null;
            }
        });
    };

    getActiveRoute = (routes, translate) => {
        let activeRoute = "Knoa Maui";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = this.getActiveRoute(routes[i].views, translate);
                if (collapseActiveRoute !== activeRoute) {
                    return translate('report.name:' + capitalizeWords(collapseActiveRoute));
                }
            } else {
                // handle whitespace in url
                let pageName = (routes[i].layout + routes[i].path).replace(/\s/g, '%20').toLowerCase()
                if (window.location.href.toLowerCase().indexOf(pageName) !== -1) {
                    return this.props.navVarBrand ? this.props.navVarBrand : translate('route.name:' + routes[i].name);
                }
            }
        }
        return activeRoute;
    };

    getActiveRouteForCustomerSupport = (routes, translate) => {
        let activeRoute = "Proxy User";
        return activeRoute;
    };

    updatePageTitle=(_navBarBrand)=> {
        this.setState({navBarBrand: _navBarBrand});
    }

    render() {
        let IsCustomerSupportPage = this.props.location.pathname === '/admin/customerSupport';
        const {t} = this.props;
        const {currentRoute, loaded} = this.state;
        let propState = JSON.parse(window.sessionStorage.getItem("proxyProps"));
        let proxyCustomers =[];
        let proxyEmail ="";
        if(propState !== null){
            proxyCustomers = propState.customers;
            proxyEmail = propState.email;
        }
        // proxyUserCustomers: (locationState && locationState.customers) ? locationState.customers : [],
        //     proxyUserEmail: (locationState && locationState.email) ? locationState.email : "",
        const componentProps = {};
        if (IsCustomerSupportPage) {
            if ((this.state && this.state.accessDenied === true) || (!this.props.location.state && (proxyCustomers.length===0 && proxyEmail===""))) {
                return <Redirect to={{
                    pathname: '/auth/login',
                    state: {session: false}
                }}/>
            }
            if(currentRoute.sbLinkName === 'Proxy User'){
                if (this.props.location.state) {
                    componentProps.customers = this.props.location.state.customers;
                    componentProps.email = this.props.location.state.email;
                } else {
                    componentProps.customers = propState.customers;
                    componentProps.email = propState.email;
                }
            }
        } else {
            routes = mauiRoutes.getRoutes();
        }

        return (
            <div className="wrapper">
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onIdle={this._onIdle}
                    timeout={1000*60*(API.getTimeout() - 1)} />
                <NotificationSystem ref="notificationSystem" style={NotificationStyles}/>
                <ModalDialog
                    showModal={this.state.showTimeoutWindow}
                    title={t("common:sessionInformation")}
                    contentBody={
                        <span>{getInfoMessages(this.props.t,'timeoutWarning', 'timestamp', new Date())}</span>
                    }
                    bodyPaddingBottom='15px'
                    footerBtnBsStyle="success"
                    footerBtnClass="KnoaSuccessButton"
                    footerBtnOnClick={this.handleLogout}
                    footerBtnText={t("route.name:logOut")}
                    footerBtnCloseText={t("common:keepWorking")}
                    handleClose={this.handleExtendSession}
                />
                {IsCustomerSupportPage && <>
                    <BuildSideBar
                        email={componentProps.email}
                        csRoutes={csRoutes}
                        currentRoute={currentRoute}
                        handleSidebarClick={this.handleSidebarClick}
                        t={t}
                        loaded={this.state.loaded}
                        updateRoutes={this.updateRoutes}/>
                    <div className="main-panel">
                        {(this.props.location.state || propState !== null) && loaded &&
                         <>  <AdminNavbar
                             {...this.props}
                             handleMiniClick={this.handleMiniClick}
                             navMenuOptions={this.state.navMenuOptions}
                             navVarBrand={this.state.navBarBrand}
                             updatePageTitle={this.updatePageTitle}
                             mini={this.state.mini}
                             isAdmin={this.state.isAdmin}
                             adminIcon={this.state.adminIcon}
                             helpIcon={this.state.helpIcon}
                             getActiveRoute={this.getActiveRouteForCustomerSupport}
                         /> 
                        <this.state.currentRoute.component
                            {...componentProps}
                            routes = {this.getRoutes}
                            handleNotificationClick={this.handleNotificationClick}
                        /></>
                        }
                    </div>
                </>
                }
                {!IsCustomerSupportPage && <>
                    <BuildSideBar
                        {...this.props}
                        image={this.state.image}
                        color={this.state.color}
                        hasImage={this.state.hasImage}
                        mini={this.state.mini}
                        updatePageTitle={this.updatePageTitle}
                        loaded={this.state.loaded}
                        updateRoutes={this.updateRoutes}
                    />
                    <div
                        className={
                            "main-panel"
                        }
                        ref={this.mainPanel}
                    >
                        <AdminNavbar
                            {...this.props}
                            handleMiniClick={this.handleMiniClick}
                            navMenuOptions={this.state.navMenuOptions}
                            navVarBrand={this.state.navBarBrand}
                            updatePageTitle={this.updatePageTitle}
                            mini={this.state.mini}
                            isAdmin={this.state.isAdmin}
                            adminIcon={this.state.adminIcon}
                            helpIcon={this.state.helpIcon}
                            getActiveRoute={this.getActiveRoute}
                        />
                        <BuildSwitches loaded={this.state.loaded} getRoutes={this.getRoutes} updatePageTitle={this.updatePageTitle}/>
                    </div>
                </>
                }
            </div>
        );
    }
}

export default withTranslation()(Dashboard);