import React, { Component } from "react";
import LinkWithTooltip from "../common/LinkWithTooltip.jsx";
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import {withTranslation} from 'react-i18next';
import {getLocalDateFromUTCTimestamp} from "../../lib/common";

class ProjectContainer extends Component {
  constructor(props) {
    super(props);

    this.handleMyProjectClick = this.handleMyProjectClick.bind(this);
    this.handleAllProjectClick = this.handleAllProjectClick.bind(this);
    this.generateData = this.generateData.bind(this);

    this.state = {myProjects: true};
  }

  handleMyProjectClick = () => this.setState({myProjects: !this.state.myProjects});
  handleAllProjectClick = () => this.setState({myProjects: !this.state.myProjects});

  generateData = (projects) => {
    const dataToReturn = projects.map(project => {
      let projectArray = [];
      projectArray.push(project.id);
      projectArray.push(project.name);
      projectArray.push(project.reports);
      projectArray.push(project.author);
      projectArray.push(getLocalDateFromUTCTimestamp(project.createdTimestamp * 1000));
      projectArray.push(project.editable);
      projectArray.push(project.shared);
      return projectArray
    });
    return dataToReturn;
  };

  render() {
    const dataTable = this.generateData(this.state.myProjects ? this.props.myProjects : this.props.allProjects);
    const { handleShow, handleShareShow, t} = this.props;
    const defaultPageSize = 10;
    const minRows = (dataTable.length > 0 && dataTable.length <= 10) ? dataTable.length : dataTable.length === 0 ? 3 : defaultPageSize;

    return (
      <div className="card">
        <div className="content">
          <div className="panel-body">
            <div className="NavBar-btn-container knoa-region" tabIndex={"-1"}>
              <Link
                to={{
                  pathname: "/admin/newProject",
                  state: {
                    newProject: true
                  }
                }}
                className="btn btn-default NavBar-btn btn-md">
                {t('common:newProject')}
              </Link>
            </div>
            <br/>
            <div className={"knoa-region"} tabIndex={"-1"}>
            {this.state.myProjects && <a className="linkOff" onClick={this.handleMyProjectClick} >{t('project:myProjects')}</a>}
            {!this.state.myProjects && <a className="linkOn"  href="javascript:void(null);" onClick={this.handleMyProjectClick} >{t('project:myProjects')}</a>}
            &nbsp;|&nbsp;
            {this.state.myProjects && <a className="linkOn" href="javascript:void(null);" onClick={this.handleAllProjectClick} >{t('project:allProjects')}</a>}
            {!this.state.myProjects && <a className="linkOff"  onClick={this.handleAllProjectClick} >{t('project:allProjects')}</a>}
            </div>
            <br/><br/>
            <div className={"knoa-region"} tabIndex={"-1"}>
              <ReactTable
                getTheadThProps={(state, rowInfo, column, instance) => {
                  return {
                    tabIndex: column.sortable === false ? '-1' : '0',
                    onKeyPress: (event) => {
                      if (
                          column.sortable !== false &&
                          (event.key === 'Enter' || event.key === ' ')
                      )
                        instance.sortColumn(column);
                    },
                }}}
                columns={[
                  {
                    Header: t('messages.item:Project'),
                    accessor: "project",
                    /*filterMethod: (filter, row) => {
                      const id = filter.pivotId || filter.id;
                      return row[id].props.children !== undefined ? String(row[id].props.children).startsWith(filter.value) : true
                    },*/
                    sortMethod: (a, b) => {
                      a = a.props.children;
                      b = b.props.children;
                      // force null and undefined to the bottom
                      a = a === null || a === undefined ? -Infinity : a;
                      b = b === null || b === undefined ? -Infinity : b;
                      // force any string values to lowercase
                      a = typeof a === 'string' ? a.toLowerCase() : a;
                      b = typeof b === 'string' ? b.toLowerCase() : b;
                      // Return either 1 or -1 to indicate a sort priority
                      if (a > b) {
                        return 1
                      }
                      if (a < b) {
                        return -1
                      }
                      // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
                      return 0
                    }
                  },
                  {Header: t('project:reports'),accessor: "reports",maxWidth: 150},
                  {Header: t('project:author'),accessor: "author",maxWidth: 300},
                  {
                    Header: t('project:created'),
                    accessor: "created",
                    maxWidth: 200,
                    sortMethod: (a, b) => {
                      const parseA = Date.parse(a);
                      const parseB = Date.parse(b);


                      return (new Date(parseA) - new Date(parseB));
                    }
                  },
                  {Header: t('user:actions'),accessor: "actions",sortable: false,maxWidth: 150}
                ]}
                data={
                  dataTable.map((prop,key) => {
                    return ({
                      id: key,
                      project: (
                        <Link
                          to={{
                            pathname: "/admin/viewProject",
                            state: {
                              id: prop[0],
                              projectName: prop[1],
                              editable: prop[5]
                            }
                          }}
                        >{prop[1]}</Link>
                      ),
                      reports: prop[2],
                      author: prop[3],
                      created: prop[4],
                      actions: (
                        <div className="actions-right">
                          {prop[5] &&
                            <>
                              <LinkWithTooltip tooltip={t('common:share')} onClick={handleShareShow} href="projects" id={prop[0] + '_-$-_' + prop[1] + '_-$-_' + prop[3] + '_-$-_' + prop[6] + '_-$-_share'} tooltipIconClass="fa fa-user" bsStyle="edit" iconStyle={{fontSize: 16 + 'px'}} />
                              <LinkWithTooltip tooltip={t('common:edit')} href="editProject" id={prop[0]} tooltipIconClass="fa fa-edit" bsStyle="edit" iconStyle={{fontSize: 16 + 'px'}} />
                              <LinkWithTooltip tooltip={t('common:delete')} onClick={handleShow} href="projects" id={prop[0] + '_-$-_' + prop[1] + '_-$-_' + prop[3] + '_-$-_' + prop[6] + '_-$-_delete'} tooltipIconClass="fa fa-times" bsStyle="edit" iconStyle={{fontSize: 16 + 'px'}} />
                            </>
                          }
                        </div>
                      )
                    })
                  })
                }
                defaultSorted={[
                  {
                    id: "project",
                    desc: false
                  }
                ]}
                filterable={false}
                defaultPageSize={defaultPageSize}
                showPagination
                showPaginationTop={false}
                showPaginationBottom
                showPageSizeOptions={false}
                className="-highlight"
                minRows={minRows}
                getPaginationProps={(a) => {
                  return {
                      style: {
                          fontSize: '12px',
                          width: '360px',
                      }
                  }
                }}
                previousText={t('common:previous')}
                nextText={t('common:next')}
                noDataText={t('project:noPrjectsFound')}
                pageText={t('common:page')}
                ofText={t('common:of')}
            />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ProjectContainer);