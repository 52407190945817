import React, {Component} from "react";
import Card from "../../../components/Card/Card";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import CustomCheckbox from "../../../components/creativeTim/CustomCheckbox";
import UAFilterContainer from "../../../components/common/UAFilterContainer.jsx";
import {API} from "../../../lib/API";
import LinkWithTooltip from "../../../components/common/LinkWithTooltip";
import {withTranslation} from 'react-i18next';
import Footer from "../../../components/layout/Footer";
import CommonContainer from "../../../components/common/CommonContainer";
import Switch from 'react-bootstrap-switch';
import {
    checkBenchmarkWarning,
    createSliderWithBenchmark,
    disableSecondHandle,
    updateBaseTooltip,
    updateMainHandleColor,
    updateBenchmarkHandleColor,
    updateBenchmarkHandleVisibility, updateHandlesIndex,
    updateLimit,
    updateSliderColor,
    updateTooltips,
    updateValues
} from "../../../lib/noUiSliderCommon";
import Spinner from "../../../components/common/Spinner";
import {CommonSelector} from "../../../components/common/CommonSelector";
import FormInputs from "../../../components/creativeTim/FormInputs";
import {Redirect} from "react-router-dom";
import ModalDialog from "../../../components/common/ModalDialog";
import {getPossibleTimezone, convertSeverMilliSecsToHrsMins, getTimeZoneList} from "../../../lib/common";
import {
    getErrorMessagesFromCode,
    getErrorMsgFromStatus, getGenericServerErrorMsg,
    getInfoMessages, getSpecificOPErrorMessages,
    getSuccessMessages,
    getWarningMessages
} from "../../../lib/messageHandler";
import {Helmet} from "react-helmet";

const localStorage = require('../../../lib/localStorage');

const GenerateKPICardsCheckboxes = ({card, handleCheckboxUpdate, translate}) => {
    return (card.map(cd => (
        <FormGroup key={cd.id} className="checkBoxContainer">
            <CustomCheckbox
                key={cd.id}
                isChecked={cd.selected === true}
                number={"cards:" + cd.id}
                label={translate("kpiCard.name:" + cd.title)}
                inline={true}
                isEnabled={cd.enabled}
                handleCheckboxUpdate={handleCheckboxUpdate}
            />
        </FormGroup>
    )))
};

const GenerateWatchListCheckboxes = ({watchlist, handleCheckboxUpdate, translate}) => {
    return (watchlist.map(wl => (
        <FormGroup key={wl.id} className="checkBoxContainer">
            <CustomCheckbox
                key={wl.id}
                isChecked={wl.selected === true}
                number={"watchlist:" + wl.id}
                label={translate("watchlist:" + wl.workbookName.toLowerCase())}
                inline={true}
                handleCheckboxUpdate={handleCheckboxUpdate}
            />
        </FormGroup>
    )))
};

const compareLanguages = (a, b) => {
    const labelA = a.label;
    const labelB = b.label;
    let comparison = 0;
    if (labelA > labelB) {
        comparison = 1;
    } else if (labelA < labelB) {
        comparison = -1;
    }
    return comparison;
};

const FormErrors = ({formErrors, fieldName}) =>
    <div className='row formErrors' aria-label={formErrors[fieldName]}>
        <p className="form-validation-error"> {formErrors[fieldName]}</p>
    </div>


class MyPreference extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUserAttributesUpdate = this.handleUserAttributesUpdate.bind(this);
        this.handleSliderCurrentChange = this.handleSliderCurrentChange.bind(this);
        this.handleSliderCurrentSlide = this.handleSliderCurrentSlide.bind(this);
        this.handleSliderCurrentStart = this.handleSliderCurrentStart.bind(this);
        this.handleSliderHistoricalChange = this.handleSliderHistoricalChange.bind(this);
        this.handleSliderHistoricalSlide = this.handleSliderHistoricalSlide.bind(this);
        this.handleSliderHistoricalStart = this.handleSliderHistoricalStart.bind(this);
        this.handleCheckboxUpdate = this.handleCheckboxUpdate.bind(this);
        this.handleLanguageUpdate = this.handleLanguageUpdate.bind(this);

        //Language names from resources
        let langNames = [];
        let langResources = this.props.i18n.options.resources;
        if (langResources) {
            Object.entries(langResources).forEach(lang => {
                let langObj = {};
                langObj.value = lang[0];
                langObj.label = lang[1].language.name;
                langNames.push(langObj);
            });
        }

        langNames.sort(compareLanguages);

        //Default language
        const language = API.getSessionLanguage();
        let tempLangOptions = langNames.filter(lang => lang.value === language);
        const defaultLanguage = {};
        defaultLanguage.value = tempLangOptions[0].value;
        defaultLanguage.label = tempLangOptions[0].label;

        //for timezones
        let defaultTimeZoneOption = getTimeZoneList().find(tz => tz.value === API.getUserTimeZone());

        this.mainContent = React.createRef();

        this.state = {
            currentTimeframe: {},
            historicalTimeframe: {},
            currentTimeframeValue: "",
            historicalTimeframeValue: "",
            userAttributes: [],
            OriginalUserAttributes: [],
            applicationOptions: [],
            applicationSelected: [],
            moduleOptions: [],
            moduleSelected: [],
            //screenGroupOptions: [],
            //screenGroupSelected: [],
            watchLists: [],
            cards: [],
            selectedLanguage: defaultLanguage,
            userLanguage: language,
            languageNames: langNames,
            selectedUserTimezone: defaultTimeZoneOption,
            // TimezoneOptions: getTimeZoneList(),
            // severTimezone: "",
            loaded: false,
            sessionPersist: true,
            preferencePersist: false,
            projectPersist: false,
            fetchInProgress: "false",
            appFocused: false,
            moduleFocused: false,
            bpFocused: false,
            annualFTECost: {},
            annualFTEHours: {},
            LTNonError: {},
            LTSysError: {},
            LTUserError: {},
            LTUncatMessage: {},
            projectDuration: {},
            projectUser: {},
            testEffortPerModule: {},
            formErrors: {},
            inputClass_annualFTECost: 'form-control form-control-border-preferedpram',
            inputClass_annualFTEHours: 'form-control  form-control-border-preferedpram',
            inputClass_LTNonError: 'form-control  form-control-border-preferedpram',
            inputClass_LTSysError: 'form-control  form-control-border-preferedpram',
            inputClass_LTUserError: 'form-control  form-control-border-preferedpram',
            inputClass_LTUncatMessage: 'form-control  form-control-border-preferedpram',
            inputClass_projectDuration: 'form-control form-control-border-preferedpram',
            inputClass_projectUser: 'form-control form-control-border-preferedpram',
            inputClass_testEffortPerModule: 'form-control form-control-border-preferedpram',
            showLoggedoutModal: false,
            sessionTerminated: false,
            showResetConfirm: false,
            setMainFocus: false
        };
    }

    handleLanguageUpdate(input) {
        this.setState({selectedLanguage: input});
    }

    handleTimeZoneUpdate = (value) => {
        this.setState({selectedUserTimezone: value});

    }

    handleSliderCurrentStart() {
        const sliderCurrent = document.getElementById('sliderCurrent');
        updateBenchmarkHandleVisibility(sliderCurrent, 'hidden');
    }

    handleSliderHistoricalStart() {
        const sliderHistorical = document.getElementById('sliderHistorical');
        updateBenchmarkHandleVisibility(sliderHistorical, 'hidden');
    }

    handleSliderCurrentSlide() {
        const sliderCurrent = document.getElementById('sliderCurrent');
        const baseValue = sliderCurrent.noUiSlider.get()[0];
        updateSliderColor(sliderCurrent, baseValue, this.state.currentTimeframe.limit, "RELATIVE");
        updateBaseTooltip(sliderCurrent);
    }

    handleSliderHistoricalSlide() {
        const sliderHistorical = document.getElementById('sliderHistorical');
        const baseValue = sliderHistorical.noUiSlider.get()[0];
        updateSliderColor(sliderHistorical, baseValue, this.state.historicalTimeframe.limit, "RELATIVE");
        updateBaseTooltip(sliderHistorical);
    }

    handleSliderCurrentChange() {
        const sliderCurrent = document.getElementById('sliderCurrent');
        const value = parseInt(sliderCurrent.noUiSlider.get()[0]);

        updateMainHandleColor(sliderCurrent, "RELATIVE");
        updateBenchmarkHandleColor(sliderCurrent, value, sliderCurrent.noUiSlider.options.range.max, "RELATIVE");
        checkBenchmarkWarning(sliderCurrent, "Current");
        updateBenchmarkHandleVisibility(sliderCurrent, 'visible');

        this.setState({currentTimeframeValue: value});
    }

    handleSliderHistoricalChange() {
        const sliderHistorical = document.getElementById('sliderHistorical');
        const value = parseInt(sliderHistorical.noUiSlider.get());

        updateMainHandleColor(sliderHistorical, "RELATIVE");
        updateBenchmarkHandleColor(sliderHistorical, value, sliderHistorical.noUiSlider.options.range.max, "RELATIVE");
        checkBenchmarkWarning(sliderHistorical, "Historical");
        updateBenchmarkHandleVisibility(sliderHistorical, 'visible');

        this.setState({historicalTimeframeValue: value});
    }

    handleApplicationsOnChange = (event) => {
        let selectedOptionsLength = event.length;
        let selectedApplications = this.state.applicationSelected;
        if (selectedOptionsLength > selectedApplications.length) {
            let newOptions = {
                id: event[selectedOptionsLength - 1].id,
                value: event[selectedOptionsLength - 1].value,
                label: event[selectedOptionsLength - 1].label,
                selected: true
            };
            selectedApplications.push(newOptions);
        } else {
            selectedApplications = event;
        }

        this.setState({applicationSelected: selectedApplications});
    };

    handleApplicationsInputChange = (event) => {
        if (event === "") return;
        // passing 0 as workbook id to indicates that this request is not report specific
        API.report
            .post('/report/list/application/0?top=100', {
                word: event
            })
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;

                let latestOptions = data.results;

                //add label
                latestOptions.map(item => {
                    item.label = item.value;
                });

                this.setState({applicationOptions: latestOptions});
            })
            .catch(e => {
                console.error(e);
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }
                let resultCode = -1;
                if (e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"
                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    };

    handleLogoutConfirm = () => {
        this.setState({sessionTerminated: true});
    };

    handleModulesOnChange = (event) => {
        let selectedOptionsLength = event.length;
        let selectedModules = this.state.moduleSelected;
        if (selectedOptionsLength > selectedModules.length) {
            let newOptions = {
                id: event[selectedOptionsLength - 1].id,
                value: event[selectedOptionsLength - 1].value,
                label: event[selectedOptionsLength - 1].label,
                selected: true
            };
            selectedModules.push(newOptions);
        } else {
            selectedModules = event;
        }

        this.setState({moduleSelected: selectedModules});
    };

    handleModulesInputChange = (event) => {
        if (event === "") return;
        // passing 0 as workbook id to indicates that this request is not report specifics
        API.report
            .post('/report/list/module/0?top=100', {
                word: event
            })
            .then(resp => {
                const {data: resData} = resp;
                const {data} = resData;

                let latestOptions = data.results;

                //add label
                latestOptions.map(item => {
                    item.label = item.value;
                });

                this.setState({moduleOptions: latestOptions});
            })
            .catch(e => {
                console.error(e);
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }
                let resultCode = -1;
                if (e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"

                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    };

    scrollToSection = (e, id) => {
        e.preventDefault();
        // console.log("Clicked: " + id);
        let items = document.querySelectorAll('.item.active');
        if (items.length) {
            items[0].className = 'item';
        }

        if (document.getElementById(id) !== null) {
            if (id === 'Home-Page') {
                document.getElementById(id).scrollIntoView();
            } else {
                document.getElementById(id).scrollIntoView(true);
                window.scrollBy(0, -80);
            }
            document.getElementById(id + '-link').className = 'item active';
        }
    };

    handleUserAttributesUpdate(selectedOptions, id) {
        // console.log("parent handle was called for attribute ID: " + id);

        let selectedListLength = selectedOptions.length;
        let currentUAObj = this.state.OriginalUserAttributes.filter(ua => ua.id === id);
        let currentUAOptions = currentUAObj[0].options;
        let originalOptions = (currentUAOptions === null || currentUAOptions === undefined || currentUAObj.length === 0) ? [] : currentUAOptions.filter(x => x.selected === true);

        if (selectedListLength > originalOptions.length) {
            let newlySelected = {
                id: selectedOptions[selectedListLength - 1].id,
                value: selectedOptions[selectedListLength - 1].value,
                selected: true
            };
            if (originalOptions.filter(x => x.id === selectedOptions[selectedListLength - 1].id).length > 0) {
                originalOptions.map(op => op.id === selectedOptions[selectedListLength - 1].id ? newlySelected : op)
            } else
                originalOptions.push(newlySelected);
        } else {
            originalOptions = selectedOptions;
        }

        let tempAllUAs = this.state.OriginalUserAttributes;
        tempAllUAs.forEach(ua => {
            if (ua.id === id) ua.options = originalOptions
        });
        this.setState({OriginalUserAttributes: tempAllUAs});
    }


    handleCheckboxUpdate(number, value) {
        let num = number.split(":");
        if (num[0] === "cards") {
            let originalCards = this.state.cards;
            let updatedCards = [];
            originalCards.forEach(card => {
                if (card.id.toString() === num[1]) card.selected = value;
                updatedCards.push(card)
            });
            this.setState({cards: updatedCards});
        } else if (num[0] === "watchlist") {
            let originalWatchLists = this.state.watchLists;
            let updatedWatchLists = [];
            originalWatchLists.forEach(wl => {
                if (wl.id.toString() === num[1]) wl.selected = value;
                updatedWatchLists.push(wl)
            });
            this.setState({watchLists: updatedWatchLists});
        }
    }

    updateLanguage = ({language}) => {
        API.setSessionLanguage({sessionLanguage: language});
        this.setState({userLanguage: language});
        this.props.i18n.changeLanguage(language);
    };

    //set User time zone once the choice is made and saved
    updateTimezone = ( userTimezone ) => {
        API.setUserTimeZone( userTimezone );
    };


    handleSessionPersistChange = (elem, sessionPersist) => {
        this.setState({sessionPersist: sessionPersist});
    };

    handlePreferencePersistChange = (elem, preferencePersist) => {
        this.setState({preferencePersist: preferencePersist});
    };

    handleProjectPersistChange = (elem, projectPersist) => {
        this.setState({projectPersist: projectPersist});
    };

    handleAnnualFTECostInputChange = (e) => {
        let tempAnnualFTECost = this.state.annualFTECost;
        tempAnnualFTECost.value = e.target.value;
        this.setState({annualFTECost: tempAnnualFTECost});
    };

    handleAnnualFTEHoursInputChange = (e) => {
        let tempAnnualFTEHours = this.state.annualFTEHours;
        tempAnnualFTEHours.value = e.target.value;
        this.setState({annualFTEHours: tempAnnualFTEHours});
    };

    handleLTNonErrorInputChange = (e) => {
        let tempLTNonError = this.state.LTNonError;
        tempLTNonError.value = e.target.value;
        this.setState({LTNonError: tempLTNonError});
    };

    handleLTSysErrorInputChange = (e) => {
        let tempLTSysError = this.state.LTSysError;
        tempLTSysError.value = e.target.value;
        this.setState({LTSysError: tempLTSysError});
    };

    handleLTUserErrorInputChange = (e) => {
        let tempLTUserError = this.state.LTUserError;
        tempLTUserError.value = e.target.value;
        this.setState({LTUserError: tempLTUserError});
    };

    handleLTUncatMessageInputChange = (e) => {
        let tempLTUncatMessage = this.state.LTUncatMessage;
        tempLTUncatMessage.value = e.target.value;
        this.setState({LTUncatMessage: tempLTUncatMessage});
    };

    handleProjectDurationInputChange = (e) => {
        let tempProjectDuration = this.state.projectDuration;
        tempProjectDuration.value = e.target.value;
        this.setState({projectDuration: tempProjectDuration});
    };

    handleProjectUserInputChange = (e) => {
        let tempProjectUser = this.state.projectUser;
        tempProjectUser.value = e.target.value;
        this.setState({projectUser: tempProjectUser});
    };


    handleTestEffortPerModuleInputChange = (e) => {
        let tempTestEffortPerModule = this.state.testEffortPerModule;
        tempTestEffortPerModule.value = e.target.value;
        this.setState({testEffortPerModule: tempTestEffortPerModule});
    };

    resetOptions = (event, filterName) => {
        switch (filterName) {
            case "application": {
                this.setState({applicationOptions: []});
                break;
            }
            case "module": {
                this.setState({moduleOptions: []});
                break;
            }
            /*case "screenGroups":   {
                this.setState({screenGroupOptions: []});
                break;
            }*/
            default: {
                // console.warn("no match filter callback method .")
            }
        }
    };

    getNameforStyles = (orignalFieldName) => {
        if (orignalFieldName === undefined) return "undefined";
        let orignalName = orignalFieldName.toString();
        let covertedName = "";

        if (orignalName.includes("Annual FTE Cost"))
            covertedName = "annualFTECost";
        else if (orignalName.includes("Annual FTE hours"))
            covertedName = "annualFTEHours";
        else if (orignalName.includes("Lost time due to non errors"))
            covertedName = "LTNonError";
        else if (orignalName.includes("Lost time due to system errors"))
            covertedName = "LTSysError";
        else if (orignalName.includes("Lost time due to user errors"))
            covertedName = "LTUserError";
        else if (orignalName.includes("Lost time due to uncategorized messages"))
            covertedName = "LTUncatMessage";
        else if (orignalName.includes("Projected Duration"))
            covertedName = "projectDuration";
        else if (orignalName.includes("Projected Users"))
            covertedName = "projectUser";
        else if (orignalName.includes("Testing effort per module"))
            covertedName = "testEffortPerModule";

        return covertedName;
    };

    handleSubmit(event) {
        event.preventDefault();
        const AnnualFTECost = this.state.annualFTECost;
        const AnnualFTEHours = this.state.annualFTEHours;
        const LTNonError = this.state.LTNonError;
        const LTSysError = this.state.LTSysError;
        const LTUserError = this.state.LTUserError;
        const LTUncatMessage = this.state.LTUncatMessage;
        const ProjectDuration = this.state.projectDuration;
        const ProjectUse = this.state.projectUser;
        const TestEffortPerModule = this.state.testEffortPerModule;

        let latestPreferredParams = [];
        let errors = [];
        latestPreferredParams.push(AnnualFTECost);
        latestPreferredParams.push(AnnualFTEHours);
        latestPreferredParams.push(LTNonError);
        latestPreferredParams.push(LTSysError);
        latestPreferredParams.push(LTUserError);
        latestPreferredParams.push(LTUncatMessage);
        latestPreferredParams.push(ProjectDuration);
        latestPreferredParams.push(ProjectUse);
        latestPreferredParams.push(TestEffortPerModule);

        latestPreferredParams.forEach(paramObj => {
            let currentParamValue = paramObj.value;
            let currentErrorObj = {};
            if (isNaN(currentParamValue)) {
                currentErrorObj["field"] = this.getNameforStyles(paramObj.name);
                currentErrorObj["message"] = this.props.t('validation:number');
            } else if ((currentParamValue !== null && currentParamValue.startsWith("-")) || Number(currentParamValue) < 0) {
                currentErrorObj["field"] = this.getNameforStyles(paramObj.name);
                currentErrorObj["message"] = this.props.t('validation:numberLess', {input: "0"});
            } else if (currentParamValue !== null && currentParamValue.length > 1 && currentParamValue.charAt(0) === '0') {
                currentErrorObj["field"] = this.getNameforStyles(paramObj.name);
                currentErrorObj["message"] = this.props.t('validation:numberZero');
            } else if (currentParamValue === "") {
                currentErrorObj["field"] = this.getNameforStyles(paramObj.name);
                currentErrorObj["message"] = this.props.t('validation:emptyValue');
            } else if (Number(currentParamValue) > 0) {
                let fieldName = this.getNameforStyles(paramObj.name);
                if (fieldName === 'annualFTECost' && Number(currentParamValue) > 1000000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "1000000"});
                } else if (fieldName === 'annualFTEHours' && Number(currentParamValue) > 10000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "10000"});
                } else if (fieldName === 'LTNonError' && Number(currentParamValue) > 100000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100000"});
                } else if (fieldName === 'LTSysError' && Number(currentParamValue) > 100000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100000"});
                } else if (fieldName === 'LTUserError' && Number(currentParamValue) > 100000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100000"});
                } else if (fieldName === 'LTUncatMessage' && Number(currentParamValue) > 100000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100000"});
                } else if (fieldName === 'projectDuration' && Number(currentParamValue) > 100) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "100"});
                } else if (fieldName === 'projectUser' && Number(currentParamValue) > 1000000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "1000000"});
                } else if (fieldName === 'testEffortPerModule' && Number(currentParamValue) > 1000) {
                    currentErrorObj["field"] = fieldName;
                    currentErrorObj["message"] = this.props.t('validation:numberMore', {input: "1000"});
                }
            }


            if (!(Object.keys(currentErrorObj).length === 0 && currentErrorObj.constructor === Object)) {
                errors.push(currentErrorObj);
            }
        });

        if (errors.length === 0) {
            this.submitData();
            this.setState({
                formErrors: '',
                inputClass_annualFTECost: 'form-control form-control-border-preferedpram',
                inputClass_annualFTEHours: 'form-control  form-control-border-preferedpram',
                inputClass_LTNonError: 'form-control  form-control-border-preferedpram',
                inputClass_LTSysError: 'form-control  form-control-border-preferedpram',
                inputClass_LTUserError: 'form-control  form-control-border-preferedpram',
                inputClass_LTUncatMessage: 'form-control  form-control-border-preferedpram',
                inputClass_projectDuration: 'form-control form-control-border-preferedpram',
                inputClass_projectUser: 'form-control form-control-border-preferedpram',
                inputClass_testEffortPerModule: 'form-control form-control-border-preferedpram',
            });

        } else {
            const formattedErrors = {};
            const errorFields = [];
            const allFields = ['inputClass_annualFTECost', 'inputClass_annualFTEHours', 'inputClass_LTNonError', 'inputClass_LTSysError', 'inputClass_LTUserError', 'inputClass_LTUncatMessage', 'inputClass_projectDuration', 'inputClass_projectUser', 'inputClass_testEffortPerModule'];
            errors.forEach(error => {
                formattedErrors[error.field] = error.message;
                errorFields.push('inputClass_' + error.field);
                if (error.field === 'annualFTECost') {
                    this.setState({inputClass_annualFTECost: 'form-control form-validation-input-error'});
                } else if (error.field === 'annualFTEHours') {
                    this.setState({inputClass_annualFTEHours: 'form-control form-validation-input-error'});
                } else if (error.field === 'LTNonError') {
                    this.setState({inputClass_LTNonError: 'form-control form-validation-input-error'});
                } else if (error.field === 'LTSysError') {
                    this.setState({inputClass_LTSysError: 'form-control form-validation-input-error'});
                } else if (error.field === 'LTUserError') {
                    this.setState({inputClass_LTUserError: 'form-control form-validation-input-error'});
                } else if (error.field === 'LTUncatMessage') {
                    this.setState({inputClass_LTUncatMessage: 'form-control form-validation-input-error'});
                } else if (error.field === 'projectDuration') {
                    this.setState({inputClass_projectDuration: 'form-control form-validation-input-error'});
                } else if (error.field === 'projectUser') {
                    this.setState({inputClass_projectUser: 'form-control form-validation-input-error'});
                } else if (error.field === 'testEffortPerModule') {
                    this.setState({inputClass_testEffortPerModule: 'form-control form-validation-input-error'});
                }

            });
            allFields.filter((field) => {
                if (!errorFields.includes(field)) {
                    this.setState({[field]: 'form-control'});
                }
            });
            this.setState({formErrors: formattedErrors});
            this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t, 'validationErrorMessage', null, null));
        }
    }

    submitData() {
        let _this = this;
        _this.setState({fetchInProgress: "true"});
        let preferenceParam = {};
        preferenceParam.applicationValueFilter = this.state.applicationSelected.filter(x => x.selected === true).map(y => y.value);
        preferenceParam.moduleValueFilters = this.state.moduleSelected.filter(x => x.selected === true).map(y => y.value);
        //preferenceParam.screenGroupFilter = this.state.screenGroupSelected.filter(x => x.selected === true).map(y => y.id);
        preferenceParam.cards = this.state.cards.filter(x => x.selected === true).map(y => y.id);
        preferenceParam.currentTimeFilter = {};
        preferenceParam.currentTimeFilter.timeFilterValue = this.state.currentTimeframeValue.toString();
        preferenceParam.historicalTimeFilter = {};
        preferenceParam.historicalTimeFilter.timeFilterValue = this.state.historicalTimeframeValue.toString();
        let uaParam = [];
        let originaUAFilterValue = this.state.OriginalUserAttributes;
        originaUAFilterValue.forEach(att => {
            att.options.filter(v => v.selected === true).map(v => v.id).forEach(vv => uaParam.push(vv))
        });
        preferenceParam.userAttributes = uaParam;
        preferenceParam.watchLists = this.state.watchLists.filter(x => x.selected === true).map(y => y.id);
        preferenceParam.language = this.state.selectedLanguage.value;
        preferenceParam.sessionPersist = this.state.sessionPersist;
        preferenceParam.preferencePersist = this.state.preferencePersist;
        preferenceParam.projectPersist = this.state.projectPersist;
        preferenceParam.timezone = this.state.selectedUserTimezone.value;
        let tempTableauParameters = [];
        let latestannualFTECost = {};
        latestannualFTECost.name = this.state.annualFTECost.name;
        latestannualFTECost.value = this.state.annualFTECost.value;
        tempTableauParameters.push(latestannualFTECost);

        let latestannualFTEHours = {};
        latestannualFTEHours.name = this.state.annualFTEHours.name;
        latestannualFTEHours.value = this.state.annualFTEHours.value;
        tempTableauParameters.push(latestannualFTEHours);

        let latestLTNonError = {};
        latestLTNonError.name = this.state.LTNonError.name;
        latestLTNonError.value = this.state.LTNonError.value;
        tempTableauParameters.push(latestLTNonError);

        let latestLTSysError = {};
        latestLTSysError.name = this.state.LTSysError.name;
        latestLTSysError.value = this.state.LTSysError.value;
        tempTableauParameters.push(latestLTSysError);

        let latestLTUserError = {};
        latestLTUserError.name = this.state.LTUserError.name;
        latestLTUserError.value = this.state.LTUserError.value;
        tempTableauParameters.push(latestLTUserError);

        let latestLTUncatMessage = {};
        latestLTUncatMessage.name = this.state.LTUncatMessage.name;
        latestLTUncatMessage.value = this.state.LTUncatMessage.value;
        tempTableauParameters.push(latestLTUncatMessage);

        let latestprojectDuration = {};
        latestprojectDuration.name = this.state.projectDuration.name;
        latestprojectDuration.value = this.state.projectDuration.value;
        tempTableauParameters.push(latestprojectDuration);

        let latestprojectUser = {};
        latestprojectUser.name = this.state.projectUser.name;
        latestprojectUser.value = this.state.projectUser.value;
        tempTableauParameters.push(latestprojectUser);

        let latesttestEffortPerModule = {};
        latesttestEffortPerModule.name = this.state.testEffortPerModule.name;
        latesttestEffortPerModule.value = this.state.testEffortPerModule.value;
        tempTableauParameters.push(latesttestEffortPerModule);

        preferenceParam.tableauParameters = tempTableauParameters;
        API.user
            .post('/user/preference', preferenceParam)
            .then(resp => {
                const {resultCode} = resp.data;
                if (resultCode === 200) {
                    let inSupport = resp.data ? resp.data.inSupport : false;
                    this.updateTimezone( _this.state.selectedUserTimezone.value );
                    //here to update the timezone
                    this.updateLanguage({language: _this.state.selectedLanguage.value});
                    //this.props.handleAlertClick("success", "tc", this.props.t('messages.success:changeSaved', {item: 'User preference'}), inSupport);

                    this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t, 'changeSaved', 'User preference'), inSupport);

                    //Reset filters for interactive reports
                    window.sessionStorage.removeItem('reportInteractive');
                } else {
                    this.props.handleAlertClick("error", "tc", getSpecificOPErrorMessages(this.props.t, 'changeNotSaved', 'User preference', resultCode));
                }

                _this.setState({fetchInProgress: localStorage.get("isLoading")});
            })
            .catch(e => {
                console.error(e);
                _this.setState({fetchInProgress: localStorage.get("isLoading")});
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }
                if (e.message && e.message.includes("Cannot read property 'data' of undefined")) {
                    this.props.handleAlertClick("error", "tc", getGenericServerErrorMsg(this.props.t));
                } else {
                    let resultCode = -1;
                    if (e.data) {
                        resultCode = e.data.resultCode;
                    }
                    if (resultCode > -1)
                        this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                    else
                        this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
                }

            });
    }

    componentDidUpdate() {
        if (this.state.loaded) {
            //Sliders ->
            const sliderCurrent = document.getElementById('sliderCurrent');
            const sliderHistorical = document.getElementById('sliderHistorical');

            updateLimit(sliderCurrent, this.state.currentTimeframe.limit);
            updateLimit(sliderHistorical, this.state.historicalTimeframe.limit);

            updateValues(sliderCurrent, this.state.currentTimeframeValue);
            updateValues(sliderHistorical, this.state.historicalTimeframeValue);

            updateHandlesIndex(sliderCurrent);
            updateHandlesIndex(sliderHistorical);

            let basePeriodText = this.props.t('report.filter:basePeriod');
            let benchmarkPeriodText = this.props.t('report.filter:benchmarkPeriod');
            updateTooltips(sliderCurrent, basePeriodText, benchmarkPeriodText);
            updateTooltips(sliderHistorical, basePeriodText, benchmarkPeriodText);

            updateMainHandleColor(sliderCurrent, "RELATIVE");
            updateMainHandleColor(sliderHistorical, "RELATIVE");

            updateBenchmarkHandleColor(sliderCurrent, this.state.currentTimeframeValue, this.state.currentTimeframe.limit, "RELATIVE");
            updateBenchmarkHandleColor(sliderHistorical, this.state.historicalTimeframeValue, this.state.historicalTimeframe.limit, "RELATIVE");

            checkBenchmarkWarning(sliderCurrent, "Current");
            checkBenchmarkWarning(sliderHistorical, "Historical");

            updateSliderColor(sliderCurrent, this.state.currentTimeframeValue, this.state.currentTimeframe.limit, "RELATIVE");
            updateSliderColor(sliderHistorical, this.state.historicalTimeframeValue, this.state.historicalTimeframe.limit, "RELATIVE");
            //<- Sliders
        }
        if(this.state.setMainFocus) {
            if (this.mainContent && this.mainContent.current) {
                this.mainContent.current.focus();
                this.setState({setMainFocus: false})
            }
        }
    }

    handleResetClick = (e) => {
        e.preventDefault();
        this.setState({
            showResetConfirm: true
        });
    };

    handleResetClose = () => {
        this.setState({
            showResetConfirm: false,
        });
    };

    handleResetConfirm = () => {
        this.setState({fetchInProgress: "true"});
        let defaultLanguage = this.state.selectedLanguage;
        //this.state.languageNames
        API.user
            .get("/user/reset/preference")
            .then(resp => {
                const {data} = resp.data;
                //console.log("the result is: "+ Object.values(resp.data).map((val, k) => <h4 k={k}>{val}</h4>));
                let defaultLangOptions = this.state.languageNames.filter(lang => lang.value === data.selectedLanguage);
                defaultLanguage.value = defaultLangOptions[0].value;
                defaultLanguage.label = defaultLangOptions[0].label;

                let serverTimezone  =  API.getServerTimeZone();
                let autoDetectedTZ = serverTimezone == null || serverTimezone === "" ? getPossibleTimezone().value : API.getServerTimeZone();

                this.updateTimezone( autoDetectedTZ );
                this.updateLanguage({language: this.state.selectedLanguage.value});
                let inSupport = resp.data ? resp.data.inSupport : false;
                if (inSupport)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, 1005, true));
                else
                    this.props.handleAlertClick("success", "tc", getSuccessMessages(this.props.t, 'valuesResetToDefault', null), inSupport);

                //Reset filters for interactive reports
                window.sessionStorage.removeItem('reportInteractive');

                this.setState({
                    currentTimeframe: data.filters.timeRanges.filter(item => item.type === "CURRENT")[0],
                    historicalTimeframe: data.filters.timeRanges.filter(item => item.type === "HISTORICAL")[0],
                    currentTimeframeValue: data.filters.timeRanges.filter(item => item.type === "CURRENT")[0].value,
                    historicalTimeframeValue: data.filters.timeRanges.filter(item => item.type === "HISTORICAL")[0].value,
                    OriginalUserAttributes: data.filters.userAttributes,
                    applicationOptions: data.filters.applicationFilters,
                    applicationSelected: data.filters.applicationFilters,
                    ModuleOptions: data.filters.moduleFilters,
                    moduleSelected: data.filters.moduleFilters,
                    cards: data.cards,
                    watchLists: data.watchlists,
                    userLanguage: data.selectedLanguage,
                    selectedLanguage: defaultLanguage,
                    selectedUserTimezone: getTimeZoneList().find( tz => tz.value===autoDetectedTZ),
                    // severTimezone: JSON.parse(window.sessionStorage.getItem("completeTZList")).filter(tz => tz.value === convertSeverMilliSecsToHrsMins(data.serverTimezone['rawOffset'], data.serverTimezone['dayLightSaving'])).map(tzObj => tzObj.label),
                    loaded: true,
                    sessionPersist: data.sessionPersist,
                    preferencePersist: data.preferencePersist,
                    projectPersist: data.projectPersist,
                    annualFTECost: data.tableauParams.filter(param => param.name === "Annual FTE Cost (currency)")[0],
                    annualFTEHours: data.tableauParams.filter(param => param.name === "Annual FTE hours")[0],
                    LTNonError: data.tableauParams.filter(param => param.name === "Lost time due to non errors (seconds)")[0],
                    LTSysError: data.tableauParams.filter(param => param.name === "Lost time due to system errors (seconds)")[0],
                    LTUserError: data.tableauParams.filter(param => param.name === "Lost time due to user errors (seconds)")[0],
                    LTUncatMessage: data.tableauParams.filter(param => param.name === "Lost Time Due to Uncategorized Messages (seconds)")[0],
                    projectDuration: data.tableauParams.filter(param => param.name === "Projected Duration (years)")[0],
                    projectUser: data.tableauParams.filter(param => param.name === "Projected Users")[0],
                    testEffortPerModule: data.tableauParams.filter(param => param.name === "Testing effort per module (hours)")[0],
                    formErrors: '',
                    showResetConfirm: false,
                    fetchInProgress: localStorage.get("isLoading"),
                    setMainFocus: true
                });
            })
            .catch(e => {
                console.error(e);
                this.setState({
                    fetchInProgress: "false",
                    showResetConfirm: false
                });
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }
                let resultCode = -1;
                if (e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"
                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    };

    componentDidMount() {
        //Sliders ->
        const sliderCurrent = document.getElementById('sliderCurrent');
        createSliderWithBenchmark(sliderCurrent);
        disableSecondHandle(sliderCurrent);
        sliderCurrent.noUiSlider.on('start', this.handleSliderCurrentStart);
        sliderCurrent.noUiSlider.on('slide', this.handleSliderCurrentSlide);
        sliderCurrent.noUiSlider.on('change', this.handleSliderCurrentChange);

        const sliderHistorical = document.getElementById('sliderHistorical');
        createSliderWithBenchmark(sliderHistorical);
        disableSecondHandle(sliderHistorical);
        sliderHistorical.noUiSlider.on('start', this.handleSliderHistoricalStart);
        sliderHistorical.noUiSlider.on('slide', this.handleSliderHistoricalSlide);
        sliderHistorical.noUiSlider.on('change', this.handleSliderHistoricalChange);
        //<- Sliders

        API.user
            .get("/user/v2/view/preference")
            .then(resp => {
                const {data} = resp.data;

                //add label to filter options
                Object.entries(data.filters).forEach(filter => {
                    if (filter[0] === 'applicationFilters' || filter[0] === 'moduleFilters') { //|| filter[0]==='screenGroupFilters'
                        if (filter[1] && filter[1].length > 0) {
                            filter[1].map(option => {
                                option.label = option.value;
                            });
                        }
                    }
                });

                this.setState({
                    currentTimeframe: data.filters.timeRanges.filter(item => item.type === "CURRENT")[0],
                    historicalTimeframe: data.filters.timeRanges.filter(item => item.type === "HISTORICAL")[0],
                    currentTimeframeValue: data.filters.timeRanges.filter(item => item.type === "CURRENT")[0].value,
                    historicalTimeframeValue: data.filters.timeRanges.filter(item => item.type === "HISTORICAL")[0].value,
                    OriginalUserAttributes: data.filters.userAttributes,
                    applicationOptions: data.filters.applicationFilters,
                    applicationSelected: data.filters.applicationFilters,
                    ModuleOptions: data.filters.moduleFilters,
                    moduleSelected: data.filters.moduleFilters,
                    //screenGroupOptions: data.filters.screenGroupFilters,
                    //screenGroupSelected: data.filters.screenGroupFilters,
                    cards: data.cards,
                    watchLists: data.watchlists,
                    userLanguage: data.selectedLanguage,
                    // severTimezone: JSON.parse(window.sessionStorage.getItem("completeTZList")).filter(tz => tz.value === convertSeverMilliSecsToHrsMins(data.serverTimezone['rawOffset'], data.serverTimezone['dayLightSaving'])).map(tzObj => tzObj.label),
                    loaded: true,
                    sessionPersist: data.sessionPersist,
                    preferencePersist: data.preferencePersist,
                    projectPersist: data.projectPersist,
                    annualFTECost: data.tableauParams.filter(param => param.name === "Annual FTE Cost (currency)")[0],
                    annualFTEHours: data.tableauParams.filter(param => param.name === "Annual FTE hours")[0],
                    LTNonError: data.tableauParams.filter(param => param.name === "Lost time due to non errors (seconds)")[0],
                    LTSysError: data.tableauParams.filter(param => param.name === "Lost time due to system errors (seconds)")[0],
                    LTUserError: data.tableauParams.filter(param => param.name === "Lost time due to user errors (seconds)")[0],
                    LTUncatMessage: data.tableauParams.filter(param => param.name === "Lost Time Due to Uncategorized Messages (seconds)")[0],
                    projectDuration: data.tableauParams.filter(param => param.name === "Projected Duration (years)")[0],
                    projectUser: data.tableauParams.filter(param => param.name === "Projected Users")[0],
                    testEffortPerModule: data.tableauParams.filter(param => param.name === "Testing effort per module (hours)")[0],
                    setMainFocus: true
                });
            })
            .catch(e => {
                console.error(e);
                if (localStorage.get("accessDenied401") === "true") {
                    this.setState({showLoggedoutModal: true});
                    return;
                }
                let resultCode = -1;
                if (e.data) {
                    resultCode = e.data.resultCode;
                }
                if (e.status === 401 || resultCode === 6007)
                    window.location.href = "/#/auth/logout"
                if (resultCode > -1)
                    this.props.handleAlertClick("error", "tc", getErrorMessagesFromCode(this.props.t, resultCode, true));
                else
                    this.props.handleAlertClick("error", "tc", getErrorMsgFromStatus(this.props.t, e, true));
            });
    }


    render() {
        const {t} = this.props;

        if (this.state.sessionTerminated)
            return <Redirect to={{pathname: '/auth/logout'}}/>;

        let showLoggedOutWindow = this.state.showLoggedoutModal;


        let selectedApps = this.state.applicationSelected.filter(x => x.selected === true);
        let selectedModules = this.state.moduleSelected.filter(x => x.selected === true);
        //let selectedScrGroups = this.state.screenGroupSelected.filter(x => x.selected === true);

        let appFilterOptions = this.state.applicationOptions === undefined ? [] : this.state.applicationOptions;
        let modFilterOptions = this.state.moduleOptions === undefined ? [] : this.state.moduleOptions;
        //let screenGroupFilterOptions = this.state.screenGroupOptions === undefined ? [] : this.state.screenGroupOptions;

        let sliderStringCurrent = "";
        let sliderStringCurrent2 = "";
        let currentTimeframeValue = parseInt(this.state.currentTimeframeValue);
        if (currentTimeframeValue > 0) {
            let valueRelative = this.state.currentTimeframeValue;
            let unitRelative = currentTimeframeValue > 1 ? t('common:days') : t('common:day');
            let limitRelative = this.state.currentTimeframe.limit;
            sliderStringCurrent = t('preferences:sliderString', {value: valueRelative, unit: unitRelative, upTo: limitRelative})
            sliderStringCurrent2 = t('preferences:sliderString2', {value: valueRelative, unit: unitRelative})
        }

        let sliderStringHistorical = "";
        let sliderStringHistorical2 = "";
        let historicalTimeframeValue = parseInt(this.state.historicalTimeframeValue);
        if (historicalTimeframeValue > 0) {
            let valueHistorical = this.state.historicalTimeframeValue;
            let unitHistorical = historicalTimeframeValue > 1 ? t('common:months') : t('common:month');
            let limitHistorical = this.state.historicalTimeframe.limit;
            sliderStringHistorical = t('preferences:sliderString', {value: valueHistorical, unit: unitHistorical, upTo: limitHistorical})
            sliderStringHistorical2 = t('preferences:sliderString2', {value: valueHistorical, unit: unitHistorical})
        }

        const footerObj = [];
        const updatePreferencesObj = {
            name: t('common:save'),
            callback: this.handleSubmit
        };
        footerObj.push(updatePreferencesObj);
        const resetPreferencesObj = {
            name: t('common:reset'),  //to be added for localization
            callback: this.handleResetClick
        };
        footerObj.push(resetPreferencesObj);

        return (
            <form noValidate>
                <div className="main-content main-content-page-with-footer" ref={this.mainContent} tabIndex={"-1"}>
                    <Helmet>
                        <title>Knoa Analytics - My Preferences</title>
                    </Helmet>
                    <ModalDialog
                        showModal={showLoggedOutWindow}
                        title={"Log Out"}
                        contentBody={
                            <span>{getInfoMessages(this.props.t, 'logoutfor401', null, null)}</span>
                        }
                        bodyPaddingBottom='15px'
                        footerBtnBsStyle="success"
                        footerBtnClass="KnoaSuccessButton"
                        footerBtnOnClick={this.handleLogoutConfirm}
                        footerBtnText={this.props.t('common:ok')}
                    />
                    <ModalDialog
                        showModal={this.state.showResetConfirm}
                        title={this.props.t('dialog:resetToDefaultSettings')}
                        contentBody={<span>{this.props.t('dialog:resetToDefaultSettingsConfirm')}</span>}
                        bodyPaddingBottom='15px'
                        footerBtnBsStyle="danger"
                        footerBtnClass="KnoaDangerButton"
                        footerBtnOnClick={this.handleResetConfirm}
                        footerBtnText={this.props.t('common:yes')}
                        footerBtnCloseText={this.props.t('common:no')}
                        handleClose={this.handleResetClose}
                    />
                    <Row>
                        <Col lg={2} className="section-navigation-myPreference">
                            <Row className="page-link-myPreference">
                                <a id="Preferred-Language-link" onClick={(e) => this.scrollToSection(e, "Preferred-Language")} className="item"
                                   to="#">{t('preferences:myPrefLangTitle')}</a>
                            </Row>
                            <Row className="page-link-myPreference">
                                <a id="Preferred-Time-Zone-link" onClick={(e) => this.scrollToSection(e, "Preferred-Time-Zone")}
                                   className="item" to="#">{t('preferences:myPreTimeZoneTitle')}</a>
                            </Row>
                            <Row className="page-link-myPreference">
                                <a id="Preferred-Filters-link" onClick={(e) => this.scrollToSection(e, "Preferred-Filters")} className="item"
                                   to="#">{t('preferences:myPrefFilterTitle')}</a>
                            </Row>
                            <Row className="page-link-myPreference">
                                <a id="Preferred-Parameters-link" onClick={(e) => this.scrollToSection(e, "Preferred-Parameters")}
                                   className="item" to="#">{t('preferences:preferredParameters')}</a>
                            </Row>
                            <Row className="page-link-myPreference">
                                <a id="Report-Settings-link" onClick={(e) => this.scrollToSection(e, "Report-Settings")} className="item"
                                   to="#">{t('preferences:reportSettingsTitle')}</a>
                            </Row>
                            <Row className="page-link-myPreference">
                                <a id="Home-Page-link" onClick={(e) => this.scrollToSection(e, "Home-Page")} className="item"
                                   to="#">{t('preferences:myPrefHomePageTitle')}</a>
                            </Row>
                        </Col>
                        <Col lg={7} className="main-content-myPreference">
                            {this.state.fetchInProgress === "true" && <Spinner/>}
                            <div hidden={this.state.fetchInProgress === "true"}>
                                <CommonContainer
                                    class="common-page-content-row "
                                    content={
                                        <Col>
                                            <div role="group" aria-labelledby="Preferred-Language" className="knoa-region" tabIndex={"-1"}>
                                                <Card
                                                    title={t('preferences:myPrefLangTitle')}
                                                    category={t('preferences:myPrefLangSubTitle')}
                                                    id={'Preferred-Language'}
                                                    content={
                                                        <div className="KnoaFilterContainer">
                                                            <FormGroup
                                                                controlId="formMyPreferenceLanguage"
                                                            >
                                                                <Row className="card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        {t('preferences:language')}
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <CommonSelector
                                                                            inputId="formMyPreferenceLanguage"
                                                                            style={"dropdown"}
                                                                            componentLocation={"normal"}
                                                                            name={"language"}
                                                                            value={this.state.selectedLanguage}
                                                                            defaultValue={this.state.languageNames[0]}
                                                                            isClearable={false}
                                                                            isSearchable={false}
                                                                            options={this.state.languageNames}
                                                                            onChange={this.handleLanguageUpdate}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                            <div role="group" aria-labelledby="Preferred-Time-Zone" className="knoa-region" tabIndex={"-1"}>
                                                <Card
                                                    title={t('preferences:myPreTimeZoneTitle')}
                                                    category={t('preferences:myPrefTimeZoneSubTitle')}
                                                    id={'Preferred-Time-Zone'}
                                                    content={
                                                        <div className="KnoaFilterContainer">
                                                            <FormGroup
                                                                controlId="preferenceTimeZone"
                                                            >
                                                                <Row  className="card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        {t('preferences:userTimeZone')}
                                                                        <LinkWithTooltip tooltip={t('preferences:userTimeZoneTooltip')}
                                                                                         href=""
                                                                                         id=""
                                                                                         tooltipIconClass="fa fa-info-circle"
                                                                                         buttonStyle={{paddingLeft: 2 + 'px'}}
                                                                                         iconStyle={{fontSize: 16 + 'px'}}/>
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <CommonSelector
                                                                            inputId="preferenceTimeZone"
                                                                            style={"dropdown"}
                                                                            componentLocation={"normal"}
                                                                            name={"language"}
                                                                            value={this.state.selectedUserTimezone}
                                                                            defaultValue={this.state.selectedUserTimezone}
                                                                            isClearable={false}
                                                                            isSearchable={false}
                                                                            options= {getTimeZoneList()}
                                                                            onChange={this.handleTimeZoneUpdate}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row className="card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        {t('preferences:serverTimeZone')}
                                                                        <LinkWithTooltip tooltip={t('preferences:serverTimeZoneTooltip')}
                                                                                         href=""
                                                                                         id=""
                                                                                         tooltipIconClass="fa fa-info-circle"
                                                                                         buttonStyle={{paddingLeft: 2 + 'px'}}
                                                                                         iconStyle={{fontSize: 16 + 'px'}}/>
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right-serverTZ">
                                                                        { getTimeZoneList().find(tz => tz.value === API.getServerTimeZone()).label }
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                            <div role="group" aria-labelledby="Preferred-Filters" className="knoa-region" tabIndex={"-1"}>
                                                <Card
                                                    title={t('preferences:myPrefFilterTitle')}
                                                    id={'Preferred-Filters'}
                                                    category={t('preferences:myPrefFilterSubTitle')}
                                                    content={
                                                        <div className="KnoaFilterContainer">
                                                            <FormGroup
                                                                role="group" aria-labelledby="timeFilters"
                                                                controlId="formMyPreferenceTimeFilters"
                                                            >
                                                                <Row className="card-section-title">
                                                                    <h5 id="timeFilters">{t('preferences:timeFilters')}</h5>
                                                                </Row>
                                                                <Row className="card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        {t('preferences:currentTimeframe')}
                                                                        <LinkWithTooltip tooltip={t('preferences:currentTimeframeTooltip')}
                                                                                         href=""
                                                                                         id=""
                                                                                         tooltipIconClass="fa fa-info-circle"
                                                                                         buttonStyle={{paddingLeft: 2 + 'px'}}
                                                                                         iconStyle={{fontSize: 16 + 'px'}}/>
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <div id="sliderCurrent" className="slider-primary"></div>
                                                                        <div className="sliderLabel">
                                                                            <span>{sliderStringCurrent}</span>
                                                                        </div>
                                                                        <div className="sliderLabel">
                                                                            <span>{sliderStringCurrent2}</span>
                                                                        </div>
                                                                        <div id="sliderWarningCurrent"
                                                                             className="user-preference-benchMark-current-slider">
                                                                    <span
                                                                        className="benchmarkWarning">{getWarningMessages(t, 'benchmarkSlider', null)}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        {t('preferences:historicalTimeframe')}
                                                                        <LinkWithTooltip
                                                                            tooltip={t('preferences:historicalTimeframeTooltip')}
                                                                            href=""
                                                                            id=""
                                                                            tooltipIconClass="fa fa-info-circle"
                                                                            buttonStyle={{paddingLeft: 2 + 'px'}}
                                                                            iconStyle={{fontSize: 16 + 'px'}}/>
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <div id="sliderHistorical" className="slider-primary"></div>
                                                                        <div className="sliderLabel">
                                                                            <span>{sliderStringHistorical}</span>
                                                                        </div>
                                                                        <div className="sliderLabel">
                                                                            <span>{sliderStringHistorical2}</span>
                                                                        </div>
                                                                        <div id="sliderWarningHistorical"
                                                                             className="user-preference-benchMark-historical-slider">
                                                                    <span
                                                                        className="benchmarkWarning">{getWarningMessages(t, 'benchmarkSlider', null)}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                                <FormGroup
                                                                    role="group" aria-labelledby="appFilters"
                                                                    controlId="formMyPreferenceApplicationFilters"
                                                                >
                                                                   <Row className="card-section-title">
                                                                        <h5 id="appFilters">{t('preferences:applicationFilters')}</h5>
                                                                    </Row>
                                                                    <Row className="card-row-flex">
                                                                        <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                             sm={1}>
                                                                            <label htmlFor="Applications"
                                                                                   className="col-control-sr-label"> {t('preferences:applications')}</label>
                                                                        </Col>
                                                                        <Col sm={1} className="card-row-col-right">
                                                                            <CommonSelector
                                                                                inputId="Applications"
                                                                                isCreatable={true}
                                                                                style={"typetosearch"}
                                                                                componentLocation={"normal"}
                                                                                placeholder={this.state.appFocused ? "" : t('common:typeToSearch')}
                                                                                value={selectedApps}
                                                                                options={appFilterOptions}
                                                                                getOptionLabel={(option) => option.value}
                                                                                getOptionValue={(option) => option.value}
                                                                                onChange={this.handleApplicationsOnChange}
                                                                                onInputChange={this.handleApplicationsInputChange}
                                                                                onMenuClose={(e) => this.resetOptions(e, "application")}
                                                                                onFocus={(e) => {
                                                                                    this.setState({appFocused: true});
                                                                                }}
                                                                                onBlur={(e) => {
                                                                                    this.setState({appFocused: false});
                                                                                }}
                                                                                isClearable
                                                                                isSearchable={true}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="card-row-flex">
                                                                        <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                             sm={1}>
                                                                            <label htmlFor="Modules"
                                                                                   className="col-control-sr-label">{t('preferences:modules')}</label>
                                                                        </Col>
                                                                        <Col sm={1} className="card-row-col-right">
                                                                            <CommonSelector
                                                                                inputId="Modules"
                                                                                isCreatable={true}
                                                                                style={"typetosearch"}
                                                                                componentLocation={"normal"}
                                                                                placeholder={this.state.moduleFocused ? "" : t('common:typeToSearch')}
                                                                                value={selectedModules}
                                                                                options={modFilterOptions}
                                                                                getOptionLabel={(option) => option.value}
                                                                                getOptionValue={(option) => option.value}
                                                                                onChange={this.handleModulesOnChange}
                                                                                onInputChange={this.handleModulesInputChange}
                                                                                onMenuClose={(e) => this.resetOptions(e, "module")}
                                                                                onFocus={(e) => {
                                                                                    this.setState({moduleFocused: true});
                                                                                }}
                                                                                onBlur={(e) => {
                                                                                    this.setState({moduleFocused: false});
                                                                                }}
                                                                                isClearable
                                                                                isSearchable={true}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    {/*<Row className="card-row-flex-business-process">
                                                            <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                 sm={1}>
                                                                {t('preferences:businessProcess')}
                                                            </Col>
                                                            <Col sm={1} className="card-row-col-right">
                                                                <CommonSelector
                                                                    style={"typetosearch"}
                                                                    componentLocation={"normal"}
                                                                    placeholder={this.state.bpFocused ? "" : t('common:typeToSearch')}
                                                                    value={selectedScrGroups}
                                                                    options={screenGroupFilterOptions}
                                                                    getOptionLabel={(option) => option.value}
                                                                    getOptionValue={(option) => option.value}
                                                                    onChange={this.handleScreenGroupsOnChange}
                                                                    onInputChange={this.handleScreenGroupsInputChange}
                                                                    onMenuClose={(e) => this.resetOptions(e, "screenGroups")}
                                                                    onFocus={(e) => {this.setState({bpFocused: true});}}
                                                                    onBlur={(e) => {this.setState({bpFocused: false});}}
                                                                    isClearable
                                                                    isSearchable={true}
                                                                />
                                                            </Col>
                                                        </Row>*/}
                                                                    <Row className="card-row-flex">
                                                                        <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                             sm={1}>
                                                                        </Col>
                                                                    </Row>
                                                                </FormGroup>
                                                                <FormGroup
                                                                    role="group" aria-labelledby="uaFilters"
                                                                    controlId="formMyPreferenceUserAttributes"
                                                                >
                                                                    <Row className="card-section-title">
                                                                        <h5 id="uaFilters">{t('preferences:userAttributes')}</h5>
                                                                    </Row>
                                                                    <UAFilterContainer
                                                                        userAttributesOptions={this.state.OriginalUserAttributes}
                                                                        handleUserAttributesUpdate={this.handleUserAttributesUpdate}
                                                                        translate={t}/>
                                                                </FormGroup>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                            <div role="group" aria-labelledby="Preferred-Parameters" className="knoa-region" tabIndex={"-1"}>
                                                <Card
                                                    id={'Preferred-Parameters'}
                                                    title={t('preferences:preferredParameters')}
                                                    category={t('preferences:preferredParametersSubTitle')}
                                                    content={
                                                        <div className="KnoaFilterContainer">
                                                            <FormGroup
                                                                controlId="formPreferredParameterSetting"
                                                            >
                                                                <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        <label htmlFor= "annualFTECost" className="col-control-sr-label">{t('preferences:annualFTECost')} <span className="text-danger-1">*</span></label>
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <FormInputs
                                                                            ncols={["col-md-12 preference-preferred-pram"]}
                                                                            proprieties={[
                                                                                {
                                                                                    type: "text",
                                                                                    name: "annualFTECost",
                                                                                    bsClass: this.state.inputClass_annualFTECost,
                                                                                    placeholder: "",
                                                                                    value: this.state.annualFTECost.value,
                                                                                    onChange: this.handleAnnualFTECostInputChange,
                                                                                    id: "annualFTECost",
                                                                                    required: true,
                                                                                }
                                                                            ]}
                                                                        />
                                                                        <div className="col-md-12">
                                                                            <FormErrors formErrors={this.state.formErrors}
                                                                                        fieldName="annualFTECost"/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        <label htmlFor= "annualFTEHours" className="col-control-sr-label">{t('preferences:annualFTEHours')} <span className="text-danger-1">*</span></label>
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <FormInputs
                                                                            ncols={["col-md-12 preference-preferred-pram"]}
                                                                            proprieties={[
                                                                                {
                                                                                    type: "text",
                                                                                    name: "annualFTEHours",
                                                                                    bsClass: this.state.inputClass_annualFTEHours,
                                                                                    placeholder: "",
                                                                                    value: this.state.annualFTEHours.value,
                                                                                    onChange: this.handleAnnualFTEHoursInputChange,
                                                                                    id: "annualFTEHours",
                                                                                    required: true,
                                                                                }
                                                                            ]}
                                                                        />
                                                                        <div className="col-md-12">
                                                                            <FormErrors formErrors={this.state.formErrors}
                                                                                        fieldName="annualFTEHours"/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        <label htmlFor= "LTNonError" className="col-control-sr-label">{t('preferences:LTNonError')} <span className="text-danger-1">*</span></label>
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <FormInputs
                                                                            ncols={["col-md-12 preference-preferred-pram"]}
                                                                            proprieties={[
                                                                                {
                                                                                    type: "text",
                                                                                    name: "LTNonError",
                                                                                    bsClass: this.state.inputClass_LTNonError,
                                                                                    placeholder: "",
                                                                                    value: this.state.LTNonError.value,
                                                                                    onChange: this.handleLTNonErrorInputChange,
                                                                                    id: "LTNonError",
                                                                                    required: true,
                                                                                }
                                                                            ]}
                                                                        />
                                                                        <div className="col-md-12">
                                                                            <FormErrors formErrors={this.state.formErrors}
                                                                                        fieldName="LTNonError"/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        <label htmlFor= "LTSysError" className="col-control-sr-label">{t('preferences:LTSysError')} <span className="text-danger-1">*</span></label>
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <FormInputs
                                                                            ncols={["col-md-12 preference-preferred-pram"]}
                                                                            proprieties={[
                                                                                {
                                                                                    type: "text",
                                                                                    name: "LTSysError",
                                                                                    bsClass: this.state.inputClass_LTSysError,
                                                                                    placeholder: "",
                                                                                    value: this.state.LTSysError.value,
                                                                                    onChange: this.handleLTSysErrorInputChange,
                                                                                    id: "LTSysError",
                                                                                    required: true,
                                                                                }
                                                                            ]}
                                                                        />
                                                                        <div className="col-md-12">
                                                                            <FormErrors formErrors={this.state.formErrors}
                                                                                        fieldName="LTSysError"/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        <label htmlFor= "LTUserError" className="col-control-sr-label">{t('preferences:LTUserError')} <span className="text-danger-1">*</span></label>
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <FormInputs
                                                                            ncols={["col-md-12 preference-preferred-pram"]}
                                                                            proprieties={[
                                                                                {
                                                                                    type: "text",
                                                                                    name: "LTUserError",
                                                                                    bsClass: this.state.inputClass_LTUserError,
                                                                                    placeholder: "",
                                                                                    value: this.state.LTUserError.value,
                                                                                    onChange: this.handleLTUserErrorInputChange,
                                                                                    id: "LTUserError",
                                                                                    required: true,
                                                                                }
                                                                            ]}
                                                                        />
                                                                        <div className="col-md-12">
                                                                            <FormErrors formErrors={this.state.formErrors}
                                                                                        fieldName="LTUserError"/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        <label htmlFor= "LTUncatMessage" className="col-control-sr-label">{t('preferences:LTUncatMessage')} <span className="text-danger-1">*</span></label>
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <FormInputs
                                                                            ncols={["col-md-12 preference-preferred-pram"]}
                                                                            proprieties={[
                                                                                {
                                                                                    type: "text",
                                                                                    name: "LTUncatMessage",
                                                                                    bsClass: this.state.inputClass_LTUncatMessage,
                                                                                    placeholder: "",
                                                                                    value: this.state.LTUncatMessage.value,
                                                                                    onChange: this.handleLTUncatMessageInputChange,
                                                                                    id: "LTUncatMessage",
                                                                                    required: true,
                                                                                }
                                                                            ]}
                                                                        />
                                                                        <div className="col-md-12">
                                                                            <FormErrors formErrors={this.state.formErrors}
                                                                                        fieldName="LTUncatMessage"/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        <label htmlFor= "projectDuration" className="col-control-sr-label">{t('preferences:projectDuration')} <span className="text-danger-1">*</span></label>
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <FormInputs
                                                                            ncols={["col-md-12 preference-preferred-pram"]}
                                                                            proprieties={[
                                                                                {
                                                                                    type: "text",
                                                                                    name: "projectDuration",
                                                                                    bsClass: this.state.inputClass_projectDuration,
                                                                                    placeholder: "",
                                                                                    value: this.state.projectDuration.value,
                                                                                    onChange: this.handleProjectDurationInputChange,
                                                                                    id: "projectDuration",
                                                                                    required: true,
                                                                                }
                                                                            ]}
                                                                        />
                                                                        <div className="col-md-12">
                                                                            <FormErrors formErrors={this.state.formErrors}
                                                                                        fieldName="projectDuration"/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        <label htmlFor= "projectUser" className="col-control-sr-label">{t('preferences:projectUser')} <span className="text-danger-1">*</span></label>
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <FormInputs
                                                                            ncols={["col-md-12 preference-preferred-pram"]}
                                                                            proprieties={[
                                                                                {
                                                                                    type: "text",
                                                                                    name: "projectUser",
                                                                                    bsClass: this.state.inputClass_projectUser,
                                                                                    placeholder: "",
                                                                                    value: this.state.projectUser.value,
                                                                                    onChange: this.handleProjectUserInputChange,
                                                                                    id: "projectUser",
                                                                                    required: true,
                                                                                }
                                                                            ]}
                                                                        />
                                                                        <div className="col-md-12">
                                                                            <FormErrors formErrors={this.state.formErrors}
                                                                                        fieldName="projectUser"/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="card-row-flex-preference-preferred-prams card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        <label htmlFor= "testEffortPerModule" className="col-control-sr-label">{t('preferences:testEffortPerModule')} <span className="text-danger-1">*</span></label>
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <FormInputs
                                                                            ncols={["col-md-12 preference-preferred-pram"]}
                                                                            proprieties={[
                                                                                {
                                                                                    type: "text",
                                                                                    name: "testEffortPerModule",
                                                                                    bsClass: this.state.inputClass_testEffortPerModule,
                                                                                    placeholder: "",
                                                                                    value: this.state.testEffortPerModule.value,
                                                                                    onChange: this.handleTestEffortPerModuleInputChange,
                                                                                    id: "testEffortPerModule",
                                                                                    required: true,
                                                                                }
                                                                            ]}
                                                                        />
                                                                        <div className="col-md-12">
                                                                            <FormErrors formErrors={this.state.formErrors}
                                                                                        fieldName="testEffortPerModule"/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                            <div role="group" aria-labelledby="Report-Settings" className="knoa-region" tabIndex={"-1"}>
                                                <Card
                                                    id={'Report-Settings'}
                                                    title={t('preferences:reportSettingsTitle')}
                                                    category={t('preferences:reportSettingsSubTitle')}
                                                    content={
                                                        <div className="KnoaFilterContainer">
                                                            <FormGroup
                                                                controlId="formReportSetting"
                                                            >
                                                                <Row className="card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        {t('preferences:sessionPersistTitle')}
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <Switch onChange={this.handleSessionPersistChange}
                                                                                value={this.state.sessionPersist}
                                                                                onText={t('common:ON')}
                                                                                offText={t('common:OFF')}
                                                                                bsSize={'small'}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row className="card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        {t('preferences:preferencePersist')}
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <Switch onChange={this.handlePreferencePersistChange}
                                                                                value={this.state.preferencePersist}
                                                                                onText={t('common:ON')}
                                                                                offText={t('common:OFF')}
                                                                                bsSize={'small'}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row className="card-row-flex">
                                                                    <Col className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        {t('preferences:projectPersist')}
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <Switch onChange={this.handleProjectPersistChange}
                                                                                value={this.state.projectPersist}
                                                                                onText={t('common:ON')}
                                                                                offText={t('common:OFF')}
                                                                                bsSize={'small'}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                            <div role="group" aria-labelledby="Home-Page" className="knoa-region" tabIndex={"-1"}>
                                                <Card
                                                    id={'Home-Page'}
                                                    title={t('preferences:myPrefHomePageTitle')}
                                                    category={t('preferences:myPrefHomePageSubTitle')}
                                                    content={
                                                        <div className="KnoaFilterContainer">
                                                            <FormGroup
                                                                role="group" aria-labelledby="kpis"
                                                                controlId="formProjectKPIS"
                                                            >
                                                                <Row className="card-row-flex">
                                                                    <Col id="kpis" className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        {t('preferences:kpis')}
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <GenerateKPICardsCheckboxes card={this.state.cards}
                                                                                                    handleCheckboxUpdate={this.handleCheckboxUpdate}
                                                                                                    translate={t}/>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                            <FormGroup
                                                                role="group" aria-labelledby="watchlists"
                                                                controlId="formProjectWatchlist"
                                                            >
                                                                <Row className="card-row-flex">
                                                                    <Col id="watchlists" className="card-row-col-label" componentClass={ControlLabel}
                                                                         sm={1}>
                                                                        {t('preferences:watchlists')}
                                                                    </Col>
                                                                    <Col sm={1} className="card-row-col-right">
                                                                        <GenerateWatchListCheckboxes watchlist={this.state.watchLists}
                                                                                                     handleCheckboxUpdate={this.handleCheckboxUpdate}
                                                                                                     translate={t}/>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    }
                                />
                            </div>
                        </Col>
                        {/*<Col lg={1}>*/}
                        {/*</Col>*/}
                    </Row>

                    <Footer fluid footerObj={footerObj}/>
                </div>
            </form>
        )
    }


}

export default withTranslation()(MyPreference);