import React, {Component} from "react";
import Select, {createFilter,AriaOnFocus } from 'react-select';
import {withTranslation} from 'react-i18next';
import CustomSelectStyles from "./CustomSelectStyles";
import ModuleSelectStyles from "./ModuleSelectStyles";
import FilterSelectStyles from "./FilterSelectStyles";
import FilterTypeToSearchStyles from "./FilterTypeToSearchStyles";
import Creatable   from 'react-select/creatable';

export class CommonSelector extends Component {

    
    render() {
        const {style, componentLocation, isModal, placeholder, name, value, height, maxMenuHeight, defaultValue, options, getOptionLabel, getOptionValue, onChange, onInputChange, onFocus, onBlur, onMenuClose, isClearable, isSearchable, isCreatable,isInstanceReport,inputId,id} = this.props;
        //style: typetosearch or dropdown
        //componentLocation: in other normal or in filterpanel
        let styles = {};
        let closeMenuOnSelect = false;
        let menuPortalTarget = document.body;
        let isMulti = true;

        if (name === "language" || name === "customer" || name === "user") {
            closeMenuOnSelect = true;
            isMulti = false;
        }

        if (componentLocation === "normal") {
            if (style === "dropdown") {
                styles = CustomSelectStyles;
               // filterOption = createFilter(filterConfig);
            } else if (style === "typetosearch") {
                styles = ModuleSelectStyles;
            }
        } else if (componentLocation === "filterpanel") {
            if (style === "dropdown") {
                styles = FilterSelectStyles;
               // filterOption = createFilter(filterConfig);
            } else if (style === "typetosearch") {
                styles = FilterTypeToSearchStyles;
            }
        }

        if (isModal) menuPortalTarget = undefined;

        return (
            <div style={{zIndex:100}}>
                {isCreatable ? (
                <Creatable
                  isMulti={isMulti}
                  placeholder={placeholder}
                  name={name}
                  options={options}
                  onChange={onChange}
                  onInputChange={onInputChange}
                  value={value}
                  defaultValue={defaultValue}
                  noOptionsMessage={() => null}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  height={height}
                  maxMenuHeight={maxMenuHeight}
                  closeMenuOnSelect={closeMenuOnSelect}
                  menuPortalTarget={menuPortalTarget}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onMenuClose={onMenuClose}
                  styles={styles}
                  isDisabled={isInstanceReport}
                  inputId ={inputId}
                  id={id}
                />
              ) : (
                <Select
                  isMulti={isMulti}
                  placeholder={placeholder}
                  name={name}
                  options={options}
                  onChange={onChange}
                  onInputChange={onInputChange}
                  value={value}
                  defaultValue={defaultValue}
                  noOptionsMessage={() => null}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  height={height}
                  maxMenuHeight={maxMenuHeight}
                  closeMenuOnSelect={closeMenuOnSelect}
                  menuPortalTarget={menuPortalTarget}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onMenuClose={onMenuClose}
                  styles={styles}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue}
                  isDisabled={isInstanceReport}
                  className="basic-single"
                  classNamePrefix="select"
                  inputId ={inputId}
                  id={id}
                />
              )
          }
          </div>
        );
    }
}

export default withTranslation()(CommonSelector);