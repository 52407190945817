import React, { Component } from "react";
import {Row, Col, Grid} from "react-bootstrap";

class InfoCardFooter extends Component {
  render() {
    const {
      footerText
    } = this.props;

    return(
      <>
      {footerText !== "" &&
          <div className="footer info-card-footer" >
            <hr/>
            <Grid fluid>
              <Row>
                <Col lg={12} sm={15} className="knoaFooterText">
                  <span>{footerText}</span>
                </Col>
              </Row>
            </Grid>
          </div>
      }
      </>
    )
  }
}

export default InfoCardFooter;