/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import MauiHome from "./views/pages/home/MauiHome.jsx";
import Report from "./views/pages/report/Report.jsx";
import LoginPage from "./views/pages/login/LoginPage.jsx";
import LogOut from "./views/pages/login/LogOut.jsx";
import UserAccount from "./views/pages/admin/UserAccount.jsx";
import Projects from "./views/pages/project/Projects.jsx";
import ProjectWizard from "./views/pages/project/ProjectWizard";
import MyPreference from "./views/pages/user/MyPreference";
import AllReports from "./views/pages/report/AllReports";
import AdminSettings from "./views/pages/admin/AdminSettings";
import AdminKpiCardPage from "./views/pages/admin/AdminKpiCardPage";
import AdminUserPage from "./views/pages/admin/AdminUserPage";
import ForgotPassword from "./views/pages/login/ForgotPassword";
import ResetPassword from "./views/pages/login/ResetPassword";
import AdminSecuritySetting from "./views/pages/admin/AdminSecuritySetting";
import twoFactorAuthentication from "./views/pages/login/twoFactorAuthentication";
import SamlLanding from "./views/pages/login/SamlLanding";
import ProxySessionChange from "./views/pages/login/ProxySessionChange";


class MauiRoutes {
    
    constructor() {
        this.defaultRoutes = [];
        this.initInstance(false);
        this.loaded = false;
    }

    initInstance = (isAdmin, homeIcon, projectIcon, adminCardIcon, adminUserIcon) => {
        this.defaultRoutes = [
            {
                path: "/home",
                name: "home",
                icon: homeIcon,
                component: MauiHome,
                layout: "/admin",
                invisible: false,
                needRefresh: false,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/projects",
                name: "Projects",
                icon: projectIcon,
                component: Projects,
                layout: "/admin",
                invisible: false,
                needRefresh: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/Settings/createUser",
                name: "New User",
                icon: "pe-7s-add-user",
                component: UserAccount,
                layout: "/admin",
                needRefresh: true,
                invisible: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/Settings/editUser",
                name: "Edit User",
                icon: "pe-7s-add-user",
                component: UserAccount,
                layout: "/admin",
                needRefresh: false,
                invisible: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/login",
                layout: "/auth",
                name: "Login Page",
                icon: "pe-7s-date",
                component: LoginPage,
                needRefresh: true,
                invisible: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/logout",
                layout: "/auth",
                name: "LogOut",
                icon: "pe-7s-date",
                component: LogOut,
                needRefresh: true,
                invisible: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/proxySessionChange",
                layout: "/auth",
                name: "ProxySessionChange",
                icon: "pe-7s-date",
                component: ProxySessionChange,
                needRefresh: true,
                invisible: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/forgotPassword",
                layout: "/auth",
                name: "Forgot Password Page",
                icon: "pe-7s-date",
                component: ForgotPassword,
                needRefresh: true,
                invisible: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/resetPassword",
                layout: "/auth",
                name: "Reset Password Page",
                icon: "pe-7s-date",
                component: ResetPassword,
                needRefresh: true,
                invisible: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/secureLanding",
                layout: "/auth",
                name: "saml landing",
                icon: "pe-7s-date",
                component: SamlLanding,
                needRefresh: true,
                invisible: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/twoFactorAuthentication",
                layout: "/auth",
                name: "Two Factor Authentication Page",
                icon: "pe-7s-date",
                component: twoFactorAuthentication,
                needRefresh: true,
                invisible: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/myProfile",
                layout: "/admin",
                name: "My Profile",
                icon: "pe-7s-date",
                component: UserAccount,
                needRefresh: true,
                invisible: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/password",
                layout: "/admin",
                name: "Password",
                icon: "pe-7s-date",
                component: ResetPassword,
                needRefresh: true,
                invisible: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/myPreference",
                layout: "/admin",
                name: "My Preferences",
                icon: "pe-7s-date",
                component: MyPreference,
                needRefresh: true,
                invisible: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/newProject",
                layout: "/admin",
                name: "New Project",
                component: ProjectWizard,
                needRefresh: false,
                invisible: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/viewProject",
                layout: "/admin",
                name: "Projects",
                component: Report,
                needRefresh: false,
                invisible: true,
                isHelp: false,
                isAdmin: false,
                reportType: 'project'
            },
            {
                path: "/editProject",
                layout: "/admin",
                name: "Edit Project",
                component: ProjectWizard,
                needRefresh: false,
                invisible: true,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/Settings/UserManagement",
                name: "User Management",
                icon: adminUserIcon,
                component: AdminUserPage,
                layout: "/admin",
                invisible: true,
                needRefresh: false,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/Settings/KPICards",
                name: "KPI Cards",
                icon: adminCardIcon,
                component: AdminKpiCardPage,
                layout: "/admin",
                invisible: true,
                needRefresh: false,
                isHelp: false,
                isAdmin: false
            },
            {
                path: "/Settings/SecuritySettings",
                name: "Security Settings",
                icon: "pe-7s-key",
                component: AdminSecuritySetting,
                layout: "/admin",
                invisible: true,
                needRefresh: false,
                isHelp: false,
                isAdmin: false
            }
        ];
        if(isAdmin) {
            let adminNode = {};
            adminNode["path"] = "/AllSettings";
            adminNode["layout"] = "/admin";
            adminNode["name"] = "Settings";
            adminNode["icon"] = "adminIcon";
            adminNode["component"] = AdminSettings;
            adminNode["invisible"] = true;
            adminNode["needRefresh"] = true;
            adminNode["isHelp"] = false;
            adminNode["isAdmin"] = true;
            this.defaultRoutes.push(adminNode);
        }
    };
    
    
    loadRoutes = (reports, isAdmin, homeIcon, projectIcon, adminCardIcon, adminUserIcon)=> {
        var _this = this;
        _this.initInstance(isAdmin, homeIcon, projectIcon, adminCardIcon, adminUserIcon);

        reports.map((prop, key) => {
            let node = {};
            node["collapse"] = true;
            node["path"] = "/"+prop.name;
            node["name"] = prop.name;
            node["state"] = "open"+prop.name;
            node["icon"] = prop.icon;
            node["layout"] = "/admin";
            node["page"] = "/All"+prop.name;
            node["invisible"] = false;
            node["needRefresh"] = true;
            node["isHelp"] = false;
            node["isAdmin"] = false;
            let child = [];
            let allReportNode = {};
            allReportNode["path"] = "/"+prop.name+"/All"+prop.name;
            allReportNode["name"] = prop.name;
            allReportNode["layout"] = "/admin";
            allReportNode["invisible"] = true;
            allReportNode["needRefresh"] = true;
            allReportNode["component"] = AllReports;
            allReportNode["isHelp"] = false;
            allReportNode["isAdmin"] = false;
            child.push(allReportNode);
            prop.views.forEach(function (report, index) {
                let viewNode = {};
                viewNode["path"] = "/"+prop.name.replace(/\s/g, "")+"/"+report.replace(/\s/g, "");
                viewNode["name"] = report;
                viewNode["layout"] = "/admin";
                viewNode["invisible"] = false;
                viewNode["needRefresh"] = true;
                viewNode["component"] = Report;
                viewNode["isHelp"] = false;
                viewNode["isAdmin"] = false;
                viewNode["reportType"] = "interactive";
                child.push(viewNode);
            });
            node["views"] = child;
            _this.defaultRoutes.push(node);
        });
    };
    
    getRoutes() {
        return this.defaultRoutes;
    }
}


const mauiRoutes = new MauiRoutes();
export { mauiRoutes };
//
// export const routes = mauiRoutes.defaultRoutes;
// export const loaded = mauiRoutes.loaded;