import React, { Component } from "react";
import InfoCard from "./InfoCard";
import KnoaCardCheckbox from "./KnoaCardCheckbox";

class InfoSelectCard extends Component {
  render() {
    const {
      id,
      isChecked,
      icon,
      subHeading,
      heading,
      footerText,
      handleProjectReportsUpdate,
      mainRowHeight
    } = this.props;

    return(
      <InfoCard
        icon={icon}
        subHeading={subHeading}
        heading={heading}
        footerText={footerText}
        checkbox={
          <KnoaCardCheckbox
            id={id+":"+subHeading.text+":"+heading.text}
            isChecked={isChecked}
            handleProjectReportsUpdate={handleProjectReportsUpdate}
        />}
        mainRowHeight={mainRowHeight}
      />
    )
  }
}

export default InfoSelectCard;