import React, {Component} from "react";
import {Col, FormGroup, Row} from "react-bootstrap";
import Card from "../Card/Card";
import {CommonSelector} from "../common/CommonSelector";
import {API} from "../../lib/API";
import {Redirect} from "react-router-dom";
import Footer from "../layout/Footer";
import {withTranslation} from 'react-i18next';
import ModalDialog from "../common/ModalDialog";
import {getInfoMessages} from "../../lib/messageHandler";
import {Helmet} from "react-helmet";
import {mauiRoutes} from "../../routes";

const localStorage = require('../../lib/localStorage');
const compareItems = (a, b) => {
    const labelA = a.label;
    const labelB = b.label;
    let comparison = 0;
    if (labelA > labelB) {
        comparison = 1;
    } else if (labelA < labelB) {
        comparison = -1;
    }
    return comparison;
};

class UserProxy extends Component {
    constructor(props) {
        super(props);

        this.submitLogin = this.submitLogin.bind(this);
        this.updateTokens = this.updateTokens.bind(this);
        this.handleCustomerUpdate = this.handleCustomerUpdate.bind(this);
        this.handleUserUpdate = this.handleUserUpdate.bind(this);

        //customers for drop-down
        let _customers = [];
        if (this.props.customers) {
            this.props.customers.map(item => {
                let customerObj = {};
                customerObj.value = item;
                customerObj.label = item;
                _customers.push(customerObj);
            });
        }

        _customers.sort(compareItems);

        this.state = {
            customers: _customers,
            users: [],
            selectedCustomer: null,
            selectedUser: null,
            loginDisabled: true,
            session: false,
            showLoggedoutModal: false,
            sessionTerminated: false
        };
    }

    handleLogoutConfirm = () => {
        this.setState({sessionTerminated: true});
    };


    updateTokens = ({ expiresIn}) => {
        API.updateTimeout({expiresIn});
        this.setState({session: true});
    };

    submitLogin = e => {
        e.preventDefault();
        if (this.state.selectedCustomer && this.state.selectedUser) {
            API.user
                .post('/session/loginAs', {userId: this.state.selectedUser.value})
                .then(resp => {
                    const {data: resData} = resp;
                    const {data} = resData;

                    this.updateTokens({
                        accessToken: data.access,
                        refreshToken: data.refresh,
                        expiresIn: data.expires
                    });
                    window.sessionStorage.setItem('proxyLogin', "true");
                    window.sessionStorage.removeItem("proxyProps");
                })
                .catch(e => {
                    console.error(e);
                    if (localStorage.get("accessDenied401") === "true") {
                        this.setState({showLoggedoutModal: true});
                        return;
                    }
                });
        }
    };

    handleCustomerUpdate(input) {
        if (!this.state.selectedCustomer || (input.value !== this.state.selectedCustomer.value)) {
            API.user
                .post('/session/supportList', {customer: input.value})
                .then(resp => {
                    const {data: resData} = resp;
                    const {data} = resData;

                    //users for drop-down
                    let _users = [];
                    if (data.users && data.users.length > 0) {
                        data.users.map(item => {
                            let userObj = {};
                            userObj.value = item.id;
                            userObj.label = item.info;
                            _users.push(userObj);
                        });

                        _users.sort(compareItems);

                        this.setState({
                            selectedCustomer: input,
                            users: _users,
                            selectedUser: null,
                            loginDisabled: true
                        });
                    }
                })
                .catch(e => {
                    console.error(e);
                    if (localStorage.get("accessDenied401") === "true") {
                        this.setState({showLoggedoutModal: true});
                        return;
                    }
                });
        }
    }

    handleUserUpdate(input) {
        if (!this.state.selectedUser || (input.value !== this.state.selectedUser.value)) {
            this.setState({
                selectedUser: input,
                loginDisabled: false
            });
        }
    }

    componentDidMount() {
        if (this.state.customers.length === 0) {
            this.props.handleNotificationClick("info", "tc", 'There are currently no customers that have PROXY USER MODE enabled.');
        }
    }

    render() {
        if (this.state.sessionTerminated)
            return <Redirect to={{pathname: '/auth/logout'}}/>;
        else if (this.state.session === true) {
            return <Redirect to={{pathname: '/admin/home'}}/>
        }

        const {customers, users, selectedCustomer, selectedUser, loginDisabled} = this.state;

        const footerObj = [];
        const loginAsUserObj = {
            name: "Login",
            disabled: loginDisabled,
            callback: this.submitLogin
        };
        footerObj.push(loginAsUserObj);

        let routes = mauiRoutes.getRoutes();
        const {t, navVarBrand, getActiveRoute} = this.props;
        return (
            <>
                <Helmet>
                    <title>Knoa Analytics - Proxy User</title>
                </Helmet>
                <ModalDialog
                    showModal={this.state.showLoggedoutModal}
                    title={"Log Out"}
                    contentBody={
                        <span>{getInfoMessages(this.props.t, 'logoutfor401', null, null)}</span>
                    }
                    bodyPaddingBottom='15px'
                    footerBtnBsStyle="success"
                    footerBtnClass="KnoaSuccessButton"
                    footerBtnOnClick={this.handleLogoutConfirm}
                    footerBtnText={this.props.t('common:ok')}
                />
                <div className="proxy-user-container">
                    <Col lg={6} md={6} sm={8} lgOffset={3} mdOffset={3} smOffset={2}>
                        <form onSubmit={this.submitLogin}>
                            <Card
                                textCenter
                                title="Proxy User Login"
                                content={
                                    <>
                                        <FormGroup>
                                            <Row className="csLabelRow">
                                                <label htmlFor="customers" className='csLabelSpan'>
                                                    Customer
                                                </label>
                                            </Row>
                                            <Row>
                                                <div className="csLabelDropdown">
                                                    <CommonSelector
                                                        inputId="customers"
                                                        style={"dropdown"}
                                                        componentLocation={"normal"}
                                                        name={"customer"}
                                                        value={selectedCustomer}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        options={customers}
                                                        onChange={this.handleCustomerUpdate}
                                                    />
                                                </div>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup>
                                            <Row className="csLabelRow">
                                                <label htmlFor="user" className='csLabelSpan'>
                                                    User
                                                </label>
                                            </Row>
                                            <Row>
                                                <div className="csLabelDropdown">
                                                    <CommonSelector
                                                        inputId="user"
                                                        style={"dropdown"}
                                                        componentLocation={"normal"}
                                                        name={"user"}
                                                        value={selectedUser}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        options={users}
                                                        onChange={this.handleUserUpdate}
                                                    />
                                                </div>
                                            </Row>
                                        </FormGroup>
                                    </>
                                }
                                ftTextCenter
                                custom='csCard'
                            />
                        </form>
                    </Col>
                </div>
                <Footer fluid footerObj={footerObj}/>
            </>
        );
    }
}


export default withTranslation()(UserProxy);