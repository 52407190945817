import React, { Component } from "react";
import {Col} from "react-bootstrap";
import LinkWithTooltip from "../common/LinkWithTooltip";

class KnoaCardToolip extends Component {
  render() {
    const {
      tooltip
    } = this.props;

    return(
      <Col sm={4}  className="colLeft card-tooltip-col">
        <div className="text-left">
          <LinkWithTooltip tooltip={tooltip.text} href="" id={tooltip.key}
                           tooltipIconClass={tooltip.iconClass} buttonStyle={tooltip.buttonStyle}
                           iconStyle={tooltip.iconStyle}/>
        </div>
      </Col>
    )
  }
}

export default KnoaCardToolip;